import { ArrowRightOutlined } from '@ant-design/icons';
import { Checkbox, message } from 'antd';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { postPredictVariant } from '../../apis/serve';
import Box from '../../components/box.jsx';
import { PackageAiBtn2 as Btn2 } from '../../components/btn.jsx';
import Container from '../../components/container.jsx';
import Loading from '../../components/loading.jsx';
import Stage from '../../components/stage.jsx';
import style from './index.module.scss';
import ImageItem from './item.jsx';

function buildAoi() {
  return {
    brand: { name: 'Brand Logo', type: null, points: null },
    variant: { name: 'Variant Name', type: null, points: null },
    brand2: {
      name: 'Secondary Element that helps with Brand Blocking (Optional)',
      type: null,
      points: null,
    },
    variant2: {
      name: 'Secondary Element that helps with Variant Differentiation (Optional))',
      type: null,
      points: null,
    },
    complete: false,
  };
}

function buildAoiPoints(aoi, scale = 1) {
  if (!aoi.points) return undefined;
  const [lt, rb] = aoi.points;
  lt.x = Math.round(lt.x * scale);
  lt.y = Math.round(lt.y * scale);
  rb.x = Math.round(rb.x * scale);
  rb.y = Math.round(rb.y * scale);
  const rt = { x: rb.x, y: lt.y };
  const lb = { x: lt.x, y: rb.y };
  return [lt, rt, rb, lb];
}

async function postAoi(id, aois) {
  // use logo aoi
  const logoAoi = buildAoiPoints(aois.brand);
  // use brand
  const brandAoi = buildAoiPoints(aois.variant);
  // use variant 1
  const variantAoi1 = buildAoiPoints(aois.brand2);
  // use variant 2
  const variantAoi2 = buildAoiPoints(aois.variant2);
  await postPredictVariant({
    id,
    points: {
      logo: logoAoi,
      brand: brandAoi,
      variant: variantAoi1,
      aoi: variantAoi2,
    },
  });
}

function PackageAiView() {
  const [context, setContext] = useState({
    basic: {},
    asset: {
      allowPublic: true,
      list: [],
    },
    aois: [],
  });
  const [loading, setLoading] = useState(false);

  const complete = useMemo(() => {
    if (!context?.aois?.length) return false;
    return context.aois.every((e) => e.complete);
  }, [context]);

  const allowPublic = useMemo(() => {
    return context.asset.allowPublic;
  }, [context]);
  const setAllowPublic = useCallback(
    (val) => {
      setContext((ctx) => {
        return {
          ...ctx,
          asset: {
            ...ctx.asset,
            allowPublic: val.target.checked,
          },
        };
      });
    },
    [context, setContext]
  );

  async function init() {
    if (loading) return;
    setLoading(true);
    try {
      const ctxs = sessionStorage.getItem('packageAiContext');
      if (!ctxs) {
        message.error('Please fill in the basic information first');
        window.open('/package-ai-screening-batch-basic', '_self');
        return null;
      }

      // use ctx
      const ctx = JSON.parse(ctxs);
      if (!ctx?.asset?.list?.length) {
        message.error('Please upload the images first');
        window.open('/package-ai-screening-batch-basic', '_self');
        return null;
      }

      // init aoi
      ctx.aois = ctx.asset.list.map(() => buildAoi());

      // set data
      setContext(ctx);
    } catch (err) {
      console.error('init error', err);
      message.error('Init error');
    }
    setLoading(false);
  }
  useEffect(() => {
    void init();
  }, []);

  const onContinue = useCallback(async () => {
    if (!complete) {
      message.warning('Please complete all the designs');
      return;
    }

    if (loading) return;
    setLoading(true);
    try {
      // upload points
      for (let i = 0; i < context.asset.list.length; i += 1) {
        const id = context.res.imageIds[i];
        if (!id) continue;
        const aoi = context.aois[i];
        if (!aoi) continue;
        await postAoi(id, aoi);
      }

      sessionStorage.setItem('packageAiContext', JSON.stringify(context));
      window.open('/package-ai-screening-batch-wait', '_self');
    } catch (err) {
      console.error('upload aoi error', err);
      message.error('Upload aoi error');
    }
    setLoading(false);
  }, [context]);

  return (
    <Container>
      <div className={style.containerInner}>
        <Stage stage={2} />
        <Box
          title="Fill in Project Details"
          className={style.box}
          bgc="transparent"
        >
          <div className={style.boxInner}>
            <div className={style.imgList}>
              {context.asset.list.map((e, i) => {
                return (
                  <ImageItem
                    className={style.imgItem}
                    key={i}
                    data={e}
                    setData={(val) => {
                      setContext((ctx) => {
                        return {
                          ...ctx,
                          asset: {
                            ...ctx.asset,
                            list: ctx.asset.list.map((item, index) => {
                              if (index === i) return val;
                              return item;
                            }),
                          },
                        };
                      });
                    }}
                    aoi={context.aois[i]}
                    setAoi={(val) => {
                      setContext((ctx) => {
                        return {
                          ...ctx,
                          aois: ctx.aois.map((item, index) => {
                            if (index === i) return val;
                            return item;
                          }),
                        };
                      });
                    }}
                  />
                );
              })}
            </div>
            <div className={style.btnsBox}>
              <Btn2
                className={style.btn2}
                onClick={onContinue}
                suffix={<ArrowRightOutlined />}
                disabled={!complete}
              >
                CONTINUE
              </Btn2>
              <Checkbox checked={allowPublic} onChange={setAllowPublic}>
                Publish my testing results in the Sous-Chef library (visible for
                others). Deselect it for keeping results only visible to you
              </Checkbox>
            </div>
            {loading && <Loading />}
          </div>
        </Box>
      </div>
    </Container>
  );
}

export default PackageAiView;
