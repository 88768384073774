import { PlusOutlined } from '@ant-design/icons';
import { message } from 'antd';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  Image as KonvaImage,
  Layer,
  Rect,
  Stage,
  Transformer,
} from 'react-konva';
import useImage from 'use-image';
import GrabageSvg from '../../assets/img/garbage.svg';
import EditableText from '../../components/editableText.jsx';
import Loading from '../../components/loading.jsx';
import style from './editor.module.scss';

function PackageAiEditor({
  className = '',
  data,
  aoi,
  setAoi,
  footText = '',
  allowNull = true,
}) {
  const [image, loadStatus] = useImage(data.standardSrc, 'anonymous');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (loadStatus === 'loading') {
      setLoading(true);
    } else if (loadStatus === 'failed') {
      message.error('Failed to load image');
      setLoading(false);
    } else {
      setLoading(false);
    }
  }, [loadStatus]);

  const boxRef = useRef(null);

  const title = useMemo(() => {
    return aoi.name;
  }, [aoi]);
  const setTitle = useCallback(
    (val) => {
      setAoi({ ...aoi, name: val });
    },
    [aoi, setAoi]
  );

  const contain = useMemo(() => {
    if (!image) {
      return {
        width: 0,
        height: 0,
        scale: 1,
      };
    }

    // calculate scale
    const imageWidth = image.width;
    const imageHeight = image.height;
    const boxWidth = window.innerWidth * 0.13;
    const boxHeight = boxWidth * 1.2;
    const scale = Math.min(boxWidth / imageWidth, boxHeight / imageHeight);

    return {
      width: imageWidth * scale,
      height: imageHeight * scale,
      scale: scale,
    };
  }, [image]);

  const rectRef = useRef();
  const transformerRef = useRef();

  // init transformer
  useEffect(() => {
    if (!rectRef.current || !transformerRef.current) return;
    transformerRef.current.nodes([rectRef.current]);
    transformerRef.current.getLayer().batchDraw();
  }, [rectRef.current, transformerRef.current]);

  const rectData = useMemo(() => {
    if (!aoi.points) return null;
    const [lt, rb] = aoi.points;
    return {
      x: lt.x * contain.scale,
      y: lt.y * contain.scale,
      width: (rb.x - lt.x) * contain.scale,
      height: (rb.y - lt.y) * contain.scale,
    };
  }, [aoi, contain.scale]);
  const setRectData = useCallback(
    (x, y, width, height) => {
      if (!contain.width) return;
      const points = [
        { x: x / contain.scale, y: y / contain.scale },
        { x: (x + width) / contain.scale, y: (y + height) / contain.scale },
      ];
      setAoi({
        ...aoi,
        type: 'rect',
        points,
        complete: true,
      });
    },
    [aoi, setAoi, contain.scale]
  );

  const onDragEnd = useCallback(
    (ev) => {
      if (!ev.target) return;
      let { x, y } = ev.target.position();
      if (x < 0) x = 0;
      if (x > contain.width) x = contain.width;
      if (y < 0) y = 0;
      if (y > contain.height) y = contain.height;
      let { width, height } = ev.target.size();
      if (x + width > contain.width) width = contain.width - x;
      if (y + height > contain.height) height = contain.height - y;
      setRectData(x, y, width, height);
    },
    [contain, setRectData]
  );

  const onTransformEnd = useCallback(() => {
    const node = rectRef.current;
    if (!node) return;
    const scaleX = node.scaleX();
    const scaleY = node.scaleY();
    let x = node.x();
    if (x < 0) x = 0;
    if (x > contain.width) x = contain.width;
    let y = node.y();
    if (y < 0) y = 0;
    if (y > contain.height) y = contain.height;
    let width = node.width() * scaleX;
    if (x + width > contain.width) width = contain.width - x;
    let height = node.height() * scaleY;
    if (y + height > contain.height) height = contain.height - y;
    node.scaleX(1);
    node.scaleY(1);
    setRectData(x, y, width, height);
  }, [rectRef.current, contain, setRectData]);

  const onCreate = useCallback(() => {
    setRectData(10, 10, 30, 30);
  }, [setRectData]);
  const onClear = useCallback(() => {
    setAoi({
      ...aoi,
      type: null,
      points: null,
      complete: false,
    });
  }, [aoi, setAoi]);

  useEffect(() => {
    if (allowNull) return;
    if (aoi.type) return;
    onCreate();
  }, [onCreate, allowNull, aoi]);

  return (
    <div className={`${style.editorBox} ${className}`}>
      <EditableText
        className={style.editorTitle}
        value={title}
        onChange={setTitle}
      />
      <div ref={boxRef} className={style.editorBody}>
        <Stage width={contain.width} height={contain.height}>
          <Layer>
            <KonvaImage
              image={image}
              width={contain.width}
              height={contain.height}
            />
            <Rect
              ref={rectRef}
              visible={Boolean(Boolean)}
              x={rectData?.x}
              y={rectData?.y}
              width={rectData?.width}
              height={rectData?.height}
              fill="#FAFF00AD"
              stroke="#00FFF0"
              strokeWidth={2}
              draggable={true}
              onDragEnd={onDragEnd}
              onTransformEnd={onTransformEnd}
            />
            <Transformer
              visible={Boolean(rectData)}
              ref={transformerRef}
              resizeEnabled={true}
              rotateEnabled={false}
              flipEnabled={false}
            />
          </Layer>
        </Stage>
        {allowNull &&
          (aoi.type ? (
            <img
              src={GrabageSvg}
              className={style.editorClearImg}
              onClick={onClear}
            />
          ) : (
            <PlusOutlined className={style.editorClear} onClick={onCreate} />
          ))}
      </div>
      <div className={style.editorFoot}>{footText}</div>
      {loading && <Loading />}
    </div>
  );
}

export default PackageAiEditor;
