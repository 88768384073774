// src/components/Popup.js
import React from 'react';
import './guide-pop-up.scss';
import HowToUse from '../how-to-use/how-to-use.jsx';
import { aiTopUnileverLogo } from '../../../../components/icons/icons.jsx';
import { aiTopCancelBtn } from '../../../../components/icons/icons.jsx';
import BorderContainer from '../../../trends-dial/components/border-container/border-container.jsx';

const GuidePopup = ({ isVisible, onClose }) => {
  return (
    <div className="guide-pop-up">
      <div className={`popup ${isVisible ? 'show' : ''}`}>
        <div className="popup-content">
          <BorderContainer hideLeftCorner headerTitle="How to Use Guide">
            <header>
              {/* <img src={aiTopUnileverLogo} alt="Unilever Logo" /> */}
              <img
                src={aiTopCancelBtn}
                className="close"
                onClick={onClose}
                alt="Close"
              />
            </header>
            <HowToUse />
          </BorderContainer>
        </div>
      </div>
    </div>
  );
};

export default GuidePopup;
