// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.F8UHU0rlh7VFdvxWWkr7{height:130px;display:flex;justify-content:center;align-items:center}.F8UHU0rlh7VFdvxWWkr7>img{margin-top:10px;position:relative;z-index:10}`, "",{"version":3,"sources":["webpack://./src/modules/gotfl-weena/pages/Layout/components/commen.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,YAAA,CACA,sBAAA,CACA,kBAAA,CAEA,0BACE,eAAA,CACA,iBAAA,CACA,UAAA","sourcesContent":[".headerWrap {\n  height: 130px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n\n  &>img {\n    margin-top: 10px;\n    position: relative;\n    z-index: 10;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"headerWrap": `F8UHU0rlh7VFdvxWWkr7`
};
export default ___CSS_LOADER_EXPORT___;
