import React from 'react';
import { cn } from '../../../utils/utils';
import { ReactComponent as AddImgSvg } from '../../../assets/svgs/add_img.svg';
import { ReactComponent as AddSvg } from '../../../assets/svgs/add.svg';
import bgIcon from '../../../assets/bg.png';
import pauseIcon from '../../../assets/images/pause.png';
import { ReactComponent as FiledirSvg } from '../../../assets/svgs/filedir.svg';
import { ReactComponent as DeleteSvg } from '../../../assets/svgs/delete.svg';

import { Checkbox, Button, Upload, Select } from 'antd';
import ModalCom from '../../../components/ModalCom/index.jsx';
import SvgCom from '../../../components/SvgCom/index.jsx';
import { useState } from 'react';

const { Dragger } = Upload;

const UploadWinCom = ({showWin, className ='', onCloseWin}) => {
  const [fileList, setFileList] = useState([]);
  const onChange = () => {};

  const props = {
    multiple: true,
    showUploadList: false,
    beforeUpload: (file, list) => {
      setFileList([ ...fileList, ...list]);
    },
    customRequest: (options) => {
      console.log(1231, options);
    }
  };

  return <ModalCom showWin={showWin} className={cn('!w-[85%] min-w-[520px]', className)} onCloseWin={onCloseWin}>
    <div className="px-[30px] relative">
      <div className="text-center">
        <span className="text-[22px] font-bold leading-none text-primary">Upload</span>
        <Dragger {...props} className="video-popup absolute right-[50px] top-[-5px]">
          <button className="border-btn">
            <SvgCom Svg={FiledirSvg} size="20"></SvgCom>
            <span className="ml-2">Browse the file</span>
          </button>
        </Dragger>
      </div>
      <div className="h-[350px] overflow-y-auto  mt-[20px]">
        {
          fileList.length === 0 && 
                    <Dragger {...props} className="video-popup">
                      <div className="h-full w-full flex flex-col justify-center items-center bg-third rounded-md group">
                        <SvgCom Svg={AddImgSvg} size="30"></SvgCom>
                        <span className="text-sm mt-3 text-primary hover:font-bold">Upload or drag here</span>
                      </div>
                    </Dragger>
        }
        {
          fileList.length > 0 &&
                    <div className="grid gap-4 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-6">
                      {
                        fileList.map((item, index) => <VideoItem key={index}></VideoItem>)
                      }
                      <Dragger {...props} className="video-popup">
                        <div className={cn('bg-[#DFECDD] rounded-2xl p-[15px] w-full h-full cursor-pointer flex justify-center items-center')}>
                          <SvgCom Svg={AddSvg} size="64"></SvgCom>
                        </div>
                      </Dragger>
                    </div>
        }
      </div>
      <div className="h-[2px] bg-grey-2 my-[15px]"></div>
      <Checkbox onChange={onChange}>Set all the videos in the same languages</Checkbox>
      <div className="mt-[10px] text-right">
        <Button type="primary" className="border-none shadow-none w-[120px] rounded-xl" disabled={false}>Upload</Button>
      </div>
    </div>
  </ModalCom>;
};

const VideoItem = () => {
  return (
    <div className={cn('bg-[#DFECDD] rounded-2xl p-[15px]')}>
      <div className="flex items-center justify-between mb-2">
        <span className="text-base font-bold leading-none text-green-1">Video name.mp4</span>
        <Select
          popupClassName="ctp-select"
          className="language-select ctp-select"
          defaultValue="English"
          options={[
            { value: 'jack', label: 'Jack' },
            { value: 'lucy', label: 'Lucy' },
            { value: 'Yiminghe', label: 'yiminghe' },
            { value: 'disabled', label: 'Disabled', disabled: true },
          ]}
        />
      </div>
      <div className="h-[265px] rounded-md relative overflow-hidden">
        <img src={bgIcon} alt="" className="w-full h-full" />
        <div className="absolute w-full h-full top-0 left-0 flex justify-center items-center cursor-pointer">
          <img src={pauseIcon} alt="" className="w-[22px] h-[30px]" />
        </div>
        <div className="absolute bottom-3 right-3 px-2 py-1 rounded text-white text-[10px] bg-[#00A32E61]">
                    02:20
        </div>
        <SvgCom Svg={DeleteSvg} size="28" className="absolute bottom-2.5 left-2 cursor-pointer"></SvgCom>
      </div>
    </div>
  );
};

export default UploadWinCom;