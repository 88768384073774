class EventBus {
  constructor() {
    this.bus = document.createElement("PainPointsElement");
  }

  addEventListener(event,callback) {
    this.bus.addEventListener(event,callback)
  }

  removeEventListener(event,callback) {
    this.bus.removeEventListener(event,callback)
  }

  //触发执行
  dispatchEvent(event,detail={}){
    this.bus.dispatchEvent(new CustomEvent(event,{detail}));
  }
}

export default new EventBus();
