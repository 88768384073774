import React, { useEffect, useState } from 'react';
import { Avatar, List, message } from 'antd';
import VirtualList from 'rc-virtual-list';


const App = ({data}) => {
  // let [data, setData] = useState([]);
  let ContainerHeight = 420;

  // const appendData = () => {
    // fetch(fakeDataUrl)
    //   .then((res) => res.json())
    //   .then((body) => {
    //     setData(data.concat(body.results));
    //     message.success(`${body.results.length} more items loaded!`);
    //   });
  // };
  useEffect(() => {
    // appendData();
  }, []);
  const onScroll = (e) => {
    // Refer to: https://developer.mozilla.org/en-US/docs/Web/API/Element/scrollHeight#problems_and_solutions
    if (Math.abs(e.currentTarget.scrollHeight - e.currentTarget.scrollTop - ContainerHeight) <= 1) {
      // appendData();
    }
  };
  return (<div style={{ padding: '0 20px' }}>
      <List
        split={false}
      >
        <VirtualList
          data={data}
          height={ContainerHeight}
          itemHeight={40}
          itemKey="name"
          onScroll={onScroll}
          styles={{verticalScrollBarThumb:{background:'#02661c'}}}
        >
          {(item) => (
            <List.Item style={{ color: 'rgb(72,158,64)' }}>{item.content}</List.Item>
          )}
        </VirtualList>
      </List>
    </div>

  );
};

// <List
//   size="small"
//   dataSource={dataC}
//   split={false}
//   renderItem={(item) => <List.Item>{item}</List.Item>}
//   style={{  color: 'rgb(72,158,64)' }}
// />
export default App;