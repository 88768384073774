const LayerMiddleDataUK = {
  'HEALTHIER LIVING': [
    {
      name: 'Irresistible Vegetable',
    },
    {
      name: 'Feel Good Food',
    },
    {
      name: 'Plant-Powered Protein',
    },
  ],
  'SMART SPENDING': [
    {
      name: 'Low Waste Menus'
    }
  ],
  'INDULGING EXPERIENCES': [
    {
      name: 'Flavor Shock'
    }
  ],
  'SMART SOLUTIONS': [
  ],
  'COOKING RENAISSANCE': [
    {
      name: 'Modernized Comfort Food'
    },
    {
      name: 'The New Sharing'
    }
  ],
  'ANYTIME ANYWHERE': [],
  'CONSCIOUS CHOICES': [
    {
      name: 'Local Abundance'
    }
  ],
  'DIASPORAS': [],
  // 专门针对US
  // 等价于前面 INDULGING EXPERIENCES
  'FLAVOR EXPLORATION': [
    {
      name: 'Flavor Shock'
    }
  ],
  // 专门针对US
  // 等价于前面 SMART SPENDING
  'SMART COOKING AND SPENDING': [
    {
      name: 'Low Waste Menus'
    }
  ],
};

export default LayerMiddleDataUK;