import { Input } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import PenSvg from '../assets/img/pen.svg';
import style from './editableText.module.scss';

function PackageAiEditableText({ className = '', value, onChange }) {
  const [isEdit, setIsEdit] = useState(false);
  const inputRef = useRef(null);

  useEffect(() => {
    if (!inputRef.current) return;
    inputRef.current.focus();
  }, [inputRef.current]);

  function onInputChange(ev) {
    if (!ev.target) return;
    onChange && onChange(ev.target.value);
  }

  return (
    <div
      className={`${style.container} ${className}`}
      onClick={() => setIsEdit(true)}
      onBlur={() => setIsEdit(false)}
    >
      {isEdit ? (
        <Input
          ref={inputRef}
          autoFocus={true}
          value={value}
          onChange={onInputChange}
        />
      ) : (
        <div className={style.header}>
          <img className={style.penImg} src={PenSvg} alt="Edit" />
          <div className={style.headerBody}>{value}</div>
        </div>
      )}
    </div>
  );
}

export default PackageAiEditableText;
