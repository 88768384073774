// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Q4dFym_Ytr03TaLlQoZT{padding:10px}.VAITm7KWfCYZt10SSWbE{width:80vw;height:80vh;object-fit:contain}.TfcsZmb5LkFP_Y0vm9wb{margin-top:10px;font-size:16px;font-weight:400;line-height:22.3px;text-align:center;color:#fff}`, "",{"version":3,"sources":["webpack://./src/modules/package-ai-screening/views/basic/help/index.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CAGF,sBACE,UAAA,CACA,WAAA,CACA,kBAAA,CAGF,sBACE,eAAA,CACA,cAAA,CACA,eAAA,CACA,kBAAA,CACA,iBAAA,CACA,UAAA","sourcesContent":[".box1 {\n  padding: 10px;\n}\n\n.video {\n  width: 80vw;\n  height: 80vh;\n  object-fit: contain;\n}\n\n.text1 {\n  margin-top: 10px;\n  font-size: 16px;\n  font-weight: 400;\n  line-height: 22.3px;\n  text-align: center;\n  color: #fff;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"box1": `Q4dFym_Ytr03TaLlQoZT`,
	"video": `VAITm7KWfCYZt10SSWbE`,
	"text1": `TfcsZmb5LkFP_Y0vm9wb`
};
export default ___CSS_LOADER_EXPORT___;
