import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import React, { useCallback } from 'react';
import style from './item.module.scss';

function PackageAiItem({ data, onClick, onUpdate, onDelete }) {
  const onEditClick = useCallback(
    (ev) => {
      ev.stopPropagation();
      onUpdate && onUpdate(data);
    },
    [onUpdate, data]
  );
  const onDeleteClick = useCallback(
    (ev) => {
      ev.stopPropagation();
      onDelete && onDelete(data.id);
    },
    [onDelete, data]
  );

  return (
    <div className={style.itemBox} onClick={(ev) => onClick(ev, data)}>
      <img
        src={data.url}
        alt={data.title}
        loading="lazy"
        crossOrigin="anonymous"
      />
      <div className={style.actionBox}>
        <div className={style.btn} onClick={onEditClick}>
          <EditOutlined className={style.icon} />
        </div>
        <div className={style.btn} onClick={onDeleteClick}>
          <DeleteOutlined className={style.icon} />
        </div>
      </div>
    </div>
  );
}

export default PackageAiItem;
