import React, { createContext, useContext, useState } from 'react';

const AskGptContext = createContext(null);

export const AskGptProvider = ({children}) => {
  const [filterByCT, setFilterByCT] = useState('');

  return <AskGptContext.Provider value={{
    filterByCT,
    setFilterByCT
  }}>
    {children}
  </AskGptContext.Provider>;
};

export const useAskGpt = () => {
  return useContext(AskGptContext) || {
    setFilterByCT: () => {}
  };
};