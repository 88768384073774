import classNames from 'classnames';
import React, { useCallback } from 'react';
import { useRef } from 'react';
import HelpMp4 from '../../../assets/video/help.mp4';
import Dialog from '../../../components/dialog.jsx';
import style from './index.module.scss';

function PackageAiHelp({ className, visible, setVisible }) {
  const videoRef = useRef();

  const onClose = useCallback(() => {
    if (!videoRef.current) return;

    videoRef.current.pause();
  }, [videoRef, videoRef.current]);

  const onVisibleChange = useCallback(
    (val) => {
      if (!val) onClose();
      setVisible(val);
    },
    [onClose, setVisible]
  );

  return (
    <Dialog
      className={classNames(style.dialog, className)}
      visible={visible}
      setVisible={onVisibleChange}
    >
      <div className={style.box1}>
        <video
          ref={videoRef}
          className={style.video}
          src={HelpMp4}
          controls={true}
        ></video>
        <div className={style.text1}>
          This module is available for CMI use only.
          <br />
          Please contact your local CMI partner to conduct the Pack Screening
          test.
        </div>
      </div>
    </Dialog>
  );
}

export default PackageAiHelp;
