// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.OxkVGF9OMBvjSO1I7Z39{height:100%;border-radius:8px;background:#061606;display:flex;flex-direction:column;align-items:center;justify-content:center}.OxkVGF9OMBvjSO1I7Z39 .zGTcIQe4tM_dMsmhgu28 img{width:32px;height:32px}.OxkVGF9OMBvjSO1I7Z39 .d33iY7Bz_T4tZyXvsRDj{color:#00a32e;font-size:14px;margin-top:10px}`, "",{"version":3,"sources":["webpack://./src/modules/ratings-review/components/no-data/no-data.module.scss"],"names":[],"mappings":"AAAA,sBACE,WAAA,CACA,iBAAA,CACA,kBAAA,CACA,YAAA,CACA,qBAAA,CACA,kBAAA,CACA,sBAAA,CACA,gDACE,UAAA,CACA,WAAA,CAEF,4CACE,aAAA,CACA,cAAA,CACA,eAAA","sourcesContent":[".noData {\n  height: 100%;\n  border-radius: 8px;\n  background: #061606;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  .noDataImg img {\n    width: 32px;\n    height: 32px;\n  }\n  .noDataMsg {\n    color: #00A32E;\n    font-size: 14px;\n    margin-top: 10px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"noData": `OxkVGF9OMBvjSO1I7Z39`,
	"noDataImg": `zGTcIQe4tM_dMsmhgu28`,
	"noDataMsg": `d33iY7Bz_T4tZyXvsRDj`
};
export default ___CSS_LOADER_EXPORT___;
