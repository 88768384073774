import React from 'react';
import style from './box.module.scss';

function PackageAiBox({ className = '', children, title, bgc = '#00000099' }) {
  return (
    <div className={`${style.box} ${className}`}>
      <div className={style.boxHeader}>{title}</div>
      <div className={style.boxBody} style={{ backgroundColor: bgc }}>
        {children}
      </div>
    </div>
  );
}

export default PackageAiBox;
