import React from 'react';
import { cn } from '../../utils/utils';

const CardCom = ({ className = '', label, children, rightNode = <></>}) => {
  return (
    <div className={cn('rounded-xl overflow-hidden', className)}>
      <div className="bg-second px-5 py-[15px] text-primary text-base leading-none font-semibold flex items-center justify-between">
        <span>{label}</span>
        <div>
          {rightNode}
        </div>
      </div>
      <div className="p-5 bg-grey-1">
        {children}
      </div>
    </div>
  );
};

export default CardCom;