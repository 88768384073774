import React from 'react';
import style from './title.module.scss';
import TitlePng from '../../../assets/images/home/title.png';

function OccasionHomeTitle({ className }) {
  return (
    <h1 className={`${style.titleContainer} ${className ?? ''}`}>
      <img className={style.titleImg} src={TitlePng} alt="occasions module" />
    </h1>
  );
}

export default OccasionHomeTitle;
