import classNames from 'classnames';
import React, { useEffect, useMemo, useRef } from 'react';
import PackageAiImg from '../../../components/img/index.jsx';
import style from './chart.module.scss';

function ChartBar({
  className,
  value,
  value2,
  value3,
  rank,
  showValue = false,
}) {
  const colorCls = useMemo(() => {
    if (rank < 0.3) {
      return style.dataGreen;
    } else if (rank < 0.7) {
      return style.dataYellow;
    } else {
      return style.dataRed;
    }
  }, [rank]);

  return (
    <div className={classNames(style.chartBarBox, className)}>
      <div className={style.chartBarValue2}>{`${value2.toFixed(0)}`}</div>
      <div className={classNames(style.chartBar, colorCls)}>
        <div className={style.bar} style={{ width: `${value3}%` }}>
          {showValue && (
            <div className={style.text}>{`${value.toFixed(1)}%`}</div>
          )}
        </div>
      </div>
    </div>
  );
}

function ChartRow({ className, item, showValue, onImageClick }) {
  return (
    <div className={classNames(style.chartRow, className)}>
      <div className={style.chartRowImage}>
        <PackageAiImg
          src={item.cover}
          // alt={item.name}
          onClick={() => {
            onImageClick(item.cover);
          }}
        />
        <div className={style.chartRowName} title={item.name}>
          {item.name}
        </div>
      </div>
      <div className={style.chartRowBox1}>
        <ChartBar
          value={item.brand}
          value2={item.brand2}
          value3={item.brand3}
          rank={item.brandRank}
          showValue={showValue}
        />
        <ChartBar
          value={item.appeal}
          value2={item.appeal2}
          value3={item.appeal3}
          rank={item.appealRank}
          showValue={showValue}
        />
        <ChartBar
          value={item.upi}
          value2={item.upi2}
          value3={item.upi3}
          rank={item.upiRank}
          showValue={showValue}
        />
      </div>
    </div>
  );
}

function Chart({ setRef, className, data, showValue, onImageClick }) {
  const chartRef = useRef();
  useEffect(() => {
    setRef && setRef(chartRef.current);
  }, [setRef, chartRef.current]);

  return (
    <div ref={chartRef} className={classNames(style.chart, className)}>
      {data.map((e) => {
        return (
          <ChartRow
            key={e.key}
            item={e}
            showValue={showValue}
            onImageClick={onImageClick}
          />
        );
      })}
    </div>
  );
}

export default Chart;
