import React, { useState, useEffect } from 'react';
import ConsumerTheaterPage from './pages/consumer-theater-page.jsx';
import { ConfigProvider, theme, App } from 'antd';
import { useLocation } from 'react-router-dom';
import AIChatForConsumer from '../consumer-theater-chat/index.jsx';
import { AskGptProvider } from '../consumer-theater-chat/hooks/useAskGPT.jsx';

const ConsumerTheater = () => {
  const location = useLocation();
  const [token, ] = useState(sessionStorage.getItem('jwt-access-token'));

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const queryToken = queryParams.get('queryToken');
    if (!queryToken) {
      setTimeout(() => {
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        });
      }, 500);
    }
  });
  
  if (!token) {
    return null;
  }

  return (
    <>
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: '#00a32e',
            colorError: '#eb0000',
            colorSuccess: '#06ca3d',
            fontFamily: 'unilever-shilling-regular'
          },
          algorithm: theme.darkAlgorithm
        }}
      >
        <AskGptProvider>
          <App>
            <ConsumerTheaterPage />
            { /* ASK GPT */}
            <AIChatForConsumer></AIChatForConsumer>
          </App>
        </AskGptProvider>
      </ConfigProvider>
    </>
  );
};

export default ConsumerTheater;
