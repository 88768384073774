import service from '../../../../util/pythonApi';

export function postFile(file, prefix = 'package-ai-screening/rawimage') {
  const data = new FormData();
  data.append('prefix', prefix);
  data.append('form', 'form');
  data.append('file', file);
  return service.request({
    url: '/v1/azure_file',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
}

export async function putFileKey(fileKey) {
  const res = await service.request({
    url: '/v1/azure_file',
    method: 'put',
    params: {
      file_key: fileKey,
    },
  });
  const url = res.data.url;
  if (url) {
    const u = new URL(url);
    u.searchParams.append('t', Date.now().toString());
    res.data.url = u.toString();
  }
  return res;
}
