import { message } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import ArrowLeft from '../../../../assets/images/svg/arrow-left.svg';
import ArrowRight from '../../../../assets/images/svg/arrow-right.svg';
import CloseSvg from '../../../../assets/images/svg/close-icon.svg';
import style from './photo.module.scss';
import { getImageDetail } from '../../apis/serve/images';

function PhotoCellDetailInfo({ label, value }) {
  return (
    <div className={style.cellDetailInfoLine}>
      <span className={style.cellDetailInfoLabel}>{label}</span>
      <span className={style.cellDetailInfoValue}>{value}</span>
    </div>
  );
}

function PhotoCellDetail({ data, onClose, onNext, onPrev }) {
  const [loading, setLoading] = useState(false);
  const [props, setProps] = useState({});

  async function readProps() {
    if (!data.id) return;
    if (loading) return;
    setLoading(true);
    try {
      const { data: res } = await getImageDetail(data.id);
      if (!res) return;
      const { props: pps } = res;
      const pp = {};
      for (const prop of pps) {
        if (pp[prop.prop]) {
          pp[prop.prop] += ', ' + prop.value;
        } else {
          pp[prop.prop] = prop.value;
        }
      }
      setProps(pp);
    } catch (err) {
      console.error('read props error', err);
      message.error('Read props failed');
    }
    setLoading(false);
  }

  useEffect(() => {
    void readProps();
  }, [data]);

  const title = useMemo(() => {
    return `${data.user_gender},${data.user_age},${data.user_location}`;
  }, [data]);

  return (
    <div className={style.cellDetailModal} onClick={onClose}>
      <div className={style.cellDetailPrev} onClick={onPrev}>
        <img src={ArrowLeft} alt="prev" />
      </div>
      <div className={style.cellDetailNext} onClick={onNext}>
        <img src={ArrowRight} alt="next" />
      </div>
      <div
        className={style.cellDetailContainer}
        onClick={(ev) => ev.stopPropagation()}
      >
        <div className={style.cellDetailClose} onClick={onClose}>
          <img src={CloseSvg} alt="close" />
        </div>
        <div className={style.cellDetailImgBox}>
          <img src={data.url} />
        </div>
        <div className={style.cellDetailInfoBox}>
          <h2>{title}</h2>
          <PhotoCellDetailInfo label="Market:" value={data.user_location} />
          <PhotoCellDetailInfo label="Gender:" value={data.user_gender} />
          <PhotoCellDetailInfo label="Age:" value={data.user_age} />
          {props.user_employment_status && (
            <PhotoCellDetailInfo
              label="Employment status:"
              value={props.user_employment_status}
            />
          )}
          {props.demog_income_all_bands && (
            <PhotoCellDetailInfo
              label="Income brackets:"
              value={props.demog_income_all_bands}
            />
          )}
          {props.activity_now && (
            <PhotoCellDetailInfo
              label="Activities:"
              value={props.activity_now}
            />
          )}
          {props.consumption_meal_type && (
            <PhotoCellDetailInfo
              label="Meal type:"
              value={props.consumption_meal_type}
            />
          )}
          {props.consumption_beverage_accompaniment_yesno && (
            <PhotoCellDetailInfo
              label="Beverage with food:"
              value={props.consumption_beverage_accompaniment_yesno}
            />
          )}
          {props.demand_space && (
            <PhotoCellDetailInfo label="Occasion:" value={props.demand_space} />
          )}
        </div>
      </div>
    </div>
  );
}

export default PhotoCellDetail;
