import { App, ConfigProvider, theme } from 'antd';
import React from 'react';
import TitlePng from '../assets/img/title.png';
import style from './container.module.scss';

function PackageAiContainer({ className = '', children }) {
  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: '#00a32e',
          colorError: '#eb0000',
          colorSuccess: '#06ca3d',
          fontFamily: 'unilever-shilling-regular',
        },
        algorithm: theme.darkAlgorithm,
      }}
    >
      <App>
        <div className={`${style.container} ${className}`}>
          <div className={style.containerHeader}>
            <a href="/package-ai-screening-batch">
              <img src={TitlePng} alt="Unmissable Pack AI" />
            </a>
          </div>
          <div className={style.containerBody}>{children}</div>
        </div>
      </App>
    </ConfigProvider>
  );
}

export default PackageAiContainer;
