const IrresistibleVegetable = {
  name: 'Irresistible Vegetable',
  desc: `Plant-forward experiences that are decadent, moreish, indulgent and
  that don’t compromise on experience. UFS’ Why: ‘Boldly Healthy’
  meets sustainability; championing chefs to make plant-forward the
  norm by showing that there is no need to compromise Core Operator
  benefit: To show that true culinary skill can transform any
  ingredient into its indulgent best Core Consumer motivation:
  “Inspire me to explore plant-forward/ plant-based without any
  compromise on flavour or enjoyment”`,
  img: 'irresisitblevegetables.jpg',
  imagePath: 'Irresistible'
};

const FeelGoodFood= {
  name: 'Feel Good Food',
  desc: `Personally positive food choices that are delicious and naturally
  promote balance in mood, body and spirit. To champion Boldly
  Healthier foods that are delicious and truly enhance your own sense
  of wellbeing. UFS’ Why: Core Operator benefit: To satisfy their
  diners’ appetite for dishes and foods that make them feel good and
  yet are fabulously tasty. Core Consumer motivation: “You are what
  you eat. I am learning that what I eat and when can have a huge
  impact on how I feel and perform, yet when I eat out I also want to
  indulge!”`,
  img: 'feelgoodfood.jpg',
  imagePath: 'feelgoodfood'
};

const FlavourContrasts = {
  name: 'Flavor Shock',
  desc: `Dining experiences that spark maximal sensory engagement and deep
  satisfaction UFS’ Why:To demonstrate our commitment to making food
  OOH exciting and engaging. Core Operator benefit: The inspiration to
  create new taste and texture experiences that really entertain and
  get people talking. Core Consumer motivation:“Dining at home I have
  really missed how gripping and entertaining food can be. It’s a
  show!”`,
  img: 'sensoryboom.jpg',
  imagePath: 'flavourcontrasts'
};

const JoyfulSharing = {
  name: 'The New Sharing',
  desc: `Food that is a simple celebration of life and being together…diverse
  and tasty, playful, fun and highly inclusive. UFS’ Why: To celebrate
  and elevate the essential role OOH plays in bringing people and
  communities together. Core Operator benefit: Finding exciting new
  ways to heighten the essential joy and experience of sharing food.
  Core Consumer motivation:“I want food to be a reminder to make time
  to laugh together, smile and enjoy the simple things in life.”`,
  img: 'joyfulsharing.jpg',
  imagePath: 'joyfulsharing'
};

const LowWasteMenus = {
  name: 'Low Waste Menus',
  desc: `Dishes that make smart use of ingredients and resources to help
  reduce waste, make food go further and give the diner a positive
  feeling UFS’ Why: To make food waste reduction and upcycling a more
  integrated part of the food system. Core Operator benefit: To
  demonstrate love, care and respect for the ingredients and suppliers
  you work with. Core Consumer motivation: “I really hate the idea of
  food waste and I look for ways to eat delicious food without
  compromise.”`,
  img: 'lowwastemenu.jpg',
  imagePath: 'lowwastemenus'
};

const MindfulProteins = {
  name: 'Plant-Powered Protein',
  desc: `Dishes that mix and match alternative protein sources, potentially
  alongside small amounts of sustainable animal protein to promote
  healthfulness and sustainability. UFS’ Why:To promote personally and
  environmentally positive and sustainable habits and practices. Core
  Operator benefit: To be a creative force in the movement for greater
  balance, health and sustainability. Championing sustainable meat
  alongside protein alternatives Core Consumer motivation: “I know I
  should reduce my meat consumption but I don’t want to sacrifice on
  taste and pleasure.”`,
  img: 'mindfulprotein.jpg',
  imagePath: 'mindfulprotein'
};

const ModernizedComfortFood = {
  name: 'Modernized Comfort Food',
  desc: `Dishes and techniques that cherish and revalue some of our most
  humble yet nourishing food traditions and stories UFS’ Why: To
  celebrate the deep and iconic role of timeless dishes and
  ingredients in food culture all around the world. Core Operator
  benefit: To show my cultural roots and culinary talent through
  incredible creations from the humblest of ingredients. Core
  motivation: ”I am looking for comforting foods that give me a sense
  of fulfilment and nourishment and connects to my story.”`,
  img: 'modernizedcomfortfood.jpg',
  imagePath: 'modernizedcomfortfood'
};

const WildPure = {
  name: 'Wild & Pure',
  desc: `Experience the scavenged, the foraged and the wild – truly new
  frontiers of what nature has to offer, brought to your plate. UFS’
  Why: To reconnect people with nature, to champion food diversity and
  encourage us to revalue a more sustainable food system. Core
  Operator benefit: To creatively use new ingredients that showcase
  nature’s benefits and specific tastes. Core Consumer motivation: “I
  really value food that has a timeless feel to it, closer to the
  land, less refined and industrial.”`,
  img: 'wildandpure.jpg',
  imagePath: 'wildandpure'
};

const keyMap = {
  'Irresistible Vegetable': IrresistibleVegetable,
  'Feel Good Food': FeelGoodFood,
  'Flavor Shock': FlavourContrasts,
  'The New Sharing': JoyfulSharing,
  'Low Waste Menus':LowWasteMenus,
  'Plant-Powered Protein':MindfulProteins,
  'Modernized Comfort Food': ModernizedComfortFood,
  // 'Wild & Pure': WildPure,
};

const solutionTrendsMapData = (type) => {
  return keyMap[type] ? keyMap[type] : null;
};

export default solutionTrendsMapData;