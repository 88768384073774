import pythonApi from '../util/pythonApi.js';

export function login(data) {
  return pythonApi({
    url: '/v1/auth/login',
    method: 'post',
    data,
  });
}

// me API
export function getUser() {
  return pythonApi({
    url: '/v1/me',
    method: 'get',
  });
}
