/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef, useMemo, useCallback } from 'react';
import LeftSidebarCollapsible from '../../../components/left-navigation/left-navigation.jsx';
import HeaderNav from '../components/header-nav/header-nav.jsx';
import style from './trends-dial-page.module.scss';
import bgVideo from '../../../assets/videos/trend-dial-video.mp4';
import { App } from 'antd';
import { Select } from 'antd';
import SideDetail from '../components/side-detail/side-detail.jsx';
import TrendsCharts from '../components/trends-charts/trends-charts.jsx';
import SolutionTrends from '../components/solution-trends/solution-trends.jsx';
import ConsumerTrends from '../components/consumer-trends/consumer-trends.jsx';
import classNames from 'classnames';
import solutionTrendsMapData from '../data/solution-trends.js';
import { fetchSizingAll } from '../api/request.js';
import IntroModal from '../components/intro-modal/intro-modal.jsx';

let message;

const TrendsDialPage = () => {
  const staticFunction = App.useApp();
  message = staticFunction.message;

  const [showScrollBtn, setShowScrollBtn] = useState(true);

  // 监听滚动 显示按钮
  function scrollCompute() {
    const winHeight = document.documentElement.clientHeight;
    const scrollTop = document.documentElement.scrollTop;
    if (scrollTop < winHeight * 0.3) {
      setShowScrollBtn(true);
    } else {
      setShowScrollBtn(false);
    }
  }
  // 注册滚动事件
  useEffect(() => {
    window.addEventListener('scroll', scrollCompute);
    scrollCompute();
    return () => {
      window.removeEventListener('scroll', scrollCompute);
    };
  }, []);
  // 显示滚动内容
  const showScrollContent = () => {
    const winHeight = document.documentElement.clientHeight;
    if (document.documentElement.scrollTop < winHeight * 0.7) {
      setTimeout(() => {
        window.scrollTo({
          top: winHeight * 0.7,
          behavior: 'smooth'
        });
      }, 500);
    }
  };

  const trendChartRef = useRef(null);

  // 所有数据
  const [allData, setAllData] = useState([]);
  // 国家
  const [countryList, setCountryList] = useState([]);
  const [countryValue, setCountryValue] = useState('');
  // nutrition 选项
  const [nutritionList, setNutritionList] = useState([]);
  const [currentNutrition, setCurrentNutrition] = useState('');
  const changeCurrentNutrition = (nutrition) => {
    const nutritionOption = nutritionList.find(item => item.value === nutrition).children;
    setCurrentNutrition(nutrition);
    trendChartRef.current.changeNutrition(nutrition, nutritionOption, countryValue);
  };
  
  // 选择国家
  const setCurrentCountryFn = (countryCode) => {
    SideDetailClose();
    setCountryValue(countryCode);
    const currentCountryOption = allData.find(item => item.country === (countryCode === 'GB' ? 'UK' : countryCode));
    const optionList = currentCountryOption.children.map(item => ({ label: item.category, value: item.category, children: item.children }));
    setNutritionList(optionList);
  };

  useEffect(() => {
    if (countryList.length > 0) return;
    fetchSizingAll()
      .then(res => {
        const countries = res.data.map(item => {
          return {
            label: item.country, 
            // 前端展示用UK， 其他地方查询用GB
            value: item.country === 'UK' ? 'GB' : item.country 
          };
          // 目前只放开 GB（UK）、US、BR, FR, ID 五个国家
          // 新增加4个 MX、PH、DE、IN
        }).filter(item => ['GB', 'US', 'BR', 'FR', 'ID', 'MX', 'PH', 'DE', 'IN'].includes(item.value));
        setAllData(res.data);
        setCountryList(countries);
      })
      .catch(error => console.error(error));
  }, [countryList]);

  useEffect(() => {
    if (allData.length === 0) return;
    const queryParams = new URLSearchParams(location.search);
    const queryToken = queryParams.get('queryToken');
    if (!queryToken) {
      // 设置默认国家
      setCurrentCountryFn('GB');
      return;
    };
    // 分享解析
    let params = {};
    try {
      const paramsStr = window.decodeURIComponent(queryToken);
      params = JSON.parse(paramsStr);
    } catch (error) {
      console.error('URL parse error: ', error);
    }
    console.log(params);
    setCurrentCountryFn(params.country);
    solutionClick(params.type);
      
  }, [allData]);

  useEffect(() => {
    if (nutritionList.length === 0) return;
    changeCurrentNutrition(nutritionList[0].value);
  }, [nutritionList]);

  // 侧边弹窗
  const SideTrendsDialRef = useRef(null);
  const [show, setShow] = useState(false);
  const [sideDetailData, setSideDetailData] = useState({
    upperTitle: '',
    title: '',
    cagr: {
      value: 0,
      date: ''
    },
    yoy: {
      value: 0,
      date: ''
    },
    solutionTrends: [],
    consumerTrends: []
  });

  const toggleSideDetail = useMemo(() => {
    return function toggle(val) {
      const title = val?.title;
      if (title) {
        setShow(true);
        setSideDetailData(val);
      } else {
        setShow(false);
      }
    };
  }, [SideTrendsDialRef]);

  // 侧边弹窗关闭回调
  const SideDetailClose = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
    setScrollPageType('');
    setShow(false);
  };

  // 侧边点击去详情
  // solution
  // consumer
  const [scrollPageType, setScrollPageType] = useState('');
  const [SolutionTrendType, setSolutionTrendType] = useState('');

  const consumerTrendRef = useRef(null);

  function solutionClick(typeName) {
    if (solutionTrendsMapData(typeName)) {
      setScrollPageType('solution');
      setSolutionTrendType(typeName);
      showScrollContent();
    }
  }
  function consumerClick(typeName) {
    setScrollPageType('consumer');
    showScrollContent();
    setTimeout(() => {
      consumerTrendRef.current.setData({
        title: sideDetailData.upperTitle,
        consumerTrend: typeName
      });
    });
  }

  // intro page
  const [introOpened, setIntroOpened] = useState(false);
  const introOpenHandle = () => {
    setScrollPageType('');
    setIntroOpened(!introOpened);
  };

  return (
    <>
      <LeftSidebarCollapsible />
      <div className={style.fixedContent}>
        {/* 背景视频 */}
        <div className={style.videoBox}>
          <video className={classNames(style.videoBackground)} autoPlay="autoPlay" muted={true} loop="loop">
            <source src={bgVideo} />
          </video>
        </div>
        <div className={classNames(style.homeContent, {[style.introOpened]: introOpened})}>
          <SideDetail
            ref={SideTrendsDialRef}
            show={show}
            sideDetailData={sideDetailData}
            country={countryValue}
            closeCallback={() => SideDetailClose()}
            solutionClick={(item) => solutionClick(item)}
            consumerClick={(item) => consumerClick(item)} />
          <HeaderNav>
            <div className={style.inputBorder}>
              <Select
                placeholder='Country'
                maxTagCount={1}
                value={countryValue}
                onChange={(newValue) => {
                  setCurrentCountryFn(newValue);
                }}
                options={countryList}
                popupMatchSelectWidth={false}
                listHeight={350}
              />
            </div>
            <div className={classNames(style.inputBorder, style.marginTop20)}>
              <Select
                placeholder='Nutrition'
                maxTagCount={1}
                value={currentNutrition}
                onChange={(newValue) => {
                  changeCurrentNutrition(newValue);
                }}
                options={nutritionList}
                popupMatchSelectWidth={false}
                listHeight={350}
              />
            </div>
          </HeaderNav>
          <div className={classNames(style.chartsBox, show && style.show)}>
            <TrendsCharts ref={trendChartRef} toggleSideDetail={toggleSideDetail} country={countryValue} />
          </div>
          <div className={style.openIntroPageBtn} onClick={introOpenHandle}>
            <div className={style.openBtnBg}></div>
            <div className={classNames(style.btnText, style.open)}>Trend<br/>Reports</div>
            <svg className={style.btnIcon} xmlns="http://www.w3.org/2000/svg" width="36" height="32" viewBox="0 0 36 32" fill="none">
              <g opacity="0.8">
                <path d="M18.75 12.1337L12.6 6.66699L10.5 8.66699L18.75 16.0003L27 8.66699L24.9 6.66699L18.75 12.1337ZM18.75 20.267L12.6 14.8003L10.5 16.667L18.75 24.0003L27 16.667L24.9 14.8003L18.75 20.267Z" fill="#00A32E"/>
              </g>
            </svg>
            <div className={classNames(style.btnText, style.close)}>Back to<br/> Trends Dial</div>
          </div>
          <IntroModal />
        </div>
      </div>
      {scrollPageType && <div className={style.TrendsDialPage}>
        {/* {showScrollBtn && <div className={style.scrollToTopContainer} onClick={showScrollContent}>
          <div className={style.scrollToTop}></div>
        </div>} */}
        { scrollPageType === 'solution' && <SolutionTrends type={SolutionTrendType} country={countryValue} />}
        { scrollPageType === 'consumer' && 
          <ConsumerTrends 
            ref={consumerTrendRef}
            country={countryValue} 
          />
        }
      </div>}
      {/* <div className={style.disclaimer}>
        <p>The information shown in this platform is solely for internal Unilever usage.</p>
        <p>Please do not share or re-work any of the images and videos or other potentially sensitive information with third parties.</p>
        <p>Copyright © 2023  Unilever</p>
      </div> */}
    </>
  );
};

export default TrendsDialPage;

export { message };
