// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.gbdzLHaRCeEYZRfZdWPu{position:relative;height:100%}.XcY3N85ozwRTEE6QHNPP{position:absolute;top:20px;right:20px;display:flex;align-items:center;z-index:1001}.xFcpP7VwRiZbz_urIRBc{margin-left:10px;color:#00a32e;cursor:pointer}.tYvMExB9VQZSnouGwp9K{position:absolute;bottom:20px;right:20px}.XIu6CTCBg0Aw5NjDfNkF{font-size:10px;font-weight:400;text-align:right;color:#fff}.aakRA8NkVcqCNt6hfa4T{height:100%;width:100%;overflow:auto;padding:50px}`, "",{"version":3,"sources":["webpack://./src/modules/package-ai-performance/views/tree/index.module.scss"],"names":[],"mappings":"AAAA,sBACE,iBAAA,CACA,WAAA,CAGF,sBACE,iBAAA,CACA,QAAA,CACA,UAAA,CACA,YAAA,CACA,kBAAA,CACA,YAAA,CAGF,sBACE,gBAAA,CACA,aAAA,CACA,cAAA,CAGF,sBACE,iBAAA,CACA,WAAA,CACA,UAAA,CAGF,sBACE,cAAA,CACA,eAAA,CACA,gBAAA,CACA,UAAA,CAGF,sBACE,WAAA,CACA,UAAA,CACA,aAAA,CACA,YAAA","sourcesContent":[".box1 {\n  position: relative;\n  height: 100%;\n}\n\n.box11 {\n  position: absolute;\n  top: 20px;\n  right: 20px;\n  display: flex;\n  align-items: center;\n  z-index: 1001;\n}\n\n.zoomBtn {\n  margin-left: 10px;\n  color: #00a32e;\n  cursor: pointer;\n}\n\n.box12 {\n  position: absolute;\n  bottom: 20px;\n  right: 20px;\n}\n\n.infoText {\n  font-size: 10px;\n  font-weight: 400;\n  text-align: right;\n  color: #fff;\n}\n\n.box13 {\n  height: 100%;\n  width: 100%;\n  overflow: auto;\n  padding: 50px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"box1": `gbdzLHaRCeEYZRfZdWPu`,
	"box11": `XcY3N85ozwRTEE6QHNPP`,
	"zoomBtn": `xFcpP7VwRiZbz_urIRBc`,
	"box12": `tYvMExB9VQZSnouGwp9K`,
	"infoText": `XIu6CTCBg0Aw5NjDfNkF`,
	"box13": `aakRA8NkVcqCNt6hfa4T`
};
export default ___CSS_LOADER_EXPORT___;
