import { App, ConfigProvider, theme } from 'antd';
import React, { useEffect } from 'react';
import { ModalProvider } from '../components/context/context.jsx';
import MainView from './main/main.jsx';

const PackageAi = () => {
  useEffect(() => {
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }, 500);
  });

  return (
    <>
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: '#00a32e',
            colorError: '#eb0000',
            colorSuccess: '#06ca3d',
            fontFamily: 'unilever-shilling-regular',
          },
          components: {},
          algorithm: theme.darkAlgorithm,
        }}
      >
        <App>
          <ModalProvider>
            <MainView />
          </ModalProvider>
        </App>
      </ConfigProvider>
    </>
  );
};

export default PackageAi;
