import { CloseOutlined, PlusOutlined, UploadOutlined } from '@ant-design/icons';
import { Button, Cascader, Form, Input, Select, message } from 'antd';
import React, { useMemo, useRef, useState } from 'react';
import { postFile } from '../apis/file/index.js';
import { postKnorr } from '../apis/serve/index.js';
import CategoryJson from '../assets/json/category.json';
import MarketJson from '../assets/json/market.json';
import KnorrDrop from './drop.jsx';
import style from './upload.module.scss';
import DefaultPng from '../assets/img/default.png';

const { Item } = Form;

const imagePattern = /image\/.*/;
const videoPattern = /video\/.*/;

const Categories = CategoryJson.map((e) => ({ label: e, value: e }));
const Markets = MarketJson.map((e) => {
  return {
    label: e.region,
    value: e.region,
    children: e.markets.map((e1) => {
      return {
        label: e1,
        value: e1,
      };
    }),
  };
});

function KnrorrUploadBtnDropItem(media, onDelete) {
  return (
    <div className={style.uploadFormUploadBtnDropItem} key={media}>
      <span className={style.uploadFormUploadBtnDropItemText}>{media}</span>
      <CloseOutlined onClick={() => onDelete(media)}></CloseOutlined>
    </div>
  );
}

/**
 * @param {()=>void|undefined} onUpload
 */
function KnorrUploadForm(onUpload) {
  const [loading, setLoading] = useState(false);

  const inputRef = useRef(null);
  const [media, setMedia] = useState({});
  function onUploadBtnClick(ev) {
    ev.stopPropagation();
    if (!inputRef.current) return;
    inputRef.current.click();
  }
  function onUploadChange(ev) {
    if (!ev.target.files) return;
    let type = 'unknown';
    if (!ev.target.files.length) {
      return;
    } else if (ev.target.files.length === 1) {
      const f = ev.target.files[0];
      if (videoPattern.test(f.type)) {
        type = 'video';
      } else if (imagePattern.test(f.type)) {
        type = 'image';
      }
    } else {
      type = 'image';
    }
    if (type === 'video') {
      setMedia({ video: ev.target.files[0] });
    } else if (type === 'image') {
      const images = [];
      for (const f of ev.target.files) {
        if (imagePattern.test(f.type)) {
          images.push(f);
        }
      }
      setMedia({ images: images.slice(0, 4) });
    }
  }
  /**
   * @type {string[]|null}
   */
  const medias = useMemo(() => {
    if (media.video) {
      return [media.video.name];
    } else if (media.images) {
      return media.images.map((f) => f.name);
    } else {
      return null;
    }
  }, [media]);
  /**
   * @type {boolean}
   */
  const mediaUploaded = useMemo(() => {
    return !!medias;
  }, [medias]);
  /**
   * @type {string|null}
   */
  const mediaStr = useMemo(() => {
    if (!medias) return null;
    return medias.join(', ');
  }, [medias]);
  /**
   * @param {string} media
   */
  function mediaDelete(mediaName) {
    if (media.video) {
      setMedia({});
    } else if (media.images) {
      const fm = media.images.filter((f) => f.name !== mediaName);
      if (!fm.length) {
        setMedia({});
      } else {
        setMedia({ images: fm });
      }
    }
  }

  async function onSubmit(values) {
    if (loading) return;
    const { title, owner, category, description, market, url } = values;
    console.log('form', values);
    if (!title) {
      message.warning('Must input title');
      return;
    }
    if (!owner) {
      message.warning('Must input owner');
      return;
    }
    setLoading(true);
    try {
      // post file
      const formMedia = {};
      if (media.video) {
        const {
          data: { key },
        } = await postFile(media.video);
        formMedia.video = key;
      } else if (media.images) {
        formMedia.images = [];
        for (const image of media.images) {
          const {
            data: { key },
          } = await postFile(image);
          formMedia.images.push(key);
        }
      } else {
        const u = new URL(window.location);
        u.pathname = DefaultPng;
        formMedia.images = [u.toString()];
      }
      // post data
      await postKnorr(title, formMedia, {
        owner: owner,
        description: description ?? undefined,
        url: url ?? undefined,
        category: category ?? [],
        market: market ? [market.join('-')] : [],
      });
      onUpload && onUpload();
    } catch (err) {
      message.error('Form submit error');
      console.error('form submit error', err);
    }
    setLoading(false);
  }

  return (
    <Form layout="vertical" className={style.uploadForm} onFinish={onSubmit}>
      <Item label="Title" name="title">
        <Input
          classNames={{ input: style.input }}
          variant="filled"
          placeholder="Type your title"
        ></Input>
      </Item>
      <Item label="Owner(s)" name="owner">
        <Input
          classNames={{ input: style.input }}
          variant="filled"
          placeholder="Type your name"
        ></Input>
      </Item>
      <Item label="Topic" name="category">
        <Select
          className={style.select}
          placeholder="Select your topic"
          variant="filled"
          mode="multiple"
          allowClear={true}
          options={Categories}
        ></Select>
      </Item>
      <Item label="What's my Learning" name="description">
        <Input.TextArea
          classNames={{ textarea: style.input }}
          variant="filled"
          placeholder="Type a summary of your learning"
        ></Input.TextArea>
      </Item>
      <div className={style.uploadFormLine}>
        <Item label="Market" name="market" style={{ width: '48%' }}>
          <Cascader
            className={style.select}
            placeholder="Select your market"
            variant="filled"
            allowClear={true}
            options={Markets}
          ></Cascader>
        </Item>
        <Item label="Upload" style={{ width: '48%' }}>
          {mediaUploaded ? (
            <div className={style.uploadFormUploadBtnHasDrop}>
              <div className={style.uploadFormUploadBtn}>{mediaStr}</div>
              {medias && (
                <div className={style.uploadFormUploadBtnDropContainer}>
                  {medias.map((e) => KnrorrUploadBtnDropItem(e, mediaDelete))}
                </div>
              )}
            </div>
          ) : (
            <div
              className={style.uploadFormUploadBtn}
              onClick={onUploadBtnClick}
            >
              <PlusOutlined />
            </div>
          )}
          <div className={style.uploadFormItemInfo}>5 images or 1 video</div>
          <input
            ref={inputRef}
            type="file"
            style={{ display: 'none' }}
            multiple
            accept="image/*,video/mp4"
            onChange={onUploadChange}
          />
        </Item>
      </div>
      <Item label="Additional Attachments URL" name="url">
        <Input.TextArea
          classNames={{ textarea: style.input }}
          variant="filled"
          placeholder="You can add your OneDrive link to more files here"
        ></Input.TextArea>
      </Item>
      <div className={style.uploadFormBtnLine}>
        <Button shape="round" htmlType="submit" loading={loading}>
          SHARE
        </Button>
      </div>
    </Form>
  );
}

function KnorrUpload({ className, onUpload }) {
  const [dropVisible, setDropVisible] = useState(false);

  return (
    <KnorrDrop
      icon={<UploadOutlined />}
      className={className}
      item={KnorrUploadForm(() => {
        setDropVisible(false);
        onUpload && onUpload();
      })}
      dropVisible={dropVisible}
      setDropVisible={setDropVisible}
    >
      Share My Learning
    </KnorrDrop>
  );
}

export default KnorrUpload;
