// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.P1Ru18xZmX17BHTTFPxy,.UKDiPBnPg9Ivd_AYeDr1{width:180px;height:100%;padding:10px;display:flex;flex-direction:column;border-top-right-radius:12px;border-bottom-right-radius:12px;transition:width .4s ease}.P1Ru18xZmX17BHTTFPxy{background-color:#1f6419}.UKDiPBnPg9Ivd_AYeDr1{background-color:#012702}.U50xFdzgdpCUzgT6C4YO{flex:1;overflow-x:hidden;overflow-y:auto}`, "",{"version":3,"sources":["webpack://./src/modules/occasion-module/views/photo/components/filter.module.scss"],"names":[],"mappings":"AAAA,4CAEE,WAAA,CACA,WAAA,CACA,YAAA,CACA,YAAA,CACA,qBAAA,CACA,4BAAA,CACA,+BAAA,CACA,yBAAA,CAGF,sBACE,wBAAA,CAGF,sBACE,wBAAA,CAGF,sBACE,MAAA,CACA,iBAAA,CACA,eAAA","sourcesContent":[".filterContainer,\n.compareContainer {\n  width: 180px;\n  height: 100%;\n  padding: 10px;\n  display: flex;\n  flex-direction: column;\n  border-top-right-radius: 12px;\n  border-bottom-right-radius: 12px;\n  transition: width 0.4s ease;\n}\n\n.filterContainer {\n  background-color: #1f6419;\n}\n\n.compareContainer {\n  background-color: #012702;\n}\n\n.filterContent {\n  flex: 1;\n  overflow-x: hidden;\n  overflow-y: auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"filterContainer": `P1Ru18xZmX17BHTTFPxy`,
	"compareContainer": `UKDiPBnPg9Ivd_AYeDr1`,
	"filterContent": `U50xFdzgdpCUzgT6C4YO`
};
export default ___CSS_LOADER_EXPORT___;
