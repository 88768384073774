// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Ze3fvAmLBgjK9uyw4B73{position:relative;height:85%;width:90%;margin:5%;overflow:hidden;display:flex;justify-content:center;align-items:center}.NPPBDYmIkIEf7cwfTSiR{border-radius:15px;overflow:hidden}`, "",{"version":3,"sources":["webpack://./src/modules/package-ai-generation/views/edit/editor.module.scss"],"names":[],"mappings":"AAAA,sBACE,iBAAA,CACA,UAAA,CACA,SAAA,CACA,SAAA,CAGA,eAAA,CACA,YAAA,CACA,sBAAA,CACA,kBAAA,CAGF,sBACE,kBAAA,CACA,eAAA","sourcesContent":[".editorBox {\n  position: relative;\n  height: 85%;\n  width: 90%;\n  margin: 5%;\n  // border-radius: 15px;\n  // background-color: #fff;\n  overflow: hidden;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n.editorStage {\n  border-radius: 15px;\n  overflow: hidden;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"editorBox": `Ze3fvAmLBgjK9uyw4B73`,
	"editorStage": `NPPBDYmIkIEf7cwfTSiR`
};
export default ___CSS_LOADER_EXPORT___;
