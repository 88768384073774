import React from 'react';
import style from './border-container.module.scss';
import classNames from 'classnames';
import { 
  borderCornerUp, 
  borderCornerRight, 
  borderCornerDown, 
  borderCornerLeft, 
  borderCornerSmallUp, 
  borderCornerSmallRight, 
  borderCornerSmallDown, 
  borderCornerSmallLeft
} from '../../../../components/icons/icons.jsx';
import { Affix } from 'antd';

function BorderContainer({ hideLeftCorner, bigTitle, small, headerTitle, children, up, headerChildren }) {
  return (
    <div className={style.lightBorderContainer}>
      {small ? 
        <>
          <img className={classNames(style.borderSvg, style.borderSvg1)} src={borderCornerSmallUp} alt="" />
          <img className={classNames(style.borderSvg, style.borderSvg2)} src={borderCornerSmallRight} alt="" />
          <img className={classNames(style.borderSvg, style.borderSvg3)} src={borderCornerSmallDown} alt="" />
          <img className={classNames(style.borderSvg, style.borderSvg4)} src={borderCornerSmallLeft} alt="" />
        </> :
        <>
          {!hideLeftCorner && <img className={classNames(style.borderSvg, style.borderSvg11)} src={borderCornerUp} alt="" />}
          <img className={classNames(style.borderSvg, style.borderSvg2)} src={borderCornerRight} alt="" />
          <img className={classNames(style.borderSvg, style.borderSvg3)} src={borderCornerDown} alt="" />
          <img className={classNames(style.borderSvg, style.borderSvg4)} src={borderCornerLeft} alt="" />
        </>
      }
      <div className={classNames(style.borderBoxContent, {[style.small]: small, [style.up]: up})}>
        {headerTitle && 
        <Affix>
          <div className={style.commonHeader}>
            <div className={classNames(style.headerTitle, {[style.bigTitle]: bigTitle})}>{ headerTitle }</div>
            <div className={style.rightBox}>{headerChildren}</div>
          </div>
        </Affix>}
        {children}
      </div>
    </div>
  );
}

export default BorderContainer;