// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.XqCxz4_basdU9YEstfM1{width:100% !important;height:100vh}.w7iWCrCopP2K6g9KG9uA{position:relative}`, "",{"version":3,"sources":["webpack://./src/modules/drafted-index/ai-top-dish.module.scss"],"names":[],"mappings":"AAAA,sBACE,qBAAA,CACA,YAAA,CAGF,sBACE,iBAAA","sourcesContent":[".fullContainer {\n  width: 100% !important;\n  height: 100vh;\n}\n\n.filterApply {\n  position: relative;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"fullContainer": `XqCxz4_basdU9YEstfM1`,
	"filterApply": `w7iWCrCopP2K6g9KG9uA`
};
export default ___CSS_LOADER_EXPORT___;
