import React, { useState } from 'react';
import TriangleSvg from '../assets/img/triangle.svg';
import style from './form.module.scss';

function PackageAiLabel({ label, isCollapse = true, onClick }) {
  return (
    <div className={style.formItemLabel} onClick={onClick}>
      <img
        className={`${isCollapse ? '' : style.formItemLabelNotCollapse}`}
        src={TriangleSvg}
      />
      <span className={style.formItemText}>{label}</span>
    </div>
  );
}

export function PackageAiFormItem({ className = '', label, children }) {
  return (
    <div className={`${style.formItem} ${className}`}>
      <PackageAiLabel label={label} />
      <div className={style.formItemContent}>{children}</div>
    </div>
  );
}

export function PackageAiFormItemCollapse({ className = '', label, children }) {
  const [isCollapse, setIsCollapse] = useState(true);

  return (
    <div className={`${style.formItem} ${className}`}>
      <PackageAiLabel
        isCollapse={isCollapse}
        label={label}
        onClick={() => setIsCollapse(!isCollapse)}
      />
      <div
        className={`${style.formItemContent} ${
          isCollapse ? style.formItemContentCollapse : ''
        }`}
      >
        {children}
      </div>
    </div>
  );
}
