import {
  CompressOutlined,
  ZoomInOutlined,
  ZoomOutOutlined,
} from '@ant-design/icons';
import React from 'react';
import style from './tool.module.scss';

const SETP = 0.01;

function PackageAiTool({ setScale, onReset }) {
  return (
    <div className={style.toolBox}>
      <ZoomInOutlined
        title="Zoom In"
        className={style.toolBtn}
        onClick={() => setScale((scale) => scale + SETP)}
      />
      <ZoomOutOutlined
        title="Zoom Out"
        className={style.toolBtn}
        onClick={() => setScale((scale) => scale - SETP)}
      />
      <CompressOutlined
        title="Reset"
        className={style.toolBtn}
        onClick={onReset}
      />
    </div>
  );
}

export default PackageAiTool;
