import React, { useEffect, useRef, useState } from 'react';
import { chevronDownIcon } from '../../icons/icons.jsx';
import FilterModal from '../filterModal.jsx';
import api from '@/util/api.jsx';
import { apiURL } from '../../../env-url.js';
import { useFilters } from '../../../modules/trends/hooks/useFilters.jsx';

function SubcategoryFilter({
  consumerTrendsCategory,
  subcategories,
  selectedCountry
}) {
  const [isSortOpen, setIsSortOpen] = useState(false);
  const ref = useRef(null);
  const [filteredResults, setFilteredResults] = useState([]);
  const {filters, updateFilters} = useFilters();

  let consumerTrend = encodeURIComponent(consumerTrendsCategory);
  const fetchData = () => {
    return api
      .get(
        `${apiURL}/trends/mintel/subcategory?country=${selectedCountry}&consumerTrend=${consumerTrend}`
      )
      .then((response) => {
        setFilteredResults((prevData) => [...prevData, ...response.data]);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  };

  const handleButtonClick = () => {
    setIsSortOpen(!isSortOpen);
  };

  const checkIfClickedOutside = (e) => {
    if (ref.current && !ref.current.contains(e.target)) {
      setIsSortOpen(false);
    }
  };

  const onApply = (subcategories) => {
    updateSubCategoriesSelection(subcategories);
    setIsSortOpen(false);
  };

  const updateSubCategoriesSelection = (values) => {
    console.log(values);
    updateFilters(({...filters, subcategories: values}));
  };

  useEffect(() => {
    fetchData();
    document.addEventListener('mousedown', checkIfClickedOutside);
    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  }, []);

  return (
    <div ref={ref}>
      <div className="consumer-filter-container">
        <button
          className={'subcategory-filter' + (isSortOpen ? ' active' : '')}
          onClick={handleButtonClick}
          role="button"
        >
          <span>Subcategory</span>
          {isSortOpen ? (
            <img
              className="up-arrow-filter"
              src={chevronDownIcon}
              alt="arrow"
            />
          ) : (
            <img className="arrow-sort" src={chevronDownIcon} alt="arrow" />
          )}
        </button>
        {isSortOpen && (
          <FilterModal
            isOpen={isSortOpen}
            onApply={onApply}
            data={filteredResults}
            selectedOptions={subcategories}
          />
        )}
      </div>
    </div>
  );
}
export default SubcategoryFilter;
