import React from 'react';
import style from './login.module.scss';
import { ConfigProvider, theme, App } from 'antd';
import LoginPage from './page/login-page.jsx';

const Login = () => (
  <ConfigProvider
    theme={{
      token: {
        colorPrimary: '#00a32e',
        colorError: '#eb0000',
        colorSuccess: '#06ca3d',
        fontFamily: 'unilever-shilling-regular',
      },
      algorithm: theme.darkAlgorithm,
    }}
  >
    <App className={style.container}>
      <LoginPage />
    </App>
  </ConfigProvider>
);
export default Login;
