import { PlusOutlined } from '@ant-design/icons';
import { message } from 'antd';
import React, { useRef, useState } from 'react';
import { putFileKey } from '../../apis/file/index.js';
import { postStandardizer } from '../../apis/serve/standardizer.js';
import EditableText from '../../components/editableText.jsx';
import Loading from '../../components/loading.jsx';
import Title from '../../components/title2.jsx';
import style from './item.module.scss';

const IMAGE_TYPE_LIST = ['image/png', 'image/jpeg'];

function PackageAiImageItem({ className = '', data, setData, onAdd }) {
  const [loading, setLoading] = useState(false);
  const inputRef = useRef(null);

  function onClick() {
    if (data.src) {
      if (!inputRef.current) return;
      inputRef.current.click();
    } else {
      onAdd && onAdd();
    }
  }

  async function onFileChange(ev) {
    if (!ev.target?.files?.length) return;
    /**
     * @type {File}
     */
    const file = ev.target.files[0];
    if (!IMAGE_TYPE_LIST.includes(file.type)) {
      message.warning('Image should be in png or jpeg format');
      return;
    }
    if (loading) return;
    setLoading(true);
    try {
      const {
        data: [std, raw],
      } = await postStandardizer(file);
      const { data: stdd } = await putFileKey(std);
      const { data: rawd } = await putFileKey(raw);
      setData({
        ...data,
        src: rawd.url,
        file: raw,
        standardSrc: stdd.url,
        standardFile: std,
      });
    } catch (err) {
      console.error('upload error', err);
      message.error('Upload failed');
    }
    setLoading(false);
  }

  return (
    <div className={`${style.imgItem} ${className}`}>
      <Title>
        <EditableText
          value={data.title}
          onChange={(val) => setData({ ...data, title: val })}
        />
      </Title>
      <div className={style.imgItemUpload} onClick={onClick}>
        {data.src ? (
          <img
            className={style.imgItemUploadImg}
            src={data.src}
            crossOrigin="anonymous"
          />
        ) : (
          <PlusOutlined className={style.imgItemUploadIcon} />
        )}
      </div>
      {loading && <Loading />}
      <input
        ref={inputRef}
        type="file"
        accept={IMAGE_TYPE_LIST.join(', ')}
        style={{ display: 'none' }}
        onChange={onFileChange}
      />
    </div>
  );
}

export default PackageAiImageItem;
