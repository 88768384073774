// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hQzVbPbXfg9IxMd2hswT{position:relative;background-color:rgba(0,0,0,.9)}.nNbKStZ3YPZNElKlumBC{width:100%;height:72px;background:linear-gradient(to right, rgba(0, 178, 0, 0.7), rgba(0, 5, 3, 0.7));position:absolute}.nNbKStZ3YPZNElKlumBC .OJ41ETtwdViYWcw8VB0a{position:absolute;top:-19px;left:-8px;height:110px;width:385px}.nNbKStZ3YPZNElKlumBC .ppdSyh0Z0Qy136m6Zo6e{position:absolute;top:26px;right:32px;cursor:pointer;z-index:10}.nNbKStZ3YPZNElKlumBC .SKpQMPuXH72dhN9nIKf3{position:relative;font-size:23px;font-weight:bold;color:#fff;line-height:1;display:flex;align-items:center;height:100%;padding-left:70px}.HnyxTvgp_6IQaGKDjcel{padding-top:72px}`, "",{"version":3,"sources":["webpack://./src/modules/plant-based/components/custom-modal/custom-modal.module.scss"],"names":[],"mappings":"AAAA,sBACE,iBAAA,CACA,+BAAA,CAGF,sBACE,UAAA,CACA,WAAA,CACA,8EAAA,CACA,iBAAA,CACA,4CACE,iBAAA,CACA,SAAA,CACA,SAAA,CAEA,YAAA,CACA,WAAA,CAGF,4CACE,iBAAA,CACA,QAAA,CACA,UAAA,CACA,cAAA,CACA,UAAA,CAGF,4CACE,iBAAA,CACA,cAAA,CACA,gBAAA,CACA,UAAA,CACA,aAAA,CACA,YAAA,CACA,kBAAA,CACA,WAAA,CACA,iBAAA,CAIJ,sBACE,gBAAA","sourcesContent":[".borderWrap {\n  position: relative;\n  background-color: rgba(0, 0, 0, 0.9);\n}\n\n.modelHeader {\n  width: 100%;\n  height: 72px;\n  background: linear-gradient(to right, rgba(0, 178, 0, 0.7), rgba(0, 5, 3, 0.7));\n  position: absolute;\n  .bgImg {\n    position: absolute;\n    top: -19px;\n    left: -8px;\n\n    height: 110px;\n    width: 385px;\n  }\n\n  .closeBtnIcon {\n    position: absolute;\n    top: 26px;\n    right: 32px;\n    cursor: pointer;\n    z-index: 10;\n  }\n\n  .headerTitle {\n    position: relative;\n    font-size: 23px;\n    font-weight: bold;\n    color: #fff;\n    line-height: 1;\n    display: flex;\n    align-items: center;\n    height: 100%;\n    padding-left: 70px;\n  }\n}\n\n.content{\n  padding-top: 72px;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"borderWrap": `hQzVbPbXfg9IxMd2hswT`,
	"modelHeader": `nNbKStZ3YPZNElKlumBC`,
	"bgImg": `OJ41ETtwdViYWcw8VB0a`,
	"closeBtnIcon": `ppdSyh0Z0Qy136m6Zo6e`,
	"headerTitle": `SKpQMPuXH72dhN9nIKf3`,
	"content": `HnyxTvgp_6IQaGKDjcel`
};
export default ___CSS_LOADER_EXPORT___;
