import { message } from 'antd';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { putFileKey } from '../../apis/file';
import BalanceSvg from '../../assets/img/balance.svg';
import ClaritySvg from '../../assets/img/clarity.svg';
import ExcitementSvg from '../../assets/img/excitement.svg';
import Box from '../../components/box2.jsx';
import ColorCoding from '../../components/colorCoding.jsx';
import ImgDialog from '../../components/imgDialog.jsx';
import Loading from '../../components/loading.jsx';
import style from './appeal.module.scss';
import Table from './table.jsx';

function colorValue(val, thresholdFn) {
  return <ColorCoding colorValue={thresholdFn(val)}>{val}</ColorCoding>;
}

function TextBox({ className = '', icon, title, children }) {
  return (
    <Box className={`${style.textBox} ${className}`}>
      <div className={style.textBoxInner}>
        <div className={style.textBox1}>
          <img src={icon} alt={title} />
        </div>
        <div className={style.textBox2}>
          <div className={style.textBoxTitle}>{title}</div>
          <div className={style.textBoxContent}>{children}</div>
        </div>
      </div>
    </Box>
  );
}

function ImageItem({ className = '', title = '', url, onClick }) {
  function onImgClick() {
    onClick && onClick(url);
  }
  return (
    <div className={classNames(style.imageItem, className)}>
      {/* <div className={style.imageItemTitle}>{title}</div> */}
      <div className={style.imageItemContent} onClick={onImgClick}>
        <img src={url} alt={title} />
      </div>
    </div>
  );
}

function PackageAiBody({ useNextBtn, context: ctx }) {
  const excitement = (
    <div className={style.tableRowCellFirst}>
      <img src={ExcitementSvg} alt="Excitement" />
      <span>Design Excitement</span>
    </div>
  );
  const clarity = (
    <div className={style.tableRowCellFirst}>
      <img src={ClaritySvg} alt="Clarity" />
      <span>Design Clarity</span>
    </div>
  );
  const symmetry = (
    <div className={style.tableRowCellFirst}>
      <img src={BalanceSvg} alt="Balance" />
      <span>Design Symmetry</span>
    </div>
  );

  // table 1
  const [column, setColumn] = useState([
    '',
    'Our Brand Current',
    'Our Brand New Design',
    'Competitor 1',
    'Competitor 2',
  ]);
  const [value, setValue] = useState([
    [excitement, 0, 0, 0, 0],
    [clarity, 0, 0, 0, 0],
    [symmetry, 0, 0, 0, 0],
  ]);
  // image
  const [data2, setData2] = useState([]);

  const [dialogVisible, setDialogVisible] = useState(false);
  const [dialogUrl, setDialogUrl] = useState('');
  function onImgClick(url) {
    setDialogVisible(true);
    setDialogUrl(url);
  }

  const [loading, setLoading] = useState(true);
  async function init() {
    try {
      const pp = ctx?.res?.predictPackage;
      if (!pp) return;
      const variants = ctx?.res?.variants;
      if (!variants) return;
      const aesImg = ctx?.res?.aesImg;
      if (!aesImg) return;

      const columns = [''];
      const values = [[excitement], [clarity], [symmetry]];
      const processAes = (name, aes) => {
        if (aes) {
          columns.push(name);
          values[0].push(
            colorValue(aes.exciting, (val) => {
              if (val > 40 && val < 60) return 3;
              else if (val > 25 && val < 75) return 2;
              else return 1;
            })
          );
          values[1].push(
            colorValue(aes.clear, (val) => {
              if (val > 75) return 3;
              else if (val > 50) return 2;
              else return 1;
            })
          );
          values[2].push(
            colorValue(aes.balance, (val) => {
              if (val > 75) return 3;
              else if (val > 60) return 2;
              else return 1;
            })
          );
        }
      };

      // ul brand
      const uld0v = variants[pp.ul_design0_variant0_id];
      processAes(`${pp.brand_ul} Current`, uld0v?.aesresult);
      const uld1v = variants[pp.ul_design1_variant0_id];
      processAes(`${pp.brand_ul} New Design`, uld1v?.aesresult);
      const uld2v = variants[pp.ul_design2_variant0_id];
      processAes(`${pp.brand_ul} New Design 2`, uld2v?.aesresult);
      const uld3v = variants[pp.ul_design3_variant0_id];
      processAes(`${pp.brand_ul} New Design 3`, uld3v?.aesresult);
      // c1 brand
      const c1d1v = variants[pp.c1_design1_variant0_id];
      processAes(`${pp.brand_c1}`, c1d1v?.aesresult);
      // c2 brand
      const c2d1v = variants[pp.c2_design1_variant0_id];
      processAes(`${pp.brand_c2}`, c2d1v?.aesresult);

      setColumn(columns);
      setValue(values);

      const data2 = [];
      const processValue = async (name, fileKey) => {
        if (!fileKey) return;
        const { data } = await putFileKey(fileKey);
        data2.push({ title: name, url: data.url });
      };

      // set ul data
      const uld0 = aesImg['current design'];
      await processValue(`${pp.brand_ul} Current`, uld0);
      const uld1 = aesImg['new design'];
      await processValue(`${pp.brand_ul} New Design`, uld1);
      setData2(data2);
    } catch (err) {
      console.error('init error', err);
      message.error('Failed to load data');
    }
    setLoading(false);
  }
  useEffect(() => {
    void init();
  }, [ctx]);

  return (
    <>
      {loading && <Loading />}
      <div className={style.box1}>
        <Table className={style.table} labels={column} values={value} />
        <div className={style.box12}>
          <TextBox
            className={style.box121}
            icon={ExcitementSvg}
            title="Design Excitement:"
          >
            The &lsquo;Excitement Score&lsquo; measures the intensity of a
            design&lsquo;s colour combination, helping to predict viewer focus
            and comprehension. Scores range from 0 to 100. High scores (&gt;75)
            suggest a design that might grab attention initially, but then might
            also overwhelm people, while low scores (&lt;25) indicate a plain
            design that may not capture attention. A balanced score between 40
            and 60 is considered excellent. Improving the results: To improve
            Excitement scores, use rich visuals, ensuring a balance between
            colours and white space, avoiding overly calm or overwhelming design
            colour themes.
          </TextBox>
          <TextBox
            className={style.box121}
            icon={ExcitementSvg}
            title="Design Clarity:"
          >
            The &lsquo;Clarity Score&lsquo; measures the number of regions,
            corners, and features in an image, helping predict visual clarity
            and appeal. Scores range from 0 to 100. High scores (&gt;75)
            indicate a clean, simple, and clear design which increases its
            perception as an attractive design, while low scores (&lt;50)
            suggest a confusing and cluttered design which makes less people
            perceive it as attractive. Improving the results: To improve clarity
            in design, reduce clutter by minimizing unnecessary text, using more
            images, increasing whitespace, and organizing content into
            distinguishable blocks. This reduces cognitive load, ensuring
            viewers focus on key elements.
          </TextBox>
          <TextBox
            className={style.box121}
            icon={ExcitementSvg}
            title="Design Symmetry:"
          >
            The &lsquo;Symmetry Score&lsquo; measures a design&lsquo;s symmetry,
            which contributes to a design&lsquo;s appeal. This algorithm checks
            for colour symmetry and intensity and gives a score ranging from 0
            to 100. High scores (&gt;75) mean the design is balanced and
            harmonic. Low scores (&lt;60) mean it&lsquo;s unbalanced and
            lopsided. Improving the results: To improve design symmetry, limit
            your palette to two or three base colours, and add white space
            around elements to make them stand out. Ensure consistent fonts and
            typography and connect all elements cohesively.
          </TextBox>
        </div>
        {data2.map((item, index) => {
          return (
            <ImageItem
              key={`${item.title}-${index}`}
              className={style.box11Item}
              title={item.title}
              url={item.url}
              onClick={onImgClick}
            />
          );
        })}
      </div>
      <div className={style.btnBox}>{useNextBtn && useNextBtn({})}</div>
      <ImgDialog
        visible={dialogVisible}
        setVisible={setDialogVisible}
        src={dialogUrl}
      />
    </>
  );
}

export default PackageAiBody;
