// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rC_Xh6fCtFbUHGzSXKcU{overflow:auto}.Y5gsPRcOe5r_NR1vSUgT{display:flex;flex-wrap:wrap}.PowuZk8E8Cn0EQibgP0c{width:calc(33.3333% - 15px);overflow:hidden;margin-bottom:15px;margin-right:15px}.PowuZk8E8Cn0EQibgP0c:nth-child(3n){margin-right:0}.x5gM79ywuEEL3In4SAp_{display:flex;align-items:center;margin-bottom:5px}.x5gM79ywuEEL3In4SAp_ .mSPA1Zlu3wXFl3EDC5ge{width:9px;height:14px;margin-right:5px;background-color:#00a32e;clip-path:polygon(0 0, 100% 50%, 0 100%)}.x5gM79ywuEEL3In4SAp_ .P8XzMVgFfCBFkEMGsk7S{font-size:14px;font-weight:500;color:#fff}.r7f8nTZEYGLSJQJ7RAxv{height:200px;border-radius:10px;background:#021b11;overflow:hidden}`, "",{"version":3,"sources":["webpack://./src/modules/package-ai-inspiration/components/compare/chart.module.scss"],"names":[],"mappings":"AAAA,sBAEE,aAAA,CAGF,sBACE,YAAA,CACA,cAAA,CAGF,sBACE,2BAAA,CACA,eAAA,CACA,kBAAA,CACA,iBAAA,CACA,oCACE,cAAA,CAIJ,sBACE,YAAA,CACA,kBAAA,CACA,iBAAA,CAEA,4CACE,SAAA,CACA,WAAA,CACA,gBAAA,CACA,wBAAA,CACA,wCAAA,CAGF,4CACE,cAAA,CACA,eAAA,CACA,UAAA,CAIJ,sBACE,YAAA,CACA,kBAAA,CACA,kBAAA,CACA,eAAA","sourcesContent":[".box1 {\n  // height: 100%;\n  overflow: auto;\n}\n\n.box11 {\n  display: flex;\n  flex-wrap: wrap;\n}\n\n.chartBox {\n  width: calc(33.3333% - 15px);\n  overflow: hidden;\n  margin-bottom: 15px;\n  margin-right: 15px;\n  &:nth-child(3n) {\n    margin-right: 0;\n  }\n}\n\n.chartTitle {\n  display: flex;\n  align-items: center;\n  margin-bottom: 5px;\n\n  .icon {\n    width: 9px;\n    height: 14px;\n    margin-right: 5px;\n    background-color: #00a32e;\n    clip-path: polygon(0 0, 100% 50%, 0 100%);\n  }\n\n  .text {\n    font-size: 14px;\n    font-weight: 500;\n    color: #fff;\n  }\n}\n\n.chart {\n  height: 200px;\n  border-radius: 10px;\n  background: #021b11;\n  overflow: hidden;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"box1": `rC_Xh6fCtFbUHGzSXKcU`,
	"box11": `Y5gsPRcOe5r_NR1vSUgT`,
	"chartBox": `PowuZk8E8Cn0EQibgP0c`,
	"chartTitle": `x5gM79ywuEEL3In4SAp_`,
	"icon": `mSPA1Zlu3wXFl3EDC5ge`,
	"text": `P8XzMVgFfCBFkEMGsk7S`,
	"chart": `r7f8nTZEYGLSJQJ7RAxv`
};
export default ___CSS_LOADER_EXPORT___;
