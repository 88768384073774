import React, { useState } from 'react';
import {
  OccasionFilterGroups,
  OccasionFilterHeader,
  OccasionFilters,
} from '../../../components/filter.jsx';
import style from './filter.module.scss';

export function OccasionFilterSide({
  otherFilters,
  groups,
  groupedFilters,
  setFilterData,
  hasCompare,
  startGroup,
  startCategory,
}) {
  const [fd, setFd] = useState(
    startCategory ? { booster_project: [startCategory] } : {}
  );

  function onFilterSubmit() {
    setFilterData({ ...fd });
  }
  function onFilterClear() {
    setFd({});
    onFilterSubmit();
  }

  return (
    <div
      className={hasCompare ? style.compareContainer : style.filterContainer}
    >
      <OccasionFilterHeader onSubmit={onFilterSubmit} onClear={onFilterClear}>
        Filter
      </OccasionFilterHeader>
      <div className={style.filterContent}>
        <OccasionFilters filters={otherFilters} data={fd} setData={setFd} />
        <OccasionFilterGroups
          groups={groups}
          filters={groupedFilters}
          data={fd}
          setData={setFd}
          startGroup={startGroup}
        />
      </div>
    </div>
  );
}
