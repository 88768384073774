import React from 'react';
import CardCom from '../../../components/CardCom/index.jsx';
import { cn } from '../../../utils/utils';

import { ReactComponent as ReportSvg } from '../../../assets/svgs/report.svg';
import { ReactComponent as Share2Svg } from '../../../assets/svgs/share2.svg';

import SvgCom from '../../../components/SvgCom/index.jsx';

import { ReactComponent as AddSvg } from '../../../assets/svgs/add.svg';

import { ReactComponent as Add2Svg } from '../../../assets/svgs/add2.svg';
import { ReactComponent as Edit2Svg } from '../../../assets/svgs/edit2.svg';
import { ReactComponent as CopySvg } from '../../../assets/svgs/copy.svg';

const InsightCom = () => {
  return (
    <div className={cn('grid gap-4')}>
      <CardCom label="Project Details" rightNode={
        <div className="flex items-center">
          <div className="flex items-center mr-6 cursor-pointer">
            <SvgCom Svg={ReportSvg} size="14"></SvgCom>
            <span className="text-sm leading-none text-primary ml-2">Reporting</span>
          </div>
          <div className="flex items-center cursor-pointer">
            <SvgCom Svg={Share2Svg} size="14"></SvgCom>
            <span className="text-sm leading-none text-primary ml-2">Share</span>
          </div>
        </div>
      }>
        <div className="text-sm text-primary font-semibold">
            Unilever Consumer Theatre (Cooking Behavior_ BBQ) UK UGN23005e - Project created with Agile Qual.
        </div>
      </CardCom>

      <TaskItemCom></TaskItemCom>
    </div>
  );
};

const TaskItemCom = () => {
  return <CardCom label="6860170 - Michael, 41 - Bromsgrove">
    <div className="text-sm font-normal text-green-1 mb-[20px]">
      {'No real special techniques ever done a barbecue. Just keep things moving. Don\'t let things burn. Classical thing is burning the outside. Have it on the inside. Lots of turning, lots of care, attention. Steak, keep basting it in butter. Some garlic and herbs is a really good technique. Make sure we have a good crust on the outside, but mainly the burgers and hot dogs and stuff. Just really keep them going. If we do any chicken dishes like above stuff, marinate it the day before, make sure that we really brought into the meat, make the flavour come out. That\'s really the techniques that really matter. Think, make sure things keep moving, nothing\'s burned, everything\'s cooked properly and evidence, we had enough time, have good flavour added to or enhanced.'}
    </div>

    <div className="flex justify-start">
      <div>
        <video className="w-[350px]" autoPlay={true} muted loop controls src="https://ec-crm-aliyun-oss.bluemoon.com.cn/officialwebsite/website/pro/website_20247_1724325255689.mp4" preload="true"></video>
      </div>
      <div className="flex-1 ml-[20px]">
        <div className="text-lg font-bold text-primary mb-[20px]">
            Importance of using a variety of seasonings and marinades.
        </div>

        {
          [1,2,3,4,5,6,7].map((item, index) => <div key={index} className="flex justify-start text-sm mb-2">
            <span className="h-fit leading-none font-semibold text-primary py-[4px] px-[8px] bg-second rounded-[5px]">00:14</span>
            <div>
              <span className="text-grey-3 mx-2">{'"You can see here the marinade was applied last night. - Preston"'}</span>
              <SvgCom Svg={Add2Svg} size="14" className="cursor-pointer inline-block"></SvgCom>
              <SvgCom Svg={Edit2Svg} size="14" className="mx-2 cursor-pointer inline-block"></SvgCom>
              <SvgCom Svg={CopySvg} size="14" className="cursor-pointer inline-block"></SvgCom>
            </div>
          </div>)
        }

        <div className="text-base leading-none font-bold text-green-1 mt-[25px]">Follow up question</div>
        <div className="text-sm font-semibold text-green-1 my-[10px]">What types of seasonings and marinades do you find most effective for enhancing the flavor of your barbecue?</div>

        <button className="border-btn !border-transparent !bg-second !text-primary !font-semibold">
          <SvgCom Svg={AddSvg} size="16"></SvgCom>
          <span className="ml-2">Add all showreel</span>
        </button>
      </div>
    </div>
  </CardCom>;
};

export default InsightCom;