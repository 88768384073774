// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.y4bNMrPBsIB7PeLGWiQw{border-collapse:collapse}.y4bNMrPBsIB7PeLGWiQw th{padding:3px;color:#00a32e;font-size:12px;border:solid 1px #00a32e;font-weight:700}.y4bNMrPBsIB7PeLGWiQw td{padding:3px;color:#00a32e;font-size:12px;border:solid 1px #00a32e}.HLnTaTaj9kOtziPUaZP7{display:flex;justify-content:center;align-items:center;height:24px}.HLnTaTaj9kOtziPUaZP7 img{height:100%}`, "",{"version":3,"sources":["webpack://./src/modules/package-ai-screening/components/legend2.module.scss"],"names":[],"mappings":"AAAA,sBACE,wBAAA,CACA,yBACE,WAAA,CACA,aAAA,CACA,cAAA,CACA,wBAAA,CACA,eAAA,CAEF,yBACE,WAAA,CACA,aAAA,CACA,cAAA,CACA,wBAAA,CAIJ,sBACE,YAAA,CACA,sBAAA,CACA,kBAAA,CACA,WAAA,CACA,0BACE,WAAA","sourcesContent":[".legendTable {\n  border-collapse: collapse;\n  th {\n    padding: 3px;\n    color: #00a32e;\n    font-size: 12px;\n    border: solid 1px #00a32e;\n    font-weight: 700;\n  }\n  td {\n    padding: 3px;\n    color: #00a32e;\n    font-size: 12px;\n    border: solid 1px #00a32e;\n  }\n}\n\n.icon {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  height: 24px;\n  img {\n    height: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"legendTable": `y4bNMrPBsIB7PeLGWiQw`,
	"icon": `HLnTaTaj9kOtziPUaZP7`
};
export default ___CSS_LOADER_EXPORT___;
