import React, { useEffect, useRef } from 'react';
import style from './index.module.scss';

function ValueDecompositionView() {
  const aRef = useRef();

  useEffect(() => {
    setTimeout(() => {
      if (aRef) aRef.current.click();
    }, 100);
  }, []);

  return (
    <div>
      <a
        ref={aRef}
        href="https://app.powerbi.com/groups/me/apps/c3191c32-0f1d-4e01-9ec9-95f5b431cd11/reports/b8a86462-5c74-4aef-9f15-b08ea0b7e02e/ReportSection?ctid=6ac7a1f4-5fb1-4153-bb4f-12d2020a1f7d&experience=power-bi"
      >
        <h1 className={style.title}>Loading....</h1>
      </a>
    </div>
  );
}

export default ValueDecompositionView;
