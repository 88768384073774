import React from 'react';
import style from './index.module.scss';
import { Table } from 'antd';
import classNames from 'classnames';
import { useBase } from '../../hooks/useBase.jsx';
import EditSvgIcon from '../../../../assets/images/bu-monthly-feedback/edit.svg';
import ExpandDownSvgIcon from '../../../../assets/images/bu-monthly-feedback/expand-down.svg';
import calendarSvg from '../../../../assets/images/bu-monthly-feedback/calendar.svg';
import { CaretDownFilled, CaretUpOutlined } from '@ant-design/icons';
import useBaseInfo from '../../hooks/useBaseInfo.js';
// import moment from 'moment';

const ShowTableCom = ({paginateChange, openChange, dataList}) => {
  const { monthDate } = useBaseInfo()
  const { userInfo } = useBase();
  const getValNode = (val) => {
    const valObj = JSON.parse(val);
    let shareVal = valObj ? valObj['share'] : '(share)';
    let valueVal = valObj ? valObj['value'] : '(bps)';
    
    if(!['am', '(share)'].includes((shareVal+'').toLowerCase())) {
      shareVal = (+shareVal) * 100;
      shareVal = shareVal.toFixed(1) + '%';
    }
    
    if(!['am', '(bps)'].includes((valueVal+'').toLowerCase())) {
      valueVal =(+valueVal.toFixed(0)) + '';
      if(+valueVal > 0) { /* empty */ } else {
        valueVal = Math.abs(+valueVal);
        valueVal = `(${valueVal})`;
      }
    }
    return <div>
      <div>{shareVal}</div>
      <div style={{color: valueVal.indexOf('(') > -1 ? 'red' : '#50B051'}}>{valueVal}</div>
    </div>;
  };
    
  // const getMonthNode = (projection, month) => {
  //   const projectionObj = projection ? JSON.parse(projection) : null;
  //   let shareVal = projectionObj ? projectionObj[`projection_${month}`]['share'] : '(share)';
  //   let valueVal = projectionObj ? projectionObj[`projection_${month}`]['value'] : '(bps)';
    
  //   shareVal = shareVal ? shareVal : '(share)';
  //   valueVal = valueVal ? valueVal : '(bps)';
  //   if(!['am', '(share)'].includes((shareVal+'').toLowerCase())) {
  //     shareVal = (+shareVal) * 100;
  //     shareVal = shareVal.toFixed(1) + '%';
  //   }
    
  //   if(!['am', '(bps)'].includes((valueVal+'').toLowerCase())) {
  //     valueVal = Math.floor(+valueVal) + '';
  //     if(+valueVal > 0) { /* empty */ } else {
  //       valueVal = Math.abs(+valueVal);
  //       valueVal = `(${valueVal})`;
  //     }
  //   }
  //   return <div>
  //     <div style={{color: shareVal !== '(share)' ? '#ffffff' : 'rgba(255,255,255,0.5)'}}>{shareVal}</div>
  //     <div style={{color: valueVal !== '(bps)' ? (valueVal.indexOf('(') > -1 ? 'red' : '#50B051') : 'rgba(255,255,255,0.5)'}}>{valueVal}</div>
  //   </div>;
  // };
    
  const columns = [
    {
      title: 'Country',
      dataIndex: 'country',
      fixed: 'left',
      width: 170,
      align: 'center',
      sorter: true,
    },
    {
      title: 'Category',
      dataIndex: 'category',
      fixed: 'left',
      sorter: true,
      width: 200,
      align: 'center',
    },
    {
      title: 'Cell',
      dataIndex: 'cell',
      fixed: 'left',
      sorter: true,
      width: 200,
      align: 'center',
    },
    {
      title: '%BG TO',
      dataIndex: 'bg',
      fixed: 'left',
      sorter: true,
      width: 120,
      align: 'center',
      render: (_, record) => <span>{Math.round(record.bg * 10 * 100) / 10}%</span>
    },
    {
      title: <div className={classNames(style.calendarBox)}>
        <img src={calendarSvg} alt="" />
        <div style={{marginTop: '5px'}}>Current Data: </div>
        <div>{monthDate} Report</div>
      </div>,
      dataIndex: 'country',
      fixed: 'left',
      width: 150,
      align: 'center',
      render: () => <div>
        <div style={{textAlign: 'left'}}>Share %</div>
        <div style={{textAlign: 'left'}}>Bps vs YA or AM</div>
      </div>
    },
    {
      title: 'Volume Actuals',
      dataIndex: 'volumn_share',
      align: 'center',
      children: [
        {
          title: 'MAT',
          dataIndex: 'volumn_mat',
          width: 70,
          align: 'center',
          render: (_, record) => getValNode(record.volumn_mat)
        },
        {
          title: 'L12W',
          dataIndex: 'volumn_l12w',
          width: 70,
          align: 'center',
          render: (_, record) => getValNode(record.volumn_l12w)
        }
      ]
    },
    {
      title: 'Value Actuals',
      dataIndex: 'value_share',
      align: 'center',
      children: [
        {
          title: 'MAT',
          dataIndex: 'value_mat',
          width: 70,
          align: 'center',
          render: (_, record) => getValNode(record.value_mat)
        },
        {
          title: 'L12W',
          dataIndex: 'value_l12w',
          width: 70,
          align: 'center',
          render: (_, record) => getValNode(record.value_l12w)
        },
      ]
    },
    // {
    //   title: 'Value Share Projection 2024(MAT)',
    //   dataIndex: 'projection',
    //   align: 'center',
    //   children: [
    //     {
    //       title: 'Feb',
    //       dataIndex: 'projection_feb',
    //       width: 90,
    //       align: 'center',
    //       render: (_, record) => getMonthNode(record.projection, 'feb')
    //     },
    //     {
    //       title: 'Mar',
    //       dataIndex: 'projection_mar',
    //       width: 90,
    //       align: 'center',
    //       render: (_, record) => getMonthNode(record.projection, 'mar')
    //     },
    //     {
    //       title: 'Apr',
    //       dataIndex: 'projection_apr',
    //       width: 90,
    //       align: 'center',
    //       render: (_, record) => getMonthNode(record.projection, 'apr')
    //     },
    //     {
    //       title: 'May',
    //       dataIndex: 'projection_may',
    //       width: 90,
    //       align: 'center',
    //       render: (_, record) => getMonthNode(record.projection, 'may')
    //     },
    //     {
    //       title: 'Jun',
    //       dataIndex: 'projection_jun',
    //       width: 90,
    //       align: 'center',
    //       render: (_, record) => getMonthNode(record.projection, 'jun')
    //     },
    //     {
    //       title: 'Jul',
    //       dataIndex: 'projection_jul',
    //       width: 90,
    //       align: 'center',
    //       render: (_, record) => getMonthNode(record.projection, 'jul')
    //     },
    //     {
    //       title: 'Aug',
    //       dataIndex: 'projection_aug',
    //       width: 90,
    //       align: 'center',
    //       render: (_, record) => getMonthNode(record.projection, 'aug')
    //     },
    //     {
    //       title: 'Sep',
    //       dataIndex: 'projection_sep',
    //       width: 90,
    //       align: 'center',
    //       render: (_, record) => getMonthNode(record.projection, 'sep')
    //     },
    //     {
    //       title: 'Oct',
    //       dataIndex: 'projection_oct',
    //       width: 90,
    //       align: 'center',
    //       render: (_, record) => getMonthNode(record.projection, 'oct')
    //     },
    //     {
    //       title: 'Nov',
    //       dataIndex: 'projection_nov',
    //       width: 90,
    //       align: 'center',
    //       render: (_, record) => getMonthNode(record.projection, 'nov')
    //     },
    //     {
    //       title: 'Dec',
    //       dataIndex: 'projection_dec',
    //       width: 90,
    //       align: 'center',
    //       render: (_, record) => getMonthNode(record.projection, 'dec')
    //     }
    //   ]
    // },
    Table.EXPAND_COLUMN,
    {
      title:<div>Turnaround<br/>Plan</div>,
      dataIndex: 'turnaround_plan',
      width: 120,
      align: 'center',
      render: (_, record) => <span>{record.turnaround_plan}</span>
    },
    {
      title:<div>Market Size<br/>(m EUR) MAT</div>,
      dataIndex: 'market_size_mat',
      width: 120,
      align: 'center',
      render: (_, record) => <span>{(Math.round(record.market_size_mat * 10) / 10).toFixed(1)}</span>
    },
    {
      title:<div>Market<br/>Growth<br/>(%) MAT</div>,
      dataIndex: 'market_growth_mat',
      width: 120,
      align: 'center',
      render: (_, record) => <span>{(Math.round((record.market_growth_mat * 100) * 100) / 100).toFixed(1)}%</span>
    },
  ];

  return <>
    <div className={classNames(style.tableWrap, style.tableBox)}>
      <Table
        rowKey="id"
        columns={columns}
        dataSource={dataList}
        bordered
        size="middle"
        scroll={{ x: '100vw', y: 'calc(100vh - 200px)' }}
        pagination={false}
        onChange={paginateChange}
        expandable={{
          columnTitle: <div style={{textAlign: 'center'}}>BU<br/>Comment<br/> <img src={ExpandDownSvgIcon} alt="" /></div>,
          columnWidth: 120,
          expandIcon: ({ expanded, onExpand, record }) => {
            return expanded ? <CaretUpOutlined style={{cursor: 'pointer'}} onClick={e => onExpand(record, e)} />  : <CaretDownFilled style={{cursor: 'pointer'}} onClick={e => onExpand(record, e)} />;
          },
          expandedRowRender: (record) => <div className={classNames(style.expandRow)}>
            <div className={classNames(style.oneRow)}>
              <div className={classNames(style.describeBox)}>
                <div className={classNames(style.title)}>Key issues</div>
                <div className={classNames(style.des)}>
                  {record.key_issues}
                </div>
              </div>
              <div className={classNames(style.describeBox)} style={{marginLeft: '30px'}}>
                <div className={classNames(style.title)}>Action plan</div>
                <div className={classNames(style.des)}>
                  {record.action_plan}
                </div>
              </div>
            </div>
            {
              userInfo && userInfo.admin && [record.business_unit, 'all'].includes(userInfo.business_unit) && <div className={classNames(style.twoRow)}>
                <div className={classNames(style.editBtn)} onClick={() => openChange(record)}>
                  <img src={EditSvgIcon} alt="" />
                  <span className={classNames(style.editBtnText)}>Edit data</span>
                </div>
              </div>
            }
          </div>,
        }}
      />
    </div>
  </>;
};

export default ShowTableCom;