import React from 'react';
import style from './chart.module.scss';
import options from './chartAll.jsx';
import ReactEcharts from 'echarts-for-react';
import NoData from './noData.jsx';
import ChatTitle from '@/modules/pain-points/components/chart-main/chatTitle.jsx';
import { dataSort } from './common.js';
import Search from './chartSearch.jsx';
import ChartList from '@/modules/pain-points/components/chart-main/chartList.jsx';
import ChartDetail from '@/modules/pain-points/components/chart-main/chartDetail.jsx';

const dataA = [
    [31163, 77.4, 27662440, 'Canada'],
    [1516, 68, 1154605773, 'China'],
    [13670, 74.7, 10582082, 'Cuba'],
    [28599, 75, 4986705, 'Finland'],
    [29476, 77.1, 56943299, 'France'],
    [31476, 75.4, 78958237, 'Germany'],
    [28666, 78.1, 254830, 'Iceland'],
    [11777, 57.7, 870601776, 'India'],
    [29550, 79.1, 122249285, 'Japan'],
    [12076, 67.9, 20194354, 'North Korea'],
    [12087, 72, 42972254, 'South Korea'],
    [24021, 75.4, 3397534, 'New Zealand'],
    [1296, 82.8, 4240375, 'Norway'],
    [10088, 70.8, 38195258, 'Poland'],
    [19349, 69.6, 147568552, 'Russia'],
    [10670, 67.3, 53994605, 'Turkey'],
    [26424, 75.7, 57110117, 'United Kingdom'],
    [37062, 75.4, 252847810, 'United States'],
    [44056, 71.8, 1154605773, 'Australia'],
];
let dataB = [
  {
    name: 'Corgi501',
    value: 501,
  },
  {
    name: 'Corgi602',
    value: 602,
  },
  {
    name: 'Corgi703',
    value: 703,
  },
  {
    name: 'Corgi804',
    value: 804,
  },
  {
    name: 'Corgi905',
    value: 905,
  },
  {
    name: 'Corgi506',
    value: 506,
  },
  {
    name: 'Corgi67',
    value: 67,
  },
  {
    name: 'Corgi78',
    value: 78,
  },
  {
    name: 'Corgi89',
    value: 89,
  },
  {
    name: 'Corgi91',
    value: 91,
  },
  {
    name: 'Corgi5',
    value: 5,
  },
  {
    name: 'Corgi6',
    value: 6,
  },
  {
    name: 'Corgi265',
    value: 265,
  },
  {
    name: 'Corgi421',
    value: 421,
  },
  {
    name: 'Corg\n\ti143',
    value: 143,
  },
];

const dataC = [
  { name: '1', content: 'Racing car sprays burning fuel into crowd.' },
  { name: '2', content: 'Japanese princess to wed commoner.' },
  { name: '3', content: 'Australian walks 100km after outback crash.' },
  { name: '4', content: 'Man charged over missing wedding girl.' },
  { name: '5', content: 'Racing car sprays burning fuel into crowd.' },
  { name: '6', content: 'Japanese princess to wed commoner.' },
  { name: '7', content: 'Australian walks 100km after outback crash.' },
  { name: '8', content: 'Man charged over missing wedding girl.' },
  { name: '9', content: 'Los Angeles battles huge wildfires.' },
  { name: '0', content: 'Racing car sprays burning fuel into crowd.' },
  { name: 'a', content: 'Japanese princess to wed commoner.' },
  { name: 's', content: 'Australian walks 100km after outback crash.' },
  { name: 'd', content: 'Man charged over missing wedding girl.' },
  { name: 'f', content: 'Los Angeles battles huge wildfires.' },
  { name: 'g', content: 'Racing car sprays burning fuel into crowd.' },
  { name: 'h', content: 'Japanese princess to wed commoner.' },
  { name: 'j', content: 'Australian walks 100km after outback crash.' },
  { name: 'k', content: 'Man charged over missing wedding girl.' },
  { name: 'l', content: 'Los Angeles battles huge wildfires.' },
];

const dataD = {};


const ChartMain = () => {

  // const changeDataA = ()=>{
  //   dataA =
  // }

  const dataAToDataB = (data) => {
    const dataList = data.map(i => ({ name: i[3], value: i[2] }));
    dataB = dataList.sort((a, b) => a.value - b.value)
    return dataB
  };

  let dataARef,dataBRef

  const dataAOnClick = (param) => {
    const echartInstance = dataBRef.getEchartsInstance();
    echartInstance.dispatchAction({
      type: 'select',
      name:param.value[3]
    })
  };

  const dataBOnClick = (param) => {
    const echartInstance = dataARef.getEchartsInstance();
    echartInstance.dispatchAction({
      type: 'select',
      dataIndex: dataB.findIndex(i=>i.name===param.value.name)
    })
  };


  return <div className={style.chartMain}>
    <div className={style.chartTop}>
      <div className={style.chartLeftTop}>
        <ChatTitle text="Top 10 Unmet Needs" />
        {
          !dataA.length ? <NoData />
            : <ReactEcharts style={{ height: '100%', width: '100%' }}
                            ref={(e) => { dataARef = e; }}
                            option={options.optionA(dataA.sort((a, b) => a[2] - b[2]))}
                            onEvents={{ 'click': dataAOnClick }} />
        }
      </div>
      <div className={style.chartRightTop}>
        <ChatTitle text="Unmet Need Full List" />
        {
          !dataA.length ? <NoData />
            : <>
              <Search />
              <ReactEcharts style={{ height: '100%', width: '100%' }}
                            ref={(e) => { dataBRef = e; }}
                            option={options.optionB(dataAToDataB(dataA))}
                            onEvents={{ 'click': dataBOnClick }}/></>
        }
        {/*<ChatTitle text="Unmet Need Full List" />*/}
        {/*<ReactEcharts style={{ height: '100%', width: '100%' }} option={options.optionB(dataB)} />*/}
      </div>
    </div>

    <div className={style.chartBottom}>
      <div className={style.chartLeftBottom}>
        <ChatTitle text="Consumer Verbatims" />
        {
          !dataC.length ? <NoData />
            : <ChartList data={dataC} />
        }
      </div>
      <div className={style.chartRightBottom}>
        <ChatTitle text="Average Ratings" />
        {
          !dataD ? <NoData />
            : <ChartDetail data={dataD} />
        }
      </div>
    </div>
  </div>;
};

export default ChartMain;
