import { App, ConfigProvider, Input, message, Radio, theme } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  postGenerativeFill,
  postUploadImage,
} from '../../apis/serve/adobeFirefly';
import Box from '../../components/box.jsx';
import { PackageAiBtn2, PackageAiBtn3 } from '../../components/btn.jsx';
import Container from '../../components/container.jsx';
import { PackageAiFormItem } from '../../components/form.jsx';
import Cursor from './cursor.jsx';
import Editor from './editor.jsx';
import style from './index.module.scss';
import Options from './options.jsx';
import Tool from './tool.jsx';

const { TextArea } = Input;

function PackageAiView() {
  const navigate = useNavigate();

  const context = JSON.parse(
    sessionStorage.getItem('packageAiContext') ?? '{}'
  );
  const [url, setUrl] = useState(context.edit?.imageUrl ?? context.base.url);
  const [adobeUrl, setAdobeUrl] = useState(null);

  const [loading, setLoading] = useState(false);
  const [func, setFunc] = useState(1);
  const [cursor, setCursor] = useState(1);
  const [brushScale, setBrushScale] = useState(2);
  const [prompt, setPrompt] = useState();
  // const [redrawStrength, setRedrawStrength] = useState(1);
  const [scale, setScale] = useState(1);
  const [clearCount, setClearCount] = useState(0);
  const [resetCount, setResetCount] = useState(0);
  const [path, setPath] = useState([]);
  const [drawRef, setDrawRef] = useState(null);

  const [undoUrl, setUndoUrl] = useState(null);

  // options
  const [optionList, setOptionList] = useState(null);
  const [optionIndex, setOptionIndex] = useState(0);
  // use first option as default
  useEffect(() => {
    if (!optionList?.length) return;
    const si = optionList[0];
    setUrl2(si.src);
    setAdobeUrl(si.src);
  }, [optionList]);
  // change option
  useEffect(() => {
    if (!optionList?.length) return;
    const si = optionList[optionIndex];
    if (!si) {
      console.warn('optionIndex out of range', optionIndex);
      return;
    }
    setUrl(si.src);
    setAdobeUrl(si.src);
  }, [optionIndex]);

  const setUrl2 = useCallback(
    (newUrl) => {
      setUndoUrl(url);
      setUrl(newUrl);
    },
    [url, setUrl, setUndoUrl]
  );

  function onFuncChange(e) {
    setFunc(e.target.value);

    // clear data
    // setPath([]);
  }

  // function onBrushScaleChange(e) {
  //   setBrushScale(e.target.value);
  // }

  function onPromptChange(e) {
    setPrompt(e.target.value);
  }

  async function onGenerate() {
    if (!drawRef) return;
    if (path.length === 0) {
      message.warning('Please draw something');
      return;
    }
    if (func === 1) {
      if (prompt && prompt.length > 1024) {
        message.warning('Prompt is too long, max length is 1024');
        return;
      }
    }
    if (loading) return;
    setLoading(true);
    try {
      const source = {};

      if (!adobeUrl) {
        const imgBlob = await fetch(url).then((res) => res.blob());

        const { status: s0, data: d0 } = await postUploadImage(imgBlob);
        if (s0 !== 'Success') {
          message.error('Upload image fail');
          console.error('upload image fail 1', s0, d0);
          return;
        }
        if (!d0.images?.length) {
          message.error('Upload image fail');
          console.error('upload image fail 2', d0);
          return;
        }
        source.uploadId = d0.images[0].id;
      } else {
        source.url = adobeUrl;
      }

      if (func === 1) {
        // use mask image data
        const maskBase64 = drawRef.toDataURL();
        // window.open(maskBase64, '_blank');
        const maskBlob = await fetch(maskBase64).then((res) => res.blob());

        // upload mask
        const { status: s1, data: d1 } = await postUploadImage(maskBlob);
        if (s1 !== 'Success') {
          message.error('Upload mask fail');
          console.error('upload mask fail 1', s1, d1);
          return;
        }
        if (!d1.images?.length) {
          message.error('Upload mask fail');
          console.error('upload mask fail 2', d1);
          return;
        }

        // generate image
        const { status: s2, data: d2 } = await postGenerativeFill({
          prompt: prompt,
          image: {
            source: source,
            mask: { uploadId: d1.images[0].id },
          },
          numVariations: 3,
        });
        if (s2 !== 'Success') {
          message.error('Generate image fail');
          console.error('generate image fail', s2, d2);
          return;
        }
        if (!d2?.outputs?.length) {
          message.error('Generate image fail');
          console.error('generate image fail', d2);
          return;
        }

        // set data
        setOptionList(d2.outputs.map((o) => ({ src: o.image.url })));

        // clear data
        setPath([]);
      } else if (func === 2) {
        // purge
        const maskBase64 = drawRef.toDataURL();
        // window.open(maskBase64, '_blank');
        const maskBlob = await fetch(maskBase64).then((res) => res.blob());

        // upload mask
        const { status: s1, data: d1 } = await postUploadImage(maskBlob);
        if (s1 !== 'Success') {
          message.error('Upload mask fail');
          console.error('upload mask fail 1', s1, d1);
          return;
        }
        if (!d1.images?.length) {
          message.error('Upload mask fail');
          console.error('upload mask fail 2', d1);
          return;
        }

        // generate image
        const { status: s2, data: d2 } = await postGenerativeFill({
          prompt: 'remove',
          image: {
            source: source,
            mask: { uploadId: d1.images[0].id },
          },
        });
        if (s2 !== 'Success') {
          message.error('Generate image fail');
          console.error('generate image fail', s2, d2);
          return;
        }
        if (!d2?.outputs?.length) {
          message.error('Generate image fail');
          console.error('generate image fail', d2);
          return;
        }
        const outputUrl = d2.outputs[0].image.url;

        // set data
        setUrl2(outputUrl);
        setAdobeUrl(outputUrl);

        // clear data
        setPath([]);
        setOptionList(null);
      } else {
        message.error('Please select a function');
      }
    } catch (err) {
      console.error('generate image error', err);
      message.error('Generate image error');
    }
    setLoading(false);
  }

  function onNext() {
    context.edit = { imageUrl: url };
    sessionStorage.setItem('packageAiContext', JSON.stringify(context));
    navigate('/package-ai-generation3');
  }

  function onClear() {
    setClearCount((c) => c + 1);
    onReset();
  }

  const onUndo = useCallback(() => {
    if (!undoUrl) return;
    setUndoUrl(null);
    setUrl(undoUrl);
    setPath([]);
  }, [undoUrl]);

  function onReset() {
    setScale(1);
    setResetCount((c) => c + 1);
  }

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: '#00a32e',
          colorError: '#eb0000',
          colorSuccess: '#06ca3d',
          fontFamily: 'unilever-shilling-regular',
        },
        algorithm: theme.darkAlgorithm,
      }}
    >
      <App>
        <Container step={2}>
          <div className={style.box}>
            <Box className={style.box1} loading={loading}>
              <Editor
                url={url}
                scale={scale}
                setScale={setScale}
                brushScale={brushScale}
                clearCount={clearCount}
                resetCount={resetCount}
                func={func}
                cursor={cursor}
                data={path}
                setData={setPath}
                setDrawRef={setDrawRef}
                setLoading={setLoading}
              />
              <Tool
                setScale={setScale}
                onClear={onClear}
                onUndo={onUndo}
                undoDisabled={!undoUrl}
                onReset={onReset}
              />
              <Cursor cursor={cursor} setCursor={setCursor} />
              <div className={style.info1}>
                Due to AI guidance and legislation, all generated assets can
                <br />
                only be used internally.
              </div>
              <Options
                className={style.options}
                list={optionList}
                selected={optionIndex}
                onSelect={setOptionIndex}
              />
            </Box>
            <Box className={style.box2} hasHeader={true} title="Editor Mode">
              <div className={style.box2Content}>
                <PackageAiFormItem label="Editor Mode">
                  <Radio.Group value={func} onChange={onFuncChange}>
                    <Radio value={1}>Prompt Generator</Radio>
                    <Radio value={2}>Eraser</Radio>
                  </Radio.Group>
                </PackageAiFormItem>
                {/* <PackageAiFormItem label="Brush Scale">
                  <Radio.Group value={brushScale} onChange={onBrushScaleChange}>
                    <Radio value={1}>Small</Radio>
                    <Radio value={2}>Medium</Radio>
                    <Radio value={3}>Large</Radio>
                  </Radio.Group>
                </PackageAiFormItem> */}
                {func === 1 && (
                  <PackageAiFormItem label="Prompt">
                    <TextArea
                      value={prompt}
                      onChange={onPromptChange}
                      placeholder="Input prompt"
                      style={{ height: 120 }}
                    />
                  </PackageAiFormItem>
                )}
                <div className={style.btnLine}>
                  <PackageAiBtn2 onClick={onGenerate}>
                    {func === 1 ? 'Generate' : 'Erase'}
                  </PackageAiBtn2>
                  <PackageAiBtn3 onClick={onNext}>Next</PackageAiBtn3>
                </div>
              </div>
            </Box>
          </div>
        </Container>
      </App>
    </ConfigProvider>
  );
}

export default PackageAiView;
