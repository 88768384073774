import React, { useRef } from 'react';

import ReactECharts from 'echarts-for-react';
import * as echarts from 'echarts/core';
import { CanvasRenderer } from 'echarts/renderers';

echarts.use(
  [CanvasRenderer]
);

const LineCharts = function LineCharts({options, chartClick}) {

  const echartsRef = useRef(null); 

  function onChartClick(params) {
    const { seriesIndex, dataIndex } = params;
    const series = options.series.map((ser, serIndex) => {
      // 恢复
      ser.data = ser.data.map(item => {
        return {
          ...item,
          symbolSize: 3,
          label: {
            fontSize: 12,
          },
        };
      });
      if (serIndex === seriesIndex) {
        ser.data = ser.data.map(function(item, index) {
          // 根据点击的节点索引修改节点的样式
          if (index === dataIndex) {
            return {
              ...item,
              symbolSize: 10,
              label: {
                fontSize: 14,
              },
            };
          }
          return item;
        });
      }
      return ser;
    });

    echartsRef.current.getEchartsInstance().setOption({
      series
    });

    const breakdown = params.data.breakdown;
    chartClick(breakdown);
  }

  return (
    <ReactECharts
      ref={echartsRef}
      echarts={echarts}
      option={options}
      style={{
        height: '340px'
      }}
      onEvents={{
        'click': onChartClick
      }}
    />
  );
};

export default LineCharts;