import React, { useState, useEffect, useRef } from 'react';
import MarketSelect from '../top-dish-market/top-dish-market.jsx';
import TopDishSelect from '../top-dish-filtern/top-dish-filter.jsx';
import BrandSelect from '../brand-filter/brand-filter.jsx';
import TopDishesTable from '../top-dish-table/top-dish-table.jsx';
import { fetchLookup, fetchWordCloud } from '../../api/request.js';
// import WordCloud from '../word-cloud/word-cloud.jsx';
import { aiTopDishInfo, chevronDownIcon,selectBackground } from '../../../../components/icons/icons.jsx';
import GuidePopup from '../guide-pop-up/guide-pop-up.jsx';
// import MarketBrandDishFilterBox from '../market-brand-dish-filter-box.jsx';
import style from './market-brand-dish.scss';

var chooseMarketData = []
var chooseBrandData = []
var chooseDishData = []
const MarketBrandDishFilter = () => {
  const [lookupData, setLookupData] = useState([]);
  const [marketData, setMarketData] = useState([]);
  const [selectedMarkets, setSelectedMarkets] = useState(['US']);
  const [selectedBrands, setSelectedBrands] = useState(['Knorr', 'Hellmann\'s']);
  const [selectedDishes, setSelectedDishes] = useState(['Pasta']);
  const [selectedWordCloud, setSelectedWordCloud] = useState([]);
  const [loading, setLoading] = useState(true);
  const [err, setErr] = useState(false);
  const topDishesTableRef = useRef(null);
  const [newMarketData, setNewMarketData] = useState([]);
  const [wordCSelectedData, setWordCSelectedData] = useState([]);
  const [headerTitle, setHeaderTitle] = useState('Top Dish Variant');

  useEffect(() => {
    fetchLookup()
      .then(res => {
        const data = res.data || {};
        const marketData = data.market || [];
        setLookupData(marketData);
        setMarketData(marketData);
        const defaultMarketData = marketData.find(market => selectedMarkets.includes(market.name)) || { brands: [] };
        if (defaultMarketData.brands.length > 0) {
          const firstBrand = defaultMarketData.brands[0];
          const topDishCloud = firstBrand.topDishes[0];
          const data = {
            countries: selectedMarkets,
            brands: [firstBrand.name],
            top_dish: topDishCloud
          };
          setWordCSelectedData([topDishCloud])
          topDishWordApi(data);
        }
      })
      .catch(err => {
        console.error('fetchLookup error:', err);
        setErr(true);
      });
  }, []);
  useEffect(() => {
    if (selectedMarkets.length > 0 && selectedBrands.length > 0) {
      handleMarketChange(selectedMarkets);
    }
  }, []);

  const topDishWordApi = (data) => {
    setLoading(true);
    fetchWordCloud(data)
      .then(res => {
        const wordRes = res.data || [];
        if (wordRes.length > 0) {
          setSelectedWordCloud(wordRes);
        }
      })
      .catch(err => {
        console.error('fetchWordCloud error:', err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleMarketChange = async (values) => {
    setSelectedMarkets(values);
    chooseMarketData = values
    try {
      // Fetch or refresh lookup data
      const res = await fetchLookup();
      const data = res.data || {};
      const fetchedMarketData = data.market || [];

      // Update state with fetched data
      setLookupData(fetchedMarketData);
      //setMarketData(fetchedMarketData);
      setNewMarketData(fetchedMarketData);

      // Get brands associated with the selected markets
      const brandsForSelectedMarkets = values.flatMap(market =>
        (fetchedMarketData.find(data => data.name === market)?.brands || [])
      );

      // Deduplicate brands
      const uniqueBrands = removeDuplicatesAndMerge(brandsForSelectedMarkets, 'name');

      // Set the selected brand and dishes based on the updated unique brands
      if (uniqueBrands.length > 0) {
        setSelectedBrands([uniqueBrands[0].name]);
        chooseBrandData = [uniqueBrands[0].name]

        // Find the top dishes for the selected brands
        const topDishes = uniqueBrands.flatMap(brand =>
          brand.topDishes
        );

        setSelectedDishes(topDishes.length > 0 ? [topDishes[0]] : []);
        chooseDishData = topDishes.length > 0 ? [topDishes[0]] : []
      } else {
        setSelectedBrands([]);
        setSelectedDishes([]);
        chooseBrandData = []
        chooseDishData = []
      }
      handleButtonClick()
    } catch (error) {
      console.error('Error handling market change:', error);
    }
  };

  const handleBrandChange = (values) => {
    setSelectedBrands(values);
    chooseBrandData = values

    // Get top dishes for selected brands across all selected markets
    const topDishes = selectedMarkets.flatMap(marketName => {
      const market = lookupData.find(data => data.name === marketName);
      if (market) {
        return market.brands
          .filter(brand => values.includes(brand.name)) // Filter by selected brands
          .flatMap(brand => brand.topDishes); // Get top dishes for these brands
      }
      return [];
    });

    // Deduplicate dishes
    const uniqueTopDishes = [...new Set(topDishes)];

    // Select the first top dish if available
    const firstTopDish = uniqueTopDishes.length > 0 ? [uniqueTopDishes[0]] : [];

    // Set the state
    setSelectedDishes(firstTopDish);
    chooseDishData = firstTopDish
    handleButtonClick()
  };

  const handleDishChange = (value) => {
    setSelectedDishes(value);
    chooseDishData = value
    handleButtonClick()
  };

  function removeDuplicatesAndMerge(arr, prop) {
    const uniqueMap = new Map();

    arr.forEach(obj => {
      const key = obj[prop];
      if (!uniqueMap.has(key)) {
        uniqueMap.set(key, obj);
      } else {
        const existingObj = uniqueMap.get(key);
        existingObj.topDishes = Array.from(new Set([...existingObj.topDishes, ...obj.topDishes]));
      }
    });

    return Array.from(uniqueMap.values());
  }

  const uniqueBrandsForDropdown = removeDuplicatesAndMerge(
    selectedMarkets.flatMap(market =>
      (lookupData.find(data => data.name === market)?.brands || [])
    ), 'name'
  );

  const uniqueTopDishes = selectedBrands.flatMap(brandName =>
    lookupData.flatMap(market => {
      const isSingleSelectedMarket = selectedMarkets.length === 1 && selectedMarkets[0] === market.name;
      const isTwoSelectedMarkets = selectedMarkets.length === 2 && selectedMarkets.includes(market.name);

      if (isSingleSelectedMarket || isTwoSelectedMarkets) {
        const brand = market.brands.find(brand => brand.name === brandName);
        return brand ? brand.topDishes : [];
      }
      return [];
    })
  );

  const uniqueDishesSet = [...new Set(uniqueTopDishes)];

  const dropdownListStyle = {
    background: '#18181B',
    borderRadius: '0px',
    boxShadow: '0px 0px 12px 0px #00A32E',
    fontFamily: 'unilever-shilling-regular'
  };

  // const [isPopupVisible, setIsPopupVisible] = useState(false);
  // const togglePopup = () => {
  //   setIsPopupVisible(!isPopupVisible);
  // };

  const handleButtonClick = () => {
    // console.log('dasdas',selectedMarkets,selectedBrands,selectedDishes)
    // console.log('data',chooseMarketData,chooseBrandData,chooseDishData)
    if (chooseDishData && chooseDishData.length === 1) {
      const data = {
        countries: chooseMarketData,
        brands: chooseBrandData,
        top_dish: chooseDishData.toString()
      };
      // console.log('aaaaa',data)
      topDishWordApi(data);
      setWordCSelectedData(selectedDishes)
    }
    // if (selectedDishes && selectedDishes.length === 1) {
    //   const data = {
    //     countries: selectedMarkets,
    //     brands: selectedBrands,
    //     top_dish: selectedDishes.toString()
    //   };
    //   // console.log('aaaaa',data,selectedDishes)
    //   topDishWordApi(data);
    //   setWordCSelectedData(selectedDishes)
    // }

    topDishesTableRef.current.handleApplyClick({
      countries: chooseMarketData,
      brands: chooseBrandData,
      top_dish: chooseDishData
    });
  };

  
  const handleEditButtonClick = (e) => {
    e.stopPropagation();
    setEditColumns((prevFilterOpen) => !prevFilterOpen);
  };
  

  return (
    <>
      {/* <GuidePopup isVisible={isPopupVisible} onClose={togglePopup} /> */}
      {/* <MarketBrandDishFilterBox/> */}

      <div className='filter-container newPlace'>
        <div className="inputBorder">
          {/* <h2>Market</h2> */}
          {/* <div > */}
            <MarketSelect
              mode="multiple"
              //maxTagCount={1}
              className='custom-select'
              dropdownStyle={dropdownListStyle}
              markets={newMarketData.map(market => market)}
              selectedMarket={selectedMarkets}
              onChange={handleMarketChange}
            />
          </div>
        {/* </div> */}
        <div className="inputBorder">
          {/* <h2>Brand</h2> */}
          <BrandSelect
            mode="multiple"
            dropdownStyle={dropdownListStyle}
            brands={uniqueBrandsForDropdown.map(brand => brand)}
            selectedBrands={selectedBrands}
            onChange={handleBrandChange}
          />
        </div>
        <div className="inputBorder">
          {/* <h2>Top Dish</h2> */}
          <TopDishSelect
            maxTagCount={1}
            dropdownStyle={dropdownListStyle}
            topDishes={uniqueDishesSet}
            selectedDishes={selectedDishes}
            onChange={handleDishChange}
          />

        </div>
        {/* <div className="inputBorder">
          <button type="button" className="applyButton btn px-16" onClick={handleButtonClick}>
            Apply
          </button>
        </div> */}
        {/* <div className="inputBorder guide-icon">How to use<span onClick={togglePopup}><img src={aiTopDishInfo} alt='info' /></span></div> */}
      </div>
      <div className='pt-20 pb-50 ml-10'>
        <TopDishesTable
          ref={topDishesTableRef}
          brand={selectedBrands}
          value={selectedDishes}
          country={selectedMarkets}
          marketData={marketData}
          selectedWordCloud={selectedWordCloud}
          loading={loading}
          err={err}
        />
      </div>
      {/* <div>
        {wordCSelectedData && wordCSelectedData.length > 1 ?  <div className='bottom-space-table'>&nbsp;</div>  : (
          <div className='ml-10'>
            <WordCloud 
              selectedWordCloud={selectedWordCloud} 
              wordCSelectedData={wordCSelectedData} 
              loading={loading} 
              err={err}
            />
          </div>
        )}
      </div> */}
    </>
  );
};

export default MarketBrandDishFilter;