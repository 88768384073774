import React from 'react';
import './tailwind.css';
import './main.scss';
import { ConfigProvider } from 'antd';
import BasicLayout from './layouts/BasicLayout.jsx';

const  MainRootPage = () => {
  return (
    <ConfigProvider theme={{
      token: {
        colorBorder: '#00A32E',
        colorPrimary: '#00A32E',
          
        colorBgContainerDisabled: '#00A32E80',
        colorTextDisabled: '#ffffff'
      }
    }}>
      <BasicLayout></BasicLayout>
    </ConfigProvider>
  );
};

export default MainRootPage;