// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.u3ky0T57OPBrwRNbcLwL{padding:10px 15px;background-color:#010f02;border-radius:20px;display:flex;flex-direction:column;align-items:stretch}.oE_d88RQctB9_MhDu3Fa{display:flex;justify-content:space-between;align-items:center}.ERNHYobDzxukArgd6sbS,.wVUQjcOoTdDlC2NLnzr7{width:25%}.gg2i5ytULbIiSwCze0Jc{margin-top:15px;flex:1;overflow:hidden}.gg2i5ytULbIiSwCze0Jc .zcUDJg4SytvgiiQ8MG4L{width:100%;height:100%}.JxKZIucbpAAa792wTi1x .yIysSmoxLZ56p_x1f9IF{padding:0 10px;font-size:16px;font-weight:400;line-height:22.3px;color:#fff}.JxKZIucbpAAa792wTi1x .Uljyk6vZvF0d0HplJ2MN{margin-top:5px;width:100%}`, "",{"version":3,"sources":["webpack://./src/modules/package-ai-performance/views/competitive/chart/index.module.scss"],"names":[],"mappings":"AACA,sBACE,iBAAA,CACA,wBAAA,CACA,kBAAA,CACA,YAAA,CACA,qBAAA,CACA,mBAAA,CAGF,sBACE,YAAA,CACA,6BAAA,CACA,kBAAA,CAGF,4CAEE,SAAA,CAGF,sBACE,eAAA,CACA,MAAA,CACA,eAAA,CAEA,4CACE,UAAA,CACA,WAAA,CAMF,4CACE,cAAA,CACA,cAAA,CACA,eAAA,CACA,kBAAA,CACA,UAAA,CAGF,4CACE,cAAA,CACA,UAAA","sourcesContent":["// chart\n.box1 {\n  padding: 10px 15px;\n  background-color: #010f02;\n  border-radius: 20px;\n  display: flex;\n  flex-direction: column;\n  align-items: stretch;\n}\n\n.box11 {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n}\n\n.box111,\n.box112 {\n  width: 25%;\n}\n\n.box12 {\n  margin-top: 15px;\n  flex: 1;\n  overflow: hidden;\n\n  .chart {\n    width: 100%;\n    height: 100%;\n  }\n}\n\n// axis select\n.axisSelect {\n  .label {\n    padding: 0 10px;\n    font-size: 16px;\n    font-weight: 400;\n    line-height: 22.3px;\n    color: #fff;\n  }\n\n  .select {\n    margin-top: 5px;\n    width: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"box1": `u3ky0T57OPBrwRNbcLwL`,
	"box11": `oE_d88RQctB9_MhDu3Fa`,
	"box111": `ERNHYobDzxukArgd6sbS`,
	"box112": `wVUQjcOoTdDlC2NLnzr7`,
	"box12": `gg2i5ytULbIiSwCze0Jc`,
	"chart": `zcUDJg4SytvgiiQ8MG4L`,
	"axisSelect": `JxKZIucbpAAa792wTi1x`,
	"label": `yIysSmoxLZ56p_x1f9IF`,
	"select": `Uljyk6vZvF0d0HplJ2MN`
};
export default ___CSS_LOADER_EXPORT___;
