import { Col, message, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { putFileKey } from '../../apis/file';
import Box from '../../components/box2.jsx';
import ImgDialog from '../../components/imgDialog.jsx';
import Legend from '../../components/legend.jsx';
import Loading from '../../components/loading.jsx';
import style from './brand.module.scss';
import { TableBodyCellValue, TableHeaderCell } from './table.jsx';
import tableStyle from './table.module.scss';
import Title from './title.jsx';

function Table({
  className = '',
  labels = [],
  values = [],
  reverse = false,
  threshold = [40, 70],
}) {
  return (
    <table className={`${tableStyle.table} ${className}`}>
      <thead>
        <tr>
          {labels.map((label, index) => {
            return <TableHeaderCell key={index}>{label}</TableHeaderCell>;
          })}
        </tr>
      </thead>
      <tbody>
        <tr>
          {values[0].map((value, index) => {
            return (
              <TableBodyCellValue
                key={index}
                index={index}
                value={value}
                reverse={reverse}
                threshold={threshold}
              />
            );
          })}
        </tr>
        <tr>
          {values[1].map((value, index) => {
            return (
              <TableBodyCellValue
                key={index}
                index={index}
                value={value}
                reverse={reverse}
                threshold={threshold}
              />
            );
          })}
        </tr>
        <tr className={className}>
          {values[2].map((value, index) => {
            return (
              <TableBodyCellValue
                key={index}
                index={index}
                value={value}
                reverse={reverse}
                threshold={threshold}
              />
            );
          })}
        </tr>
        <tr className={className}>
          {values[3].map((value, index) => {
            return (
              <TableBodyCellValue
                key={index}
                index={index}
                value={value}
                reverse={reverse}
                threshold={threshold}
              />
            );
          })}
        </tr>
      </tbody>
    </table>
  );
}

function Item3({ className = '', title, url, onClick }) {
  function onImgClick() {
    onClick && onClick(url);
  }

  return (
    <div className={`${style.item} ${className}`}>
      <div className={style.itemTitle}>{title}</div>
      <div className={style.itemImg} onClick={onImgClick}>
        <img src={url} alt={title} />
      </div>
    </div>
  );
}

function PackageAiBody({ useNextBtn, context: ctx }) {
  // table1
  const [column1, setColumn1] = useState([
    '',
    'Our Brand Current',
    'Our Brand New Design',
    'Competitor 1',
    'Competitor 2',
  ]);
  const [value1, setValue1] = useState([
    ['Brand Logo', 0.0, 0.0, 0.0, 0.0],
    ['Variant Name', 0.0, 0.0, 0.0, 0.0],
    ['Secondary Brand Blocking (Optional)', 0.0, 0.0, 0.0, 0.0],
    ['Secondary Variant Differentiation (Optional)', 0.0, 0.0, 0.0, 0.0],
  ]);
  // image list
  const [data3, setData3] = useState([]);

  const [loading, setLoading] = useState(true);
  async function init() {
    try {
      const pp = ctx?.res?.predictPackage;
      if (!pp) return;
      const variants = ctx?.res?.variants;
      if (!variants) return;
      const column = [''];
      const value = [
        ['Brand Logo'],
        ['Variant Name'],
        ['Secondary Brand Blocking (Optional)'],
        ['Secondary Variant Differentiation (Optional)'],
      ];
      const image = [];

      const processValue = async (name, v) => {
        const vas = v?.vasresult;
        if (vas) {
          column.push(name);
          value[0].push(vas.logo || '');
          value[1].push(vas.variant || '');
          value[2].push(vas.brand || '');
          value[3].push(vas.aoi || '');
        }
        const hi = v?.roiimage;
        if (hi) {
          const { data } = await putFileKey(hi);
          image.push({
            title: name,
            url: data.url,
          });
        }
      };

      // ul brand
      if (pp.ul_design1_variant0_id) {
        const uld0v = variants[pp.ul_design0_variant0_id];
        await processValue(`${pp.brand_ul} Current`, uld0v);
        const uld1v = variants[pp.ul_design1_variant0_id];
        await processValue(`${pp.brand_ul} New Design`, uld1v);
        // const uld2v = variants[pp.ul_design2_variant0_id];
        // await processValue(`${pp.brand_ul} New Design 2`, uld2v);
        // const uld3v = variants[pp.ul_design3_variant0_id];
        // await processValue(`${pp.brand_ul} New Design 3`, uld3v);
      } else {
        const uld0v = variants[pp.ul_design0_variant0_id];
        await processValue(pp.brand_ul, uld0v);
      }
      // c1 brand
      const c1d1v = variants[pp.c1_design1_variant0_id];
      await processValue(pp.brand_c1, c1d1v);
      // c2 brand
      const c2d1v = variants[pp.c2_design1_variant0_id];
      await processValue(pp.brand_c2, c2d1v);

      setColumn1(column);
      setValue1(value);
      setData3(image);
    } catch (err) {
      console.error('init error', err);
      message.error('Get data error');
    }
    setLoading(false);
  }
  useEffect(() => {
    void init();
  }, [ctx]);

  const [dialogVisible, setDialogVisible] = useState(false);
  const [dialogImg, setDialogImg] = useState('');
  function onImgClick(url) {
    setDialogImg(url);
    setDialogVisible(true);
  }

  return (
    <div className={style.box1}>
      {loading && <Loading />}
      <Row gutter={36}>
        <Col span={13}>
          <Title>Brand Visibility Score</Title>
          <div className={style.tableBox}>
            <Table labels={column1} values={value1} />
          </div>
          <Legend className={style.legend} />
        </Col>
        <Col span={11}>
          <Title>DBA Hotspot</Title>
          <div className={style.items}>
            {data3.map((item, index) => {
              return (
                <Item3
                  key={`${item.title}-${index}`}
                  title={item.title}
                  url={item.url}
                  onClick={onImgClick}
                />
              );
            })}
          </div>
        </Col>
      </Row>
      <Row className={style.box2} gutter={36}>
        <Col span={13}>
          <Box>
            <div className={style.text1}>
              Note:
              <br />
              The scores above, in percentages, indicate the chance of each
              highlighted design element being noticed in the initial 3-5
              seconds, outside a shelf context
            </div>
          </Box>
        </Col>
        <Col span={11}>
          <Box>
            <div className={style.text1}>
              Note:
              <br />
              The hotspot highlights prominent pack design elements, with
              percentages indicating the likelihood of them being noticed within
              the first 3-5 seconds.
            </div>
          </Box>
        </Col>
      </Row>
      <div className={style.btnBox}>{useNextBtn && useNextBtn({})}</div>
      <ImgDialog
        visible={dialogVisible}
        setVisible={setDialogVisible}
        src={dialogImg}
      />
    </div>
  );
}

export default PackageAiBody;
