import { EditOutlined, DragOutlined } from '@ant-design/icons';
import React from 'react';
import style from './cursor.module.scss';

function PackageAiTool({ cursor = 1, setCursor }) {
  return (
    <div className={style.cursorBox}>
      <EditOutlined
        className={`${style.cursorBtn} ${cursor === 1 ? style.active : ''}`}
        onClick={() =>
          setCursor((c) => {
            if (c === 1) return 0;
            return 1;
          })
        }
      />
      <DragOutlined
        className={`${style.cursorBtn} ${cursor === 2 ? style.active : ''}`}
        onClick={() => {
          setCursor((c) => {
            if (c === 2) return 0;
            return 2;
          });
        }}
      />
    </div>
  );
}

export default PackageAiTool;
