const LayerMiddleDataUS = {
  'CONSCIOUS CHOICES': [],
  'HEALTHIER LIVING': [
    {
      name: 'PB&Beyond',
      value: 5.3
    },
    {
      name: 'Better for you',
      value: 10.7
    },
  ],
  'COOKING RENAISSANCE': [
    {
      name: 'meals that matter',
    },
  ],
  'FLAVOR EXPLORATION': [
    {
      name: 'Flavor Exploration'
    }
  ],
  'SMART SOLUTIONS': [
    {
      name: 'Smart Solutions'
    }
  ],
  'SMART COOKING AND SPENDING': [
    {
      name: 'Smart Solutions'
    }
  ],
  'ANYTIME ANYWHERE': [
    {
      name: 'ANYTIME ANYWHERE'
    }
  ],
};

export default LayerMiddleDataUS;