import React, { useEffect, memo} from 'react';

import ReactECharts from 'echarts-for-react';
import * as echarts from 'echarts/core';
import { CanvasRenderer } from 'echarts/renderers';

echarts.use(
  [CanvasRenderer]
);

const LineCharts = memo(function LineCharts({options}) {

  useEffect(() => {
    
  }, []);

  function onChartClick() {

  }

  return (
    <ReactECharts
      echarts={echarts}
      option={options}
      style={{
        height: '400px'
      }}
      onEvents={{
        'click': onChartClick
      }}
    />
  );
});

export default LineCharts;