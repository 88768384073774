import request from './fetch';

export const topDishGetList = (params = {}) => {
  // console.log('topDishGetList', params);
  return request({
    url: '/v1/top-dish/list',
    method: 'get',
    params
  });
};

