import React, { useEffect, useState } from 'react';
import { downloadWb } from '../../../apis/file/index.js';
import BarColChart from '../charts/bar-col.jsx';
import BarChart from '../charts/bar.jsx';
import style from './tab.module.scss';

function FoodPurchase({ filter, compare, hasCompare, exportCount, isActive }) {
  const saveDataFns = [];
  function appendSaveDataFn(fn) {
    saveDataFns.push(fn);
  }

  useEffect(() => {
    if (!isActive) return;
    if (!exportCount) return;
    downloadWb(saveDataFns, 'xlsx', 'Food Purchase');
  }, [exportCount]);

  const [userSelectChart, setUserSelectChart] = useState('');
  const [userSelectChartKey, setUserSelectChartKey] = useState('');
  const [userSelectChartValue, setUserSelectChartValue] = useState('');
  function onUserSelectChart(name, key, value) {
    setUserSelectChart(name);
    setUserSelectChartKey(key);
    setUserSelectChartValue(value);
  }

  return (
    <div className={style.chartContainer}>
      <BarChart
        name="who-purchase"
        tabName="food-purchase"
        title="Who purchase"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Purchase Decision Maker"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <BarChart
        name="type-of-channel"
        tabName="food-purchase"
        title="Type of channel"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Purchase Channel"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <BarChart
        name="type-of-store"
        tabName="food-purchase"
        title="Type of store"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Purchase Channel Name (Instore)"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <BarChart
        name="type-of-website-app"
        tabName="food-purchase"
        title="Type of website/app"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Delivery Websites/Apps Used"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <BarColChart
        name="retailers"
        tabName="food-purchase"
        title="Retailers"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        desc={true}
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Retailers"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <BarColChart
        name="shopping-type"
        tabName="food-purchase"
        title="Shopping type"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        desc={true}
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Purchase Shopping Type"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
    </div>
  );
}

export default FoodPurchase;
