import { useCallback, useEffect, useState } from 'react';
import { useRoot } from './useRoot.jsx';
import { chatAiAsk } from '../api/request.js';
import { useAskGpt } from './useAskGPT.jsx';

const useChatCore = () => {
  const [ quesText, setQuesText ] = useState('');
  const [ question, setQuestion] = useState('');
  const { loading, sessionId, range, setSessionId, pushChatList, setLoading, setIsRetryAsk, setChatList } = useRoot();
  const { filterByCT } = useAskGpt();

  const inputValChange = (event) => {
    const val = event.target.value;
    setQuesText(val);
  };

  const callAiAsk = useCallback(async () => {
    if(!question) return;
    setIsRetryAsk(false);
    try {
      let params = {};
      if(range === 'full') {
        params = {
          question: question,
          sessionId: sessionId
        };
      } else {
        params = {
          filterOptions: filterByCT,
          question: question,
          sessionId: sessionId
        };
      }
      const result = await chatAiAsk(params);
      if(result.code === 200) {
        const { sessionId } = result.data;
        pushChatList('answer', result.data);
        setSessionId(sessionId);
        setQuestion('');
      }
      if(result.code === 500) {
        setIsRetryAsk(true);
      }
    } catch(error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, [pushChatList, setLoading, setSessionId, setIsRetryAsk, range, filterByCT, sessionId, question]);

  useEffect(() => {
    if(loading) {
      callAiAsk();
    }
  }, [loading, callAiAsk]);

  const onSend = useCallback((initQuestion = '') => {
    let tempQuesText = initQuestion || quesText;
    if(!tempQuesText || loading) return;
    pushChatList('question', tempQuesText);
    setQuestion(tempQuesText);

    setQuesText('');
    setLoading(true);
  }, [pushChatList, setLoading, quesText, loading]);

  const onRefresh = () => {
    setQuesText('');
    setQuestion('');
    setChatList([]);
    setSessionId('');
    setIsRetryAsk(false);
  };

  return {
    quesText,
    inputValChange,
    onSend,
    onRefresh,
    callAiAsk
  };
};

export default useChatCore;