// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ObBDYvnd9WkMDrgLcAhg{display:flex;flex-direction:column}.CwF3neOjMFPk0eYf7BWm{height:60px;display:flex;align-items:center;background-color:#00a32e;padding:0 30px;font-size:16px;font-weight:900;color:#fff}.j7Rl6aCMIhOd96OCuvgY{flex:1;overflow:hidden;background-color:rgba(0,0,0,.6);position:relative}`, "",{"version":3,"sources":["webpack://./src/modules/package-ai-screening-batch/components/box.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,qBAAA,CAGF,sBACE,WAAA,CACA,YAAA,CACA,kBAAA,CACA,wBAAA,CACA,cAAA,CACA,cAAA,CACA,eAAA,CACA,UAAA,CAGF,sBACE,MAAA,CACA,eAAA,CACA,+BAAA,CACA,iBAAA","sourcesContent":[".box {\n  display: flex;\n  flex-direction: column;\n}\n\n.boxHeader {\n  height: 60px;\n  display: flex;\n  align-items: center;\n  background-color: #00a32e;\n  padding: 0 30px;\n  font-size: 16px;\n  font-weight: 900;\n  color: #fff;\n}\n\n.boxBody {\n  flex: 1;\n  overflow: hidden;\n  background-color: #00000099;\n  position: relative;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"box": `ObBDYvnd9WkMDrgLcAhg`,
	"boxHeader": `CwF3neOjMFPk0eYf7BWm`,
	"boxBody": `j7Rl6aCMIhOd96OCuvgY`
};
export default ___CSS_LOADER_EXPORT___;
