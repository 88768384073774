import React from 'react';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import { TagCloud } from 'react-tagcloud';
import 'tippy.js/themes/light.css';
import { Empty } from 'antd';

const TagCloudComponent = ({ themeData }) => {
  const getTagColor = (score) => {
    // Example logic: Change colors based on count range
    if (score <= 100) {
      return '#008651';
    } else if (score <= 150) {
      return '#006717';
    } else {
      return '#00A32E';
    }
  };
  //tippy('.red');
  const customRenderer = (tag) => (
    <Tippy
      maxWidth=""
      content={
        <span>
          <span
            className="tippy-circle"
            style={{ backgroundColor: getTagColor(tag.score) }}
          ></span>
          : {tag.score} %
        </span>
      }
      placement="top"
      animation="scale"
      arrow={false}
      arrowType="sharp"
      duration={[200, 200]}
      theme="tomato" // Specify your custom theme name
    >
      <span
        key={tag.theme}
        style={{
          animation: 'blinker 3s linear infinite',
          animationDelay: `${Math.random() * 3}s`,
          fontSize: `14px`,
          // margin: `4px 40px 4px ${tag.count}px`,
          margin: '4px',
          padding: '5px 40px 5px 10px',
          display: 'inline-block',
          color: '#FDFFFD',
          backgroundColor: getTagColor(tag.score),
          borderRadius: '3px',
        }}
      >
        {tag.theme}
      </span>
    </Tippy>
  );
  const data = themeData.slice(0, 18);
  return (
    <div>
      {data.length > 0 ? (
        <TagCloud
          minSize={12}
          maxSize={35}
          tags={data}
          renderer={customRenderer}
        />
      ) : (
        <div className='pt-30'>
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
        />
        </div>
      )}
    </div>
  );
};

export default TagCloudComponent;
