import { Tabs } from 'antd';
import React, { useEffect, useState } from 'react';
import { downloadWb } from '../../../apis/file/index.js';
import BarColChart from '../charts/bar-col.jsx';
import BarChart from '../charts/bar.jsx';
import StackChart from '../charts/stack-col.jsx';
import StackRowChart from '../charts/stack.jsx';
import style from './tab.module.scss';

function FrequencyOfCooking({
  filter,
  compare,
  hasCompare,
  exportCount,
  isActive,
}) {
  const saveDataFns = [];
  function appendSaveDataFn(fn) {
    saveDataFns.push(fn);
  }

  useEffect(() => {
    if (!isActive) return;
    downloadWb(saveDataFns, 'xlsx', 'Frequency of Cooking');
  }, [exportCount]);

  const [userSelectChart, setUserSelectChart] = useState('');
  const [userSelectChartKey, setUserSelectChartKey] = useState('');
  const [userSelectChartValue, setUserSelectChartValue] = useState('');
  function onUserSelectChart(name, key, value) {
    setUserSelectChart(name);
    setUserSelectChartKey(key);
    setUserSelectChartValue(value);
  }

  return (
    <div className={style.chartContainer}>
      <BarChart
        name="frequency-of-cooking"
        tabName="habits-attitudes-and-lifestyle"
        tab2Name="frequency-of-cooking"
        title="Frequency of Cooking"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Frequency of Cooking"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
        sortList={[
          '2 times per week or less',
          '3-4 times per week',
          '5-6 times per week',
          'Every day',
        ]}
      />
    </div>
  );
}

function PerceivedCookingSkills({
  filter,
  compare,
  hasCompare,
  exportCount,
  isActive,
}) {
  const saveDataFns = [];
  function appendSaveDataFn(fn) {
    saveDataFns.push(fn);
  }

  useEffect(() => {
    if (!isActive) return;
    downloadWb(saveDataFns, 'xlsx', 'Perceived Cooking Skills');
  }, [exportCount]);

  const [userSelectChart, setUserSelectChart] = useState('');
  const [userSelectChartKey, setUserSelectChartKey] = useState('');
  const [userSelectChartValue, setUserSelectChartValue] = useState('');
  function onUserSelectChart(name, key, value) {
    setUserSelectChart(name);
    setUserSelectChartKey(key);
    setUserSelectChartValue(value);
  }

  return (
    <div className={style.chartContainer}>
      <BarChart
        name="perceived-cooking-skills"
        tabName="habits-attitudes-and-lifestyle"
        tab2Name="perceived-cooking-skills"
        title="Perceived Cooking Skills"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Perceived Cooking Skills"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
        sortList={[
          '0 – Cooking is a challenge to me',
          '1',
          '2',
          '3',
          '4',
          '5',
          '6',
          '7',
          '8',
          '9',
          '10 – I have expert skills in the kitchen',
        ]}
      />
    </div>
  );
}

function ChefType({
  filter,
  compare,
  hasCompare,
  exportCount,
  isActive,
}) {
  const saveDataFns = [];
  function appendSaveDataFn(fn) {
    saveDataFns.push(fn);
  }

  useEffect(() => {
    if (!isActive) return;
    downloadWb(saveDataFns, 'xlsx', 'Chef Type');
  }, [exportCount]);

  const [userSelectChart, setUserSelectChart] = useState('');
  const [userSelectChartKey, setUserSelectChartKey] = useState('');
  const [userSelectChartValue, setUserSelectChartValue] = useState('');
  function onUserSelectChart(name, key, value) {
    setUserSelectChart(name);
    setUserSelectChartKey(key);
    setUserSelectChartValue(value);
  }

  return (
    <div className={style.chartContainer}>
      <BarChart
        name="chef-type"
        tabName="habits-attitudes-and-lifestyle"
        tab2Name="chef-type"
        title="Chef Type"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Chef Type"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
    </div>
  );
}

function DietaryPreferences({
  filter,
  compare,
  hasCompare,
  exportCount,
  isActive,
}) {
  const saveDataFns = [];
  function appendSaveDataFn(fn) {
    saveDataFns.push(fn);
  }

  useEffect(() => {
    if (!isActive) return;
    downloadWb(saveDataFns, 'xlsx', 'Dietary Preferences & Health');
  }, [exportCount]);

  const [userSelectChart, setUserSelectChart] = useState('');
  const [userSelectChartKey, setUserSelectChartKey] = useState('');
  const [userSelectChartValue, setUserSelectChartValue] = useState('');
  function onUserSelectChart(name, key, value) {
    setUserSelectChart(name);
    setUserSelectChartKey(key);
    setUserSelectChartValue(value);
  }

  return (
    <div className={style.chartContainer}>
      <StackRowChart
        name="dietary-preferences-health-eating-habits"
        tabName="habits-attitudes-and-lifestyle"
        tab2Name="dietary-preferences-health"
        title="Eating habits"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Eating Habits"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <BarChart
        name="dietary-preferences-health-type-of-diet"
        tabName="habits-attitudes-and-lifestyle"
        tab2Name="dietary-preferences-health"
        title="Type of Diet"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Type Of Diet"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <BarChart
        name="dietary-preferences-health-low-no-dietary-paractices"
        tabName="habits-attitudes-and-lifestyle"
        tab2Name="dietary-preferences-health"
        title="Low/no dietary paractices"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Low/No Dietary Paractices"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <StackChart
        name="dietary-preferences-health-type-of-healthy-lifestyle"
        tabName="habits-attitudes-and-lifestyle"
        tab2Name="dietary-preferences-health"
        title="Type of Healthy Lifestyle"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="2"
        sortList={['1 - Not at all', '2', '3', '4', '5 - Very healthy']}
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Type Of Healthy Lifestyle"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <BarChart
        name="dietary-preferences-health-actions-to-be-healthy"
        tabName="habits-attitudes-and-lifestyle"
        tab2Name="dietary-preferences-health"
        title="Actions to be healthy"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Activity For Healthy Lifestyle"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <StackChart
        name="dietary-preferences-health-current-healthy-lifestyle-status"
        tabName="habits-attitudes-and-lifestyle"
        tab2Name="dietary-preferences-health"
        title="Current Healthy Lifestyle status"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="2"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Current Healthy Lifestyle Status"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <BarChart
        name="dietary-preferences-health-step-to-eat-more-healthy"
        tabName="habits-attitudes-and-lifestyle"
        tab2Name="dietary-preferences-health"
        title="Steps to eat more healthy"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="6"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Steps To Eat More Healthy"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <BarChart
        name="dietary-preferences-health-drives-to-have-a-healthy-lifestyle"
        tabName="habits-attitudes-and-lifestyle"
        tab2Name="dietary-preferences-health"
        title="Drives to have a healthy lifestyle"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Drives To Have A Healthy Lifestyle"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <BarChart
        name="dietary-preferences-health-main-challenges-to-achieve-health-goal"
        tabName="habits-attitudes-and-lifestyle"
        tab2Name="dietary-preferences-health"
        title="Main challenges to achieve health goal"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Main Challenges To Achieve Health Goal"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <StackChart
        name="dietary-preferences-health-frequency-of-exercise"
        tabName="habits-attitudes-and-lifestyle"
        tab2Name="dietary-preferences-health"
        title="Frequency of exercise"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="2"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Frequency Of Exercise"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <StackChart
        name="dietary-preferences-health-level-of-exercise"
        tabName="habits-attitudes-and-lifestyle"
        tab2Name="dietary-preferences-health"
        title="Level of exercise"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="2"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Level Of Exercise"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <BarChart
        name="dietary-preferences-health-type-of-exercise"
        tabName="habits-attitudes-and-lifestyle"
        tab2Name="dietary-preferences-health"
        title="Type of exercise"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Type Of Exercise"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <BarChart
        name="dietary-preferences-health-fitness-inspiration"
        tabName="habits-attitudes-and-lifestyle"
        tab2Name="dietary-preferences-health"
        title="Fitness inspiration"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Fitness Inspiration"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
    </div>
  );
}

function SustainabilityAttitudes({
  filter,
  compare,
  hasCompare,
  exportCount,
  isActive,
}) {
  const saveDataFns = [];
  function appendSaveDataFn(fn) {
    saveDataFns.push(fn);
  }

  useEffect(() => {
    if (!isActive) return;
    downloadWb(saveDataFns, 'xlsx', 'Sustainability Attitudes');
  }, [exportCount]);

  const [userSelectChart, setUserSelectChart] = useState('');
  const [userSelectChartKey, setUserSelectChartKey] = useState('');
  const [userSelectChartValue, setUserSelectChartValue] = useState('');
  function onUserSelectChart(name, key, value) {
    setUserSelectChart(name);
    setUserSelectChartKey(key);
    setUserSelectChartValue(value);
  }

  return (
    <div className={style.chartContainer}>
      <StackChart
        name="sustainability-attitudes-importance-of-environmentally-friendly-on-food-purchase"
        tabName="habits-attitudes-and-lifestyle"
        tab2Name="sustainability-attitudes"
        title="Importance of envioronmentally friendly on food purchase"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        sortList={[
          '5 - Very important',
          '4',
          '3',
          '2',
          '1 - Not important at all',
        ]}
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Importance Of Envioronmentally Friendly On Food Purchase"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <BarChart
        name="sustainability-attitudes-actively-looking-for-when-food-purchase"
        tabName="habits-attitudes-and-lifestyle"
        tab2Name="sustainability-attitudes"
        title="Actively looking for when food purchase"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Sustainability When Searching"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <StackChart
        name="sustainability-attitudes-noticed-sustainability-food-trends"
        tabName="habits-attitudes-and-lifestyle"
        tab2Name="sustainability-attitudes"
        title="Noticed sustainability food trends"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Noticed Sustainability Food Trends"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <BarChart
        name="sustainability-attitudes-new-sustainable-trends-products-detected"
        tabName="habits-attitudes-and-lifestyle"
        tab2Name="sustainability-attitudes"
        title="New sustainable trends / products detected"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="New Sustainable Trends / Products Detected"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
    </div>
  );
}

function DeliveryAndMealKits({
  filter,
  compare,
  hasCompare,
  exportCount,
  isActive,
}) {
  const saveDataFns = [];
  function appendSaveDataFn(fn) {
    saveDataFns.push(fn);
  }

  useEffect(() => {
    if (!isActive) return;
    downloadWb(saveDataFns, 'xlsx', 'Delivery and Meal kits');
  }, [exportCount]);

  const [userSelectChart, setUserSelectChart] = useState('');
  const [userSelectChartKey, setUserSelectChartKey] = useState('');
  const [userSelectChartValue, setUserSelectChartValue] = useState('');
  function onUserSelectChart(name, key, value) {
    setUserSelectChart(name);
    setUserSelectChartKey(key);
    setUserSelectChartValue(value);
  }

  return (
    <div className={style.chartContainer}>
      <BarColChart
        name="delivery-and-meal-kits-usage-of-online-app-delivery-service"
        tabName="habits-attitudes-and-lifestyle"
        tab2Name="delivery-and-meal-kits"
        title="Usage of online app/delivery service?"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="6"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Usage Of Online App/Delivery Service"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <BarChart
        name="delivery-and-meal-kits-frequency-of-using-online-app-delivery-services"
        tabName="habits-attitudes-and-lifestyle"
        tab2Name="delivery-and-meal-kits"
        title="Frequency of using online app/delivery services?"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="6"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Frequency Of Using Online App/Delivery Services"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <BarChart
        name="delivery-and-meal-kits-delivery-websites-apps-used"
        tabName="habits-attitudes-and-lifestyle"
        tab2Name="delivery-and-meal-kits"
        title="Delivery websites/apps used"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Delivery Websites/Apps Used"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <BarChart
        name="delivery-and-meal-kits-rapid-grocery-service-used"
        tabName="habits-attitudes-and-lifestyle"
        tab2Name="delivery-and-meal-kits"
        title="Rapid grocery service used"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Rapid Grocery Service Used"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <BarChart
        name="delivery-and-meal-kits-meal-kit-service-used"
        tabName="habits-attitudes-and-lifestyle"
        tab2Name="delivery-and-meal-kits"
        title="Meal kit service used"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Meal Kit Service Used"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <BarChart
        name="delivery-and-meal-kits-farm-box-company-used"
        tabName="habits-attitudes-and-lifestyle"
        tab2Name="delivery-and-meal-kits"
        title="Farm box company used"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Farm Box Company Used"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <BarChart
        name="delivery-and-meal-kits-direct-to-consumer-brands-use"
        tabName="habits-attitudes-and-lifestyle"
        tab2Name="delivery-and-meal-kits"
        title="Direct to consumer brands use"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Direct To Consumer Brands Use"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
    </div>
  );
}

function HabitsAttitudesAndLifestyle({
  filter,
  compare,
  hasCompare,
  exportCount,
  isActive,
}) {
  const startTab = new URL(window.location).searchParams.get('tab2');

  const [activeKey, setActiveKey] = useState(
    startTab ?? 'dietary-preferences-health'
  );

  return (
    <Tabs
      tabPosition="top"
      defaultActiveKey={startTab}
      onChange={setActiveKey}
      items={[
        {
          label: <span>{'Dietary Preferences \u0026 Health'}</span>,
          key: 'dietary-preferences-health',
          children: (
            <DietaryPreferences
              filter={filter}
              compare={compare}
              hasCompare={hasCompare}
              exportCount={exportCount}
              isActive={isActive && activeKey === 'dietary-preferences-health'}
            />
          ),
        },
        {
          label: <span>{'Sustainability attitudes'}</span>,
          key: 'sustainability-attitudes',
          children: (
            <SustainabilityAttitudes
              filter={filter}
              compare={compare}
              hasCompare={hasCompare}
              exportCount={exportCount}
              isActive={isActive && activeKey === 'sustainability-attitudes'}
            />
          ),
        },
        {
          label: <span>{'Delivery and Meal kits'}</span>,
          key: 'delivery-and-meal-kits',
          children: (
            <DeliveryAndMealKits
              filter={filter}
              compare={compare}
              hasCompare={hasCompare}
              exportCount={exportCount}
              isActive={isActive && activeKey === 'delivery-and-meal-kits'}
            />
          ),
        },
        {
          label: <span>{'Frequency Of Cooking'}</span>,
          key: 'frequency-of-cooking',
          children: (
            <FrequencyOfCooking
              filter={filter}
              compare={compare}
              hasCompare={hasCompare}
              exportCount={exportCount}
              isActive={isActive && activeKey === 'frequency-of-cooking'}
            />
          ),
        },
        {
          label: <span>{'Perceived Cooking Skills'}</span>,
          key: 'perceived-cooking-skills',
          children: (
            <PerceivedCookingSkills
              filter={filter}
              compare={compare}
              hasCompare={hasCompare}
              exportCount={exportCount}
              isActive={isActive && activeKey === 'perceived-cooking-skills'}
            />
          ),
        },
        {
          label: <span>{'Chef Type'}</span>,
          key: 'chef-type',
          children: (
            <ChefType
              filter={filter}
              compare={compare}
              hasCompare={hasCompare}
              exportCount={exportCount}
              isActive={isActive && activeKey === 'chef-type'}
            />
          ),
        },
      ]}
    ></Tabs>
  );
}

export default HabitsAttitudesAndLifestyle;
