// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.jn_HdjQRcZRSZ0lDors6{margin-right:25px;margin-bottom:25px;width:10vw;overflow:hidden}.csGR6iXJ8OtIntwolBqo{margin-top:10px;width:10vw;height:12vw;cursor:pointer;border-radius:6px;overflow:hidden;background-color:#02290c;position:relative}.bJrB6nqvW1PZu3LCmv7F{display:block;width:100%;height:100%;object-fit:contain}.GTJuDfD5yBr9BXzcezC8{position:absolute;top:50%;left:50%;transform:translate(-50%, -50%);z-index:2}`, "",{"version":3,"sources":["webpack://./src/modules/package-ai-screening-batch/views/basic/item.module.scss"],"names":[],"mappings":"AAAA,sBACE,iBAAA,CACA,kBAAA,CACA,UAAA,CACA,eAAA,CAGF,sBACE,eAAA,CACA,UAAA,CACA,WAAA,CACA,cAAA,CACA,iBAAA,CACA,eAAA,CACA,wBAAA,CACA,iBAAA,CAGF,sBACE,aAAA,CACA,UAAA,CACA,WAAA,CACA,kBAAA,CAGF,sBACE,iBAAA,CACA,OAAA,CACA,QAAA,CACA,+BAAA,CACA,SAAA","sourcesContent":[".imgItem {\n  margin-right: 25px;\n  margin-bottom: 25px;\n  width: 10vw;\n  overflow: hidden;\n}\n\n.imgItemUpload {\n  margin-top: 10px;\n  width: 10vw;\n  height: 12vw;\n  cursor: pointer;\n  border-radius: 6px;\n  overflow: hidden;\n  background-color: #02290c;\n  position: relative;\n}\n\n.imgItemUploadImg {\n  display: block;\n  width: 100%;\n  height: 100%;\n  object-fit: contain;\n}\n\n.imgItemUploadIcon {\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n  z-index: 2;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"imgItem": `jn_HdjQRcZRSZ0lDors6`,
	"imgItemUpload": `csGR6iXJ8OtIntwolBqo`,
	"imgItemUploadImg": `bJrB6nqvW1PZu3LCmv7F`,
	"imgItemUploadIcon": `GTJuDfD5yBr9BXzcezC8`
};
export default ___CSS_LOADER_EXPORT___;
