// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Dzlirst6Mxyr8LFFujg0{padding:5px;background-color:rgba(2,33,13,.6);display:inline-flex;align-items:center;justify-content:center;border-radius:50px}.R4W5TzkLVTtCC2wN0eYD{padding:5px 15px;border-radius:50px;display:inline-flex;line-height:1;cursor:pointer;font-size:14px;margin:0 1px}.R4W5TzkLVTtCC2wN0eYD.qFOxMQ4mqjWg3n2WoigW{background-color:green}.R4W5TzkLVTtCC2wN0eYD:hover{background-color:green}`, "",{"version":3,"sources":["webpack://./src/modules/bu-monthly-feedback/components/switch-btn/index.module.scss"],"names":[],"mappings":"AAAA,sBACI,WAAA,CACA,iCAAA,CACA,mBAAA,CACA,kBAAA,CACA,sBAAA,CACA,kBAAA,CAGJ,sBACI,gBAAA,CACA,kBAAA,CACA,mBAAA,CACA,aAAA,CACA,cAAA,CACA,cAAA,CACA,YAAA,CAEA,2CACI,sBAAA,CAGJ,4BACI,sBAAA","sourcesContent":[".btnBox {\n    padding: 5px;\n    background-color: rgba(2, 33, 13, 0.6);\n    display: inline-flex;\n    align-items: center;\n    justify-content: center;\n    border-radius: 50px;\n}\n\n.btn {\n    padding: 5px 15px;\n    border-radius: 50px;\n    display: inline-flex;\n    line-height: 1;\n    cursor: pointer;\n    font-size: 14px;\n    margin: 0 1px;\n\n    &.active {\n        background-color: green;\n    }\n\n    &:hover {\n        background-color: green;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"btnBox": `Dzlirst6Mxyr8LFFujg0`,
	"btn": `R4W5TzkLVTtCC2wN0eYD`,
	"active": `qFOxMQ4mqjWg3n2WoigW`
};
export default ___CSS_LOADER_EXPORT___;
