import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Wildandpure from '../../../../assets/images/Emerging-food-solution/wildandpure/wildandpure.jpg';
import FoodSliderComponent from './food-slider.jsx';
import FoodServiceComponent from './irr-food-service.jsx';
import IrrSocial from './irr-social.jsx';
import { leftLineIcon } from '../../../../components/icons/icons.jsx';
import FutureMenuPdf from '../../../../assets/pdf/Future-Menus-Report-2023.pdf';

function WildPure() {
  const [showSlider, setShowSlider] = useState(true);
  const [showSliderservice, setShowSliderservice] = useState(true);
  const [showSlidersocial, setShowSlidersocial] = useState(true);
  const handleSlider = () => {
    setShowSlider(!showSlider);
  };
  const handleSliderservice = () => {
    setShowSliderservice(!showSliderservice);
  };
  const handleSlidersocial = () => {
    setShowSlidersocial(!showSlidersocial);
  };
  const handleModal = () => {
    setShowSlider(!showSlider);
  };
  const handleModalService = () => {
    setShowSliderservice(!showSliderservice);
  };
  const handleModalSocial = () => {
    setShowSlidersocial(!showSlidersocial);
  };
  const handleCloseSlider = () => {
    setShowSlider(true);
  };
  const handleCloseSliderservice = () => {
    setShowSliderservice(!showSliderservice);
  };
  const handleCloseSlidersocial = () => {
    setShowSlidersocial(!showSlidersocial);
  };

  const navigate = useNavigate();
  const handleNavigationMain = () => {
    navigate('/trends-dial');
  };
  return (
    <>
      <div className="trends-detail-title">
        <h2><img style={{cursor: 'pointer'}} src={leftLineIcon} onClick={handleNavigationMain} alt="left icon" /> Wild & Pure</h2>
      </div>
      <div className="flex-container">
        <div className="emerging-description">
          <div>
            Experience the scavenged, the foraged and the wild – truly new
            frontiers of what nature has to offer, brought to your plate. UFS’
            Why: To reconnect people with nature, to champion food diversity and
            encourage us to revalue a more sustainable food system. Core
            Operator benefit: To creatively use new ingredients that showcase
            nature’s benefits and specific tastes. Core Consumer motivation: “I
            really value food that has a timeless feel to it, closer to the
            land, less refined and industrial.”
          </div>
        </div>

        <div className="emerging-image">
          <div>
            <img src={Wildandpure} alt="wildandpure" />
          </div>
        </div>
      </div>
      {!showSlider && (
        <FoodSliderComponent
          handleCloseSlider={handleCloseSlider}
          handleSlider={handleSlider}
        />
      )}
      {!showSliderservice && (
        <FoodServiceComponent
          handleCloseSliderservice={handleCloseSliderservice}
          handleSliderservice={handleSliderservice}
        />
      )}
      {!showSlidersocial && (
        <IrrSocial
          handleCloseSlidersocial={handleCloseSlidersocial}
          handleSlidersocial={handleSlidersocial}
        />
      )}
      <ul className="emerging-links">
        <li onClick={handleModal}>Trends Deep Dive</li>
        <li onClick={handleModalService}>Food Service Trend Scape</li>
        <li onClick={handleModalSocial}>Social Listening Analysis</li>
        <li><a href={FutureMenuPdf} target="_blank" rel="noreferrer"> Future Menu 2023</a></li>
      </ul>
    </>
  );
}

export default WildPure;
