import React, { useRef } from 'react';
import { aiTopTiktokLogo } from '../../../../components/icons/icons.jsx';
import style from './tiktok.module.scss';
import VideoPlayer from '../video-player/video-player.jsx';
import { Empty } from 'antd';

const VideoComponent = ({ videoData, trendingDish }) => {
  const VideoPlayerRef = useRef(null);

  const openDetail = (item) => {
    if (VideoPlayerRef.current && VideoPlayerRef.current.open) {
      VideoPlayerRef.current.open(item);
    } else {
      console.error('VideoPlayerRef.current or its open method is not defined');
    }
  };

  const handleClick = (item) => {
    openDetail(item);
  };
  const data = videoData.slice(0, 6);

  return (
    <div className={style.SearchTiktok}>
      <div className={style.labelContainer}>
        {/* <div className={style.tiktokTitle}>
          <div><img src={aiTopTiktokLogo} alt="TikTok Logo" /></div>
          <div className={style.text}>
             Top 6 Trending TikTok content for <span className={style.tendText}>{trendingDish}</span>
          </div>
        </div> */}
        <div className={style.videoContainer}>
          {data?.length === 0 && <div className={style.subBox}>
            <Empty
              className={style.emptyTable}
              image={Empty.PRESENTED_IMAGE_SIMPLE}
            /></div>}
          {data?.length > 0 && (
            <div className={style.videosBox}>
              {data.map((item) => (
                <div key={item.videoId} className={style.videoItem}>
                  <div
                    className={style.videoItemInner}
                    onClick={() => handleClick(item)}
                  >
                    <iframe
                      className={style.iframe}
                      src={`https://www.tiktok.com/embed/${item.videoId}`}
                      title="Video Player"
                      allowFullScreen
                      scrolling='no'
                      allow="encrypted-media"
                    ></iframe>
                    <div className={style.videoCover}></div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
      <VideoPlayer ref={VideoPlayerRef} />
    </div>
  );
};

export default VideoComponent;
