import React, { useEffect, useState } from 'react';
import Layout from '../../components/layout.jsx';
import OccasionDashboardContent from './content.jsx';
import style from './dashboard.module.scss';
import OccasionDashboardFilter from './filter.jsx';

function OccasionDashboard() {
  const [filter, setFilter] = useState({});
  const [compare, setCompare] = useState({});
  const [hasCompare, setHasCompare] = useState(false);
  const [exportCount, setExportCount] = useState(0);

  function onExport() {
    setExportCount(exportCount + 1);
  }

  useEffect(() => {
    if (!hasCompare) setCompare({});
  }, [hasCompare]);

  return (
    <Layout onExport={onExport}>
      <div className={style.dashboardContainer}>
        <OccasionDashboardFilter
          setFilterData={setFilter}
          setCompareData={setCompare}
          hasCompare={hasCompare}
          setHasCompare={setHasCompare}
        />
        <OccasionDashboardContent
          filter={filter}
          compare={compare}
          hasCompare={hasCompare}
          exportCount={exportCount}
        />
      </div>
    </Layout>
  );
}

export default OccasionDashboard;
