import React, { useState, useImperativeHandle, useRef, forwardRef, useEffect, useCallback } from 'react';
import { Modal, Table } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import BorderContainer from '../border-container/border-container.jsx';
import style from './tiktok-modal.module.scss';
import { cancelGreenIcon, triangleRight } from '../../../../components/icons/icons.jsx';
import classNames from 'classnames';
import { 
  fetchSearchCategoriesChart,
  fetchSearchCategoriesKeywords
} from '../../api/request.js';

const antIcon = (
  <LoadingOutlined
    style={{
      fontSize: 30,
      color: '#00FF00'
    }}
    spin
  />
);

function debounce (callback, delay) {
  let lastTime;
  return function () {
    clearTimeout(lastTime);
    const [that, args] = [this, arguments];
    lastTime = setTimeout(() => {
      callback.apply(that, args);
    }, delay);
  };
}

let requestIndex = Date.now();

let TiktokModal = ({country, consumerTrend, category}, ref) => {

  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleCancel = () => {
    setIsModalOpen(false);
    setLabels([]);
    setTableData([]);
    setTotal(0);
    setCurrent(1);
    setPageSize(20);
    setHeight(50);
    setShowOpen(false);
    setOpened(false);
  };

  // 标签
  const [labels, setLabels] = useState([]);
  const [subCategory, setSubCategory] = useState('');
  const labelClick = (label) => {
    setSubCategory(subCategory === label.name ? '' : label.name);
  };
  const labelBoxRef = useRef(null);
  const [height, setHeight] = useState(50);
  const [opened, setOpened] = useState(false);
  const [showOpen, setShowOpen] = useState(false);
  const openLabels = () => {
    const boxHeight = labelBoxRef.current?.offsetHeight || 90;
    setOpened(!opened);
    if (opened) {
      setHeight(90);
    } else {
      setHeight(boxHeight);
    }
  };

  useEffect(() => {
    if (labels.length == 0) return;
    const boxHeight = labelBoxRef.current?.offsetHeight || 90;
    if (boxHeight > 100) {
      setShowOpen(true);
      setHeight(90);
    } else if  (boxHeight > 60) {
      setHeight(90);
    }
  }, [labels]);

  // table
  const [tableData, setTableData] = useState([]);
  const [tableLoading, setTableLoading] = useState(true);
  const [total, setTotal] = useState(0);
  const [current, setCurrent] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [sort, setSort] = useState(null);

  const paginateChange = (pagination, _, sorter) => {
    const {current, pageSize} = pagination;
    let {field, order} = sorter;
    if (field == 'keywordName') field = 'Keyword';
    if (field == 'volume') field = 'Total';
    const sort = field ? (order === 'descend' ? `-${field}` : field) : null;
    setSort(sort);
    setCurrent(current);
    setPageSize(pageSize);
  };

  const fetchTableDataFn = (params) => {
    setTableLoading(true);
    fetchSearchCategoriesKeywords(params, requestIndex)
      .then(res => {
        if (requestIndex !== res.config.uuid) return;
        const resData = res.data.data;
        setTableData(Array.isArray(resData.data) ? resData.data : []);
        setTotal(resData.total);
        setCurrent(resData.current);
        setPageSize(resData.size);
      })
      .catch(err => {console.error(err);})
      .finally(() => {
        setTableLoading(false);
      });
  };
  const delayFetchTableData = useCallback(debounce(function(val) { return fetchTableDataFn(val);}, 500), []);

  useEffect(() => {
    if (!isModalOpen) return;
    const params = {
      country,
      consumerTrend,
      category,
      subCategory,
      page: current,
      pageSize,
      sort
    };
    requestIndex++;
    delayFetchTableData(params);
  }, [isModalOpen, subCategory, current, pageSize, sort]);

  const columns = [
    {
      title: 'Column Header',
      dataIndex: 'keywordName',
      width: '220px',
      align: 'center',
      sorter: true
    },
    {
      title: 'Volume(over 4 years)',
      dataIndex: 'volume',
      width: '200px',
      align: 'center',
      sorter: true,
    },
    {
      title: 'CAGA',
      dataIndex: 'cagr',
      width: '90px',
      align: 'center',
      render: (_, { cagr }) => {
        return cagr || cagr == 0 ? cagr : '-';
      }
    },
    {
      title: 'YOY',
      dataIndex: 'yoy',
      width: '90px',
      align: 'center',
      render: (_, { yoy }) => {
        return yoy || yoy == 0 ? yoy : '-';
      }
    },
    {
      title: 'Associated categories',
      dataIndex: 'accociatedCategories',
      width: '220px',
      align: 'center',
      render: (_, { accociatedCategories }) => {
        return (<div className={style.categoryBox}>
          {accociatedCategories?.map((item, index) => {
            return (<div key={item + index} className={style.tableTag}>#{item}</div>);
          })}
        </div>
        );
      },
    },
    {
      title: 'Sub categories',
      dataIndex: 'subCategories',
      width: '170px',
      align: 'center',
      render: (_, { subCategories }) => {
        return subCategories?.join(', ');
      },
    }
  ];

  useImperativeHandle(ref, () => ({
    open: (subCategory) => {
      const params = {
        country,
        consumerTrend,
        category,
        limitCount: 50
      };
      fetchSearchCategoriesChart(params)
        .then(res => {
          setLabels(res.data||[]);
        })
        .catch(err => {console.error(err);});
      setSubCategory(subCategory);
      setIsModalOpen(true);
    },
    close: () => {
      setIsModalOpen(false);
    },
  }));

  return (
    <Modal
      title="" 
      wrapClassName={style.myDialog}
      width={1200} 
      centered 
      destroyOnClose
      footer={null} 
      open={isModalOpen}
      onCancel={handleCancel}
      closeIcon={(
        <div className={style.closeBtn}>
          <img src={cancelGreenIcon} alt="" />
        </div>
      )}
    >
      <div className={style.dialogBox}>
        <BorderContainer up>
          <div className={style.dialogMain}>
            <div className={style.labelWrapper} style={{height: height + 'px'}}>
              <div ref={labelBoxRef} className={style.labelBox}>
                <div className={style.labelContainer}>
                  {labels.map((item, index) => {
                    return (
                      <div 
                        key={item.name + index} 
                        className={classNames(style.labelItem, 
                          {[style.active]: subCategory === item.name}
                        )}
                        onClick={() => labelClick(item)}
                      >{item.name}</div>);
                  })}
                </div>
              </div>
              {showOpen && <div className={classNames(style.arrow, {[style.opened]: opened})} onClick={() => openLabels()}>
                <img src={triangleRight} alt="" />
              </div>}
            </div>
            <div className={style.tableContainer}>
              <Table
                columns={columns}
                dataSource={tableData}
                scroll={{
                  scrollToFirstRowOnChange: true,
                  x: false,
                  y: 500
                }}
                loading={{spinning: tableLoading, indicator: antIcon}}
                onChange={paginateChange}
                pagination={{
                  position: ['bottomCenter'],
                  showQuickJumper: true,
                  current,
                  pageSize,
                  total,
                  showSizeChanger: true,
                  pageSizeOptions: [10, 20, 30, 40],
                }}
              />
            </div>
          </div>
        </BorderContainer>
      </div>
    </Modal>
  );
};

TiktokModal = forwardRef(TiktokModal);

export default TiktokModal;