import React, { useState } from 'react';
import calenderIcon from '../../assets/images/svg/calendar-icon.svg';
import { minDate, todayDate } from '@/modules/trends/hooks/useFilters.jsx';

function DateFilterModal({
  isOpen,
  onApply,
  startDate,
  endDate,
}) {
  const [selectedStartDate, setSelectedStartDate] = useState(startDate);
  const [selectedEndDate, setSelectedEndDate] = useState(endDate);

  const [startDateError, setStartDateError] = useState(false);
  const [endDateError, setEndDateError] = useState(false);

  const isFutureStartDate = new Date(selectedStartDate) > new Date();
  const isStartDateAfterEndDate =
    new Date(selectedStartDate) > new Date(selectedEndDate);

  const handleResetDate = () => {
    setSelectedStartDate(minDate);
    setSelectedEndDate(todayDate);
  };

  const handleFilterApply = () => {
    if (!isFutureStartDate && !isStartDateAfterEndDate) {
      setStartDateError(false);
      setEndDateError(false);
      onApply(selectedStartDate, selectedEndDate);
    } else {
      if (isFutureStartDate) {
        setStartDateError(true);
      }
      if (isStartDateAfterEndDate) {
        setEndDateError(true);
      }
    }
  };

  return (
    <>
      <div className={`filter-modal ${isOpen ? 'open' : ''}`}>
        <div className="modal-content">
          <div className="flex">
            <div className="start-date">
              Start date
              <input
                type="date"
                style={{
                  backgroundImage: `url(${calenderIcon})`,
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'right 3px center',
                  borderColor: startDateError ? 'red' : '',
                }}
                value={selectedStartDate}
                onChange={(e) => {
                  const newStartDate = e.target.value;
                  if (!isFutureStartDate && !isStartDateAfterEndDate) {
                    setStartDateError(false);
                  }
                  setSelectedStartDate(newStartDate);
                }}
                max={selectedEndDate || ''}
              />
              {startDateError && (
                <div className="error-message">
                  Start date cannot be in the future.
                </div>
              )}
              {endDateError && (
                <div className="error-message">
                  Start date cannot be after the end date.
                </div>
              )}
            </div>
            <div className="end-date">
              End date
              <input
                type="date"
                style={{
                  backgroundImage: `url(${calenderIcon})`,
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'right 3px center',
                  borderColor: endDateError ? 'red' : '',
                }}
                value={selectedEndDate}
                onChange={(e) => {
                  const newEndDate = e.target.value;
                  if (newEndDate >= selectedStartDate) {
                    setEndDateError(false);
                  }
                  setSelectedEndDate(newEndDate);
                }}
              />
              {endDateError && (
                <div className="error-message">
                  End date cannot be before the start date.
                </div>
              )}
            </div>
          </div>
          <button
            className="btn-reset"
            onClick={handleResetDate}
            role="button"
          >
            Reset
          </button>
          <button
            className="btn-filter"
            onClick={handleFilterApply}
            onKeyDown={(event) => {
              if (event.key === 'Enter') {
                handleFilterApply(); // You missed invoking the function here
              }
            }}
            role="button"
          >
            Apply
          </button>
        </div>
      </div>
    </>
  );
}

export default DateFilterModal;
