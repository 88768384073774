// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.s2dZxfP8nBk_NFtJh65g{height:100%;display:flex;flex-direction:column}.hyz6IUyoZP5ugsdb6yTF{margin-top:25px;flex:1;overflow:hidden;position:relative}.S64E8E2SWAGbN1N__1nv{position:relative;height:100%;padding:50px;overflow-x:hidden;overflow-y:auto}.LH_l6KgUGhmLuvoNbVpE{padding:15px 30px;background-color:#02290c;font-size:16px;font-weight:400;line-height:22.3px;text-align:left}.LH_l6KgUGhmLuvoNbVpE ul{padding-left:1em;list-style:disc}.EztyhKfc2N4xW7dtMIv1{margin-top:20px}.MIyo7FF5zGCT5pt5Y_9W{position:absolute;right:50px;bottom:50px;width:230px}.uTkuksnIqk9iKR96Fhh9{margin-bottom:10px}.IG2WeraC97J3Sqp1vGw6{height:85vh;width:85vw}`, "",{"version":3,"sources":["webpack://./src/modules/package-ai-screening/views/aoi/index.module.scss"],"names":[],"mappings":"AAAA,sBACE,WAAA,CACA,YAAA,CACA,qBAAA,CAGF,sBACE,eAAA,CACA,MAAA,CACA,eAAA,CACA,iBAAA,CAGF,sBACE,iBAAA,CACA,WAAA,CACA,YAAA,CACA,iBAAA,CACA,eAAA,CAGF,sBACE,iBAAA,CACA,wBAAA,CACA,cAAA,CACA,eAAA,CACA,kBAAA,CACA,eAAA,CACA,yBACE,gBAAA,CACA,eAAA,CAIJ,sBACE,eAAA,CAGF,sBACE,iBAAA,CACA,UAAA,CACA,WAAA,CACA,WAAA,CAGF,sBACE,kBAAA,CAGF,sBACE,WAAA,CACA,UAAA","sourcesContent":[".containerInner {\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n}\n\n.box {\n  margin-top: 25px;\n  flex: 1;\n  overflow: hidden;\n  position: relative;\n}\n\n.form {\n  position: relative;\n  height: 100%;\n  padding: 50px;\n  overflow-x: hidden;\n  overflow-y: auto;\n}\n\n.info {\n  padding: 15px 30px;\n  background-color: #02290c;\n  font-size: 16px;\n  font-weight: 400;\n  line-height: 22.3px;\n  text-align: left;\n  ul {\n    padding-left: 1em;\n    list-style: disc;\n  }\n}\n\n.design {\n  margin-top: 20px;\n}\n\n.btnsBox {\n  position: absolute;\n  right: 50px;\n  bottom: 50px;\n  width: 230px;\n}\n\n.btn2 {\n  margin-bottom: 10px;\n}\n\n.helpImg {\n  height: 85vh;\n  width: 85vw;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"containerInner": `s2dZxfP8nBk_NFtJh65g`,
	"box": `hyz6IUyoZP5ugsdb6yTF`,
	"form": `S64E8E2SWAGbN1N__1nv`,
	"info": `LH_l6KgUGhmLuvoNbVpE`,
	"design": `EztyhKfc2N4xW7dtMIv1`,
	"btnsBox": `MIyo7FF5zGCT5pt5Y_9W`,
	"btn2": `uTkuksnIqk9iKR96Fhh9`,
	"helpImg": `IG2WeraC97J3Sqp1vGw6`
};
export default ___CSS_LOADER_EXPORT___;
