import React from 'react';
import style from './no-data.module.scss';
import noData from '../../../../assets/images/ratings-review/no-data.svg';

function NoData() {

  return (
    <div className={style.noData}>
      <div className={style.noDataImg}><img src={noData} alt="" /></div>
      <div className={style.noDataMsg}>No relevant data for this selection, please choose again.</div>
    </div>
  );
}

export default NoData;