import React from 'react';
import { Select } from 'antd';
import { aiTopDishInfo, chevronDownIcon,selectBackground } from '../../../../components/icons/icons.jsx';

const { Option } = Select;

const MarketSelect = ({ markets, selectedMarket, onChange, dropdownStyle }) => {
  return (
    <Select
      value={selectedMarket}
      maxTagCount={1}
      mode='multiple'
      onChange={onChange} 
      className='custom-select filter-select'
      dropdownStyle={dropdownStyle}
    >
      {markets.map(market => (
        <Option key={market.name} value={market.name}>
           <input
              type="checkbox"
              className='topdish-filter-checkbox'
              checked={selectedMarket.includes(market.name)}
              readOnly
            />
          {market.name}
        </Option>
      ))}
    </Select>
  );
};

export default MarketSelect;
