import React, { useState } from 'react';
import { OccasionCompareBtn } from '../../components/filter.jsx';
import Layout from '../../components/layout.jsx';
import OccasionPhotoContent from './content.jsx';
import OccasionPhotoFilter from './filter.jsx';
import style from './photo.module.scss';

function OccasionPhoto() {
  const startCategory = new URL(window.location).searchParams.get('category');
  const [filter, setFilter] = useState(
    startCategory ? { booster_project: startCategory } : {}
  );
  const [compare, setCompare] = useState(
    startCategory ? { booster_project: startCategory } : {}
  );

  return (
    <Layout>
      <div className={style.photoContainer}>
        <div
          className={`${style.photoContainerInner} ${style.photoContentFilter}`}
        >
          <OccasionPhotoFilter
            setFilterData={setFilter}
            hasCompare={false}
            startCategory={startCategory}
          />
          <OccasionPhotoContent filter={filter} />
        </div>
        <div
          className={`${style.photoContainerInner} ${style.photoContentCompare}`}
        >
          <OccasionPhotoFilter
            setFilterData={setCompare}
            hasCompare={true}
            startCategory={startCategory}
          />
          <OccasionPhotoContent filter={compare} />
        </div>
        <a href="/occasion-photo">
          <OccasionCompareBtn display={true} />
        </a>
      </div>
    </Layout>
  );
}

export default OccasionPhoto;
