import React, { memo } from 'react';
import ReactECharts from 'echarts-for-react';
import * as echarts from 'echarts/core';
import { CanvasRenderer } from 'echarts/renderers';

echarts.use(
  [CanvasRenderer]
);

const AverageBarChart = memo(function AverageBarChart({ item }) {
  const { positive, neutral, negative } = item;

  const options = {
    tooltip: {
      trigger: 'axis',
      backgroundColor: 'rgba(0,0,0,0.7)',
      borderColor: '#00A32E',
      textStyle: {
        color: '#FFFFFF'
      }
    },
    grid: {
      left: '50',
      right: '0',
      top: '0',
      bottom: '0',
    },
    xAxis: {
      type: 'value',
      show: false,
      axisTick: {
        show: false
      },
    },
    yAxis: {
      type: 'category',
      axisLabel: {
        color: '#00A32E',
        fontSize: 10,
        margin: 4,
      },
      axisTick: {
        show: false
      },
      axisLine: {
        show: false
      },
      boundaryGap: [0, 0],
      data: ['Positive', 'Neutral', 'Negative'].reverse()
    },
    series: [
      {
        type: 'bar',
        itemStyle: {
          color: '#00A32E'
        },
        barWidth: 14,
        data: [positive, neutral, negative].reverse()
      }
    ]
  };

  return (
    <ReactECharts
      echarts={echarts}
      option={options}
      style={{
        height: '70px'
      }}
    />
  );
});

export default AverageBarChart;