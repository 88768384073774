import request from './fetch';

// search/dial
export function fetchSearchDial (params, uuid) {
  return request({
    url: '/v1/trend-dial/search/dial',
    method: 'get',
    uuid,
    params
  });
}

export function fetchSearchMacro (params, uuid) {
  return request({
    url: '/v1/trend-dial/search/macro',
    method: 'get',
    uuid,
    params
  });
}

// consumer trend details/chart month year
export function fetchSearchDetails (params, uuid) {
  return request({
    url: '/v1/trend-dial/search/details',
    method: 'get',
    uuid,
    params
  });
}

// brands
export function fetchMintelBrands (params, uuid) {
  return request({
    url: '/v1/trend-dial/mintel/brands',
    method: 'get',
    uuid,
    params
  });
}

// subcategory
export function fetchMintelSubcategory (params, uuid) {
  return request({
    url: '/v1/trend-dial/mintel/subcategory',
    method: 'get',
    uuid,
    params
  });
}

// market
export function fetchMintelMarket (params, uuid) {
  return request({
    url: '/v1/trend-dial/mintel/market',
    method: 'get',
    uuid,
    params
  });
}

// consumerTrendDetails
export function fetchConsumerTrendDetails (params, uuid) {
  return request({
    url: '/v1/trend-dial/mintel/consumerTrendDetails',
    method: 'get',
    uuid,
    params,
    originData: true
  });
}

// associated Optional tags
export function fetchSearchCategoriesAssociated (params, uuid) {
  return request({
    url: '/v1/trend-dial/search/categories/associated',
    method: 'get',
    uuid,
    params
  });
}

// category chart
export function fetchSearchCategoriesChart (params, uuid) {
  return request({
    url: '/v1/trend-dial/search/categories/chart',
    method: 'get',
    uuid,
    params
  });
}

// tiktok videos
export function fetchTiktokCategoriesVideos (params, uuid) {
  return request({
    url: '/v1/trend-dial/tiktok/categories/videos',
    method: 'get',
    uuid,
    params,
    originData: true
  });
}

// chart -> keywords
export function fetchSearchCategoriesKeywords (params, uuid) {
  return request({
    url: '/v1/trend-dial/search/categories/keywords',
    method: 'get',
    uuid,
    params,
    originData: true
  });
}

export function fetchSizingAll () {
  return request({
    url: '/v1/trend-dial/sizing/all',
    method: 'get'
  });
}