import React from 'react';
import { Select } from 'antd';
import BrandStyle from '../brand-filter/brand-filter.module.scss';
import Style from '../graph/graph.module.scss';

const { Option } = Select;

const YearSelector = ({ years, selectedYears, setSelectedYears, dropdownStyle }) => {
  const handleChange = (value) => {
    setSelectedYears(value);
  };

  return (
    <div>
      <Select
        value={selectedYears}
        mode="multiple"
        className={`graph-select ${BrandStyle.brandSelect} ${Style.InputContainer}`}
        dropdownStyle={dropdownStyle}
        onChange={handleChange}
      >
        {years.map(year => (
          <Option key={year} value={year} style={{fontSize:'12px'}}>
            <input
              type='checkbox'
              className='topdish-filter-checkbox'
              checked={selectedYears.includes(year)}
              readOnly
            />
            {year}
          </Option>
        ))}
      </Select>
    </div>
  );
};

export default YearSelector;