import React, { useMemo } from 'react';
import style from './stage.module.scss';
import { CheckOutlined } from '@ant-design/icons';

function Arrow({ active = false, isNext = false }) {
  const activeClass = useMemo(() => {
    if (active && !isNext) {
      return style.stageArrowActive;
    } else if (isNext && !active) {
      return style.stageArrowNext;
    } else {
      return '';
    }
  }, [active, isNext]);

  return (
    <div className={`${style.stageArrow} ${activeClass}`}>
      <div className={style.stageArrowItem1}></div>
      <div className={style.stageArrowItem2}></div>
    </div>
  );
}

function ActiveIcon({ className = '' }) {
  return (
    <div className={`${style.stageActiveIcon} ${className}`}>
      <div className={style.stageActiveIconInner}></div>
    </div>
  );
}

function Item({ children, index, active = false, passed = false }) {
  const icon = useMemo(() => {
    if (active) {
      return <ActiveIcon />;
    } else if (passed) {
      return <CheckOutlined className={style.stageCheckIcon} />;
    } else {
      return <div className={style.stageIndex}>{index}.</div>;
    }
  });

  return (
    <div
      className={`${style.stageItem}  ${active ? style.stageArrowActive : ''}`}
    >
      {icon}
      <div className={`${style.stageText}`}>{children}</div>
    </div>
  );
}

function PackageAiStage({ stage = 1 }) {
  return (
    <div className={style.stageBox}>
      <div className={style.stageItem}>
        <div className={style.stageTitle}>Pack AI Screening</div>
      </div>
      <Arrow isNext={stage === 1} />
      <Item active={stage === 1} passed={stage > 1} index="1">
        Fill in Project Details
      </Item>
      <Arrow active={stage === 1} isNext={stage === 2} />
      <Item active={stage === 2} passed={stage > 2} index="2">
        Upload Pack Design Images
      </Item>
      <Arrow active={stage === 2} isNext={stage === 3} />
      <Item active={stage === 3} passed={stage > 3} index="3">
        Highlight Key Design Elements
      </Item>
      <Arrow active={stage === 3} isNext={stage === 4} />
      <Item active={stage === 4} passed={stage > 4} index="4">
        Result
      </Item>
    </div>
  );
}

export default PackageAiStage;
