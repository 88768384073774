// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wtLdcjwsJ5jI03NrnaC6{position:absolute;top:10px;left:30px;z-index:11}.u4XLpeMp_DRg7EyvwQhL{width:83px}`, "",{"version":3,"sources":["webpack://./src/modules/occasion-module/views/home/components/logo.module.scss"],"names":[],"mappings":"AAAA,sBACE,iBAAA,CACA,QAAA,CACA,SAAA,CACA,UAAA,CAGF,sBACE,UAAA","sourcesContent":[".logoContainer {\n  position: absolute;\n  top: 10px;\n  left: 30px;\n  z-index: 11;\n}\n\n.logoImg {\n  width: 83px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"logoContainer": `wtLdcjwsJ5jI03NrnaC6`,
	"logoImg": `u4XLpeMp_DRg7EyvwQhL`
};
export default ___CSS_LOADER_EXPORT___;
