import React, { useState, useEffect } from 'react';
import TimeRangeSelector from '../range-selector/range-selector.jsx';
import YearSelector from '../year-selector/year-selector.jsx';
import GraphComponent from '../graph-new/graph.jsx';
import GraphComponentMulti from '../graph-new/graph-multi.jsx';
import style from '../graph/graph.module.scss';
import { aiTopDishInfo } from '../../../../components/icons/icons.jsx';
import Tippy from '@tippyjs/react';
import { graphTrendIndexLogo, aiTopTiktokLogo, aiTopMetaLogo, aiTopGoogleLogo } from '../../../../components/icons/icons.jsx';

const initialTimeRanges = ['Monthly', 'Quarterly', 'Yearly'];
const years = ['2021', '2022', '2023', '2024'];

const GraphWithSelectors = ({
  countries,
  brand,
  topDish,
  trendingDishId,
  graphId,
  graphData,
  getEchartsOption,
  getMultiEchartsOption
}) => {
  const [selectedTimeRange, setSelectedTimeRange] = useState('Monthly');
  const [selectedYears, setSelectedYears] = useState(['2024']);
  const [timeRanges, setTimeRanges] = useState(initialTimeRanges);

  const dropdownListStyle = {
    fontSize: '12px',
    fontFamily: 'unilever-shilling-regular',
    background: '#18181B',
    borderRadius: '0px',
    boxShadow: '0px 0px 12px 0px #00A32E',
  };

  useEffect(() => {
    if (selectedYears.length > 1) {
      setTimeRanges(['Monthly', 'Quarterly', 'Yearly']);
    } else if (selectedYears.length === 1) {
      setTimeRanges(['Monthly', 'Quarterly']);
      if (selectedTimeRange === 'Yearly') {
        setSelectedTimeRange('Monthly');
      }
    } else {
      setTimeRanges(initialTimeRanges);
    }
  }, [selectedYears]);

  const getLogo = (graphId) => {
    let logoSrc = null;
    let titleText = '';
    let className = '';
    let tooltipContent = '';
  
    switch (graphId) {
    case 'TrendIndexCurve':
      logoSrc = graphTrendIndexLogo;
      titleText = 'Trend Index Curve';
      className = 'trend-index-curve';
      tooltipContent = 'Shows the evolution of the trend index score for the selected \'Trending Twists\' over a given period of time';
      break;
    case 'MetaPosts':
      logoSrc = aiTopMetaLogo;
      titleText = 'Posts';
      className = 'meta-post';
      tooltipContent = 'Shows the evolution of the number of Meta posts for the selected \'Trending Twists\' over a given period of time';
      break;
    case 'TiktokMentions':
      logoSrc = aiTopTiktokLogo;
      titleText = 'Mentions';
      className = 'tik-tok-mentions';
      tooltipContent = 'Shows the evolution of the number of TikTok mentions for the selected \'Trending Twists\' over a given period of time';
      break;
    case 'GoogleSearches':
      logoSrc = aiTopGoogleLogo;
      titleText = 'Searches';
      className = 'google-searches';
      tooltipContent = 'Shows the evolution of the number of Google searches for the selected \'Trending Twists\' over a given period of time';
      break;
    case 'channels':
      break
    default:
      break;
    }
  
    if (logoSrc) {
      return { logoSrc, titleText, className, tooltipContent };
    } else {
      return null;
    }
  };

  const logoInfo = getLogo(graphId);

  return (
    <div className={style.GraphWithSelectors}>
      <div className={`filter-container ${style.BorderContainer} ${graphData === ' ' ? 'no-graph' : 'graph'}`}>
        {/* {logoInfo && (
          <div className='logo-container'>
            <div className='title-wrapper'>
              <div className='logo-wrapper'>
                <img src={logoInfo.logoSrc} alt={`${logoInfo.titleText} logo`} />
              </div>
              <div className={logoInfo.className}>{logoInfo.titleText}</div>
            </div>
          </div>
        )} */}
        {/* <div className="inputBorder">
          <YearSelector
            years={years}
            selectedYears={selectedYears}
            dropdownStyle={dropdownListStyle}
            setSelectedYears={setSelectedYears}
          />
        </div>
        <div className="inputBorder">
          <TimeRangeSelector
            timeRanges={timeRanges}
            selectedTimeRange={selectedTimeRange}
            dropdownStyle={dropdownListStyle}
            setSelectedTimeRange={setSelectedTimeRange}
          />
        </div>
        <div className="inputBorder rightSpace"> 
          <Tippy
            maxWidth="270px"
            theme="custom"
            content={<span>{logoInfo ? logoInfo.tooltipContent : ''}</span>}
            placement="top"
          >
            <img src={aiTopDishInfo} alt="info" />
          </Tippy>
        </div> */}
        {graphId=='channels'?
        <GraphComponentMulti
          countries={countries}
          brand={brand}
          topDish={topDish}
          trendingDishId={trendingDishId}
          selectedYears={selectedYears}
          selectedTimeRange={selectedTimeRange}
          graphId={graphId}
          graphData={graphData}
          getMultiEchartsOption={getMultiEchartsOption}
        />
        :
        <GraphComponent
          countries={countries}
          brand={brand}
          topDish={topDish}
          trendingDishId={trendingDishId}
          selectedYears={selectedYears}
          selectedTimeRange={selectedTimeRange}
          graphId={graphId}
          graphData={graphData}
          getEchartsOption={getEchartsOption}
        />
        }
        
      </div>
    </div>
  );
};

export default GraphWithSelectors;
