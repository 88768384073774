import { Tabs } from 'antd';
import React, { useEffect, useState } from 'react';
import { downloadWb } from '../../../apis/file/index.js';
import BarColChart from '../charts/bar-col.jsx';
import BarChart from '../charts/bar.jsx';
import LineChart from '../charts/line.jsx';
import StackChart from '../charts/stack-col.jsx';
import TimeChart from '../charts/time.jsx';
import style from './tab.module.scss';

function Who({ filter, compare, hasCompare, exportCount, isActive }) {
  const saveDataFns = [];
  function appendSaveDataFn(fn) {
    saveDataFns.push(fn);
  }

  useEffect(() => {
    if (!isActive) return;
    downloadWb(saveDataFns, 'xlsx', 'Who');
  }, [exportCount]);

  const [userSelectChart, setUserSelectChart] = useState('');
  const [userSelectChartKey, setUserSelectChartKey] = useState('');
  const [userSelectChartValue, setUserSelectChartValue] = useState('');
  function onUserSelectChart(name, key, value) {
    setUserSelectChart(name);
    setUserSelectChartKey(key);
    setUserSelectChartValue(value);
  }

  return (
    <div className={style.chartContainer}>
      <StackChart
        name="who-age"
        tabName="who-when-why-where-who-with"
        tab2Name="who"
        title="Age"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="2"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="User Age Group"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <StackChart
        name="who-future-focused-age"
        tabName="who-when-why-where-who-with"
        tab2Name="who"
        title="Future Focused Age"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="2"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Future Focused Age"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <StackChart
        name="who-gender"
        tabName="who-when-why-where-who-with"
        tab2Name="who"
        title="Gender"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="2"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="User Gender"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <StackChart
        name="who-sel"
        tabName="who-when-why-where-who-with"
        tab2Name="who"
        title="SEL"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="2"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="User SEL Bands"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <StackChart
        name="who-income-brackets"
        tabName="who-when-why-where-who-with"
        tab2Name="who"
        title="Income brackets"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="2"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Income Brackets"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <StackChart
        name="who-income-brackets-br"
        tabName="who-when-why-where-who-with"
        tab2Name="who"
        title="Income brackets BR"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="2"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Income Brackets"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <StackChart
        name="who-income-brackets-de"
        tabName="who-when-why-where-who-with"
        tab2Name="who"
        title="Income brackets DE"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="2"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Income Brackets"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <StackChart
        name="who-income-brackets-id"
        tabName="who-when-why-where-who-with"
        tab2Name="who"
        title="Income brackets ID"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="2"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Income Brackets"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <StackChart
        name="who-income-brackets-mx"
        tabName="who-when-why-where-who-with"
        tab2Name="who"
        title="Income brackets MX"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="2"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Income Brackets"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <StackChart
        name="who-income-brackets-uk"
        tabName="who-when-why-where-who-with"
        tab2Name="who"
        title="Income brackets UK"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="2"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Income Brackets"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <StackChart
        name="who-income-brackets-us"
        tabName="who-when-why-where-who-with"
        tab2Name="who"
        title="Income brackets US"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="2"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Income Brackets"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <StackChart
        name="who-income-brackets-it"
        tabName="who-when-why-where-who-with"
        tab2Name="who"
        title="Income brackets IT"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="2"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Income Brackets"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <StackChart
        name="who-income-brackets-fr"
        tabName="who-when-why-where-who-with"
        tab2Name="who"
        title="Income brackets FR"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="2"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Income Brackets"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <StackChart
        name="who-income-brackets-tr"
        tabName="who-when-why-where-who-with"
        tab2Name="who"
        title="Income brackets TR"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="2"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Income Brackets"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <StackChart
        name="who-income-brackets-nl"
        tabName="who-when-why-where-who-with"
        tab2Name="who"
        title="Income brackets NL"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="2"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Income Brackets"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <StackChart
        name="who-income-brackets-au"
        tabName="who-when-why-where-who-with"
        tab2Name="who"
        title="Income brackets AU"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="2"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Income Brackets"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <StackChart
        name="who-income-brackets-ch"
        tabName="who-when-why-where-who-with"
        tab2Name="who"
        title="Income brackets CH"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="2"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Income Brackets"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <StackChart
        name="who-income-brackets-se"
        tabName="who-when-why-where-who-with"
        tab2Name="who"
        title="Income brackets SE"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="2"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Income Brackets"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <StackChart
        name="who-income-brackets-be"
        tabName="who-when-why-where-who-with"
        tab2Name="who"
        title="Income brackets BE"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="2"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Income Brackets"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <StackChart
        name="who-income-brackets-fi"
        tabName="who-when-why-where-who-with"
        tab2Name="who"
        title="Income brackets FI"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="2"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Income Brackets"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <BarColChart
        name="who-household-composition"
        tabName="who-when-why-where-who-with"
        tab2Name="who"
        title="Houshold composition"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        desc={true}
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Houshold Composition"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <StackChart
        name="who-working-vs-not-working"
        tabName="who-when-why-where-who-with"
        tab2Name="who"
        title="Working vs. not working"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="2"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Working Vs. Not Working"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <BarChart
        name="who-employment-status"
        tabName="who-when-why-where-who-with"
        tab2Name="who"
        title="Employment status"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="User Age Group"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <BarChart
        name="who-ethnicity"
        tabName="who-when-why-where-who-with"
        tab2Name="who"
        title="Ethnicity"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Ethnicity"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <BarChart
        name="who-region"
        tabName="who-when-why-where-who-with"
        tab2Name="who"
        title="Region"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Region"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
    </div>
  );
}

function When({ filter, compare, hasCompare, exportCount, isActive }) {
  const saveDataFns = [];
  function appendSaveDataFn(fn) {
    saveDataFns.push(fn);
  }

  useEffect(() => {
    if (!isActive) return;
    if (!exportCount) return;
    downloadWb(saveDataFns, 'xlsx', 'When');
  }, [exportCount]);

  const [userSelectChart, setUserSelectChart] = useState('');
  const [userSelectChartKey, setUserSelectChartKey] = useState('');
  const [userSelectChartValue, setUserSelectChartValue] = useState('');
  function onUserSelectChart(name, key, value) {
    setUserSelectChart(name);
    setUserSelectChartKey(key);
    setUserSelectChartValue(value);
  }

  return (
    <div className={style.chartContainer}>
      <BarChart
        name="when-meal-type"
        tabName="who-when-why-where-who-with"
        tab2Name="when"
        title="Meal type"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Meal Type"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <TimeChart
        name="when-meal-type"
        tabName="who-when-why-where-who-with"
        tab2Name="when"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        chartKey="Meal Type"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <BarChart
        name="when-weekday-vs-weekend"
        tabName="who-when-why-where-who-with"
        tab2Name="when"
        title="Weekday vs. weekend"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Weekday Vs. Weekend"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <LineChart
        name="when-time-of-day"
        tabName="who-when-why-where-who-with"
        tab2Name="when"
        title="Time of day"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        sortList={[
          '12am-03am',
          '03am-06am',
          '06am-09am',
          '09am-12pm',
          '12pm-03pm',
          '03pm-06pm',
          '06pm-09pm',
          '09pm-12am',
        ]}
        chartKey="Time Of Day"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <BarChart
        name="when-activities"
        tabName="who-when-why-where-who-with"
        tab2Name="when"
        title="Activities"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Activities"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <BarChart
        name="when-when-did-you-decide-what-to-eat"
        tabName="who-when-why-where-who-with"
        tab2Name="when"
        title="When did you decide what to eat"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Food Decision Time"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
    </div>
  );
}

function WhyMood({ filter, compare, hasCompare, exportCount, isActive }) {
  const saveDataFns = [];
  function appendSaveDataFn(fn) {
    saveDataFns.push(fn);
  }

  useEffect(() => {
    if (!isActive) return;
    if (!exportCount) return;
    downloadWb(saveDataFns, 'xlsx', 'Why Mood');
  }, [exportCount]);

  const [userSelectChart, setUserSelectChart] = useState('');
  const [userSelectChartKey, setUserSelectChartKey] = useState('');
  const [userSelectChartValue, setUserSelectChartValue] = useState('');
  function onUserSelectChart(name, key, value) {
    setUserSelectChart(name);
    setUserSelectChartKey(key);
    setUserSelectChartValue(value);
  }

  return (
    <div className={style.chartContainer}>
      <BarChart
        name="why-pre-meal-mood"
        tabName="who-when-why-where-who-with"
        tab2Name="why-mood"
        title="Pre meal mood"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Pre Meal Mood"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <BarChart
        name="why-post-meal-mood"
        tabName="who-when-why-where-who-with"
        tab2Name="why-mood"
        title="Post meal mood"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Post Meal Mood"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <BarChart
        name="why-top-reasons-for-choices"
        tabName="who-when-why-where-who-with"
        tab2Name="why-mood"
        title="Top reasons for choice"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Top Reasons For Choice"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <BarChart
        name="why-top-benefits"
        tabName="who-when-why-where-who-with"
        tab2Name="why-mood"
        title="Top benefits"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Top Benefits"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <BarChart
        name="why-top-reason-of-category-choices"
        tabName="who-when-why-where-who-with"
        tab2Name="why-mood"
        title="Top reason of category choices"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Top Reason Of Category Choices"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <StackChart
        name="why-was-anything-else-considered"
        tabName="who-when-why-where-who-with"
        tab2Name="why-mood"
        title="Was anything else considered?"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Consideration (Y/N)"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <BarChart
        name="why-what-other-food-or-drink-did-you-consider-having-instead-l1"
        tabName="who-when-why-where-who-with"
        tab2Name="why-mood"
        title="What other food or drink did you consider having instead? (L1)"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Consideration Set (Food / Drink, L1)"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <BarChart
        name="why-what-other-food-or-drink-did-you-consider-having-instead-l2"
        tabName="who-when-why-where-who-with"
        tab2Name="why-mood"
        title="What other food or drink did you consider having instead? (L2)"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Consideration Set (Food / Drink, L2)"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
    </div>
  );
}

function WhoWith({ filter, compare, hasCompare, exportCount, isActive }) {
  const saveDataFns = [];
  function appendSaveDataFn(fn) {
    saveDataFns.push(fn);
  }

  useEffect(() => {
    if (!isActive) return;
    if (!exportCount) return;
    downloadWb(saveDataFns, 'xlsx', 'Who with');
  }, [exportCount]);

  const [userSelectChart, setUserSelectChart] = useState('');
  const [userSelectChartKey, setUserSelectChartKey] = useState('');
  const [userSelectChartValue, setUserSelectChartValue] = useState('');
  function onUserSelectChart(name, key, value) {
    setUserSelectChart(name);
    setUserSelectChartKey(key);
    setUserSelectChartValue(value);
  }

  return (
    <div className={style.chartContainer}>
      <BarChart
        name="who-with-who-are-you-with"
        tabName="who-when-why-where-who-with"
        tab2Name="who-with"
        title="Who are you with"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Who With"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <BarChart
        name="who-with-group-size"
        tabName="who-when-why-where-who-with"
        tab2Name="who-with"
        title="Group size"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        sortList={['1', '2', '3', '4', '5+']}
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Group Size"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <BarChart
        name="who-with-are-people-you-are-with-having-the-same-food-as-you"
        tabName="who-when-why-where-who-with"
        tab2Name="who-with"
        title="Are people you are with having the same food as you?"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Sharing (Y/N)"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <BarChart
        name="who-with-who-decided-what-you-were-going-to-eat-today"
        tabName="who-when-why-where-who-with"
        tab2Name="who-with"
        title="Who decided what you were going to eat today?"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Food Decision Maker"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
    </div>
  );
}

function Where({ filter, compare, hasCompare, exportCount, isActive }) {
  const saveDataFns = [];
  function appendSaveDataFn(fn) {
    saveDataFns.push(fn);
  }

  useEffect(() => {
    if (!isActive) return;
    if (!exportCount) return;
    downloadWb(saveDataFns, 'xlsx', 'Where');
  }, [exportCount]);

  const [userSelectChart, setUserSelectChart] = useState('');
  const [userSelectChartKey, setUserSelectChartKey] = useState('');
  const [userSelectChartValue, setUserSelectChartValue] = useState('');
  function onUserSelectChart(name, key, value) {
    setUserSelectChart(name);
    setUserSelectChartKey(key);
    setUserSelectChartValue(value);
  }

  return (
    <div className={style.chartContainer}>
      <BarChart
        name="where-in-home-vs-out-of-home"
        tabName="who-when-why-where-who-with"
        tab2Name="who-with"
        title="In home vs. Out of home"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="In Home Vs. Out Of Home"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <BarChart
        name="where-place-of-consumption"
        tabName="who-when-why-where-who-with"
        tab2Name="who-with"
        title="Place of consumption"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Place Of Consumption"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
    </div>
  );
}

function WhoWhenWhyWhereWhoWithWhere({
  filter,
  compare,
  hasCompare,
  exportCount,
  isActive,
}) {
  const startTab = new URL(window.location).searchParams.get('tab2');

  const [activeKey, setActiveKey] = useState(startTab ?? 'who');

  return (
    <Tabs
      tabPosition="top"
      defaultActiveKey={startTab}
      onChange={setActiveKey}
      items={[
        {
          label: <span>{'Who'}</span>,
          key: 'who',
          children: (
            <Who
              filter={filter}
              compare={compare}
              hasCompare={hasCompare}
              exportCount={exportCount}
              isActive={isActive && activeKey === 'who'}
            />
          ),
        },
        {
          label: <span>{'When'}</span>,
          key: 'when',
          children: (
            <When
              filter={filter}
              compare={compare}
              hasCompare={hasCompare}
              exportCount={exportCount}
              isActive={isActive && activeKey === 'when'}
            />
          ),
        },
        {
          label: <span>{'Why \u0026 Mood'}</span>,
          key: 'why-mood',
          children: (
            <WhyMood
              filter={filter}
              compare={compare}
              hasCompare={hasCompare}
              exportCount={exportCount}
              isActive={isActive && activeKey === 'why-mood'}
            />
          ),
        },
        {
          label: <span>{'Who with'}</span>,
          key: 'who-with',
          children: (
            <WhoWith
              filter={filter}
              compare={compare}
              hasCompare={hasCompare}
              exportCount={exportCount}
              isActive={isActive && activeKey === 'who-with'}
            />
          ),
        },
        {
          label: <span>{'Where'}</span>,
          key: 'where',
          children: (
            <Where
              filter={filter}
              compare={compare}
              hasCompare={hasCompare}
              exportCount={exportCount}
              isActive={isActive && activeKey === 'where'}
            />
          ),
        },
      ]}
    ></Tabs>
  );
}

export default WhoWhenWhyWhereWhoWithWhere;
