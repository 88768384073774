import React, { useState, useEffect, useRef, memo, forwardRef, useImperativeHandle } from 'react';
import ReactECharts from 'echarts-for-react';
import * as echarts from 'echarts/core';
import { CanvasRenderer } from 'echarts/renderers';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import style from './trends-charts.module.scss';
import classNames from 'classnames';
import { fetchSearchDial, fetchSearchMacro } from '../../api/request.js';
import UKLayerMiddleData from '../../data/charts-data/layer-middle-data-uk.js';
import USLayerMiddleData from '../../data/charts-data/layer-middle-data-us.js';
import DiasporaModal from '../diaspora-modal/diaspora-modal.jsx';

import ImageTotalNutrition from '../../../../assets/images/trends-dial/Total Nutrition.png';
import ImageBouillionSeasoning from '../../../../assets/images/trends-dial/Bouillion & Seasoning.png';
import ImageMealSolutions from '../../../../assets/images/trends-dial/Meal Solutions.png';
import ImageMiniMeal from '../../../../assets/images/trends-dial/Mini meal.png';
import ImageMayonnaise from '../../../../assets/images/trends-dial/Mayonnaise.png';
import ImageScratchCooking from '../../../../assets/images/trends-dial/Scratch Cooking.png';
import ImageRicePastaSide from '../../../../assets/images/trends-dial/Rice & Pasta Side.png';
import ImageRamenAndOtherCups from '../../../../assets/images/trends-dial/Ramen and other cups.png';
import ImageBrothBouillon from '../../../../assets/images/trends-dial/Broth & Bouillon.png';
import ImageOthers from '../../../../assets/images/trends-dial/others.png';

const imgMap = {
  'Total Nutrition': ImageTotalNutrition,
  'Bouillon & Seasoning': ImageBouillionSeasoning,
  'Meal Solutions': ImageMealSolutions,
  'Mini meal': ImageMiniMeal,
  'Mayonnaise': ImageMayonnaise,
  'Scratch Cooking': ImageScratchCooking,
  'Rice & Pasta Side': ImageRicePastaSide,
  'Ramen and other cups': ImageRamenAndOtherCups,
  'Broth & Bouillon': ImageBrothBouillon,
  'others': ImageOthers
};

echarts.use(
  [CanvasRenderer]
);

const antIcon = (
  <LoadingOutlined
    style={{
      fontSize: 30,
      color: '#00FF00'
    }}
    spin
  />
);

function deepClone(value) {
  if (value !== null) {
    let cloned;
    if (Array.isArray(value)) {
      cloned = new Array(value.length);
      for (let i = 0; i < value.length; i++) {
        cloned[i] = typeof value[i] !== 'object' ? value[i] : deepClone(value[i]);
      }
    } else {
      cloned = {};
      const keys = Object.keys(value);
      for (let i = 0; i < keys.length; i++) {
        const key = keys[i];
        cloned[key] = typeof value[key] !== 'object' ? value[key] : deepClone(value[key]);
      }
    }
    return cloned;
  }
  return value;
}

let animateIng = false;

// 创建一个隐藏的 Canvas 元素
const canvas = document.createElement('canvas');
const ctx = canvas.getContext('2d');

const isWindows = navigator.userAgent.toLowerCase().indexOf('win') >= 0;
const dpr = window.devicePixelRatio || 1;
let fontSizeNum = 12;
if (isWindows) {
  fontSizeNum = 12 / dpr;
}
if (fontSizeNum < 8) {
  fontSizeNum = 8;
}

// 设置文字样式
const fontSize = fontSizeNum + 'px';
const fontFamily = 'unilever-shilling-regular';
const fontWeight = 'normal'; // 文字粗细
ctx.font = `${fontWeight} ${fontSize}px ${fontFamily}`;

function getTextWidth(text) {
  ctx.clearRect(0, 0, 1000, 1000);
  return ctx.measureText(text).width;
}

function calculateBaseLength(sideLength, angleInDegrees) {

  // 将角度转换为弧度  
  const angleInRadians = angleInDegrees * 3.6 * (Math.PI / 180);
  
  // 使用余弦定理计算底边长度的平方  
  const baseSquared = 2 * sideLength * sideLength * (1 - Math.cos(angleInRadians));  
    
  // 对底边长度的平方开平方，得到底边的长度  
  const baseLength = Math.sqrt(baseSquared);  
    
  return baseLength;  
} 

const colors = [
  {
    color1: '#004771',
    color2: '#31739A',
    color3: '#0566A0',
    textColor: '#FFFFFF'
  },
  {
    color1: '#036090',
    color2: '#4E91B4',
    color3: '#0173AE',
    textColor: '#FFFFFF'
  },
  {
    color1: '#04763F',
    color2: '#4A9B74',
    color3: '#05954F',
    textColor: '#FFFFFF'
  },
  {
    color1: '#E38007',
    color2: '#FFB151',
    color3: '#F39118',
    textColor: '#FFFFFF'
  },
  {
    color1: '#F6CF00',
    color2: '#EBDD5B',
    color3: '#FFD707',
    textColor: '#00A32E'
  },
  {
    color1: '#F8BC02',
    color2: '#EAC963',
    color3: '#FFC100',
    textColor: '#FFFFFF'
  }
];

const colorMap = {
  'CONSCIOUS CHOICES': {
    color1: '#32806C',
    color2: '#6DA496',
    color3: '#39A388',
    textColor: '#FFFFFF'
  },
  'HEALTHIER LIVING': {
    color1: '#04763F',
    color2: '#4A9B74',
    color3: '#05954F',
    textColor: '#FFFFFF'
  },
  'COOKING RENAISSANCE': {
    color1: '#036090',
    color2: '#4E91B4',
    color3: '#0173AE',
    textColor: '#FFFFFF'
  },
  'INDULGING EXPERIENCE': {
    color1: '#E38007',
    color2: '#FFB151',
    color3: '#F39118',
    textColor: '#FFFFFF'
  },
  'SMART SPENDING': {
    color1: '#F6CF00',
    color2: '#EBDD5B',
    color3: '#FFD707',
    textColor: '#00A32E'
  },
  'SMART SOLUTIONS': {
    color1: '#004771',
    color2: '#31739A',
    color3: '#0566A0',
    textColor: '#FFFFFF'
  },
  'ANYTIME ANYWHERE': {
    color1: '#F8BC02',
    color2: '#EAC963',
    color3: '#FFC100',
    textColor: '#FFFFFF'
  },
  'DIASPORA': {
    color1: '#A6A6A6',
    color2: '#E1EDE7',
    color3: '#D7D2D2',
    textColor: '#00A32E'
  },
  'FLAVOR EXPLORATION': {
    color1: '#E38007',
    color2: '#FFB151',
    color3: '#F39118',
    textColor: '#FFFFFF'
  },
  'SMART COOKING AND SPENDING': {
    color1: '#F6CF00',
    color2: '#EBDD5B',
    color3: '#FFD707',
    textColor: '#00A32E'
  }
};

let dialData = {};
let macroData = {};

let TrendsCharts = forwardRef(function TrendsCharts({country, toggleSideDetail}, ref) {
  const rootElement = useRef(null);
  const [chartsRadius, setChartsRadius] = useState(0);
  function computeRadius() {
    if (rootElement.current) {
      const { offsetWidth, offsetHeight } = rootElement.current;
      setChartsRadius(Math.min(offsetWidth, offsetHeight) * 0.8 + 8);
    }
  }
  // 注册resize
  useEffect(() => {
    window.addEventListener('resize', computeRadius);
    computeRadius();
    return () => {
      window.removeEventListener('resize', computeRadius);
    };
  }, []);

  const DiasporaModalRef = useRef(null);

  const [loading, setLoading] = useState(true);
  const [option, setOption] = useState({});

  // echarts options计算
  const chartsOptionsComputed = (seriesData, silent) => {
    const rd = (chartsRadius - 8) / 2;
    seriesData.forEach((level1, index) => {
      let color = colorMap[level1.name.toUpperCase().trim()];
      if (!color) {
        color = colors[index%colors.length];
      }
      level1.itemStyle = {
        color: color.color1,
        borderColor: color.color1,
        shadowColor: 'rgba(0, 0, 0, 0.5)',
        shadowBlur: 4,
      };
      if (level1.value > 50) {
        level1.label = {
          width: '100',
          rotate: 'tangential',
          overflow: 'break',
        };
      } else {
        const r = rd / 80 * (65 / 3 / 2 * 5 + 15);
        let width = calculateBaseLength(r, level1.value);
        width = width - 4;
        const textWidth = getTextWidth(level1.name);
        if (width > textWidth/2) {
          level1.label = {
            width,
            rotate: 'tangential',
            overflow: 'break',
          };
        } else {
          level1.label = {
            width,
            rotate: 'tangential',
            overflow: 'truncate',
          };
        }
      }
      level1.label.color = color.textColor;
      level1?.children?.forEach(level2 => {
        level2.itemStyle = {
          color: color.color2,
          borderColor: color.color2,
          shadowColor: 'rgba(0, 0, 0, 0.5)',
          shadowBlur: 4,
        };
        if (level2.value > 50) {
          level2.label = {
            width: '100',
            rotate: 'tangential',
            overflow: 'break',
          };
        } else {
          const r = rd / 80 * (65 / 3 / 2 * 3 + 15);
          let width = calculateBaseLength(r, level2.value);
          width = width - 4;
          const textWidth = getTextWidth(level2.name);
          if (width > textWidth/2) {
            level2.label = {
              width: width > 100 ? 100 : width,
              rotate: 'tangential',
              overflow: 'break',
            };
          } else {
            level2.label = {
              width,
              rotate: 'tangential',
              overflow: 'truncate',
            };
          }
        }
        level2.label.color = color.textColor;

        level2?.children?.forEach(level3 => {
          level3.itemStyle = { 
            color: color.color3,
            borderColor: color.color3,
            shadowColor: 'rgba(0, 0, 0, 0.5)',
            shadowBlur: 4,
          };
          
          if (level3.value > 50) {
            level3.label = {
              width: '100',
              rotate: 'tangential',
              overflow: 'break',
            };
          } else {
            const r = rd / 80 * (65 / 3 / 2 * 1 + 15);
            let width = calculateBaseLength(r, level3.value);
            width = width - 4;
            const textWidth = getTextWidth(level3.name);
            if (width > textWidth/2) {
              level3.label = {
                width: width > 100 ? 100 : width,
                rotate: 'tangential',
                overflow: 'break',
              };
            } else {
              level3.label = {
                width,
                rotate: 'tangential',
                overflow: 'truncate',
              };
            }
          }
          level3.label.color = color.textColor;

          level3.label.show = false;
        });
      });
    });
    const opts = {
      tooltip: {
        trigger: 'item',
      },
      series: [
        {
          radius: ['15%', '80%'],
          type: 'sunburst',
          sort: undefined,
          nodeClick: 'false',
          emphasis: {
            focus: 'self',
            itemStyle: {
              opacity: 1
            }
          },
          label: {
            color: '#fff',
            fontSize,
            fontFamily
          },
          levels: [
            {},
            {
              radius: ['58%', '80%'],
            },
            {
              radius: ['36%', '58%'],
            },
            {
              radius: ['15%', '36%'],
            }
          ],
          data: seriesData,
          tooltip: {
            formatter: (params) => {
              return params.marker + params.name + ': ' + params.value.toFixed(2) + '%';
            }
          },
          silent
        }
      ]
    };
    setOption(opts);
  };

  // 依赖country更新dial数据
  const [sideLoading, setSideLoading] = useState(true);
  useEffect(() => {
    setSideLoading(true);
    const params = {
      country: country
    };
    Promise.allSettled([fetchSearchDial(params), fetchSearchMacro(params)])
      .then(([res1, res2]) => {
        if (res1.status === 'fulfilled') {
          const dialRes = {};
          Object.keys(res1.value.data).forEach(key => {
            dialRes[key.toUpperCase().trim()] = res1.value.data[key];
          });
          dialData = dialRes;
        } else {
          dialData = {};
        }
        if (res2.status === 'fulfilled') {
          const macroRes = {};
          res2.value.data.forEach(item => {
            macroRes[item.name.toUpperCase().trim()] = item;
          });
          macroData = macroRes;
        } else {
          macroData = {};
        }
      })
      .catch(error => console.error(error))
      .finally(() => {
        setSideLoading(false);
      });
  }, [country]);

  const toggleHandler = (data) => {
    toggleSideDetail && toggleSideDetail(data);
  };

  const onChartClick = (e) => {
    const { treePathInfo } = e;
    const level1_name = treePathInfo[1].name.toUpperCase().trim();
    if (level1_name === 'DIASPORA' && country === 'GB') {
      return DiasporaModalRef.current.open();
    }
    const macro = macroData[level1_name] || {
      name: ' ',
      cagr: 0,
      periodCagr: '',
      yoy: 0,
      periodYoy: ''
    };
    const macroKey = macro.name.toUpperCase().trim();
    // macro 接口无数据
    // if (!macro) return;
    const dial_list = dialData[macroKey] || [];
    const series = option.series[0].data;
    const data = {
      upperTitle: level1_name,
      title: macro.name,
      cagr: {
        value: macro.cagr,
        date: macro.periodCagr
      },
      yoy: {
        value: macro.yoy,
        date: macro.periodYoy
      },
      solutionTrends: series.find(item => item.name === level1_name).children.map(item => {
        return {
          name: item.name,
        };
      }),
      consumerTrends: dial_list
    };
    if (animateIng) return;
    toggleHandler(data);
  };

  const [nutritionValue, setNutritionValue] = useState(imgMap['others']);

  useImperativeHandle(ref, () => ({
    changeNutrition: (nutrition, nutritionOption, countryValue) => {
      setLoading(true);
      if (imgMap[nutrition.trim()]) {
        setNutritionValue(imgMap[nutrition.trim()]);
      } else {
        setNutritionValue(imgMap['others']);
      }
      const optionData = [];
      let middleData = [];
      if (countryValue !== 'US') {
        middleData = deepClone(UKLayerMiddleData);
      } else {
        if (nutrition === 'Scratch Cooking') {
          middleData = deepClone(USLayerMiddleData);
        }
      }
      nutritionOption.forEach(consumerTrend => {
        const level1Data = {
          name: consumerTrend.consumerTrend.toUpperCase().trim(),
          value: consumerTrend.shareValue * 100,
        };
        const level2Data = middleData[level1Data.name] || [];
        if (level2Data.length === 0) {
          level2Data.push({
            name: '',
            value: level1Data.value
          });
        }

        const level3Data = consumerTrend.children.map(item => ({
          name: item.macroTrend,
          value: item.shareValue * 100
        }));
        if (level3Data.length === 0) {
          level3Data.push({
            name: '',
            value: level1Data.value
          });
        }

        level2Data[0].children = level3Data;
        level1Data.children = level2Data.map(item => ({
          ...item,
          value: Object.prototype.hasOwnProperty.call(item, 'value') ? item.value :level1Data.value / level2Data.length
        }));
        optionData.push(level1Data);
      });
      // const silent = !['US', 'GB'].includes(countryValue);
      const silent = false;
      setTimeout(() => {
        chartsOptionsComputed(optionData, silent);
        setLoading(false);
      }, 500);
    },
  }));

  return (
    <div className={classNames(style.myEchartsMap)} ref={rootElement}>
      {(loading || sideLoading) && <Spin indicator={antIcon}>
        <div className={style.placeholder}></div>
      </Spin>}
      {!(loading || sideLoading) && (
        <>
          <ReactECharts
            echarts={echarts}
            option={option}
            onEvents={{
              'click': onChartClick
            }}
          />
          {Object.keys(option)?.length > 0 && <div
            className={style.bgBox} 
            style={{
              width: chartsRadius + 'px',
              height: chartsRadius + 'px'
            }}
          >
            <div className={style.bgBoxInner}>
              <img src={nutritionValue} alt="" />
            </div>
          </div>}
        </>
      )}
      <DiasporaModal ref={DiasporaModalRef} />
    </div>
  );
});

TrendsCharts = memo(TrendsCharts);

export default TrendsCharts;