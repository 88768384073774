import { Button, Select } from 'antd';
import React from 'react';
import OccasionCollapse from './collapse.jsx';
import style from './filter.module.scss';

export function OccasionFilterItem({
  label,
  name,
  options,
  multiple,
  data,
  setData,
  additionalFilter,
}) {
  function onSelect(value) {
    if (!value || !value.length) {
      delete data[name];
      setData({ ...data });
    } else {
      setData({ ...data, [name]: value });
    }
  }

  return (
    <div className={style.filterItemContainer} key={name}>
      {label && <div className={style.filterItemLabel}>{label}</div>}
      <Select
        style={{ width: '100%' }}
        value={data[name]}
        onChange={onSelect}
        name={name}
        options={options}
        mode={multiple ? 'multiple' : undefined}
      ></Select>
      {additionalFilter && additionalFilter()}
    </div>
  );
}

export function OccasionFilterGroups({
  groups,
  filters,
  data,
  setData,
  startGroup,
  additionalBtnMap,
}) {
  const filterGroups = groups.map((e, i) => {
    const additionalBtns = additionalBtnMap ? additionalBtnMap[e.label] : {};
    return {
      key: e.id,
      label: e.label,
      children: filters[i].map((e1) => {
        const additionalFilter = additionalBtns
          ? additionalBtns[e1.label]
          : undefined;
        return OccasionFilterItem({ ...e1, data, setData, additionalFilter });
      }),
    };
  });
  return <OccasionCollapse items={filterGroups} startGroup={startGroup} />;
}

export function OccasionFilters({ filters, data, setData }) {
  return filters.map((e) => OccasionFilterItem({ ...e, data, setData }));
}

export function OccasionCompareBtn({ display, setDisplay }) {
  return (
    <div className={style.compareBtnContainer} title="Compare">
      <div
        className={`${style.compareBtn} ${
          display ? style.compareBtnActive : ''
        }`}
        onClick={() => setDisplay && setDisplay(!display)}
      >
        {display ? 'Close' : 'Compare'}
      </div>
    </div>
  );
}

// eslint-disable-next-line no-unused-vars
function OccasionAdditionalFilterBtns({ setData, onSubmit }) {
  function onMiniMealsPastaClick() {
    if (!setData) return;
    setData({
      'Dishes & Meals (Grouped)': ['Pasta dishes'],
      'Preparation effort level': [
        'It was mostly prepared, I just needed to heat it up',
        'It was mostly prepared, I just needed to add hot water',
        'It was mostly prepared, I just needed to put it together / assemble it',
      ],
    });
    onSubmit && onSubmit();
  }

  function onMiniMealsNoodleSoupClick() {
    if (!setData) return;
    setData({
      'Dishes & Meals (Grouped)': ['Soups'],
      'Preparation effort level': [
        'It was mostly prepared, I just needed to add hot water',
      ],
    });
    onSubmit && onSubmit();
  }

  return (
    <div className={style.filterBtnContainer}>
      <Button onClick={onMiniMealsPastaClick}>Pasta dishes</Button>
      <Button onClick={onMiniMealsNoodleSoupClick}>Noodle Soups</Button>
    </div>
  );
}

export function OccasionFilterHeader({ children, onSubmit, onClear }) {
  return (
    <div className={style.filterHeader}>
      <h3>{children}</h3>
      <Button onClick={onClear}>Clear filter</Button>
      <Button onClick={onSubmit}>Apply filter</Button>
    </div>
  );
}
