import request from './fetch';

export function fileUpload (data) {
  return request({
    url: '/v1/azure_file',
    method: 'post',
    headers:{
      'Content-Type': 'multipart/form-data'
    },
    data
  });
}

export function getWrokShopList (params) {
  return request({
    url: '/v1/gotfl-weena/workshop/list',
    method: 'get',
    params
  });
}

export function getWrokShopDetail (id) {
  return request({
    url: '/v1/gotfl-weena/workshop',
    method: 'get',
    params: {
      id
    }
  });
}

// delete workshop
export function deleteWorkshop (id) {
  return request({
    url: '/v1/gotfl-weena/workshop',
    method: 'delete',
    data: {
      id
    }
  });
}

export function postWorkshop (data) {
  return request({
    url: '/v1/gotfl-weena/workshop',
    method: 'post',
    data
  });
}

export function putWorkshop (data) {
  return request({
    url: '/v1/gotfl-weena/workshop',
    method: 'put',
    data
  });
}

export function getResourceByShopId (id) {
  return request({
    url: '/v1/gotfl-weena/docManage/all',
    method: 'get',
    params: {
      id
    }
  });
}

//
export function getResourceDetail (id) {
  return request({
    url: '/v1/gotfl-weena/docManage',
    method: 'get',
    params: {
      id
    }
  });
}

// delete workshop
export function deleteResource (id) {
  return request({
    url: '/v1/gotfl-weena/docManage',
    method: 'delete',
    data: {
      id
    }
  });
}

export function postResource (data) {
  return request({
    url: '/v1/gotfl-weena/docManage',
    method: 'post',
    data
  });
}

export function putResource (data) {
  return request({
    url: '/v1/gotfl-weena/docManage',
    method: 'put',
    data
  });
}