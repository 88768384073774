import { Tabs } from 'antd';
import React, { useEffect, useState } from 'react';
import { downloadWb } from '../../../apis/file/index.js';
import BarColChart from '../charts/bar-col.jsx';
import BarChart from '../charts/bar.jsx';
import LineChart from '../charts/line.jsx';
import StackChart from '../charts/stack-col.jsx';
import StackRowChart from '../charts/stack.jsx';
import Divider from '../components/divider.jsx';
import style from './tab.module.scss';

function FormatChoice({ filter, compare, hasCompare, exportCount, isActive }) {
  const saveDataFns = [];
  function appendSaveDataFn(fn) {
    saveDataFns.push(fn);
  }

  useEffect(() => {
    if (!isActive) return;
    downloadWb(saveDataFns, 'xlsx', 'Format Choice');
  }, [exportCount]);

  const [userSelectChart, setUserSelectChart] = useState('');
  const [userSelectChartKey, setUserSelectChartKey] = useState('');
  const [userSelectChartValue, setUserSelectChartValue] = useState('');
  function onUserSelectChart(name, key, value) {
    setUserSelectChart(name);
    setUserSelectChartKey(key);
    setUserSelectChartValue(value);
  }

  return (
    <div className={style.chartContainer}>
      <BarChart
        name="format-choice"
        tabName="category-deep-dive"
        tab2Name="format-choice"
        title="Format Choice"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Format Choice"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
    </div>
  );
}

function PrefferedFlavour({
  filter,
  compare,
  hasCompare,
  exportCount,
  isActive,
}) {
  const saveDataFns = [];
  function appendSaveDataFn(fn) {
    saveDataFns.push(fn);
  }

  useEffect(() => {
    if (!isActive) return;
    downloadWb(saveDataFns, 'xlsx', 'Preffered flavour');
  }, [exportCount]);

  const [userSelectChart, setUserSelectChart] = useState('');
  const [userSelectChartKey, setUserSelectChartKey] = useState('');
  const [userSelectChartValue, setUserSelectChartValue] = useState('');
  function onUserSelectChart(name, key, value) {
    setUserSelectChart(name);
    setUserSelectChartKey(key);
    setUserSelectChartValue(value);
  }

  return (
    <div className={style.chartContainer}>
      <BarChart
        name="preffered-flavour"
        tabName="category-deep-dive"
        tab2Name="preffered-flavour"
        title="Preffered flavour"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Preffered flavour"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
    </div>
  );
}

function MomentOfUseDuringPreparation({
  filter,
  compare,
  hasCompare,
  exportCount,
  isActive,
}) {
  const saveDataFns = [];
  function appendSaveDataFn(fn) {
    saveDataFns.push(fn);
  }

  useEffect(() => {
    if (!isActive) return;
    downloadWb(saveDataFns, 'xlsx', 'Moment of use during preparation');
  }, [exportCount]);

  const [userSelectChart, setUserSelectChart] = useState('');
  const [userSelectChartKey, setUserSelectChartKey] = useState('');
  const [userSelectChartValue, setUserSelectChartValue] = useState('');
  function onUserSelectChart(name, key, value) {
    setUserSelectChart(name);
    setUserSelectChartKey(key);
    setUserSelectChartValue(value);
  }

  return (
    <div className={style.chartContainer}>
      <BarChart
        name="moment-of-use-during-preparation"
        tabName="category-deep-dive"
        tab2Name="moment-of-use-during-preparation"
        title="Moment of use during preparation"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Moment of use during preparation"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
    </div>
  );
}

function FrequencyOfUseOfCategory({
  filter,
  compare,
  hasCompare,
  exportCount,
  isActive,
}) {
  const saveDataFns = [];
  function appendSaveDataFn(fn) {
    saveDataFns.push(fn);
  }

  useEffect(() => {
    if (!isActive) return;
    downloadWb(saveDataFns, 'xlsx', 'Frequency of use of category');
  }, [exportCount]);

  const [userSelectChart, setUserSelectChart] = useState('');
  const [userSelectChartKey, setUserSelectChartKey] = useState('');
  const [userSelectChartValue, setUserSelectChartValue] = useState('');
  function onUserSelectChart(name, key, value) {
    setUserSelectChart(name);
    setUserSelectChartKey(key);
    setUserSelectChartValue(value);
  }

  return (
    <div className={style.chartContainer}>
      <BarChart
        name="frequency-of-use-of-category"
        tabName="category-deep-dive"
        tab2Name="frequency-of-use-of-category"
        title="Frequency of use of category"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Frequency of use of category"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
    </div>
  );
}

function Mayonnaise({ filter, compare, hasCompare, exportCount, isActive }) {
  const saveDataFns = [];
  function appendSaveDataFn(fn) {
    saveDataFns.push(fn);
  }

  useEffect(() => {
    if (!isActive) return;
    downloadWb(saveDataFns, 'xlsx', 'Mayonnaise');
  }, [exportCount]);

  const [userSelectChart, setUserSelectChart] = useState('');
  const [userSelectChartKey, setUserSelectChartKey] = useState('');
  const [userSelectChartValue, setUserSelectChartValue] = useState('');
  function onUserSelectChart(name, key, value) {
    setUserSelectChart(name);
    setUserSelectChartKey(key);
    setUserSelectChartValue(value);
  }

  return (
    <div className={style.chartContainer}>
      <Divider title="Occasions and Need States" />
      <BarChart
        name="mayonnaise-needstates"
        tabName="category-deep-dive"
        tab2Name="mayonnaise"
        title="Needstate"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        appendSaveDataFn={appendSaveDataFn}
        hiddenItems={['Noise', 'Keep', 'Delete']}
        chartKey="Needstate"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <BarChart
        name="mayonnaise-specific-needstates"
        tabName="category-deep-dive"
        tab2Name="mayonnaise"
        title="Specific Needstates"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Specific Needstates"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <BarChart
        name="mayonnaise-occasion"
        tabName="category-deep-dive"
        tab2Name="mayonnaise"
        title="Occasion"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Occasion"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <Divider title="Dishes, Categories, Ingredients" />
      <BarChart
        name="mayonnaise-dishes-meals-grouped"
        tabName="category-deep-dive"
        tab2Name="mayonnaise"
        title="Dishes & Meals (Grouped)"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Dishes & Meals (Grouped)"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <BarChart
        name="mayonnaise-dishes-meals-specific"
        tabName="category-deep-dive"
        tab2Name="mayonnaise"
        title="Dishes & Meals (Specific)"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        hiddenItems={['7', '6', '9', '10']}
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Dishes & Meals (Specific)"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <BarChart
        name="mayonnaise-products-ingredients-grouped-l2"
        tabName="category-deep-dive"
        tab2Name="mayonnaise"
        title="Products / Ingredients (Grouped, L2)"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Products / Ingredients (Grouped, L2)"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <BarChart
        name="mayonnaise-products-ingredients-specific"
        tabName="category-deep-dive"
        tab2Name="mayonnaise"
        title="Products / Ingredients (Specific)"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Products / Ingredients (Specific)"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <Divider title="Who" />
      <StackChart
        name="mayonnaise-age"
        tabName="category-deep-dive"
        tab2Name="mayonnaise"
        title="Age"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="2"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="User Age Group"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <StackChart
        name="mayonnaise-gender"
        tabName="category-deep-dive"
        tab2Name="mayonnaise"
        title="Gender"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="2"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="User Gender"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <StackChart
        name="mayonnaise-sel"
        tabName="category-deep-dive"
        tab2Name="mayonnaise"
        title="SEL"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="2"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="User SEL Bands"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <StackChart
        name="mayonnaise-income-brackets"
        tabName="category-deep-dive"
        tab2Name="mayonnaise"
        title="Income brackets"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="2"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Income Brackets"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <BarColChart
        name="mayonnaise-household-composition"
        tabName="category-deep-dive"
        tab2Name="mayonnaise"
        title="Houshold composition"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        desc={true}
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Houshold Composition"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <StackChart
        name="mayonnaise-working-vs-not-working"
        tabName="category-deep-dive"
        tab2Name="mayonnaise"
        title="Working vs. not working"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="2"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Working Vs. Not Working"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <BarChart
        name="mayonnaise-employment-status"
        tabName="category-deep-dive"
        tab2Name="mayonnaise"
        title="Employment status"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="2"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="User Employment Status"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <BarChart
        name="mayonnaise-ethnicity"
        tabName="category-deep-dive"
        tab2Name="mayonnaise"
        title="Ethnicity"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Ethnicity"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <BarChart
        name="mayonnaise-region"
        tabName="category-deep-dive"
        tab2Name="mayonnaise"
        title="Region"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Region"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <Divider title="When, where, with who" />
      <BarChart
        name="mayonnaise-meal-type"
        tabName="category-deep-dive"
        tab2Name="mayonnaise"
        title="Meal type"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="6"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Meal Type"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <LineChart
        name="mayonnaise-time-of-day"
        tabName="category-deep-dive"
        tab2Name="mayonnaise"
        title="Time of day"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        sortList={[
          '12am-03am',
          '03am-06am',
          '06am-09am',
          '09am-12pm',
          '12pm-03pm',
          '03pm-06pm',
          '06pm-09pm',
          '09pm-12am',
        ]}
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Time Of Day"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <BarChart
        name="mayonnaise-activities"
        tabName="category-deep-dive"
        tab2Name="mayonnaise"
        title="Activities"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Activities"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <BarChart
        name="mayonnaise-when-did-you-decide-what-to-eat"
        tabName="category-deep-dive"
        tab2Name="mayonnaise"
        title="When did you decide what to eat"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Food Decision Time"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <BarChart
        name="mayonnaise-place-of-consumption"
        tabName="category-deep-dive"
        tab2Name="mayonnaise"
        title="Place of consumption"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Place Of Consumption"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <BarChart
        name="mayonnaise-who-are-you-with"
        tabName="category-deep-dive"
        tab2Name="mayonnaise"
        title="Who are you with"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Who With"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <BarChart
        name="mayonnaise-group-size"
        tabName="category-deep-dive"
        tab2Name="mayonnaise"
        title="Group size"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        sortList={['1', '2', '3', '4', '5+']}
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Group Size"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <BarChart
        name="mayonnaise-top-reasons-for-choices"
        tabName="category-deep-dive"
        tab2Name="mayonnaise"
        title="Top reasons for choice"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Top Reasons For Choice"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <BarChart
        name="mayonnaise-how-frequently-would-you-say-you-have-this-food"
        tabName="category-deep-dive"
        tab2Name="mayonnaise"
        title="How frequently would you say you have this food?"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Frequency Of Preparing/Cooking This Food"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <Divider title="What" />
      <BarChart
        name="mayonnaise-brand"
        tabName="category-deep-dive"
        tab2Name="mayonnaise"
        title="Brand"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Brand (Mayonnaise)"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <BarChart
        name="mayonnaise-type"
        tabName="category-deep-dive"
        tab2Name="mayonnaise"
        title="Type"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Type (Mayonnaise)"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <BarChart
        name="mayonnaise-pack-format"
        tabName="category-deep-dive"
        tab2Name="mayonnaise"
        title="Pack format"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Pack Format (Mayonnaise)"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <BarChart
        name="mayonnaise-are-you-having-the-mayonnaise-as-a"
        tabName="category-deep-dive"
        tab2Name="mayonnaise"
        title="Are you having the mayonnaise as a..."
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Top Categories"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <BarChart
        name="mayonnaise-additional-ingredients"
        tabName="category-deep-dive"
        tab2Name="mayonnaise"
        title="Additional Ingredients"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Additional Ingredients (Overall)"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <BarChart
        name="mayonnaise-did-you-combine-the-mayonnaise-with-any-other-ingredients"
        tabName="category-deep-dive"
        tab2Name="mayonnaise"
        title="Did you combine the mayonnaise with any other ingredients?"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Preparation Combination (Mayonnaise)"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <BarChart
        name="mayonnaise-what-did-you-combine-with-the-mayonnaise"
        tabName="category-deep-dive"
        tab2Name="mayonnaise"
        title="What did you combine with the mayonnaise?"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Preparation Combination (Mayonnaise)"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <Divider title="Why & Consideration Set" />
      <BarChart
        name="mayonnaise-main-reasons-that-you-chose-to-have-mayonnaise-with-in-your-food-today"
        tabName="category-deep-dive"
        tab2Name="mayonnaise"
        title="Main reasons that you chose to have mayonnaise with / in your food today"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Top Reason Of Category Choices"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <BarChart
        name="mayonnaise-what-else-if-anything-did-you-consider-having-with-in-your-food-instead-of-mayonnaise"
        tabName="category-deep-dive"
        tab2Name="mayonnaise"
        title="What else, if anything, did you consider having with / in your food INSTEAD OF mayonnaise?"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Products / Ingredients (Specific)"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
    </div>
  );
}

function Bouillon({ filter, compare, hasCompare, exportCount, isActive }) {
  const saveDataFns = [];
  function appendSaveDataFn(fn) {
    saveDataFns.push(fn);
  }

  useEffect(() => {
    if (!isActive) return;
    downloadWb(saveDataFns, 'xlsx', 'Bouillon');
  }, [exportCount]);

  const [userSelectChart, setUserSelectChart] = useState('');
  const [userSelectChartKey, setUserSelectChartKey] = useState('');
  const [userSelectChartValue, setUserSelectChartValue] = useState('');
  function onUserSelectChart(name, key, value) {
    setUserSelectChart(name);
    setUserSelectChartKey(key);
    setUserSelectChartValue(value);
  }

  return (
    <div className={style.chartContainer}>
      <Divider title="Occasions and Need States" />
      <BarChart
        name="bouillon-needstates"
        tabName="category-deep-dive"
        tab2Name="bouillon"
        title="Needstate"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        appendSaveDataFn={appendSaveDataFn}
        hiddenItems={['Noise', 'Keep', 'Delete']}
        chartKey="Needstate"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <BarChart
        name="bouillon-specific-needstates"
        tabName="category-deep-dive"
        tab2Name="bouillon"
        title="Specific Needstates"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Specific Needstates"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <BarChart
        name="bouillon-occasion"
        tabName="category-deep-dive"
        tab2Name="bouillon"
        title="Occasion"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Occasion"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <Divider title="Dishes, Categories, Ingredients" />
      <BarChart
        name="bouillon-dishes-meals-grouped"
        tabName="category-deep-dive"
        tab2Name="bouillon"
        title="Dishes & Meals (Grouped)"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Dishes & Meals (Grouped)"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <BarChart
        name="bouillon-dishes-meals-specific"
        tabName="category-deep-dive"
        tab2Name="bouillon"
        title="Dishes & Meals (Specific)"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        hiddenItems={['7', '6', '9', '10']}
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Dishes & Meals (Specific)"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <BarChart
        name="bouillon-products-ingredients-grouped-l2"
        tabName="category-deep-dive"
        tab2Name="bouillon"
        title="Products / Ingredients (Grouped, L2)"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Products / Ingredients (Grouped, L2)"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <BarChart
        name="bouillon-products-ingredients-specific"
        tabName="category-deep-dive"
        tab2Name="bouillon"
        title="Products / Ingredients (Specific)"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Products / Ingredients (Specific)"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <Divider title="Who" />
      <StackChart
        name="bouillon-age"
        tabName="category-deep-dive"
        tab2Name="bouillon"
        title="Age"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="2"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="User Age Group"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <StackChart
        name="bouillon-gender"
        tabName="category-deep-dive"
        tab2Name="bouillon"
        title="Gender"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="2"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="User Gender"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <StackChart
        name="bouillon-sel"
        tabName="category-deep-dive"
        tab2Name="bouillon"
        title="SEL"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="2"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="User SEL Bands"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <StackChart
        name="bouillon-income-brackets"
        tabName="category-deep-dive"
        tab2Name="bouillon"
        title="Income brackets"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="2"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Income Brackets"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <BarColChart
        name="bouillon-household-composition"
        tabName="category-deep-dive"
        tab2Name="bouillon"
        title="Houshold composition"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        desc={true}
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Houshold Composition"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <StackChart
        name="bouillon-working-vs-not-working"
        tabName="category-deep-dive"
        tab2Name="bouillon"
        title="Working vs. not working"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="2"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Working Vs. Not Working"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <BarChart
        name="bouillon-employment-status"
        tabName="category-deep-dive"
        tab2Name="bouillon"
        title="Employment status"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="2"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="User Employment Status"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <BarChart
        name="bouillon-ethnicity"
        tabName="category-deep-dive"
        tab2Name="bouillon"
        title="Ethnicity"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Ethnicity"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <BarChart
        name="bouillon-region"
        tabName="category-deep-dive"
        tab2Name="bouillon"
        title="Region"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Region"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <Divider title="When, where, with who" />
      <BarChart
        name="bouillon-meal-type"
        tabName="category-deep-dive"
        tab2Name="bouillon"
        title="Meal type"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="6"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Meal Type"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <LineChart
        name="bouillon-time-of-day"
        tabName="category-deep-dive"
        tab2Name="bouillon"
        title="Time of day"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        sortList={[
          '12am-03am',
          '03am-06am',
          '06am-09am',
          '09am-12pm',
          '12pm-03pm',
          '03pm-06pm',
          '06pm-09pm',
          '09pm-12am',
        ]}
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Time Of Day"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <BarChart
        name="bouillon-activities"
        tabName="category-deep-dive"
        tab2Name="bouillon"
        title="Activities"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Activities"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <BarChart
        name="bouillon-when-did-you-decide-what-to-eat"
        tabName="category-deep-dive"
        tab2Name="bouillon"
        title="When did you decide what to eat"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Food Decision Time"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <BarChart
        name="bouillon-place-of-consumption"
        tabName="category-deep-dive"
        tab2Name="bouillon"
        title="Place of consumption"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Place Of Consumption"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <BarChart
        name="bouillon-who-are-you-with"
        tabName="category-deep-dive"
        tab2Name="bouillon"
        title="Who are you with"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Who With"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <BarChart
        name="bouillon-group-size"
        tabName="category-deep-dive"
        tab2Name="bouillon"
        title="Group size"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        sortList={['1', '2', '3', '4', '5+']}
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Group Size"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <BarChart
        name="bouillon-top-reasons-for-choices"
        tabName="category-deep-dive"
        tab2Name="bouillon"
        title="Top reasons for choice"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Top Reasons For Choice"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <BarChart
        name="bouillon-how-frequently-would-you-say-you-have-this-food"
        tabName="category-deep-dive"
        tab2Name="bouillon"
        title="How frequently would you say you have this food?"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Frequency Of Preparing/Cooking This Food"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <Divider title="What" />
      <BarChart
        name="bouillon-brand"
        tabName="category-deep-dive"
        tab2Name="bouillon"
        title="Brand"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Brand (Boullion / Stock)"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <BarChart
        name="bouillon-type"
        tabName="category-deep-dive"
        tab2Name="bouillon"
        title="Type"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Type (Bouillon / Stock)"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <BarChart
        name="bouillon-flavour"
        tabName="category-deep-dive"
        tab2Name="bouillon"
        title="Flavour"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Flavour (Boullion / Stock)"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <BarChart
        name="bouillon-how-did-you-use-the-bouillon-stock-cube-today"
        tabName="category-deep-dive"
        tab2Name="bouillon"
        title="How did you use the bouillon / stock cube today?"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Preparation Method (Bouillon / Stock)"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <BarChart
        name="bouillon-did-you-add-any-of-the-following-with-it"
        tabName="category-deep-dive"
        tab2Name="bouillon"
        title="Did you add any of the following with it?"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Extra Ingredients Added"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <BarChart
        name="bouillon-did-you-also-add-salt-to-the-dish-you-cooked-using-bouillon-stock"
        tabName="category-deep-dive"
        tab2Name="bouillon"
        title="Did you also add salt to the dish you cooked using bouillon / stock?"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Additional Ingredients (Salt In Bouillon / Stock)"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <BarChart
        name="bouillon-during-which-stage-of-the-cooking-process-did-you-use-bouillon-stock"
        tabName="category-deep-dive"
        tab2Name="bouillon"
        title="During which stage of the cooking process did you use bouillon / stock?"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Cooking / Eating Stage Product Used"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <Divider title="Why & Consideration Set" />
      <BarChart
        name="bouillon-main-reasons-that-you-chose-bouillon-stock-in-your-food-today"
        tabName="category-deep-dive"
        tab2Name="bouillon"
        title="Main reasons that you chose bouillon / stock in your food today"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Top Reason Of Category Choices"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <BarChart
        name="bouillon-what-other-ingredients-if-any-did-you-consider-using-instead-of-bouillon-stock-in-your-food-today-l6"
        tabName="category-deep-dive"
        tab2Name="bouillon"
        title="What other ingredients, if any, did you consider using instead of bouillon / stock in your food today? (L6)"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Consideration Set (Ingredients, L6)"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
    </div>
  );
}

function SweetSoySauseBoost({
  filter,
  compare,
  hasCompare,
  exportCount,
  isActive,
}) {
  const saveDataFns = [];
  function appendSaveDataFn(fn) {
    saveDataFns.push(fn);
  }

  useEffect(() => {
    if (!isActive) return;
    downloadWb(saveDataFns, 'xlsx', 'Sweet Soy Sauce Boost');
  }, [exportCount]);

  const [userSelectChart, setUserSelectChart] = useState('');
  const [userSelectChartKey, setUserSelectChartKey] = useState('');
  const [userSelectChartValue, setUserSelectChartValue] = useState('');
  function onUserSelectChart(name, key, value) {
    setUserSelectChart(name);
    setUserSelectChartKey(key);
    setUserSelectChartValue(value);
  }

  return (
    <div className={style.chartContainer}>
      <Divider title="Occasions and Need States" />
      <BarChart
        name="sweet-soy-sauce-boost-needstates"
        tabName="category-deep-dive"
        tab2Name="sweet-soy-sauce-boost"
        title="Needstate"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        appendSaveDataFn={appendSaveDataFn}
        hiddenItems={['Noise', 'Keep', 'Delete']}
        chartKey="Needstate"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <BarChart
        name="sweet-soy-sauce-boost-specific-needstates"
        tabName="category-deep-dive"
        tab2Name="sweet-soy-sauce-boost"
        title="Specific Needstates"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Specific Needstates"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <BarChart
        name="sweet-soy-sauce-boost-occasion"
        tabName="category-deep-dive"
        tab2Name="sweet-soy-sauce-boost"
        title="Occasion"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Occasion"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <Divider title="Dishes, Categories, Ingredients" />
      <BarChart
        name="sweet-soy-sauce-boost-dishes-meals-grouped"
        tabName="category-deep-dive"
        tab2Name="sweet-soy-sauce-boost"
        title="Dishes & Meals (Grouped)"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Dishes & Meals (Grouped)"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <BarChart
        name="sweet-soy-sauce-boost-dishes-meals-specific"
        tabName="category-deep-dive"
        tab2Name="sweet-soy-sauce-boost"
        title="Dishes & Meals (Specific)"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        appendSaveDataFn={appendSaveDataFn}
        hiddenItems={['7', '6', '9', '10']}
        chartKey="Dishes & Meals (Specific)"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <BarChart
        name="sweet-soy-sauce-boost-products-ingredients-grouped-l2"
        tabName="category-deep-dive"
        tab2Name="sweet-soy-sauce-boost"
        title="Products / Ingredients (Grouped, L2)"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Products / Ingredients (Grouped, L2)"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <BarChart
        name="sweet-soy-sauce-boost-products-ingredients-specific"
        tabName="category-deep-dive"
        tab2Name="sweet-soy-sauce-boost"
        title="Products / Ingredients (Specific)"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Products / Ingredients (Specific)"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <Divider title="Who" />
      <StackChart
        name="sweet-soy-sauce-boost-age"
        tabName="category-deep-dive"
        tab2Name="sweet-soy-sauce-boost"
        title="Age"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="2"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="User Age Group"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <StackChart
        name="sweet-soy-sauce-boost-gender"
        tabName="category-deep-dive"
        tab2Name="sweet-soy-sauce-boost"
        title="Gender"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="2"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="User Gender"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <StackChart
        name="sweet-soy-sauce-boost-sel"
        tabName="category-deep-dive"
        tab2Name="sweet-soy-sauce-boost"
        title="SEL"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="2"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="User SEL Bands"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <StackChart
        name="sweet-soy-sauce-boost-income-brackets"
        tabName="category-deep-dive"
        tab2Name="sweet-soy-sauce-boost"
        title="Income brackets"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="2"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Income Brackets"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <BarColChart
        name="sweet-soy-sauce-boost-household-composition"
        tabName="category-deep-dive"
        tab2Name="sweet-soy-sauce-boost"
        title="Houshold composition"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        desc={true}
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Houshold Composition"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <StackChart
        name="sweet-soy-sauce-boost-working-vs-not-working"
        tabName="category-deep-dive"
        tab2Name="sweet-soy-sauce-boost"
        title="Working vs. not working"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="2"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Working Vs. Not Working"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <BarChart
        name="sweet-soy-sauce-boost-employment-status"
        tabName="category-deep-dive"
        tab2Name="sweet-soy-sauce-boost"
        title="Employment status"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="2"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="User Employment Status"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <BarChart
        name="sweet-soy-sauce-boost-ethnicity"
        tabName="category-deep-dive"
        tab2Name="sweet-soy-sauce-boost"
        title="Ethnicity"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Ethnicity"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <BarChart
        name="sweet-soy-sauce-boost-region"
        tabName="category-deep-dive"
        tab2Name="sweet-soy-sauce-boost"
        title="Region"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Region"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <Divider title="When, where, with who" />
      <BarChart
        name="sweet-soy-sauce-boost-meal-type"
        tabName="category-deep-dive"
        tab2Name="sweet-soy-sauce-boost"
        title="Meal type"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="6"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Meal Type"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <LineChart
        name="sweet-soy-sauce-boost-time-of-day"
        tabName="category-deep-dive"
        tab2Name="sweet-soy-sauce-boost"
        title="Time of day"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        sortList={[
          '12am-03am',
          '03am-06am',
          '06am-09am',
          '09am-12pm',
          '12pm-03pm',
          '03pm-06pm',
          '06pm-09pm',
          '09pm-12am',
        ]}
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Time Of Day"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <BarChart
        name="sweet-soy-sauce-boost-activities"
        tabName="category-deep-dive"
        tab2Name="sweet-soy-sauce-boost"
        title="Activities"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Activities"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <BarChart
        name="sweet-soy-sauce-boost-when-did-you-decide-what-to-eat"
        tabName="category-deep-dive"
        tab2Name="sweet-soy-sauce-boost"
        title="When did you decide what to eat"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Food Decision Time"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <BarChart
        name="sweet-soy-sauce-boost-place-of-consumption"
        tabName="category-deep-dive"
        tab2Name="sweet-soy-sauce-boost"
        title="Place of consumption"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Place Of Consumption"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <BarChart
        name="sweet-soy-sauce-boost-who-are-you-with"
        tabName="category-deep-dive"
        tab2Name="sweet-soy-sauce-boost"
        title="Who are you with"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Who With"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <BarChart
        name="sweet-soy-sauce-boost-group-size"
        tabName="category-deep-dive"
        tab2Name="sweet-soy-sauce-boost"
        title="Group size"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        sortList={['1', '2', '3', '4', '5+']}
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Group Size"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <BarChart
        name="sweet-soy-sauce-boost-top-reasons-for-choices"
        tabName="category-deep-dive"
        tab2Name="sweet-soy-sauce-boost"
        title="Top reasons for choice"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Top Reasons For Choice"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <BarChart
        name="sweet-soy-sauce-boost-how-frequently-would-you-say-you-have-this-food"
        tabName="category-deep-dive"
        tab2Name="sweet-soy-sauce-boost"
        title="How frequently would you say you have this food?"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Frequency Of Preparing/Cooking This Food"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <Divider title="What" />
      <BarChart
        name="sweet-soy-sauce-boost-brand"
        tabName="category-deep-dive"
        tab2Name="sweet-soy-sauce-boost"
        title="Brand"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Brand (Sweet Soy Sauce)"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <BarChart
        name="sweet-soy-sauce-boost-type"
        tabName="category-deep-dive"
        tab2Name="sweet-soy-sauce-boost"
        title="Type"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Type (Sweet Soy Sauce)"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <BarChart
        name="sweet-soy-sauce-boost-pack-format"
        tabName="category-deep-dive"
        tab2Name="sweet-soy-sauce-boost"
        title="Pack format"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Pack Format (Sweet Soy Kecap Manis)"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <BarChart
        name="sweet-soy-sauce-boost-how-did-you-use-the-sweet-soy-sauce-kecap-manis"
        tabName="category-deep-dive"
        tab2Name="sweet-soy-sauce-boost"
        title="How did you use the sweet soy sauce / kecap manis?"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Preparation Method (Sweet Soy Kecap Manis)"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <Divider title="Why & Consideration Set" />
      <BarChart
        name="sweet-soy-sauce-boost-main-reasons-that-you-chose-to-have-sweet-soy-sauce-kecap-manis-with-in-your-food-today"
        tabName="category-deep-dive"
        tab2Name="sweet-soy-sauce-boost"
        title="Main reasons that you chose to have sweet soy sauce / kecap manis with / in your food today"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Top Reason Of Category Choices"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <BarChart
        name="sweet-soy-sauce-boost-what-other-ingredients-if-any-did-you-consider-using-instead-of-sweet-soy-sauce-kecap-manis-in-your-food-today"
        tabName="category-deep-dive"
        tab2Name="sweet-soy-sauce-boost"
        title="What other ingredients, if any, did you consider using instead of sweet soy sauce / kecap manis in your food today?"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Consideration Set (Ingredients, L6)"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
    </div>
  );
}

function SidesBoost({ filter, compare, hasCompare, exportCount, isActive }) {
  const saveDataFns = [];
  function appendSaveDataFn(fn) {
    saveDataFns.push(fn);
  }

  useEffect(() => {
    if (!isActive) return;
    downloadWb(saveDataFns, 'xlsx', 'Sides Boost');
  }, [exportCount]);

  const [userSelectChart, setUserSelectChart] = useState('');
  const [userSelectChartKey, setUserSelectChartKey] = useState('');
  const [userSelectChartValue, setUserSelectChartValue] = useState('');
  function onUserSelectChart(name, key, value) {
    setUserSelectChart(name);
    setUserSelectChartKey(key);
    setUserSelectChartValue(value);
  }

  return (
    <div className={style.chartContainer}>
      <Divider title="Occasions and Need States" />
      <BarChart
        name="sides-boost-needstates"
        tabName="category-deep-dive"
        tab2Name="sides-boost"
        title="Needstate"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        appendSaveDataFn={appendSaveDataFn}
        hiddenItems={['Noise', 'Keep', 'Delete']}
        chartKey="Needstate"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <BarChart
        name="sides-boost-specific-needstates"
        tabName="category-deep-dive"
        tab2Name="sides-boost"
        title="Specific Needstates"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Specific Needstates"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <BarChart
        name="sides-boost-occasion"
        tabName="category-deep-dive"
        tab2Name="sides-boost"
        title="Occasion"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Occasion"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <Divider title="Dishes, Categories, Ingredients" />
      <BarChart
        name="sides-boost-dishes-meals-grouped"
        tabName="category-deep-dive"
        tab2Name="sides-boost"
        title="Dishes & Meals (Grouped)"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Dishes & Meals (Grouped)"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <BarChart
        name="sides-boost-dishes-meals-specific"
        tabName="category-deep-dive"
        tab2Name="sides-boost"
        title="Dishes & Meals (Specific)"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        appendSaveDataFn={appendSaveDataFn}
        hiddenItems={['7', '6', '9', '10']}
        chartKey="Dishes & Meals (Specific)"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <BarChart
        name="sides-boost-products-ingredients-grouped-l2"
        tabName="category-deep-dive"
        tab2Name="sides-boost"
        title="Products / Ingredients (Grouped, L2)"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Products / Ingredients (Grouped, L2)"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <BarChart
        name="sides-boost-products-ingredients-specific"
        tabName="category-deep-dive"
        tab2Name="sides-boost"
        title="Products / Ingredients (Specific)"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Products / Ingredients (Specific)"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <Divider title="Who" />
      <StackChart
        name="sides-boost-age"
        tabName="category-deep-dive"
        tab2Name="sides-boost"
        title="Age"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="2"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="User Age Group"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <StackChart
        name="sides-boost-gender"
        tabName="category-deep-dive"
        tab2Name="sides-boost"
        title="Gender"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="2"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="User Gender"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <StackChart
        name="sides-boost-sel"
        tabName="category-deep-dive"
        tab2Name="sides-boost"
        title="SEL"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="2"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="User SEL Bands"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <StackChart
        name="sides-boost-income-brackets"
        tabName="category-deep-dive"
        tab2Name="sides-boost"
        title="Income brackets"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="2"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Income Brackets"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <BarColChart
        name="sides-boost-household-composition"
        tabName="category-deep-dive"
        tab2Name="sides-boost"
        title="Houshold composition"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        desc={true}
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Houshold Composition"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <StackChart
        name="sides-boost-working-vs-not-working"
        tabName="category-deep-dive"
        tab2Name="sides-boost"
        title="Working vs. not working"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="2"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Working Vs. Not Working"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <BarChart
        name="sides-boost-employment-status"
        tabName="category-deep-dive"
        tab2Name="sides-boost"
        title="Employment status"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="2"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="User Employment Status"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <BarChart
        name="sides-boost-ethnicity"
        tabName="category-deep-dive"
        tab2Name="sides-boost"
        title="Ethnicity"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Ethnicity"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <BarChart
        name="sides-boost-region"
        tabName="category-deep-dive"
        tab2Name="sides-boost"
        title="Region"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Region"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <Divider title="When, where, with who" />
      <BarChart
        name="sides-boost-meal-type"
        tabName="category-deep-dive"
        tab2Name="sides-boost"
        title="Meal type"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="6"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Meal Type"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <LineChart
        name="sides-boost-time-of-day"
        tabName="category-deep-dive"
        tab2Name="sides-boost"
        title="Time of day"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        sortList={[
          '12am-03am',
          '03am-06am',
          '06am-09am',
          '09am-12pm',
          '12pm-03pm',
          '03pm-06pm',
          '06pm-09pm',
          '09pm-12am',
        ]}
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Time Of Day"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <BarChart
        name="sides-boost-activities"
        tabName="category-deep-dive"
        tab2Name="sides-boost"
        title="Activities"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Activities"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <BarChart
        name="sides-boost-when-did-you-decide-what-to-eat"
        tabName="category-deep-dive"
        tab2Name="sides-boost"
        title="When did you decide what to eat"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Food Decision Time"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <BarChart
        name="sides-boost-place-of-consumption"
        tabName="category-deep-dive"
        tab2Name="sides-boost"
        title="Place of consumption"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Place Of Consumption"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <BarChart
        name="sides-boost-who-are-you-with"
        tabName="category-deep-dive"
        tab2Name="sides-boost"
        title="Who are you with"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Who With"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <BarChart
        name="sides-boost-group-size"
        tabName="category-deep-dive"
        tab2Name="sides-boost"
        title="Group size"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        sortList={['1', '2', '3', '4', '5+']}
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Group Size"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <BarChart
        name="sides-boost-top-reasons-for-choices"
        tabName="category-deep-dive"
        tab2Name="sides-boost"
        title="Top reasons for choice"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Top Reasons For Choice"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <BarChart
        name="sides-boost-how-frequently-would-you-say-you-have-this-food"
        tabName="category-deep-dive"
        tab2Name="sides-boost"
        title="How frequently would you say you have this food?"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Frequency Of Preparing/Cooking This Food"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <Divider title="What" />
      <BarChart
        name="sides-boost-brand"
        tabName="category-deep-dive"
        tab2Name="sides-boost"
        title="Brand"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Brand (Rice / Pasta Sides)"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <BarChart
        name="sides-boost-how-would-you-describe-the-rice-pasta-you-are-eating"
        tabName="category-deep-dive"
        tab2Name="sides-boost"
        title="How would you describe the rice / pasta you are eating?"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Type (Rice / Pasta Sides)"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <BarChart
        name="sides-boost-what-flavor-is-the-rice-pasta-sides-that-you-are-having"
        tabName="category-deep-dive"
        tab2Name="sides-boost"
        title="What flavor is the rice / pasta sides that you are having?"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Flavour (Rice / Pasta Sides)"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <BarChart
        name="sides-boost-how-did-you-prepare-the-rice-pasta-sides-you-are-having"
        tabName="category-deep-dive"
        tab2Name="sides-boost"
        title="How did you prepare the rice / pasta sides you are having?"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Preparation Method (Rice / Pasta Sides)"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <BarChart
        name="sides-boost-what-if-anything-extra-did-you-add-to-the-dish"
        tabName="category-deep-dive"
        tab2Name="sides-boost"
        title="What, if anything extra, did you add to the dish?"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Additional Ingredients (Cup Rice)"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <Divider title="Why & Consideration Set" />
      <BarChart
        name="sides-boost-top-reasons-for-choice"
        tabName="category-deep-dive"
        tab2Name="sides-boost"
        title="Top reasons for choice"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Top Reasons For Choice"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <StackRowChart
        name="sides-boost-was-anything-else-considered"
        tabName="category-deep-dive"
        tab2Name="sides-boost"
        title="Was anything else considered?"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Consideration (Y/N)"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <BarChart
        name="sides-boost-what-other-food-or-drink-did-you-consider-having-instead-l2"
        tabName="category-deep-dive"
        tab2Name="sides-boost"
        title="What other food or drink did you consider having instead? (L2)"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Consideration Set (Food / Drink, L2)"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <BarChart
        name="sides-boost-reasons-for-not-choosing-food-product-considered"
        tabName="category-deep-dive"
        tab2Name="sides-boost"
        title="Reasons for not choosing food / product considered"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Reasons For Not Choosing Food / Product Considered"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
    </div>
  );
}

function MiniMealsBoost({
  filter,
  compare,
  hasCompare,
  exportCount,
  isActive,
}) {
  const saveDataFns = [];
  function appendSaveDataFn(fn) {
    saveDataFns.push(fn);
  }

  useEffect(() => {
    if (!isActive) return;
    downloadWb(saveDataFns, 'xlsx', 'Mini Meals Boost');
  }, [exportCount]);

  const [userSelectChart, setUserSelectChart] = useState('');
  const [userSelectChartKey, setUserSelectChartKey] = useState('');
  const [userSelectChartValue, setUserSelectChartValue] = useState('');
  function onUserSelectChart(name, key, value) {
    setUserSelectChart(name);
    setUserSelectChartKey(key);
    setUserSelectChartValue(value);
  }

  return (
    <div className={style.chartContainer}>
      <Divider title="Occasions and Need States" />
      <BarChart
        name="mini-meals-boost-needstates"
        tabName="category-deep-dive"
        tab2Name="mini-meals-boost"
        title="Needstate"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        appendSaveDataFn={appendSaveDataFn}
        hiddenItems={['Noise', 'Keep', 'Delete']}
        chartKey="Needstate"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <BarChart
        name="mini-meals-boost-specific-needstates"
        tabName="category-deep-dive"
        tab2Name="mini-meals-boost"
        title="Specific Needstates"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Specific Needstates"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <BarChart
        name="mini-meals-boost-occasion"
        tabName="category-deep-dive"
        tab2Name="mini-meals-boost"
        title="Occasion"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Occasion"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <Divider title="Dishes, Categories, Ingredients" />
      <BarChart
        name="mini-meals-boost-dishes-meals-grouped"
        tabName="category-deep-dive"
        tab2Name="mini-meals-boost"
        title="Dishes & Meals (Grouped)"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Dishes & Meals (Grouped)"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <BarChart
        name="mini-meals-boost-dishes-meals-specific"
        tabName="category-deep-dive"
        tab2Name="mini-meals-boost"
        title="Dishes & Meals (Specific)"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        appendSaveDataFn={appendSaveDataFn}
        hiddenItems={['7', '6', '9', '10']}
        chartKey="Dishes & Meals (Specific)"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <BarChart
        name="mini-meals-boost-products-ingredients-grouped-l2"
        tabName="category-deep-dive"
        tab2Name="mini-meals-boost"
        title="Products / Ingredients (Grouped, L2)"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Products / Ingredients (Grouped, L2)"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <BarChart
        name="mini-meals-boost-products-ingredients-specific"
        tabName="category-deep-dive"
        tab2Name="mini-meals-boost"
        title="Products / Ingredients (Specific)"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Products / Ingredients (Specific)"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <Divider title="Who" />
      <StackChart
        name="mini-meals-boost-age"
        tabName="category-deep-dive"
        tab2Name="mini-meals-boost"
        title="Age"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="2"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="User Age Group"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <StackChart
        name="mini-meals-boost-gender"
        tabName="category-deep-dive"
        tab2Name="mini-meals-boost"
        title="Gender"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="2"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="User Gender"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <StackChart
        name="mini-meals-boost-sel"
        tabName="category-deep-dive"
        tab2Name="mini-meals-boost"
        title="SEL"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="2"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="User SEL Bands"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <StackChart
        name="mini-meals-boost-income-brackets"
        tabName="category-deep-dive"
        tab2Name="mini-meals-boost"
        title="Income brackets"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="2"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Income Brackets"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <BarColChart
        name="mini-meals-boost-household-composition"
        tabName="category-deep-dive"
        tab2Name="mini-meals-boost"
        title="Houshold composition"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        desc={true}
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Houshold Composition"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <StackChart
        name="mini-meals-boost-working-vs-not-working"
        tabName="category-deep-dive"
        tab2Name="mini-meals-boost"
        title="Working vs. not working"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="2"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Working Vs. Not Working"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <BarChart
        name="mini-meals-boost-employment-status"
        tabName="category-deep-dive"
        tab2Name="mini-meals-boost"
        title="Employment status"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="2"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="User Employment Status"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <BarChart
        name="mini-meals-boost-ethnicity"
        tabName="category-deep-dive"
        tab2Name="mini-meals-boost"
        title="Ethnicity"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Ethnicity"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <BarChart
        name="mini-meals-boost-region"
        tabName="category-deep-dive"
        tab2Name="mini-meals-boost"
        title="Region"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Region"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <Divider title="When, where, with who" />
      <BarChart
        name="mini-meals-boost-meal-type"
        tabName="category-deep-dive"
        tab2Name="mini-meals-boost"
        title="Meal type"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="6"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Meal Type"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <LineChart
        name="mini-meals-boost-time-of-day"
        tabName="category-deep-dive"
        tab2Name="mini-meals-boost"
        title="Time of day"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        sortList={[
          '12am-03am',
          '03am-06am',
          '06am-09am',
          '09am-12pm',
          '12pm-03pm',
          '03pm-06pm',
          '06pm-09pm',
          '09pm-12am',
        ]}
        appendSaveDataFn={appendSaveDataFn}
      />
      <BarChart
        name="mini-meals-boost-activities"
        tabName="category-deep-dive"
        tab2Name="mini-meals-boost"
        title="Activities"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Time Of Day"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <BarChart
        name="mini-meals-boost-when-did-you-decide-what-to-eat"
        tabName="category-deep-dive"
        tab2Name="mini-meals-boost"
        title="When did you decide what to eat"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Food Decision Time"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <BarChart
        name="mini-meals-boost-place-of-consumption"
        tabName="category-deep-dive"
        tab2Name="mini-meals-boost"
        title="Place of consumption"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Place Of Consumption"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <BarChart
        name="mini-meals-boost-who-are-you-with"
        tabName="category-deep-dive"
        tab2Name="mini-meals-boost"
        title="Who are you with"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Who With"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <BarChart
        name="mini-meals-boost-group-size"
        tabName="category-deep-dive"
        tab2Name="mini-meals-boost"
        title="Group size"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        sortList={['1', '2', '3', '4', '5+']}
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Group Size"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <BarChart
        name="mini-meals-boost-top-reasons-for-choices"
        tabName="category-deep-dive"
        tab2Name="mini-meals-boost"
        title="Top reasons for choice"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        appendSaveDataFn={appendSaveDataFn}
      />
      <BarChart
        name="mini-meals-boost-how-frequently-would-you-say-you-have-this-food"
        tabName="category-deep-dive"
        tab2Name="mini-meals-boost"
        title="How frequently would you say you have this food?"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Top Reasons For Choice"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <Divider title="What" />
      <BarChart
        name="mini-meals-boost-first-of-all-which-if-any-of-the-following-are-you-eating-today"
        tabName="category-deep-dive"
        tab2Name="mini-meals-boost"
        title="First of all, which, if any, of the following are you eating today?"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Product Type"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <BarChart
        name="mini-meals-boost-and-which-brand-is-the-rice-you-are-having"
        tabName="category-deep-dive"
        tab2Name="mini-meals-boost"
        title="And which brand is the rice you are having?"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Brands (All Boosts)"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <BarChart
        name="mini-meals-boost-which-flavour-is-the-cup-rice-you-are-having"
        tabName="category-deep-dive"
        tab2Name="mini-meals-boost"
        title="Which flavour is the cup rice you are having?"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Flavour (Boosts)"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <BarChart
        name="mini-meals-boost-what-if-anything-extra-did-you-add-to-the-dish"
        tabName="category-deep-dive"
        tab2Name="mini-meals-boost"
        title="What, if anything extra, did you add to the dish?"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Additional Ingredients (Overall)"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <BarChart
        name="mini-meals-boost-which-type-of-pasta-noodles-are-you-having"
        tabName="category-deep-dive"
        tab2Name="mini-meals-boost"
        title="Which type of pasta / noodles are you having?"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Type (Block Noodles)"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <BarChart
        name="mini-meals-boost-are-the-block-noodles-you-are-having"
        tabName="category-deep-dive"
        tab2Name="mini-meals-boost"
        title="Are the block noodles you are having..."
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Type Of Product (Main / Side / Snack)"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <BarChart
        name="mini-meals-boost-and-which-brand-is-it-block-noodles"
        tabName="category-deep-dive"
        tab2Name="mini-meals-boost"
        title="And which brand is it? (Block Noodles)"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Brand (Block Noodles)"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <BarChart
        name="mini-meals-boost-which-flavour-is-the-block-noodles-you-are-having-block-noodles"
        tabName="category-deep-dive"
        tab2Name="mini-meals-boost"
        title="Which flavour is the block noodles you are having? (Block Noodles)"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Flavour (Block Noodles)"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <BarChart
        name="mini-meals-boost-what-if-anything-extra-did-you-add-to-the-dish-block-noodles"
        tabName="category-deep-dive"
        tab2Name="mini-meals-boost"
        title="What, if anything extra, did you add to the dish? (Block Noodles)"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Additional Ingredients (Block Noodles)"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <BarChart
        name="mini-meals-boost-and-which-brand-is-it-cup-noodles-pasta"
        tabName="category-deep-dive"
        tab2Name="mini-meals-boost"
        title="And which brand is it? (Cup Noodles / pasta)"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Brand (Cup Pasta / Noodles)"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <BarChart
        name="mini-meals-boost-which-flavour-is-the-cup-pasta-noodles-you-are-having-cup-noodles-pasta"
        tabName="category-deep-dive"
        tab2Name="mini-meals-boost"
        title="Which flavour is the cup pasta / noodles you are having? (Cup Noodles / Pasta)"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Flavour (Cup Pasta / Noodles)"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <BarChart
        name="mini-meals-boost-what-if-anything-extra-did-you-add-to-the-dish-cup-noodles-pasta"
        tabName="category-deep-dive"
        tab2Name="mini-meals-boost"
        title="What, if anything extra, did you add to the dish? (Cup Noodles / Pasta)"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Additional Ingredients (Cup Pasta / Noodles)"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <Divider title="Why & Consideration Set" />
      <BarChart
        name="mini-meals-boost-what-other-food-or-drink-did-you-consider-having-instead-l1"
        tabName="category-deep-dive"
        tab2Name="mini-meals-boost"
        title="What other food or drink did you consider having instead? (L1)"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Consideration Set (Food / Drink, L1)"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <BarChart
        name="mini-meals-boost-what-other-food-or-drink-did-you-consider-having-instead-l2"
        tabName="category-deep-dive"
        tab2Name="mini-meals-boost"
        title="What other food or drink did you consider having instead? (L2)"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Consideration Set (Food / Drink, L2)"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <BarChart
        name="mini-meals-boost-reasons-for-not-choosing-food-product-considered"
        tabName="category-deep-dive"
        tab2Name="mini-meals-boost"
        title="Reasons for not choosing food / product considered"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Reasons For Not Choosing Food / Product Considered"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
    </div>
  );
}

function SoupBoost({ filter, compare, hasCompare, exportCount, isActive }) {
  const saveDataFns = [];
  function appendSaveDataFn(fn) {
    saveDataFns.push(fn);
  }

  useEffect(() => {
    if (!isActive) return;
    downloadWb(saveDataFns, 'xlsx', 'Mayonnaise');
  }, [exportCount]);

  const [userSelectChart, setUserSelectChart] = useState('');
  const [userSelectChartKey, setUserSelectChartKey] = useState('');
  const [userSelectChartValue, setUserSelectChartValue] = useState('');
  function onUserSelectChart(name, key, value) {
    setUserSelectChart(name);
    setUserSelectChartKey(key);
    setUserSelectChartValue(value);
  }

  return (
    <div className={style.chartContainer}>
      <Divider title="Occasions and Need States" />
      <BarChart
        name="soup-boost-needstates"
        tabName="category-deep-dive"
        tab2Name="soup-boost"
        title="Needstate"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        appendSaveDataFn={appendSaveDataFn}
        hiddenItems={['Noise', 'Keep', 'Delete']}
        chartKey="Needstate"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <BarChart
        name="soup-boost-specific-needstates"
        tabName="category-deep-dive"
        tab2Name="soup-boost"
        title="Specific Needstates"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Specific Needstates"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <BarChart
        name="soup-boost-occasion"
        tabName="category-deep-dive"
        tab2Name="soup-boost"
        title="Occasion"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Occasion"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <Divider title="Dishes, Categories, Ingredients" />
      <BarChart
        name="soup-boost-dishes-meals-grouped"
        tabName="category-deep-dive"
        tab2Name="soup-boost"
        title="Dishes & Meals (Grouped)"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Dishes & Meals (Grouped)"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <BarChart
        name="soup-boost-dishes-meals-specific"
        tabName="category-deep-dive"
        tab2Name="soup-boost"
        title="Dishes & Meals (Specific)"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        appendSaveDataFn={appendSaveDataFn}
        hiddenItems={['7', '6', '9', '10']}
        chartKey="Dishes & Meals (Specific)"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <BarChart
        name="soup-boost-products-ingredients-grouped-l2"
        tabName="category-deep-dive"
        tab2Name="soup-boost"
        title="Products / Ingredients (Grouped, L2)"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Products / Ingredients (Grouped, L2)"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <BarChart
        name="soup-boost-products-ingredients-specific"
        tabName="category-deep-dive"
        tab2Name="soup-boost"
        title="Products / Ingredients (Specific)"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Products / Ingredients (Specific)"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <Divider title="Who" />
      <StackChart
        name="soup-boost-age"
        tabName="category-deep-dive"
        tab2Name="soup-boost"
        title="Age"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="2"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="User Age Group"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <StackChart
        name="soup-boost-gender"
        tabName="category-deep-dive"
        tab2Name="soup-boost"
        title="Gender"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="2"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="User Gender"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <StackChart
        name="soup-boost-sel"
        tabName="category-deep-dive"
        tab2Name="soup-boost"
        title="SEL"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="2"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="User SEL Bands"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <StackChart
        name="soup-boost-income-brackets"
        tabName="category-deep-dive"
        tab2Name="soup-boost"
        title="Income brackets"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="2"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Income Brackets"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <BarColChart
        name="soup-boost-household-composition"
        tabName="category-deep-dive"
        tab2Name="soup-boost"
        title="Houshold composition"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        desc={true}
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Houshold Composition"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <StackChart
        name="soup-boost-working-vs-not-working"
        tabName="category-deep-dive"
        tab2Name="soup-boost"
        title="Working vs. not working"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="2"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Working Vs. Not Working"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <BarChart
        name="soup-boost-employment-status"
        tabName="category-deep-dive"
        tab2Name="soup-boost"
        title="Employment status"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="2"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="User Employment Status"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <BarChart
        name="soup-boost-ethnicity"
        tabName="category-deep-dive"
        tab2Name="soup-boost"
        title="Ethnicity"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Ethnicity"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <BarChart
        name="soup-boost-region"
        tabName="category-deep-dive"
        tab2Name="soup-boost"
        title="Region"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Region"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <Divider title="When, where, with who" />
      <BarChart
        name="soup-boost-meal-type"
        tabName="category-deep-dive"
        tab2Name="soup-boost"
        title="Meal type"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="6"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Meal Type"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <LineChart
        name="soup-boost-time-of-day"
        tabName="category-deep-dive"
        tab2Name="soup-boost"
        title="Time of day"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        sortList={[
          '12am-03am',
          '03am-06am',
          '06am-09am',
          '09am-12pm',
          '12pm-03pm',
          '03pm-06pm',
          '06pm-09pm',
          '09pm-12am',
        ]}
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Time Of Day"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <BarChart
        name="soup-boost-activities"
        tabName="category-deep-dive"
        tab2Name="soup-boost"
        title="Activities"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Activities"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <BarChart
        name="soup-boost-when-did-you-decide-what-to-eat"
        tabName="category-deep-dive"
        tab2Name="soup-boost"
        title="When did you decide what to eat"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Food Decision Time"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <BarChart
        name="soup-boost-place-of-consumption"
        tabName="category-deep-dive"
        tab2Name="soup-boost"
        title="Place of consumption"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Place Of Consumption"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <BarChart
        name="soup-boost-who-are-you-with"
        tabName="category-deep-dive"
        tab2Name="soup-boost"
        title="Who are you with"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Who With"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <BarChart
        name="soup-boost-group-size"
        tabName="category-deep-dive"
        tab2Name="soup-boost"
        title="Group size"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        sortList={['1', '2', '3', '4', '5+']}
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Group Size"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <BarChart
        name="soup-boost-top-reasons-for-choices"
        tabName="category-deep-dive"
        tab2Name="soup-boost"
        title="Top reasons for choice"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Top Reasons For Choice"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <BarChart
        name="soup-boost-how-frequently-would-you-say-you-have-this-food"
        tabName="category-deep-dive"
        tab2Name="soup-boost"
        title="How frequently would you say you have this food?"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Frequency Of Preparing/Cooking This Food"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <Divider title="What" />
      <BarChart
        name="soup-boost-brand"
        tabName="category-deep-dive"
        tab2Name="soup-boost"
        title="Brand"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Brand (Instant Soup)"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <BarChart
        name="soup-boost-which-best-describes-the-type-of-instant-soup"
        tabName="category-deep-dive"
        tab2Name="soup-boost"
        title="Which best describes the type of instant soup?"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Type (Instant Soup)"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <BarChart
        name="soup-boost-what-is-the-specific-flavour-of-the-instant-soup"
        tabName="category-deep-dive"
        tab2Name="soup-boost"
        title="What is the specific flavour of the instant soup?"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Flavour (Instant Soup)"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <BarChart
        name="soup-boost-did-you-add-anything-extra-to-your-instant-soup-today"
        tabName="category-deep-dive"
        tab2Name="soup-boost"
        title="Did you add anything extra to your instant soup today?"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Additional Ingredients (Instant Soup)"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <BarChart
        name="soup-boost-is-the-instant-soup-you-are-having"
        tabName="category-deep-dive"
        tab2Name="soup-boost"
        title="Is the instant soup you are having…"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Type Of Product (Main / Side / Snack)"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <Divider title="Why & Consideration Set" />
      <BarChart
        name="soup-boost-main-reasons-that-you-chose-to-have-instant-soup-today"
        tabName="category-deep-dive"
        tab2Name="soup-boost"
        title="Main reasons that you chose to have instant soup today"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Top Reason Of Category Choices"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <BarChart
        name="soup-boost-what-other-ingredients-if-any-did-you-consider-using-instead-of-instant-soup-in-your-food-today"
        tabName="category-deep-dive"
        tab2Name="soup-boost"
        title="What other ingredients, if any, did you consider using instead of instant soup in your food today?"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Consideration Set (Ingredients, L6)"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
    </div>
  );
}

function MealmakerBoost({
  filter,
  compare,
  hasCompare,
  exportCount,
  isActive,
}) {
  const saveDataFns = [];
  function appendSaveDataFn(fn) {
    saveDataFns.push(fn);
  }

  useEffect(() => {
    if (!isActive) return;
    downloadWb(saveDataFns, 'xlsx', 'Mayonnaise');
  }, [exportCount]);

  const [userSelectChart, setUserSelectChart] = useState('');
  const [userSelectChartKey, setUserSelectChartKey] = useState('');
  const [userSelectChartValue, setUserSelectChartValue] = useState('');
  function onUserSelectChart(name, key, value) {
    setUserSelectChart(name);
    setUserSelectChartKey(key);
    setUserSelectChartValue(value);
  }

  return (
    <div className={style.chartContainer}>
      <Divider title="Occasions and Need States" />
      <BarChart
        name="mealmaker-boost-needstates"
        tabName="category-deep-dive"
        tab2Name="mealmaker-boost"
        title="Needstate"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        appendSaveDataFn={appendSaveDataFn}
        hiddenItems={['Noise', 'Keep', 'Delete']}
        chartKey="Needstate"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <BarChart
        name="mealmaker-boost-specific-needstates"
        tabName="category-deep-dive"
        tab2Name="mealmaker-boost"
        title="Specific Needstates"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Specific Needstates"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <BarChart
        name="mealmaker-boost-occasion"
        tabName="category-deep-dive"
        tab2Name="mealmaker-boost"
        title="Occasion"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Occasion"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <Divider title="Dishes, Categories, Ingredients" />
      <BarChart
        name="mealmaker-boost-dishes-meals-grouped"
        tabName="category-deep-dive"
        tab2Name="mealmaker-boost"
        title="Dishes & Meals (Grouped)"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Dishes & Meals (Grouped)"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <BarChart
        name="mealmaker-boost-dishes-meals-specific"
        tabName="category-deep-dive"
        tab2Name="mealmaker-boost"
        title="Dishes & Meals (Specific)"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        appendSaveDataFn={appendSaveDataFn}
        hiddenItems={['7', '6', '9', '10']}
        chartKey="Dishes & Meals (Specific)"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <BarChart
        name="mealmaker-boost-products-ingredients-grouped-l2"
        tabName="category-deep-dive"
        tab2Name="mealmaker-boost"
        title="Products / Ingredients (Grouped, L2)"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Products / Ingredients (Grouped, L2)"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <BarChart
        name="mealmaker-boost-products-ingredients-specific"
        tabName="category-deep-dive"
        tab2Name="mealmaker-boost"
        title="Products / Ingredients (Specific)"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Products / Ingredients (Specific)"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <Divider title="Who" />
      <StackChart
        name="mealmaker-boost-age"
        tabName="category-deep-dive"
        tab2Name="mealmaker-boost"
        title="Age"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="2"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="User Age Group"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <StackChart
        name="mealmaker-boost-gender"
        tabName="category-deep-dive"
        tab2Name="mealmaker-boost"
        title="Gender"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="2"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="User Gender"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <StackChart
        name="mealmaker-boost-sel"
        tabName="category-deep-dive"
        tab2Name="mealmaker-boost"
        title="SEL"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="2"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="User SEL Bands"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <StackChart
        name="mealmaker-boost-income-brackets"
        tabName="category-deep-dive"
        tab2Name="mealmaker-boost"
        title="Income brackets"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="2"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Income Brackets"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <BarColChart
        name="mealmaker-boost-household-composition"
        tabName="category-deep-dive"
        tab2Name="mealmaker-boost"
        title="Houshold composition"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        desc={true}
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Houshold Composition"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <StackChart
        name="mealmaker-boost-working-vs-not-working"
        tabName="category-deep-dive"
        tab2Name="mealmaker-boost"
        title="Working vs. not working"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="2"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Working Vs. Not Working"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <BarChart
        name="mealmaker-boost-employment-status"
        tabName="category-deep-dive"
        tab2Name="mealmaker-boost"
        title="Employment status"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="2"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="User Employment Status"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <BarChart
        name="mealmaker-boost-ethnicity"
        tabName="category-deep-dive"
        tab2Name="mealmaker-boost"
        title="Ethnicity"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Ethnicity"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <BarChart
        name="mealmaker-boost-region"
        tabName="category-deep-dive"
        tab2Name="mealmaker-boost"
        title="Region"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Region"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <Divider title="When, where, with who" />
      <BarChart
        name="mealmaker-boost-meal-type"
        tabName="category-deep-dive"
        tab2Name="mealmaker-boost"
        title="Meal type"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="6"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Meal Type"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <LineChart
        name="mealmaker-boost-time-of-day"
        tabName="category-deep-dive"
        tab2Name="mealmaker-boost"
        title="Time of day"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        sortList={[
          '12am-03am',
          '03am-06am',
          '06am-09am',
          '09am-12pm',
          '12pm-03pm',
          '03pm-06pm',
          '06pm-09pm',
          '09pm-12am',
        ]}
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Time Of Day"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <BarChart
        name="mealmaker-boost-activities"
        tabName="category-deep-dive"
        tab2Name="mealmaker-boost"
        title="Activities"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Activities"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <BarChart
        name="mealmaker-boost-when-did-you-decide-what-to-eat"
        tabName="category-deep-dive"
        tab2Name="mealmaker-boost"
        title="When did you decide what to eat"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Food Decision Time"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <BarChart
        name="mealmaker-boost-place-of-consumption"
        tabName="category-deep-dive"
        tab2Name="mealmaker-boost"
        title="Place of consumption"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Place Of Consumption"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <BarChart
        name="mealmaker-boost-who-are-you-with"
        tabName="category-deep-dive"
        tab2Name="mealmaker-boost"
        title="Who are you with"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Who With"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <BarChart
        name="mealmaker-boost-group-size"
        tabName="category-deep-dive"
        tab2Name="mealmaker-boost"
        title="Group size"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        sortList={['1', '2', '3', '4', '5+']}
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Group Size"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <BarChart
        name="mealmaker-boost-top-reasons-for-choices"
        tabName="category-deep-dive"
        tab2Name="mealmaker-boost"
        title="Top reasons for choice"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Top Reasons For Choice"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <BarChart
        name="mealmaker-boost-how-frequently-would-you-say-you-have-this-food"
        tabName="category-deep-dive"
        tab2Name="mealmaker-boost"
        title="How frequently would you say you have this food?"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Frequency Of Preparing/Cooking This Food"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <Divider title="What" />
      <BarChart
        name="mealmaker-boost-what-brand-was-the-seasoning-spice-mix-meal-maker-specifically-for-a-dish"
        tabName="category-deep-dive"
        tab2Name="mealmaker-boost"
        title="What brand was the seasoning / spice mix / meal maker specifically for a dish?"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Brand (Meal Maker)"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <BarChart
        name="mealmaker-boost-seasoning-type"
        tabName="category-deep-dive"
        tab2Name="mealmaker-boost"
        title="Seasoning type"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Type (Meal Maker)"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <BarChart
        name="mealmaker-boost-which-flavour-did-you-use"
        tabName="category-deep-dive"
        tab2Name="mealmaker-boost"
        title="Which flavour did you use?"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Flavore (Meal Maker)"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <BarChart
        name="mealmaker-boost-during-which-stage-of-the-cooking-process-did-you-use-seasoning-spice-mix-mealmaker"
        tabName="category-deep-dive"
        tab2Name="mealmaker-boost"
        title="During which stage of the cooking process did you use seasoning / spice mix / mealmaker?"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Cooking / Eating Stage Product Used"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <BarChart
        name="mealmaker-boost-which-if-any-of-the-following-ingredients-have-you-added-used-when-preparing-your-food-today"
        tabName="category-deep-dive"
        tab2Name="mealmaker-boost"
        title="Which, if any, of the following ingredients have you added / used when preparing your food today?"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Extra Ingredients Added"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <Divider title="Why & Consideration Set" />
      <BarChart
        name="mealmaker-boost-main-reasons-that-you-chose-to-use-this-seasoning-spice-mix-mealmaker-today"
        tabName="category-deep-dive"
        tab2Name="mealmaker-boost"
        title="Main reasons that you chose to use this seasoning / spice mix / mealmaker today"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Top Reason Of Category Choices"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <BarChart
        name="mealmaker-boost-what-other-ingredients-if-any-did-you-consider-using-instead-of-seasoning-spice-mix-mealmaker-in-your-food-today"
        tabName="category-deep-dive"
        tab2Name="mealmaker-boost"
        title="What other ingredients, if any, did you consider using instead of seasoning / spice mix / mealmaker in your food today?"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Consideration Set (Ingredients, L6)"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
    </div>
  );
}

function CategoryDeepDive({
  filter,
  compare,
  hasCompare,
  exportCount,
  isActive,
}) {
  const startTab = new URL(window.location).searchParams.get('tab2');

  const [activeKey, setActiveKey] = useState(startTab ?? 'mayonnaise');

  return (
    <Tabs
      tabPosition="top"
      defaultActiveKey={startTab}
      onChange={setActiveKey}
      items={[
        {
          label: <span>{'Mayonnaise'}</span>,
          key: 'mayonnaise',
          children: (
            <Mayonnaise
              filter={filter}
              compare={compare}
              hasCompare={hasCompare}
              exportCount={exportCount}
              isActive={isActive && activeKey === 'mayonnaise'}
            />
          ),
        },
        {
          label: <span>{'Bouillon'}</span>,
          key: 'bouillon',
          children: (
            <Bouillon
              filter={filter}
              compare={compare}
              hasCompare={hasCompare}
              exportCount={exportCount}
              isActive={isActive && activeKey === 'bouillon'}
            />
          ),
        },
        {
          label: <span>{'Sweet Soy Sauce Boost'}</span>,
          key: 'sweet-soy-sauce-boost',
          children: (
            <SweetSoySauseBoost
              filter={filter}
              compare={compare}
              hasCompare={hasCompare}
              exportCount={exportCount}
              isActive={isActive && activeKey === 'sweet-soy-sauce-boost'}
            />
          ),
        },
        {
          label: <span>{'Sides Boost'}</span>,
          key: 'sides-boost',
          children: (
            <SidesBoost
              filter={filter}
              compare={compare}
              hasCompare={hasCompare}
              exportCount={exportCount}
              isActive={isActive && activeKey === 'sides-boost'}
            />
          ),
        },
        {
          label: <span>{'Mini-meals Boost'}</span>,
          key: 'mini-meals-boost',
          children: (
            <MiniMealsBoost
              filter={filter}
              compare={compare}
              hasCompare={hasCompare}
              exportCount={exportCount}
              isActive={isActive && activeKey === 'mini-meals-boost'}
            />
          ),
        },
        {
          label: <span>{'Soup Boost'}</span>,
          key: 'soup-boost',
          children: (
            <SoupBoost
              filter={filter}
              compare={compare}
              hasCompare={hasCompare}
              exportCount={exportCount}
              isActive={isActive && activeKey === 'soup-boost'}
            />
          ),
        },
        {
          label: <span>{'Mealmaker Boost'}</span>,
          key: 'mealmaker-boost',
          children: (
            <MealmakerBoost
              filter={filter}
              compare={compare}
              hasCompare={hasCompare}
              exportCount={exportCount}
              isActive={isActive && activeKey === 'mealmaker-boost'}
            />
          ),
        },
        {
          label: <span>{'Format Choice'}</span>,
          key: 'format-choice',
          children: (
            <FormatChoice
              filter={filter}
              compare={compare}
              hasCompare={hasCompare}
              exportCount={exportCount}
              isActive={isActive && activeKey === 'format-choice'}
            />
          ),
        },
        {
          label: <span>{'Preffered flavour'}</span>,
          key: 'preffered-flavour',
          children: (
            <PrefferedFlavour
              filter={filter}
              compare={compare}
              hasCompare={hasCompare}
              exportCount={exportCount}
              isActive={isActive && activeKey === 'preffered-flavour'}
            />
          ),
        },
        {
          label: <span>{'Moment of use during preparation'}</span>,
          key: 'moment-of-use-during-preparation',
          children: (
            <MomentOfUseDuringPreparation
              filter={filter}
              compare={compare}
              hasCompare={hasCompare}
              exportCount={exportCount}
              isActive={
                isActive && activeKey === 'moment-of-use-during-preparation'
              }
            />
          ),
        },
        {
          label: <span>{'Frequency of use of category'}</span>,
          key: 'frequency-of-use-of-category',
          children: (
            <FrequencyOfUseOfCategory
              filter={filter}
              compare={compare}
              hasCompare={hasCompare}
              exportCount={exportCount}
              isActive={
                isActive && activeKey === 'frequency-of-use-of-category'
              }
            />
          ),
        },
      ]}
    ></Tabs>
  );
}

export default CategoryDeepDive;
