import request from './fetch';
import requestCmi from './fetchCmi';

// login
export function chatAiAsk (data) {
  return request({
    url: '/ai/ask',
    method: 'post',
    data
  });
}

export function getRecommend () {
  return request({
    url: '/ai/recommend',
    method: 'get',
  });
}

export function getFileUrl (data) {
  return requestCmi({
    url: '/v1/azure_file',
    method: 'put',
    params: data
  });
}