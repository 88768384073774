// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.oKoc0xfIgJDwRqmCzjrf{width:30vw;padding:25px}.KOEgNF3b6BRWXk_myy2u{padding:2px 5px;border:solid 1px #444;border-radius:4px;background:#141414;white-space:nowrap;overflow:hidden;text-overflow:ellipsis;text-align:center;color:#00a32e;cursor:pointer;transition:all .2s}.KOEgNF3b6BRWXk_myy2u:hover{border-color:#00a32e}.KOEgNF3b6BRWXk_myy2u .N7Qws9z1WghFgxNb0tgA{margin-right:5px}.nxZHUmBnG5ehMhtRA9Mg{display:flex;justify-content:center}.vXXWFMkmtIdEjmDR4Izc{color:#00a32e}`, "",{"version":3,"sources":["webpack://./src/modules/package-ai-generation/views/base/upload.module.scss"],"names":[],"mappings":"AAAA,sBACE,UAAA,CACA,YAAA,CAGF,sBACE,eAAA,CACA,qBAAA,CACA,iBAAA,CACA,kBAAA,CACA,kBAAA,CACA,eAAA,CACA,sBAAA,CACA,iBAAA,CACA,aAAA,CACA,cAAA,CACA,kBAAA,CAEA,4BACE,oBAAA,CAGF,4CACE,gBAAA,CAIJ,sBACE,YAAA,CACA,sBAAA,CAGF,sBACE,aAAA","sourcesContent":[".box1 {\n  width: 30vw;\n  padding: 25px;\n}\n\n.box111 {\n  padding: 2px 5px;\n  border: solid 1px #444;\n  border-radius: 4px;\n  background: #141414;\n  white-space: nowrap;\n  overflow: hidden;\n  text-overflow: ellipsis;\n  text-align: center;\n  color: #00a32e;\n  cursor: pointer;\n  transition: all 0.2s;\n\n  &:hover {\n    border-color: #00a32e;\n  }\n\n  .icon {\n    margin-right: 5px;\n  }\n}\n\n.box12 {\n  display: flex;\n  justify-content: center;\n}\n\n.formLabel {\n  color: #00a32e;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"box1": `oKoc0xfIgJDwRqmCzjrf`,
	"box111": `KOEgNF3b6BRWXk_myy2u`,
	"icon": `N7Qws9z1WghFgxNb0tgA`,
	"box12": `nxZHUmBnG5ehMhtRA9Mg`,
	"formLabel": `vXXWFMkmtIdEjmDR4Izc`
};
export default ___CSS_LOADER_EXPORT___;
