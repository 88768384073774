import React from 'react';
import style from './title.module.scss';
import RightSvg from '../../assets/img/right-g.svg';

function PackageAiTitle({ className = '', children }) {
  return (
    <div className={`${style.title} ${className}`}>
      <img src={RightSvg} />
      <h2>{children}</h2>
    </div>
  );
}

export default PackageAiTitle;
