import { DeleteOutlined, HeartFilled, HeartOutlined } from '@ant-design/icons';
import { message } from 'antd';
import React, { useMemo, useState } from 'react';
import {
  deleteKnorrById,
  deleteKnorrFavorite,
  getKnorrFavorite,
  postKnorrFavorite,
} from '../apis/serve';
import DefaultPng from '../assets/img/default.png';
import KnorrDetail from './detail.jsx';
import style from './item.module.scss';

function KnorrItem({ data, isAdmin, userId, onDelete }) {
  const [detailVisible, setDetailVisible] = useState(false);
  const owner = useMemo(() => {
    return data.owner;
  }, [data]);

  const media = useMemo(() => {
    if (!data.media || !data.media.length) return DefaultPng;
    return data.media[0].url;
  }, [data]);
  const images = useMemo(() => {
    if (!data.media || !data.media.length) return [DefaultPng];
    return data.media.map((item) => item.url);
  }, [data]);
  const mediaType = useMemo(() => {
    if (!data.media || !data.media.length) return 'unknown';
    return data.media[0].type;
  }, [data]);
  const cover = useMemo(() => {
    if (mediaType !== 'video') return DefaultPng;
    return data.media[0].cover;
  }, [mediaType]);

  const [isFav, setFav] = useState(Boolean(data.favorite));
  const [favoriteCount, setFavoriteCount] = useState(data.fav_count);
  async function readFav() {
    try {
      if (!data.id) return;
      const { data: res } = await getKnorrFavorite(data.id);
      setFav(res.status);
      setFavoriteCount(res.count);
    } catch (err) {
      message.error('Read favorite error');
      console.error('read favorite error', err);
    }
  }
  async function onClickFav(ev) {
    try {
      ev.stopPropagation();
      if (!data.id) return;
      if (!isFav) {
        await postKnorrFavorite(data.id);
        void readFav();
      } else {
        await deleteKnorrFavorite(data.id);
        void readFav();
      }
    } catch (err) {
      message.error('Favorite error');
      console.error('favorite error', err);
    }
  }
  const favCountStr = useMemo(() => {
    if (favoriteCount > 10 ** 6) {
      return `${favoriteCount / 10 ** 6} M`;
    } else if (favoriteCount > 10 ** 3) {
      return `${favoriteCount / 10 ** 3} k`;
    } else {
      return `${favoriteCount}`;
    }
  }, [favoriteCount]);

  const isMine = useMemo(() => {
    return data.created_by_fk === userId;
  }, [data]);
  const allowDelete = useMemo(() => {
    return isMine ?? isAdmin ?? false;
  }, [isMine]);
  async function onDeleteClick(ev) {
    if (!allowDelete) return;
    ev.stopPropagation();
    try {
      await deleteKnorrById(data.id);
      if (onDelete) onDelete(data.id);
    } catch (err) {
      message.error('Delte knorr error');
      console.error('delete knorr error', err);
    }
  }

  const [coverError, setCoverError] = useState(false);

  return (
    <>
      <div className={style.itemBox} onClick={() => setDetailVisible(true)}>
        {mediaType == 'image' && <img src={media} alt={data.title} />}
        {mediaType == 'video' && (
          <img
            src={coverError ? DefaultPng : cover}
            alt={data.title}
            onError={() => setCoverError(true)}
          />
        )}{' '}
        <div className={style.itemInfo}>
          <div className={style.itemInfoLeft}>
            <h3 className={style.itemTitle}>{data.title}</h3>
            <div className={style.itemTime}>{owner}</div>
          </div>
          <div
            className={`${style.itemFav} ${isFav ? style.itemFavActive : ''}`}
            onClick={onClickFav}
          >
            {isFav ? <HeartFilled /> : <HeartOutlined />}
            <span>{favCountStr}</span>
          </div>
        </div>
        <div
          className={style.itemDelete}
          style={{ display: allowDelete ? '' : 'none' }}
          onClick={onDeleteClick}
        >
          <DeleteOutlined></DeleteOutlined>
        </div>
      </div>
      {detailVisible && (
        <KnorrDetail
          data={data}
          mediaType={mediaType}
          images={images}
          video={media}
          cover={cover}
          isFav={isFav}
          favCountStr={favCountStr}
          onFav={onClickFav}
          onClose={() => setDetailVisible(false)}
        ></KnorrDetail>
      )}
    </>
  );
}

export default KnorrItem;
