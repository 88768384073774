import React, { useEffect, useRef, useState } from 'react';
import { searchGreenIcon, closeIcon } from '../icons/icons.jsx';

function FilterModal({
  isOpen,
  onApply,
  data,
  selectedOptions,
}) {
  const [searchQuery, setSearchQuery] = useState('');
  const [searchClick, setSearchClick] = useState(false);
  const [innerOptions, setInnerOptions] = useState(selectedOptions);
  const [clearAll, setClearAll] = useState(selectedOptions.length === 0);

  const inputRef = useRef();

  const handleClearAll = (event) => {
    const { checked } = event.target;
    if(checked){
      setInnerOptions([]);
      setClearAll(true);
    } else {
      setClearAll(false);
      setInnerOptions(selectedOptions);
    }
  };

  const handleSearchInputChange = (e) => {
    const query = e.target.value;
    setSearchQuery(query || '');
  };

  const handleSelectionChange = (event) => {
    const { value, checked } = event.target;
    if (checked) {
      value in innerOptions ? null : setInnerOptions((prevSelectedOptions) => [
        ...prevSelectedOptions,
        value,
      ]);
      setClearAll(false);
    } 
    else {
      setInnerOptions((prevSelectedOptions) =>
        prevSelectedOptions.filter((option) => option !== value)
      );
    }
  };
  
  useEffect(() => {
    if (!isOpen) {
      setSearchQuery('');
    }
    if(innerOptions.length === 0){
      setClearAll(true);
    }
  }, [isOpen, innerOptions]);

  const filteredList = data.filter(function (item) {
    return item.name && item.name.toLowerCase().includes(searchQuery.toLowerCase());
  });

  const handleFilterApply = () => {
    onApply(innerOptions);
  };

  const focusInputField = () => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  const handleSearchClick = () => {
    setSearchClick(!searchClick);
    focusInputField();
  };

  return (
    <>
      <div className={`filter-modal ${isOpen ? 'open' : ''}`}>
        <div className="modal-content">
          <div className="search">
            <div className="search-input">
              <input
                className="input-search"
                placeholder="Search"
                value={searchQuery}
                onChange={handleSearchInputChange}
                ref={inputRef} // Add ref to input for focusing
              />
              {searchQuery === '' ? (
                <img
                  src={searchGreenIcon}
                  className="search-icon"
                  alt="search"
                  onClick={handleSearchClick} // Toggle search click state
                />
              ) : (
                <img
                  src={closeIcon}
                  className="search-icon"
                  alt="clear"
                  onClick={handleSearchClick} // Toggle search click state
                />
              )}
            </div>
          </div>

          <div className="search-label-wrapper">
            <label>
              <input 
                type="checkbox"
                checked={clearAll}
                onChange={handleClearAll}
              />
              CLEAR ALL
            </label>
           
            {filteredList.map((option, i) => (
              <label key={i}>
                <input
                  type="checkbox"
                  checked={innerOptions.includes(option.name)}
                  onChange={handleSelectionChange}
                  value={option.name}
                />
                {option.name}
              </label>
            ))}
          </div>
          <button
            className="btn-filter"
            onClick={handleFilterApply}
            onKeyDown={(event) => {
              if (event.key === 'Enter') {
                handleFilterApply(); // You missed invoking the function here
              }
            }}
            role="button"
          >
            Apply
          </button>
        </div>
      </div>
    </>
  );
}

export default FilterModal;
