import React from 'react';
import style from './index.module.scss';
import { LoadingOutlined } from '@ant-design/icons';

function BorderBox({
  className = '',
  children,
  loading = false,
  hasBox = true,
}) {
  return (
    <div className={`${style.box} ${className}`}>
      {hasBox && (
        <>
          <div
            className={`${style.boxCorner} ${style.boxCornerTop} ${style.boxCornerLeft}`}
          />
          <div
            className={`${style.boxCorner} ${style.boxCornerTop} ${style.boxCornerRight}`}
          />
          <div
            className={`${style.boxCorner} ${style.boxCornerBottom} ${style.boxCornerLeft}`}
          />
          <div
            className={`${style.boxCorner} ${style.boxCornerBottom} ${style.boxCornerRight}`}
          />
        </>
      )}
      <div className={style.boxInner}>
        {children}
        {loading && (
          <div className={style.boxLoading}>
            <div className={style.boxLoadingIcon}>
              <LoadingOutlined />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default BorderBox;
