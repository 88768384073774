import React, { useRef } from 'react';
import { aiTopMetaLogo } from '../../../../components/icons/icons.jsx';
import style from '../tiktok-modal-tab/tiktok.module.scss';
import VideoPlayer from '../meta-post-video-player/video-player.jsx';
import { Empty } from 'antd';

const MetaPostComponent = ({ videoData, trendingDish }) => {
  const VideoPlayerRef = useRef(null);

  const openDetail = (item) => {
    if (VideoPlayerRef.current && VideoPlayerRef.current.open) {
      VideoPlayerRef.current.open({
        videoId: item.PostId,
        videoTitle: item.PostTitle,
        videoUri: getEmbedUrl(item.PostURL),
      });
    } else {
      console.error('VideoPlayerRef.current or its open method is not defined');
    }
  };

  const handleClick = (item) => {
    openDetail(item);
  };

  const getEmbedUrl = (url) => {
    if (url.includes('facebook.com')) {
      return `https://www.facebook.com/plugins/video.php?href=${encodeURIComponent(url)}`;
    } else if (url.includes('instagram.com')) {
      const parts = url.split('/');
      const shortcode = parts[parts.length - 2];
      return `https://www.instagram.com/p/${shortcode}/embed/`;
    } else if (url.includes('threads.net')) {
      const parts = url.split('/');
      const videoId = parts[parts.length - 1];
      return `https://www.threads.net/embed/${videoId}`;
    }
    return url;
  };
  const data = videoData.slice(0, 6);

  return (
    <div className={`${style.SearchTiktok} mt-20`}>
      <div className={style.labelContainer}>
        <div className={style.tiktokTitle}>
          <div><img className={style.metaLogo} src={aiTopMetaLogo} alt="Meta Logo" /></div>
          <div className={style.text}>
          Top 6 Trending Meta content for  <span className={style.tendText}>{trendingDish}</span>
          </div>
        </div>
        <div className={style.videoContainer}>
          {data?.length === 0 && <div className={style.subBox}>
            <Empty
              className={style.emptyTable}
              image={Empty.PRESENTED_IMAGE_SIMPLE}
            /></div>}
          {data?.length > 0 && (
            <div className={style.videosBox}>
              {data.map((item) => (
                <div key={item.PostId} className={style.videoItem}>
                  <div
                    className={style.videoItemInner}
                    onClick={() => handleClick(item)}
                  >
                    <iframe
                      className={style.iframe}
                      src={getEmbedUrl(item.PostURL)}
                      title="Video Player"
                      allowFullScreen
                      scrolling='no'
                      allow="encrypted-media"
                    ></iframe>
                    <div className={style.videoCover}></div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
      <VideoPlayer ref={VideoPlayerRef} />
    </div>
  );
};

export default MetaPostComponent;
