import React from 'react';
import ReactDOM from 'react-dom/client';
import { MsalProvider } from '@azure/msal-react';
import { EventType, PublicClientApplication } from '@azure/msal-browser';
import App from './App';
import store from './store/store.js';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import msalConfig from './authConfig';
import Login from './login/login.jsx';

export const msalInstance = new PublicClientApplication(msalConfig);

if (!process.env.IGNORE_MFA || process.env.IGNORE_MFA === 'false') {
  msalInstance.addEventCallback((event) => {
    if (
      (event.eventType === EventType.LOGIN_SUCCESS ||
        event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS ||
        event.eventType === EventType.SSO_SILENT_SUCCESS) &&
      event.payload &&
      event.payload.account
    ) {
      msalInstance.setActiveAccount(event.payload.account);
      console.log('msal callback: login success');
    }
    if (
      event.eventType === EventType.HANDLE_REDIRECT_END &&
      !msalInstance.getActiveAccount()
    ) {
      console.log('First login, will reload soon..');
      window.location.reload();
    }
  });
  if (!msalInstance.getActiveAccount()) {
    const loginRequest = {
      scopes: ['openid', 'profile', 'User.Read'],
      extraScopesToConsent: [process.env.AZURE_LOGIN_URL],
    };
    await msalInstance.loginRedirect(loginRequest).catch((error) => {
      console.error('Error during loginRedirect:', error);
    });
  }
}

const root = ReactDOM.createRoot(document.getElementById('root'));
if (!process.env.IGNORE_MFA || process.env.IGNORE_MFA === 'false') {
  root.render(
    <>
      <MsalProvider instance={msalInstance}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </MsalProvider>
    </>
  );
} else if (
  process.env.IGNORE_MFA === 'true' &&
  sessionStorage.getItem('jwt-access-token')
) {
  // console.log('MFA disabled, token exist');
  root.render(
    <>
      <Provider store={store}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </Provider>
    </>
  );
} else {
  // console.log('MFA disabled, login');
  root.render(
    <>
      <Provider store={store}>
        <BrowserRouter>
          <Login />
        </BrowserRouter>
      </Provider>
    </>
  );
}
