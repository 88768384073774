// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/images/consumer-theater-chat/bubble.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.GLQTZT4dp1HBhP6iNEPj{background-image:url(${___CSS_LOADER_URL_REPLACEMENT_0___});background-repeat:no-repeat;background-size:100% 100%;position:fixed;bottom:50px;right:20px;z-index:1000;width:120px;height:120px;font-size:20px;line-height:1;font-weight:700;display:flex;justify-content:center;align-items:center;color:#00ff48;cursor:pointer}`, "",{"version":3,"sources":["webpack://./src/modules/consumer-theater-chat/index.module.scss"],"names":[],"mappings":"AAAA,sBACE,wDAAA,CACA,2BAAA,CACA,yBAAA,CAEA,cAAA,CACA,WAAA,CACA,UAAA,CACA,YAAA,CAEA,WAAA,CACA,YAAA,CAEA,cAAA,CACA,aAAA,CACA,eAAA,CAEA,YAAA,CACA,sBAAA,CACA,kBAAA,CAEA,aAAA,CACA,cAAA","sourcesContent":[".bubbleBox {\n  background-image: url('../../assets/images/consumer-theater-chat/bubble.png');\n  background-repeat: no-repeat;\n  background-size: 100% 100%;\n\n  position: fixed;\n  bottom: 50px;\n  right: 20px;\n  z-index: 1000;\n\n  width: 120px;\n  height: 120px;\n\n  font-size: 20px;\n  line-height: 1;\n  font-weight: 700;\n\n  display: flex;\n  justify-content: center;\n  align-items: center;\n\n  color: #00ff48;\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"bubbleBox": `GLQTZT4dp1HBhP6iNEPj`
};
export default ___CSS_LOADER_EXPORT___;
