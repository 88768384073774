import React, { useEffect, useRef } from 'react';
import style from './index.module.scss';

function RequestAccessValueDecompositionView() {
  const aRef = useRef();

  useEffect(() => {
    setTimeout(() => {
      if (aRef) aRef.current.click();
    }, 100);
  }, []);

  return (
    <div>
      <a ref={aRef} href="https://forms.office.com/r/7ZehmG96GU">
        <h1 className={style.title}>Loading....</h1>
      </a>
    </div>
  );
}

export default RequestAccessValueDecompositionView;
