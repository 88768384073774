import React, { useRef, useState } from 'react';
import CardCom from '../../../components/CardCom/index.jsx';
import { cn } from '../../../utils/utils';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Tabs } from 'antd';

import TestBg from '../../../assets/bg.png';

const tabItems = [
  {
    key: 'tab_1',
    label: 'Data',
  },
  {
    key: 'tab_2',
    label: 'Labels',
  },
  {
    key: 'tab_3',
    label: 'Tags',
  },
  {
    key: 'tab_4',
    label: 'Themes',
  },
  {
    key: 'tab_5',
    label: 'Showreels',
  },
];

const ResponseCom = () => {
  return (
    <div className={cn('grid gap-4')}>
      <TaskItemCom></TaskItemCom>
      <TaskItemCom></TaskItemCom>
    </div>
  );
};

const TaskItemCom = () => {
  const [curQIndex, setCurQIndex] = useState(1);
  const ref = useRef(null);

  const pageChange = (type) => {
    const curNode = ref.current;
    if(!curNode) return;
    let scrollVal = 0;
    if(type === 'pre') {
      scrollVal = -350;
    } else {
      scrollVal = 350;
    }
    curNode.scrollTo({
      left: curNode.scrollLeft + scrollVal,
      behavior: 'smooth',
    });
  };

  return <CardCom label="6860170 - Michael, 41 - Bromsgrove">
    <div className="flex justify-start">
      <div>
        <video className="w-[350px]" autoPlay={true} muted loop controls src="https://ec-crm-aliyun-oss.bluemoon.com.cn/officialwebsite/website/pro/website_20247_1724325255689.mp4" preload="true"></video>
        <div className="w-[350px] h-[80px] relative mt-3">
          <div onClick={() => pageChange('pre')} className="h-full flex justify-center items-center text-primary text-2xl cursor-pointer absolute left-0 z-10">
            <LeftOutlined />
          </div>

          <div onClick={() => pageChange('next')} className="h-full flex justify-center items-center text-primary text-2xl cursor-pointer absolute right-0 z-10">
            <RightOutlined />
          </div>
          <div ref={ref} className="w-full whitespace-nowrap overflow-x-auto">
            {
              [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15].map((item, index) => <div key={index} onClick={() => setCurQIndex(index)} className="w-[80px] inline-block relative overflow-hidden rounded-md mr-2 cursor-pointer">
                <img src={TestBg} className="w-full h-full" alt="" />
                <span className="px-[6px] py-[3px] text-[10px] leading-none bg-primary text-white font-normal rounded-3xl absolute top-[5px] left-[5px]">Q{item}</span>
                {
                  curQIndex !== index && <div className="bg-white opacity-50 w-full h-full absolute top-0 left-0"></div>
                }
              </div>)
            }
          </div>
        </div>
      </div>
      <div className="flex-1 ml-[20px]">
        <div className="w-fit bg-primary text-base leading-none font-bold text-white py-[7px] px-[14px] rounded-3xl">Question 1</div>
        <div className="my-[13px] text-primary text-base font-semibold">What are the special cooking techniques you apply when cooking a BBQ?</div>
        <div className="text-sm text-green-1 whitespace-break-spaces">
          {'No real special techniques ever done a barbecue. Just keep things moving. Don\'t let things burn. Classical thing is burning the outside. Have it on the inside. Lots of turning, lots of care, attention. Steak, keep basting it in butter. Some garlic and herbs is a really good technique. Make sure we have a good crust on the outside, but mainly the burgers and hot dogs and stuff. Just really keep them going. If we do any chicken dishes like above stuff, marinate it the day before, make sure that we really brought into the meat, make the flavour come out. That\'s really the techniques that really matter. Think, make sure things keep moving, nothing\'s burned, everything\'s cooked properly and evidence, we had enough time, have good flavour added to or enhanced.'}
        </div>
        <Tabs defaultActiveKey="1" items={tabItems} className="tab-custom [&>div.ant-tabs-nav]:before:border-[#00A32E1A] [&>div.ant-tabs-nav]:before:border-[1.5px] [&>div.ant-tabs-nav]:mb-0" />
        <div className="mt-5 text-sm text-green-1 font-normal [&>div>div.resonse-label]:w-1/3">
          <div className="flex justify-start mb-1">
            <div className="resonse-label font-semibold">state</div>
            <div>England</div>
          </div>
          <div className="flex justify-start mb-1">
            <div className="resonse-label font-semibold">location</div>
            <div>Bromsgrove</div>
          </div>
        </div>
      </div>
    </div>
  </CardCom>;
};

export default ResponseCom;