import React from 'react';
import Box from './box.jsx';
import style from './dialog.module.scss';
import { CloseCircleOutlined } from '@ant-design/icons';

function PackageAiDialog({
  className = '',
  title,
  children,
  visible,
  setVisible,
  zIndex = 2001,
}) {
  return (
    <div
      className={style.dialogContainer}
      style={{ display: visible ? 'block' : 'none', zIndex: zIndex }}
    >
      <div
        className={style.dialogModal}
        onClick={() => setVisible(false)}
      ></div>
      <Box
        hasHeader={Boolean(title)}
        title={title}
        className={`${style.dialogBox} ${className}`}
        headerColorReverse={true}
        headerRight={
          <div className={style.closeBtn} onClick={() => setVisible(false)}>
            <CloseCircleOutlined
              className={style.closeIcon}
            ></CloseCircleOutlined>
          </div>
        }
      >
        {children}
      </Box>
    </div>
  );
}

export default PackageAiDialog;
