import request from '../../../../util/pythonApi';

const CountryFilters = [
  'Income Brackets BR',
  'Income Brackets DE',
  'Income Brackets ID',
  'Income Brackets MX',
  'Income Brackets UK',
  'Income Brackets US',
  'Income Brackets IT',
  'Income Brackets FR',
  'Income Brackets TR',
  'Income Brackets NL',
  'Income Brackets AU',
  'Income Brackets CH',
  'Income Brackets SE',
  'Income Brackets BE',
  'Income Brackets FI',
];

/**
 * process filter
 * @param {Record<string,string[]>} filter
 */
function processFilter(filter) {
  for (const key in filter) {
    if (CountryFilters.includes(key)) {
      const v = filter[key];
      delete filter[key];
      const ks = key.split(' ');
      const kk = ks.slice(0, -1).join(' ');
      filter[kk] = v;
      filter['Markets'] = [ks[ks.length - 1]];
    }
  }
}

export async function getChart(name, filter) {
  processFilter(filter);
  return await request({
    url: '/v1/occasion/data/chart',
    method: 'get',
    params: {
      name,
      filter: JSON.stringify(filter),
    },
  });
}

export async function getTimeChart(name, filter) {
  processFilter(filter);
  return await request({
    url: '/v1/occasion/data/chart',
    method: 'get',
    params: {
      name: `${name}-time-analysis`,
      filter: JSON.stringify(filter),
    },
  });
}

export function getVerbatim(name, filter) {
  processFilter(filter);
  return request({
    url: '/v1/occasion/data/verbatim',
    method: 'get',
    params: {
      name,
      filter: JSON.stringify(filter),
    },
  });
}
