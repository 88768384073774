import request from './fetch';

// login
export function login (data) {
  return request({
    url: '/v1/auth/login',
    method: 'post',
    data
  });
}

// list
export function fetchList (params, uuid) {
  return request({
    url: '/v1/consumer_theater',
    method: 'get',
    uuid,
    params,
    originData: true
  });
}

// fetchOne
export function fetchOne (params) {
  return request({
    url: '/v1/consumer_theater',
    method: 'get',
    params,
  });
}

// countries
export function fetchCountries (params) {
  return request({
    url: '/v1/consumer_theater/country',
    method: 'get',
    params
  });
}

// category list
export function fetchCategory (params) {
  return request({
    url: '/v1/consumer_theater/category',
    method: 'get',
    params
  });
}

// video list of topic
export function getListByTopic (params, uuid) {
  return request({
    url: '/v1/consumer_theater/getByTopic',
    method: 'get',
    uuid,
    params,
    originData: true
  });
}

// dictionary list
export function fetchDictionary (params) {
  return request({
    url: '/v1/consumer_theater/dictionary',
    method: 'get',
    params
  });
}

// geographic_region list
export function fetchGeographicRegion (params) {
  return request({
    url: '/v1/consumer_theater/geographic_region',
    method: 'get',
    params
  });
}

// add suggestion
export function postSuggestion (data) {
  return request({
    url: '/v1/consumer_theater/suggestion',
    method: 'post',
    data
  });
}

// insight data
export function fetchInsight (params, uuid) {
  return request({
    url: '/v1/consumer_theater/insight',
    method: 'get',
    uuid,
    params,
    originData: true
  });
}

// admin
export function updateSummaryItem (data) {
  return request({
    url: '/v1/consumer_theater/insight',
    method: 'post',
    data,
  });
}

// upload
export function fileUpload (data) {
  return request({
    url: '/v1/azure_file',
    method: 'post',
    data,
    headers:{
      'Content-Type': 'multipart/form-data'
    },
  });
}

// projectID get
export function getProjectId (params) {
  return request({
    url: '/v1/consumer_theater/projectid',
    method: 'get',
    params,
  });
}

// projectID post
export function postProjectId (data) {
  return request({
    url: '/v1/consumer_theater/projectid',
    method: 'post',
    data
  });
}

// projectID put
export function putProjectId (data) {
  return request({
    url: '/v1/consumer_theater/projectid',
    method: 'put',
    data
  });
}

// projectID delete
export function deleteProjectId (data) {
  return request({
    url: '/v1/consumer_theater/projectid',
    method: 'delete',
    data
  });
}

// celery task
export function celeryTask (data) {
  return request({
    url: '/v1/celery_task',
    method: 'post',
    data
  });
}

// delete ConsumerTheater
export function deleteConsumerTheater (data) {
  return request({
    url: '/v1/consumer_theater',
    method: 'delete',
    data,
  });
}