import { MoreOutlined } from '@ant-design/icons';
import { Dropdown, Switch, message } from 'antd';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import LoadingModal from '../../../components/loading.jsx';
import style from './chart.module.scss';
import ChartFullScreen from './full-screen.jsx';

function ChartContainer({
  name,
  tabName,
  tab2Name,
  title,
  count,
  size,
  loading,
  className,
  children,
  setIntersecting,
  onDataLabelChange,
  onSaveExcel,
  onSaveCsv,
  onSaveImg,
}) {
  const sizeClass = style[`chartSize${size}`] ?? '';
  const divRef = useRef(null);
  const displayCount = useMemo(() => {
    return typeof count === 'number';
  }, [count]);

  useEffect(() => {
    const obs = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        setIntersecting && setIntersecting(entry.isIntersecting);
      });
    });
    obs.observe(divRef.current);
    return () => {
      obs.disconnect();
    };
  }, []);

  const [showDataLabels, setShowDataLabels] = useState(false);
  useEffect(() => {
    onDataLabelChange && onDataLabelChange(showDataLabels);
  }, [showDataLabels]);

  function onShare() {
    const u = new URL(window.location.href);
    u.searchParams.set('chart', name);
    u.searchParams.set('tab', tabName);
    if (tab2Name) u.searchParams.set('tab2', tab2Name);
    navigator.clipboard.writeText(u.href);
    message.success('Link copied to clipboard');
  }

  const dropItems = [
    {
      key: 1,
      label: (
        <>
          Show Data Labels{' '}
          <Switch
            disabled={!onDataLabelChange}
            onChange={setShowDataLabels}
          ></Switch>
        </>
      ),
    },
  ];
  if (onSaveExcel) {
    dropItems.push({
      key: 2,
      label: <span onClick={onSaveExcel}>Export as Excel</span>,
    });
  }
  if (onSaveCsv) {
    dropItems.push({
      key: 3,
      label: <span onClick={onSaveCsv}>Export as Csv</span>,
    });
  }
  if (onSaveImg) {
    dropItems.push({
      key: 4,
      label: <span onClick={onSaveImg}>Export as PNG</span>,
    });
  }
  dropItems.push({
    key: 5,
    label: <span onClick={() => setFullScreen(true)}>Full Screen</span>,
  });
  dropItems.push({
    key: 6,
    label: <span onClick={onShare}>Share link</span>,
  });

  const startChart = new URL(window.location).searchParams.get('chart');
  const [fullScreen, setFullScreen] = useState(startChart === name);

  return (
    <div
      ref={divRef}
      className={`${style.chartContainer} ${sizeClass} ${className}`}
    >
      <div className={style.chartHeader}>
        <h3 className={style.chartTitle}>{title}</h3>
        <div className={style.chartHeaderRight}>
          {displayCount && (
            <div className={style.chartCountText}>
              <span>{count} SAMPLE SIZE</span>
              {count < 100 && (
                <span className={style.chartCountTextWarning}>
                  {' '}
                  - Low Sample Size
                </span>
              )}
            </div>
          )}
          <Dropdown className={style.chartDropdown} menu={{ items: dropItems }}>
            <MoreOutlined />
          </Dropdown>
        </div>
      </div>
      <div className={style.chartContent}>{children}</div>
      <LoadingModal loading={loading}></LoadingModal>
      {fullScreen && (
        <ChartFullScreen
          title={title}
          count={count}
          onClose={() => setFullScreen(false)}
        >
          {children}
        </ChartFullScreen>
      )}
    </div>
  );
}

export default ChartContainer;
