import React, { useCallback, useEffect, useState } from 'react';

import style from './index.module.scss';
import LeftFilterNavigate from '../../components/left-filter-navigate/left-filter-navigate.jsx';
import ChartMain from '@/modules/pain-points/components/chart-main/chartMain.jsx';

const HomePage = () => {

  return <>
      <div className={style.boxWrap}>
        <LeftFilterNavigate/>
        <ChartMain/>
      </div>
  </>;
};

export default HomePage;