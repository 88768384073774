// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.FGWE6cInVNQSNDtnOPKg{width:100% !important;padding-top:10px !important}.Q6Cw2ahfU5EXUiwlBqJE{position:relative}.mHGDNx8p1thRXrU8EMZa{font-size:24px;font-family:"unilever-shilling-regular";color:#33d44a;text-align:center;margin:auto;padding-left:5em;padding-top:8px;padding-bottom:15px;font-weight:600}.ItAdMAnMiBtV_4u8Skoo{width:35px;margin-left:1em;margin-right:.8em}`, "",{"version":3,"sources":["webpack://./src/modules/ai-top-dish/ai-top-dish.module.scss"],"names":[],"mappings":"AAAA,sBACE,qBAAA,CACA,2BAAA,CAGF,sBACE,iBAAA,CAGF,sBACE,cAAA,CACA,uCAAA,CACA,aAAA,CACA,iBAAA,CACA,WAAA,CACA,gBAAA,CACA,eAAA,CACA,mBAAA,CACA,eAAA,CAEF,sBACE,UAAA,CACA,eAAA,CACA,iBAAA","sourcesContent":[".fullContainer {\n  width: 100% !important;\n  padding-top: 10px !important;\n}\n\n.aiTopDishFilterApply {\n  position: relative;\n}\n\n.titleAiToDish {\n  font-size: 24px;\n  font-family: 'unilever-shilling-regular';\n  color: #33d44a;\n  text-align: center;\n  margin: auto;\n  padding-left: 5em;\n  padding-top: 8px;\n  padding-bottom: 15px;\n  font-weight: 600;\n}\n.iconContainer {\n  width: 35px;\n  margin-left: 1em;\n  margin-right: 0.8em;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"fullContainer": `FGWE6cInVNQSNDtnOPKg`,
	"aiTopDishFilterApply": `Q6Cw2ahfU5EXUiwlBqJE`,
	"titleAiToDish": `mHGDNx8p1thRXrU8EMZa`,
	"iconContainer": `ItAdMAnMiBtV_4u8Skoo`
};
export default ___CSS_LOADER_EXPORT___;
