import classNames from 'classnames';
import React, { useMemo, useState } from 'react';
import Divider from '../../../components/divider/index.jsx';
import Chart from './chart.jsx';
import Header from './header.jsx';
import style from './index.module.scss';

function Table({ className, data, setChartRef, showValue, onImgClick }) {
  // sort, brand, appeal, upi
  const [sortBy, setSortBy] = useState('brand');
  const [sortOrder, setSortOrder] = useState('asc');

  const sortData = useMemo(() => {
    return data.sort((a, b) => {
      let x = a[sortBy];
      let y = b[sortBy];
      if (sortOrder === 'asc') {
        return x - y;
      } else {
        return y - x;
      }
    });
  }, [data, sortBy, sortOrder]);

  return (
    <div className={classNames(style.box13, className)}>
      <div className={style.box131}>
        <div className={style.item}>
          <Header
            text="Brand Visibility"
            tip="This metric measures the probability of the brand logo being noticed in the initial 3-5 seconds of looking at the pack design. It is based on predictive eye-tracking as measured via the 3M VAS algorithm."
            sort={sortBy === 'brand' ? sortOrder : undefined}
            onSortClick={() => {
              setSortBy('brand');
              setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
            }}
          />
          <div
            className={classNames(style.itemBox1, {
              [style.itemBox1Active]: sortBy === 'brand',
            })}
          ></div>
        </div>
        <Divider />
        <div className={style.item}>
          <Header
            text="Appeal"
            tip="This metric measures the rate of consumers who chose the pack as most appealing within its category shelf context. It is derived from consumer survey data."
            sort={sortBy === 'appeal' ? sortOrder : undefined}
            onSortClick={() => {
              setSortBy('appeal');
              setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
            }}
          />
          <div
            className={classNames(style.itemBox1, {
              [style.itemBox1Active]: sortBy === 'appeal',
            })}
          ></div>
        </div>
        <Divider />
        <div className={style.item}>
          <Header
            text="Unpriced Purchase Intent"
            tip="This metric measures the rate of respondents who are moderately and very likely to purchase the product based on consideration of the design alone. It is based on consumer survey data."
            sort={sortBy === 'upi' ? sortOrder : undefined}
            onSortClick={() => {
              setSortBy('upi');
              setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
            }}
          />
          <div
            className={classNames(style.itemBox1, {
              [style.itemBox1Active]: sortBy === 'upi',
            })}
          ></div>
        </div>
      </div>
      <div className={style.box132}>
        <Chart
          setRef={setChartRef}
          className={style.box1321}
          data={sortData}
          showValue={showValue}
          onImageClick={onImgClick}
        />
      </div>
    </div>
  );
}

export default Table;
