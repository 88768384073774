import service from '../../../../util/pythonApi';

export function getKnorrFavorite(id) {
  return service.request({
    url: `/v1/knorr/${id}/favorite`,
    method: 'get',
  });
}

export function postKnorrFavorite(id) {
  return service.request({
    url: `/v1/knorr/${id}/favorite`,
    method: 'post',
  });
}

export function deleteKnorrFavorite(id) {
  return service.request({
    url: `/v1/knorr/${id}/favorite`,
    method: 'delete',
  });
}
