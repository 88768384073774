import { message } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import LoadingGif from '../../assets/img/loading.gif';
import Box from '../../components/box.jsx';
import Container from '../../components/container.jsx';
import Stage from '../../components/stage.jsx';
import style from './index.module.scss';
import { sleep } from '../../utils/time';
import { getPredictVariant, postPredictVariantRun } from '../../apis/serve';

function Loading({ value = 0 }) {
  const limitedValue = useMemo(() => {
    if (value <= 100) return value;
    return 100;
  }, [value]);

  return (
    <div className={style.loadingModal}>
      <img className={style.loadingImg} src={LoadingGif} />
      <div className={style.loadingText}>{limitedValue} %</div>
    </div>
  );
}

function PackageAiView() {
  const [loading, setLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [per, setPer] = useState(0);

  async function init() {
    try {
      const ctxs = sessionStorage.getItem('packageAiContext');
      if (!ctxs) {
        message.error('Please fill in the basic information first');
        window.open('/package-ai-screening-batch-basic', '_self');
        return null;
      }
      const ctx = JSON.parse(ctxs);

      // init interval
      const itv = setInterval(() => {
        setPer((p) => {
          if (p < 100) return p + 1;
          return p;
        });
      }, 3600);

      ctx.res.postVariantRun = {};
      ctx.res.variants = {};
      const ids = ctx.res.imageIds;
      const idBatch = [];
      for (let i = 0; i < ids.length; i += 6) {
        idBatch.push(ids.slice(i, i + 6));
      }
      // process data
      for (const vids of idBatch) {
        const { data: pvrd } = await postPredictVariantRun({ id: vids });
        if (pvrd) {
          ctx.res.postVariantRun = { ...ctx.res.postVariantRun, ...pvrd };
        }
        for (const vid of vids) {
          const ids = vid.toString();
          const { data: vd } = await getPredictVariant(ids);
          if (vd) {
            ctx.res.variants[ids] = vd;
          }
        }
      }

      sessionStorage.setItem('packageAiContext', JSON.stringify(ctx));
      clearInterval(itv);
      setPer(100);

      // goto result
      await sleep(1000);
      window.open('/package-ai-screening-batch-result', '_self');
    } catch (err) {
      console.error('init error', err);
      message.error('Fail to load data');
      setIsError(true);
      setLoading(false);
    }
  }
  useEffect(() => {
    void init();
  }, []);

  return (
    <Container>
      <div className={style.containerInner}>
        <Stage stage={3} />
        <Box title="Outcome" className={style.box}>
          {loading && <Loading value={per} />}
          {isError && <h1 className={style.errorText}>Fail to load data</h1>}
        </Box>
      </div>
    </Container>
  );
}

export default PackageAiView;
