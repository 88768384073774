// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sSWZ24C8gwyM8mlGyPwr{height:100%;padding:30px;box-sizing:border-box;display:flex;flex-direction:column}.vrLvpE386Z7XBUK4V_K1{display:flex;align-items:center}.vrLvpE386Z7XBUK4V_K1 .OIiDw6zKjZw8dCZpzUJi{flex:1;overflow:hidden;margin-right:10px}.ohpXRtg3BLQsGYRTDo3M{flex:1;overflow-x:hidden;overflow-y:auto;margin-top:20px;display:grid;grid-template-columns:repeat(2, 1fr);grid-row-gap:24px;grid-column-gap:24px}.YwFy0j29vKW9c_aG5UtW{margin-top:15px;display:flex;justify-content:center}`, "",{"version":3,"sources":["webpack://./src/modules/package-ai-generation/views/update/library.module.scss"],"names":[],"mappings":"AAAA,sBACE,WAAA,CACA,YAAA,CACA,qBAAA,CACA,YAAA,CACA,qBAAA,CAGF,sBACE,YAAA,CACA,kBAAA,CAEA,4CACE,MAAA,CACA,eAAA,CACA,iBAAA,CAIJ,sBACE,MAAA,CACA,iBAAA,CACA,eAAA,CACA,eAAA,CACA,YAAA,CACA,oCAAA,CACA,iBAAA,CACA,oBAAA,CAGF,sBACE,eAAA,CACA,YAAA,CACA,sBAAA","sourcesContent":[".libraryBox {\n  height: 100%;\n  padding: 30px;\n  box-sizing: border-box;\n  display: flex;\n  flex-direction: column;\n}\n\n.libraryHeader {\n  display: flex;\n  align-items: center;\n\n  .input {\n    flex: 1;\n    overflow: hidden;\n    margin-right: 10px;\n  }\n}\n\n.libraryBody {\n  flex: 1;\n  overflow-x: hidden;\n  overflow-y: auto;\n  margin-top: 20px;\n  display: grid;\n  grid-template-columns: repeat(2, 1fr);\n  grid-row-gap: 24px;\n  grid-column-gap: 24px;\n}\n\n.confirmBox {\n  margin-top: 15px;\n  display: flex;\n  justify-content: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"libraryBox": `sSWZ24C8gwyM8mlGyPwr`,
	"libraryHeader": `vrLvpE386Z7XBUK4V_K1`,
	"input": `OIiDw6zKjZw8dCZpzUJi`,
	"libraryBody": `ohpXRtg3BLQsGYRTDo3M`,
	"confirmBox": `YwFy0j29vKW9c_aG5UtW`
};
export default ___CSS_LOADER_EXPORT___;
