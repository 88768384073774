import request from '../../../../util/pythonApi';

const CountryFilters = [
  'demog_income_all_unlv_bands_br',
  'demog_income_all_unlv_bands_de',
  'demog_income_all_unlv_bands_id',
  'demog_income_all_unlv_bands_mx',
  'demog_income_all_unlv_bands_uk',
  'demog_income_all_unlv_bands_us',
];

/**
 * process filter
 * @param {Record<string,string[]>} filter
 */
function processFilter(filter) {
  for (const key in filter) {
    if (CountryFilters.includes(key)) {
      const v = filter[key];
      delete filter[key];
      const ks = key.split('_');
      const kk = ks.slice(0, -1).join(' ');
      filter[kk] = v;
      filter['Markets'] = [ks[ks.length - 1].toUpperCase()];
    }
  }
}

export function getImages(page, pageSize, filter) {
  processFilter(filter);
  return request({
    url: '/v1/occasion/images',
    method: 'get',
    params: {
      page: page ?? 1,
      page_size: pageSize ?? 10,
      filter: JSON.stringify(filter),
    },
  });
}

export function getImageDetail(id) {
  return request({
    url: `/v1/occasion/images/${id}`,
    method: 'get',
  });
}
