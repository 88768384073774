import React, { useEffect, useState } from 'react';
import { downloadWb } from '../../../apis/file/index.js';
import BarChart from '../charts/bar.jsx';
import style from './tab.module.scss';

function CookingPreparation({
  filter,
  compare,
  hasCompare,
  exportCount,
  isActive,
}) {
  const saveDataFns = [];
  function appendSaveDataFn(fn) {
    saveDataFns.push(fn);
  }

  useEffect(() => {
    if (!isActive) return;
    if (!exportCount) return;
    downloadWb(saveDataFns, 'xlsx', 'Cooking Preparation');
  }, [exportCount]);

  const [userSelectChart, setUserSelectChart] = useState('');
  const [userSelectChartKey, setUserSelectChartKey] = useState('');
  const [userSelectChartValue, setUserSelectChartValue] = useState('');
  function onUserSelectChart(name, key, value) {
    setUserSelectChart(name);
    setUserSelectChartKey(key);
    setUserSelectChartValue(value);
  }

  return (
    <div className={style.chartContainer}>
      <BarChart
        name="effort-level"
        tabName="cooking-preparation"
        title="Effort level"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Preparation Effort Level (Grouped)"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <BarChart
        name="preparation-effort-level"
        tabName="cooking-preparation"
        title="Preparation effort level"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Preparation Effort Level"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <BarChart
        name="preparation-time"
        tabName="cooking-preparation"
        title="Preparation time"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        sortList={[
          'More than 1.5 hours',
          '1 to 1.5 hours',
          '46 minutes to 1 hour',
          '31 to 45 minutes',
          '16 to 30 minutes',
          '5 to 15 minutes',
          'Less than 5 minutes',
        ]}
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Preparation Time"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <BarChart
        name="preparation-method"
        tabName="cooking-preparation"
        title="Preparation method"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Preparation Method (Overall)"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <BarChart
        name="when-did-you-decide-what-to-cook-prepare"
        tabName="cooking-preparation"
        title="When did you decide what to cook/prepare?"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Food Decision Time"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <BarChart
        name="frequency-of-preparing-cooking-this-food"
        tabName="cooking-preparation"
        title="Frequency of preparing/cooking this food"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Frequency Of Preparing/Cooking This Food"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <BarChart
        name="recipe-used-to-cook-prepare"
        tabName="cooking-preparation"
        title="Recipe used to cook/prepare"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Recipe (Y/N)"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <BarChart
        name="where-did-you-find-the-recipe"
        tabName="cooking-preparation"
        title="Where did you find the recipe?"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Recipe Source"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
    </div>
  );
}

export default CookingPreparation;
