import React from 'react';
import { Select } from 'antd';

const { Option } = Select;

const TopDishSelect = ({ topDishes, selectedDishes, onChange, dropdownStyle }) => {
  const handleChange = (value) => {
    if (value.includes('selectAll')) {
      if (selectedDishes.length === topDishes.length) {
        onChange([]);
      } else {
        onChange(topDishes);
      }
    } else {
      onChange(value);
    }
  };
  return (
    <Select
      mode="multiple"
      maxTagCount={1}
      value={selectedDishes}
      onChange={handleChange}
      className='custom-select filter-select'
      dropdownStyle={dropdownStyle}
    >
      <Option key="selectAll" value="selectAll">
        <input
          type="checkbox"
          className='topdish-filter-checkbox topdish-scroll'
          checked={selectedDishes.length === topDishes.length}
          readOnly
        />
        Select All
      </Option>
      {topDishes.map(dish => (
        <Option key={dish} value={dish}>
          <input
            type="checkbox"
            className='topdish-filter-checkbox topdish-scroll'
            checked={selectedDishes.includes(dish)}
            readOnly
          />
          {dish}
        </Option>
      ))}
    </Select>
  );
};

export default TopDishSelect;
