import React, { useState, useImperativeHandle, forwardRef } from 'react';
import diasporaModalData from './diaspora-modal-data.js';
import ReactECharts from 'echarts-for-react';
import * as echarts from 'echarts/core';
import { CanvasRenderer } from 'echarts/renderers';
import style from './diaspora-modal.module.scss';
import { Modal } from 'antd';
import { cancelGreenIcon } from '../../../../components/icons/icons.jsx';
import BorderContainer from '../border-container/border-container.jsx';

echarts.use(
  [CanvasRenderer]
);

const modifiedData = diasporaModalData.map((item) => {
  return {
    ...item,
    label: {
      ...item.label,
    },
    name: item.name.toUpperCase(),
  };
});

let DiasporaModal = (_, ref) => {

  const [isModalOpen, setIsModalOpen] = useState(false);

  const option = {
    tooltip: {
      trigger: 'item',
    },
    series: [
      {
        type: 'pie',
        radius: '80%',
        avoidLabelOverlap: true,
        label: {
          show: true,
          position: 'inside',
        },
        itemStyle: {
          borderRadius: 0,
        },

        data: modifiedData,
        emphasis: {
          label: {
            color: '#FFFFFF',
            show: true,
          },
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: 'rgba(0, 0, 0, 0.5)',
          },
        },
      },
    ],
  };

  useImperativeHandle(ref, () => ({
    open: () => {
      setIsModalOpen(true);
    }
  }));

  return (
    <Modal
      title="" 
      wrapClassName={style.myDialog}
      width={1200} 
      centered 
      destroyOnClose
      footer={null} 
      open={isModalOpen}
      onCancel={() => setIsModalOpen(false)}
      closeIcon={(
        <div className={style.closeBtn}>
          <img src={cancelGreenIcon} alt="" />
        </div>
      )}
    >
      <div className={style.dialogBox}>
        <BorderContainer up>
          <div className={style.dialogMain}>
            <div className={style.titleBox}>Diaspora / Population segregation in the UK</div>
            <div className={style.populationSegDesc}>
              <p>
                As consumer cohorts become more diverse — due to globalisation,
                immigration and wider social change - region-specific flavours are in
                need. This is about food as a means of connecting people back to their
                culture. Consumers look to brands to help them connect with heritage
                and authentic cuisines allowing them to authentically cook and consume
                foods from their various cultures and cuisines roots at home.
              </p>
            </div>

            <div className={style.diasporaWrapper}>
              <div className={style.showList}>
                <ul className={style.columnContainer}>
                  <h3>Country</h3>
                  {diasporaModalData.map((item, index) => {
                    return <li key={index} className={style.columnItem}>{item.name}</li>;
                  })}
                </ul>

                <ul className={style.columnContainer}>
                  <h3>Population</h3>
                  {diasporaModalData.map((item, index) => {
                    return <li key={index} className={style.columnItem}>{item.value}</li>;
                  })}
                </ul>
              </div>
              <div className={style.showGraph}>
                <ReactECharts
                  isAnimationActive={true}
                  option={option}
                  style={{ width: '540px', height: 600 }}
                />
              </div>
            </div>
          </div>
        </BorderContainer>
      </div>
    </Modal>
  );
};
DiasporaModal = forwardRef(DiasporaModal);
export default DiasporaModal;
