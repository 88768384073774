import React, { useRef, useState, useEffect } from 'react';
import style from './gpt-insight.module.scss';
import { Input } from 'antd';
const { TextArea } = Input;

const GPTInsight = () => {
  const textAreaRef = useRef(null);
  const [height, setHeight] = useState(38);

  useEffect(() => {
    const handleResize = () => {
      if (textAreaRef.current) {
        const newHeight = textAreaRef.current.scrollHeight;
        if (newHeight !== height) {
          setHeight(newHeight);
          console.log('TextArea height changed: ', newHeight);
        }
      }
    };

    handleResize(); // Check height on component mount

    // Use MutationObserver to detect changes in the TextArea content
    const observer = new MutationObserver(handleResize);
    if (textAreaRef.current) {
      observer.observe(textAreaRef.current.resizableTextArea.textArea, { attributes: true, childList: true, subtree: true, characterData: true });
    }

    return () => {
      observer.disconnect();
    };
  }, [height]);

  return (
    <div className={style.gptInsightBox}>
      <div className={style.messageBox} id="123" style={{height: `100% - ${height}px`}}></div>
      <div className={style.inputBox}>
        <div className={style.inputBox}>
          <TextArea 
            ref={textAreaRef}
            placeholder="Enter what you want to ask"
            autoSize={{
              minRows: 1,
              maxRows: 3,
            }}
          />
        </div>
        <div className={style.sendButton}></div>
      </div>
    </div>
  );
};

export default GPTInsight;