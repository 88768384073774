import React from 'react';
import { ConfigProvider } from 'antd';
import { Collapse } from 'antd';


const App = ({ item }) => {

  const onChange = (key) => {
    console.log(key);
  };

  return <ConfigProvider
    theme={{
      components: {
        Collapse: {
          /* 这里是你的组件 token */
          contentBg: 'rgba(255,0,0,0)',
          headerBg: 'rgba(0,255,0,0)',
          colorBorder: 'rgba(0,0,255,0)',
          colorText: '#FFF',
          colorTextHeading: '#FFF',
          headerPadding: '10px 25px',
          contentPadding: '5px 40px 10px 40px',
          fontSize: 16,
        },
      },
    }}
  >
    <Collapse items={[item]} expandIconPosition="end" defaultActiveKey={['2']}
              onChange={onChange} />

  </ConfigProvider>;

};

App.defaultProps = {
  item:{
    itemKey: '',
    label: '',
    children: '',
  }
};

export default App;