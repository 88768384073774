import React, { useMemo } from 'react';
import AiIcon from '../../components/aiIcon.jsx';
import ColorCoding from '../../components/colorCoding.jsx';
import Line from './line.jsx';
import style from './table.module.scss';
import Title from './title.jsx';

function DeepDiveBtn({ index }) {
  const link = useMemo(() => {
    return `/package-ai-screening-deep-dive?index=${index}`;
  }, [index]);

  return (
    <a href={link}>
      <div className={style.deepBtn}>Deep Dive</div>
    </a>
  );
}

function TableH({ label, index, url, onImgClick }) {
  const cns = [style.headerCell];
  if (index === 1) {
    cns.push(style.cellHighLight);
  }
  function onClick() {
    if (!onImgClick) return;
    onImgClick(url, label);
  }

  return (
    <th className={`${cns.join(' ')}`}>
      <div className={style.headerCellInner}>
        {url && (
          <img
            className={style.headerCellImg}
            src={url}
            alt={label}
            onClick={onClick}
          />
        )}
        {label}
      </div>
    </th>
  );
}

export function PackageAiTableHeader({
  className = '',
  labels = [],
  onImgClick,
}) {
  return (
    <thead className={`${style.header} ${className}`}>
      <tr>
        {labels.map((label, index) => (
          <TableH
            key={index}
            label={label.title}
            url={label.url}
            index={index}
            onImgClick={onImgClick}
          />
        ))}
      </tr>
    </thead>
  );
}

function TableD({ value, index, colorValue = false, thresholdFn }) {
  const cns = [style.cell];
  if (index === 0) {
    cns.push(style.cellFirst);
  } else if (index === 1) {
    cns.push(style.cellHighLight);
  }

  const displayValue = useMemo(() => {
    if (index === 0) return value;
    if (value === undefined || value === null) return 'Blank';
    if (typeof value !== 'number') return value;
    return value.toFixed(2);
  }, [value]);

  const cv = useMemo(() => {
    if (index === 0) return 0;
    if (!colorValue) return 0;
    if (!thresholdFn) return 0;
    return thresholdFn(value);
  }, [value, colorValue]);

  return (
    <td className={cns.join(' ')}>
      {index === 0 ? (
        displayValue
      ) : (
        <ColorCoding colorValue={cv}>{displayValue}</ColorCoding>
      )}
    </td>
  );
}

export function PackageAiTableRow({
  className = '',
  values = [],
  colorValue = false,
  thresholdFn,
}) {
  return (
    <tr className={`${style.row} ${className}`}>
      {values.map((value, index) => (
        <TableD
          key={index}
          value={value}
          index={index}
          colorValue={colorValue}
          thresholdFn={thresholdFn}
        />
      ))}
    </tr>
  );
}

function TableDIcon({ value, index, transFn }) {
  const cns = [style.cell];
  if (index === 0) {
    cns.push(style.cellFirst);
  } else if (index === 1) {
    cns.push(style.cellHighLight);
  }

  return (
    <td className={`${cns.join(' ')}`}>
      {index === 0 ? (
        value
      ) : (
        <AiIcon value={transFn ? transFn(value) : value} />
      )}
    </td>
  );
}

function PackageAiTableRowIcon({ className = '', values = [], transFn }) {
  return (
    <tr className={`${style.row} ${className}`}>
      {values.map((value, index) => (
        <TableDIcon key={index} value={value} index={index} transFn={transFn} />
      ))}
    </tr>
  );
}

export function PackageAiTableRowSingle({ className = '', children }) {
  return (
    <tr className={`${style.row} ${className}`}>
      <td colSpan={8}>{children}</td>
    </tr>
  );
}

export function PackageAiTable({
  className = '',
  value = {},
  column = [],
  onImgClick,
}) {
  const titles = useMemo(() => {
    return [{ title: '' }].concat(column);
  });

  const values = useMemo(() => {
    return {
      packageVisibility: [
        <>
          Pack Visibility on Shelf
          <DeepDiveBtn key="Pack Visibility on Shelf" index={0} />
        </>,
      ].concat(value.packageVisibility),
      brandStdout: [
        <>
          Brand Visibility
          <DeepDiveBtn key="Brand Visibility" index={1} />
        </>,
      ].concat(value.brandStdout),
      balance: [
        <>
          Brand-Variant Balance
          <DeepDiveBtn key="Brand-Variant Balance" index={2} />
        </>,
      ].concat(value.balance),
      logoExcite: [
        <>
          Design Excitement
          <DeepDiveBtn key="Design Excitement" index={4} />
        </>,
      ].concat(value.logoExcite),
      logoClarity: [
        <>
          Design Clarity
          <DeepDiveBtn key="Design Clarity" index={4} />
        </>,
      ].concat(value.logoClarity),
      logoBalance: [
        <>
          Design Symmetry
          <DeepDiveBtn key="Design Symmetry" index={4} />
        </>,
      ].concat(value.logoBalance),
    };
  }, [value]);
  return (
    <table className={`${style.table} ${className}`}>
      <PackageAiTableHeader labels={titles} onImgClick={onImgClick} />
      <tbody>
        <PackageAiTableRowSingle>
          <Title>Attract</Title>
        </PackageAiTableRowSingle>
        <PackageAiTableRow
          values={values.packageVisibility}
          colorValue={true}
          thresholdFn={(val) => {
            if (val > 70) return 3;
            else if (val > 40) return 2;
            else return 1;
          }}
        />
        <PackageAiTableRowSingle>
          <Line />
        </PackageAiTableRowSingle>
        <PackageAiTableRow
          values={values.brandStdout}
          colorValue={true}
          thresholdFn={(val) => {
            if (val > 70) return 3;
            else if (val > 40) return 2;
            else return 1;
          }}
        />
        <PackageAiTableRowSingle>
          <Line />
        </PackageAiTableRowSingle>
        <PackageAiTableRow
          values={values.balance}
          colorValue={true}
          thresholdFn={(val) => {
            const av = Math.abs(val);
            if (av < 0.5) {
              return 3;
            } else if (av < 1.5) {
              return 2;
            } else {
              return 1;
            }
          }}
        />
        <PackageAiTableRowSingle>
          <Title>Engage</Title>
        </PackageAiTableRowSingle>
        <PackageAiTableRow
          values={values.logoExcite}
          colorValue={true}
          thresholdFn={(val) => {
            if (val > 40 && val < 60) return 3;
            else if (val > 25 && val < 75) return 2;
            else return 1;
          }}
        />
        <PackageAiTableRowSingle>
          <Line />
        </PackageAiTableRowSingle>
        <PackageAiTableRow
          values={values.logoClarity}
          colorValue={true}
          thresholdFn={(val) => {
            if (val > 75) return 3;
            else if (val > 50) return 2;
            else return 1;
          }}
        />
        <PackageAiTableRowSingle>
          <Line />
        </PackageAiTableRowSingle>
        <PackageAiTableRow
          values={values.logoBalance}
          colorValue={true}
          thresholdFn={(val) => {
            if (val > 75) return 3;
            else if (val > 60) return 2;
            else return 1;
          }}
        />
      </tbody>
    </table>
  );
}
