import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import './styles/main.scss';
import { useMsal } from '@azure/msal-react';
import SignOutMessage from './components/profile/logout-page/logout-page.jsx';
import NavRoutes from './routes/router.jsx';
import { getToken } from '@/util/api.jsx';
import pythonApi from '@/util/pythonApi.js';
import { pythonApiURL } from './env-url.js';

function App() {
  const { instance, accounts } = useMsal();
  const [userName, setUserName] = useState();

  const location = useLocation();
  const auditLog = (data) => {
    pythonApi
      .post(`${pythonApiURL}/v1/auditlog`, data)
      .then((res) => {
        if (res.status === 200) {
          console.log('audit logging success');
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    // audit log
    if (location.pathname !== '/' && location.pathname !== '/navbar') {
      auditLog(
        JSON.stringify({
          category: 'page-view',
          message: location.search,
          event: location.pathname,
        })
      );
    }
  }, [location]);

  useEffect(() => {
    const fetchTokenAsync = async () => {
      await getToken();
    };

    if (accounts.length > 0) {
      const account = accounts[0]; // Assuming you are using only one account
      setUserName(account.name);
    }
    fetchTokenAsync().catch(console.error);
  }, [accounts, instance]);

  return (
    <>
      {accounts.length > 0 ? (
        <div className="app-container">
          <NavRoutes userName={userName} />
        </div>
      ) : sessionStorage.getItem('jwt-access-token') ? (
        <div className="app-container">
          <NavRoutes />
        </div>
      ) : (
        <SignOutMessage />
      )}
    </>
  );
}

export default App;
