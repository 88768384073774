import React, { useCallback } from 'react';
import style from './options.module.scss';
import { UpOutlined, DownOutlined } from '@ant-design/icons';

function Item({ className = '', src, alt, selected, onClick }) {
  return (
    <div
      className={`${style.optionsItem} ${
        selected ? style.active : ''
      } ${className}`}
      onClick={onClick}
    >
      <img src={src} alt={alt} crossOrigin="anonymous" />
    </div>
  );
}

function PackageAiOptions({ className = '', list, selected, onSelect }) {
  const onPrev = useCallback(() => {
    const ns = selected - 1;
    if (ns < 0) onSelect(list.length - 1);
    onSelect(ns);
  }, [list, selected, onSelect]);
  const onNext = useCallback(() => {
    const ns = selected + 1;
    if (ns >= list.length) onSelect(0);
    onSelect(ns);
  }, [list, selected, onSelect]);

  if (!list) return null;

  return (
    <div className={`${style.optionsContainer} ${className}`}>
      <div className={style.optionsPrev} onClick={onPrev}>
        <UpOutlined className={style.optionsBtnIcon} />
      </div>
      {list.map((item, i) => {
        return (
          <Item
            key={i}
            src={item.src}
            alt={item.alt}
            selected={selected === i}
            onClick={() => onSelect(i)}
          />
        );
      })}
      <div className={style.optionsNext} onClick={onNext}>
        <DownOutlined className={style.optionsBtnIcon} />
      </div>
    </div>
  );
}

export default PackageAiOptions;
