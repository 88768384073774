import { message } from 'antd';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Image as KonvaImage, Layer, Line, Rect, Stage } from 'react-konva';
import useImage from 'use-image';
import BrushIco from '../../assets/img/brush.ico';
import style from './editor.module.scss';

const BRUSH_COLOR = '#00a32e99';
const PURGE_COLOR = '#00000099';
const BRUSH_SIZES = [0, 5, 15, 45];

function PackageAiLine({ data, posScale = 1, color = BRUSH_COLOR }) {
  return (
    <Line
      points={data.points.map((e) => e / posScale)}
      stroke={color}
      strokeWidth={data.size / posScale}
      lineCap="round"
      lineJoin="round"
    />
  );
}

function PackageAiEditor({
  url,
  brushScale,
  scale = 1,
  setScale,
  clearCount = 0,
  resetCount = 0,
  func = 1,
  cursor = 1,
  data,
  setData,
  setDrawRef,
  setLoading,
}) {
  const [image, imageStatus] = useImage(url, 'anonymous');
  useEffect(() => {
    if (imageStatus === 'failed') {
      message.error('Failed to load image');
      setLoading(false);
    } else if (imageStatus === 'loaded') {
      // reset scale
      setScale(1);
      setLoading(false);
    } else if (imageStatus === 'loading') {
      setLoading(true);
    } else {
      console.warn('unknown image status', imageStatus);
    }
  }, [imageStatus]);
  /**
   * @type {React.MutableRefObject<HTMLDivElement>}
   */
  const boxRef = useRef(null);
  const contain = useMemo(() => {
    if (!image || !boxRef.current) {
      return { width: 0, height: 0, scale: 1 };
    }
    const boxWidth = boxRef.current.clientWidth;
    const boxHeight = boxRef.current.clientHeight;
    const boxRatio = boxWidth / boxHeight;
    const imageWidth = image.width;
    const imageHeight = image.height;
    const imageRatio = imageWidth / imageHeight;
    if (boxRatio > imageRatio) {
      const scale = boxHeight / imageHeight;
      return {
        imageWidth,
        imageHeight,
        width: imageWidth * scale,
        height: boxHeight,
        scale,
      };
    } else {
      const scale = boxWidth / imageWidth;
      return {
        imageWidth,
        imageHeight,
        width: boxWidth,
        height: imageHeight * scale,
        scale,
      };
    }
  }, [image, boxRef.current]);
  const burshSize = useMemo(() => {
    return BRUSH_SIZES[brushScale];
  }, [brushScale]);

  const [isDrawing, setIsDrawing] = useState(false);
  const [isGrabbing, setIsGrabbing] = useState(false);
  const [grabPos, setGrabPos] = useState({
    startX: 0,
    startY: 0,
    left: 0,
    top: 0,
  });

  function handleMouseDown(e) {
    const pos = e.target.getStage().getPointerPosition();
    if (cursor === 1) {
      setIsDrawing(true);
      const f = (d) => [...d, { size: burshSize, points: [pos.x, pos.y] }];
      setData(f);
    } else if (cursor === 2) {
      setIsGrabbing(true);
      setGrabPos((p) => {
        return {
          ...p,
          startX: pos.x,
          startY: pos.y,
        };
      });
    }
  }
  function handleMouseMove(e) {
    const pos = e.target.getStage().getPointerPosition();
    if (cursor === 1) {
      if (!isDrawing) return;
      const f = (d) => {
        const lastPath = d[d.length - 1];
        lastPath.points = lastPath.points.concat([pos.x, pos.y]);
        return [...d];
      };
      setData(f);
    } else if (cursor === 2) {
      if (!isGrabbing) return;
      setGrabPos((p) => {
        const { startX, startY, left, top } = p;
        const dx = pos.x - startX;
        const dy = pos.y - startY;
        return {
          ...p,
          left: left + dx,
          top: top + dy,
        };
      });
    }
  }
  function handleMouseUp() {
    if (cursor === 1) {
      setIsDrawing(false);
    } else if (cursor === 2) {
      setIsGrabbing(false);
    }
  }

  function onClear() {
    setData([]);
  }
  useEffect(() => {
    if (clearCount) onClear();
  }, [clearCount]);

  function onReset() {
    setGrabPos({
      startX: 0,
      startY: 0,
      left: 0,
      top: 0,
    });
  }
  useEffect(() => {
    if (resetCount) onReset();
  }, [resetCount]);

  const drawRef = useRef(null);
  useEffect(() => {
    setDrawRef(drawRef.current);
  }, [drawRef.current]);

  const cursorStyle = useMemo(() => {
    if (cursor === 1) {
      return `url(${BrushIco}) 8 16, auto`;
    } else if (cursor === 2) {
      if (isGrabbing) return 'grabbing';
      else return 'grab';
    }
    return 'auto';
  }, [cursor, isGrabbing]);

  const lineColor = useMemo(() => {
    if (func === 1) {
      return BRUSH_COLOR;
    } else if (func === 2) {
      return PURGE_COLOR;
    } else {
      return BRUSH_COLOR;
    }
  }, [func]);

  return (
    <div className={style.editorBox} ref={boxRef}>
      <Stage
        className={style.editorStage}
        width={contain.width}
        height={contain.height}
        onMouseDown={handleMouseDown}
        onMousemove={handleMouseMove}
        onMouseup={handleMouseUp}
        onMouseLeave={handleMouseUp}
        style={{
          position: 'relative',
          left: `${grabPos.left}px`,
          top: `${grabPos.top}px`,
          transform: `scale(${scale})`,
          cursor: cursorStyle,
        }}
      >
        <Layer>
          {image && (
            <KonvaImage
              image={image}
              width={contain.width}
              height={contain.height}
            />
          )}
        </Layer>
        <Layer>
          {data.map((e, i) => (
            <PackageAiLine data={e} key={i} posScale={1} color={lineColor} />
          ))}
        </Layer>
      </Stage>
      <Stage
        width={contain.imageWidth}
        height={contain.imageHeight}
        style={{ display: 'none' }}
      >
        <Layer ref={drawRef}>
          <Rect
            width={contain.imageWidth}
            height={contain.imageHeight}
            fill="#000"
          />
          {data.map((e, i) => (
            <PackageAiLine
              data={e}
              key={i}
              posScale={contain.scale}
              color="#fff"
            />
          ))}
        </Layer>
      </Stage>
    </div>
  );
}

export default PackageAiEditor;
