import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';

const RootContext = createContext(null);

export const RootProvider = ({children}) => {
  const [ isExpansion, setIsExpansion ] = useState(false);
  const [ chatList, setChatList ] = useState([]);
  const [ range, setRange ] = useState('full');
  const [ loading, setLoading ] = useState(false);
  const [ sessionId, setSessionId] = useState('');
  const [ isRetryAsk, setIsRetryAsk ] = useState(false);

  // preview video
  const [ videoUrl, setVideoUrl ] = useState('');
  const [ openPreviewWin, setOpenPreviewWin] = useState(false);

  /**
   * add new chat data
   * type: answer, question
   */
  const pushChatList = useCallback((type, data) => {
    const chatObj = {
      type,
      createTime: new Date().getTime(),
      data
    };
    const tempChatList = [...chatList];
    tempChatList.push(chatObj);
    setChatList(tempChatList);
  }, [chatList]);

  // scroll page end
  useEffect(() => {
    const elementObj = document.getElementById('customer_ai_chat');
    if(!elementObj) return;
    const height = elementObj.scrollHeight;
    elementObj.scrollTo({
      top: height,
      behavior: 'smooth'
    });
  }, [chatList.length]);

  return <RootContext.Provider value={{
    isExpansion,
    chatList,
    range,
    loading,
    sessionId,
    videoUrl,
    openPreviewWin,
    isRetryAsk,

    setIsExpansion,
    pushChatList,
    setRange,
    setLoading,
    setSessionId,
    setVideoUrl,
    setOpenPreviewWin,
    setIsRetryAsk,
    setChatList
  }}>
    {children}
  </RootContext.Provider>;
};

export const useRoot = () => {
  return useContext(RootContext) || {
    isExpansion: false,
    chatList: [],
    range: 'full',
    loading: false,
    sessionId: '',
    videoUrl: '',
    openPreviewWin: false,
    isRetryAsk: false,

    setIsExpansion: () => {},
    pushChatList: () => {},
    setRange: () => {},
    setLoading: () => {},
    setSessionId: () => {},
    setVideoUrl: () => {},
    setOpenPreviewWin: () => {},
    setIsRetryAsk: () => {},
    setChatList: () => {}
  };
};