import { CloseCircleOutlined } from '@ant-design/icons';
import React from 'react';
import Box from './box2.jsx';
import style from './dialog.module.scss';

function PackageAiDialog({
  className = '',
  children,
  visible,
  setVisible,
  hasBox = true,
}) {
  return (
    <div
      className={style.dialogContainer}
      style={{ display: visible ? 'block' : 'none' }}
    >
      <div
        className={style.dialogModal}
        onClick={() => setVisible(false)}
      ></div>
      <Box className={`${style.dialogBox} ${className}`} hasBox={hasBox}>
        {children}
        <div className={style.closeBtn} onClick={() => setVisible(false)}>
          <CloseCircleOutlined
            className={style.closeIcon}
            size={32}
          ></CloseCircleOutlined>
        </div>
      </Box>
    </div>
  );
}

export default PackageAiDialog;
