import React from 'react';
import style from './border-container.module.scss';
import classNames from 'classnames';
import borderCorner from '../../../..//assets/images/ratings-review/border-corner.svg';
import { Affix } from 'antd';

function BorderContainer({ headerTitle, children, headerChildren }) {
  return (
    <div className={style.lightBorderContainer}>
      <img className={classNames(style.borderSvg, style.borderSvg2)} src={borderCorner} alt="" />
      <img className={classNames(style.borderSvg, style.borderSvg3)} src={borderCorner} alt="" />
      <img className={classNames(style.borderSvg, style.borderSvg4)} src={borderCorner} alt="" />
      <Affix>
        <div className={style.commonHeader}>
          <div className={classNames(style.headerTitle)}>{ headerTitle }</div>
          <div className={style.rightBox}>{headerChildren}</div>
        </div>
      </Affix>
      <div className={classNames(style.borderBoxContent)}>
        {children}
      </div>
    </div>
  );
}

export default BorderContainer;