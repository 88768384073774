import { Col, message, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { putFileKey } from '../../apis/file';
import Box from '../../components/box2.jsx';
import style from './brand2.module.scss';
import ImgDialog from '../../components/imgDialog.jsx';
import Loading from '../../components/loading.jsx';

function ImageItem({ className = '', title = '', url, onClick }) {
  function onImgClick() {
    onClick && onClick(url);
  }
  return (
    <div className={className}>
      <div className={style.imageItemTitle}>{title}</div>
      <div className={style.imageItemContent} onClick={onImgClick}>
        <img src={url} alt={title} />
      </div>
    </div>
  );
}

function PackageAiBody({ useNextBtn, context: ctx }) {
  // image
  const [data1, setData1] = useState([]);
  const [data2, setData2] = useState([]);

  const [loading, setLoading] = useState(true);
  async function init() {
    try {
      const pp = ctx?.res?.predictPackage;
      if (!pp) return;
      const variants = ctx?.res?.variants;
      if (!variants) return;
      const list = [];
      const list2 = [];

      const processValue = async (name, fileKey) => {
        if (!fileKey) return;
        const { data } = await putFileKey(fileKey);
        list.push({ title: name, url: data.url });
      };
      const processValue2 = async (name, fileKey) => {
        if (!fileKey) return;
        const { data } = await putFileKey(fileKey);
        list2.push({ title: name, url: data.url });
      };

      // ul brand
      const uld0v = variants[pp.ul_design0_variant0_id];
      await processValue(`${pp.brand_ul} Current`, uld0v?.sequenceimage);
      const uld1v = variants[pp.ul_design1_variant0_id];
      await processValue(`${pp.brand_ul} New Design`, uld1v?.sequenceimage);
      const uld2v = variants[pp.ul_design2_variant0_id];
      await processValue(`${pp.brand_ul} New Design 2`, uld2v?.sequenceimage);
      const uld3v = variants[pp.ul_design3_variant0_id];
      await processValue(`${pp.brand_ul} New Design 3`, uld3v?.sequenceimage);
      setData1(list);
      // c1 brand
      const c1d1v = variants[pp.c1_design1_variant0_id];
      await processValue2(`${pp.brand_c1}`, c1d1v?.sequenceimage);
      // c2 brand
      const c2d1v = variants[pp.c2_design1_variant0_id];
      await processValue2(`${pp.brand_c2}`, c2d1v?.sequenceimage);
      setData2(list2);
    } catch (err) {
      console.error('init error', err);
      message.error('Failed to load data');
    }
    setLoading(false);
  }
  useEffect(() => {
    void init();
  }, [ctx]);

  const [dialogVisible, setDialogVisible] = useState(false);
  const [dialogUrl, setDialogUrl] = useState('');
  function onImgClick(url) {
    setDialogVisible(true);
    setDialogUrl(url);
  }

  return (
    <div className={style.box1}>
      {loading && <Loading />}
      <Row gutter={36}>
        <Col span={20}>
          <div className={style.box11}>
            {data1.map((item, index) => {
              return (
                <ImageItem
                  key={`${item.title}-${index}`}
                  className={style.box11Item}
                  title={item.title}
                  url={item.url}
                  onClick={onImgClick}
                />
              );
            })}
            <div className={style.box11Item1} />
            {data2.map((item, index) => {
              return (
                <ImageItem
                  key={`${item.title}-${index}`}
                  className={style.box11Item}
                  title={item.title}
                  url={item.url}
                  onClick={onImgClick}
                />
              );
            })}
          </div>
        </Col>
        <Col span={4}>
          <div className={style.box12}>
            <Box>
              <div className={style.box121}>
                <div className={style.box12Title}>
                  Based on Metrixlab Pack Meta learnings, the optimal design
                  hierarchy:
                </div>
                <ul className={style.box12List}>
                  <li>brand</li>
                  <li>variant</li>
                  <li>RTB</li>
                  <li>emotive promise visualization</li>
                  <li>pack content</li>
                  <li>call-to-action</li>
                </ul>
              </div>
            </Box>
          </div>
        </Col>
      </Row>
      <div className={style.btnBox}>{useNextBtn && useNextBtn({})}</div>
      <ImgDialog
        visible={dialogVisible}
        setVisible={setDialogVisible}
        src={dialogUrl}
      />
    </div>
  );
}

export default PackageAiBody;
