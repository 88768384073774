// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PfnEg1C51sN2N79khP8u{height:100%;display:flex;flex-direction:column}.HzjPsIlWq0oVErCrWAbw{margin-top:25px;flex:1;overflow:hidden}.yfsTh6xKR_2rlYqd1tWU{height:100%;display:flex;flex-direction:column;padding:15px 25px 0}.kftq4jDEllrbWu8m7WoC{flex:1;overflow-x:hidden;overflow-y:auto;display:flex;flex-wrap:wrap}.V9OFy31TCaIkIXWZngUZ{margin-right:25px;margin-bottom:25px}.M3MI8MtgwA1p8sVddzpv{margin-top:30px;display:flex;flex-direction:column;align-items:end}`, "",{"version":3,"sources":["webpack://./src/modules/package-ai-screening-batch/views/aoi/index.module.scss"],"names":[],"mappings":"AAAA,sBACE,WAAA,CACA,YAAA,CACA,qBAAA,CAGF,sBACE,eAAA,CACA,MAAA,CACA,eAAA,CAGF,sBACE,WAAA,CACA,YAAA,CACA,qBAAA,CACA,mBAAA,CAGF,sBACE,MAAA,CACA,iBAAA,CACA,eAAA,CACA,YAAA,CACA,cAAA,CAGF,sBACE,iBAAA,CACA,kBAAA,CAGF,sBACE,eAAA,CACA,YAAA,CACA,qBAAA,CACA,eAAA","sourcesContent":[".containerInner {\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n}\n\n.box {\n  margin-top: 25px;\n  flex: 1;\n  overflow: hidden;\n}\n\n.boxInner {\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n  padding: 15px 25px 0;\n}\n\n.imgList {\n  flex: 1;\n  overflow-x: hidden;\n  overflow-y: auto;\n  display: flex;\n  flex-wrap: wrap;\n}\n\n.imgItem {\n  margin-right: 25px;\n  margin-bottom: 25px;\n}\n\n.btnsBox {\n  margin-top: 30px;\n  display: flex;\n  flex-direction: column;\n  align-items: end;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"containerInner": `PfnEg1C51sN2N79khP8u`,
	"box": `HzjPsIlWq0oVErCrWAbw`,
	"boxInner": `yfsTh6xKR_2rlYqd1tWU`,
	"imgList": `kftq4jDEllrbWu8m7WoC`,
	"imgItem": `V9OFy31TCaIkIXWZngUZ`,
	"btnsBox": `M3MI8MtgwA1p8sVddzpv`
};
export default ___CSS_LOADER_EXPORT___;
