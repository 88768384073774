import React, { useEffect, useState,forwardRef } from 'react';
import ReactSlider from 'react-slider';

const IndexScoreFilter = forwardRef(({ sortOrder,setSelectedIndexScoreRange, initialSelectedRanges,handleSort },ref) => {
  const [minScore, setMinScore] = useState(() => {
    return initialSelectedRanges?.[0]?.split('-')[0] || 1;
  });
  const [maxScore, setMaxScore] = useState(() => {
    return initialSelectedRanges?.[0]?.split('-')[1] || 100;
  });
  const [selectedRanges, setSelectedRanges] = useState(initialSelectedRanges);

  useEffect(() => {
    const range = `${minScore}-${maxScore}`;
    setSelectedRanges([range]);
  }, [minScore, maxScore]);

  useEffect(() => {
    setSelectedIndexScoreRange(selectedRanges);
  }, [selectedRanges, setSelectedIndexScoreRange]);

  const handleSliderChange = (values) => {
    const [min, max] = values;
    setMinScore(min);
    setMaxScore(max);
  };

  const handleMinScoreChange = (event) => {
    const value = Number(event.target.value);
    if (value < maxScore && value >= 1) {
      setMinScore(value);
    }
  };

  const handleMaxScoreChange = (event) => {
    const value = Number(event.target.value);
    if (value > minScore && value <= 100) {
      setMaxScore(value);
    }
  };

  const handleKeyDown = (event, type) => {
    if (event.key === 'ArrowUp') {
      event.preventDefault();
      if (type === 'min') {
        const newMin = Math.min(minScore + 1, maxScore);
        setMinScore(newMin);
      } else {
        const newMax = Math.min(maxScore + 1, 100);
        setMaxScore(newMax);
      }
    } else if (event.key === 'ArrowDown') {
      event.preventDefault();
      if (type === 'min') {
        const newMin = Math.max(minScore - 1, 1);
        setMinScore(newMin);
      } else {
        const newMax = Math.max(maxScore - 1, minScore);
        setMaxScore(newMax);
      }
    }
  };

  
  const handleOptionChange = (e) => {
    const newSortOrder = e.target.value;
    handleSort(newSortOrder);
  };

  return (
    <div className="indexscorefilter"style={{width:'380px',height:'200px'}}>
      <ReactSlider
        className="custom-slider"
        thumbClassName="custom-slider-thumb"
        trackClassName="custom-slider-track"
        value={[minScore, maxScore]}
        min={1}
        max={100}
        onChange={handleSliderChange}
        pearling
        minDistance={1}
      />
      <div className='input-container'>
        <label>
          <input
            className='index-score-input'
            type="number"
            min="1"
            max="100"
            value={minScore}
            onChange={handleMinScoreChange}
            onKeyDown={(e) => handleKeyDown(e, 'min')}
          />
        </label> 
        <div className='to-range'>to</div>
        <label>
          <input
            className='index-score-input'
            type="number"
            min="1"
            max="100"
            value={maxScore}
            onChange={handleMaxScoreChange}
            onKeyDown={(e) => handleKeyDown(e, 'max')}
          />
        </label>
    <div className="interiorSort" ref={ref}>
      <label>
      High to Low
      <input
          type="radio"
          name="sort"
          value="high-to-low"
          checked={sortOrder === 'high-to-low'}
          onChange={handleOptionChange}
        />
      </label>
      <label>
        Low to High
        <input
          type="radio"
          name="sort"
          value="low-to-high"
          checked={sortOrder === 'low-to-high'}
          onChange={handleOptionChange}
        />
      </label>
      <label>
        As Is
        <input
          type="radio"
          name="sort"
          value="as-is"
          className='asIsButton'
          checked={sortOrder === 'as-is'}
          onChange={handleOptionChange}
        />
      </label>
    </div>
      </div>
    </div>
  );
});

export default IndexScoreFilter;
