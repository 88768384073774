/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useCallback } from 'react';
import style from './filter-tool.module.scss';
import { DatePicker, Select, Tooltip, Divider, Button } from 'antd';
import { DownOutlined, ExclamationCircleOutlined, LoadingOutlined } from '@ant-design/icons';
import moment from 'moment';
import dayjs from 'dayjs';
import { fetchSearchOptions } from '../../api/request.js';

let requestIndexMarket = Date.now();
let requestIndexRetailer = Date.now();
let requestIndexCategory = Date.now();
let requestIndexFormat = Date.now();
let requestIndexBrand = Date.now();
let requestIndexPackage = Date.now();
let requestIndexProduct = Date.now();

function debounce (callback, delay) {
  let lastTime;
  return function () {
    clearTimeout(lastTime);
    const [that, args] = [this, arguments];
    lastTime = setTimeout(() => {
      callback.apply(that, args);
    }, delay);
  };
}

const MAX_COUNT = 3;

const FilterTool = ({putParams}) => {

  const [dateFrom, setDateFrom] = useState(null);
  const [dateTo, setDateTo] = useState(null);

  const disabledDateFrom = (current) => {
    return current && current > moment(dateTo).add(1, 'days');
  };
  const disabledDateTo = (current) => {
    return current && current < moment(dateFrom);
  };

  const fromDateChange = (_, value) => {
    setDateFrom(value);
    putParams({
      start_at: value,
      end_at: dateTo,
      product_id: product,
      brand: brand,
      market: market,
      retailer: retailer,
      // phyPackage: phyPackage
      format: format
    });
  };

  const toDateChange = (_, value) => {
    setDateTo(value);
    putParams({
      start_at: dateFrom,
      end_at: value,
      product_id: product,
      brand: brand,
      market: market,
      retailer: retailer,
      // phyPackage: phyPackage
      format: format
    });
  };

  const refreshBtn = ({loading, success, onClick}) => {
    return <>
      { loading ? <LoadingOutlined /> : 
        success ? <DownOutlined /> : <Tooltip title="Interface error, click Rerequest">
          <ExclamationCircleOutlined style={{color: '#eb0000'}} onClick={onClick} />
        </Tooltip>
      }
    </>;
  };

  // clear all
  const handleClearAll = () => {
    handleChangeMarket([], []);
    setDateFrom(null);
    setDateTo(null);
    putParams({
      start_at: '',
      end_at: '',
      product_id: [],
      brand: [],
      market: [],
      retailer: [],
      // phyPackage: []
      format: []
    });
  };

  // Market
  const [marketOpen, setMarketOpen] = useState(false);
  const [marketSuccess, setMarketSuccess] = useState(true);
  const [marketLoading, setMarketLoading] = useState(false);
  const [marketOptions, setMarketOptions] = useState([]);
  const [market, setMarket] = useState([]);
  const [marketPaths, setMarketPaths] = useState([]);
  const fetchMarket = () => {
    setMarketLoading(true);
    fetchSearchOptions({}, requestIndexMarket)
      .then(res => {
        // index
        if (requestIndexMarket !== res.config.uuid) return;
        const mapObj = {};
        if (Array.isArray(res.data.data)) {
          res.data.data.forEach(item => {
            if (!mapObj[item.name]) {
              mapObj[item.name] = {
                label: item.name,
                value: item.name,
                values: []
              };
            }
            mapObj[item.name].values.push(item);
          });
        }
        const arr = Object.keys(mapObj).map(key => mapObj[key]);
        setMarketOptions(arr);
        setMarketLoading(false);
        setMarketSuccess(true);
      })
      .catch(error => {
        // index
        if (requestIndexMarket !== error.config.uuid) return;
        setMarketLoading(false);
        setMarketSuccess(false);
        console.error(error);
      });
  };
  useEffect(() => {
    fetchMarket();
  }, []);
  const handleChangeMarket = (val, op) => {
    setMarket(val);
    // 值清空
    setRetailer([]);
    setCategory([]);
    setFormat([]);
    setBrand([]);
    setPackage([]);
    setProduct([]);
    // options清空
    setRetailerOptions([]);
    setCategoryOptions([]);
    setFormatOptions([]);
    setBrandOptions([]);
    setPackageOptions([]);
    setProductOptions([]);
    // 获取 Retailer options
    requestIndexRetailer++;
    const paths = [];
    op?.forEach(item => {
      item.values.forEach(source => {
        paths.push(source.value);
      });
    });
    setMarketPaths(paths);
    delayFetchRetailer({paths: paths});
  };
  const suffixMarket = refreshBtn({
    loading: marketLoading, 
    success: marketSuccess, 
    onClick: fetchMarket
  });
  const marketSelectAll = () => {
    handleChangeMarket(marketOptions.map(item => item.value), marketOptions);
    setMarketOpen(false);
  };

  // Retailer
  const [retailerOpen, setRetailerOpen] = useState(false);
  const [retailerSuccess, setRetailerSuccess] = useState(true);
  const [retailerLoading, setRetailerLoading] = useState(false);
  const [retailerOptions, setRetailerOptions] = useState([]);
  const [retailer, setRetailer] = useState([]);
  const [retailerPaths, setRetailerPaths] = useState([]);
  const fetchRetailer = ({paths}) => {
    if (paths.length === 0) {
      setRetailerLoading(false);
      return;
    }
    setRetailerLoading(true);
    // index
    fetchSearchOptions({paths}, requestIndexRetailer)
      .then(res => {
        // index
        if (requestIndexRetailer !== res.config.uuid) return;
        const mapObj = {};
        if (Array.isArray(res.data.data)) {
          res.data.data.forEach(item => {
            if (!mapObj[item.name]) {
              mapObj[item.name] = {
                label: item.name,
                value: item.name,
                values: []
              };
            }
            mapObj[item.name].values.push(item);
          });
        }
        const arr = Object.keys(mapObj).map(key => mapObj[key]);
        // options
        setRetailerOptions(arr);
        setRetailerLoading(false);
        setRetailerSuccess(true);
      })
      .catch(error => {
        // index
        if (requestIndexRetailer !== error.config.uuid) return;
        setRetailerLoading(false);
        setRetailerSuccess(false);
        console.error(error);
      });
  };
  const delayFetchRetailer = useCallback(debounce(function(val) { return fetchRetailer(val);}, 500), []);
  const handleChangeRetailer = (val, op) => {
    setRetailer(val);
    // 值清空
    setCategory([]);
    setFormat([]);
    setBrand([]);
    setPackage([]);
    setProduct([]);
    // options清空
    setCategoryOptions([]);
    setFormatOptions([]);
    setBrandOptions([]);
    setPackageOptions([]);
    setProductOptions([]);
    // 获取 Category options
    // index
    requestIndexCategory++;
    const paths = [];
    op?.forEach(item => {
      item.values.forEach(source => {
        paths.push(source.value);
      });
    });
    setRetailerPaths(paths);
    delayFetchCategory({paths: paths});
  };
  const suffixRetailer = refreshBtn({
    loading: retailerLoading, 
    success: retailerSuccess, 
    onClick: () => {
      requestIndexRetailer++;
      delayFetchRetailer({paths: marketPaths});
    }});
  const retailerSelectAll = () => {
    handleChangeRetailer(retailerOptions.map(item => item.value), retailerOptions);
    setRetailerOpen(false);
  };

  // Category
  const [categoryOpen, setCategoryOpen] = useState(false);
  const [categorySuccess, setCategorySuccess] = useState(true);
  const [categoryLoading, setCategoryLoading] = useState(false);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [category, setCategory] = useState([]);
  const [categoryPaths, setCategoryPaths] = useState([]);
  const fetchCategory = ({paths}) => {
    if (paths.length === 0) {
      setCategoryLoading(false);
      return;
    }
    setCategoryLoading(true);
    // index
    fetchSearchOptions({paths}, requestIndexCategory)
      .then(res => {
        // index
        if (requestIndexCategory !== res.config.uuid) return;
        const mapObj = {};
        if (Array.isArray(res.data.data)) {
          res.data.data.forEach(item => {
            if (!mapObj[item.name]) {
              mapObj[item.name] = {
                label: item.name,
                value: item.name,
                values: []
              };
            }
            mapObj[item.name].values.push(item);
          });
        }
        const arr = Object.keys(mapObj).map(key => mapObj[key]);
        // options
        setCategoryOptions(arr);
        setCategoryLoading(false);
        setCategorySuccess(true);
      })
      .catch(error => {
        // index
        if (requestIndexCategory !== error.config.uuid) return;
        setCategoryLoading(false);
        setCategorySuccess(false);
        console.error(error);
      });
  };
  const delayFetchCategory = useCallback(debounce(function(val) { return fetchCategory(val);}, 500), []);
  const handleChangeCategory = (val, op) => {
    setCategory(val);
    // 值清空
    setFormat([]);
    setBrand([]);
    setPackage([]);
    setProduct([]);
    // options清空
    setFormatOptions([]);
    setBrandOptions([]);
    setPackageOptions([]);
    setProductOptions([]);
    // 获取 Format options
    // index
    requestIndexFormat++;
    const paths = [];
    op?.forEach(item => {
      item.values.forEach(source => {
        paths.push(source.value);
      });
    });
    setCategoryPaths(paths);
    delayFetchFormat({paths: paths});
  };
  const suffixCategory = refreshBtn({
    loading: categoryLoading, 
    success: categorySuccess, 
    onClick: () => {
      requestIndexCategory++;
      delayFetchCategory({paths: retailerPaths});
    }});
  const categorySelectAll = () => {
    handleChangeCategory(categoryOptions.map(item => item.value), categoryOptions);
    setCategoryOpen(false);
  };

  // Sub-Category
  const [formatOpen, setFormatOpen] = useState(false);
  const [formatSuccess, setFormatSuccess] = useState(true);
  const [formatLoading, setFormatLoading] = useState(false);
  const [formatOptions, setFormatOptions] = useState([]);
  const [format, setFormat] = useState([]);
  const [formatPaths, setFormatPaths] = useState([]);
  const fetchFormat = ({paths}) => {
    if (paths.length === 0) {
      setFormatLoading(false);
      return;
    }
    setFormatLoading(true);
    // index
    fetchSearchOptions({paths}, requestIndexFormat)
      .then(res => {
        // index
        if (requestIndexFormat !== res.config.uuid) return;
        const mapObj = {};
        if (Array.isArray(res.data.data)) {
          res.data.data.forEach(item => {
            if (!mapObj[item.name]) {
              mapObj[item.name] = {
                label: item.name,
                value: item.name,
                values: []
              };
            }
            mapObj[item.name].values.push(item);
          });
        }
        const arr = Object.keys(mapObj).map(key => mapObj[key]);
        // options
        setFormatOptions(arr);
        setFormatLoading(false);
        setFormatSuccess(true);
      })
      .catch(error => {
        // index
        if (requestIndexFormat !== error.config.uuid) return;
        setFormatLoading(false);
        setFormatSuccess(false);
        console.error(error);
      });
  };
  const delayFetchFormat = useCallback(debounce(function(val) { return fetchFormat(val);}, 500), []);
  const handleChangeFormat = (val, op) => {
    setFormat(val);
    // 值清空
    setBrand([]);
    setPackage([]);
    setProduct([]);
    // options清空
    setBrandOptions([]);
    setPackageOptions([]);
    setProductOptions([]);
    
    requestIndexBrand++;
    const paths = [];
    op?.forEach(item => {
      item.values.forEach(source => {
        paths.push(source.value);
      });
    });
    setFormatPaths(paths);
    delayFetchBrand({paths: paths});
  };
  const suffixFormat = refreshBtn({
    loading: formatLoading, 
    success: formatSuccess, 
    onClick: () => {
      requestIndexFormat++;
      delayFetchFormat({paths: categoryPaths});
    }});
  const formatSelectAll = () => {
    handleChangeFormat(formatOptions.map(item => item.value), formatOptions);
    setFormatOpen(false);
  };

  // Brand
  const [brandSuccess, setBrandSuccess] = useState(true);
  const [brandLoading, setBrandLoading] = useState(false);
  const [brandOptions, setBrandOptions] = useState([]);
  const [brand, setBrand] = useState([]);
  const [brandPaths, setBrandPaths] = useState([]);
  const fetchBrand = ({paths}) => {
    if (paths.length === 0) {
      setBrandLoading(false);
      return;
    }
    setBrandLoading(true);
    // index
    fetchSearchOptions({paths}, requestIndexBrand)
      .then(res => {
        // index
        if (requestIndexBrand !== res.config.uuid) return;
        const mapObj = {};
        if (Array.isArray(res.data.data)) {
          res.data.data.forEach(item => {
            if (!mapObj[item.name]) {
              mapObj[item.name] = {
                label: item.name,
                value: item.name,
                values: []
              };
            }
            mapObj[item.name].values.push(item);
          });
        }
        const arr = Object.keys(mapObj).map(key => mapObj[key]);
        // options
        setBrandOptions(arr);
        setBrandLoading(false);
        setBrandSuccess(true);
      })
      .catch(error => {
        // index
        if (requestIndexBrand !== error.config.uuid) return;
        setBrandLoading(false);
        setBrandSuccess(false);
        console.error(error);
      });
  };
  const delayFetchBrand = useCallback(debounce(function(val) { return fetchBrand(val);}, 500), []);
  const handleChangeBrand = (val, op) => {
    setBrand(val);
    // 值清空
    setPackage([]);
    setProduct([]);
    // options清空
    setPackageOptions([]);
    setProductOptions([]);
    // 获取 product options

    /******** 暂时去掉phyPackage ********/
    // index
    // requestIndexPackage++;
    // const paths = [];
    // op?.forEach(item => {
    //   item.values.forEach(source => {
    //     paths.push(source.value);
    //   });
    // });
    // putParams({
    //   start_at: dateFrom,
    //   end_at: dateTo,
    //   product_id: [],
    //   brand: val,
    //   market: market,
    //   retailer: retailer,
    //   phyPackage: phyPackage
    // });
    // setBrandPaths(paths);
    // const params = {
    //   paths: paths
    // };
    // delayFetchPackage(params);
    /******** 暂时去掉phyPackage ********/
    requestIndexProduct++;
    const paths = [];
    op?.forEach(item => {
      item.values.forEach(source => {
        paths.push(source.value);
      });
    });
    setBrandPaths(paths);
    putParams({
      start_at: dateFrom,
      end_at: dateTo,
      product_id: [],
      brand: val,
      market: market,
      retailer: retailer,
      format: format
    });
    const params = {
      paths: paths,
      filter_type: 'product'
    };
    delayFetchProduct(params);

  };
  const suffixBrand = refreshBtn({
    loading: brandLoading, 
    success: brandSuccess, 
    onClick: () => {
      requestIndexBrand++;
      delayFetchBrand({paths: formatPaths});
    }});

  // Physical Package
  const [packageOpen, setPackageOpen] = useState(false);
  const [packageSuccess, setPackageSuccess] = useState(true);
  const [packageLoading, setPackageLoading] = useState(false);
  const [packageOptions, setPackageOptions] = useState([]);
  const [phyPackage, setPackage] = useState([]);
  const [packagePaths, setPackagePaths] = useState([]);
  const fetchPackage = ({paths}) => {
    if (paths.length === 0) {
      setPackageLoading(false);
      return;
    }
    setPackageLoading(true);
    // index
    fetchSearchOptions({paths}, requestIndexPackage)
      .then(res => {
        // index
        if (requestIndexPackage !== res.config.uuid) return;
        const mapObj = {};
        if (Array.isArray(res.data.data)) {
          res.data.data.forEach(item => {
            if (!mapObj[item.name]) {
              mapObj[item.name] = {
                label: item.name,
                value: item.name,
                values: []
              };
            }
            mapObj[item.name].values.push(item);
          });
        }
        const arr = Object.keys(mapObj).map(key => mapObj[key]);
        // options
        setPackageOptions(arr);
        setPackageLoading(false);
        setPackageSuccess(true);
      })
      .catch(error => {
        // index
        if (requestIndexPackage !== error.config.uuid) return;
        setPackageLoading(false);
        setPackageSuccess(false);
        console.error(error);
      });
  };
  const delayFetchPackage = useCallback(debounce(function(val) { return fetchPackage(val);}, 500), []);
  const handleChangePackage = (val, op) => {
    setPackage(val);
    // 值清空
    setProduct([]);
    // options清空
    setProductOptions([]);
    // 获取 product options
    // index
    requestIndexProduct++;
    const paths = [];
    op?.forEach(item => {
      item.values.forEach(source => {
        paths.push(source.value);
      });
    });
    setPackagePaths(paths);
    putParams({
      start_at: dateFrom,
      end_at: dateTo,
      product_id: [],
      brand: brand,
      market: market,
      retailer: retailer,
      phyPackage: val,
      format: format
    });
    const params = {
      paths: paths,
      filter_type: 'product'
    };
    delayFetchProduct(params);
  };

  const suffixPackage = refreshBtn({
    loading: packageLoading, 
    success: packageSuccess, 
    onClick: () => {
      requestIndexPackage++;
      delayFetchPackage({paths: brandPaths});
    }});
  const packageSelectAll = () => {
    handleChangePackage(packageOptions.map(item => item.value), packageOptions);
    setPackageOpen(false);
  };

  // Product
  const [productSuccess, setProductSuccess] = useState(true);
  const [productLoading, setProductLoading] = useState(false);
  const [productOptions, setProductOptions] = useState([]);
  const [product, setProduct] = useState([]);
  const handleChangeProduct = (val) => {
    setProduct(val);
    putParams({
      start_at: dateFrom,
      end_at: dateTo,
      product_id: val,
      brand: brand,
      market: market,
      retailer: retailer,
      // phyPackage: phyPackage
      format: format
    });
  };
  const fetchProduct = ({paths, filter_type}) => {
    if (paths.length === 0) {
      setProductLoading(false);
      return;
    }
    setProductLoading(true);
    // index
    fetchSearchOptions({paths, filter_type}, requestIndexProduct)
      .then(res => {
        // index
        if (requestIndexProduct !== res.config.uuid) return;
        const arr = Array.isArray(res.data.data) ? res.data.data.map(item => {
          return {
            ...item,
            label: item.product_name,
            value: item.id,
          };
        }) : [];
        // options
        setProductOptions(arr);
        setProductLoading(false);
        setProductSuccess(true);
      })
      .catch(error => {
        // index
        if (requestIndexProduct !== error.config.uuid) return;
        setProductLoading(false);
        setProductSuccess(false);
        console.error(error);
      });
  };
  const delayFetchProduct = useCallback(debounce(function(val) { return fetchProduct(val);}, 500), []);
  /******** 暂时去掉phyPackage ********/
  // const suffixProduct = refreshBtn({
  //   loading: productLoading, 
  //   success: productSuccess, 
  //   onClick: () => {
  //     requestIndexProduct++;
  //     delayFetchProduct({paths: packagePaths, filter_type: 'product'});
  //   }});
  /******** 暂时去掉phyPackage ********/
  const suffixProduct = refreshBtn({
    loading: productLoading, 
    success: productSuccess, 
    onClick: () => {
      requestIndexProduct++;
      delayFetchProduct({paths: brandPaths, filter_type: 'product'});
    }});

  return (
    <div className={style.toolBox}>
      <div className={style.line}></div>
      <div className={style.filterHeader}>
        <div className={style.filterHeaderTitle}>Filter</div>
        <Button 
          className={style.filterHeaderBtn} 
          type="primary" 
          ghost 
          size="small" 
          onClick={handleClearAll}
        >Clear Filter</Button>
      </div>
      <div className={style.toolItem}>
        <div className={style.toolItemTitle}>Date</div>
        <div className={style.toolItemContent}>
          <div className={style.contentItem}>
            <div className={style.contentItemTitle}>From</div>
            <div className={style.contentItemBox}>
              <DatePicker 
                size="small" 
                placeholder="" 
                picker="month"
                disabledDate={disabledDateFrom}
                onChange={fromDateChange} 
                value={dateFrom ? dayjs(dateFrom) : null}
              />
            </div>
          </div>
          <div className={style.contentItem}>
            <div className={style.contentItemTitle}>To</div>
            <div className={style.contentItemBox}>
              <DatePicker 
                size="small" 
                placeholder="" 
                picker="month"
                disabledDate={disabledDateTo}
                onChange={toDateChange} 
                value={dateTo ? dayjs(dateTo) : null}
              />
            </div>
          </div>
        </div>
      </div>
      <div className={style.toolItem}>
        <div className={style.toolItemTitle}>Market</div>
        <div className={style.toolItemContent}>
          <Select
            loading={marketLoading}
            showSearch
            mode="multiple"
            allowClear
            size='small'
            placeholder=""
            maxTagCount='responsive'
            maxTagTextLength={10}
            suffixIcon={suffixMarket}
            value={market}
            onChange={handleChangeMarket}
            options={marketOptions}
            open={marketOpen}
            onDropdownVisibleChange={(open) => setMarketOpen(open)}
            dropdownRender={(menu) => (
              <>
                {menu}
                {marketOptions.length > 0 && <>
                  <Divider
                    style={{
                      margin: '8px 0',
                    }}
                  />
                  <Button type="primary" size='small' onClick={marketSelectAll}>
                    Select All
                  </Button>
                </>}
              </>
            )}
          />
        </div>
      </div>
      <div className={style.toolItem}>
        <div className={style.toolItemTitle}>Retailer</div>
        <div className={style.toolItemContent}>
          <Select
            loading={retailerLoading}
            showSearch
            mode="multiple"
            allowClear
            size='small'
            placeholder=""
            maxTagCount='responsive'
            maxTagTextLength={10}
            suffixIcon={suffixRetailer}
            value={retailer}
            onChange={handleChangeRetailer}
            options={retailerOptions}
            open={retailerOpen}
            onDropdownVisibleChange={(open) => setRetailerOpen(open)}
            dropdownRender={(menu) => (
              <>
                {menu}
                {retailerOptions.length > 0 && <>
                  <Divider
                    style={{
                      margin: '8px 0',
                    }}
                  />
                  <Button type="primary" size='small' onClick={retailerSelectAll}>
                    Select All
                  </Button>
                </>}
              </>
            )}
          />
        </div>
      </div>
      <div className={style.toolItem}>
        <div className={style.toolItemTitle}>Category</div>
        <div className={style.toolItemContent}>
          <Select
            loading={categoryLoading}
            showSearch
            mode="multiple"
            allowClear
            size='small'
            placeholder=""
            maxTagCount='responsive'
            maxTagTextLength={10}
            suffixIcon={suffixCategory}
            value={category}
            onChange={handleChangeCategory}
            options={categoryOptions}
            open={categoryOpen}
            onDropdownVisibleChange={(open) => setCategoryOpen(open)}
            dropdownRender={(menu) => (
              <>
                {menu}
                {categoryOptions.length > 0 && <>
                  <Divider
                    style={{
                      margin: '8px 0',
                    }}
                  />
                  <Button type="primary" size='small' onClick={categorySelectAll}>
                    Select All
                  </Button>
                </>}
              </>
            )}
          />
        </div>
      </div>
      <div className={style.toolItem}>
        {/* format */}
        <div className={style.toolItemTitle}>Sub-Category</div>
        <div className={style.toolItemContent}>
          <Select
            loading={formatLoading}
            showSearch
            mode="multiple"
            allowClear
            size='small'
            placeholder=""
            maxTagCount='responsive'
            maxTagTextLength={10}
            suffixIcon={suffixFormat}
            value={format}
            onChange={handleChangeFormat}
            options={formatOptions}
            open={formatOpen}
            onDropdownVisibleChange={(open) => setFormatOpen(open)}
            dropdownRender={(menu) => (
              <>
                {menu}
                {formatOptions.length > 0 && <>
                  <Divider
                    style={{
                      margin: '8px 0',
                    }}
                  />
                  <Button type="primary" size='small' onClick={formatSelectAll}>
                    Select All
                  </Button>
                </>}
              </>
            )}
          />
        </div>
      </div>
      <div className={style.toolItem}>
        <div className={style.toolItemTitle}>Brand</div>
        <div className={style.toolItemContent}>
          <Select
            loading={brandLoading}
            showSearch
            mode="multiple"
            allowClear
            size='small'
            placeholder=""
            maxTagCount='responsive'
            maxTagTextLength={10}
            maxCount={MAX_COUNT}
            suffixIcon={suffixBrand}
            value={brand}
            onChange={handleChangeBrand}
            options={brandOptions}
          />
        </div>
      </div>
      {/* <div className={style.toolItem}>
        <div className={style.toolItemTitle}>Physical Package</div>
        <div className={style.toolItemContent}>
          <Select
            loading={packageLoading}
            showSearch
            mode="multiple"
            allowClear
            size='small'
            placeholder=""
            maxTagCount='responsive'
            maxTagTextLength={10}
            suffixIcon={suffixPackage}
            value={phyPackage}
            onChange={handleChangePackage}
            options={packageOptions}
            open={packageOpen}
            onDropdownVisibleChange={(open) => setPackageOpen(open)}
            dropdownRender={(menu) => (
              <>
                {menu}
                {packageOptions.length > 0 && <>
                  <Divider
                    style={{
                      margin: '8px 0',
                    }}
                  />
                  <Button type="primary" size='small' onClick={packageSelectAll}>
                    Select All
                  </Button>
                </>}
              </>
            )}
          />
        </div>
      </div> */}
      <div className={style.toolItem}>
        <div className={style.toolItemTitle}>Product</div>
        <div className={style.toolItemContent}>
          <Select
            loading={productLoading}
            showSearch
            mode="multiple"
            allowClear
            size='small'
            placeholder=""
            maxTagCount='responsive'
            maxTagTextLength={10}
            maxCount={MAX_COUNT}
            suffixIcon={suffixProduct}
            value={product}
            onChange={handleChangeProduct}
            options={productOptions}
          />
        </div>
      </div>
    </div>
  );
};

export default FilterTool;
