import React from 'react';
import style from './index.module.scss';
import { Modal } from 'antd';
import BorderBox from '../border-box/index.jsx';
import { useRoot } from '../../hooks/useRoot.jsx';
const PreviewVideo = () => {
  const { videoUrl, openPreviewWin, setOpenPreviewWin } = useRoot();
  return (
    <Modal open={openPreviewWin} onCancel={() => setOpenPreviewWin(false)} closeIcon={<div className={style.closeBtn}></div>} width="70%" footer={false} className={style.customModalWrap} destroyOnClose={true}>
      <BorderBox>
        <video src={videoUrl} controls className={style.video}></video>
      </BorderBox>
    </Modal>
  );
};

export default PreviewVideo;