import service from '../../../../util/pythonApi';

export function postPredictImage(fileKeys) {
  return service.request({
    url: '/v1/pred_image',
    method: 'post',
    data: {
      filekeys: fileKeys,
    },
  });
}
