import request from './fetch';

export function baseInfo () {
  return request({
    url: '/v1/bu-feedback/utils',
    method: 'get',
  });
}

export function getFeedback (data) {
  return request({
    url: '/v1/bu-feedback',
    method: 'get',
    params: data
  });
}

export function putFeedback (data) {
  return request({
    url: '/v1/bu-feedback',
    method: 'put',
    data
  });
}

// audit Log 
export function auditLog(data){
  return request({
    url: '/v1/auditlog',
    method: 'post',
    data
  });
}