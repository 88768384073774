import { useCallback, useState } from 'react';
import { getResourceByShopId, getWrokShopDetail, getWrokShopList } from '../api/request';

const useBasic = () => {
  // home page 
  const [workshopList, setWorkshopList] = useState([]);
  const [paging, setPaging] = useState([]);
  const [search, setSearch] = useState('');

  // intro detail
  const [introDetail, setIntroDetail] = useState({});

  // workshop resources
  const [resourcesDeail, setResourcesDeail] = useState({});

  const fnGetWrokShopList = useCallback(async (page = 1) => {
    try {
      const params = {
        search,
        page,
        page_size: 10
      };
      const result = await getWrokShopList(params);
      if(result && result.status === 'Success') {
        // pageSize, pages,
        const { data, page, total} = result.data;
        setWorkshopList(data);
        setPaging({
          page,
          total
        });
      }
    } catch(error) {
      console.log(error);
    }
  }, [search]);

  const updateSearch = (search) => {
    setSearch(search);
  };

  // get workshop detail
  const fnGetWrokShopDetail = useCallback(async (id) => {
    try {
      const result = await getWrokShopDetail(id);
      if(result && result.status === 'Success') {
        setIntroDetail(result.data);
      }
    } catch(error) {
      console.log(error);
    }
  }, []);

  // get workshop resources
  const fnGetResourceByShopId = useCallback(async (id) => {
    try {
      const result = await getResourceByShopId(id);
      if(result && result.status === 'Success') {
        setResourcesDeail(result.data);
      }
    } catch(error) {
      console.log(error);
    }
  }, []);

  return {
    workshopList,
    paging,
    fnGetWrokShopList,
    updateSearch,

    introDetail,
    fnGetWrokShopDetail,

    resourcesDeail,
    fnGetResourceByShopId,
  };
};

export default useBasic;