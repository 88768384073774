import React from 'react';
import { ReactComponent  as LeftArrowSvg } from '../../assets/svgs/left_arrow.svg';
import { ReactComponent as  WarnSvg} from '../../assets/svgs/warn.svg';
import { Collapse, Input, Tabs } from 'antd';
import { cn } from '../../utils/utils';
import { useState } from 'react';
import OverviewCom from './components/OverviewCom.jsx';
import ResponseCom from './components/ResponseCom.jsx';
import InsightCom from './components/InsightCom.jsx';
import SvgCom from '../../components/SvgCom/index.jsx';
import { useNavigate } from 'react-router-dom';

const collapseClassNames = {
  header: '!text-[13px] !font-semibold !text-primary !p-0 !pt-4',
  body: '!p-0 !pt-2 !pb-5 border-b-2 border-[#00A32E1A] border-solid'
};

const tabItems = [
  {
    key: 'tab_1',
    label: 'Overview',
  },
  {
    key: 'tab_2',
    label: 'Responses',
  },
  {
    key: 'tab_3',
    label: 'AI Insights',
  },
  {
    key: 'tab_4',
    label: 'AI Chat',
  },
];

const ProjectDetailPage = () => {
  const nvigate = useNavigate();
  const [tabIndex, setTabIndex] = useState('tab_1');

  const items = [
    {
      key: '1',
      label: 'Custom themes',
      classNames: collapseClassNames,
      children: <div className="bg-second rounded-lg px-[10px] py-[15px] flex">
        <SvgCom Svg={WarnSvg} size="16"></SvgCom>
        <div className="flex-1 ml-2.5 text-xs font-normal text-primary">
            Create and edit custom themes in theme builder so you can use them here to filter responses like a pro
        </div>
      </div>,
    },
    {
      key: '2',
      label: 'Automated themes',
      classNames: collapseClassNames,
      children: <div className="flex flex-wrap">
        {
          // false ? 'bg-second text-primary' :
          [1,2,3,4,5].map((item, index) => <div key={index} className={cn('py-[7px] px-[10px] text-xs leading-none font-normal rounded-3xl mr-2 mb-2',  'bg-primary text-white' )}>improvise [{item}]</div>)
        }
      </div>,
    }
  ];

  const onTabChange = (activeKey) => {
    setTabIndex(activeKey);
  };

  const fnGoBack = () => {
    nvigate(-1);
  };
    
  return <div className="flex justify-start h-full relative">
    {/* Filter Box */}
    <div className="w-[255px] h-[calc(100vh - 90px)] bg-grey-4 px-[15px] py-[20px] rounded-tr-[20px] shadow-filter-box relative z-[1]">
      <div className="text-base leading-none font-semibold text-primary">Filter</div>
      <Input className="mt-[10px] rounded-3xl bg-transparent"></Input>

      <div className="h-4"></div>

      <Collapse expandIconPosition="end" defaultActiveKey={['1']} ghost items={items}/>
    </div>
    {/* top nav bar  */}
    <div className="h-[60px] w-full bg-grey-4 absolute top-0 left-0 pl-[255px]">
      <Tabs defaultActiveKey={tabIndex} onChange={onTabChange} items={tabItems} className="tab-custom [&>div.ant-tabs-nav]:before:border-none [&>div.ant-tabs-nav]:mb-0 ml-[20px] absolute bottom-0" />
    </div>
    {/* Main Box */}
    <div className="flex-1 mt-[60px] px-[20px] pt-[25px] pb-[10px]">
      <div className="text-primary flex justify-start items-center mb-[22px] cursor-pointer" onClick={fnGoBack}>
        <SvgCom Svg={LeftArrowSvg} size="16"></SvgCom>
        <span className="text-base leading-none font-semibold ml-2">(Final)FR-Cooking Tension-General Cooking</span>
      </div>
      <div className="overflow-y-auto" style={{height: 'calc(100vh - 195px)'}}>
        {
          tabIndex === 'tab_1' && <OverviewCom></OverviewCom>
        }
        {
          tabIndex === 'tab_2' && <ResponseCom></ResponseCom>
        }
        {
          tabIndex === 'tab_3' && <InsightCom></InsightCom>
        }
      </div>
    </div>
  </div>;
};

export default ProjectDetailPage;