let count = 0;

export function uuid() {
  count += 1;
  if (count > 999999) count = 0;
  return `${count.toString().padStart(6, '0')}-${Date.now()
    .toString()
    .slice(-6)
    .padStart(6, '0')}`;
}
