function appendQueryParams(url, params) {
  const urlParts = url.split('#'); 
  const baseParts = urlParts[0].split('?');
  const basePath = baseParts[0];  
  const search = baseParts[1] || '';  
  const hash = urlParts[1] || '';  
  const searchParams = new URLSearchParams(search);  

  for (const key in params) {  
    if (Object.prototype.hasOwnProperty.call(params, key)) {  
      searchParams.set(key, params[key]);  
    }  
  }  

  let newBasePath = basePath;
  if (searchParams.size > 0) {
    newBasePath += '?' + searchParams.toString();
  }
  if (hash) {
    newBasePath += '#' + hash;
  }

  return newBasePath; 
}

export default appendQueryParams;