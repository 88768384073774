// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.QQz5bdk8m3_0zmb3HP6F{height:100%;position:relative;z-index:200}.A_9SsO7WVIzfevOT0hSw{top:0;right:0;left:0;bottom:0;position:fixed;z-index:-100}.TpdRT6GBo_1kIHpm7ZeD{height:100%;position:absolute;top:50%;left:50%;transform:translate(-50%, -50%);opacity:.3;transition:transform .3s ease-in-out}`, "",{"version":3,"sources":["webpack://./src/modules/top-dish/pages/Layout/index.module.scss"],"names":[],"mappings":"AAAA,sBAGE,WAAA,CAGA,iBAAA,CACA,WAAA,CAGF,sBACE,KAAA,CACA,OAAA,CACA,MAAA,CACA,QAAA,CACA,cAAA,CACA,YAAA,CAGF,sBACE,WAAA,CACA,iBAAA,CACA,OAAA,CACA,QAAA,CACA,+BAAA,CACA,UAAA,CACA,oCAAA","sourcesContent":[".painLayout {\n  //margin-left: 134px;\n  // margin-left: 250px;\n  height: 100%;\n  // width: calc(100% - 250px);\n  //transition: all 0.3s ease;\n  position: relative;\n  z-index: 200;\n}\n\n.videoBox {\n  top: 0;\n  right: 0;\n  left: 0;\n  bottom: 0;\n  position: fixed;\n  z-index: -100;\n}\n\n.videoBackground {\n  height: 100%;\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n  opacity: 0.3;\n  transition: transform 0.3s ease-in-out;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"painLayout": `QQz5bdk8m3_0zmb3HP6F`,
	"videoBox": `A_9SsO7WVIzfevOT0hSw`,
	"videoBackground": `TpdRT6GBo_1kIHpm7ZeD`
};
export default ___CSS_LOADER_EXPORT___;
