import React, { useCallback, useEffect, useState } from 'react';
import style from './main.module.scss';

import preViewSvg from '../../../../assets/images/consumer-theater-chat/preview.svg';
import playSvg from '../../../../assets/images/consumer-theater-chat/play.svg';
import defBgIcon from '../../../../assets/images/consumer-theater-chat/bg.png';
import { Col, Row } from 'antd';
import { useRoot } from '../../hooks/useRoot.jsx';
import { getFileUrl } from '../../api/request.js';

const SendAIBox = ({data}) => {
  const { isExpansion, setOpenPreviewWin, setVideoUrl } = useRoot();

  const fnOpenPreview = useCallback(async (item) => {
    const result = await getFileUrl({file_key: item.video_path});
    if(result.status === 'Success') {
      setVideoUrl(result.data.url);
      setOpenPreviewWin(true);
    }
  }, []);

  return (
    <div className={style.sendAiBox}>
      <div className={style.textBox} dangerouslySetInnerHTML={{__html: data.answer}}></div>
      {
        data.related_contexts.length && <Row gutter={[8, 8]} style={{marginTop: '10px'}}>
          {
            data.related_contexts.map((item, index) => (
              <Col key={index} className="gutter-row" xs={8} sm={isExpansion ? 6 : 8} md={isExpansion ? 6 : 8} lg={isExpansion ? 4 : 8} xxl={isExpansion ? 2 : 8}>
                <div className={style.videoImgBox} onClick={() => fnOpenPreview(item)}>
                  <CmiImg fileKey={item.image_path}></CmiImg>
                  <img src={playSvg} alt="play" className={style.playIcon} />
                  <div className={style.previewBox}>
                    <img src={preViewSvg} alt="preview" className={style.previewIcon} />
                  </div>
                  <span className={style.videoTitle}>{item.title}</span>
                </div>
              </Col>
            ))
          }
        </Row>
      }
    </div>
  );
};

const CmiImg = ({fileKey}) => {
  const [imgUrl, setImgUrl] = useState(defBgIcon);

  const fnGetFileUrl = useCallback(async () => {
    const result = await getFileUrl({file_key: fileKey});
    if(result.status === 'Success') {
      setImgUrl(result.data.url);
    }
  }, [fileKey]);

  useEffect(() => {
    fnGetFileUrl();
  }, [fnGetFileUrl]);

  return <img src={imgUrl} alt="bg" className={style.thumImg} />;
};

export default SendAIBox;