import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { getResourceDetail, getWrokShopDetail } from '../api/request';

const RootContext = createContext(null);
export const RootProvider = ({children}) => {
  const [openEditPopup, setOpenEditPopup] = useState(false);
  const [editType, setEditType] = useState('intro');
  const [infoDetail, setInfoDetail] = useState({});
  const [isEdit, setIsEdit] = useState(false);
  const [refreshFlag, setRefreshFlag] = useState(0)
  const [shopId, setShopId] = useState(-1)
  const [isGotflWeenaAdmin, setIsGotflWeenaAdmin] = useState(false)

  useEffect(() => {
    let userObj = sessionStorage.getItem('user')
    userObj = JSON.parse(userObj)
    if(userObj?.permissions?.includes('GOTFL_WEENA_ADMIN')) {
      setIsGotflWeenaAdmin(true)
    } else {
      setIsGotflWeenaAdmin(false)
    }
  }, [])

  const fnOpenEditPopup = useCallback((isOpen, type = '', shopId = -1) => {
    setEditType(type);
    setOpenEditPopup(isOpen);

    if(shopId) {
      setShopId(shopId)
    }

    if(!isOpen) {
      setInfoDetail({});
      setIsEdit(false);
      setShopId(-1);
    }
  }, []);

  const fnOpenEditPopupForModify = useCallback(async (id, type = '') => {
    setIsEdit(true);
    if(type === 'intro') {
      const result = await getWrokShopDetail(id);
      if(result && result.status === 'Success') {
        setInfoDetail(result.data);
        fnOpenEditPopup(true, type);
      }
    } else {
      const result = await getResourceDetail(id);
      if(result && result.status === 'Success') {
        setInfoDetail(result.data);
        fnOpenEditPopup(true, type);
      }
    }
  }, [fnOpenEditPopup]);

  const updateRefreshFlag = (flag) => {
    setRefreshFlag(flag)
  }
  
  return <RootContext.Provider value={{
    openEditPopup,
    editType,
    infoDetail,
    isEdit,
    refreshFlag,
    shopId,
    isGotflWeenaAdmin,

    fnOpenEditPopup,
    fnOpenEditPopupForModify,
    updateRefreshFlag,
  }}>
    {children}
  </RootContext.Provider>;
};

export const useRoot = () => {
  return useContext(RootContext) || {
    openEditPopup: false,
    editType: 'intro',
    infoDetail: {},
    isEdit: false,
    refreshFlag: 0,
    shopId: -1,
    isGotflWeenaAdmin: false,

    fnOpenEditPopup: () => {},
    fnOpenEditPopupForModify: () => {},
    updateRefreshFlag: () => {}
  };
};