// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fzN6fHjQc3cQlwsA2pTo{display:flex;overflow:hidden}.DNLL6NEBdrL1oq0s3zLl{margin-top:4px;display:block;height:15px;margin-right:10px}.mrR5osek5CEuxB2JPA0B{overflow-x:hidden;overflow-y:auto}.mrR5osek5CEuxB2JPA0B h2{font-size:16px;font-weight:600;line-height:22.3px;color:#fff}.htSzc7OFkmpbVFAedr51{margin-top:12px}.ws7XXHBfk6HvHF8RrXOw{font-size:12px;font-weight:400;line-height:16.73px}.sfvttZ81AD1iTIgQPHpI{margin-top:8px;width:150px}`, "",{"version":3,"sources":["webpack://./src/modules/package-ai-generation/views/base/filter.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,eAAA,CAGF,sBACE,cAAA,CACA,aAAA,CACA,WAAA,CACA,iBAAA,CAGF,sBACE,iBAAA,CACA,eAAA,CACA,yBACE,cAAA,CACA,eAAA,CACA,kBAAA,CACA,UAAA,CAIJ,sBACE,eAAA,CAGF,sBACE,cAAA,CACA,eAAA,CACA,mBAAA,CAGF,sBACE,cAAA,CACA,WAAA","sourcesContent":[".filterBox {\n  display: flex;\n  overflow: hidden;\n}\n\n.filterIcon {\n  margin-top: 4px;\n  display: block;\n  height: 15px;\n  margin-right: 10px;\n}\n\n.filterBody {\n  overflow-x: hidden;\n  overflow-y: auto;\n  h2 {\n    font-size: 16px;\n    font-weight: 600;\n    line-height: 22.3px;\n    color: #fff;\n  }\n}\n\n.filterItem {\n  margin-top: 12px;\n}\n\n.filterLabel {\n  font-size: 12px;\n  font-weight: 400;\n  line-height: 16.73px;\n}\n\n.filterSelect {\n  margin-top: 8px;\n  width: 150px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"filterBox": `fzN6fHjQc3cQlwsA2pTo`,
	"filterIcon": `DNLL6NEBdrL1oq0s3zLl`,
	"filterBody": `mrR5osek5CEuxB2JPA0B`,
	"filterItem": `htSzc7OFkmpbVFAedr51`,
	"filterLabel": `ws7XXHBfk6HvHF8RrXOw`,
	"filterSelect": `sfvttZ81AD1iTIgQPHpI`
};
export default ___CSS_LOADER_EXPORT___;
