import service from '../../../../util/pythonApi';

export function postPredictVariant(data) {
  return service.request({
    url: '/v1/pred_variant',
    method: 'post',
    data,
  });
}

export function postPredictVariantRun(data) {
  return service.request({
    url: '/v1/pred_variant_run',
    method: 'post',
    data,
  });
}

export function getPredictVariant(id) {
  return service.request({
    url: `/v1/pred_variant/${id}`,
    method: 'get',
  });
}
