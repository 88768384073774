import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import classNames from 'classnames';
import React, { useCallback } from 'react';
import style from './item.module.scss';

function Actions({ className, onUpdate, onDelete }) {
  const onEditClick = useCallback(
    (ev) => {
      ev.stopPropagation();
      onUpdate && onUpdate();
    },
    [onUpdate]
  );

  const onDeleteClick = useCallback(
    (ev) => {
      ev.stopPropagation();
      onDelete && onDelete();
    },
    [onDelete]
  );

  return (
    <div className={classNames(style.actionBox, className)}>
      <div className={style.btn} onClick={onEditClick}>
        <EditOutlined className={style.icon} />
      </div>
      <div className={style.btn} onClick={onDeleteClick}>
        <DeleteOutlined className={style.icon} />
      </div>
    </div>
  );
}

function PackageAiItem({ data, selected, onSelect, onUpdate, onDelete }) {
  const onUpdateClick = useCallback(() => {
    onUpdate && onUpdate({ ...data });
  }, [data, onUpdate]);
  const onDeleteClick = useCallback(() => {
    onDelete && onDelete(data.id);
  }, [data, onDelete]);

  return (
    <div className={style.box} onClick={onSelect}>
      <div className={style.boxInner}>
        <div className={style.img}>
          <img src={data.url} loading="lazy" crossOrigin="anonymous" />
          <Actions
            className={style.action}
            onUpdate={onUpdateClick}
            onDelete={onDeleteClick}
          />
        </div>
        <div className={style.body}>
          <h3 className={style.title}>{data.title}</h3>
        </div>
      </div>
      <div className={`${style.cornerBox} ${selected ? style.active : ''}`}>
        <div className={style.corner1} />
        <div className={style.corner2} />
        <div className={style.corner3} />
        <div className={style.corner4} />
      </div>
    </div>
  );
}

export default PackageAiItem;
