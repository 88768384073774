import React, { useState, useEffect } from 'react';
import {
  arrowUp,
  arrowDown,
  Filter,
  searchIcon,
  closeIcon,
} from '../../../../../components/icons/icons.jsx';
import api from '../../../../../util/api.jsx';
import { apiURL } from '../../../../../env-url.js';
import DrillFilterModal from './drill-down-fillter-model.jsx';
import Loader from '../../../../../components/loader/loader.jsx';
import Tooltip from '../../../../../components/tooltip/tooltip.jsx';
import TopDishes from '../top-dishes.jsx';

function Drill({
  category,
  object,
  consumerTrendsCategory,
  showDrillDown,
  selectedItemName,
  setShowbarchart,
  showbarchart,
  selectedCountry
}) {
  const [transactions, setTransactions] = useState([]);
  const [showInput, setShowInput] = useState(false);
  const [searchInput, setSearchInput] = useState('');
  const [filteredResults, setFilteredResults] = useState([]);
  const [search, setNewSearch] = useState('');
  const [buttons, setButtons] = useState([]);
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [sortStatus, setSortstatus] = useState(true);
  const [sortStatusVol, setSortstatusVol] = useState(true);
  const [sortStatusCarg, setSortstatusCarg] = useState(true);
  const [sortStatusyoy, setSortstatusyoy] = useState(true);
  const [associateBack, setAssociatedBack] = useState('');
  const [searchStatus, setNewSearchStatus] = useState(true);
  const [searchVol, setNewSearchVol] = useState(true);
  const [searchCarg, setNewSearchCarg] = useState(true);
  const [searchyoy, setNewSearchyoy] = useState(true);
  const handleApply = () => {
    if (selectedOptions.length < 1) {
      setFilteredResults(transactions);
    } else {
      const query = selectedOptions;
      let updatedList = [...transactions];
      updatedList = updatedList.filter((item) => {
        return (
          item.accociatedCategories
            .toString()
            .toLowerCase()
            .indexOf(query.toString().toLowerCase()) !== -1
        );
      });
      setFilteredResults(updatedList);
      setNewSearch(true);
    }
    setIsFilterOpen(!isFilterOpen);
  };

  const handleButtonClick = () => {
    setIsFilterOpen(!isFilterOpen);
  };

  const fetchSubcategories = () => {
    const requestBody = {
      country: selectedCountry,
      category: category,
      subCategory: '',
      consumerTrend: selectedItemName,
      numberOfObjects: 50,
    };
    api
      .post(`${apiURL}/trends/search/v2/categories`, requestBody)
      .then((response) => {
        setButtons((prevData) => [...prevData, ...response.data]);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  };

  const fetchData = () => {
    const requestBody = {
      country: selectedCountry,
      category: category,
      subCategory: object,
      consumerTrend: selectedItemName,
    };
    api
      .post(`${apiURL}/trends/search/v2/categories/keywords`, requestBody)
      .then((response) => {
        setTransactions((prevData) => [...prevData, ...response.data]);
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  };

  useEffect(() => {
    fetchData();
    fetchSubcategories();
  }, []);
  const searchItems = (searchValue) => {
    setSearchInput(searchValue);
    if (searchInput !== '') {
      const filteredData = transactions.filter((item) => {
        return Object.values(item)
          .join('')
          .toLowerCase()
          .includes(searchInput.toLowerCase());
      });
      setFilteredResults(filteredData);
      setLoading(false);
    } else {
      setFilteredResults(transactions);
    }
  };

  const handleSearch = () => {
    setShowInput(true);
  };

  function onHeaderClick(e) {
    if (sortStatus) {
      let type = e.target.textContent.toLowerCase();
      const sorted = [...transactions].sort((a, b) =>
        a[type] > b[type] ? 1 : -1
      );
      setTransactions(sorted);
      setSortstatus(!sortStatus);
    } else {
      let type = e.target.textContent.toLowerCase();
      const sorted = [...transactions].sort((a, b) =>
        b[type] > a[type] ? 1 : -1
      );
      setTransactions(sorted);
      setSortstatus(!sortStatus);
    }
    if (searchStatus) {
      let type = e.target.textContent.toLowerCase();
      const sorted = [...filteredResults].sort((a, b) =>
        a[type] > b[type] ? 1 : -1
      );
      setFilteredResults(sorted);
      setNewSearchStatus(!searchStatus);
    } else {
      let type = e.target.textContent.toLowerCase();
      const sorted = [...filteredResults].sort((a, b) =>
        b[type] > a[type] ? 1 : -1
      );
      setFilteredResults(sorted);
      setNewSearchStatus(!searchStatus);
    }
  }

  function onHeaderClickVolume() {
    if (sortStatusVol) {
      let sorted = [...transactions].sort((a, b) => a.volume - b.volume);
      setTransactions(sorted);
      setSortstatusVol(!sortStatusVol);
    } else {
      let sorted = [...transactions].sort((a, b) => b.volume - a.volume);
      setTransactions(sorted);
      setSortstatusVol(!sortStatusVol);
    }
    if (searchVol) {
      let sorted = [...filteredResults].sort((a, b) => a.volume - b.volume);
      setFilteredResults(sorted);
      setNewSearchVol(!searchVol);
    } else {
      let sorted = [...filteredResults].sort((a, b) => b.volume - a.volume);
      setFilteredResults(sorted);
      setNewSearchVol(!searchVol);
    }
  }
  function onHeaderClickCarg() {
    if (sortStatusCarg) {
      const sorted = [...transactions].sort((a, b) => a.cagr - b.cagr);
      setTransactions(sorted);
      setSortstatusCarg(!sortStatusCarg);
    } else {
      const sorted = [...transactions].sort((a, b) => b.cagr - a.cagr);
      setTransactions(sorted);
      setSortstatusCarg(!sortStatusCarg);
    }
    if (searchCarg) {
      const sorted = [...filteredResults].sort((a, b) => a.cagr - b.cagr);
      setFilteredResults(sorted);
      setNewSearchCarg(!searchCarg);
    } else {
      const sorted = [...filteredResults].sort((a, b) => b.cagr - a.cagr);
      setFilteredResults(sorted);
      setNewSearchCarg(!searchCarg);
    }
  }
  function onHeaderClickYoy() {
    if (sortStatusyoy) {
      const sorted = [...transactions].sort((a, b) => a.yoy - b.yoy);
      setTransactions(sorted);
      setSortstatusyoy(!sortStatusyoy);
    } else {
      const sorted = [...transactions].sort((a, b) => b.yoy - a.yoy);
      setTransactions(sorted);
      setSortstatusyoy(!sortStatusyoy);
    }
    if (searchyoy) {
      const sorted = [...filteredResults].sort((a, b) => a.yoy - b.yoy);
      setFilteredResults(sorted);
      setNewSearchyoy(!searchyoy);
    } else {
      const sorted = [...filteredResults].sort((a, b) => b.yoy - a.yoy);
      setFilteredResults(sorted);
      setNewSearchyoy(!searchyoy);
    }
  }

  const handleSearchChange = (e) => {
    setNewSearch(e.target.value);
    if (e.target.value) {
      const requestBody = {
        country: selectedCountry,
        category: category,
        subCategory: e.target.value,
        consumerTrend: selectedItemName,
      };
      api
        .post(`${apiURL}/trends/search/v2/categories/keywords`, requestBody)
        .then((response) => {
          setFilteredResults(response.data);
          setLoading(false);
        })
        .catch((error) => {
          console.error('Error fetching data:', error);
        });
    } else {
      setFilteredResults(transactions);
    }
  };

  const onAssociateBack = (e) => {
    setAssociatedBack(e);
    setShowbarchart(true);
  };

  return isLoading ? ( //Check if is loading
    <Loader />
  ) : (
    <>
      {showDrillDown && showbarchart == false && (
        <>
          <div className="drill-container">
            <div className="horizontal-nav">
              <div className="drill-title"></div>
              <div className="drill-sort">
                <div className="search-section">
                  {showInput && (
                    <input
                      className="search-input"
                      type="text"
                      value={searchInput}
                      onChange={(e) => searchItems(e.target.value)}
                      placeholder="Search..."
                    />
                  )}
                  {searchInput === '' ? (
                    <img src={searchIcon} alt="search" onClick={handleSearch} />
                  ) : (
                    <img
                      src={closeIcon}
                      alt="search"
                      onClick={() => {
                        setSearchInput('');
                        setShowInput(false);
                      }}
                    />
                  )}
                </div>
              </div>
            </div>
            <div className="drill-button-container">
              {buttons.map(({ objectName }) => (
                <button
                  className={`tags ${
                    search === objectName ? 'active-dish' : ''
                  }`}
                  value={objectName}
                  key={objectName}
                  onClick={(e) => handleSearchChange(e)}
                >
                  {objectName}
                </button>
              ))}
            </div>
            <div className="fixTableHead">
              <table>
                <tr>
                  <th onClick={onHeaderClick} width="25%">
                    Column Header
                    {sortStatus && searchStatus ? (
                      <img src={arrowUp} alt="up-arrow" />
                    ) : (
                      <img src={arrowDown} alt="up-arrow" />
                    )}
                  </th>
                  <th onClick={onHeaderClickVolume} width="15%">
                    Volume (over 4 years){' '}
                    <Tooltip text="2019-2023">
                      <button>i</button>
                    </Tooltip>
                    {sortStatusVol && searchVol ? (
                      <img src={arrowUp} alt="up-arrow" />
                    ) : (
                      <img src={arrowDown} alt="up-arrow" />
                    )}
                  </th>
                  <th onClick={onHeaderClickCarg} width="8.3%">
                    CAGR
                    {sortStatusCarg && searchCarg ? (
                      <img src={arrowUp} alt="up-arrow" />
                    ) : (
                      <img src={arrowDown} alt="up-arrow" />
                    )}
                  </th>
                  <th onClick={onHeaderClickYoy} width="8.3%">
                    YOY
                    {sortStatusyoy && searchyoy ? (
                      <img src={arrowUp} alt="up-arrow" />
                    ) : (
                      <img src={arrowDown} alt="up-arrow" />
                    )}
                  </th>
                  <th width="25%">
                    Associated categories
                    <img
                      src={Filter}
                      alt="up-arrow"
                      onClick={handleButtonClick}
                    />
                    {isFilterOpen && (
                      <DrillFilterModal
                        onClose={handleButtonClick}
                        isOpen={isFilterOpen}
                        onApply={handleApply}
                        selectedOptions={selectedOptions}
                        setSelectedOptions={setSelectedOptions}
                        brandcategory={category}
                        object={object}
                        selectedItemName={selectedItemName}
                        consumerTrendsCategory={consumerTrendsCategory}
                        selectedCountry={selectedCountry}
                      />
                    )}
                  </th>
                  <th width="20%">Sub categories</th>
                </tr>
                {searchInput.length > 1 || search
                  ? filteredResults.map((item, key) => {
                    return isLoading ? ( //Check if is loading
                      <Loader />
                    ) : (
                      <tr key={key}>
                        <td>{item.keywordName}</td>
                        <td>{item.volume}</td>
                        <td>{item && item.cagr ? item.cagr + ' %' : '-'}</td>
                        <td>{item && item.yoy ? item.yoy + ' %' : '-'}</td>
                        <td>
                          {item.accociatedCategories.map(
                            (associate, index) => (
                              <span
                                onClick={() => onAssociateBack(associate)}
                                className="category-box"
                                key={index}
                              >
                                #{associate}
                              </span>
                            )
                          )}
                        </td>
                        <td>
                          {item.subCategories.map((e, index) => (
                            <span className="sub-box" key={index}>
                              {e}
                            </span>
                          ))}
                        </td>
                      </tr>
                    );
                  })
                  : transactions.map((item, key) => {
                    return isLoading ? ( //Check if is loading
                      <Loader />
                    ) : (
                      <tr key={key}>
                        <td>{item.keywordName}</td>
                        <td>{item.volume}</td>
                        <td>{item && item.cagr ? item.cagr + ' %' : '-'}</td>
                        <td>{item && item.yoy ? item.yoy + ' %' : '-'}</td>
                        <td>
                          {item.accociatedCategories.map(
                            (associate, index) => (
                              <span
                                onClick={() => onAssociateBack(associate)}
                                className="category-box"
                                key={index}
                              >
                                #{associate}
                              </span>
                            )
                          )}
                        </td>
                        <td>
                          {item.subCategories.map((e, index) => (
                            <span className="sub-box" key={index}>
                              {e}
                            </span>
                          ))}
                        </td>
                      </tr>
                    );
                  })}
              </table>
            </div>
          </div>
        </>
      )}
      {showbarchart && (
        <TopDishes
          selectedItemName={selectedItemName}
          consumerTrendsCategory={consumerTrendsCategory}
          category={associateBack}
        />
      )}
    </>
  );
}

export default Drill;
