import {
  CompressOutlined,
  RollbackOutlined,
  ZoomInOutlined,
  ZoomOutOutlined,
} from '@ant-design/icons';
import React from 'react';
import EraserSvg from '../../assets/img/eraser.svg';
import style from './tool.module.scss';

const SETP = 0.1;

function PackageAiTool({
  setScale,
  onClear,
  onUndo,
  undoDisabled = true,
  onReset,
}) {
  return (
    <div className={style.toolBox}>
      <RollbackOutlined
        title="Undo"
        className={`${style.toolBtn} ${undoDisabled ? style.disabled : ''}`}
        onClick={onUndo}
      />
      <img
        src={EraserSvg}
        alt="Clear"
        title="Clear"
        className={style.toolBtn1}
        onClick={onClear}
      />
      <ZoomInOutlined
        title="Zoom In"
        className={style.toolBtn}
        onClick={() => setScale((scale) => scale + SETP)}
      />
      <ZoomOutOutlined
        title="Zoom Out"
        className={style.toolBtn}
        onClick={() => setScale((scale) => scale - SETP)}
      />
      <CompressOutlined
        title="Reset"
        className={style.toolBtn}
        onClick={onReset}
      />
    </div>
  );
}

export default PackageAiTool;
