import React from 'react';
import { Input } from 'antd';
import { SearchOutlined } from '@ant-design/icons';


const App = () => {
  const onChange = (key) => {
    console.log(key);
  };

  return <div style={{padding:'20px 15px 0 15px'}}>
    <Input prefix={<SearchOutlined />} placeholder="Search" size="large"
           style={{borderRadius: '30px', background: 'transparent', borderColor: 'rgb(20,109,30)', color: 'rgb(72,158,64)' }} />
  </div>;
};

export default App;