// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.gq0x5ZCrGx_FIpkCw6ng{height:100%;position:relative}`, "",{"version":3,"sources":["webpack://./src/modules/pain-points/pages/Layout/index.module.scss"],"names":[],"mappings":"AAAA,sBAGE,WAAA,CAGA,iBAAA","sourcesContent":[".painLayout {\n  //margin-left: 134px;\n  // margin-left: 250px;\n  height: 100%;\n  // width: calc(100% - 250px);\n  //transition: all 0.3s ease;\n  position: relative;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"painLayout": `gq0x5ZCrGx_FIpkCw6ng`
};
export default ___CSS_LOADER_EXPORT___;
