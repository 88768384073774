// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.G7Zp6bA4zPa7tFqj3UtW{margin-bottom:20px}.pkAMkS2saHSlDmXt8IIr img{display:inline-block;margin-right:10px;width:10px;height:12px}.CBS_zfhVwyxcPwbwaN5R{transform:rotate(90deg)}.XimEMKqLOj_4gPoe8vwX{font-size:16px;font-weight:400;line-height:22.3px}.SpiQAuEMShDG_MsMNoAv{margin-top:14px;transition:height .3s;overflow:hidden}.vU8HDc4CoJUVsOtrvFpP{height:0}`, "",{"version":3,"sources":["webpack://./src/modules/package-ai-generation/components/form.module.scss"],"names":[],"mappings":"AAAA,sBACE,kBAAA,CAIA,0BACE,oBAAA,CACA,iBAAA,CACA,UAAA,CACA,WAAA,CAIJ,sBACE,uBAAA,CAGF,sBACE,cAAA,CACA,eAAA,CACA,kBAAA,CAGF,sBACE,eAAA,CACA,qBAAA,CACA,eAAA,CAGF,sBACE,QAAA","sourcesContent":[".formItem {\n  margin-bottom: 20px;\n}\n\n.formItemLabel {\n  img {\n    display: inline-block;\n    margin-right: 10px;\n    width: 10px;\n    height: 12px;\n  }\n}\n\n.formItemLabelNotCollapse {\n  transform: rotate(90deg);\n}\n\n.formItemText {\n  font-size: 16px;\n  font-weight: 400;\n  line-height: 22.3px;\n}\n\n.formItemContent {\n  margin-top: 14px;\n  transition: height 0.3s;\n  overflow: hidden;\n}\n\n.formItemContentCollapse {\n  height: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"formItem": `G7Zp6bA4zPa7tFqj3UtW`,
	"formItemLabel": `pkAMkS2saHSlDmXt8IIr`,
	"formItemLabelNotCollapse": `CBS_zfhVwyxcPwbwaN5R`,
	"formItemText": `XimEMKqLOj_4gPoe8vwX`,
	"formItemContent": `SpiQAuEMShDG_MsMNoAv`,
	"formItemContentCollapse": `vU8HDc4CoJUVsOtrvFpP`
};
export default ___CSS_LOADER_EXPORT___;
