export function parseDate (date) {
  if(date instanceof Date)
    return date.toISOString().split('T')[0];
}

export function isWinDpr1() {
  var isMac = (navigator.platform == 'Mac68K') || (navigator.platform == 'MacPPC') || (navigator.platform == 'Macintosh') || (navigator.platform == 'MacIntel');
  if (isMac) {
    // mac default true
    return true;
  } else {
    if(window.devicePixelRatio <= 1) {
      return true;
    } else {
      return false;
    }
  }
}

export function isIpad() {
  return /iPad/.test(navigator.userAgent) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1) || window.innerWidth <= 1366;
}