import { FileAddOutlined } from '@ant-design/icons';
import { Form, Input, message } from 'antd';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { postFile } from '../../apis/file';
import { postPackageAiAsset } from '../../apis/serve/asset';
import { PackageAiBtn1 as Btn } from '../../components/btn.jsx';
import Dialog from '../../components/dialog2.jsx';
import Loading from '../../components/loading.jsx';
import style from './upload.module.scss';

const IMG_FILE_TYPE = ['image/jpeg', 'image/png', 'image/webp'];

const { Item } = Form;

function UploadDialog({ visible, setVisible, afterUpload }) {
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState();
  const fileName = useMemo(() => {
    if (!file) return 'Click to select file';
    return file.name;
  }, [file]);

  const onFinish = useCallback(
    async (values) => {
      if (!file) {
        message.warning('Please select a file');
        return;
      }
      if (loading) return;
      setLoading(true);
      try {
        const { data: fd } = await postFile(file);
        await postPackageAiAsset({
          url: fd.key,
          title: values.title ?? file.name,
          type: 'logo',
        });
        setVisible(false);
        afterUpload && afterUpload();
      } catch (err) {
        console.error('upload asset error', err);
      }
      setLoading(false);
    },
    [file]
  );

  const submitRef = useRef();
  const onSubmit = useCallback(() => {
    if (!submitRef.current) return;
    submitRef.current.click();
  }, [submitRef.current]);

  const uploadRef = useRef();

  const onUpload = useCallback(() => {
    if (!uploadRef.current) return;
    uploadRef.current.click();
  }, [uploadRef.current]);
  const onUploadFile = useCallback((files) => {
    if (!files?.length) return;
    setFile(files[0]);
  });

  return (
    <Dialog
      title="Upload asset"
      visible={visible}
      setVisible={setVisible}
      zIndex={101}
    >
      <div className={style.box1}>
        <div className={style.box11}>
          <Form layout="vertical" labelCol={{ span: 8 }} onFinish={onFinish}>
            <Item label={<span className={style.formLabel}>File</span>}>
              <div className={style.box111} onClick={onUpload}>
                <FileAddOutlined className={style.icon} />
                {fileName}
              </div>
            </Item>
            <Item
              label={<span className={style.formLabel}>Title</span>}
              name="title"
            >
              <Input placeholder="Input title" />
            </Item>
            <button ref={submitRef} type="submit" style={{ display: 'none' }} />
          </Form>
        </div>
        <div className={style.box12}>
          <Btn onClick={onSubmit}>Submit</Btn>
        </div>
      </div>
      <input
        ref={uploadRef}
        style={{ display: 'none' }}
        type="file"
        name="upload"
        id="upload"
        accept={IMG_FILE_TYPE.join(',')}
        onChange={(ev) => {
          if (!ev.target) return;
          onUploadFile(ev.target.files);
        }}
      />
      <Loading loading={loading} />
    </Dialog>
  );
}

export default UploadDialog;
