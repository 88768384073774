import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import BorderContainer from '../border-container/border-container.jsx';
import style from './consumer-trends.module.scss';
import classNames from 'classnames';
import { triangleRight, dialExport } from '../../../../components/icons/icons.jsx';
import LineCharts from '../line-charts/line-charts.jsx';
import { Select, Spin } from 'antd';
import CompetitorInnovation from '../competitor-innovation/competitor-innovation.jsx';
import SearchTiktok from '../search-tiktok/search-tiktok.jsx';
import consumerTrendDescUK from '../../data/consumer-trends-desc/consumer-trend-desc-uk.js';
import consumerTrendDescUS from '../../data/consumer-trends-desc/consumer-trend-desc-us.js';
import { fetchSearchDetails } from '../../api/request.js';
import { LoadingOutlined } from '@ant-design/icons';
import { utils, writeFile } from 'xlsx';

// 增加国家，这里需要加 consumerTrend 描述文件对象
// 数据库都是GB，只有前端展示是UK
const descriptionData = {
  GB: consumerTrendDescUK,
  US: consumerTrendDescUS
};

const antIcon = (
  <LoadingOutlined
    style={{
      fontSize: 30,
      color: '#00FF00'
    }}
    spin
  />
);

let ConsumerTrends = ({country}, ref) => {
  const [activeTab, setActiveTab] = useState(null);
  const [title, setTitle] = useState('');
  const [consumerTrend, setConsumerTrend] = useState('');
  const [description, setDescription] = useState('');
  const [detailData, setDetailData] = useState({
    yearsData: [],
    monthsData: []
  });
  const [yearLineOption, setYearLineOption] = useState({});
  const [monthLineOption, setMonthLineOption] = useState({});
  const [yearList, setYearList] = useState([]);
  const [currentYear, setCurrentYear] = useState(null);
  const [yearLoading, setYearLoading] = useState(true);
  const [monthLoading, setMonthLoading] = useState(true);
  const [yearData, setYearData] = useState([]);
  const [monthData, setMonthData] = useState([]);

  const yearOptionFn = (data) => {
    setYearData(data);
    setYearLineOption({
      grid: {
        left: '100'
      },
      tooltip: {
        trigger: 'axis',
        backgroundColor: 'rgba(0,0,0,0.7)',
        borderColor: '#00A32E',
        textStyle: {
          color: '#FFFFFF'
        }
      },
      xAxis: {
        type: 'category',
        axisLine: {
          lineStyle: {
            color: '#00A32E'
          }
        },
        axisTick: {
          show: false
        },
        data: data.map(item => item.name)
      },
      yAxis: {
        type: 'value',
        axisLabel: {
          color: '#00A32E'
        },
        splitLine: {
          lineStyle: {
            color: 'rgba(0, 163, 46, .2)'
          }
        }
      },
      series: [
        {
          data: data.map(item => item.value),
          type: 'line',
          symbol: 'circle',
          symbolSize: 3,
          itemStyle: {
            color: '#00A32E'
          },
          lineStyle: {
            color: '#00A32E',
            width: '3'
          }
        }
      ]
    });
  };

  const monthOptionFn = (data) => {
    setMonthData(data);
    setMonthLineOption({
      grid: {
        left: '80'
      },
      tooltip: {
        trigger: 'axis',
        backgroundColor: 'rgba(0,0,0,0.7)',
        borderColor: '#00A32E',
        textStyle: {
          color: '#FFFFFF'
        }
      },
      xAxis: {
        type: 'category',
        axisLine: {
          lineStyle: {
            color: '#00A32E'
          }
        },
        axisTick: {
          show: false
        },
        axisLabel: {
          hideOverlap: true
        },
        data: data.map(item => item.name)
      },
      yAxis: {
        type: 'value',
        axisLabel: {
          color: '#00A32E'
        },
        splitLine: {
          lineStyle: {
            color: 'rgba(0, 163, 46, .2)'
          }
        }
      },
      series: [
        {
          data: data.map(item => item.value),
          type: 'line',
          symbol: 'circle',
          symbolSize: 3,
          itemStyle: {
            color: '#00A32E',
          },
          lineStyle: {
            color: '#00A32E',
            width: '3'
          }
        }
      ]
    });
  };

  const monthFn = () => {
    let monthData = [];
    monthData = detailData.monthsData.filter(item => item.period.indexOf(currentYear) > -1).map(item => {
      return {
        name: item.period,
        value: item.value
      };
    });
    monthOptionFn(monthData);
    setMonthLoading(false);
  };

  useEffect(() => {
    if (!country || !consumerTrend) return;
    // 静态文件数据跟api接口数据不一定一致，兼容处理
    const desList = country === 'US' ? descriptionData.US : descriptionData.GB;
    setDescription(desList.find(item => item.title === consumerTrend)?.content);
    const params = {
      country,
      consumerTrend
    };
    setYearLoading(true);
    setMonthLoading(true);
    fetchSearchDetails(params)
      .then(res => {
        setDetailData({
          yearsData: res.data.yearsData,
          monthsData: res.data.monthsData.sort((pre, next) => {
            return pre.period > next.period ? 1 : -1;
          })
        });
        const years = [];
        const yearData = [];
        res.data.yearsData.forEach(item => {
          years.push({
            label: item.period,
            value: item.period
          });
          yearData.push({
            name: item.period,
            value: item.value
          });
        });
        if (years.length) {
          setYearList(years);
          const index = years.length - 1;
          index > -1 && setCurrentYear(years[index].value);
          yearOptionFn(yearData);
        }
        setYearLoading(false);
        monthFn();
      })
      .catch(error => {
        setYearLoading(false);
        setMonthLoading(false);
        console.error(error);
      });
  }, [consumerTrend]);

  useEffect(() => {
    if (!currentYear) return;
    monthFn();
  }, [currentYear]);

  const tabClick = (tabIndex) => {
    setActiveTab(tabIndex);
  };

  // 导出excel
  const generatorExcel = () => {
    const yearExcel = utils.json_to_sheet(yearData.map(item => {
      return {
        year: item.name,
        value: item.value
      };
    }), {header: ['year', 'value']});
    const monthExcel = utils.json_to_sheet(monthData.map(item => {
      return {
        month: item.name,
        value: item.value
      };
    }), {header: ['month', 'value']});
    const wbYear = utils.book_new();
    // const wbMonth = utils.book_new();
    utils.book_append_sheet(wbYear, yearExcel);
    utils.book_append_sheet(wbYear, monthExcel);
    // utils.book_append_sheet(wbMonth, monthExcel);
    writeFile(wbYear, 'consumer_data_' + Date.now() + '.xlsx');
    // writeFile(wbMonth, Date.now() + '_month_data.xlsx');
  };

  useImperativeHandle(ref, () => ({
    setData({title, consumerTrend}) {
      setTitle(title);
      setActiveTab(null);
      setConsumerTrend(consumerTrend);
    }
  }));

  return (
    <div className={style.ConsumerTrendsPage}>
      <BorderContainer hideLeftCorner headerTitle="Consumer Trends">
        <div className={style.consumerMainContent}>
          <div className={style.mainMenuBox}>
            <div className={classNames(style.menuItemBrother)} onClick={() => tabClick(null)}>{consumerTrend}</div>
            <div className={classNames(style.menuItem, {
              [style.active]: activeTab === 1
            })} onClick={() => tabClick(1)}>Competitor Innovation</div>
            <div className={classNames(style.menuItem, {
              [style.active]: activeTab === 2
            })} onClick={() => tabClick(2)}>Search & Tik-tok</div>
          </div>
          <div className={style.lineBox}></div>
          <div className={style.contentBox}>
            {/* 基础信息 */}
            {!activeTab && <div className={style.informationBox}>
              <div className={style.headerTitle}>
                <div className={style.titleBox}>
                  <div className={style.arrow}>
                    <img src={triangleRight} alt="" />
                  </div>
                  <div className={style.titleInner}>{title}/{consumerTrend}</div>
                </div>
                <div className={style.exportBox} onClick={() => generatorExcel()}>
                  <div className={style.exportIcon}>
                    <img src={dialExport} alt="" />
                  </div>
                  Export
                </div>
              </div>
              <div className={style.descBox}>{description}</div>
              <div className={style.chartsBox}>
                <div className={style.chartsItem}>
                  <div className={style.chartsTitle}>
                    <div className={style.chartsTitleInner}>Number of searches in Google</div>
                  </div>
                  {<Spin indicator={antIcon} spinning={yearLoading}>
                    <LineCharts options={yearLineOption} />
                  </Spin>}
                </div>
                <div className={style.chartsItem}>
                  <div className={style.chartsTitle}>
                    <div className={style.chartsTitleInner}>Number of searches in Google</div>
                    <div className={style.inputBorder}>
                      <Select
                        placeholder='Date'
                        maxTagCount={1}
                        value={currentYear}
                        onChange={(newValue) => {
                          setCurrentYear(newValue);
                        }}
                        options={yearList}
                        popupMatchSelectWidth={false}
                      />
                    </div>
                  </div>
                  {<Spin indicator={antIcon} spinning={monthLoading}>
                    <LineCharts options={monthLineOption} />
                  </Spin>}
                </div>
              </div>
            </div>}
            {activeTab === 1 && <CompetitorInnovation country={country} consumerTrend={consumerTrend} />}
            {activeTab === 2 && <SearchTiktok country={country} consumerTrend={consumerTrend} />}
          </div>
        </div>
      </BorderContainer>
    </div>
  );
};

ConsumerTrends = forwardRef(ConsumerTrends);

export default ConsumerTrends;