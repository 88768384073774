import Dialog from './dialog.jsx';
import style from './help.module.scss';
import { QuestionCircleOutlined } from '@ant-design/icons';
import React, { useState } from 'react';

function PackageAiHelp({ className = '', children, hasBox = true }) {
  const [visible, setVisible] = useState(false);

  function onClick() {
    setVisible(true);
  }

  return (
    <div className={`${className}`}>
      <QuestionCircleOutlined className={style.helpIcon} onClick={onClick} />
      <Dialog visible={visible} setVisible={setVisible} hasBox={hasBox}>
        {children}
      </Dialog>
    </div>
  );
}

export default PackageAiHelp;
