// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Dr6FL9wqIP4HrsdNXVjG{margin-bottom:16px;display:flex;align-items:center}.Dr6FL9wqIP4HrsdNXVjG img{display:block;margin-right:20px;height:20px}.Dr6FL9wqIP4HrsdNXVjG .TWfwvjANvDKxDf6jW0kJ{font-size:16px;font-weight:900;line-height:22.3px;color:#fff}`, "",{"version":3,"sources":["webpack://./src/modules/package-ai-screening-batch/components/title2.module.scss"],"names":[],"mappings":"AAAA,sBACE,kBAAA,CACA,YAAA,CACA,kBAAA,CACA,0BACE,aAAA,CACA,iBAAA,CACA,WAAA,CAEF,4CACE,cAAA,CACA,eAAA,CACA,kBAAA,CACA,UAAA","sourcesContent":[".title {\n  margin-bottom: 16px;\n  display: flex;\n  align-items: center;\n  img {\n    display: block;\n    margin-right: 20px;\n    height: 20px;\n  }\n  .titleText {\n    font-size: 16px;\n    font-weight: 900;\n    line-height: 22.3px;\n    color: #fff;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": `Dr6FL9wqIP4HrsdNXVjG`,
	"titleText": `TWfwvjANvDKxDf6jW0kJ`
};
export default ___CSS_LOADER_EXPORT___;
