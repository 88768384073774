import React from 'react';
import { Select } from 'antd';
import Style from './brand-filter.module.scss';
 
const { Option } = Select;

const BrandSelect = ({ brands, selectedBrands, onChange, dropdownStyle }) => {
  return (
    <Select 
      mode="multiple" 
      maxTagCount={1}
      value={selectedBrands} 
      onChange={onChange} 
      className={`custom-select filter-select ${Style.brandSelect}`}
      dropdownStyle={dropdownStyle}
    >
      {brands.map(brand => {
        return (
          <Option key={brand.name} value={brand.name}>
            <input
              type="checkbox"
              className='topdish-filter-checkbox'
              checked={selectedBrands.includes(brand.name)}
              readOnly
            />
            {brand.name}
          </Option>
        );
      })}
    </Select>
  );
};

export default BrandSelect;
