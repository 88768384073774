import classNames from 'classnames';
import React from 'react';
import style from './index.module.scss';

const SUB_FILTERS = ['generation', 'gender', 'user_vs_nouser', 'social_class'];
const SUB_FILTER_DISPLAY = {
  generation: 'Generation',
  gender: 'Gender',
  user_vs_nouser: 'Category User vs Non-User',
  social_class: 'Social Class',
};

function SubFilter({ className = '', data, setData = () => {} }) {
  return (
    <div className={`${style.searchWrap} ${className}`}>
      <div className={style.title}>
        <s className={style.icon}></s>
        <span className={style.text}>Subgroup Filters</span>
      </div>
      {SUB_FILTERS.map((item, index) => {
        return (
          <div
            key={index}
            className={classNames(style.myRadioBtn, {
              [style.active]: data === item,
            })}
            onClick={() => {
              setData((prev) => {
                if (prev === item) return null;
                return item;
              });
            }}
          >
            <div className={style.text}>{SUB_FILTER_DISPLAY[item]}</div>
            <div className={style.bg}></div>
            <div className={style.outerCircleS}>
              <div className={style.innerCircleS}></div>
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default SubFilter;
