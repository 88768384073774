import React from 'react';
import style from './main.module.scss';

const SendUserBox = ({data}) => {
  return (
    <div className={style.sendUserBox}>
      {data}
    </div>
  );
};

export default SendUserBox;