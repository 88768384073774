import React, { useState, useEffect } from 'react';
import TrendsDialPage from './pages/trends-dial-page.jsx';
import { ConfigProvider, theme, App } from 'antd';

const TrendsDial = () => {

  // eslint-disable-next-line no-unused-vars
  const [token, ] = useState(sessionStorage.getItem('jwt-access-token'));

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    }, 500);
  });
  
  if (!token) {
    return null;
  }

  return (
    <>
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: '#00a32e',
            colorError: '#eb0000',
            colorSuccess: '#06ca3d',
            fontFamily: 'unilever-shilling-regular'
          },
          algorithm: theme.darkAlgorithm
        }}
      >
        <App>
          <TrendsDialPage />
        </App>
      </ConfigProvider>
    </>
  );
};

export default TrendsDial;
