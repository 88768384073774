import React, {createContext, useContext, useState} from 'react';
import { parseDate } from '../../../util/utils.jsx';

export const minDate = parseDate(new Date('2017-01-01T00:00:00'));
export const todayDate = parseDate(new Date());

export const emptyFilter = {
  brands: [],
  subcategories: [],
  markets: [],
  startDate: minDate,
  endDate: todayDate,
};

export const FiltersContext = createContext(null);

export const FiltersProvider = ({ children }) => {
  const [filters, setFilters] = useState(emptyFilter);
  
  const updateFilters = (filters) => {
    setFilters(filters);
  }; 
  // TODOs: possible methods
  // const updateBrands
  /*
  const updateBrands = (brands) => {
    setFilters({...filters, brands:brands});
  }
  */
  // const updateSubcategories
  // const updateMarket
  // const updateDates

  // TODO: useReducer?

  return (
    <FiltersContext.Provider value={{filters, updateFilters}}>
      {children}
    </FiltersContext.Provider>
  );
};

export const useFilters = () => {
  return useContext(FiltersContext);
};