// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.e1VmaBLoyI3KXdABdVBS{display:flex;align-items:center}.mEw0IklLSIZmufPyUoOe{flex:1}.CDEtuGmKVkWva3Lf93Ly{margin-right:5px;font-size:18px;color:#00a32e}`, "",{"version":3,"sources":["webpack://./src/modules/package-ai-screening/components/editableText.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,kBAAA,CAGF,sBACE,MAAA,CAGF,sBACE,gBAAA,CACA,cAAA,CACA,aAAA","sourcesContent":[".header {\n  display: flex;\n  align-items: center;\n}\n\n.headerBody {\n  flex: 1;\n}\n\n.penImg {\n  margin-right: 5px;\n  font-size: 18px;\n  color: #00a32e;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": `e1VmaBLoyI3KXdABdVBS`,
	"headerBody": `mEw0IklLSIZmufPyUoOe`,
	"penImg": `CDEtuGmKVkWva3Lf93Ly`
};
export default ___CSS_LOADER_EXPORT___;
