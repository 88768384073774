import React from 'react';
import BrandFilter from '../../../../../../components/competitor-innovation-filters/brand-filter/brand-filter.jsx';
import SubcategoryFilter from '../../../../../../components/competitor-innovation-filters/subcategory-filter/subcategory-filter.jsx';
import MarketFilter from '../../../../../../components/competitor-innovation-filters/market-filter/market-filter.jsx';
import DateFilter from '../../../../../../components/competitor-innovation-filters/date-filter/date-filter.jsx';
import { useFilters } from '../../../../hooks/useFilters.jsx';

function ConsumerFilters({
  consumerTrendsCategory,
  selectedCountry
}) {
  const {filters, updateFilters} = useFilters();

  return (
    <div className="consumer-filter-container">
      <div className="consumer-filters">
        <BrandFilter
          consumerTrendsCategory={consumerTrendsCategory}
          selectedCountry={selectedCountry}
          brands={filters.brands}
          updateFilters={updateFilters}
        />
        <SubcategoryFilter
          consumerTrendsCategory={consumerTrendsCategory}
          selectedCountry={selectedCountry}
          subcategories={filters.subcategories}
          updateFilters={updateFilters}
        />
        <MarketFilter
          consumerTrendsCategory={consumerTrendsCategory}
          selectedCountry={selectedCountry}
          markets={filters.markets}
          updateFilters={updateFilters}
        />
        <DateFilter
          startDate={filters.startDate}
          endDate={filters.endDate}
          updateFilters={updateFilters}
        />
      </div>
    </div>
  );
}
export default ConsumerFilters;
