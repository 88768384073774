import React from 'react';
import style from './divider.module.scss';

function Divider({ title }) {
  return (
    <div className={style.dividerBox}>
      <h2 className={style.dividerTitle}>{title}</h2>{' '}
    </div>
  );
}

export default Divider;
