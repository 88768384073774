import React, { useState, useEffect } from 'react';
const LazyImage = ({ src }) => {
  const [imageSrc, setImageSrc] = useState(null);

  useEffect(() => {
    if (!src) return;
    (async () => {
      const imgModule = await import(/* webpackChunkName: "trend-dial-solution" */ `../../../../assets/images/Emerging-food-solution/${src}`);
      setImageSrc(imgModule.default || imgModule.myImage);
    })();
  }, []);
  
  return (
    <>
      {!imageSrc && <p>Loading...</p>}
      {imageSrc && <img alt="lazy loaded image" src={imageSrc} />}
    </>
  );
};
 
export default LazyImage;