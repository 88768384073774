import React,{useRef,forwardRef} from 'react';
import './deep-dive-pop-up.scss';
import BorderContainer from '../../../trends-dial/components/border-container/border-container-small.jsx';
import DeepDiveBody from '../deep-dive-body/deep-dive-body.jsx'

const DeepDivePopup = forwardRef(({ isDeepDiveVisible,changeIsDeepDiveVisible,selectedWordCloud,loading,err,trendingDishData, onClose }, ref) => {
  return (
    <div className="deepDivee-pop-up" ref={ref}>
      <div className={`popup ${isDeepDiveVisible ? 'show' : ''}`}>
        <div className="popup-content">
          <BorderContainer hideLeftCorner headerTitle="Top Dish Variant Deep Dive" headerTitleSize='20px'>
            <DeepDiveBody changeIsDeepDiveVisible={changeIsDeepDiveVisible} selectedWordCloud={selectedWordCloud} loading={loading} err={err} trendingDishData={trendingDishData} />
          </BorderContainer>
        </div>
      </div>
    </div>
  );
});

DeepDivePopup.displayName = 'DeepDivePopup';

export default DeepDivePopup;