import React from 'react';
import classNames from 'classnames';
import style from './header.module.scss';

import PainImgLogo from '../../img/logo.svg';
import Profile from '@/components/profile/profile.jsx';

const PlantHeader = () => {
  return <>
    <div className={classNames(style.headerWrap, style.headerHeight)}>
      <img src={PainImgLogo} style={{ height: 70 }}></img>
      {/*<img src={PainImgTitle} style={{ height: 70 }}></img>*/}
      <div className={style.headerText}>Unmissable Pain Points</div>
      {/*<img src={PainImgUser} style={{ position: 'absolute',right:20,height:55 }}></img>*/}
      <Profile />
    </div>
    <div className={classNames(style.headerHeight)}></div>
  </>;
};

export default PlantHeader;
