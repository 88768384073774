import { CloseOutlined } from '@ant-design/icons';
import classNames from 'classnames';
import React from 'react';
import TitlePng from '../assets/img/title.png';
import style from './dialog2.module.scss';

function PackageAiDialog({
  className,
  visible,
  setVisible,
  title,
  children,
  zIndex = 2001,
}) {
  return (
    <div
      className={classNames(style.dialogContainer, className)}
      style={{ display: visible ? 'block' : 'none', zIndex: zIndex }}
    >
      <div className={style.dialogModal} onClick={() => setVisible(false)} />
      <div className={style.dialogContent}>
        <div className={style.dialogContentInner}>
          <div className={style.dialogHeader}>
            <div
              className={style.dialogTitle}
              style={{ backgroundImage: `url(${TitlePng})` }}
            >
              <span className={style.text}>{title}</span>
            </div>
            <div
              className={style.dialogClose}
              onClick={() => setVisible(false)}
            >
              <CloseOutlined className={style.icon} />
            </div>
          </div>
          <div className={style.dialogBody}>{children}</div>
        </div>
        {/* <div
          className={`${style.boxCorner} ${style.boxCornerTop} ${style.boxCornerLeft}`}
        /> */}
        <div
          className={`${style.boxCorner} ${style.boxCornerTop} ${style.boxCornerRight}`}
        />
        <div
          className={`${style.boxCorner} ${style.boxCornerBottom} ${style.boxCornerLeft}`}
        />
        <div
          className={`${style.boxCorner} ${style.boxCornerBottom} ${style.boxCornerRight}`}
        />
      </div>
    </div>
  );
}

export default PackageAiDialog;
