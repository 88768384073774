// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.W8dDWVtl8mBZptoGiGlZ{padding:5px 15px}.pShe2_3f8MloKufyHrqa{color:#00a32e;font-size:12px}.ahd4HOdgtgoZng4MWdGQ{text-align:end}.iMaDYR9o9JEz9w4rlXaX{margin-top:3px;display:flex;align-items:center}.efJgSbAENYeNGYCuGaJ8{width:12px;height:12px;margin-right:5px;border-radius:50%}.ibvS0m7CMxErQ1O6wVw4{background-color:#da0000}.le_f4Q8V1MYsYROxthhT{background-color:#ffbb38}.KKv8G0EFxg2LvsBYMiKA{background-color:#00a32e}.S_JhK8HJxS2CuzlsPPQx{color:#00a32e;font-size:12px}`, "",{"version":3,"sources":["webpack://./src/modules/package-ai-screening/components/legend.module.scss"],"names":[],"mappings":"AAAA,sBACE,gBAAA,CAGF,sBACE,aAAA,CACA,cAAA,CAGF,sBACE,cAAA,CAGF,sBACE,cAAA,CACA,YAAA,CACA,kBAAA,CAGF,sBACE,UAAA,CACA,WAAA,CACA,gBAAA,CACA,iBAAA,CAGF,sBACE,wBAAA,CAGF,sBACE,wBAAA,CAGF,sBACE,wBAAA,CAGF,sBACE,aAAA,CACA,cAAA","sourcesContent":[".boxInner {\n  padding: 5px 15px;\n}\n\n.text1 {\n  color: #00a32e;\n  font-size: 12px;\n}\n\n.text1a {\n  text-align: end;\n}\n\n.box11 {\n  margin-top: 3px;\n  display: flex;\n  align-items: center;\n}\n\n.icon {\n  width: 12px;\n  height: 12px;\n  margin-right: 5px;\n  border-radius: 50%;\n}\n\n.dataRed {\n  background-color: #da0000;\n}\n\n.dataYellow {\n  background-color: #ffbb38;\n}\n\n.dataGreen {\n  background-color: #00a32e;\n}\n\n.text2 {\n  color: #00a32e;\n  font-size: 12px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"boxInner": `W8dDWVtl8mBZptoGiGlZ`,
	"text1": `pShe2_3f8MloKufyHrqa`,
	"text1a": `ahd4HOdgtgoZng4MWdGQ`,
	"box11": `iMaDYR9o9JEz9w4rlXaX`,
	"icon": `efJgSbAENYeNGYCuGaJ8`,
	"dataRed": `ibvS0m7CMxErQ1O6wVw4`,
	"dataYellow": `le_f4Q8V1MYsYROxthhT`,
	"dataGreen": `KKv8G0EFxg2LvsBYMiKA`,
	"text2": `S_JhK8HJxS2CuzlsPPQx`
};
export default ___CSS_LOADER_EXPORT___;
