import { ConfigProvider, theme } from 'antd';
import React, { useState } from 'react';
import BackgroundPng from '../../assets/images/home/bg.png';
import Logo from './components/logo.jsx';
import Title from './components/title.jsx';
import { OccasionHomeBall } from './components/ball.jsx';
import style from './home.module.scss';
import PhotoItemsJson from '../../assets/json/photo-items.json';
import DashboardItemsJson from '../../assets/json/dashboard-items.json';
import Btn1Png from '../../assets/images/home/btn1.png';

function OccasionHome() {
  const [photoItemsDisplay, setPhotoItemsDisplay] = useState(false);
  const [dashboardItemsDisplay, setDashboardItemsDisplay] = useState(false);

  function onPhotoClick() {
    setPhotoItemsDisplay(!photoItemsDisplay);
    if (dashboardItemsDisplay) setDashboardItemsDisplay(false);
  }
  function onDashboardClick() {
    setDashboardItemsDisplay(!dashboardItemsDisplay);
    if (photoItemsDisplay) setPhotoItemsDisplay(false);
  }

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: '#00a32e',
          colorError: '#eb0000',
          colorSuccess: '#06ca3d',
          fontFamily: 'unilever-shilling-regular',
        },
        algorithm: theme.darkAlgorithm,
      }}
    >
      <div className={style.homeContainer}>
        <div className={style.homeBgBox}>
          <img src={BackgroundPng} />
          <div className={style.homeBgItem}></div>
        </div>
        <Logo />
        <Title />
        <div className={style.homeBallLine}>
          <OccasionHomeBall
            title={
              <>
                Occasions
                <br />
                Dashboard
              </>
            }
            items={DashboardItemsJson.map((e) => {
              return { ...e, url: `/occasion-dashboard?tab=${e.name}` };
            })}
            itemsDisplay={dashboardItemsDisplay}
            onClick={onDashboardClick}
          />
          <OccasionHomeBall
            title={
              <>
                Occasions
                <br />
                Photo
              </>
            }
            items={PhotoItemsJson.map((e) => {
              return { ...e, url: `/occasion-photo?category=${e.name}` };
            })}
            descAtLeft={true}
            itemsDisplay={photoItemsDisplay}
            onClick={onPhotoClick}
          />
        </div>
        <div className={style.navBox}>
          <a href="https://unilever-my.sharepoint.com/personal/feranmi_muraina_unilever_com/_layouts/15/stream.aspx?id=%2Fpersonal%2Fferanmi%5Fmuraina%5Funilever%5Fcom%2FDocuments%2FRecordings%2FCMI%20Occasions%20Dashboard%20Training%2D20240926%5F100222%2DMeeting%20Recording%2Emp4&nav=eyJyZWZlcnJhbEluZm8iOnsicmVmZXJyYWxBcHAiOiJTdHJlYW1XZWJBcHAiLCJyZWZlcnJhbFZpZXciOiJTaGFyZURpYWxvZy1MaW5rIiwicmVmZXJyYWxBcHBQbGF0Zm9ybSI6IldlYiIsInJlZmVycmFsTW9kZSI6InZpZXcifX0%3D&nav=eyJyZWZlcnJhbEluZm8iOnsicmVmZXJyYWxBcHAiOiJTdHJlYW1XZWJBcHAiLCJyZWZlcnJhbFZpZXciOiJTaGFyZURpYWxvZy1MaW5rIiwicmVmZXJyYWxBcHBQbGF0Zm9ybSI6IldlYiIsInJlZmVycmFsTW9kZSI6InZpZXcifX0=&ga=1">
            <div className={style.navBtn}>
              <img src={Btn1Png} />
              <span className={style.navBtnText}>Go to Tutorial Video</span>
            </div>
          </a>
        </div>
      </div>
    </ConfigProvider>
  );
}
export default OccasionHome;
