import { message } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import {
  deleteKnorrFavorite,
  getKnorrById,
  getKnorrFavorite,
  getKnorrMedia,
  postKnorrFavorite
} from '../apis/serve';
import KnorrDetail from './detail.jsx';

function KnorrDetailId({ id, onClose }) {
  const [data, setData] = useState({});
  async function readData() {
    if (!id) return;
    try {
      const { data: res } = await getKnorrById(id);
      if (!res) {
        message.warning('Knorr data not found');
        onClose && onClose();
        return;
      }
      setData(res);
    } catch (err) {
      console.error('read data error', err);
      message.error('Read data error');
    }
  }

  const [media, setMedia] = useState('');
  const [images, setImages] = useState([]);
  const [mediaType, setMediaType] = useState('unknown');
  async function readMedia() {
    try {
      if (!id) return;
      const { data: res } = await getKnorrMedia(id);
      if (!res.length) {
        return;
      }
      setMediaType(res[0].type);
      setMedia(res[0].url);
      if (res[0].type === 'image') {
        setImages(res.map((item) => item.url));
      }
    } catch (err) {
      message.error('Read media error');
      console.error('read media error', err);
    }
  }

  const [isFav, setFav] = useState(false);
  const [favoriteCount, setFavoriteCount] = useState(0);
  async function readFav() {
    try {
      if (!id) return;
      const { data: res } = await getKnorrFavorite(id);
      setFav(res.status);
      setFavoriteCount(res.count);
    } catch (err) {
      message.error('Read favorite error');
      console.error('read favorite error', err);
    }
  }
  async function onClickFav(ev) {
    try {
      ev.stopPropagation();
      if (!id) return;
      if (!isFav) {
        await postKnorrFavorite(id);
        void readFav();
      } else {
        await deleteKnorrFavorite(id);
        void readFav();
      }
    } catch (err) {
      message.error('Favorite error');
      console.error('favorite error', err);
    }
  }
  const favCountStr = useMemo(() => {
    if (favoriteCount > 10 ** 6) {
      return `${favoriteCount / 10 ** 6} M`;
    } else if (favoriteCount > 10 ** 3) {
      return `${favoriteCount / 10 ** 3} k`;
    } else {
      return `${favoriteCount}`;
    }
  }, [favoriteCount]);

  useEffect(() => {
    void readData();
    void readFav();
    void readMedia();
  }, [id]);

  return (
    <KnorrDetail
      data={data}
      mediaType={mediaType}
      images={images}
      video={media}
      isFav={isFav}
      favCountStr={favCountStr}
      onFav={onClickFav}
      onClose={onClose}
    ></KnorrDetail>
  );
}

export default KnorrDetailId;
