// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.QDGe8wXu7gkMF5t5VM8x{width:100%;padding-top:100%;height:0;position:relative;border-radius:10px;overflow:hidden;cursor:pointer}.QDGe8wXu7gkMF5t5VM8x img{position:absolute;top:0;left:0;width:100%;height:100%;object-fit:contain;display:block}.QDGe8wXu7gkMF5t5VM8x .E27S6C6EldC502WXpjnt{display:none}.QDGe8wXu7gkMF5t5VM8x:hover .E27S6C6EldC502WXpjnt{display:flex}.E27S6C6EldC502WXpjnt{position:absolute;top:0;left:0;right:0;z-index:11;display:flex;justify-content:end;align-items:center;background-color:rgba(15,15,15,.3098039216)}.E27S6C6EldC502WXpjnt .TF3scg_CUCpCPjUE1B_A{margin:0 5px;cursor:pointer}.E27S6C6EldC502WXpjnt .NxjhDueOGngD_LYedaIp{height:14px;color:#00a32e}`, "",{"version":3,"sources":["webpack://./src/modules/package-ai-generation/views/update/item.module.scss"],"names":[],"mappings":"AAAA,sBACE,UAAA,CACA,gBAAA,CACA,QAAA,CACA,iBAAA,CACA,kBAAA,CACA,eAAA,CACA,cAAA,CAEA,0BACE,iBAAA,CACA,KAAA,CACA,MAAA,CACA,UAAA,CACA,WAAA,CACA,kBAAA,CACA,aAAA,CAGF,4CACE,YAAA,CAGA,kDACE,YAAA,CAKN,sBACE,iBAAA,CACA,KAAA,CACA,MAAA,CACA,OAAA,CACA,UAAA,CACA,YAAA,CACA,mBAAA,CACA,kBAAA,CACA,2CAAA,CAEA,4CACE,YAAA,CACA,cAAA,CAGF,4CACE,WAAA,CACA,aAAA","sourcesContent":[".itemBox {\n  width: 100%;\n  padding-top: 100%;\n  height: 0;\n  position: relative;\n  border-radius: 10px;\n  overflow: hidden;\n  cursor: pointer;\n\n  img {\n    position: absolute;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n    object-fit: contain;\n    display: block;\n  }\n\n  .actionBox {\n    display: none;\n  }\n  &:hover {\n    .actionBox {\n      display: flex;\n    }\n  }\n}\n\n.actionBox {\n  position: absolute;\n  top: 0;\n  left: 0;\n  right: 0;\n  z-index: 11;\n  display: flex;\n  justify-content: end;\n  align-items: center;\n  background-color: #0f0f0f4f;\n\n  .btn {\n    margin: 0 5px;\n    cursor: pointer;\n  }\n\n  .icon {\n    height: 14px;\n    color: #00a32e;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"itemBox": `QDGe8wXu7gkMF5t5VM8x`,
	"actionBox": `E27S6C6EldC502WXpjnt`,
	"btn": `TF3scg_CUCpCPjUE1B_A`,
	"icon": `NxjhDueOGngD_LYedaIp`
};
export default ___CSS_LOADER_EXPORT___;
