import React from 'react';
import FilterGroups from '../../assets/json/filter-groups.json';
import Filters1 from '../../assets/json/filters-1.json';
import Filters10 from '../../assets/json/filters-10.json';
import Filters11 from '../../assets/json/filters-11.json';
import Filters12 from '../../assets/json/filters-12.json';
import Filters2 from '../../assets/json/filters-2.json';
import Filters3 from '../../assets/json/filters-3.json';
import Filters4 from '../../assets/json/filters-4.json';
import Filters5 from '../../assets/json/filters-5.json';
import Filters6 from '../../assets/json/filters-6.json';
import Filters7 from '../../assets/json/filters-7a.json';
import Filters8 from '../../assets/json/filters-8.json';
import Filters9 from '../../assets/json/filters-9.json';
import FiltersOther from '../../assets/json/filters-other.json';
import { OccasionFilterSide } from './components/filter.jsx';

const Filters = [
  Filters1,
  Filters2,
  Filters3,
  Filters4,
  Filters5,
  Filters6,
  Filters7,
  Filters8,
  Filters9,
  Filters10,
  Filters11,
  Filters12,
].map((e) => e.map((e1) => ({ ...e1, name: e1.label })));

function OccasionDashboardFilter({
  setFilterData,
  setCompareData,
  hasCompare,
  setHasCompare,
  startGroup,
  onClearFilter,
}) {
  return (
    <OccasionFilterSide
      otherFilters={FiltersOther.map((e) => ({ ...e, name: e.label }))}
      groups={FilterGroups}
      groupedFilters={Filters}
      setFilterData={setFilterData}
      setCompareData={setCompareData}
      hasCompare={hasCompare}
      setHasCompare={setHasCompare}
      startGroup={startGroup}
      onClearFilter={onClearFilter}
    />
  );
}

export default OccasionDashboardFilter;
