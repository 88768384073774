import React, {useEffect, useState} from 'react';
import style from './product-deep-dive.module.scss';
import LinkIcon from '../../../../assets/images/ratings-review/link.svg';
import classNames from 'classnames';
import NoData from '../no-data/no-data.jsx';

const ArticleBox = ({product}) => {
  const [btnActive, setBtnActive] = useState('1');
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [productList, setProductList] = useState([]);
  useEffect(() => {
    let type = btnActive;
    let btnDisabled = false;
    if (product.type === 'product') {
      type = '1';
      btnDisabled = true;
    } else {
      btnDisabled = false;
    }
    setBtnActive(type);
    setBtnDisabled(btnDisabled);
    if (type === '1') {
      setProductList(Array.isArray(product.reviews) ? product.reviews.filter(item => item.review_body) : []);
    }
    if (btnDisabled) return;
    if (type === '2') {
      setProductList(Array.isArray(product.cec) ? product.cec.map(item => {
        return {
          ...item,
          review_title: item.case_verbatim_comments,
          review_body: item.case_description,
          review_url: ''
        };
      }).filter(item => item.review_body) : []);
    }
  }, [product]);
  const onClickBtn = (type) => {
    if (type === '1') {
      setProductList(Array.isArray(product.reviews) ? product.reviews.filter(item => item.review_body) : []);
      setBtnActive(type);
    }
    if (btnDisabled) return;
    if (type === '2') {
      setProductList(Array.isArray(product.cec) ? product.cec.map(item => {
        return {
          ...item,
          review_title: item.case_verbatim_comments,
          review_body: item.case_description,
          review_url: ''
        };
      }).filter(item => item.review_body) : []);
      setBtnActive(type);
    }
  };
  return (
    <div className={style.articleContainer}>
      <div className={style.articleBtnGroup}>
        <div className={
          classNames(
            style.btnItem, 
            {[style.active]: btnActive === '1'}
          )} 
        onClick={() => onClickBtn('1')}
        >Verbatims</div>
        <div className={
          classNames(style.btnItem, 
            {
              [style.active]: btnActive === '2', 
              [style.disabled]: btnDisabled
            }
          )}
        onClick={() => onClickBtn('2')}
        >CEC</div>
      </div>
      <div className={style.articleBox}>
        {productList.length === 0 && <NoData />}
        {productList.length > 0 &&
          productList.map((item, index) => {
            return (
              <div key={index} className={style.articleItem}>
                <div className={style.articleContent}>{item.review_body}</div>
                {item.review_url && <div className={style.articleLink} onClick={() => window.open(item.review_url, '_blank')}>
                  <img src={LinkIcon} alt="" />
                </div>}
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default ArticleBox;