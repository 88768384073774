import React, { useRef, useState } from 'react';
import DetailTitle from '../detail-title/detail-title.jsx';
import './key-ingredients.scss';
import { ingredientShare, ingredientGrowth } from '../../../../components/icons/icons.jsx';

const ingredients = [
    { name: 'Buttermilk', socialShare: 6.71, monthlyGrowth: 31.1, ranking: 1, img: 'https://s3-alpha-sig.figma.com/img/2421/f8fd/e4de1de4863d42b73a58e109d7f575e3?Expires=1731888000&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=GaFhpcZ2S9puu85Jvc64Q1iEYCWXLQAcl2mFrvYr535R1sqZT1AeZUFf~KTlkrj2~mGvsDZ3Plnr8qHi-QZZHlmT88oqP0f-uE7hvfqsnECUVpAuYd9wTbMeW-BQ99saA8~IcGyZM3H4UrQqmhV1rot1aprjGyOtHb1BHJho11NJXFDcb6RDjXIZpjSmTeIwfZL1cwYfW9sXKIa4DKMsGG-45m-niZcalNHnWPitxCN7SoF4RHqaUiazDHHqPQxde3~Ik6bbq4VAyyhGYbezlNxs1Xs9FKNF1fyDrxwA1AcupcQta9BoyF0DYBMmdqfndZInlv3~S93D5JZoVAw9xA__' },
    { name: 'Buttermilk', socialShare: 6.71, monthlyGrowth: 31.1, ranking: 1, img: 'https://s3-alpha-sig.figma.com/img/2421/f8fd/e4de1de4863d42b73a58e109d7f575e3?Expires=1731888000&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=GaFhpcZ2S9puu85Jvc64Q1iEYCWXLQAcl2mFrvYr535R1sqZT1AeZUFf~KTlkrj2~mGvsDZ3Plnr8qHi-QZZHlmT88oqP0f-uE7hvfqsnECUVpAuYd9wTbMeW-BQ99saA8~IcGyZM3H4UrQqmhV1rot1aprjGyOtHb1BHJho11NJXFDcb6RDjXIZpjSmTeIwfZL1cwYfW9sXKIa4DKMsGG-45m-niZcalNHnWPitxCN7SoF4RHqaUiazDHHqPQxde3~Ik6bbq4VAyyhGYbezlNxs1Xs9FKNF1fyDrxwA1AcupcQta9BoyF0DYBMmdqfndZInlv3~S93D5JZoVAw9xA__' },
    { name: 'Buttermilk', socialShare: 6.71, monthlyGrowth: 31.1, ranking: 1, img: 'https://s3-alpha-sig.figma.com/img/2421/f8fd/e4de1de4863d42b73a58e109d7f575e3?Expires=1731888000&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=GaFhpcZ2S9puu85Jvc64Q1iEYCWXLQAcl2mFrvYr535R1sqZT1AeZUFf~KTlkrj2~mGvsDZ3Plnr8qHi-QZZHlmT88oqP0f-uE7hvfqsnECUVpAuYd9wTbMeW-BQ99saA8~IcGyZM3H4UrQqmhV1rot1aprjGyOtHb1BHJho11NJXFDcb6RDjXIZpjSmTeIwfZL1cwYfW9sXKIa4DKMsGG-45m-niZcalNHnWPitxCN7SoF4RHqaUiazDHHqPQxde3~Ik6bbq4VAyyhGYbezlNxs1Xs9FKNF1fyDrxwA1AcupcQta9BoyF0DYBMmdqfndZInlv3~S93D5JZoVAw9xA__' },
    { name: 'Buttermilk', socialShare: 6.71, monthlyGrowth: 31.1, ranking: 1, img: 'https://s3-alpha-sig.figma.com/img/2421/f8fd/e4de1de4863d42b73a58e109d7f575e3?Expires=1731888000&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=GaFhpcZ2S9puu85Jvc64Q1iEYCWXLQAcl2mFrvYr535R1sqZT1AeZUFf~KTlkrj2~mGvsDZ3Plnr8qHi-QZZHlmT88oqP0f-uE7hvfqsnECUVpAuYd9wTbMeW-BQ99saA8~IcGyZM3H4UrQqmhV1rot1aprjGyOtHb1BHJho11NJXFDcb6RDjXIZpjSmTeIwfZL1cwYfW9sXKIa4DKMsGG-45m-niZcalNHnWPitxCN7SoF4RHqaUiazDHHqPQxde3~Ik6bbq4VAyyhGYbezlNxs1Xs9FKNF1fyDrxwA1AcupcQta9BoyF0DYBMmdqfndZInlv3~S93D5JZoVAw9xA__' },
    { name: 'Buttermilk', socialShare: 6.71, monthlyGrowth: 31.1, ranking: 1, img: 'https://s3-alpha-sig.figma.com/img/2421/f8fd/e4de1de4863d42b73a58e109d7f575e3?Expires=1731888000&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=GaFhpcZ2S9puu85Jvc64Q1iEYCWXLQAcl2mFrvYr535R1sqZT1AeZUFf~KTlkrj2~mGvsDZ3Plnr8qHi-QZZHlmT88oqP0f-uE7hvfqsnECUVpAuYd9wTbMeW-BQ99saA8~IcGyZM3H4UrQqmhV1rot1aprjGyOtHb1BHJho11NJXFDcb6RDjXIZpjSmTeIwfZL1cwYfW9sXKIa4DKMsGG-45m-niZcalNHnWPitxCN7SoF4RHqaUiazDHHqPQxde3~Ik6bbq4VAyyhGYbezlNxs1Xs9FKNF1fyDrxwA1AcupcQta9BoyF0DYBMmdqfndZInlv3~S93D5JZoVAw9xA__' },
    { name: 'Buttermilk', socialShare: 6.71, monthlyGrowth: 31.1, ranking: 1, img: 'https://s3-alpha-sig.figma.com/img/2421/f8fd/e4de1de4863d42b73a58e109d7f575e3?Expires=1731888000&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=GaFhpcZ2S9puu85Jvc64Q1iEYCWXLQAcl2mFrvYr535R1sqZT1AeZUFf~KTlkrj2~mGvsDZ3Plnr8qHi-QZZHlmT88oqP0f-uE7hvfqsnECUVpAuYd9wTbMeW-BQ99saA8~IcGyZM3H4UrQqmhV1rot1aprjGyOtHb1BHJho11NJXFDcb6RDjXIZpjSmTeIwfZL1cwYfW9sXKIa4DKMsGG-45m-niZcalNHnWPitxCN7SoF4RHqaUiazDHHqPQxde3~Ik6bbq4VAyyhGYbezlNxs1Xs9FKNF1fyDrxwA1AcupcQta9BoyF0DYBMmdqfndZInlv3~S93D5JZoVAw9xA__' },
    { name: 'Buttermilk', socialShare: 6.71, monthlyGrowth: 31.1, ranking: 1, img: 'https://s3-alpha-sig.figma.com/img/2421/f8fd/e4de1de4863d42b73a58e109d7f575e3?Expires=1731888000&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=GaFhpcZ2S9puu85Jvc64Q1iEYCWXLQAcl2mFrvYr535R1sqZT1AeZUFf~KTlkrj2~mGvsDZ3Plnr8qHi-QZZHlmT88oqP0f-uE7hvfqsnECUVpAuYd9wTbMeW-BQ99saA8~IcGyZM3H4UrQqmhV1rot1aprjGyOtHb1BHJho11NJXFDcb6RDjXIZpjSmTeIwfZL1cwYfW9sXKIa4DKMsGG-45m-niZcalNHnWPitxCN7SoF4RHqaUiazDHHqPQxde3~Ik6bbq4VAyyhGYbezlNxs1Xs9FKNF1fyDrxwA1AcupcQta9BoyF0DYBMmdqfndZInlv3~S93D5JZoVAw9xA__' },
    { name: 'Buttermilk', socialShare: 6.71, monthlyGrowth: 31.1, ranking: 1, img: 'https://s3-alpha-sig.figma.com/img/2421/f8fd/e4de1de4863d42b73a58e109d7f575e3?Expires=1731888000&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=GaFhpcZ2S9puu85Jvc64Q1iEYCWXLQAcl2mFrvYr535R1sqZT1AeZUFf~KTlkrj2~mGvsDZ3Plnr8qHi-QZZHlmT88oqP0f-uE7hvfqsnECUVpAuYd9wTbMeW-BQ99saA8~IcGyZM3H4UrQqmhV1rot1aprjGyOtHb1BHJho11NJXFDcb6RDjXIZpjSmTeIwfZL1cwYfW9sXKIa4DKMsGG-45m-niZcalNHnWPitxCN7SoF4RHqaUiazDHHqPQxde3~Ik6bbq4VAyyhGYbezlNxs1Xs9FKNF1fyDrxwA1AcupcQta9BoyF0DYBMmdqfndZInlv3~S93D5JZoVAw9xA__' },
    { name: 'Buttermilk', socialShare: 6.71, monthlyGrowth: 31.1, ranking: 1, img: 'https://s3-alpha-sig.figma.com/img/2421/f8fd/e4de1de4863d42b73a58e109d7f575e3?Expires=1731888000&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=GaFhpcZ2S9puu85Jvc64Q1iEYCWXLQAcl2mFrvYr535R1sqZT1AeZUFf~KTlkrj2~mGvsDZ3Plnr8qHi-QZZHlmT88oqP0f-uE7hvfqsnECUVpAuYd9wTbMeW-BQ99saA8~IcGyZM3H4UrQqmhV1rot1aprjGyOtHb1BHJho11NJXFDcb6RDjXIZpjSmTeIwfZL1cwYfW9sXKIa4DKMsGG-45m-niZcalNHnWPitxCN7SoF4RHqaUiazDHHqPQxde3~Ik6bbq4VAyyhGYbezlNxs1Xs9FKNF1fyDrxwA1AcupcQta9BoyF0DYBMmdqfndZInlv3~S93D5JZoVAw9xA__' },
    { name: 'Buttermilk', socialShare: 6.71, monthlyGrowth: 31.1, ranking: 1, img: 'https://s3-alpha-sig.figma.com/img/2421/f8fd/e4de1de4863d42b73a58e109d7f575e3?Expires=1731888000&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=GaFhpcZ2S9puu85Jvc64Q1iEYCWXLQAcl2mFrvYr535R1sqZT1AeZUFf~KTlkrj2~mGvsDZ3Plnr8qHi-QZZHlmT88oqP0f-uE7hvfqsnECUVpAuYd9wTbMeW-BQ99saA8~IcGyZM3H4UrQqmhV1rot1aprjGyOtHb1BHJho11NJXFDcb6RDjXIZpjSmTeIwfZL1cwYfW9sXKIa4DKMsGG-45m-niZcalNHnWPitxCN7SoF4RHqaUiazDHHqPQxde3~Ik6bbq4VAyyhGYbezlNxs1Xs9FKNF1fyDrxwA1AcupcQta9BoyF0DYBMmdqfndZInlv3~S93D5JZoVAw9xA__' },
    { name: 'Buttermilk', socialShare: 6.71, monthlyGrowth: 31.1, ranking: 1, img: 'https://s3-alpha-sig.figma.com/img/2421/f8fd/e4de1de4863d42b73a58e109d7f575e3?Expires=1731888000&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=GaFhpcZ2S9puu85Jvc64Q1iEYCWXLQAcl2mFrvYr535R1sqZT1AeZUFf~KTlkrj2~mGvsDZ3Plnr8qHi-QZZHlmT88oqP0f-uE7hvfqsnECUVpAuYd9wTbMeW-BQ99saA8~IcGyZM3H4UrQqmhV1rot1aprjGyOtHb1BHJho11NJXFDcb6RDjXIZpjSmTeIwfZL1cwYfW9sXKIa4DKMsGG-45m-niZcalNHnWPitxCN7SoF4RHqaUiazDHHqPQxde3~Ik6bbq4VAyyhGYbezlNxs1Xs9FKNF1fyDrxwA1AcupcQta9BoyF0DYBMmdqfndZInlv3~S93D5JZoVAw9xA__' },
    { name: 'Buttermilk', socialShare: 6.71, monthlyGrowth: 31.1, ranking: 1, img: 'https://s3-alpha-sig.figma.com/img/2421/f8fd/e4de1de4863d42b73a58e109d7f575e3?Expires=1731888000&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=GaFhpcZ2S9puu85Jvc64Q1iEYCWXLQAcl2mFrvYr535R1sqZT1AeZUFf~KTlkrj2~mGvsDZ3Plnr8qHi-QZZHlmT88oqP0f-uE7hvfqsnECUVpAuYd9wTbMeW-BQ99saA8~IcGyZM3H4UrQqmhV1rot1aprjGyOtHb1BHJho11NJXFDcb6RDjXIZpjSmTeIwfZL1cwYfW9sXKIa4DKMsGG-45m-niZcalNHnWPitxCN7SoF4RHqaUiazDHHqPQxde3~Ik6bbq4VAyyhGYbezlNxs1Xs9FKNF1fyDrxwA1AcupcQta9BoyF0DYBMmdqfndZInlv3~S93D5JZoVAw9xA__' }
]


function KeyIngredients() {
    const IngredientItem = (item) => {
        const e = item.item
        return (
            <div className='IngredientBox'>
                <img className='IngredientImage' src={e.img} />
                <div className='IngredientText'>
                    <div className='ranking'>#{e.ranking}</div>
                    <div className='name'>{e.name}</div>
                    <div className='share'>
                        <div className='text'>Social share</div>
                        <div className='value'>
                            <img src={ingredientShare}/>
                            <div>{e.socialShare}%</div>
                        </div>
                    </div>
                    <div className='growth'>
                        <div className='text'>Monthly growth</div>
                        <div className='value'>
                            <img src={ingredientGrowth}/>
                            <div>{e.monthlyGrowth}%</div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    return (
        <>
            <DetailTitle title={'Key Ingredients'} />
            <div className='explain'>Top and Trending Ingredients for Fried Chicken Tikka</div>
            <div className='Ingredients'>
                {ingredients.map((item, index) => (
                    <IngredientItem item={item} key={index} />
                ))}
            </div>
        </>
    )
}

export default KeyIngredients;