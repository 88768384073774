import React, { useRef, useEffect } from 'react';

const Sort = ({ sortOrder, handleSort}) => {
  const sortRef = useRef(null);

  const handleOptionChange = (e) => {
    const newSortOrder = e.target.value;
    handleSort(newSortOrder);
  };

  return (
    <div className="sortContainer" ref={sortRef}>
      <label>
      High to Low
      <input
          type="radio"
          name="sort"
          value="high-to-low"
          checked={sortOrder === 'high-to-low'}
          onChange={handleOptionChange}
        />
      </label>
      <label>
        Low to High
        <input
          type="radio"
          name="sort"
          value="low-to-high"
          checked={sortOrder === 'low-to-high'}
          onChange={handleOptionChange}
        />
      </label>
      <label>
        As Is
        <input
          type="radio"
          name="sort"
          value="as-is"
          className='asIsButton'
          checked={sortOrder === 'as-is'}
          onChange={handleOptionChange}
        />
      </label>
    </div>

  );
};

export default Sort;
