import React, { useState } from 'react';
import { Table } from 'antd';
import { useNavigate } from 'react-router-dom';

const dataSource = Array.from({ length: 100 }).map((_, i) => ({
  key: i,
  projectId: '32' + i,
  projectName: 'Edward King',
  status: 'Active',
  startDate: '2024-10-30',
  expires: '2025-12-12',
  responses: '200'
}));

const ProjectPage = () => {
  const nvigate = useNavigate();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const rowSelection = {
    selectedRowKeys,
    onChange: setSelectedRowKeys,
    columnWidth: 50
  };

  const columns = [
    {
      title: 'Project ID',
      width: 150,
      dataIndex: 'projectId',
      key: 'projectId',
      fixed: 'left',
      align: 'center'
    },
    {
      title: 'Project Name',
      dataIndex: 'projectName',
      key: 'projectName',
      align: 'center',
      minWidth: 200
    },
    {
      title: 'Status',
      width: 150,
      dataIndex: 'status',
      key: 'status',
      align: 'center',
      render: (value) => {
        return <div className='text-center py-[5px] px-[15px] inline-block rounded-xl bg-second' onClick={fnOpenDetail}>
          {value}
        </div>;
      }
    },
    {
      title: 'Start Date',
      width: 200,
      dataIndex: 'startDate',
      key: 'startDate',
      align: 'center'
    },
    {
      title: 'Expires',
      width: 200,
      dataIndex: 'expires',
      key: 'expires',
      align: 'center'
    },
    {
      title: 'Responses',
      width: 200,
      dataIndex: 'responses',
      key: 'responses',
      align: 'center'
    },
  ];

  const fnOpenDetail = () => {
    nvigate('/consumer-theatre-processing/project/detail');
  };

  return (
    <div className="px-[30px] py-[30px]">
      <Table
        className="ctp-table"
        bordered={false}
        rowSelection={rowSelection}
        columns={columns}
        dataSource={dataSource}
        scroll={{ x: 'max-content', y: 'calc(100vh - 240px)'}}
      />
    </div>
  );
};

export default ProjectPage;