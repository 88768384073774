import { CloseOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import classNames from 'classnames';
import React, { useCallback, useState } from 'react';
import HelpPng from '../../assets/images/help.png';
import style from './tutorial.module.scss';

const KEY = 'package-ai-inspiration-topline-tutorial';

function HelpDialog({ className, setVisible }) {
  const onClose = useCallback(() => {
    setVisible(false);
    localStorage.setItem(KEY, 'true');
  }, [setVisible]);

  return (
    <div className={classNames(style.helpDialog, className)}>
      <img src={HelpPng} alt="tutorial" />
      <div className={style.closeBtn} onClick={onClose}>
        <CloseOutlined />
      </div>
    </div>
  );
}

function PackageAiTutorial({ className }) {
  const [visible, setVisible] = useState(!localStorage.getItem(KEY));

  const onGet = useCallback(() => {
    setVisible(true);
  }, [setVisible]);

  return (
    <>
      <div className={classNames(style.tutorial, className)}>
        <QuestionCircleOutlined className={style.icon} />
        <div className={style.text} onClick={onGet}>
          User Hint
        </div>
      </div>
      {visible && <HelpDialog setVisible={setVisible} />}
    </>
  );
}

export default PackageAiTutorial;
