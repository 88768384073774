// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.G2kGKEexrcD2PwKQGauA{width:100%;border:none;border-collapse:separate;border-spacing:0}.KsDXazZZ2B94vEiSJIRC{padding:12px 18px;font-size:16px;font-weight:600;line-height:22.3px;white-space:nowrap;text-align:center;color:#fff;background-color:#022f03}.wEXvmFskc2Df2fglMTMR{padding:12px 18px;font-size:16px;font-weight:400;line-height:22.3px;text-align:center;color:#00a32e;border-bottom:solid 1px rgba(0,163,46,.5019607843)}.X39OEu3OpOIND_qo1WIO{text-align:left}`, "",{"version":3,"sources":["webpack://./src/modules/package-ai-screening-batch/views/result/table.module.scss"],"names":[],"mappings":"AAAA,sBACE,UAAA,CACA,WAAA,CACA,wBAAA,CACA,gBAAA,CAGF,sBACE,iBAAA,CACA,cAAA,CACA,eAAA,CACA,kBAAA,CACA,kBAAA,CACA,iBAAA,CACA,UAAA,CACA,wBAAA,CAGF,sBACE,iBAAA,CACA,cAAA,CACA,eAAA,CACA,kBAAA,CACA,iBAAA,CACA,aAAA,CACA,kDAAA,CAGF,sBACE,eAAA","sourcesContent":[".table {\n  width: 100%;\n  border: none;\n  border-collapse: separate;\n  border-spacing: 0;\n}\n\n.headerCell {\n  padding: 12px 18px;\n  font-size: 16px;\n  font-weight: 600;\n  line-height: 22.3px;\n  white-space: nowrap;\n  text-align: center;\n  color: #fff;\n  background-color: #022f03;\n}\n\n.cell {\n  padding: 12px 18px;\n  font-size: 16px;\n  font-weight: 400;\n  line-height: 22.3px;\n  text-align: center;\n  color: #00a32e;\n  border-bottom: solid 1px #00a32e80;\n}\n\n.cellFirst {\n  text-align: left;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"table": `G2kGKEexrcD2PwKQGauA`,
	"headerCell": `KsDXazZZ2B94vEiSJIRC`,
	"cell": `wEXvmFskc2Df2fglMTMR`,
	"cellFirst": `X39OEu3OpOIND_qo1WIO`
};
export default ___CSS_LOADER_EXPORT___;
