import { ExportOutlined } from '@ant-design/icons';
import { message } from 'antd';
import React, { useEffect, useState } from 'react';
import Box from '../../components/box.jsx';
import { PackageAiBtn4 as Btn4 } from '../../components/btn.jsx';
import Container from '../../components/container.jsx';
import Loading from '../../components/loading.jsx';
import Stage from '../../components/stage.jsx';
import Brand from './brand.jsx';
import style from './index.module.scss';
// for debug
// import ExampleJson from '../../assets/json/exampleContext.json';

function PackageAiView() {
  const [context, setContext] = useState({
    basic: {},
    asset: {
      allowPublic: true,
      list: [],
    },
    aois: [],
    res: {},
  });
  const [loading, setLoading] = useState(false);

  const title = 'Attract: Brand Visibility';

  async function init() {
    // for debug
    // sessionStorage.setItem('packageAiContext', JSON.stringify(ExampleJson));
    if (loading) return;
    setLoading(true);
    try {
      const ctxs = sessionStorage.getItem('packageAiContext');
      if (!ctxs) {
        message.error('Please fill in the basic information first');
        window.open('/package-ai-screening-batch-basic', '_self');
        return null;
      }
      const ctx = JSON.parse(ctxs);

      // set data
      setContext(ctx);
    } catch (err) {
      console.error('init error', err);
      message.error('Init error');
    }
    setLoading(false);
  }
  useEffect(() => {
    void init();
  }, []);

  const [exportCount, setExportCount] = useState(0);
  function onExport() {
    setExportCount((e) => e + 1);
  }

  return (
    <Container>
      <div className={style.containerInner}>
        <Stage stage={3} />
        <Box
          title={
            <div className={style.boxHeaderInner}>
              {title}
              <Btn4 className={style.boxHeaderExport} onClick={onExport}>
                <ExportOutlined className={style.boxHeaderBtnIcon} />
                Export
              </Btn4>
            </div>
          }
          className={style.box}
        >
          <div className={style.boxInner}>
            <Brand context={context} exportCount={exportCount} />
            {loading && <Loading />}
          </div>
        </Box>
      </div>
    </Container>
  );
}

export default PackageAiView;
