import React from 'react';
import Box from './box2.jsx';
import style from './legend.module.scss';

function PackageAiLegend({ className = '' }) {
  return (
    <Box className={className}>
      <div className={`${style.boxInner} ${style.box1}`}>
        <div className={style.text1}>Legend</div>
        <div>
          <div className={style.box11}>
            <div className={`${style.icon} ${style.dataGreen}`} />
            <span className={style.text2}> = Outstanding</span>
          </div>
          <div className={style.box11}>
            <div className={`${style.icon} ${style.dataYellow}`} />
            <span className={style.text2}> = Ready</span>
          </div>
          <div className={style.box11}>
            <div className={`${style.icon} ${style.dataRed}`} />
            <span className={style.text2}> = Risky/High Risk</span>
          </div>
        </div>
      </div>
    </Box>
  );
}

export default PackageAiLegend;
