import { PlusOutlined } from '@ant-design/icons';
import { Col, message, Row } from 'antd';
import React, { useMemo, useRef, useState } from 'react';
import { putFileKey } from '../../apis/file';
import { postStandardizer } from '../../apis/serve';
import Loading from '../../components/loading.jsx';
import style from './design.module.scss';

const IMAGE_TYPE_LIST = ['image/png', 'image/jpeg', 'image/tiff'];

function Upload({ url, onChange }) {
  const inputRef = useRef(null);
  const [loading, setLoading] = useState(false);

  function onClick() {
    if (!inputRef.current) return;
    inputRef.current.click();
  }

  async function onFileChange(ev) {
    if (!onChange) return;
    if (!ev.target?.files?.length) return;
    /**
     * @type {File}
     */
    const file = ev.target.files[0];
    if (!IMAGE_TYPE_LIST.includes(file.type)) {
      console.warn('imge type not allow', file.type);
      message.warning('Image should be in png or jpeg format');
      return;
    }
    if (loading) return;
    setLoading(true);
    try {
      const { data } = await postStandardizer(file);
      const [std, raw] = data;
      const { data: stdd } = await putFileKey(std);
      const { data: rawd } = await putFileKey(raw);
      onChange({
        std: std,
        stdUrl: stdd?.url,
        raw: raw,
        rawUrl: rawd?.url,
      });
    } catch (err) {
      console.error('upload error', err);
      message.error('Upload failed');
    }
    setLoading(false);
  }

  return (
    <div className={style.uploadBox}>
      <div className={style.uploadBoxInner} onClick={onClick}>
        {url ? (
          <img className={style.uploadImg} src={url} />
        ) : (
          <PlusOutlined className={style.uploadIcon} />
        )}
        {loading && <Loading />}
      </div>
      <input
        ref={inputRef}
        type="file"
        accept={IMAGE_TYPE_LIST.join(', ')}
        style={{ display: 'none' }}
        onChange={onFileChange}
      />
    </div>
  );
}

function PackageAiDesign({
  className = '',
  context,
  design = {},
  showTitle = true,
  onChange1,
  onChange2,
}) {
  const hasVariant2 = useMemo(() => {
    return (context.basic?.numberOfVariantsPerDesign ?? 1) > 1;
  }, [context]);

  return (
    <div className={`${style.designBox} ${className}`}>
      {showTitle && (
        <h3
          className={`${style.designTitle} ${
            hasVariant2 ? '' : style.designTitle2
          }`}
        >
          {design.name}
        </h3>
      )}
      <Row className={style.designBody} gutter={12}>
        <Col span={12}>
          <Upload url={design.variants[0].url} onChange={onChange1} />
        </Col>
        {hasVariant2 && (
          <Col span={12}>
            <Upload url={design.variants[1].url} onChange={onChange2} />
          </Col>
        )}
      </Row>
    </div>
  );
}

export default PackageAiDesign;
