// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DUlXiv_jKz8iJwxvwzeb{position:absolute;top:0;left:0;z-index:3001;width:100%;height:100%;background-color:rgba(122,122,122,.3) !important}.nImS3EWA4zMYVVZdl_mQ{position:absolute;top:50%;left:50%;z-index:3002;transform:translate(-50%, -50%)}`, "",{"version":3,"sources":["webpack://./src/modules/package-ai-generation/components/loading.module.scss"],"names":[],"mappings":"AAAA,sBACE,iBAAA,CACA,KAAA,CACA,MAAA,CACA,YAAA,CACA,UAAA,CACA,WAAA,CACA,gDAAA,CAGF,sBACE,iBAAA,CACA,OAAA,CACA,QAAA,CACA,YAAA,CACA,+BAAA","sourcesContent":[".loadingModal {\n  position: absolute;\n  top: 0;\n  left: 0;\n  z-index: 3001;\n  width: 100%;\n  height: 100%;\n  background-color: rgba(122, 122, 122, 0.3) !important;\n}\n\n.loadingIcon {\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  z-index: 3002;\n  transform: translate(-50%, -50%);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loadingModal": `DUlXiv_jKz8iJwxvwzeb`,
	"loadingIcon": `nImS3EWA4zMYVVZdl_mQ`
};
export default ___CSS_LOADER_EXPORT___;
