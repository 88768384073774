import React, { useEffect, useRef, useState } from 'react';
import { chevronDownIcon } from '../../icons/icons.jsx';
import DateFilterModal from '../dateFilterModal.jsx';
import { useFilters } from '../../../modules/trends/hooks/useFilters.jsx';

function DateFilter({
  startDate,
  endDate,
  updateFilters
}) {
  const [isDateClicked, setIsDateClicked] = useState(false);
  const { filters } = useFilters();

  const ref = useRef(null);

  const handleButtonClick = () => {
    setIsDateClicked(!isDateClicked);
  };

  const checkIfClickedOutside = (e) => {
    if (ref.current && !ref.current.contains(e.target)) {
      setIsDateClicked(false);
    }
  };

  const onApply = (startDate, endDate) => {
    updateFilters({
      ...filters,
      startDate: startDate,
      endDate: endDate,
    });
    handleButtonClick();
  };

  useEffect(() => {
    document.addEventListener('mousedown', checkIfClickedOutside);

    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  }, []);

  return (
    <div ref={ref}>
      <div className="consumer-filter-container">
        <button
          className={'date-filter' + (isDateClicked ? ' active' : '')}
          onClick={handleButtonClick}
          role="button"
        >
          <span>Date</span>
          {isDateClicked ? (
            <img
              className="up-arrow-filter"
              src={chevronDownIcon}
              alt="arrow"
            />
          ) : (
            <img className="arrow-sort" src={chevronDownIcon} alt="arrow" />
          )}
        </button>
        {isDateClicked && (
          <DateFilterModal
            isOpen={isDateClicked}
            onApply={onApply}
            startDate={startDate}
            endDate={endDate}
          />
        )}
      </div>
    </div>
  );
}
export default DateFilter;
