import classNames from 'classnames';
import React, { useCallback } from 'react';
import Bg2Png from '../../assets/images/select-bg.png';
import style from './index.module.scss';

export function PackageAiBtn2({
  className,
  children,
  disabled = false,
  onClick,
}) {
  const oc = useCallback(
    (ev) => {
      if (disabled) return;
      onClick && onClick(ev);
    },
    [onClick]
  );

  return (
    <div
      className={classNames(
        style.btn2,
        {
          [style.disabled]: disabled,
        },
        className
      )}
      style={{ backgroundImage: `url(${Bg2Png})` }}
      onClick={oc}
    >
      <div className={style.text}>{children}</div>
    </div>
  );
}
