import React from 'react';
import ReactWordcloud from 'react-wordcloud';
//import { Resizable } from "re-resizable";
import 'tippy.js/dist/tippy.css';
import 'tippy.js/animations/scale.css';

const SocialAnimatedWordCloud = (selectedWordCloud) => {
  const options = {
    colors: ['#00A32E'],
    enableTooltip: false,
    deterministic: false,
    fontFamily: 'unilever-shilling-regular',
    fontSizes: [10,40,80],
    //fontStyle: 'normal',
    fontWeight: 'normal',
    // height: '250',
    //width: '1050',
    padding: 4,
    rotations: 2,
    rotationAngles: [0, 90],
    scale: 'sqrt',
    spiral: 'rectangular',
    transitionDuration: 1000,
  };

  const wordcloudanimationData = selectedWordCloud;

  const wordcloudanimationDataWordword =
    wordcloudanimationData.selectedWordCloud.map((item, index) => {
      return {
        text: item.text,
        value: item.value,
      };
    });

  const data = wordcloudanimationDataWordword.slice(0, 29);
  return (
    <div className="animated-word-cloud">
      <ReactWordcloud
        options={options}
        words={data}
      />
    </div>
  );
};

export default SocialAnimatedWordCloud;
