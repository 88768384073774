import React from 'react';
import { Select } from 'antd';

const { Option } = Select;

const MarketSelect = ({ markets, selectedMarket, onChange, dropdownStyle }) => {
  return (
    <Select
      value={selectedMarket}
      placeholder="* Market"
      maxTagCount={1}
      maxTagTextLength={selectedMarket.length > 1 ? 2 : 8}
      //popupMatchSelectWidth={false}
      // allowClear
      onChange={onChange}
      className="custom-select filter-select custom-small-select"
      dropdownStyle={dropdownStyle}
    >
      <Option value="market" disabled className="topdish-disabled-filter">
        Market
      </Option>
      {markets.map((market) => (
        <Option
          key={market.name}
          value={market.name}
          className="topdish-filter"
        >
          {/* <input
              type="checkbox"
              className='topdish-filter-checkbox'
              checked={selectedMarket.includes(market.name)}
              readOnly
            /> */}
          {market.name}
        </Option>
      ))}
    </Select>
  );
};

export default MarketSelect;
