// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pRVGKY06habx17rJQTcM{width:100vw;height:100vh;overflow:hidden;position:relative;display:flex;flex-direction:column;background-color:#071207}.CmvUDaL8AhzwcYm63if3{height:84px;display:flex;justify-content:center;align-items:center}.CmvUDaL8AhzwcYm63if3 img{width:600px}.ttXoxgaVHlcWKgW5G9hA{flex:1;padding:0 35px 35px;overflow:hidden}.K_JRWLbmzod8fjhugCOt{position:absolute;top:25px;right:25px}.c7XiQjRd3NsY979ZEYVL{position:absolute;top:25px;right:170px}`, "",{"version":3,"sources":["webpack://./src/modules/package-ai-screening/components/container.module.scss"],"names":[],"mappings":"AAAA,sBACE,WAAA,CACA,YAAA,CACA,eAAA,CACA,iBAAA,CACA,YAAA,CACA,qBAAA,CACA,wBAAA,CAGF,sBACE,WAAA,CACA,YAAA,CACA,sBAAA,CACA,kBAAA,CACA,0BACE,WAAA,CAIJ,sBACE,MAAA,CACA,mBAAA,CACA,eAAA,CAGF,sBACE,iBAAA,CACA,QAAA,CACA,UAAA,CAGF,sBACE,iBAAA,CACA,QAAA,CACA,WAAA","sourcesContent":[".container {\n  width: 100vw;\n  height: 100vh;\n  overflow: hidden;\n  position: relative;\n  display: flex;\n  flex-direction: column;\n  background-color: #071207;\n}\n\n.containerHeader {\n  height: 84px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  img {\n    width: 600px;\n  }\n}\n\n.containerBody {\n  flex: 1;\n  padding: 0 35px 35px;\n  overflow: hidden;\n}\n\n.btnDatabase {\n  position: absolute;\n  top: 25px;\n  right: 25px;\n}\n\n.btnTemplate {\n  position: absolute;\n  top: 25px;\n  right: 170px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `pRVGKY06habx17rJQTcM`,
	"containerHeader": `CmvUDaL8AhzwcYm63if3`,
	"containerBody": `ttXoxgaVHlcWKgW5G9hA`,
	"btnDatabase": `K_JRWLbmzod8fjhugCOt`,
	"btnTemplate": `c7XiQjRd3NsY979ZEYVL`
};
export default ___CSS_LOADER_EXPORT___;
