import service from '../../../../util/pythonApi';

/**
 * get date str, format is YYYY-MM-DD
 * @param {Date} date input date
 * @returns {string} output string
 */
function getDateStr(date) {
  return date.toISOString().split('T')[0];
}

export function getKnorr(query, skip = 0, pageSize = 12) {
  const { favorite, mine, start, end, category, market, ...rest } = query;
  const qq = { ...rest };
  if (favorite) qq.favorite = 'true';
  if (mine) qq.mine = 'true';
  if (start) qq.start = getDateStr(start);
  if (end) qq.end = getDateStr(end);
  if (category) qq.category = category.join(',');
  if (market) qq.market = market.join(',');
  return service.request({
    url: '/v1/knorr',
    method: 'get',
    params: {
      ...qq,
      skip,
      page_size: pageSize,
    },
  });
}

export function postKnorr(title, media, data = {}) {
  return service.request({
    url: '/v1/knorr',
    method: 'post',
    data: {
      title,
      ...data,
      ...media,
    },
  });
}

export function getKnorrById(id) {
  return service.request({
    url: `/v1/knorr/${id}`,
    method: 'get',
  });
}

export function deleteKnorrById(id) {
  return service.request({
    url: `/v1/knorr/${id}`,
    method: 'delete',
  });
}

export * from './favorite';
export * from './media';
export * from './tag';
export * from './visit';
