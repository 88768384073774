import React from 'react';
import style from './index.module.scss';
import { Input, Modal } from 'antd';
import BorderBox from '../border-box/index.jsx';
import expansionSvg from '../../../../assets/images/consumer-theater-chat/expansion.svg';
import contractionSvg from '../../../../assets/images/consumer-theater-chat/contraction.svg';
import sendSvg from '../../../../assets/images/consumer-theater-chat/send.svg';
import refreshSvg from '../../../../assets/images/consumer-theater-chat/refresh.svg';
import ChatMainBody from '../chain-content/main.jsx';
import { useRoot } from '../../hooks/useRoot.jsx';
import useChatCore from '../../hooks/useChatCore.js';
import { LoadingOutlined } from '@ant-design/icons';

const minWinStyle = {
  bottom: 20,
  right: 20,
  top: 'unset',
  position: 'absolute'
};

const AIChatPopup = ({showWin, setShowWin}) => {
  const { isExpansion, loading, setIsExpansion, } = useRoot();
  const { quesText,  inputValChange, onSend, onRefresh } = useChatCore();

  return (<>
    <Modal open={showWin} onCancel={() => setShowWin(false)} closeIcon={false} width={isExpansion ? '70%' : '450px'} style={minWinStyle} footer={false} className={style.customModalWrap} >
      <BorderBox>
        <div className={style.boxHeader}>
          <span className={style.title}>Consumer Theatre GPT</span>
          <img src={isExpansion ? contractionSvg : expansionSvg} alt="expansion" className={style.expansion} onClick={() => setIsExpansion(!isExpansion)} />
        </div>
        <div className={style.chatContent} id="customer_ai_chat">
          <ChatMainBody></ChatMainBody>
        </div>
        <div className={style.footer}>
          <div className={style.inputBox}>
            <img src={refreshSvg} alt="refresh" className={style.refreshIcon} onClick={onRefresh} />
            <Input disabled={loading} value={quesText} onChange={inputValChange} className={style.input} placeholder="Enter what you want to ask"></Input>
            {
              loading ? <LoadingOutlined className={style.loadingIcon}></LoadingOutlined> : <img src={sendSvg} alt="send" className={style.sendIcon} onClick={() => onSend()} />
            }
          </div>
        </div>
      </BorderBox>
    </Modal>
  </>);
};

export default AIChatPopup;