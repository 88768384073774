import React, { useRef, useState } from 'react';
import { Button } from 'antd';
import { Link } from 'react-router-dom';
import BorderContainer from '../../consumer-theater/components/border-container/border-container.jsx';
import style from './business-case-assistant-page.module.scss';
import { ExcelIcon, leftLineIcon } from '../../../components/icons/icons.jsx';
import { getAzureBlobUrl } from '../api/request.js';
import { App } from 'antd';

let message;

let BusinessCaseAssistantPage = () => {
  const staticFunction = App.useApp();
  message = staticFunction.message;

  const [showExcel, setShowExcel] = useState(false);

  const ExcelNames = {
    // awareness: 'Best-in-Class Benchmark-Awareness.xlsx',
    // distribution: 'Best-in-Class Benchmark-Distribution.xlsx',
    // penvolVal: 'Best-in-Class Benchmark-PenVolVal.xlsx',
    benchmarks: 'Nutrition Best-in-Class Innovation Benchmarks.xlsx',
  };

  const handleBack = () => {
    setShowExcel(false);
  };

  const excelTitle = (
    <>
      <span className={style.backIcon} onClick={handleBack}>
        <img src={leftLineIcon} className="back-arrow-icon" alt="back" />
        Back
      </span>
      <span>Best in Class Benchmark</span>
      <span> </span>
    </>
  );

  const handleDownload = async (key) => {
    const filepath = 'bca/' + ExcelNames[key];
    const fileKey = encodeURI(filepath);
    const res = await getAzureBlobUrl(fileKey);
    window.open(res.data.url, '_blank');
  };

  const aRef = useRef(null);
  async function onGiicanni() {
    if (!aRef.current) return;
    const { data } = await getAzureBlobUrl(
      'data/GII Cannibalisation Reference Tool - July 2023_shared version_Innovation types updated_ver 1.xlsx'
    );
    aRef.current.href = data.url;
    aRef.current.click();
  }

  return (
    <div className={style.container}>
      <div className={style.dialogBox}>
        <BorderContainer id="topic">
          <div className={style.dialogMain}>
            <div className={style.dialogTitle}>
              <div className={style.titleBox}>
                {showExcel ? (
                  excelTitle
                ) : (
                  <span>Business Case Target Setting</span>
                )}
              </div>
            </div>
            <div className={style.contentBox}>
              {showExcel ? (
                <div className={style.ulContainer}>
                  <ul className={style.excelList}>
                    {Object.keys(ExcelNames).map((item, index) => (
                      <li key={index} onClick={() => handleDownload(item)}>
                        <a>
                          <figure className={style.imageContainer}>
                            <img src={ExcelIcon} />
                            <figcaption>{ExcelNames[item]}</figcaption>
                          </figure>
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              ) : (
                <>
                  <div className={style.item}>
                    <Button
                      className={style.button}
                      type="primary"
                      size="large"
                      role="button"
                      onClick={() => {
                        setShowExcel(true);
                      }}
                    >
                      Best in Class Benchmark
                    </Button>
                  </div>
                  <div className={style.item}>
                    <Link to="https://unilever.kantar.com/dirichletmodel/login?ReturnUrl=%2fdirichletmodel%2f">
                      <Button
                        className={style.button}
                        type="primary"
                        size="large"
                        role="button"
                      >
                        Business Case Predicting Tool
                      </Button>
                    </Link>
                  </div>
                  <div className={style.item}>
                    <Button
                      className={style.button}
                      type="primary"
                      size="large"
                      role="button"
                      onClick={onGiicanni}
                    >
                      GII Cannibalisation Reference Tool
                    </Button>
                  </div>
                </>
              )}
            </div>
          </div>
        </BorderContainer>
      </div>
      <a ref={aRef} download style={{ display: 'none' }}></a>
    </div>
  );
};

export default BusinessCaseAssistantPage;

export { message };
