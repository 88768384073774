import React from 'react';
import { ConfigProvider, Input, Select } from 'antd';
import noDataImg from '@/modules/pain-points/img/noData.png';

const noData = ({ title, options }) => {

  return <>
    <div style={{height: '100%', width: '100%',display:'flex',alignItems:'center',justifyContent:'center',paddingBottom:'100px'}}>
      <img src={noDataImg} style={{ height: '160px', width: '300px' }} />
    </div>
  </>
}



export default noData;