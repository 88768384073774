import React, { useEffect, useState } from 'react';
import { useMsal } from '@azure/msal-react';
import { userProfile, edit } from '../../../../../components/icons/icons.jsx';
import { Link } from 'react-router-dom';  
// import { useSessionStorage } from '../../../hooks/useSessionStorage.js';

function ProfileDropdown() {
  const { accounts, instance } = useMsal();
  const [userName, setUserName] = useState('');
  const [email, setEmail] = useState('');
  const [isSupervisor, setIsSupervisor] = useState(false);
  // const [user, ] = useSessionStorage('user');
  const user = JSON.parse(sessionStorage.getItem('user'));
  useEffect(() => {
    if (accounts.length > 0) {
      const account = accounts[0];
      setUserName(account.name);
      setEmail(account.username);
    }
    if(user && user['is_supervisor']){
      setIsSupervisor(true);
    }
  }, []);

  const handleLogout = () => {
    if(!process.env.IGNORE_MFA || process.env.IGNORE_MFA === 'false')
      instance.logout();
    else{
      sessionStorage.removeItem('jwt-access-token');
      sessionStorage.clear();
      window.location.reload();
    }
  };

  return (
    <div className="profile-dropdown-container">
      <div className="dropdown-contents">
        <div>
          <img src={userProfile} alt="profile Icon" />
        </div>
        <div className="username">{userName}</div>
        <div className="user-email">{email}</div>
        {isSupervisor ? (
          <div className="user-management">
            <Link to="/user-management">
              <button
                className="user-managment-button btn px-16"
                icon={<img src={edit} alt="profile Icon" />}
              >
                User Management
              </button>
            </Link>
          </div>
        ) : null}

        <button
          onClick={handleLogout}
          className="share-learning-button btn px-16"
        >
          Sign Out
        </button>
      </div>
    </div>
  );
}

export default ProfileDropdown;
