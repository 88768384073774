// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ijLPnXtMK4l7ds3w9Q2l{position:relative;width:100%;height:70px;border-radius:10px;background-color:rgba(32,35,32,.8196078431);cursor:pointer}.ijLPnXtMK4l7ds3w9Q2l:hover>.n2NzHFhU9AI0XtW5Sgcu{display:block}.n2NzHFhU9AI0XtW5Sgcu{position:absolute;top:-2%;left:-2%;width:104%;height:104%;display:none}.OoZvHTpdhnBm4EpiB1wb{position:absolute;top:50%;left:50%;transform:translate(-50%, -50%);font-size:20px;font-weight:900;line-height:27.88px;color:#fff;white-space:nowrap}`, "",{"version":3,"sources":["webpack://./src/modules/package-ai/components/btn.module.scss"],"names":[],"mappings":"AAAA,sBACE,iBAAA,CACA,UAAA,CACA,WAAA,CACA,kBAAA,CACA,2CAAA,CACA,cAAA,CAEE,kDACE,aAAA,CAKN,sBACE,iBAAA,CACA,OAAA,CACA,QAAA,CACA,UAAA,CACA,WAAA,CACA,YAAA,CAGF,sBACE,iBAAA,CACA,OAAA,CACA,QAAA,CACA,+BAAA,CACA,cAAA,CACA,eAAA,CACA,mBAAA,CACA,UAAA,CACA,kBAAA","sourcesContent":[".btn {\n  position: relative;\n  width: 100%;\n  height: 70px;\n  border-radius: 10px;\n  background-color: #202320d1;\n  cursor: pointer;\n  &:hover {\n    > .btnImg {\n      display: block;\n    }\n  }\n}\n\n.btnImg {\n  position: absolute;\n  top: -2%;\n  left: -2%;\n  width: 104%;\n  height: 104%;\n  display: none;\n}\n\n.btnText {\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n  font-size: 20px;\n  font-weight: 900;\n  line-height: 27.88px;\n  color: #fff;\n  white-space: nowrap;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"btn": `ijLPnXtMK4l7ds3w9Q2l`,
	"btnImg": `n2NzHFhU9AI0XtW5Sgcu`,
	"btnText": `OoZvHTpdhnBm4EpiB1wb`
};
export default ___CSS_LOADER_EXPORT___;
