import React, { useEffect, useState, useImperativeHandle, forwardRef, useCallback } from 'react';
import style from './product-deep-dive.module.scss';
import classNames from 'classnames';
import WordCharts from './word-charts.jsx';
import LineCharts from './line-charts.jsx';
import ArticleBox from './article-box.jsx';
import { fetchChartsByTopicData, fetchProductDeepDiveReviews } from '../../api/request.js';
import { Spin, Image } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { utils, writeFile } from 'xlsx';
import NoData from '../no-data/no-data.jsx';
import imageToBeUpload from '../../../../assets/images/ratings-review/image-to-be-upload.jpg';
import GPTInsight from './gpt-insight.jsx';

const antIcon = (
  <LoadingOutlined
    style={{
      fontSize: 30,
      color: '#00FF00'
    }}
    spin
  />
);

function debounce (callback, delay) {
  let lastTime;
  return function () {
    clearTimeout(lastTime);
    const [that, args] = [this, arguments];
    lastTime = setTimeout(() => {
      callback.apply(that, args);
    }, delay);
  };
}

let requestIndex = Date.now();
let requestIndexScore = Date.now();
let requestStop = false;
let requestStopScore = false;

let ProductDeepDive = ({visible, searchParams, noFilterResult, tabChange, currentTabKey}, ref) => {
  
  const [currentTab, setCurrentTab] = useState('1');
  const [currentProduct, setCurrentProduct] = useState(null);
  currentTabKey = currentTabKey === 'Overall' ? undefined : currentTabKey;

  const [productLoading, setProductLoading] = useState(false);
  const [products, setProducts] = useState([]);
  const fetchProduct = (searchParams) => {
    if (requestStop) return;
    setProductLoading(true);
    fetchProductDeepDiveReviews(searchParams, requestIndex)
      .then(res => {
        if (requestStop) return;
        if (requestIndex !== res.config.uuid) return;
        const arr = Array.isArray(res.data.data) ? res.data.data : [];
        setProducts(arr);
        setCurrentProduct(arr[0]??null);
        setProductLoading(false);
      })
      .catch(error => {
        if (requestIndex !== error?.config?.uuid) return;
        setProducts([]);
        setCurrentProduct(null);
        setProductLoading(false);
      });
  };
  const delayFetchProduct = useCallback(debounce(function(val) { return fetchProduct(val);}, 500), []);

  useEffect(() => {
    if (!searchParams || currentTabKey === 'market') {
      requestStop = true;
      setProducts([]);
      setCurrentProduct(null);
      setProductLoading(false);
      return;
    }
    const newSearchParams = {...searchParams, topic: currentTabKey};
    requestStop = false;
    requestIndex++;
    delayFetchProduct(newSearchParams);
  }, [searchParams, currentTabKey]);

  const changeTab = (val) => {
    setCurrentTab(val);
    tabChange(val);
  };

  const changeProduct = (val) => {
    setCurrentProduct(val);
  };

  const [scoreLineData, setScoreLineData] = useState([]);
  const [currentScoreData, setCurrentScoreData] = useState(null);
  const [scoreLoading, setScoreLoading] = useState(false);

  const fetchScore = (searchParams) => {
    if (requestStopScore) return;
    setScoreLoading(true);
    fetchChartsByTopicData(searchParams, requestIndexScore)
      .then(res => {
        if (requestStopScore) return;
        if (requestIndexScore !== res.config.uuid) return;
        const arr = Array.isArray(res.data.data) ? res.data.data : [];
        setScoreLineData(arr);
        setScoreLoading(false);
      })
      .catch(error => {
        if (requestIndexScore !== error?.config?.uuid) return;
        setScoreLineData([]);
        setScoreLoading(false);
      });
  };
  const delayFetchScore = useCallback(debounce(function(val) { return fetchScore(val);}, 500), []);

  useEffect(() => {
    if (!searchParams || currentTabKey === 'market') {
      requestStopScore = true;
      setScoreLineData([]);
      setCurrentScoreData(null);
      setScoreLoading(false);
      return;
    }
    const newSearchParams = {...searchParams, topic: currentTabKey};
    requestStopScore = false;
    requestIndexScore++;
    delayFetchScore(newSearchParams);
  }, [searchParams, currentTabKey]);

  useEffect(()=> {
    const index = scoreLineData.findIndex(item => item.name === currentProduct?.name);
    if (index > -1) {
      setCurrentScoreData(scoreLineData[index].data);
    } else {
      setCurrentScoreData(null);
    }
  }, [scoreLineData, currentProduct]);

  // Reviews Keywords

  // Reviews Sentiment Score
  const computeScore = () => {
    // 定义表头
    const headers = [''];
    // 定义数据
    const data = [[]];
    const timeData = {};
    products.forEach(item => {
      const product_name = item.name;
      headers.push(product_name);
      const index = scoreLineData.findIndex(item => item.name === product_name);
      if (index > -1) {
        scoreLineData[index].data.forEach((score) => {
          if (!timeData[score.time]) {
            timeData[score.time] = {};
          }
          timeData[score.time][product_name] = score.score;
        });
      }
    });
    Object.keys(timeData).sort((a, b) => a - b).forEach(time => {
      data.push([time]);
      headers.forEach(product => {
        product && (data[data.length - 1].push(timeData[time][product]??''));
      });
    });
    const sheetData = [headers, ...data];
    // 创建工作表
    const worksheet = utils.aoa_to_sheet(sheetData);
    // 列宽度
    const cols = [
      { wch: 20 },
      { wch: 40 },
      { wch: 40 },
      { wch: 40 }
    ];
    worksheet['!cols'] = cols;
    return worksheet;
  };

  // Verbatims
  const computeVerbatims = () => {
    // 定义表头
    const headers = ['product_name', 'product_id', 'review_title', 'review_date', 'review_body', 'review_url'];
    // 定义数据
    const data = [[]];
    products.forEach(item => {
      item.reviews?.forEach(review => {
        data.push([
          review.name,
          review.id,
          review.review_title,
          review.review_date,
          review.review_body,
          review.review_url
        ]);
      });
    });
    const sheetData = [headers, ...data];
    // 创建工作表
    const worksheet = utils.aoa_to_sheet(sheetData);
    // 列宽度
    const cols = [
      { wch: 40 },
      { wch: 40 },
      { wch: 40 },
      { wch: 20 },
      { wch: 40 },
      { wch: 40 }
    ];
    worksheet['!cols'] = cols;
    return worksheet;
  };

  const computeData = (fileType) => {
    const scoreSheet = computeScore();
    const verbatimsSheet = computeVerbatims();

    const wb = utils.book_new();
    utils.book_append_sheet(wb, scoreSheet, 'Reviews Sentiment Score');
    utils.book_append_sheet(wb, verbatimsSheet, 'Verbatims');
    writeFile(wb, `Product Deep Dive Data.${fileType}`);
  };

  useImperativeHandle(ref, () => ({
    exportExcel: () => {
      computeData('xlsx');
    },
    exportCsv: () => {
      computeData('csv');
    },
    getTab: () => {
      return currentTab;
    }
  }));

  if (!visible) return null;

  return (
    <>
      <Spin indicator={antIcon} spinning={productLoading || scoreLoading}>
        {!products || products.length === 0 && noFilterResult}
        {products && products.length > 0 && 
        (<div className={style.productDeepDive}>
          <div className={style.diveLeft}>
            <div className={classNames(style.itemBox, currentTab === '1' ? style.active : '')} onClick={() => changeTab('1')}>Reviews Keywords</div>
            <div className={classNames(style.itemBox, currentTab === '2' ? style.active : '')} onClick={() => changeTab('2')}>Reviews Sentiment Score</div>
            <div className={classNames(style.itemBox, currentTab === '3' ? style.active : '')} onClick={() => changeTab('3')}>Verbatims/CEC</div>
            {/* <div className={classNames(style.itemBox, currentTab === '4' ? style.active : '')} onClick={() => changeTab('4')}>GPT Insight</div> */}
          </div>
          <div className={style.diveRight}>
            {
              currentTab !== '4' && <>
                {currentTab !== '2' && 
                  <div className={style.mainBox}>
                    { currentTab === '1' && (currentProduct && currentProduct.reviews.length > 0 ? <WordCharts product={currentProduct} /> : <NoData />)}
                    { currentTab === '3' && (currentProduct && currentProduct.reviews.length > 0 ? <ArticleBox product={currentProduct} searchParams={searchParams} noFilterResult={noFilterResult} /> : <NoData />)}
                  </div>
                }
                {currentTab === '2' &&
                    <div className={style.mainBox2}>
                      <div className={style.scoreContainer}>
                        <div className={style.chartsContainer}>
                          {currentScoreData?.length ? <LineCharts product={currentScoreData} /> : <NoData />}
                        </div>
                      </div>
                    </div>
                }
                <div className={style.productsChangeBox}>
                  { products.map(item => (
                    <div 
                      key={item.name}
                      className={classNames(
                        style.productChangeBtn, 
                        currentProduct?.name === item.name ? style.active : ''
                      )} 
                      onClick={() => changeProduct(item)}
                    >
                      <div className={style.productBox}>
                        <div className={style.productImgBox}>
                          <Image
                            preview={false}
                            width={20}
                            height={20}
                            src={item.img_url}
                            fallback={imageToBeUpload}
                            placeholder={
                              <div className={style.productImgPlaceholder}>
                                <LoadingOutlined />
                              </div>
                            }
                          />
                        </div>
                        <div className={style.productName} title={item.name}>{item.name}</div>
                      </div>
                    </div>)
                  ) }
                </div>
              </>
            }
            {
              currentTab === '4' && <GPTInsight searchParams={searchParams} />
            }
          </div>
        </div>)}
      </Spin>
    </>
  );
};

ProductDeepDive = forwardRef(ProductDeepDive);

export default ProductDeepDive;