import {
  QuestionCircleOutlined,
  SearchOutlined,
  UploadOutlined,
} from '@ant-design/icons';
import { App, ConfigProvider, Input, message, theme } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  deletePackageAiAsset,
  getPackageAiAsset,
  putPackageAiAsset,
} from '../../apis/serve/asset';
import Box from '../../components/box.jsx';
import { PackageAiBtn1 as Btn } from '../../components/btn.jsx';
import Container from '../../components/container.jsx';
import More from '../../components/more.jsx';
import DeleteDialog from './delete.jsx';
import Filter from './filter.jsx';
import HelpDialog from './help.jsx';
import style from './index.module.scss';
import Item from './item.jsx';
import UpdateDialog from './update.jsx';
import UploadDialog from './upload.jsx';

const HELP_KEY = 'package-ai-generation-help';

function PackageAiView() {
  const navigate = useNavigate();

  const [search, setSearch] = useState();
  const [filter, setFilter] = useState({});
  const [list, setList] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [loading, setLoading] = useState(false);
  const [isNoMore, setIsNoMore] = useState(false);

  const clearList = useCallback(() => {
    setList([]);
    setIsNoMore(false);
  }, [setList, setIsNoMore]);

  useEffect(() => {
    clearList();
  }, [search, filter]);

  const readAsset = useCallback(async () => {
    if (isNoMore) return;
    try {
      setLoading(true);
      const isEmpty = !list?.length;
      const limit = isEmpty ? 18 : 6;
      const { data } = await getPackageAiAsset(
        { ...filter, search },
        isEmpty ? 0 : list.length,
        limit,
        'package'
      );
      if (data) {
        const { data: dd } = data;
        setIsNoMore(dd.length < limit);
        if (isEmpty) {
          setList(dd);
        } else {
          setList((prev) => prev.concat(dd));
        }
      } else {
        console.warn('read asset fail', data);
        message.warning('Read asset fail');
      }
    } catch (err) {
      console.error('read asset error', err);
      message.error('Read asset error');
    }
    setLoading(false);
  }, [list, setList, setLoading, search, filter, isNoMore, setIsNoMore]);

  async function onNext() {
    if (selectedIndex < 0) {
      message.warning('Please select a package first');
      return;
    }
    if (loading) return;
    setLoading(true);
    try {
      const item = list[selectedIndex];
      sessionStorage.setItem(
        'packageAiContext',
        JSON.stringify({
          base: {
            url: item.url,
          },
        })
      );
      navigate('/package-ai-generation2');
    } catch (err) {
      console.error('upload error', err);
      message.error('Upload image fail');
    } finally {
      setLoading(false);
    }
  }

  const [uploadVisible, setUploadVisible] = useState(false);
  const onUpload = useCallback(() => {
    setUploadVisible(true);
  }, [setUploadVisible]);

  const [helpVisible, setHelpVisible] = useState(
    localStorage.getItem(HELP_KEY) !== 'true'
  );
  const onHelp = useCallback(() => {
    setHelpVisible(true);
    localStorage.setItem(HELP_KEY, 'true');
  }, [setHelpVisible]);

  const [updateData, setUpdateData] = useState();
  const [updateVisible, setUpdateVisible] = useState(false);
  const onUpdateClick = useCallback(
    (data) => {
      setUpdateData(data);
      setUpdateVisible(true);
    },
    [setUpdateData, setUpdateVisible]
  );
  const onUpdateConfirm = useCallback(
    async (data) => {
      setUpdateVisible(false);
      if (!data) return;
      await putPackageAiAsset(data);
      clearList();
    },
    [setUpdateData]
  );

  const [deleteId, setDeleteId] = useState();
  const [deleteVisible, setDeleteVisible] = useState(false);
  const onDeleteClick = useCallback(
    (id) => {
      setDeleteId(id);
      setDeleteVisible(true);
    },
    [setDeleteId, setDeleteVisible]
  );
  const onDeleteConfirm = useCallback(async () => {
    setDeleteVisible(false);
    if (!deleteId) return;
    await deletePackageAiAsset(deleteId);
    clearList();
  }, [deleteId, setDeleteVisible]);

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: '#00a32e',
          colorError: '#eb0000',
          colorSuccess: '#06ca3d',
          fontFamily: 'unilever-shilling-regular',
        },
        algorithm: theme.darkAlgorithm,
      }}
    >
      <App>
        <Container step={1}>
          <Box
            className={style.box}
            hasHeader={true}
            title="Choose or Upload your base pack"
            headerRight={
              <div className={style.box1}>
                <Input
                  style={{ width: '250px' }}
                  value={search}
                  onChange={(ev) => {
                    if (!ev.target) return;
                    setSearch(ev.target.value);
                  }}
                  prefix={<SearchOutlined />}
                />
              </div>
            }
          >
            <div className={style.boxBody}>
              <div className={style.content}>
                <div className={style.filterBox}>
                  <Filter filter={filter} setFilter={setFilter} />
                  <div className={style.uploadBox}>
                    <div className={style.text}>Upload Base Pack</div>
                    <div className={style.uploadBtn} onClick={onUpload}>
                      <UploadOutlined className={style.icon} />
                      UPLOAD
                    </div>
                  </div>
                </div>
                <div className={style.boxLine}></div>
                <div className={style.itemListBox}>
                  <div className={style.itemListBoxBody}>
                    <div className={style.itemList}>
                      {list.map((item, index) => (
                        <Item
                          data={item}
                          key={`${item.title}-${index}`}
                          selected={index === selectedIndex}
                          onSelect={() => setSelectedIndex(index)}
                          onUpdate={onUpdateClick}
                          onDelete={onDeleteClick}
                        />
                      ))}
                      <More onLoadMore={readAsset} />
                    </div>
                  </div>
                  <div className={style.itemListBoxFooter}>
                    <Btn
                      disabled={!selectedIndex < 0}
                      loading={loading}
                      onClick={onNext}
                    >
                      NEXT
                    </Btn>
                  </div>
                  <div className={style.helpBtnBox}>
                    <div className={style.helpBtn} onClick={onHelp}>
                      <QuestionCircleOutlined className={style.icon} />
                      <div className={style.text}>User Hint</div>
                    </div>
                  </div>
                  <div className={style.info}>
                    Due to AI guidance and legislation, all generated assets can
                    <br />
                    only be used internally.
                  </div>
                </div>
              </div>
            </div>
          </Box>
        </Container>
      </App>
      <HelpDialog visible={helpVisible} setVisible={setHelpVisible} />
      <UploadDialog
        visible={uploadVisible}
        setVisible={setUploadVisible}
        afterUpload={clearList}
      />
      <UpdateDialog
        data={updateData}
        visible={updateVisible}
        setVisible={setUpdateVisible}
        onConfirm={onUpdateConfirm}
      />
      <DeleteDialog
        visible={deleteVisible}
        setVisible={setDeleteVisible}
        onConfirm={onDeleteConfirm}
      />
    </ConfigProvider>
  );
}

export default PackageAiView;
