import { ArrowRightOutlined } from '@ant-design/icons';
import { Form, Input, message } from 'antd';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { putFileKey } from '../../../package-ai-screening-batch/apis/file/index.js';
import { postStandardizer } from '../../apis/serve/standardizer.js';
import { postPredictImage } from '../../apis/serve/predictImage.js';
import Box from '../../components/box.jsx';
import {
  PackageAiBtn2 as Btn2,
  PackageAiBtn3 as Btn3,
} from '../../components/btn.jsx';
import Container from '../../components/container.jsx';
import Loading from '../../components/loading.jsx';
import Stage from '../../components/stage.jsx';
import style from './index.module.scss';
import ImageItem from './item.jsx';

const { Item } = Form;

const RULES = {
  projectName: [{ required: true, message: 'Please input your project name!' }],
};
const MAX_FILE_LENGTH = 14;
const IMAGE_TYPE_LIST = ['image/png', 'image/jpeg'];

function buildItem(index = 1) {
  return { title: `Design ${index}` };
}

function PackageAiView() {
  const [context, setContext] = useState({
    basic: {
      projectName: 'Project Name',
    },
    asset: {
      allowPublic: true,
      list: [],
    },
  });
  const [loading, setLoading] = useState(false);

  const resetRef1 = useRef(null);

  function onFormValuesChange(changedValues) {
    setContext((ctx) => {
      return {
        ...ctx,
        basic: {
          ...ctx.basic,
          ...changedValues,
        },
      };
    });
  }

  const assetList = useMemo(() => {
    const ass = context.asset.list.filter((e) => Boolean(e.standardFile));
    if (ass.length < 3) {
      const l = [...ass];
      for (let i = l.length; i < 3; i += 1) {
        l.push(buildItem(i + 1));
      }
      return l;
    } else if (ass.length < MAX_FILE_LENGTH) {
      return [...ass, buildItem(ass.length + 1)];
    } else {
      return ass;
    }
  }, [context]);

  const batchAddRef = useRef(null);
  function onBatchAdd() {
    if (!batchAddRef.current) return;
    batchAddRef.current.click();
  }
  async function onBatchAddFileChange(ev) {
    if (!ev.target?.files?.length) return;
    if (loading) return;
    setLoading(true);

    try {
      /**
       * @type {File[]}
       */
      const files = ev.target.files;

      let count = context.asset.list.length;

      // loop all file
      for (const f of files) {
        // check image image type
        if (!IMAGE_TYPE_LIST.includes(f.type)) {
          message.warning('Image should be in png or jpeg format');
          continue;
        }
        // limit length
        if (count >= MAX_FILE_LENGTH) continue;
        // set data
        const {
          data: [std, raw],
        } = await postStandardizer(f);
        const { data: stdd } = await putFileKey(std);
        const { data: rawd } = await putFileKey(raw);
        count += 1;
        setContext((ctx) => {
          // exists asset
          const ass = ctx.asset.list.filter((e) => Boolean(e.standardFile));
          return {
            ...ctx,
            asset: {
              ...ctx.asset,
              list: [
                ...ass,
                {
                  ...buildItem(ass.length + 1),
                  src: rawd.url,
                  file: raw,
                  standardSrc: stdd.url,
                  standardFile: std,
                },
              ],
            },
          };
        });
      }
    } catch (err) {
      console.error('batch upload error', err);
      message.error('Upload failed');
    }
    setLoading(false);
  }

  const onContinue = useCallback(async () => {
    const ass = context.asset?.list.filter((e) => Boolean(e.standardFile));
    if (!ass?.length) {
      message.warning('Please upload at least one image');
      return;
    }

    if (loading) return;
    setLoading(true);
    try {
      // upload images
      const { data: iids } = await postPredictImage(
        context.asset.list.map((e) => e.standardFile)
      );
      if (!iids) throw new Error('Upload images error');

      // set context
      sessionStorage.setItem(
        'packageAiContext',
        JSON.stringify({
          ...context,
          res: {
            imageIds: iids,
          },
        })
      );
      window.open('/package-ai-screening-batch-aoi', '_self');
    } catch (err) {
      console.error('upload data error', err);
      message.error('Upload images error');
    }
    setLoading(false);
  }, [context]);

  function onCancel() {
    setContext({
      basic: {},
      asset: {
        allowPublic: true,
        list: [],
      },
    });
  }

  return (
    <Container>
      <div className={style.containerInner}>
        <Stage stage={1} />
        <Box
          title="Fill in Project Details"
          className={style.box}
          bgc="transparent"
        >
          <div className={style.boxInner}>
            <Form
              layout="vertical"
              initialValues={context.basic}
              onValuesChange={onFormValuesChange}
            >
              <Item
                label="Project Name"
                name="projectName"
                rules={RULES.projectName}
              >
                <Input className={style.formInput} />
              </Item>
              <button
                ref={resetRef1}
                type="reset"
                style={{ display: 'none' }}
              ></button>
            </Form>
            <div className={style.imgList}>
              {assetList.map((e, i) => {
                return (
                  <ImageItem
                    className={style.imgItem}
                    key={i}
                    data={e}
                    setData={(val) => {
                      setContext((ctx) => {
                        return {
                          ...ctx,
                          asset: {
                            ...ctx.asset,
                            list: ctx.asset.list.map((item, index) => {
                              if (index === i)
                                return {
                                  ...buildItem(index + 1),
                                  ...val,
                                };
                              return item;
                            }),
                          },
                        };
                      });
                    }}
                    onAdd={onBatchAdd}
                  />
                );
              })}
            </div>
            <div className={style.btnsBox}>
              <Btn3 className={style.btn2} onClick={onCancel}>
                Cancel
              </Btn3>
              <Btn2
                className={style.btn2}
                onClick={onContinue}
                suffix={<ArrowRightOutlined />}
              >
                CONTINUE
              </Btn2>
            </div>
            {loading && <Loading />}
            <input
              ref={batchAddRef}
              type="file"
              accept={IMAGE_TYPE_LIST.join(', ')}
              style={{ display: 'none' }}
              multiple={true}
              onChange={onBatchAddFileChange}
            />
          </div>
        </Box>
      </div>
    </Container>
  );
}

export default PackageAiView;
