import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Irresistible from '../../../../assets/images/Emerging-food-solution/lowwastemenus/lowwastemenu.jpg';
import FoodSliderComponent from './food-slider.jsx';
import FoodServiceComponent from './irr-food-service.jsx';
import IrrSocial from './irr-social.jsx';
import { leftLineIcon } from '../../../../components/icons/icons.jsx';
import FutureMenuPdf from '../../../../assets/pdf/Future-Menus-Report-2023.pdf';

function LowWasteMenus() {
  const [showSlider, setShowSlider] = useState(true);
  const [showSliderservice, setShowSliderservice] = useState(true);
  const [showSlidersocial, setShowSlidersocial] = useState(true);
  const handleSlider = () => {
    setShowSlider(!showSlider);
  };
  const handleSliderservice = () => {
    setShowSliderservice(!showSliderservice);
  };
  const handleSlidersocial = () => {
    setShowSlidersocial(!showSlidersocial);
  };
  const handleModal = () => {
    setShowSlider(!showSlider);
  };
  const handleModalService = () => {
    setShowSliderservice(!showSliderservice);
  };
  const handleModalSocial = () => {
    setShowSlidersocial(!showSlidersocial);
  };
  const handleCloseSlider = () => {
    setShowSlider(true);
  };
  const handleCloseSliderservice = () => {
    setShowSliderservice(!showSliderservice);
  };
  const handleCloseSlidersocial = () => {
    setShowSlidersocial(!showSlidersocial);
  };

  const navigate = useNavigate();
  const handleNavigationMain = () => {
    navigate('/trends-dial');
  };
  return (
    <>
      <div className="trends-detail-title">
        <h2><img style={{cursor: 'pointer'}} src={leftLineIcon} onClick={handleNavigationMain} alt="left icon" /> low waste menus</h2>
      </div>
      <div className="flex-container">
        <div className="emerging-description">
          <div>
            Dishes that make smart use of ingredients and resources to help
            reduce waste, make food go further and give the diner a positive
            feeling UFS’ Why: To make food waste reduction and upcycling a more
            integrated part of the food system. Core Operator benefit: To
            demonstrate love, care and respect for the ingredients and suppliers
            you work with. Core Consumer motivation: “I really hate the idea of
            food waste and I look for ways to eat delicious food without
            compromise.”
          </div>
        </div>

        <div className="emerging-image">
          <div>
            <img src={Irresistible} alt="Irresistible" />
          </div>
        </div>
      </div>
      {!showSlider && (
        <FoodSliderComponent
          handleCloseSlider={handleCloseSlider}
          handleSlider={handleSlider}
        />
      )}
      {!showSliderservice && (
        <FoodServiceComponent
          handleCloseSliderservice={handleCloseSliderservice}
          handleSliderservice={handleSliderservice}
        />
      )}
      {!showSlidersocial && (
        <IrrSocial
          handleCloseSlidersocial={handleCloseSlidersocial}
          handleSlidersocial={handleSlidersocial}
        />
      )}
      <ul className="emerging-links">
        <li onClick={handleModal}>Trends Deep Dive</li>
        <li onClick={handleModalService}>Food Service Trend Scape</li>
        <li onClick={handleModalSocial}>Social Listening Analysis</li>
        <li><a href={FutureMenuPdf} target="_blank" rel="noreferrer"> Future Menu 2023</a></li>
      </ul>
    </>
  );
}

export default LowWasteMenus;
