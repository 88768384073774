// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ocJ25xhCT9VUVZN5VkZb{height:100%;display:flex;align-items:stretch;padding:25px}.ceKIpm6p4PE_2QFVMQDv{display:flex;flex-direction:column;align-items:stretch}.YSuUMDAbHUfqLeIOyrAJ{display:flex;justify-content:center}.bVibfwHUTFGxqa4_S3Vb{margin-top:15px;flex:1;overflow:hidden}.Y0Jj7r57bJQme1NhjuC3{margin:0 15px}.Pt2J7YrH7B6O1vOqVed_{flex:1;overflow:hidden}.Lc1EuUemzzdoREEzmo6A{position:absolute;top:5px;right:5px}.Lc1EuUemzzdoREEzmo6A .ZVcPT4aIyzcJ0Lsv0CpJ{font-size:20px;color:#00a32e}.Lc1EuUemzzdoREEzmo6A .iobTYkvOZVUhjwNDiDlN{margin-left:5px}`, "",{"version":3,"sources":["webpack://./src/modules/package-ai-performance/views/competitive/index.module.scss"],"names":[],"mappings":"AAAA,sBACE,WAAA,CACA,YAAA,CACA,mBAAA,CACA,YAAA,CAGF,sBACE,YAAA,CACA,qBAAA,CACA,mBAAA,CAGF,sBACE,YAAA,CACA,sBAAA,CAGF,sBACE,eAAA,CACA,MAAA,CACA,eAAA,CAGF,sBACE,aAAA,CAGF,sBACE,MAAA,CACA,eAAA,CAGF,sBACE,iBAAA,CACA,OAAA,CACA,SAAA,CACA,4CACE,cAAA,CACA,aAAA,CAEF,4CACE,eAAA","sourcesContent":[".box1 {\n  height: 100%;\n  display: flex;\n  align-items: stretch;\n  padding: 25px;\n}\n\n.box11 {\n  display: flex;\n  flex-direction: column;\n  align-items: stretch;\n}\n\n.box111 {\n  display: flex;\n  justify-content: center;\n}\n\n.box112 {\n  margin-top: 15px;\n  flex: 1;\n  overflow: hidden;\n}\n\n.box12 {\n  margin: 0 15px;\n}\n\n.box13 {\n  flex: 1;\n  overflow: hidden;\n}\n\n.box2 {\n  position: absolute;\n  top: 5px;\n  right: 5px;\n  .icon {\n    font-size: 20px;\n    color: #00a32e;\n  }\n  .switch {\n    margin-left: 5px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"box1": `ocJ25xhCT9VUVZN5VkZb`,
	"box11": `ceKIpm6p4PE_2QFVMQDv`,
	"box111": `YSuUMDAbHUfqLeIOyrAJ`,
	"box112": `bVibfwHUTFGxqa4_S3Vb`,
	"box12": `Y0Jj7r57bJQme1NhjuC3`,
	"box13": `Pt2J7YrH7B6O1vOqVed_`,
	"box2": `Lc1EuUemzzdoREEzmo6A`,
	"icon": `ZVcPT4aIyzcJ0Lsv0CpJ`,
	"switch": `iobTYkvOZVUhjwNDiDlN`
};
export default ___CSS_LOADER_EXPORT___;
