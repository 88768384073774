import { message } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  getPredictPackage,
  getPredictShelf,
  getPredictVariant,
  getShelfColor,
  getShelfDesign,
  postPredictAestheticsRun,
  postPredictShelfRun,
  postPredictVariantRun,
  postSuggest,
  postPredictAestheticsImg,
} from '../../apis/serve';
import LoadingGif from '../../assets/img/loading.gif';
import Box from '../../components/box.jsx';
import Container from '../../components/container.jsx';
import Stage from '../../components/stage.jsx';
import style from './index.module.scss';

const DESIGN_ID_KEYS = [
  'ul_design0_variant0_id',
  'ul_design0_variant1_id',
  'ul_design1_variant0_id',
  'ul_design1_variant1_id',
  'ul_design2_variant0_id',
  'ul_design2_variant1_id',
  'ul_design3_variant0_id',
  'ul_design3_variant1_id',
  'c1_design1_variant0_id',
  'c1_design1_variant1_id',
  'c2_design1_variant0_id',
  'c2_design1_variant1_id',
];

function Loading({ value = 0 }) {
  const limitedValue = useMemo(() => {
    if (value <= 100) return value;
    return 100;
  }, [value]);

  return (
    <div className={style.loadingModal}>
      <img className={style.loadingImg} src={LoadingGif} />
      <div className={style.loadingText}>{limitedValue} %</div>
    </div>
  );
}

function PackageAiView() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [per, setPer] = useState(0);

  async function init() {
    try {
      const itv = setInterval(() => {
        setPer((p) => {
          if (p < 100) return p + 1;
          return p;
        });
      }, 3600);
      const ctxs = sessionStorage.getItem('packageAiContext');
      if (!ctxs) {
        message.error('Please fill in the basic information first');
        navigate('/package-ai-screening-basic');
        return null;
      }
      const ctx = JSON.parse(ctxs);
      ctx.res = {
        ...ctx.res,
        postVariantRun: {},
        variants: {},
        postAesRun: {},
        variantSuggests: {
          balance: {},
          aesthetics: {},
          package: {},
        },
        shelfDesigns: {},
        shelfColors: {},
        shlefSuggests: {},
      };
      const pp = ctx.res.predictPackage;

      const promises = [];

      // post shelf run
      promises.push(async () => {
        const { data } = await postPredictShelfRun({ id: pp.id });
        ctx.res.postShelfRun = data;
      });

      // post variant run
      for (const key of DESIGN_ID_KEYS) {
        // variant id
        const vid = pp[key];
        if (!vid) continue;
        const vids = vid.toString();
        promises.push(async () => {
          const { data: paesRes } = await postPredictAestheticsRun({
            id: vid,
          });
          ctx.res.postAesRun[vids] = paesRes;
          const { data: ppvrRes } = await postPredictVariantRun({ id: vid });
          ctx.res.postVariantRun[vids] = ppvrRes;
          const { data: ppvRes } = await getPredictVariant(vid);
          ctx.res.variants[vids] = ppvRes;
          const { data: sbRes } = await postSuggest({
            id: vid,
            clinic: 'balance',
          });
          ctx.res.variantSuggests.balance[vids] = sbRes;
          const { data: saRes } = await postSuggest({
            id: vid,
            clinic: 'aesthetics',
          });
          ctx.res.variantSuggests.aesthetics[vids] = saRes;
          const { data: spRes } = await postSuggest({
            id: vid,
            clinic: 'package',
          });
          ctx.res.variantSuggests.package[vids] = spRes;
        });
      }

      // run all
      await Promise.all(promises.map((p) => p()));
      promises.length = 0;

      // get shelf
      const { data: sfRes } = await getPredictShelf(pp.id);
      ctx.res.predictShelf = sfRes;
      // get shelf design
      const designKeys = [
        'uldesign0_id',
        'uldesign1_id',
        'uldesign2_id',
        'uldesign3_id',
        'c1design1_id',
        'c2design1_id',
      ];
      const colorKeys = [
        'black_shelf_id',
        'dark gray_shelf_id',
        'light gray_shelf_id',
        'white_shelf_id',
      ];
      for (const key of designKeys) {
        // design id
        const did = sfRes[key];
        if (!did) continue;
        const dids = did.toString();
        promises.push(async () => {
          const { data: sdRes } = await getShelfDesign(did);
          ctx.res.shelfDesigns[dids] = sdRes;
          const { data: sgRes } = await postSuggest({
            id: did,
            clinic: 'shelf',
          });
          ctx.res.shlefSuggests[dids] = sgRes;
          for (const ck of colorKeys) {
            // color id
            const cid = sdRes[ck];
            if (!cid) continue;
            const { data: scdRes } = await getShelfColor(cid);
            ctx.res.shelfColors[cid.toString()] = scdRes;
          }
        });
      }

      // run all
      await Promise.all(promises.map((p) => p()));

      // get package
      const { data: ppRes } = await getPredictPackage(pp.id);
      ctx.res.predictPackage = ppRes;

      // post aesthetics img
      const { data: aesImg } = await postPredictAestheticsImg({ id: pp.id });
      ctx.res.aesImg = aesImg;

      sessionStorage.setItem('packageAiContext', JSON.stringify(ctx));
      clearInterval(itv);
      setPer(100);
      setTimeout(() => {
        navigate('/package-ai-screening-result');
        setLoading(false);
      }, 1000);
    } catch (err) {
      console.error('init error', err);
      message.error('Fail to load data');
      setIsError(true);
      setLoading(false);
    }
  }
  useEffect(() => {
    void init();
  }, []);

  return (
    <Container>
      <div className={style.containerInner}>
        <Stage stage={4} />
        <Box title="Outcome" className={style.box}>
          {loading && <Loading value={per} />}
          {isError && <h1 className={style.errorText}>Fail to load data</h1>}
        </Box>
      </div>
    </Container>
  );
}

export default PackageAiView;
