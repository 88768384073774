import React, { useMemo, useState } from 'react';
import style from './collapse.module.scss';

function OccasionCollapseItem({
  label,
  key,
  children,
  activeKey,
  setActiveKey,
}) {
  const isActive = useMemo(() => {
    return activeKey.includes(key);
  }, [activeKey, key]);

  function onClick() {
    if (isActive) {
      setActiveKey(activeKey.filter((e) => e !== key));
    } else {
      setActiveKey([...activeKey, key]);
    }
  }

  return (
    <div className={style.collapseItemContainer} key={key}>
      <div className={style.collapseItemLabel} onClick={onClick}>
        {label}
      </div>
      <div
        className={`${style.collapseItemContent} ${
          isActive ? style.collapseItemContentActive : ''
        }`}
      >
        {children}
      </div>
    </div>
  );
}

function OccasionCollapse({ items, className, startGroup }) {
  const [activeKey, setActiveKey] = useState(
    startGroup ? [parseInt(startGroup)] : []
  );
  return (
    <div className={className}>
      {items.map((e) =>
        OccasionCollapseItem({ ...e, activeKey, setActiveKey })
      )}
    </div>
  );
}

export default OccasionCollapse;
