import { Select } from 'antd';
import React from 'react';
import style from './select.module.scss';

function KnorrSelect({
  preIcon,
  className,
  options,
  value,
  onChange,
  placeholder,
}) {
  return (
    <div
      className={`${style.select} ${preIcon ? style.selectHasPreIcon : ''} ${
        className ?? ''
      }`}
    >
      {preIcon && <img src={preIcon} />}
      <Select
        variant="fill"
        options={options}
        value={value}
        placeholder={placeholder}
        onChange={onChange}
      ></Select>
    </div>
  );
}

export default KnorrSelect;
