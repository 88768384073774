import React, { useState, useEffect } from 'react';
import CombinedGraph from '../graph/combined-graph.jsx';
import { fetchGrowth } from '../../api/request.js';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import 'antd/dist/reset.css';
import style from '../tab/tab.module.scss';
import GraphWithSelectors from '../tab/graphwithselector.jsx';

const GrowthTrend = ({
  countries,
  brand,
  topDish,
  trendingDishId
}) => {
  const [graphData, setGraphData] = useState(null);
  const [loading, setLoading] = useState(true);

  // Function to combine data for GoogleSearches, TiktokMentions, and MetaPosts
  const combineGraphData = (graphData) => {
    const combinedData = [];

    if (graphData.GoogleSearches) {
      combinedData.push({
        label: 'Google Searches',
        data: graphData.GoogleSearches,
        graphId: 'GoogleSearches'
      });
    }

    if (graphData.TiktokMentions) {
      combinedData.push({
        label: 'Tiktok Mentions',
        data: graphData.TiktokMentions,
        graphId: 'TiktokMentions'
      });
    }

    if (graphData.MetaPosts) {
      combinedData.push({
        label: 'Meta Posts',
        data: graphData.MetaPosts,
        graphId: 'MetaPosts'
      });
    }

    return combinedData;
  };

  const fetchData = async () => {
    setLoading(true);
    try {
      const data = {
        countries: countries,
        brands: brand,
        top_dishes: topDish,
        trending_dish_id: trendingDishId,
      };

      const response = await fetchGrowth(data);
      setGraphData(response.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, [trendingDishId]);

  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 30,
        color: '#00FF00',
      }}
      spin
    />
  );

  return (
    <div className={style.tabTransition}>
      {loading ? (
        <Spin indicator={antIcon}>
          <div className='spin-icon'></div>
        </Spin>
      ) : (
        <>
          {graphData && (
            <div className="graph-content">
              {/* Section for the TrendIndexCurve Graph */}
              <section id="trend-index-curve-section">
                <div className={style.BorderContainer}>
                  {graphData.TrendIndexCurve ? (
                    <GraphWithSelectors
                      countries={countries}
                      brand={brand}
                      topDish={topDish}
                      trendingDishId={trendingDishId}
                      graphData={graphData.TrendIndexCurve}
                      graphId="TrendIndexCurve"
                    />
                  ) : (
                    <div className="no-data-container">
                      <p>No data available for TrendIndexCurve</p>
                    </div>
                  )}
                </div>
              </section>

              {/* Section for Combined Graph (Google Searches, Tiktok Mentions, and Meta Posts) */}
              {/* <section id="combined-metrics-section">
                <div className={`Border ${style.Border}`}>
                  <CombinedGraph
                    countries={countries}
                    brand={brand}
                    topDish={topDish}
                    trendingDishId={trendingDishId}
                    graphData={combineGraphData(graphData)}
                    graphId="CombinedData"
                  />
                </div>
              </section> */}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default GrowthTrend;
