import React from 'react';
import { useNavigate } from 'react-router-dom';
import style from './top.module.scss';

const TopBar = () => {
  const navigate = useNavigate();

  return (
    <div className={style.topBarWrap}>
      <h1 className={style.title}>Pack AI Competitive Landscape Module</h1>
      <div className={style.navWrap}>
        <div
          className={style.navItem}
          onClick={() => {
            navigate('/package-ai-performance');
          }}
        >
          <span>Home Page</span>
        </div>
      </div>
    </div>
  );
};

export default TopBar;
