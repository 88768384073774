// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bb5z0w5zBc6iPPmGE3Mr{height:80px}.xtVWp5CsMI0xqsVZ3xQv{position:fixed;left:0;right:0;top:0;z-index:10;display:flex;justify-content:center;align-items:center;padding-left:134px}`, "",{"version":3,"sources":["webpack://./src/modules/plant-based/pages/Layout/components/commen.module.scss"],"names":[],"mappings":"AAAA,sBACE,WAAA,CAGF,sBACE,cAAA,CACA,MAAA,CACA,OAAA,CACA,KAAA,CACA,UAAA,CAEA,YAAA,CACA,sBAAA,CACA,kBAAA,CAIA,kBAAA","sourcesContent":[".headerHeight {\n  height: 80px;\n}\n\n.headerWrap {\n  position: fixed;\n  left: 0;\n  right: 0;\n  top: 0;\n  z-index: 10;\n\n  display: flex;\n  justify-content: center;\n  align-items: center;\n\n  // background-color: #000;\n\n  padding-left: 134px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"headerHeight": `bb5z0w5zBc6iPPmGE3Mr`,
	"headerWrap": `xtVWp5CsMI0xqsVZ3xQv`
};
export default ___CSS_LOADER_EXPORT___;
