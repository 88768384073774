/* eslint-disable no-unused-vars */
import React from 'react';
import TagCloudComponent from './related-theme-chart.jsx';
import Style from './related-theme.module.scss';
import { topRightSmall, topLeftSmall, bottomRightSmall, bottomLeftSmall } from '../../../../components/icons/icons.jsx';

const relatedTheme = ({ themeData }) => {
  return (
    <>
      <div className={`${Style.relatedThemeMainContainer}`}>
        <div className={`${Style.relatedThemeContainer}`}>
          {/* <BorderContainer> */}
          <div className={Style.mainContainer}>
            <div className={Style.textContainer}>
              <h1>Related Theme</h1>
              <p>
                Most popular themes in relation to the selected trending twist
                on TikTok
              </p>
              <span><strong>Popularity Score</strong></span>
              <ul>
                <li>
                  <span className={`${Style.dot} ${Style.mediumGreen}`}></span>
                  Less than 100
                </li>
                <li>
                  <span className={`${Style.dot} ${Style.darkGreen}`}></span>
                  Less than 150
                </li>
                <li>
                  <span className={`${Style.dot} ${Style.lightGreen}`}></span>
                  More than 150
                </li>
              </ul>
            </div>
            <div className={Style.wordContainer}>
              <TagCloudComponent themeData={themeData} />
            </div>
          </div>
          {/* </BorderContainer> */}
        </div>
      </div>
    </>
  );
};

export default relatedTheme;
