import React, { useRef, useState } from 'react';
import './trend-summary.scss';
import DetailTitle from '../detail-title/detail-title.jsx'
import { putFileKey } from '../../api/request.js';


function TrendSummary(trendingDishData = { trendingDishData }) {
    console.log('trendingDishData', trendingDishData.trendingDishData)
    const dishData = trendingDishData.trendingDishData
    const text = `${dishData.trendingDishId} has a trend index of ${dishData.detail.trend_index} this month, an increase of ${dishData.detail.trend_index_variation} vs. last month. With an Instagram share of ${dishData.detail.meta_post_count_variation}%, it has increased by ${dishData.detail.relevance_score_variation}% in social discussion in the past month.`
    const [imgUrl,setImgUrl] = useState(null)
    
    const getImgUrl = async (e) => {
        let countries = dishData.countries[0]
        if(dishData.countries[0]=="BR"){
            countries = 'Brazil'
        }else if(dishData.countries[0]=="ID"){
            countries = 'Indonesia'
        }else if(dishData.countries[0]=="IN"){
            countries = 'India'
        }
        const imgName = `/${countries}/${dishData.brand[0]}/${dishData.topDish[0]}/${dishData.trendingDishId}.jpg`
        const { data } = await putFileKey(imgName);
        console.log(imgName,data)
        setImgUrl(data.url)
    }
    getImgUrl()

    return (
        <>
            <DetailTitle title={'Trend Summary'} />
            <div id='print'>
            <div className='briefText'>{text}</div>
            <img className='briefImg' src={imgUrl} />
            </div>
        </>
    )
}

export default TrendSummary;