import axios from 'axios';
import { msalInstance } from '@/index.js';
import { login as LoginJWT } from '@/modules/concept-ai/api/request.js';

export async function getJWTToken(){
  const jwtToken = sessionStorage.getItem('jwt-access-token');
  if (jwtToken) {
    return jwtToken;
  }

  // get aad authentication
  const currentAccount = msalInstance.getActiveAccount();
  let msgraph_token = sessionStorage.getItem('aad-token');;
  const res = await LoginJWT({
    username: currentAccount.username,
    adalToekn: msgraph_token,
  }).catch((err) => console.log(err));
  sessionStorage.setItem('jwt-access-token', res.access_token);
  return res.access_token;
}

let refreshFlag = false;
const pythonApi = axios.create({
  baseURL: process.env.REACT_APP_PYTHON_API_URL,
  timeout: 0
});

pythonApi.interceptors.request.use(
  (config) => {
    let jwt_token = sessionStorage.getItem('jwt-access-token');
    if (jwt_token) {
      config.headers['Authorization'] = 'Bearer ' + jwt_token;
    }
    if (!config.headers['Content-Type']) {
      config.headers['Content-Type'] = 'application/json';
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

pythonApi.interceptors.response.use(
  (response) => {
    if (response.status !== 200) {
      const res = response.data;
      console.error(res.message);
      return Promise.reject(res);
    }
    if (response.config.originData) {
      return response;
    }
    return response.data;
  },
  (error) => {
    if (error.code === 'ERR_NETWORK') {
      console.error(error.message);
    } else if (error.response.status === 401) {
      if (refreshFlag) {
        return Promise.reject(error);
      }
      refreshFlag = true;
      sessionStorage.removeItem('jwt-access-token');
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    } else if (error.response && error.response.status === 500) {
      error.response.data.message && console.error(error.response.data.message);
    }
    return Promise.reject(error);
  }
);

export default pythonApi;