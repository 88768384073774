// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ujVuZMIQJLZYluAvwodQ{position:absolute;right:15px;bottom:15px;z-index:2;display:flex;align-items:center}.An9_z_ZmqKu5CjWDv5fq{margin-right:16px;font-size:20px;color:#00a32e;transition:color .3s}.An9_z_ZmqKu5CjWDv5fq.JG4muHMtI7BxgM_LqJlc{color:#ccc;cursor:not-allowed !important}.loUeKZ2VtdJN2JkIQFUy{margin-right:16px;height:20px;width:20px;color:#00a32e;transition:color .3s}.loUeKZ2VtdJN2JkIQFUy.JG4muHMtI7BxgM_LqJlc{color:#ccc;cursor:not-allowed !important}`, "",{"version":3,"sources":["webpack://./src/modules/package-ai-generation/views/update/tool.module.scss"],"names":[],"mappings":"AAAA,sBACE,iBAAA,CACA,UAAA,CACA,WAAA,CACA,SAAA,CACA,YAAA,CACA,kBAAA,CAGF,sBACE,iBAAA,CACA,cAAA,CACA,aAAA,CACA,oBAAA,CAEA,2CACE,UAAA,CACA,6BAAA,CAIJ,sBACE,iBAAA,CACA,WAAA,CACA,UAAA,CACA,aAAA,CACA,oBAAA,CAEA,2CACE,UAAA,CACA,6BAAA","sourcesContent":[".toolBox {\n  position: absolute;\n  right: 15px;\n  bottom: 15px;\n  z-index: 2;\n  display: flex;\n  align-items: center;\n}\n\n.toolBtn {\n  margin-right: 16px;\n  font-size: 20px;\n  color: #00a32e;\n  transition: color 0.3s;\n\n  &.disabled {\n    color: #ccc;\n    cursor: not-allowed !important;\n  }\n}\n\n.toolBtn1 {\n  margin-right: 16px;\n  height: 20px;\n  width: 20px;\n  color: #00a32e;\n  transition: color 0.3s;\n\n  &.disabled {\n    color: #ccc;\n    cursor: not-allowed !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"toolBox": `ujVuZMIQJLZYluAvwodQ`,
	"toolBtn": `An9_z_ZmqKu5CjWDv5fq`,
	"disabled": `JG4muHMtI7BxgM_LqJlc`,
	"toolBtn1": `loUeKZ2VtdJN2JkIQFUy`
};
export default ___CSS_LOADER_EXPORT___;
