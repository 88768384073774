// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.OcCXC554Q5SKRPeFhXbg{padding:10px 34px;background-color:#eef4f0;border-radius:14px;color:#00a32e;transition:all .3s ease;cursor:pointer;line-height:16px;white-space:nowrap}.PVRwsgb4fqALt0Biu8NG{margin-left:18px;font-size:16px;font-weight:400;letter-spacing:0em}.dQjEvxzuca212TTv7j4n{background-color:#008626;color:#fff}`, "",{"version":3,"sources":["webpack://./src/modules/knorr/components/btn.module.scss"],"names":[],"mappings":"AAAA,sBACE,iBAAA,CACA,wBAAA,CACA,kBAAA,CACA,aAAA,CACA,uBAAA,CACA,cAAA,CACA,gBAAA,CACA,kBAAA,CAGF,sBACE,gBAAA,CACA,cAAA,CACA,eAAA,CACA,kBAAA,CAGF,sBACE,wBAAA,CACA,UAAA","sourcesContent":[".btn {\n  padding: 10px 34px;\n  background-color: #eef4f0;\n  border-radius: 14px;\n  color: #00a32e;\n  transition: all 0.3s ease;\n  cursor: pointer;\n  line-height: 16px;\n  white-space: nowrap;\n}\n\n.btnText {\n  margin-left: 18px;\n  font-size: 16px;\n  font-weight: 400;\n  letter-spacing: 0em;\n}\n\n.btnPrimary {\n  background-color: #008626;\n  color: #fff;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"btn": `OcCXC554Q5SKRPeFhXbg`,
	"btnText": `PVRwsgb4fqALt0Biu8NG`,
	"btnPrimary": `dQjEvxzuca212TTv7j4n`
};
export default ___CSS_LOADER_EXPORT___;
