import { useCallback, useState } from 'react';
import { auditLog, getFeedback, putFeedback } from '../api/request';
import { utils, writeFile } from 'xlsx';

const useFeedback = () => {
  const [dataList, setDataList] = useState([]);

  const getFeedbackList = useCallback(async (filters = {}) => {
    const parms = filters;
    const {data = [], status = ''} = await getFeedback(parms);
    if(status === 'Success') {
      setDataList(data);
    }
  }, []);

  const updateFeedback = useCallback(async(parms) => {
    try {
      const {status = ''} = await putFeedback(parms);
      if(status === 'Success') {
        return true;
      }
      return false;
    } catch(error) {
      return false;
    } finally {
      try {
        auditLog(
          JSON.stringify({
            category: 'event',
            event: 'Bu Feedback event',
            message: 'update feedback',
          })
        );
      } catch(error) { /* empty */ }
    }
    
  }, []);

  const changeDataList = (tempDataList) => {
    setDataList(tempDataList);
  };

  const parseObj = useCallback((objStr, key) => {
    const obj = JSON.parse(objStr);
    return obj[key];
  }, []);

  const exportExcel = useCallback(() => {
    if(!dataList.length) return;
    const excelData = [];
    dataList.forEach(item => {
      let projection = item.projection;
      if(projection) {
        projection = JSON.parse(projection);
      } else {
        projection = {projection_feb: '', projection_mar: '', projection_apr: '', projection_may: '', projection_jun: '', projection_jul: '', projection_aug: '', projection_sep: '', projection_oct: '', projection_nov: '', projection_dec: ''};
      }
      const tempObj = {
        country: item.country,
        category: item.category,
        bg: item.bg,
        'business unit': item.business_unit,
        'cell': item.cell,

        'value mat share': parseObj(item.value_mat, 'share'),
        'value mat': parseObj(item.value_mat, 'value'),
        'value l12w share': parseObj(item.value_l12w, 'share'),
        'value l12w': parseObj(item.value_l12w, 'value'),

        'volumn mat share': parseObj(item.volumn_mat, 'share'),
        'volumn mat': parseObj(item.volumn_mat, 'value'),
        'volumn l12w share': parseObj(item.volumn_l12w, 'share'),
        'volumn l12w': parseObj(item.volumn_l12w, 'value'),

        'market size mat': item.market_size_mat,
        'market growth mat': item.market_growth_mat,

        'turnaround plan': item.turnaround_plan,

        'projection_feb_share': projection.projection_feb ?  projection.projection_feb['share'] : '',
        'projection_feb': projection.projection_feb ?  projection.projection_feb['value'] : '',
        'projection_mar_share': projection.projection_mar ?  projection.projection_mar['share'] : '',
        'projection_mar': projection.projection_mar ?  projection.projection_mar['value'] : '',

        'projection_apr_share': projection.projection_apr ?  projection.projection_apr['share'] : '',
        'projection_apr': projection.projection_apr ?  projection.projection_apr['value'] : '',

        'projection_may_share': projection.projection_may ?  projection.projection_may['share'] : '',
        'projection_may': projection.projection_may ?  projection.projection_may['value'] : '',

        'projection_jun_share': projection.projection_jun ?  projection.projection_jun['share'] : '',
        'projection_jun': projection.projection_jun ?  projection.projection_jun['value'] : '',

        'projection_jul_share': projection.projection_jul ?  projection.projection_jul['share'] : '',
        'projection_jul': projection.projection_jul ?  projection.projection_jul['value'] : '',

        'projection_aug_share': projection.projection_aug ?  projection.projection_aug['share'] : '',
        'projection_aug': projection.projection_aug ?  projection.projection_aug['value'] : '',

        'projection_sep_share': projection.projection_sep ?  projection.projection_sep['share'] : '',
        'projection_sep': projection.projection_sep ?  projection.projection_sep['value'] : '',

        'projection_oct_share': projection.projection_oct ?  projection.projection_oct['share'] : '',
        'projection_oct': projection.projection_oct ?  projection.projection_oct['value'] : '',

        'projection_nov_share': projection.projection_nov ?  projection.projection_nov['share'] : '',
        'projection_nov': projection.projection_nov ?  projection.projection_nov['value'] : '',

        'projection_dec_share': projection.projection_dec ?  projection.projection_dec['share'] : '',
        'projection_dec': projection.projection_dec ?  projection.projection_dec['value'] : '',

        'key issues': item.key_issues,
        'action plan': item.action_plan
      };
      excelData.push(tempObj);
    });

    const sheet = utils.json_to_sheet(excelData);

    const wb = utils.book_new();
    utils.book_append_sheet(wb, sheet, 'Sheet1');
    writeFile(wb, 'BuFeedback.xlsx');
  }, [dataList, parseObj]);

  return {dataList, getFeedbackList, updateFeedback, changeDataList, exportExcel};
};

export default useFeedback;