import React, { useEffect, useState } from 'react';
import FilterSelect from '@/modules/pain-points/components/collapse/filterSelect.jsx';
import { ConfigProvider, Select } from 'antd';
import EventBus from '@/modules/pain-points/hooks/eventBus';


const App = (data) => {

  let setData;
  [data, setData] = useState();

  const handleChange = (value) => {
    console.log(`selected ${value}`);

    EventBus.dispatchEvent('painPointListChangeEvent', value);

  };

  let selectOptions = [
    {
      value: 2,
      label: 'Reach',
    },
    {
      value: 1,
      label: 'Importance',
    },
    {
      value: 0,
      label: 'Willingness to pay premium price',
    }
  ]

  return <>
    <div style={{width:'160px',position:'absolute',right:'5%',top:'115px'}}>
      <div style={{color:"#027f1d",fontSize:'12px',marginBottom:'5px'}}>Ranking by</div>
      <ConfigProvider
        theme={{
          components: {
            Select: {
              /* 这里是你的组件 token */
              colorBgContainer: '#10450d',
              colorBorder: '#10450d',
              colorPrimary: '#10450d',
              colorPrimaryHover: '#10450d',
              colorBgElevated: '#10450d',
              optionSelectedBg: '#146d1e',
              colorText: '#FFF',
            },
          },
        }}
      >
        <Select
          style={{
            width: '100%', border: 'none',
          }}
          defaultValue="Reach"
          onChange={handleChange}
          options={selectOptions}
        />
      </ConfigProvider>
    </div>
  </>;

};

export default App;