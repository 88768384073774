import React from 'react';
import style from './main.module.scss';
import classNames from 'classnames';

const SendLoadingBox = () => {
  return (
    <div className={style.sendLoadingBox}>
      <div className={style.circleWrap}>
        <div className={style.circle}></div>
        <div className={classNames(style.circle, style.transparent)}></div>
      </div>
    </div>
  );
};

export default SendLoadingBox;