import {
  ArrowRightOutlined,
  InfoCircleOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons';
import {
  AutoComplete,
  Checkbox,
  Col,
  Form,
  Input,
  message,
  Row,
  Select,
  Tooltip,
} from 'antd';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import BrandJson from '../../assets/json/brand.json';
import BusinessObjectiveJson from '../../assets/json/businessObjective.json';
import CategoryJson from '../../assets/json/category.json';
import Box from '../../components/box.jsx';
import {
  PackageAiBtn1 as Btn1,
  PackageAiBtn2 as Btn2,
  PackageAiBtn3 as Btn3,
} from '../../components/btn.jsx';
import Container from '../../components/container.jsx';
import Stage from '../../components/stage.jsx';
import Help from './help/index.jsx';
import style from './index.module.scss';

const { Item } = Form;

function useBussinessObjectiveOption(item) {
  return {
    label: (
      <span>
        <span className={style.optionText}>{item.label}</span>
        <Tooltip title={item.tooltip}>
          <QuestionCircleOutlined className={style.optionIcon} />
        </Tooltip>
      </span>
    ),
    value: item.value,
  };
}

function PackageAiBusinessObjective({
  className,
  value,
  onChange,
  defaultValue,
}) {
  /**
   * @type {Array<string>}
   */
  const localValue = useMemo(() => [value], [value]);
  /**
   * @param {Array<string>} val
   */
  function handleChange(val) {
    if (!onChange) return;
    if (val.length === 0) {
      onChange(undefined);
    } else if (val.length === 1) {
      onChange(val[0]);
    } else {
      for (const v of val) {
        if (v === value) continue;
        onChange(v);
        break;
      }
    }
  }

  return (
    <Checkbox.Group
      className={className}
      options={BusinessObjectiveJson.map(useBussinessObjectiveOption)}
      value={localValue}
      onChange={handleChange}
      defaultValue={defaultValue}
    />
  );
}

function PackageAiView() {
  const navigate = useNavigate();
  const [context, setContext] = useState({});
  const [step, setStep] = useState(1);
  const [value1, setValue1] = useState({});
  const [initialValues1, setInitialValues1] = useState({});
  const [initialValues2, setInitialValues2] = useState({
    numberOfOwnNewPackDesigns: 1,
    numberOfVariantsPerDesign: 1,
  });
  const submitRef1 = useRef(null);
  const resetRef1 = useRef(null);
  const submitRef2 = useRef(null);
  const resetRef2 = useRef(null);

  const brandOptions = useMemo(() => {
    return BrandJson.map((e) => ({ label: e, value: e }));
  }, []);
  const categoryOptions = useMemo(() => {
    return CategoryJson.map((e) => ({ label: e, value: e }));
  }, []);

  function onContinue() {
    if (step === 1) {
      if (!submitRef1.current) return;
      setInitialValues1({ ...value1 });
      submitRef1.current.click();
    } else if (step === 2) {
      if (!submitRef2.current) return;
      submitRef2.current.click();
    }
  }

  function onCancel() {
    if (step === 1) {
      if (!resetRef1.current) return;
      setInitialValues1({});
      setTimeout(() => {
        resetRef1.current.click();
      }, 100);
    } else if (step === 2) {
      setStep(1);
      if (!resetRef2.current) return;
      resetRef2.current.click();
    }
  }

  const rules = {
    projectName: [
      {
        required: true,
        message: 'Please input your project name!',
      },
    ],
    businessObjective: [
      {
        required: true,
        message: 'Please select your business objective!',
      },
    ],
    category: [
      {
        required: true,
        message: 'Please input your category!',
      },
    ],
    unileverBrandName: [
      {
        required: true,
        message: 'Please input your Unilever brand name!',
      },
    ],
    competitor1BrandName: [
      {
        required: true,
        message: 'Please input your competitor 1 brand name!',
      },
    ],
    competitor2BrandName: [
      {
        required: true,
        message: 'Please input your competitor 2 brand name!',
      },
    ],
  };

  function onFinish1(values) {
    setValue1(values);
    setStep(2);
  }

  async function onFinish2(values) {
    sessionStorage.setItem(
      'packageAiContext',
      JSON.stringify({
        ...context,
        basic: {
          ...initialValues2,
          ...value1,
          ...values,
        },
      })
    );
    navigate('/package-ai-screening-asset');
  }

  function onFinishFailed({ errorFields }) {
    for (const e of errorFields) {
      message.error(e.errors[0]);
    }
  }

  function init() {
    const ctxs = sessionStorage.getItem('packageAiContext');
    if (!ctxs) return;
    const ctx = JSON.parse(ctxs);
    if (ctx.basic) {
      const {
        projectName,
        businessObjective,
        category,
        unileverBrandName,
        numberOfOwnNewPackDesigns,
        numberOfVariantsPerDesign,
        competitor1BrandName,
        competitor2BrandName,
      } = ctx.basic;
      setInitialValues1({ projectName, businessObjective, category });
      setInitialValues2({
        unileverBrandName,
        numberOfOwnNewPackDesigns,
        numberOfVariantsPerDesign,
        competitor1BrandName,
        competitor2BrandName,
      });
      setTimeout(() => {
        resetRef1.current?.click();
        resetRef2.current?.click();
      }, 100);
    }
    setContext(ctx);
  }
  useEffect(() => {
    init();
  }, []);

  const c2Note = useMemo(() => {
    if (value1.businessObjective === 'Competitor Benchmarking') {
      return 'Please consider the Attractive Packaging performance on UBS when choosing benchmark competitors to ensure relevant comparisons. ';
    } else if (value1.businessObjective === 'Redesign') {
      return 'Depending on the JTBD, please consider not only direct competitors by brand size but also best-in-class benchmarks, including smaller brands with strong pack designs.';
    } else if (value1.businessObjective === 'Innovation') {
      return 'Please tailor competitor selection to the business scenario; for new brands/products, include source of volumes, and for line extensions, consider the best-selling variants within the range.';
    } else {
      return `Unknown business objective ${value1.businessObjective}`;
    }
  }, [value1]);

  const [helpVisible, setHelpVisible] = useState(true);
  const onHelp = useCallback(() => {
    setHelpVisible(true);
  }, [setHelpVisible]);

  return (
    <Container>
      <div className={style.containerInner}>
        <Stage stage={1} />
        <Box
          title="Fill in Project Details"
          className={style.box}
          bgc="transparent"
        >
          {step === 1 && (
            <div className={style.form}>
              <Form
                className={style.formInner}
                layout="vertical"
                onFinish={onFinish1}
                onFinishFailed={onFinishFailed}
                initialValues={initialValues1}
              >
                <Item
                  label="Project Name"
                  name="projectName"
                  rules={rules.projectName}
                >
                  <Input className={style.formInput}></Input>
                </Item>
                <Item
                  label="Business Objective"
                  name="businessObjective"
                  rules={rules.businessObjective}
                >
                  <PackageAiBusinessObjective
                    className={style.formCheckboxGroup}
                  />
                </Item>
                <Item label="Category" name="category" rules={rules.category}>
                  <Select
                    className={style.formSelect}
                    options={categoryOptions}
                  ></Select>
                </Item>
                <button
                  ref={submitRef1}
                  type="submit"
                  style={{ display: 'none' }}
                ></button>
                <button
                  ref={resetRef1}
                  type="reset"
                  style={{ display: 'none' }}
                ></button>
              </Form>
              <div className={style.btnsBox}>
                <Btn3 className={style.btn2} onClick={onCancel}>
                  Cancel
                </Btn3>
                <Btn2
                  className={style.btn2}
                  onClick={onContinue}
                  suffix={<ArrowRightOutlined></ArrowRightOutlined>}
                >
                  CONTINUE
                </Btn2>
              </div>
            </div>
          )}
          {step === 2 && (
            <div className={`${style.form}`}>
              <Form
                className={style.formInner}
                layout="vertical"
                onFinish={onFinish2}
                onFinishFailed={onFinishFailed}
                initialValues={initialValues2}
              >
                <Row className={style.formBox21} gutter={40}>
                  <Col span={8}>
                    <Item
                      label="Unilever Brand Name"
                      name="unileverBrandName"
                      rules={rules.unileverBrandName}
                    >
                      <AutoComplete
                        className={style.formSelect}
                        options={brandOptions}
                      ></AutoComplete>
                    </Item>
                  </Col>
                </Row>
                <Row className={style.formBox22} gutter={40}>
                  <Col span={8}>
                    <Item
                      label="Competitor 1 Brand Name"
                      name="competitor1BrandName"
                      rules={rules.competitor1BrandName}
                    >
                      <Input className={style.formInput}></Input>
                    </Item>
                  </Col>
                  <Col span={8}>
                    <Item
                      label="Competitor 2 Brand Name"
                      name="competitor2BrandName"
                      rules={rules.competitor2BrandName}
                    >
                      <Input className={style.formInput}></Input>
                    </Item>
                    <p className={style.info}>{c2Note}</p>
                  </Col>
                </Row>
                <button
                  ref={submitRef2}
                  type="submit"
                  style={{ display: 'none' }}
                ></button>
                <button
                  ref={resetRef2}
                  type="reset"
                  style={{ display: 'none' }}
                ></button>
              </Form>
              <div className={style.btnsBox}>
                <Btn3 className={style.btn2} onClick={onCancel}>
                  Cancel
                </Btn3>
                <Btn2
                  className={style.btn2}
                  onClick={onContinue}
                  suffix={<ArrowRightOutlined></ArrowRightOutlined>}
                >
                  CONTINUE
                </Btn2>
              </div>
            </div>
          )}
          <div className={style.helpBtnBox}>
            <Btn1 onClick={onHelp}>
              <InfoCircleOutlined className={style.icon} />
              USER HINT
            </Btn1>
          </div>
        </Box>
      </div>
      <Help visible={helpVisible} setVisible={setHelpVisible} />
    </Container>
  );
}

export default PackageAiView;
