// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AGl9uO5PWihwikcy5GvU{position:relative;display:flex}.AGl9uO5PWihwikcy5GvU .wHd5tkKYlFZu0asou9EL{height:100%}.GjPSrwAcA8E5_4TeV6f8{position:absolute;top:50%;transform:translate(-50%, -50%);z-index:10;height:72px;width:48px;border-radius:6px;background-color:#00a32e;cursor:col-resize}.GjPSrwAcA8E5_4TeV6f8 .YgFivrJiW3fFIG9lXzoE{position:absolute;top:50%;height:28px;color:#fff}.GjPSrwAcA8E5_4TeV6f8 .wORtnMQdPeaXoQuuEQM5{left:7px;transform:translateY(-50%)}.GjPSrwAcA8E5_4TeV6f8 .jJAkcY4NJkxWJfrqDIw7{right:7px;transform:translateY(-50%) rotate(180deg)}.GjPSrwAcA8E5_4TeV6f8 .kn3RD8qaAQ79JImgesAC{position:absolute;top:0;left:0;width:100%;height:100%}`, "",{"version":3,"sources":["webpack://./src/modules/package-ai-screening/views/database/resize.module.scss"],"names":[],"mappings":"AAAA,sBACE,iBAAA,CACA,YAAA,CAEA,4CACE,WAAA,CAIJ,sBACE,iBAAA,CACA,OAAA,CACA,+BAAA,CACA,UAAA,CACA,WAAA,CACA,UAAA,CACA,iBAAA,CACA,wBAAA,CACA,iBAAA,CAEA,4CACE,iBAAA,CACA,OAAA,CACA,WAAA,CACA,UAAA,CAGF,4CACE,QAAA,CACA,0BAAA,CAGF,4CACE,SAAA,CACA,yCAAA,CAGF,4CACE,iBAAA,CACA,KAAA,CACA,MAAA,CACA,UAAA,CACA,WAAA","sourcesContent":[".resizeBox {\n  position: relative;\n  display: flex;\n\n  .item {\n    height: 100%;\n  }\n}\n\n.resizeBtn {\n  position: absolute;\n  top: 50%;\n  transform: translate(-50%, -50%);\n  z-index: 10;\n  height: 72px;\n  width: 48px;\n  border-radius: 6px;\n  background-color: #00a32e;\n  cursor: col-resize;\n\n  .icon {\n    position: absolute;\n    top: 50%;\n    height: 28px;\n    color: #fff;\n  }\n\n  .left {\n    left: 7px;\n    transform: translateY(-50%);\n  }\n\n  .right {\n    right: 7px;\n    transform: translateY(-50%) rotate(180deg);\n  }\n\n  .over {\n    position: absolute;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"resizeBox": `AGl9uO5PWihwikcy5GvU`,
	"item": `wHd5tkKYlFZu0asou9EL`,
	"resizeBtn": `GjPSrwAcA8E5_4TeV6f8`,
	"icon": `YgFivrJiW3fFIG9lXzoE`,
	"left": `wORtnMQdPeaXoQuuEQM5`,
	"right": `jJAkcY4NJkxWJfrqDIw7`,
	"over": `kn3RD8qaAQ79JImgesAC`
};
export default ___CSS_LOADER_EXPORT___;
