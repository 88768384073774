import React, { useRef, useState ,useCallback } from 'react';
import './deep-dive-body.scss';
import TrendSummary from '../trend-summary/trend-summary.jsx'
import GrowthTrend from '../growth-trend/growth-trend.jsx'
import SocialBuzz from '../social-buzz/social-buzz.jsx'
import KeyIngredients from '../key-ingredients/key-ingredients.jsx'
import {arrowRightGreen,exportTopRight,exportTopLeft,exportBottomRight,exportBottomLeft,exportLogo,popuoClose} from '../../../../components/icons/icons.jsx';


function DeepDiveBody({changeIsDeepDiveVisible=changeIsDeepDiveVisible,selectedWordCloud={selectedWordCloud},loading={loading},err={err},trendingDishData={trendingDishData}}) {
  const componentRef = useRef(null)
  const sideMenuItems = [
    {title:'Trend Summary'},
    {title:'Growth Trend'},
    {title:'Social Buzz'},
    {title:'Key Ingredients'}
  ];
  const [selectedTitle, setSelectedTitle] = useState('Trend Summary');
  const SideMenuItem = ({item}) =>{
    if(selectedTitle==item.title){
      return(
        <div className='sideMenuItem' onClick={(e)=>handleSideMenuClick(item)}>
          <div className='sideMenuText' style={{color:'#26F028'}}>{item.title}</div>
          <img className='selectedArrow' src={arrowRightGreen}/>
        </div>
      )
    }else{
      return(
        <div className='sideMenuItem' onClick={(e)=>handleSideMenuClick(item)}>
          <div className='sideMenuText'>{item.title}</div>
        </div>
      )
    }
  };


  const handleSideMenuClick = (e) => {
    if(e.title ==='Key Ingredients'){
      return
    }
    setSelectedTitle(e.title)
  }
  const handleExport = (e) =>{
    console.log(e,selectedTitle)
    // content: () => componentRef.current,
    // let printStr = document.getElementById('print').innerHTML
    // let oldStr = document.body.innerHTML
    // document.body.innerHTML = document.getElementById('print').innerHTML
    // window.print()
    // document.body.innerHTML = oldStr
  }

  const handleClose = (e) =>{
    // console.log(e)
    changeIsDeepDiveVisible(false)
  }

  return (
    <div className="container">
        <div className='sideMenu'>
          {sideMenuItems.map((item,index)=>(
            <SideMenuItem item={item} key={index}/>
          ))}
        </div>
        <div className='borderLine'></div>
        
        <div className='export' onClick={handleExport}>
          <img className='exportLogo' src={exportLogo}/>
          <img className='exportTR' src={exportTopRight}/>
          <img className='exportTL' src={exportTopLeft}/>
          <img className='exportBR' src={exportBottomRight}/>
          <img className='exportBL' src={exportBottomLeft}/>
          Export
        </div>
        <div className='popupclose' onClick={handleClose}><img src={popuoClose}/></div>
        <div className='detail'>
          {selectedTitle=='Trend Summary' && <TrendSummary  trendingDishData={trendingDishData} ref={componentRef}/>}
          {selectedTitle=='Growth Trend' && <GrowthTrend loading={loading} err={err} trendingDishData={trendingDishData}/>}
          {selectedTitle=='Social Buzz' && <SocialBuzz selectedWordCloud={selectedWordCloud} loading={loading} err={err} trendingDishData={trendingDishData}/>}
          {selectedTitle=='Key Ingredients' && <KeyIngredients/>}
        </div>
    </div>
  );
}

export default DeepDiveBody;
