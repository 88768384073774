// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AmdOQMECNgxmRVRAQF43{display:flex}.c4NhrMGT3oS7oC5D0eRI{background-color:hsla(0,0%,100%,.5019607843);display:flex;border-top-left-radius:14px;border-top-right-radius:14px}.Plge1p_7eDNy6wTsVgKE{padding:14px 44px 8px;border-top-left-radius:14px;border-top-right-radius:14px;font-size:16px;font-weight:500;letter-spacing:0em;transition:all .4s;cursor:pointer}.Plge1p_7eDNy6wTsVgKE span{color:#fff}.hwd2fsYsVGJ__8BJpid9{background-color:#fff}.hwd2fsYsVGJ__8BJpid9 span{color:#00a32e}.wf8gqKOcVjYgenVgyB3A{background-color:#fff;padding:24px 30px;border-top-right-radius:16px;border-bottom-left-radius:16px;border-bottom-right-radius:16px}`, "",{"version":3,"sources":["webpack://./src/modules/knorr/components/tabs.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CAGF,sBACE,4CAAA,CACA,YAAA,CACA,2BAAA,CACA,4BAAA,CAGF,sBACE,qBAAA,CACA,2BAAA,CACA,4BAAA,CACA,cAAA,CACA,eAAA,CACA,kBAAA,CACA,kBAAA,CACA,cAAA,CACA,2BACE,UAAA,CAIJ,sBACE,qBAAA,CACA,2BACE,aAAA,CAIJ,sBACE,qBAAA,CACA,iBAAA,CACA,4BAAA,CACA,8BAAA,CACA,+BAAA","sourcesContent":[".tabHeader {\n  display: flex;\n}\n\n.tabHeaderInner {\n  background-color: #ffffff80;\n  display: flex;\n  border-top-left-radius: 14px;\n  border-top-right-radius: 14px;\n}\n\n.tabHeaderBtn {\n  padding: 14px 44px 8px;\n  border-top-left-radius: 14px;\n  border-top-right-radius: 14px;\n  font-size: 16px;\n  font-weight: 500;\n  letter-spacing: 0em;\n  transition: all 0.4s;\n  cursor: pointer;\n  span {\n    color: #fff;\n  }\n}\n\n.tabHeaderBtnActive {\n  background-color: #fff;\n  span {\n    color: #00a32e;\n  }\n}\n\n.tabContent {\n  background-color: #fff;\n  padding: 24px 30px;\n  border-top-right-radius: 16px;\n  border-bottom-left-radius: 16px;\n  border-bottom-right-radius: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tabHeader": `AmdOQMECNgxmRVRAQF43`,
	"tabHeaderInner": `c4NhrMGT3oS7oC5D0eRI`,
	"tabHeaderBtn": `Plge1p_7eDNy6wTsVgKE`,
	"tabHeaderBtnActive": `hwd2fsYsVGJ__8BJpid9`,
	"tabContent": `wf8gqKOcVjYgenVgyB3A`
};
export default ___CSS_LOADER_EXPORT___;
