// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.QfQLkdEjIfZwT1oBEWy7{position:relative;padding:30px;border:solid 1px #444;grid-area:span 1/span 6}.w92mWeecHaCCTQAQkG_y{font-size:24px;font-weight:900}`, "",{"version":3,"sources":["webpack://./src/modules/occasion-module/views/dashboard/components/divider.module.scss"],"names":[],"mappings":"AAAA,sBACE,iBAAA,CACA,YAAA,CACA,qBAAA,CACA,uBAAA,CAGF,sBACE,cAAA,CACA,eAAA","sourcesContent":[".dividerBox {\n  position: relative;\n  padding: 30px;\n  border: solid 1px #444;\n  grid-area: span 1 / span 6;\n}\n\n.dividerTitle {\n  font-size: 24px;\n  font-weight: 900;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dividerBox": `QfQLkdEjIfZwT1oBEWy7`,
	"dividerTitle": `w92mWeecHaCCTQAQkG_y`
};
export default ___CSS_LOADER_EXPORT___;
