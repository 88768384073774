import React from 'react';
import LoadingGif from '../../assets/img/loading2.gif';
import style from './loading.module.scss';

function PackageAiLoading({ className = '' }) {
  return (
    <div className={`${style.loadingBox} ${className}`}>
      <img
        className={style.loadingImg}
        src={LoadingGif}
        alt="loading"
        loading="lazy"
      />
    </div>
  );
}

export default PackageAiLoading;
