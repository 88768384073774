// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rN_7cdNYMR6jKkYonHzz{position:absolute;top:0;left:0;width:100%;height:100%;z-index:-1}.rN_7cdNYMR6jKkYonHzz video{width:100%;height:100%;object-fit:cover;opacity:.3}`, "",{"version":3,"sources":["webpack://./src/modules/package-ai-performance/components/video-bg/bg.module.scss"],"names":[],"mappings":"AAAA,sBACE,iBAAA,CACA,KAAA,CACA,MAAA,CACA,UAAA,CACA,WAAA,CACA,UAAA,CACA,4BACE,UAAA,CACA,WAAA,CACA,gBAAA,CACA,UAAA","sourcesContent":[".videoBox {\n  position: absolute;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  z-index: -1;\n  video {\n    width: 100%;\n    height: 100%;\n    object-fit: cover;\n    opacity: 0.3;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"videoBox": `rN_7cdNYMR6jKkYonHzz`
};
export default ___CSS_LOADER_EXPORT___;
