import React, { useEffect, useState } from 'react';
import style from './index.module.scss';
import { Select } from 'antd';
import classNames from 'classnames';
import SwitchBtnCom from '../switch-btn/index.jsx';
import logoSmall from '../../../../assets/images/bu-monthly-feedback/logo_small.png';
import DwSvgIcon from '../../../../assets/images/bu-monthly-feedback/download-icon.svg';
import EditContent from './edit.jsx';
import { useBase } from '../../hooks/useBase.jsx';
import useFeedback from '../../hooks/useFeedback.js';
import ShowTableCom from './showTable.jsx';

const MainContent = () => {
  const { buOptions, categoryOptions, editChange, curDataChange, curData, isEdit } = useBase();
  const { dataList, getFeedbackList, changeDataList, exportExcel } = useFeedback();

  useEffect(() => {
    try {
      getFeedbackList();
    } catch(error) {
      console.log(error);
    }
  }, [getFeedbackList]);

  const [bu, setBu] = useState('World');
  const [category, setCategory] = useState(null);
  const handleChange = (value, type) => {
    if(type === 'bu') {
      setBu(value);
    } else {
      setCategory(value);
    }
  };

  const [sort, setSort] = useState('bg_desc');
  const paginateChange = (pagination, _, sorter) => {
    const { field, order } = sorter;
    if(order === 'ascend') {
      setSort(`${field}_asc`);
    } else if(order === 'descend'){
      setSort(`${field}_desc`);
    } else {
      setSort(null);
    }
  };

  const [size, setSize] = useState('all');
  const fnSwitchChange = (val) => {
    setSize(val);
  };

  useEffect(() => {
    getFeedbackList({
      size,
      sort,
      bu,
      category
    });
  }, [getFeedbackList, size, sort, bu, category]);

  const fnOpen = (record) => {
    editChange();
    curDataChange([record]);
  };

  useEffect(() => {
    if(curData.length && !isEdit) {
      const tempData = [...dataList];
      const index = tempData.findIndex(item => item.id === curData[0].id);
      if(index > -1) {
        tempData[index] = curData[0];
        changeDataList(tempData);
      }
    }
  }, [isEdit, curData]);

  return <div>
    <div className={classNames(style.headerWrap)}>
      <img src={logoSmall} alt="" className={classNames(style.leftBox)} />
      <div className={classNames(style.rightBox)}>
        {
          !isEdit ? <>
            <div className={classNames(style.selectBox)}>
              <div className={style.inputBorder} style={{backgroundColor: bu  ? 'rgba(1, 102, 2, 1)' :  'transparent'}}>
                <Select
                  value={bu}
                  style={{width: '112px'}}
                  placeholder='Business Unit'
                  options={buOptions}
                  onChange={(value) => handleChange(value, 'bu')}
                  popupMatchSelectWidth={false}
                />
              </div>
              <div className={style.inputBorder} style={{backgroundColor: category  ? 'rgba(1, 102, 2, 1)' :  'transparent'}}>
                <Select
                  value={category}
                  style={{width: '112px'}}
                  placeholder='Category'
                  allowClear
                  options={categoryOptions}
                  onChange={(value) => handleChange(value, 'category')}
                  popupMatchSelectWidth={false}
                />
              </div>
            </div>
            <div style={{width: '30px'}}></div>
            <SwitchBtnCom onChange={fnSwitchChange}></SwitchBtnCom>
            <div style={{width: '30px'}}></div>
            <div className={classNames(style.downBtn)} onClick={() => exportExcel()}>
              <img src={DwSvgIcon} alt="" className={classNames(style.iconImg)}/>
              <span className={classNames(style.label)}>Export excels</span>
            </div>
            <div style={{width: '20px'}}></div>
          </> : <div style={{heigh: '88px'}}></div>
        }
      </div>
    </div>
    <div className={classNames(style.switchEditWrap)} id="tableWrap">
      <ShowTableCom dataList={dataList} paginateChange={paginateChange} openChange={fnOpen}></ShowTableCom>
      <div className={classNames(style.tableBox)}>
        <EditContent></EditContent>
      </div>
    </div>
  </div>;

};

export default MainContent;