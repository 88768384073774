import React from 'react';

const ErrorModal = ({ showErrorModal,setShowErrorModal}) => {
  return (
    <div className="confirmation-modal modal">
      <div className="modal-backdrop"></div>
      <div className="modal-body">
        <div className="modal-container">
          <p>No Data Found</p>
          <div className="buttons">
            <button
              onClick={()=>{
                setShowErrorModal(!showErrorModal);
              }}
              className="share-learning-button cancel-button close"
            >
              Okay
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ErrorModal;
