// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.D9BqPCaSp5LLk_EEVPhq{padding:20px;display:grid;gap:1vw;grid-template-columns:repeat(6, 1fr);grid-auto-rows:90px}@media screen and (max-width: 1300px){.D9BqPCaSp5LLk_EEVPhq{grid-auto-rows:60px}}`, "",{"version":3,"sources":["webpack://./src/modules/occasion-module/views/dashboard/tabs/tab.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,YAAA,CACA,OAAA,CACA,oCAAA,CACA,mBAAA,CACA,sCANF,sBAOI,mBAAA,CAAA","sourcesContent":[".chartContainer {\n  padding: 20px;\n  display: grid;\n  gap: 1vw;\n  grid-template-columns: repeat(6, 1fr);\n  grid-auto-rows: 90px;\n  @media screen and (max-width: 1300px) {\n    grid-auto-rows: 60px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"chartContainer": `D9BqPCaSp5LLk_EEVPhq`
};
export default ___CSS_LOADER_EXPORT___;
