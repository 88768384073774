// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.XqCxz4_basdU9YEstfM1{width:100% !important;height:100vh}.w7iWCrCopP2K6g9KG9uA{position:relative;width:95%;margin:auto;margin-top:4vw}.XZLuL1rWB5kZ7Z_P4o32{margin-right:4vw;width:3vw}`, "",{"version":3,"sources":["webpack://./src/modules/drafted-index/ai-top-dish.module.scss"],"names":[],"mappings":"AAAA,sBACE,qBAAA,CACA,YAAA,CAGF,sBACE,iBAAA,CACA,SAAA,CACA,WAAA,CACA,cAAA,CAGF,sBACE,gBAAA,CACA,SAAA","sourcesContent":[".fullContainer {\n  width: 100% !important;\n  height: 100vh;\n}\n\n.filterApply {\n  position: relative;\n  width: 95%;\n  margin: auto;\n  margin-top: 4vw;\n}\n\n.aiTopDishEngine {\n  margin-right: 4vw;\n  width: 3vw;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"fullContainer": `XqCxz4_basdU9YEstfM1`,
	"filterApply": `w7iWCrCopP2K6g9KG9uA`,
	"aiTopDishEngine": `XZLuL1rWB5kZ7Z_P4o32`
};
export default ___CSS_LOADER_EXPORT___;
