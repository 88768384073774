import React from 'react';
import { Tabs, Button } from 'antd';
import style from './advance-com.module.scss';
import classNames from 'classnames';

const AdvanceCom = () => {
  const dashboardTabItems = [
    {
      key: '1',
      label: 'R-View Dashboard',
    }
  ];
  const dvocTabItems = [
    {
      key: '1',
      label: 'DVOC',
    }
  ];

  const jumpBtnHandle = (url) => {
    window.open(url, '_blank');
  };
  
  return (
    <div className={style.advanceCom}>
      <Tabs items={dashboardTabItems} defaultActiveKey="1" />
      <div className={style.contentBox}>
        <div className={style.mainItem}>
          <div className={classNames(style.mainItemContent, style.rView)}>
            <Button className={style.jumpBtn} type="primary" size="small" 
              onClick={() => jumpBtnHandle('https://app.powerbi.com/groups/me/apps/29c55f16-e039-467d-ac2f-f668b97052a6/reports/4da20254-1f09-452f-9ffe-0fdf0cfea5ac/ReportSection56fe263e5343a55b15b2?ctid=f66fae02-5d36-495b-bfe0-78a6ff9f8e6e&experience=power-bi')}
            >Jump to</Button>
          </div>
        </div>
      </div>
      <Tabs items={dvocTabItems} defaultActiveKey="1" />
      <div className={style.contentBox}>
        <div className={style.mainItem}>
          <div className={classNames(style.mainItemContent, style.dvoc)}>
            <Button className={style.jumpBtn} type="primary" size="small"
              onClick={() => jumpBtnHandle('https://app.powerbi.com/groups/me/apps/fdb5ca09-9998-4984-8268-8bdb1a10db87/reports/5efe9bbe-285a-489a-b199-acf2fa189b8e/ReportSectioncbc331c18a76c8a0aa68?ctid=f66fae02-5d36-495b-bfe0-78a6ff9f8e6e&experience=power-bi')}
            >Jump to</Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdvanceCom;