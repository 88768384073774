import React from 'react';
import { howToUseTool } from '../../../components/icons/icons.jsx';

export const guideData = {
  data: [
    {
      title: 'Trend Index',
      content:
        (<span className='guide-data-index'>The trend index is based on a scale from 0 to 100 and indicates the importance of the trend for a brand. The index is based on <b>three key principles that prioritize social and absolute short-term growth:</b><ol><li>Social outweighs search (3:1)</li><li>Absolute volume change outweighs percentage volume change (72:25)</li><li>Short-term growth outweighs long-term growth (95:5)</li></ol> <br />A brief explanation of each metric: </span>),
      subdata: [
        {
          subtitle: 'Social',
          suncontent:
            'Based on Instagram and TikTok data',
        },
        {
          subtitle: 'Search',
          suncontent:
            'Based on Google data',
        },
        {
          subtitle: 'Absolute volume change',
          suncontent:
            'The absolute change in the number of mentions on social and the number of searches on Google vs. the previous month. Prioritizing absolute change enables us to differentiate between a “big bet” and a “small” bet.',
        },
        {
          subtitle: 'Percentage volume change',
          suncontent:
            'The percentage change in the number of mentions on social and the number of searches on Google vs. the previous month.',
        },
        {
          subtitle: 'Short-term growth',
          suncontent:
            'Considers how the volume has grown or declined in the last month across all channels. Prioritizing short-term growth enables us to identify new, rising trends.',
        },
        {
          subtitle: 'Long-term growth',
          suncontent:
            'Considers how the volume has grown or declined in the last year across all channels.',
        },
      ],
      footercontent:
        'The trend index is normalized within the market and there cannot be compared across markets. To allow for comparison between brands and top dishes, the trend index is normalized across brands and top dishes. ',
      icon: (
        <div className='guide-data-icon'>
          <span>Trend <br/>Index</span>
          {/* <span><img
            src={Filter}
            alt="filter"
          /></span> */}
        </div>
      ),
    },
    {
      title: 'Trend lifestage',
      content:
        'The trend lifestage shows the pattern of growth of the trend over the period for which there is data available. This considers all channels. \n\nThere are four types of trend lifestages for the trending twists:',
      subdata: [
        {
          subtitle: 'Consistent increase trend:',
          suncontent:
            'The trend has been consistently increasing over time.',
        },
        {
          subtitle: 'Consistent decrease trend:',
          suncontent:
            'The data shows non-seasonal fluctuations with irregular variations that do not follow a specific cycle.The trend has been consistently decreasing over time.',
        },
        {
          subtitle: 'Seasonal trend:',
          suncontent:
            'The trend shows a seasonal pattern with recurring fluctuations that follow a specific cycle.',
        },
        {
          subtitle: 'Fluctuating trend:',
          suncontent:
            'The data shows non-seasonal fluctuations with irregular variations that do not follow a specific cycle.',
        },
      ],
      // Removed icon and replaced with name in div
      icon: (
        <div className='guide-data-icon'>
          <span>Trend <br/>Lifecycle</span>
          {/* <span><img
            src={Filter}
            alt="filter"
          /></span> */}
        </div>
      ),
    },
    {
      title: 'Category relevance',
      content:
        'The relevance score shows how relevant the top dish variant is to the top dish. This score is based on a scale from 0 to 100. \n\nScores as high as 100 indicate the highest relevance of a top dish variant to the top dish, whereas scores as low as 0 indicate a low relevance to the top dish. \n\nSearch count data is used to determine the relevance of the top dish variant and can be understood using the following calculation: \n\nRelevance Score = (Search Count of Top Dish Variants – Mean Search Count) / Standard Deviation of Search Counts \n\nThe values are further normalised so that the lowest score will equal 0 and the highest 100.',
      subdata: [],
      icon: (
        <div className='guide-data-icon'>
          <span>Category <br/>Relevance</span>
          {/* <span><img
            src={arrowUp}
            alt="arrowUp"
          /></span> */}
        </div>
      ),
    },
  ],
  faq: [
    {
      question: 'What trend should I be focusing on in my market / for my brand?',
      answer: 'You can filter the dishes that are relevant for your market and/or brand using the filters at the top of the dashboard. As the trend index is determined across top dishes, the top dish variant with the highest trend index is the most trending for your market.',
    },
    {
      question: 'If my brands primary top dish is pasta, what trend should I focus on?',
      answer: 'You can filter for your brand and top dish using the filters at the top of the dashboard. The top dish variant for pasta with the highest trend index score will be the most trending.',
    },
    {
      question: 'Why are there no top dish variants with a high trend index for the top dish I selected?',
      answer: 'The trend index is calculated across dishes to determine the most trending top dish variant across all top dishes. This means that if there is a viral variant for a particular top dish (e.g. soup) that has grown significantly in the last month, the growth in a variant for another top dish (e.g. pasta) may be relatively low and thus receive a lower index score. In simple terms, if there is a variant for top dish A that is trending more than the variants for top dish B, all variants for top dish B will have a lower trend index. Hence, it is recommended to look at the other top dish options before proceeding to execution.',
    },
    {
      question: 'What is the most trending twist on Instagram?',
      answer: 'If you are looking for a trending top dish variant on a particular channel (e.g. Instagram), you can use the filters to sort the columns based on the % growth for that channel in the last month. For the most trending twist on Instagram, look for the variant that has shown the highest growth in volume in the last month.',
    },
    {
      question: 'How often is the trend index updated?',
      answer: 'The trend index is updated every month. The top of the dashboard will show the latest period for which the data is available..',
    },
    {
      question: 'Why is the trend index not available in my market yet?',
      answer: 'We are developing the trend index for markets on a rolling basis. Currently, the index is available for the US and India. Brazil and Indonesia are planned for launch towards the end of Q4 2024.',
    },
    {
      question: 'Why is there no TikTok or Instagram content available for some top dish variants?',
      answer: 'In most cases, there will be social content for your top dish variant. We are continuously working on getting in more social data and hope to have social content examples available for all top dish variants in the near future.',
    },
    {
      question: 'Why are some of the characters in the hashtags of posts appearing as question marks?',
      answer: 'This is a result of emojis and unrecognizable characters.',
    },
    {
      question: 'Why are some posts appearing from influencers not based in the specified market?',
      answer: 'Due to Brandwatch and Meta limitations, some posts will appear related to the selected trending twist and market, but not always from an influencer based in that specific market.',
    },
    {
      question: 'When will there be more channels available beyond TikTok, Instagram and Google?',
      answer: 'We are continuously adding new functionalities to the trend index and are planning on adding more channels in the new future. Once a new channel is added, we will inform you as soon as possible.',
    },
    // Add more FAQ items as needed
  ],
  tool:{
    title: 'How to use tool',
    icon: `${howToUseTool}`,
  }
};

