import pythonApi from '@/util/pythonApi';

export function getUserList(page, pageSize) {
  const params = {
    page: page,
    page_size: pageSize,
  };
  return pythonApi({
    url: '/v1/users',
    method: 'get',
    params: params,
  });
}

export function createUser(data) {
  return pythonApi({
    url: '/v1/users',
    method: 'post',
    data: data,
  });
}

export function editUser(id, data) {
  return pythonApi({
    url: '/v1/users/' + id,
    method: 'put',
    data: data,
  });
}

export function deleteUser(id) {
  return pythonApi({
    url: '/v1/users/' + id,
    method: 'delete',
  });
}
