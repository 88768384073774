import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Tabs, Dropdown, Spin } from 'antd';
import { DownOutlined, LoadingOutlined } from '@ant-design/icons';
import style from './main-com.module.scss';
import noFilters from '../../../../assets/images/ratings-review/no-filters.svg';
import classNames from 'classnames';
import AverageCom from '../average-com/average-com.jsx';
import MarketingMix from '../marketing-mix/marketing-mix.jsx';
import ProductDeepDive from '../product-deep-dive/product-deep-dive.jsx';
import { fetchChartsData } from '../../api/request.js';
import NoData from '../no-data/no-data.jsx';

const averageTabItems = [
  {
    key: 'rating',
    label: 'Average Ratings',
  },
  {
    key: 'score',
    label: 'Average Sentiment Scores',
  },
  {
    key: 'volume',
    label: 'Reviews Volume',
  }
];

const marketTabItems = [
  {
    key: 'market',
    label: 'Marketing Mix Indicator',
  },
  {
    key: 'Overall',
    label: 'Overall',
  },
  {
    key: 'Product',
    label: 'Product'
  },
  {
    key: 'Price',
    label: 'Price'
  },
  {
    key: 'Place',
    label: 'Place'
  },
  {
    key: 'Proposition',
    label: 'Proposition'
  },
  {
    key: 'Promotion',
    label: 'Promotion'
  },
  {
    key: 'Packaging',
    label: 'Packaging'
  }
];

const antIcon = (
  <LoadingOutlined
    style={{
      fontSize: 30,
      color: '#00FF00'
    }}
    spin
  />
);

function debounce (callback, delay) {
  let lastTime;
  return function () {
    clearTimeout(lastTime);
    const [that, args] = [this, arguments];
    lastTime = setTimeout(() => {
      callback.apply(that, args);
    }, delay);
  };
}

let requestIndex = Date.now();
let requestStop = false;

const MainCom = ({searchParams}) => {

  const [averageExportLoading, setAverageExportLoading] = useState(false);
  const averageRef = useRef(null);
  const excelExportAverage = () => {
    if (!averageRef?.current) return;
    setAverageExportLoading(true);
    averageRef.current.exportExcel();
    setAverageExportLoading(false);
  };
  const csvExportAverage = () => {
    if (!averageRef?.current) return;
    setAverageExportLoading(true);
    averageRef.current.exportCsv();
    setAverageExportLoading(false);
  };
  const averageDropItems = [
    {
      key: '1',
      label: <div onClick={excelExportAverage}>Export as Excel</div>,
    },
    {
      key: '2',
      label: <div onClick={csvExportAverage}>Export as Csv</div>,
    },
  ];

  // average
  const [averageTabKey, setAverageTabKey] = useState('rating');
  const averageTabChange = (val) => {
    setAverageTabKey(val);
  };
  const [averageLoading, setAverageLoading] = useState(false);
  const [averageData, setAverageData] = useState([]);

  const fetchAverage = (searchParams) => {
    if (requestStop) return;
    setAverageLoading(true);
    fetchChartsData(searchParams, requestIndex)
      .then(res => {
        if (requestStop || requestIndex !== res.config.uuid) return;
        const arr = Array.isArray(res.data.data) ? res.data.data.map(item => {
          return {
            ...item,
            label: item.name,
            value: item.name
          };
        }) : [];
        setAverageData(arr);
        setAverageLoading(false);
      })
      .catch(error => {
        if (requestIndex !== error?.config?.uuid) return;
        setAverageData([]);
        setAverageLoading(false);
      });
  };
  const delayFetchAverage = useCallback(debounce(function(val) { return fetchAverage(val);}, 500), []);

  useEffect(() => {
    if (!searchParams) {
      requestStop = true;
      setAverageData([]);
      setAverageLoading(false);
      return;
    }
    requestStop = false;
    requestIndex++;
    delayFetchAverage(searchParams);
  }, [searchParams]);

  const useComputed = (computedFunc, dependencies) => {
    const [value, setValue] = useState(computedFunc());
   
    useEffect(() => {
      setValue(computedFunc());
    }, dependencies);
   
    return value;
  };

  // marketing 
  // tab
  const [marketTabKey, setMarketTabKey] = useState('market');
  const tabMarketChangeHandle = (val) => {
    setMarketTabKey(val);
    if (val === 'market') {
      setMarketExportShow(true);
    } else {
      if (productRef.current?.getTab() === '1') {
        setMarketExportShow(false);
      } else {
        setMarketExportShow(true);
      }
    }
  };
  const marketVisible = useComputed(() => marketTabKey === 'market', [marketTabKey]);
  const productVisible = useComputed(() => marketTabKey !== 'market', [marketTabKey]);

  const [marketExportShow, setMarketExportShow] = useState(true);

  const deepDiveTabChange = (val) => {
    if (marketTabKey !== 'market' && val === '1') {
      setMarketExportShow(false);
    } else {
      setMarketExportShow(true);
    }
  };

  // 导出
  const [marketExportLoading, setMarketExportLoading] = useState(false);
  const marketRef = useRef(null);
  const productRef = useRef(null);
  const excelExport = () => {
    setMarketExportLoading(true);
    if (marketTabKey === 'market') marketRef.current.exportExcel();
    if (marketTabKey !== 'market') productRef.current.exportExcel();
    setMarketExportLoading(false);
  };
  const csvExport = () => {
    setMarketExportLoading(true);
    if (marketTabKey === 'market') marketRef.current.exportCsv();
    if (marketTabKey !== 'market') productRef.current.exportCsv();
    setMarketExportLoading(false);
  };
  const dropItems = [
    {
      key: '1',
      label: <div onClick={excelExport}>Export as Excel</div>,
    },
    {
      key: '2',
      label: <div onClick={csvExport}>Export as Csv</div>,
    },
  ];

  // 没有选择产品时的展示内容
  const noFilterResult = (
    <div className={style.noData}>
      {!searchParams ?
        <>
          <div className={style.noDataImg}><img src={noFilters} alt="" /></div>
          <div className={style.noDataMsg}>Make a selection to display or compare</div>
        </>
        :
        <NoData />
      }
    </div>
  );

  return (
    <div className={style.mainCom}>
      <div className={style.tabBox}>
        <Tabs items={averageTabItems} activeKey={averageTabKey} onChange={averageTabChange}></Tabs>
        <Dropdown className={classNames(style.exportDropdown, !searchParams ? style.exportDisabled : '')} menu={{ items: averageDropItems }} disabled={averageExportLoading || !searchParams}>
          <div className={style.exportBtn}>{averageExportLoading && <LoadingOutlined />}Export as<DownOutlined className={style.icon} /></div>
        </Dropdown>
      </div>
      <Spin indicator={antIcon} spinning={averageLoading}>
        <div className={style.contentBox}>
          {
            averageData.length === 0 && noFilterResult
          }
          {
            averageData.length > 0 && <AverageCom ref={averageRef} products={averageData} tab={averageTabKey} />
          }
        </div>
      </Spin>
      <div className={classNames(style.tabBox, style.secondTabBox)}>
        <Tabs defaultActiveKey="1" items={marketTabItems} activeKey={marketTabKey} onChange={tabMarketChangeHandle}></Tabs>
        {marketExportShow && <Dropdown className={classNames(style.exportDropdown, !searchParams ? style.exportDisabled : '')} menu={{ items: dropItems }} disabled={marketExportLoading || !searchParams}>
          <div className={style.exportBtn}>{marketExportLoading && <LoadingOutlined />}Export as<DownOutlined className={style.icon} /></div>
        </Dropdown>}
      </div>
      <div className={style.contentBox1}>
        <div className={style.contentBoxMix} style={{display: marketVisible ? 'block' : 'none' }}>
          <MarketingMix 
            ref={marketRef} 
            visible={marketVisible} 
            searchParams={searchParams} 
            noFilterResult={noFilterResult}
          />
        </div>
        <div className={style.contentBoxDeep} style={{display: productVisible ? 'block' : 'none' }}>
          <ProductDeepDive 
            ref={productRef}
            visible={productVisible}
            searchParams={searchParams}
            noFilterResult={noFilterResult}
            tabChange={deepDiveTabChange}
            currentTabKey={marketTabKey}
          />
        </div>
      </div>
    </div>
  );
};

export default MainCom;