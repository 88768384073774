import React, { useEffect } from 'react';
import classNames from 'classnames';
import style from './intro-popup.module.scss';
import closeSvg from '../../../../assets/images/gotfl-weena/close-icon.svg';
import { ReactComponent as ArrowRightSvg } from '../../../../assets/images/gotfl-weena/arrow_right.svg';

import { Modal } from 'antd';
import BorderContainer from '../../../../components/border-container/border-container.jsx';
import useBasic from '../../hooks/useBasic.js';
import { isWinDpr1 } from '../../../../util/utils.jsx';
import { downloadFile } from '../../hooks/utils.js';

const PreviewIntroPopup = ({open, onClose, detail, onChangeIndex, indexStatus}) => {
  return <Modal open={open} closeIcon={false} width="1100px" centered={isWinDpr1()} footer={false} className={style.customModalWrap}  destroyOnClose={true}>
    <img src={closeSvg} className={classNames(style.closeIcon)} onClick={() => onClose(false)} />
    <BorderContainer id="intro_1">
      <div className={classNames(style.titleWrap)}>Offline Weena</div>
      <div className={classNames(style.contentWrap)}>
        <img src={detail?.thumbUrl} className={classNames(style.imgBg)}></img>
        <div className={classNames(style.detailWrap)}>
          <div className={classNames(style.label)}>E-Mail :</div>
          <div className={classNames(style.desc)}>{detail?.name}</div>
          <div style={{height: '8px'}}></div>
          <div className={classNames(style.label)}>Job Role :</div>
          <div className={classNames(style.desc)}>{detail?.jobRole}</div>
          <div style={{height: '8px'}}></div>
          <div className={classNames(style.label)}>What did you find inspiring about this product?</div>
          <div className={classNames(style.scrollBox)}>
            <div className={classNames(style.desc)}>{detail?.intro}</div>
          </div>

          {/*
            <div className={classNames(style.btnBox)}>
              <div className={classNames(style.btn)} onClick={() => downloadFile(detail)}>
                Download Picture
              </div>

              <div className={classNames(style.btn, style.outlineBtn)} onClick={() => onChangeIndex('next')}>
                Next Picture
              </div>
            </div>
            */
          }
        </div>

        <div className={classNames(style.btnBar, style.preBar)}>
          <ArrowRightSvg className={classNames(style.btnIcon, indexStatus === 'preEnd' ? style.disabled : '')} onClick={() => onChangeIndex('pre')}></ArrowRightSvg>
        </div>
        <div className={classNames(style.btnBar, style.nextBar)}>
          <ArrowRightSvg className={classNames(style.btnIcon, indexStatus === 'nextEnd' ? style.disabled : '')} fill="red" onClick={() => onChangeIndex('next')}></ArrowRightSvg>
        </div>
      </div>
    </BorderContainer>
  </Modal>;
};

export default PreviewIntroPopup;