import React, { useState, useEffect } from 'react';
// import style from '';
import { Table, Menu, Dropdown, Button, Tag, Modal, Form, Spin, notification } from 'antd';
import { deleteUser, editUser } from '../api/request';
import EditCreateUserModal from '../components/edit-create-user.jsx';

const UserManagementTable = ({
  fetchData,
  userList,
  total,
  currentPage,
  pageSize,
  loading,
  handlePageChange,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editingRecord, setEditingRecord] = useState(null);
  const [form] = Form.useForm();

  const columns = [
    {
      title: 'User Name',
      dataIndex: 'username',
      key: 'username',
      sorter: (a, b) => a.username.localeCompare(b.username),
    },
    {
      title: 'Roles',
      dataIndex: 'role',
      key: 'roles',
      sorter: (a, b) => a.username.localeCompare(b.username),
    },
    {
      title: 'Department',
      dataIndex: 'department',
      key: 'department',
      sorter: (a, b) => a.username.localeCompare(b.username),
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      sorter: (a, b) => a.username.localeCompare(b.username),
    },
    {
      title: 'Is Active',
      dataIndex: 'active',
      key: 'active',
      render: (active) => (active ? 'true' : 'false'),
      sorter: (a, b) => a.username.localeCompare(b.username),
    },
    {
      title: 'Created By',
      dataIndex: 'created_by',
      key: 'created_by',
      sorter: (a, b) => a.username.localeCompare(b.username),
    },
    {
      title: 'Created By',
      dataIndex: 'created_by',
      key: 'created_by',
      sorter: (a, b) => a.username.localeCompare(b.username),
    },
    {
      title: 'Permissions',
      dataIndex: 'permissions',
      key: 'permissions',
      render: (_, { permissions }) => (
        <>
          {permissions.map((permission, index) => {
            let color = 'geekblue';
            return <Tag color={color} key={index}>{permission.toUpperCase()}</Tag>;
          })}
        </>
      ),
    },
    {
      title: 'Action',
      key: '',
      render: (_, record) => (
        <Dropdown overlay={getMenu(record)}>
          <Button>
            Action<i className="anticon anticon-down"></i>
          </Button>
        </Dropdown>
      ),
    },
  ];

  const handleFinish = () => {
    form
      .validateFields()
      .then((values) => {
        console.log('Form values: ', values);
        editUser(editingRecord.id, JSON.stringify(values)).then((response) => {
          if (response.status === 'Success') {
            notification.open({
              message: 'successfully updated user',
              description: 'updated new user:' + values.username,
              onClick: () => {
                console.log('Notification Clicked!');
              },
            });
            fetchData(); 
          } else {
            notification.error({
              message: 'Fail to update user',
            });
            console.error('error updating');
          }
        });
      })
      .catch((info) => {
        console.log('Validate Failed:', info);
      });
    setIsEditing(false);
  };

  const showEditModal = (record) => {
    setIsEditing(true);
    setEditingRecord(record);
    // renderEditModal();
  };

  const handleDelete = (record) => {
    Modal.confirm({
      title: 'Confirm Delete',
      content: 'Are you sure to delete this record?',
      onOk: async () => {
        try {
          const response = await deleteUser(record.id);
          if (response.status === 200) {
            fetchData();
          }
          console.log(`Deleted: ${record.id}`);
        } catch (error) {
          console.error('Error deleting user:', error);
          // 可以选择在这里显示一个错误消息给用户
        }
      },
    });
  };

  const getMenu = (record) => (
    <Menu>
      <Menu.Item key="edit" onClick={() => showEditModal(record)}>
        Edit
      </Menu.Item>
      <Menu.Item key="delete" onClick={() => handleDelete(record)}>
        Delete
      </Menu.Item>
    </Menu>
  );

  // 渲染编辑模态框
  const renderEditModal = () => (
    <EditCreateUserModal
      title="Edit"
      isEditing={isEditing}
      setIsEditing={setIsEditing}
      form={form}
      handleFinish={handleFinish}
      editingRecord={editingRecord}
    />
  );

  const pagination = {
    current: currentPage,
    pageSize: pageSize,
    total: total,
    showSizeChanger: false,
  };

  useEffect(() => {
    console.log(userList);
  }, [userList]);

  return (
    <div>
      {loading ? (
        <Spin tip="Loading..." />
      ) : (
        <Table
          columns={columns}
          dataSource={userList}
          pagination={pagination}
          onChange={handlePageChange}
        />
      )}

      {renderEditModal()}
    </div>
  );
};

export default UserManagementTable;