// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.p_B_N6dNJOxE3i_drQXM{display:inline-block;height:24px;margin-right:3px;border-radius:50%;margin-right:3px}.p_B_N6dNJOxE3i_drQXM:last-child{margin-right:0}.p_B_N6dNJOxE3i_drQXM img{height:100%}`, "",{"version":3,"sources":["webpack://./src/modules/package-ai-screening/components/aiIcon2.module.scss"],"names":[],"mappings":"AAAA,sBACE,oBAAA,CACA,WAAA,CACA,gBAAA,CACA,iBAAA,CACA,gBAAA,CACA,iCACE,cAAA,CAGF,0BACE,WAAA","sourcesContent":[".aiCycle {\n  display: inline-block;\n  height: 24px;\n  margin-right: 3px;\n  border-radius: 50%;\n  margin-right: 3px;\n  &:last-child {\n    margin-right: 0;\n  }\n\n  img {\n    height: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"aiCycle": `p_B_N6dNJOxE3i_drQXM`
};
export default ___CSS_LOADER_EXPORT___;
