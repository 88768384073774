import React, { useRef, useState } from 'react';
import './detail-title.scss';
import {arrowRightGreen2} from '../../../../components/icons/icons.jsx';


function DetailTitle(e) {
    return(
        <div className='titleBox'>
          <img className='titleArrow' src={arrowRightGreen2}/>
          <div className='titleText'>{e.title}</div>
        </div>
    )
}


export default DetailTitle;