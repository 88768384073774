import React from 'react';
import KnorrBtn from './btn.jsx';
import style from './drop.module.scss';

function KnorrDrop({
  children,
  icon,
  className,
  item,
  dropVisible,
  setDropVisible,
}) {
  return (
    <div className={`${style.dropBox} ${className}`}>
      <div
        className={style.dropContent}
        style={{ display: dropVisible ? '' : 'none' }}
      >
        {item}
      </div>
      <KnorrBtn
        className={style.dropBtn}
        type={dropVisible ? 'primary' : 'default'}
        onClick={() => setDropVisible(!dropVisible)}
        icon={icon}
      >
        {children}
      </KnorrBtn>
    </div>
  );
}

export default KnorrDrop;
