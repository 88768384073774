import React, { useEffect, useRef } from 'react';
import style from './index.module.scss';

function InnoflexGptView() {
  const aRef = useRef();

  useEffect(() => {
    setTimeout(() => {
      if (aRef) aRef.current.click();
    }, 100);
  }, []);

  return (
    <div>
      <a
        ref={aRef}
        href="https://bnlwe-da04-d-930339-webapi-01.azurewebsites.net/"
      >
        <h1 className={style.title}>Loading....</h1>
      </a>
    </div>
  );
}

export default InnoflexGptView;
