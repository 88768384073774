// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BKgoBtg5mAxVJxRuh7KH{display:inline-block;padding:5px 15px;border-radius:9999px;border:solid 1px}.BKgoBtg5mAxVJxRuh7KH.XbDI1tWch9XGV2rc_wcn{color:#fff;background-color:#f55d5d;border-color:#f55d5d}.BKgoBtg5mAxVJxRuh7KH.rPMKpdiu8akDw8schsDg{color:#000;background-color:#ffbb38;border-color:#ffbb38}.BKgoBtg5mAxVJxRuh7KH.m_fRIjdK0Hpb_l0WXL2u{color:#fff;background-color:#00a32e;border-color:#00a32e}.BKgoBtg5mAxVJxRuh7KH.T45xmnT9l9vWYD8ALY54{color:#000;background-color:#fff;border-color:#fff}.BP3CIduOV__p8eAQgpe0{font-size:14px;font-weight:400;line-height:19.52px}`, "",{"version":3,"sources":["webpack://./src/modules/package-ai-screening-batch/components/colorCoding.module.scss"],"names":[],"mappings":"AAAA,sBACE,oBAAA,CACA,gBAAA,CACA,oBAAA,CACA,gBAAA,CAEA,2CACE,UAAA,CACA,wBAAA,CACA,oBAAA,CAGF,2CACE,UAAA,CACA,wBAAA,CACA,oBAAA,CAGF,2CACE,UAAA,CACA,wBAAA,CACA,oBAAA,CAGF,2CACE,UAAA,CACA,qBAAA,CACA,iBAAA,CAIJ,sBACE,cAAA,CACA,eAAA,CACA,mBAAA","sourcesContent":[".colorCodingContainer {\n  display: inline-block;\n  padding: 5px 15px;\n  border-radius: 9999px;\n  border: solid 1px;\n\n  &.colorCodingRed {\n    color: #fff;\n    background-color: #f55d5d;\n    border-color: #f55d5d;\n  }\n\n  &.colorCodingAmber {\n    color: #000;\n    background-color: #ffbb38;\n    border-color: #ffbb38;\n  }\n\n  &.colorCodingGreen {\n    color: #fff;\n    background-color: #00a32e;\n    border-color: #00a32e;\n  }\n\n  &.colorCodingDefault {\n    color: #000;\n    background-color: #fff;\n    border-color: #fff;\n  }\n}\n\n.colorCodingText {\n  font-size: 14px;\n  font-weight: 400;\n  line-height: 19.52px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"colorCodingContainer": `BKgoBtg5mAxVJxRuh7KH`,
	"colorCodingRed": `XbDI1tWch9XGV2rc_wcn`,
	"colorCodingAmber": `rPMKpdiu8akDw8schsDg`,
	"colorCodingGreen": `m_fRIjdK0Hpb_l0WXL2u`,
	"colorCodingDefault": `T45xmnT9l9vWYD8ALY54`,
	"colorCodingText": `BP3CIduOV__p8eAQgpe0`
};
export default ___CSS_LOADER_EXPORT___;
