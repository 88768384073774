// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NiVlD3DqPYwYQHvDpn3Q{position:relative}.Oh5KCwFxybajmz1NrYGQ{height:100%;margin-left:65px;display:flex;align-items:stretch}.cmugqL1IaPx11L976s6w{position:absolute;bottom:0;left:0;z-index:11;width:100%;height:calc(100% - 52px);overflow:auto}.iME1gj39R0VbS7ZtdPiz{flex:1;overflow:hidden;display:flex;flex-direction:column}.JlV2_CX7nItnoLdS3VbX{flex:1;overflow:hidden;transition:background-color .3s}.JlV2_CX7nItnoLdS3VbX.yhiVpwNGtV27oAU7TiOP{background-color:rgba(2,41,12,.6)}`, "",{"version":3,"sources":["webpack://./src/modules/package-ai-performance/views/competitive/table/index.module.scss"],"names":[],"mappings":"AAAA,sBACE,iBAAA,CAGF,sBACE,WAAA,CACA,gBAAA,CACA,YAAA,CACA,mBAAA,CAGF,sBACE,iBAAA,CACA,QAAA,CACA,MAAA,CACA,UAAA,CACA,UAAA,CACA,wBAAA,CACA,aAAA,CAGF,sBACE,MAAA,CACA,eAAA,CACA,YAAA,CACA,qBAAA,CAGF,sBACE,MAAA,CACA,eAAA,CACA,+BAAA,CACA,2CACE,iCAAA","sourcesContent":[".box13 {\n  position: relative;\n}\n\n.box131 {\n  height: 100%;\n  margin-left: 65px;\n  display: flex;\n  align-items: stretch;\n}\n\n.box132 {\n  position: absolute;\n  bottom: 0;\n  left: 0;\n  z-index: 11;\n  width: 100%;\n  height: calc(100% - 52px);\n  overflow: auto;\n}\n\n.item {\n  flex: 1;\n  overflow: hidden;\n  display: flex;\n  flex-direction: column;\n}\n\n.itemBox1 {\n  flex: 1;\n  overflow: hidden;\n  transition: background-color 0.3s;\n  &.itemBox1Active {\n    background-color: rgba(2, 41, 12, 0.6);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"box13": `NiVlD3DqPYwYQHvDpn3Q`,
	"box131": `Oh5KCwFxybajmz1NrYGQ`,
	"box132": `cmugqL1IaPx11L976s6w`,
	"item": `iME1gj39R0VbS7ZtdPiz`,
	"itemBox1": `JlV2_CX7nItnoLdS3VbX`,
	"itemBox1Active": `yhiVpwNGtV27oAU7TiOP`
};
export default ___CSS_LOADER_EXPORT___;
