import React, { useCallback, useEffect, useState } from 'react';
import style from './main.module.scss';
import classNames from 'classnames';
import useChatCore from '../../hooks/useChatCore';
import { useRoot } from '../../hooks/useRoot.jsx';
import { getRecommend } from '../../api/request.js';
import { useAskGpt } from '../../hooks/useAskGPT.jsx';

const InitQuestionBox = () => {
  const { onSend } = useChatCore();
  const { range, setRange } = useRoot();
  const { filterByCT } = useAskGpt();
  const [ allQuestion, setAllQuestion ] = useState({});
  const [ curQuestion, setCurQuestion ] = useState([]);

  const fnGetRecommend = useCallback(async () => {
    const result = await getRecommend();
    if(result?.code === 200) {
      setAllQuestion(result.data);
    }
  }, []);

  useEffect(() => {
    fnGetRecommend();
  }, [fnGetRecommend]);

  useEffect(() => {
    let tempList = [];
    const allQuestionVal = Object.values(allQuestion);
    allQuestionVal.forEach(item => {
      tempList.push(item[0]);
    });
    if(range !== 'full') {
      let { category = '' } = filterByCT;
      if(category) {
        category = category.trim();
        tempList = allQuestion[category] || [];
      }
    }

    setCurQuestion(tempList);
  }, [range, allQuestion, filterByCT]);

  return (
    <div className={style.initQuestionBox}>
      <div className={style.btnBox}>
        <button onClick={() => setRange('full')} className={classNames(style.btnBorder, range === 'full' ? style.active : '')}>
          Ask full system
        </button>
        <div style={{width: '20px'}}></div>
        <button onClick={() => setRange('current')} className={classNames(style.btnBorder, range === 'current' ? style.active : '')}>
          Ask Current page
        </button>
      </div>

      <div className={classNames(style.tipBox)}>
        <div className={classNames(style.tipTitle)}>Tips:</div>
        <p>· Always reference a specific target group. e.g. “Among Gen Z respondents” or “Among respondents in the United States”</p>
        <p>· Break down compound questions into multiple prompts</p>
        <p>· If the response is not satisfactory, rephrase the prompt</p>
        <p>· Remove abbreviations from your prompting</p>
      </div>

      <div className={classNames(style.questionList)}>
        {
          curQuestion.map((item, index) => <div key={index} className={classNames(style.questionItem)} onClick={() => onSend(item)}>{item}</div>)
        }
      </div>
    </div>
  );
};

export default InitQuestionBox;