// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LWppJEHbp5WoTTkmLDuA{height:100%;display:flex;flex-direction:column}.Bw1pVrv2VaOTTJGqF98g{flex:1;overflow:hidden}`, "",{"version":3,"sources":["webpack://./src/modules/package-ai-screening/views/share/index.module.scss"],"names":[],"mappings":"AAAA,sBACE,WAAA,CACA,YAAA,CACA,qBAAA,CAGF,sBACE,MAAA,CACA,eAAA","sourcesContent":[".containerInner {\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n}\n\n.box {\n  flex: 1;\n  overflow: hidden;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"containerInner": `LWppJEHbp5WoTTkmLDuA`,
	"box": `Bw1pVrv2VaOTTJGqF98g`
};
export default ___CSS_LOADER_EXPORT___;
