import { ConfigProvider, Layout, theme } from 'antd';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Logo from '../assets/img/logo.png';
import KnorrDetail from '../components/detail-id.jsx';
import KnorrListAll from '../components/list-all.jsx';
import KnorrListMine from '../components/list-mine.jsx';
import KnorrTabs from '../components/tabs.jsx';
import KnorrUpload from '../components/upload.jsx';
import style from './views.module.scss';

const { Content, Header, Footer } = Layout;

function KnorrList() {
  const [uploadTime, setUploadTime] = useState(0);
  const [scrollCount, setScrollCount] = useState(0);
  const [detailVisible, setDetailVisible] = useState(false);
  const { id } = useParams();
  useEffect(() => {
    if (id) setDetailVisible(true);
  }, [id]);

  /**
   * @param {React.UIEvent<HTMLDivElement, UIEvent>} ev
   */
  function onScroll(ev) {
    const isBottom =
      ev.target.scrollHeight - ev.target.scrollTop <=
      ev.target.clientHeight + 110;
    if (isBottom) setScrollCount(scrollCount + 1);
  }

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: '#00a32e',
          colorError: '#eb0000',
          colorSuccess: '#06ca3d',
          fontFamily: 'unilever-shilling-regular',
          colorTextPlaceholder: '#11621A',
        },
        algorithm: theme.defaultAlgorithm,
        components: {
          Form: {
            labelColor: '#fff',
          },
          Button: {
            defaultActiveBg: '#eef4f0',
            defaultBg: '#eef4f0',
            defaultHoverBg: '#eef4f0',
            defaultBorderColor: '#eef4f0',
            defaultColor: '#00a32e',
            primaryColor: '#fff',
          },
        },
      }}
    >
      <div className={style.listContainer} onScroll={onScroll}>
        <Layout style={{ background: 'none' }}>
          <Header className={style.listHeader}>
            <img className={style.listLogo} src={Logo} alt="knorr" />
            <KnorrUpload
              className={style.listUpload}
              onUpload={() => setUploadTime(Date.now())}
            ></KnorrUpload>
          </Header>
          <Content className={style.listContent}>
            <KnorrTabs
              items={[
                {
                  label: 'All Learning',
                  key: 'all',
                  children: KnorrListAll({ uploadTime, scrollCount }),
                },
                {
                  label: 'My Learning',
                  key: 'my',
                  children: KnorrListMine({ uploadTime, scrollCount }),
                },
              ]}
            ></KnorrTabs>
          </Content>
          <Footer className={style.listFooter}>
            <p className={style.listDeclar}>
              * Due to privacy legislation all insights & photos / videos can
              only be used internally, and only after verbal consent from the
              audience. Ideally, no personal data is obtained and any personal
              data obtained anonymized.*
            </p>
          </Footer>
        </Layout>
      </div>
      {detailVisible && (
        <KnorrDetail
          id={id}
          onClose={() => setDetailVisible(false)}
        ></KnorrDetail>
      )}
    </ConfigProvider>
  );
}

export default KnorrList;
