export const loadUser = () => {
  try {
    const serializedState = sessionStorage.getItem('user');

    if (serializedState === null) {
      return undefined;
    }

    return JSON.parse(serializedState);
  } catch (error) {
    return undefined;
  }
};

export const saveUser = (user) => {
  try {
    const serializedState = JSON.stringify(user);
    sessionStorage.setItem('user', serializedState);
  } catch (error) {
    // Ignore write errors.
    return undefined;
  }
};
