import React, { useCallback, useEffect, useState } from 'react';
import classNames from 'classnames';
import style from './index.module.scss';

import { ExclamationCircleFilled } from '@ant-design/icons';
import searchSvg from '../../../../assets/images/gotfl-weena/search-icon.svg';
import switchSvg from '../../../../assets/images/gotfl-weena/switch-type.svg';
import upload2Svg from '../../../../assets/images/gotfl-weena/upload-2.svg';
import deleteSvg from '../../../../assets/images/gotfl-weena/icn_delete.svg';
import editSvg from '../../../../assets/images/gotfl-weena/icn_edit.svg';

import IntroPopup from '../../components/intro-popup/intro-popup.jsx';
import DetailPopup from '../../components/detail-popup/detail-popup.jsx';
import { Modal, Pagination } from 'antd';
import { useNavigate, useSearchParams } from 'react-router-dom';
import useBasic from '../../hooks/useBasic.js';
import IntroUpdatePopup from '../../components/intro-update-popup/index.jsx';
import { debounce } from '../../../concept-ai/utils/utils.js';
import { useRoot } from '../../hooks/useRoot.jsx';
import { deleteWorkshop } from '../../api/request.js';

const { confirm } = Modal;

const HomePage = () => {
  const { fnOpenEditPopup, fnOpenEditPopupForModify, isGotflWeenaAdmin } = useRoot();
  const { paging, workshopList, fnGetWrokShopList, updateSearch } = useBasic();
  const navigate = useNavigate();
  let [searchParams] = useSearchParams();
  const [isCard, setIsCard] = useState(true);
  const [openIntroWin, setOpenIntroWin] = useState(false);
  const [openMore, setOpenMore] = useState(false);

  const [curWorkshopId, setCurWorkshopId] = useState(-1);

  const fnOpenIntroWin = (id) => {
    setCurWorkshopId(id);
    setOpenIntroWin(true);
    // if(id == 3) {
    //   window.open('http://47.76.161.195:8501/', '_blank');
    // } else {
      
    // }
  };
  const fnCloseIntroWin = () => {
    setOpenIntroWin(false);
  };

  const fnOpenMore = () => {
    fnCloseIntroWin();
    setOpenMore(true);
  };

  const fnCloseMore = () => {
    setOpenMore(false);
  };

  // Processing url
  useEffect(() => {
    if(openIntroWin) {
      navigate(`?type=intro&curId=${curWorkshopId}`);
    } else {
      if(openMore) {
        navigate(`?type=detail&curId=${curWorkshopId}`);
      } else {
        navigate('');
      }
    }
  }, [curWorkshopId, navigate, openIntroWin, openMore]);

  // resolve url
  const [isFirst, setIsFirst] = useState(false);
  useEffect(() => {
    if(!isFirst) {
      setIsFirst(true);
      let type = searchParams.get('type');
      let curId = searchParams.get('curId');
      if(curId && type) {
        setCurWorkshopId(curId);
        if(type === 'intro') {
          setOpenIntroWin(true);
        } else if(type === 'detail') {
          setOpenMore(true);
        }
      }
    }
  }, [searchParams, isFirst]);

  useEffect(() => {
    fnGetWrokShopList();
  }, [fnGetWrokShopList]);

  const fnInputChange = useCallback(debounce(function(e) { 
    updateSearch(e.target.value);
  }, 500), [updateSearch]);

  const fnEditWorkshop = (e, id) => {
    e.stopPropagation();
    try {
      fnOpenEditPopupForModify(id, 'intro');
    } catch(error) {
      console.log(error);
    }
  };

  const fnDelWorkshop = (e, id) => {
    e.stopPropagation();
    confirm({
      title: 'Do you Want to delete these items?',
      icon: <ExclamationCircleFilled />,
      content: '',
      onOk: async () => {
        try {
          await deleteWorkshop(id);
          fnGetWrokShopList(1);
        } catch(error) {
          console.log(error);
        }
      },
      onCancel() {
      },
    });
  };

  return (
    <div style={{height: '100%'}}>
      {
        isGotflWeenaAdmin && <div className={classNames(style.uploadBtn)} onClick={() => fnOpenEditPopup(true, 'intro')}>
          <img src={upload2Svg} alt="" className={classNames(style.iconImg)}/>
          <span className={classNames(style.label)}>Upload</span>
        </div>
      }
      <>
        <div className={classNames(style.searchWrap)}>
          <div className={classNames(style.searchBox)}>
            <img src={searchSvg} />
            <input placeholder='Enter the theme of the workshop' onChange={fnInputChange}></input>
          </div>
        </div>

        <div className={classNames(style.mainBox)}>
          <div className={classNames(style.dataListWrap, isCard ? style.switchType : '')}>
            {
              isCard && workshopList.map((item, index) => <div key={index} className={classNames(style.card)} onClick={() => fnOpenIntroWin(item.id)}>
                <img src={item.thumbUrl} className={classNames(style.imgBg)}></img>
                <div className={classNames(style.maskWrap)}>
                  {
                    isGotflWeenaAdmin && <div className={classNames(style.ctrlBox)}>
                      <img src={editSvg} onClick={(e) => fnEditWorkshop(e, item.id)} />
                      <img src={deleteSvg} onClick={(e) => fnDelWorkshop(e, item.id)}/>
                    </div>
                  }
                  <div className={classNames(style.infoWrap)}>
                    <div className={classNames(style.title)}>{item.name}</div>
                    <span className={classNames(style.label)}>{item.when}</span>
                  </div>
                </div>
              </div>)
            }
            {
              !isCard && workshopList.map((item, index) => <div key={index} className={classNames(style.listCard)} onClick={() => fnOpenIntroWin(item.id)}>
                <div className={classNames(style.leftBox)}>
                  <img src={item.thumbUrl} className={classNames(style.imgBg)}></img>
                  <div className={classNames(style.infoWrap)}>
                    <div className={classNames(style.title)}>{item.name}</div>
                    <span className={classNames(style.label)}>{item.when}</span>
                  </div>
                </div>
                {
                  isGotflWeenaAdmin && <div className={classNames(style.ctrlBox)}>
                    <img src={editSvg} onClick={(e) => fnEditWorkshop(e, item.id)} />
                    <img src={deleteSvg} onClick={(e) => fnDelWorkshop(e, item.id)}/>
                  </div>
                }
              </div>)
            }
          </div>
          <div className={classNames(style.ctrlWrap)}>
            <img src={switchSvg} style={{cursor: 'pointer'}} onClick={() => setIsCard(!isCard)}></img>
            <div className={classNames(style.paginationBox)}>
              <Pagination defaultCurrent={paging.page} total={paging.total} showSizeChanger={false} onChange={(page) => fnGetWrokShopList(page)}/>
            </div>
          </div>
        </div>
      </>
      <DetailPopup open={openMore} id={curWorkshopId} onClose={fnCloseMore}></DetailPopup>
      <IntroPopup id={curWorkshopId} open={openIntroWin} onClose={fnCloseIntroWin} onMore={fnOpenMore}></IntroPopup>
      <IntroUpdatePopup onRefresh={() => fnGetWrokShopList(1)}></IntroUpdatePopup>
    </div>
  );
};

export default HomePage;