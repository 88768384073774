import React, { useState, useRef, useEffect } from 'react';
import BorderContainer from '../border-container/border-container.jsx';
import style from './solution-trends.module.scss';
import classNames from 'classnames';
import { triangleRight } from '../../../../components/icons/icons.jsx';
import solutionTrendsMapData from '../../data/solution-trends.js';
import LazyImage from '../lazy-image/lazy-image.jsx';
import { LeftOutlined } from '@ant-design/icons';
import { Carousel } from 'antd';
import { dialArrowLeft } from '../../../../components/icons/icons.jsx';
import FutureMenuPdf from '../../../../assets/pdf/Future-Menus-Report-2024.pdf';
import ShareModal from '../../../../components/share-modal/share-modal.jsx';
import { dialShare } from '../../../../components/icons/icons.jsx';

function appendQueryParams(url, params) {
  const urlParts = url.split('#'); 
  const baseParts = urlParts[0].split('?');
  const basePath = baseParts[0];  
  const search = baseParts[1] || '';  
  const hash = urlParts[1] || '';  
  const searchParams = new URLSearchParams(search);  

  for (const key in params) {  
    if (Object.prototype.hasOwnProperty.call(params, key)) {  
      searchParams.set(key, params[key]);  
    }  
  }  

  let newBasePath = basePath;
  if (searchParams.size > 0) {
    newBasePath += '?' + searchParams.toString();
  }
  if (hash) {
    newBasePath += '#' + hash;
  }

  return newBasePath; 
}

const SolutionTrends = ({type, country}) => {
  const currentType = type;
  const [activeTab, setActiveTab] = useState(null);
  const [currentData, setCurrentData] = useState(null);
  const [showCarousel, setShowCarousel] = useState(false);
  const [images, setImages] = useState([]);
  const titleOne = country === 'US' ? 'Category Value Driver' : 'Emerging Food Solution Trends';
  const [showShare, setShowShare] = useState(false);
  const [shareUrl, setShareUrl] = useState('');

  const shareHandle = () => {
    const params = {
      type,
      country
    };
    const paramsStr = JSON.stringify(params);
    const queryToken = window.decodeURIComponent(paramsStr);
    const shareUrl = appendQueryParams(window.location.href, {queryToken});
    setShareUrl(shareUrl);
    setShowShare(true);
  };

  const carouselRef = useRef(null);

  useEffect(() => {
    setActiveTab(null);
    setShowCarousel(false);
    setCurrentData(solutionTrendsMapData(currentType));
  }, [type]);

  function tabClick(tabIndex) {
    setActiveTab(tabIndex);
    setShowCarousel(true);
    let path = '';
    if (tabIndex === 2) {
      path = 'Social Listening Analysis';
    } else if (tabIndex === 4) {
      path = 'Trend Deep Dive';
    } else if (tabIndex === 8) {
      path = 'Food Service Trend Scape';
    }
    const arr = [];
    for(let i = 1; i <= tabIndex; i++) {
      arr.push(`${currentData.imagePath}/${path}/image${i}.png`);
    }
    setImages(arr);
  };

  function back() {
    setActiveTab(null);
    setShowCarousel(false);
  };

  function prev() {
    carouselRef.current.prev();
  }

  function next() {
    carouselRef.current.next();
  }

  if (!currentData) {
    return null;
  }

  const headerChildren = (
    <div className={style.shareBox} onClick={() => shareHandle()}>
      <img src={dialShare} alt="" />
    </div>
  );

  return (
    <div className={style.SolutionTrendsPage}>
      <BorderContainer hideLeftCorner bigTitle headerTitle={titleOne} headerChildren={headerChildren}>
        <div key={currentType} className={style.solutionMainContent}>
          <div className={style.mainMenuBox}>
            <div className={classNames(style.menuItemBrother)}>{currentData.name}</div>
            <div className={classNames(style.menuItem, {
              [style.active]: activeTab === 4
            })} onClick={() => tabClick(4)}>Trends Deep Dive</div>
            <div className={classNames(style.menuItem, {
              [style.active]: activeTab === 8
            })} onClick={() => tabClick(8)}>Food Service Trend Scape</div>
            <div className={classNames(style.menuItem)}>
              <a href={FutureMenuPdf} target="_blank" rel="noreferrer"> Future Menu 2024</a>
            </div>
            <div className={classNames(style.menuItem, {
              [style.active]: activeTab === 2
            })} onClick={() => tabClick(2)}>Social Listening Analysis</div>
          </div>
          <div className={style.lineBox}></div>
          <div className={style.contentBox}>
            {/* 基础信息 */}
            {!showCarousel ? <div className={style.informationBox}>
              <div className={style.titleBox}>
                <div className={style.arrow}>
                  <img src={triangleRight} alt="" />
                </div>
                <div className={style.titleInner}>{ currentData.name }</div>
              </div>
              <div className={style.descBox}>{ currentData.desc }</div>
              <div className={style.imgBox}>
                <LazyImage src={`${currentData.imagePath}/${currentData.img}`} />
              </div>
            </div> : <div className={style.carouselBox}>
              <div className={style.backBtn} onClick={() => back()}>
                <LeftOutlined /> Back
              </div>
              <div className={style.carouselMain}>
                <div className={style.carouselPre}>
                  <img src={dialArrowLeft} alt="" onClick={() => prev()} />
                </div>
                <div className={style.carouselContent}>
                  <Carousel ref={carouselRef} waitForAnimate key={`${currentType}-${activeTab}`}>
                    {(images.map((item) => {
                      return (
                        <div className={style.carouselItem} key={currentType + item}>
                          <LazyImage src={item} key={currentType + item} />
                        </div>
                      );
                    }))}
                  </Carousel>
                </div>
                <div className={style.carouselNext}>
                  <img src={dialArrowLeft} alt="" onClick={() => next()} />
                </div>
              </div>
            </div>}
          </div>
        </div>
      </BorderContainer>
      {showShare ? <ShareModal cardURL={shareUrl} onClose={() => setShowShare(false)} /> : null}
    </div>
  );
};

export default SolutionTrends;