import React, { useMemo } from 'react';
import style from './aiIcon.module.scss';

function PackageAiAiIcon({ value }) {
  const cls = useMemo(() => {
    if (value === 0) {
      return style.aiIconRed;
    } else if (value === 1) {
      return style.aiIconGreen;
    } else if (value === 2) {
      return style.aiIconGreen;
    } else {
      return style.aiIconAmber;
    }
  });

  if (value === 2) {
    return (
      <>
        <div className={`${style.aiCycle} ${cls}`} />
        <div className={`${style.aiCycle} ${cls}`} />
      </>
    );
  } else {
    return <div className={`${style.aiCycle} ${cls}`} />;
  }
}

export default PackageAiAiIcon;
