// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AL0Jqgbwhj29vchH5OQY{height:2px;background:linear-gradient(90deg, #030f07 0%, #00a32e 50.52%, #030c07 100%)}`, "",{"version":3,"sources":["webpack://./src/modules/package-ai-screening/views/result/line.module.scss"],"names":[],"mappings":"AAAA,sBACE,UAAA,CACA,2EAAA","sourcesContent":[".line {\n  height: 2px;\n  background: linear-gradient(90deg, #030f07 0%, #00a32e 50.52%, #030c07 100%);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"line": `AL0Jqgbwhj29vchH5OQY`
};
export default ___CSS_LOADER_EXPORT___;
