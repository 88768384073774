import React, { useState, useImperativeHandle, forwardRef } from 'react';
import { Modal } from 'antd';
import BorderContainer from '../../../trends-dial/components/border-container/border-container.jsx';
import style from '../video-player/video-player.module.scss';
import { cancelGreenIcon } from '../../../../components/icons/icons.jsx';
import { LeftOutlined } from '@ant-design/icons';

let VideoPlayer = (_, ref) => {
  const [videoData, setVideoData] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  useImperativeHandle(ref, () => ({
    open: (data) => {
      setVideoData(data);
      setIsModalOpen(true);
    },
    close: () => {
      setIsModalOpen(false);
    },
  }));

  const getEmbedUrl = (url) => {
    if (url.includes('facebook.com')) {
      return `https://www.facebook.com/plugins/video.php?href=${url}`;
    } else if (url.includes('instagram.com')) {
      return url.includes('/embed') ? url : `${url}embed/`;
    }
    return url;
  };

  const viewRender = () => {
    if (videoData?.videoUri) {
      const embedUrl = getEmbedUrl(videoData.videoUri);
      return (
        <iframe
          className={style.iframe}
          src={embedUrl}
          title="Video Player"
          allowFullScreen
          scrolling="no"
          allow="encrypted-media"
        ></iframe>
      );
    }
    return 'No Data';
  };

  return (
    <Modal
      title="" 
      wrapClassName={style.myDialog}
      width={1100} 
      centered 
      destroyOnClose
      footer={null} 
      open={isModalOpen}
      onCancel={handleCancel}
      closeIcon={(
        <div className={style.closeBtn}>
          <img src={cancelGreenIcon} alt="Close" />
        </div>
      )}
    >
      <div className={style.dialogBox}>
        <div className={style.backBtn} onClick={() => setIsModalOpen(false)}>
          <LeftOutlined /> 
        </div>
        <BorderContainer>
          <div className={style.dialogMain}>
            <div className={style.videoBox}>
              {viewRender()}
            </div>
            <div className={style.videoTextContainer}>{videoData?.videoTitle}</div>
          </div>
        </BorderContainer>
      </div>
    </Modal>
  );
};

VideoPlayer = forwardRef(VideoPlayer);

export default VideoPlayer;
