import React from 'react';
import LeftSidebarCollapsible from '../../../components/left-navigation/left-navigation.jsx';
import learningBgVideo from '../../../assets/videos/GOTFLbackgroundVideo.mp4';
import MarketTrendHorizontalNav from '../components/market-trend-horizontal-nav/market-trend-horizontal-nav.jsx';
import TrendsInnovation from '../components/competitor-innovation/trends-innovation.jsx';
import ConsumerFilters from '../components/competitor-innovation/consumer-trends/consumer-filter/consumer-filter.jsx';
import Profile from '../../../components/profile/profile.jsx';
import { useLocation } from 'react-router-dom';
import { FiltersProvider } from '../hooks/useFilters.jsx';

function ConsumerTrendsInnovation() {
  const location = useLocation();
  const selectedItemName = location.state.selectedItemName;
  const consumerTrendsCategory = location.state.consumerTrendsCategory;
  const selectedCountry = location.state.selectedCountry;
  return (
    <>
      <LeftSidebarCollapsible />
      <div className="sidebar-right-layout">
        <video autoPlay muted className="learning-bg-video" loop>
          <source src={learningBgVideo} type="video/mp4" />
        </video>
        <div className="my-learning-container">
          <div className="horizontal-user-profile">
            <Profile />
          </div>
          <MarketTrendHorizontalNav
            consumerTrendsCategory={consumerTrendsCategory}
            selectedItemName={selectedItemName}
            selectedCountry={selectedCountry}
          />
          <FiltersProvider>
            <ConsumerFilters
              consumerTrendsCategory={selectedItemName}
              selectedCountry={selectedCountry}
            />
            <TrendsInnovation
              consumerTrendsCategory={selectedItemName}
              selectedCountry={selectedCountry}
            />
          </FiltersProvider>
        </div>
      </div>
    </>
  );
}

export default ConsumerTrendsInnovation;
