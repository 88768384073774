import React from 'react';
import learningBgVideo from '../../../../assets/videos/plant-based-bg.mp4';
import LeftFilterNavigateCollapsible from '../../components/left-filter-navigate/left-filter-navigate.jsx';
import PlantHeader from '../../components/header/header.jsx';
import { useBase } from '../../hooks/useBase.jsx';
import style from './index.module.scss';

const BaseLayout = ({children}) => {
  const { openWin } = useBase();
  return <>
    {/*<LeftFilterNavigateCollapsible />*/}
    <div className={style.painLayout} >
      <PlantHeader></PlantHeader>
      <div>
        {children}
      </div>
    </div>
  </>;
};

export default BaseLayout;