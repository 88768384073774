import style from './btn.module.scss';
import React from 'react';

export function PackageAiBtn1({
  className = '',
  children,
  disabled = false,
  onClick,
}) {
  function click(ev) {
    if (disabled) return;
    onClick && onClick(ev);
  }

  return (
    <div
      className={`${style.btn1} ${
        disabled ? style.btnDisabled : ''
      } ${className}`}
      onClick={click}
    >
      <div className={style.btn1Text}>{children}</div>
    </div>
  );
}

export function PackageAiBtn2({
  className = '',
  children,
  disabled = false,
  onClick,
  suffix,
}) {
  function click(ev) {
    if (disabled) return;
    onClick && onClick(ev);
  }

  return (
    <div
      className={`${style.btn2} ${
        disabled ? style.btnDisabled : ''
      } ${className}`}
      onClick={click}
    >
      <div className={style.btn2Text}>{children}</div>
      {suffix && <div className={style.btnSuffix}>{suffix}</div>}
    </div>
  );
}

export function PackageAiBtn3({
  className = '',
  children,
  disabled = false,
  onClick,
}) {
  function click(ev) {
    if (disabled) return;
    onClick && onClick(ev);
  }

  return (
    <div
      className={`${style.btn3} ${
        disabled ? style.btnDisabled : ''
      } ${className}`}
      onClick={click}
    >
      <div className={style.btn3Text}>{children}</div>
    </div>
  );
}

export function PackageAiBtn4({
  className = '',
  children,
  disabled = false,
  onClick,
}) {
  function click(ev) {
    if (disabled) return;
    onClick && onClick(ev);
  }

  return (
    <div
      className={`${style.btn4} ${
        disabled ? style.btnDisabled : ''
      } ${className}`}
      onClick={click}
    >
      <div className={style.btn4Text}>{children}</div>
    </div>
  );
}

export function PackageAiBtn5({
  className = '',
  children,
  disabled = false,
  onClick,
  prefix,
}) {
  function click(ev) {
    if (disabled) return;
    onClick && onClick(ev);
  }

  return (
    <div
      className={`${style.btn5} ${
        disabled ? style.btnDisabled : ''
      } ${className}`}
      onClick={click}
    >
      {prefix && <div className={style.btnPrefix}>{prefix}</div>}
      <div className={style.btn5Text}>{children}</div>
    </div>
  );
}
