import React, { useState } from 'react';
import {
  OccasionCompareBtn,
  OccasionFilterGroups,
  OccasionFilterHeader,
  OccasionFilters,
} from '../../../components/filter.jsx';
import style from './filter.module.scss';

export function OccasionFilterSide({
  otherFilters,
  groups,
  groupedFilters,
  setFilterData,
  setCompareData,
  hasCompare,
  setHasCompare,
  startGroup,
  startCategory,
  onClearFilter,
}) {
  const [fd, setFd] = useState(
    startCategory ? { booster_project: startCategory } : {}
  );
  const [cd, setCd] = useState({});

  function onFilterSubmit() {
    setFilterData({ ...fd });
  }
  function onFilterClear() {
    setFd({});
    onFilterSubmit();
    onClearFilter && onClearFilter();
  }
  function onCompareSubmit() {
    setCompareData({ ...cd });
  }
  function onCompareClear() {
    setCd({});
    onCompareSubmit();
  }

  return (
    <>
      <div className={style.filterContainer}>
        <OccasionFilterHeader onSubmit={onFilterSubmit} onClear={onFilterClear}>
          Filter
        </OccasionFilterHeader>
        <div className={style.filterContent}>
          <OccasionFilters filters={otherFilters} data={fd} setData={setFd} />
          <OccasionFilterGroups
            groups={groups}
            filters={groupedFilters}
            data={fd}
            setData={setFd}
            startGroup={startGroup}
          />
        </div>
      </div>
      <div
        className={style.compareContainer}
        style={{
          display: `${hasCompare ? 'flex' : 'none'}`,
        }}
      >
        <OccasionFilterHeader
          onSubmit={onCompareSubmit}
          onClear={onCompareClear}
        >
          Compare
        </OccasionFilterHeader>
        <div className={style.filterContent}>
          <OccasionFilters filters={otherFilters} data={cd} setData={setCd} />
          <OccasionFilterGroups
            groups={groups}
            filters={groupedFilters}
            data={cd}
            setData={setCd}
          />
        </div>
      </div>
      <OccasionCompareBtn display={hasCompare} setDisplay={setHasCompare} />
    </>
  );
}
