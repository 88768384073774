import React from 'react';
import style from './main.module.scss';
import { useRoot } from '../../hooks/useRoot.jsx';
import useChatCore from '../../hooks/useChatCore';

const SendRetryBox = () => {
  const { setLoading } = useRoot();
  const { callAiAsk } = useChatCore();

  const fnRetryAsk = () => {
    setLoading(true);
    callAiAsk();
  };
  return (
    <div className={style.sendRetryBox}>
      <span className={style.warnMsg}>The AI service disconnected. Try again.</span>
      <button className={style.retryBtn} onClick={fnRetryAsk}>Retry</button>
    </div>
  );
};

export default SendRetryBox;