// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TK9Azfr_yXusp40sVpSx{position:fixed;height:100%;width:260px;background:#012702;border-radius:0 15px 15px 0;padding:30px 15px 30px 10px}.TK9Azfr_yXusp40sVpSx .Aoc5swrhjidndAg3sUSa{margin-left:15px}.TK9Azfr_yXusp40sVpSx .gFcKHcYvUMuJ3OlTsfrg{margin-left:30px}.TK9Azfr_yXusp40sVpSx .z2U4LqQz7hgzJY4XFJeZ{font-size:17px}.TK9Azfr_yXusp40sVpSx .Q5jD1sWSbkqRpX6wo6st{display:flex;justify-content:space-between}.TK9Azfr_yXusp40sVpSx .oxat4_QAfX6v65Yx0Tbd{height:2px;margin:6px 18px 4px 18px;background:#10450d}.TK9Azfr_yXusp40sVpSx .Gir___QtBujUUO7qIPTB{font-size:16px;margin:10px 25px}`, "",{"version":3,"sources":["webpack://./src/modules/pain-points/components/left-filter-navigate/common.module.scss"],"names":[],"mappings":"AAAA,sBACE,cAAA,CACA,WAAA,CACA,WAAA,CACA,kBAAA,CACA,2BAAA,CACA,2BAAA,CAEA,4CACE,gBAAA,CAGF,4CACE,gBAAA,CAGF,4CACE,cAAA,CAGF,4CACE,YAAA,CACA,6BAAA,CAGF,4CACE,UAAA,CACA,wBAAA,CACA,kBAAA,CAGF,4CACE,cAAA,CACA,gBAAA","sourcesContent":[".filterBox {\n  position: fixed;\n  height: 100%;\n  width: 260px;\n  background: #012702;\n  border-radius: 0 15px 15px 0;\n  padding: 30px 15px 30px 10px;\n\n  .left15 {\n    margin-left: 15px;\n  }\n\n  .left30 {\n    margin-left: 30px;\n  }\n\n  .textTitle {\n    font-size: 17px;\n  }\n\n  .flexBetween {\n    display: flex;\n    justify-content: space-between;\n  }\n\n  .line {\n    height: 2px;\n    margin: 6px 18px 4px 18px;\n    background: #10450d;\n  }\n\n  .selectBox {\n    font-size: 16px;\n    margin: 10px 25px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"filterBox": `TK9Azfr_yXusp40sVpSx`,
	"left15": `Aoc5swrhjidndAg3sUSa`,
	"left30": `gFcKHcYvUMuJ3OlTsfrg`,
	"textTitle": `z2U4LqQz7hgzJY4XFJeZ`,
	"flexBetween": `Q5jD1sWSbkqRpX6wo6st`,
	"line": `oxat4_QAfX6v65Yx0Tbd`,
	"selectBox": `Gir___QtBujUUO7qIPTB`
};
export default ___CSS_LOADER_EXPORT___;
