import React from 'react';
import learningBgVideo from '../../../../assets/videos/gotfl-weena-bg.mp4';
import LeftSidebarCollapsible from '../../../../components/left-navigation/left-navigation.jsx';
import PlantHeader from './components/header.jsx';

const BaseLayout = ({children}) => {
  return <>
    <LeftSidebarCollapsible />
    <div className="sidebar-right-layout" style={{overflow: 'hidden'}}>
      <video autoPlay muted className="learning-bg-video" loop>
        <source src={learningBgVideo} type="video/mp4" />
      </video>
      <PlantHeader></PlantHeader>
      <div className="my-learning-container" style={{height: 'calc(100% - 130px)'}}>
        {children}
      </div>
    </div>
  </>;
};

export default BaseLayout;