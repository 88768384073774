// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PzCqCdhnAQtQlX_WofBO{position:relative}.sOANMwUKSEsOZ4bWUwv_{position:absolute;top:50%;right:0;z-index:11;padding:20px 26px;border-top-left-radius:12px;border-bottom-right-radius:12px;border-bottom-left-radius:12px;background-color:#008626}.CCqmG75Ce9hBNuvKU7fR{position:relative;z-index:12}`, "",{"version":3,"sources":["webpack://./src/modules/knorr/components/drop.module.scss"],"names":[],"mappings":"AAAA,sBACE,iBAAA,CAGF,sBACE,iBAAA,CACA,OAAA,CACA,OAAA,CACA,UAAA,CACA,iBAAA,CACA,2BAAA,CACA,+BAAA,CACA,8BAAA,CACA,wBAAA,CAGF,sBACE,iBAAA,CACA,UAAA","sourcesContent":[".dropBox {\n  position: relative;\n}\n\n.dropContent {\n  position: absolute;\n  top: 50%;\n  right: 0;\n  z-index: 11;\n  padding: 20px 26px;\n  border-top-left-radius: 12px;\n  border-bottom-right-radius: 12px;\n  border-bottom-left-radius: 12px;\n  background-color: #008626;\n}\n\n.dropBtn {\n  position: relative;\n  z-index: 12;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dropBox": `PzCqCdhnAQtQlX_WofBO`,
	"dropContent": `sOANMwUKSEsOZ4bWUwv_`,
	"dropBtn": `CCqmG75Ce9hBNuvKU7fR`
};
export default ___CSS_LOADER_EXPORT___;
