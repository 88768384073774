import React from 'react';
import { ConfigProvider, Input, Select } from 'antd';


const App = ({ title, options }) => {
  const onChange = (key) => {
    console.log(key);
  };

  return <>
    <div style={{ marginBottom: '10px' }}>{title}</div>
    {/*<Select style={{ marginBottom: '10px', background: '#10450d', color: '#FFF', border: 'none' }}*/}
    {/*        options={[{ value: 'sample', label: <span>sample</span> }]} />;*/}


    <ConfigProvider
      theme={{
        components: {
          Select: {
            /* 这里是你的组件 token */
            colorBgContainer: '#10450d',
            colorBorder: '#10450d',
            colorPrimary: '#10450d',
            colorPrimaryHover: '#10450d',
            colorBgElevated: '#10450d',
            optionSelectedBg: '#146d1e',
            colorText: '#FFF',
          },
        },
      }}
    >
      {/*, background: '', color: '#FFF'*/}
      <Select
        style={{
          width: '100%', marginBottom: '10px', border: 'none',
        }}
        options={options}
      />
    </ConfigProvider>


  </>;
};

App.defaultProps = [
  {
    value: 'jack',
    label: 'Jack',
  },
  {
    value: 'lucy',
    label: 'Lucy',
  },
  {
    value: 'Yiminghe',
    label: 'yiminghe',
  },
  {
    value: 'disabled',
    label: 'Disabled',
  },
];

export default App;