import React from 'react';
import { cn } from '../../utils/utils';
import logoIcon from '../../assets/images/logo.png';

import {ReactComponent as NavHomeSvg} from '../../assets/svgs/nav_home.svg';
import {ReactComponent as NavProjectSvg} from '../../assets/svgs/nav_project.svg';
import {ReactComponent as NavShowreelSvg} from '../../assets/svgs/nav_showreel.svg';
import {ReactComponent as UserCenterSvg} from '../../assets/svgs/user_center.svg';
import SvgCom from '../../components/SvgCom/index.jsx';
import { Link, useLocation } from 'react-router-dom';
import { Select, Input } from 'antd';
import { SearchOutlined } from '@ant-design/icons';

const navList = [
  {
    label: 'Home',
    icon: NavHomeSvg,
    url:'/consumer-theatre-processing/home'
  },
  {
    label: 'Project',
    icon: NavProjectSvg,
    url:'/consumer-theatre-processing/project'
  },
  {
    label: 'Showreel',
    icon: NavShowreelSvg,
    url:'/consumer-theatre-processing/showreel'
  }
];

const NavBarCom = () => {
  const location = useLocation();

  return (
    <>
      <div
        className={cn(
          'h-[60px] w-full bg-primary text-white fixed top-0 left-0 flex justify-between items-center px-[28px] shadow-basic z-10'
        )}
      >
        <div className="flex items-center">
          <img src={logoIcon} className="w-[40px]" alt="" />
          <span className="text-lg whitespace-nowrap font-bold leading-none ml-[25px] hidden md:block">
            Consumer Theatre Processing
          </span>
        </div>
        <div className="flex items-center flex-1 justify-end">
          <div className="relative h-[32px] min-w-[280px] w-[50%] bg-[#FFFFFF87] rounded-3xl flex items-center overflow-hidden">
            <div className="h-full w-fit bg-whitd rounded-l-3xl bg-white relative flex items-center">
              <SearchOutlined className="text-primary text-xl ml-3"/>
              <Select
                popupClassName="ctp-select"
                className="filter-select ctp-select w-[100px]"
                defaultValue="all"
                options={[
                  { value: 'all', label: 'All' },
                  { value: 'project', label: 'Project' },
                  { value: 'showreel', label: 'Showreel' },
                ]}
              />
            </div>
            <Input className="flex-1 h-full border-none !outline-none !bg-transparent !shadow-none font-semibold" />
          </div>
          <SvgCom Svg={UserCenterSvg} className="ml-3 cursor-pointer" size="20"></SvgCom>
        </div>
      </div>
      <div className="w-[75px] h-full fixed top-0 left-0 bg-second pt-[60px] flex justify-start flex-col items-center">
        <div className="h-5"></div>
        {
          navList.map((item, index) => <Link key={index} to={item.url} className={cn('cf-effect flex flex-col items-center justify-center w-[60px] h-[60px] rounded-[10px] cursor-pointer text-primary hover:bg-third mb-2', location.pathname === item.url && '!bg-primary text-white')}>
            <SvgCom Svg={item.icon} className="w-7 h-7"></SvgCom>
            <span className="cf-effect font-semibold text-[10px]">{item.label}</span>
          </Link>)
        }
      </div>
    </>
  );
};

export default NavBarCom;
