import React, { useEffect } from 'react';
import TopDishDeepDive from '../top-dish-deep-dive/top-dish-deep-dive.jsx';

const DeepDivePopup = ({ isVisible, onClose, countries, brand, trendingDish, topDish, trendingDishId }) => {
  useEffect(() => {
    if (isVisible) {
      document.body.classList.add('no-scroll');
    } else {
      document.body.classList.remove('no-scroll');
    }

    return () => {
      document.body.classList.remove('no-scroll');
    };
  }, [isVisible]);

  return (
    <div className="deep-pop-up">
      <div className={`popup ${isVisible ? 'show' : ''}`}>
        <div>
          <TopDishDeepDive 
            onClose={onClose} 
            countries={countries}        
            brand={brand}                
            topDish={topDish}
            trendingDish={trendingDish}            
            trendingDishId={trendingDishId} 
          />
        </div>
      </div>
    </div>
  );
};

export default DeepDivePopup;
