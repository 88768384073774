// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.zpuswcvS3hCegqvBvnze{background-color:#da0000}.W_7CKKeOV6QbvpGMlqIW{background-color:#00a32e}.zNX7sQZKYfLToNTOM46a{background-color:#ffbb38}.zBurdecFFaMs97v6XLlg{display:inline-block;width:16px;height:16px;margin-right:3px;border-radius:50%;margin-right:3px}.zBurdecFFaMs97v6XLlg:last-child{margin-right:0}`, "",{"version":3,"sources":["webpack://./src/modules/package-ai-screening/components/aiIcon.module.scss"],"names":[],"mappings":"AAAA,sBACE,wBAAA,CAGF,sBACE,wBAAA,CAGF,sBACE,wBAAA,CAGF,sBACE,oBAAA,CACA,UAAA,CACA,WAAA,CACA,gBAAA,CACA,iBAAA,CACA,gBAAA,CACA,iCACE,cAAA","sourcesContent":[".aiIconRed {\n  background-color: #da0000;\n}\n\n.aiIconGreen {\n  background-color: #00a32e;\n}\n\n.aiIconAmber {\n  background-color: #ffbb38;\n}\n\n.aiCycle {\n  display: inline-block;\n  width: 16px;\n  height: 16px;\n  margin-right: 3px;\n  border-radius: 50%;\n  margin-right: 3px;\n  &:last-child {\n    margin-right: 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"aiIconRed": `zpuswcvS3hCegqvBvnze`,
	"aiIconGreen": `W_7CKKeOV6QbvpGMlqIW`,
	"aiIconAmber": `zNX7sQZKYfLToNTOM46a`,
	"aiCycle": `zBurdecFFaMs97v6XLlg`
};
export default ___CSS_LOADER_EXPORT___;
