import { Tabs } from 'antd';
import React, { useEffect, useState } from 'react';
import { downloadWb } from '../../../apis/file/index.js';
import BarChart from '../charts/bar.jsx';
import style from './tab.module.scss';

function DishesCategories({
  filter,
  compare,
  hasCompare,
  exportCount,
  isActive,
}) {
  const saveDataFns = [];
  function appendSaveDataFn(fn) {
    saveDataFns.push(fn);
  }

  useEffect(() => {
    if (!isActive) return;
    if (!exportCount) return;
    downloadWb(saveDataFns, 'xlsx', 'Dishes, categories and Ingredients');
  }, [exportCount]);

  const [userSelectChart, setUserSelectChart] = useState('');
  const [userSelectChartKey, setUserSelectChartKey] = useState('');
  const [userSelectChartValue, setUserSelectChartValue] = useState('');
  function onUserSelectChart(name, key, value) {
    setUserSelectChart(name);
    setUserSelectChartKey(key);
    setUserSelectChartValue(value);
  }

  return (
    <div className={style.chartContainer}>
      <BarChart
        name="dishes-categories-and-ingredients-dishes-meals-grouped"
        tabName="dishes-categories-and-ingredients-consideration-sets"
        tab2Name="dishes-categories-and-ingredients"
        title="Dishes & Meals (Grouped)"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Dishes & Meals (Grouped)"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <BarChart
        name="dishes-categories-and-ingredients-dishes-meals-specific"
        tabName="dishes-categories-and-ingredients-consideration-sets"
        tab2Name="dishes-categories-and-ingredients"
        title="Dishes & Meals (Specific)"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        hiddenItems={['7', '6', '9', '10']}
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Dishes & Meals (Specific)"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <BarChart
        name="dishes-categories-and-ingredients-products-ingredients-grouped-l2"
        tabName="dishes-categories-and-ingredients-consideration-sets"
        tab2Name="dishes-categories-and-ingredients"
        title="Products / Ingredients (Grouped, L2)"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Products / Ingredients (Grouped, L2)"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <BarChart
        name="dishes-categories-and-ingredients-products-ingredients-specific"
        tabName="dishes-categories-and-ingredients-consideration-sets"
        tab2Name="dishes-categories-and-ingredients"
        title="Products / Ingredients (Specific)"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Products / Ingredients (Specific)"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <BarChart
        name="dishes-categories-and-ingredients-special-scratch-cooking-dishes-grouping"
        tabName="dishes-categories-and-ingredients-consideration-sets"
        tab2Name="dishes-categories-and-ingredients"
        title="Special scratch cooking dishes grouping"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Special Scratch Cooking Dishes Grouping"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <BarChart
        name="dishes-categories-and-ingredients-frequency-of-eating-this-food"
        tabName="dishes-categories-and-ingredients-consideration-sets"
        tab2Name="dishes-categories-and-ingredients"
        title="Frequency of eating this food"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Frequency Of Preparing/Cooking This Food"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
    </div>
  );
}

function ConsiderationSets({
  filter,
  compare,
  hasCompare,
  exportCount,
  isActive,
}) {
  const saveDataFns = [];
  function appendSaveDataFn(fn) {
    saveDataFns.push(fn);
  }

  useEffect(() => {
    if (!isActive) return;
    if (!exportCount) return;
    downloadWb(saveDataFns, 'xlsx', 'Consideration Set');
  }, [exportCount]);

  const [userSelectChart, setUserSelectChart] = useState('');
  const [userSelectChartKey, setUserSelectChartKey] = useState('');
  const [userSelectChartValue, setUserSelectChartValue] = useState('');
  function onUserSelectChart(name, key, value) {
    setUserSelectChart(name);
    setUserSelectChartKey(key);
    setUserSelectChartValue(value);
  }

  return (
    <div className={style.chartContainer}>
      <BarChart
        name="consideration-sets-was-anything-else-considered"
        tabName="dishes-categories-and-ingredients-consideration-sets"
        tab2Name="consideration-set"
        title="Was anything else considered?"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Consideration (Y/N)"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <BarChart
        name="consideration-sets-what-other-food-or-drink-did-you-consider-having-instead-l2"
        tabName="dishes-categories-and-ingredients-consideration-sets"
        tab2Name="consideration-set"
        title="What other food or drink did you consider having instead? (L2)"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Consideration Set (Food / Drink, L2)"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <BarChart
        name="consideration-sets-reasons-for-not-choosing-food-product-considered"
        tabName="dishes-categories-and-ingredients-consideration-sets"
        tab2Name="consideration-set"
        title="Reasons for not choosing food / product considered"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Reasons For Not Choosing Food / Product Considered"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
    </div>
  );
}

function DishesCategoriesConsiderationSets({
  filter,
  compare,
  hasCompare,
  exportCount,
  isActive,
}) {
  const startTab = new URL(window.location).searchParams.get('tab2');

  const [activeKey, setActiveKey] = useState(
    startTab ?? 'dishes-categories-and-ingredients'
  );

  return (
    <Tabs
      tabPosition="top"
      defaultActiveKey={startTab}
      onChange={setActiveKey}
      items={[
        {
          label: <span>{'Dishes, categories and Ingredients'}</span>,
          key: 'dishes-categories-and-ingredients',
          children: (
            <DishesCategories
              filter={filter}
              compare={compare}
              hasCompare={hasCompare}
              exportCount={exportCount}
              isActive={
                isActive && activeKey === 'dishes-categories-and-ingredients'
              }
            />
          ),
        },
        {
          label: <span>{'Consideration Set'}</span>,
          key: 'consideration-set',
          children: (
            <ConsiderationSets
              filter={filter}
              compare={compare}
              hasCompare={hasCompare}
              exportCount={exportCount}
              isActive={isActive && activeKey === 'consideration-set'}
            />
          ),
        },
      ]}
    ></Tabs>
  );
}

export default DishesCategoriesConsiderationSets;
