import React from 'react';
import { useState, useEffect, useImperativeHandle, forwardRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { changeStepOneData } from '../../../../store/concept-ai.js';
import { Input, Select, Upload, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { fileUpload } from '../../../../api/request.js';
import {
  AllOptionsTree,
  OptionsApiPriceTier,
} from '../../../../constant/constant.jsx';
import style from './step-one.module.scss';
import { message } from '../../../../pages/main.jsx';

const { Dragger } = Upload;

const antIcon = (
  <LoadingOutlined
    style={{
      fontSize: 22,
      color: '#78F550',
    }}
    spin
  />
);

let FormCom = (_, ref) => {
  const dispatch = useDispatch();
  const country = useSelector((state) => state.conceptAi.country);
  const stepOneData = useSelector((state) => state.conceptAi.stepOneData);

  // Concept Name
  const [conceptName, setConceptName] = useState(stepOneData.product);
  const setConcept = (val) => {
    setConceptName(val);
    dispatch(changeStepOneData({ stepOneData: { product: val } }));
  };

  // category
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [categoryValue, setCategoryValue] = useState(stepOneData.category);
  const setCategory = (val) => {
    setCategoryValue(val);
    dispatch(changeStepOneData({ stepOneData: { category: val } }));
  };
  useEffect(() => {
    let res = [];
    const item = AllOptionsTree.find(
      (item) => item.value === country
    );
    if (item) {
      res = item.children;
    }
    setCategoryOptions(res);
    if (!res.find((item) => item.value === categoryValue)) {
      setCategory('');
    }
  }, [country]);

  // format
  const [formatOptions, setFormatOptions] = useState([]);
  const [formatValue, setFormatValue] = useState(stepOneData.format);
  const setFormat = (val) => {
    setFormatValue(val);
    dispatch(changeStepOneData({ stepOneData: { format: val } }));
  };
  useEffect(() => {
    let res = [];
    const item = categoryOptions.find((item) => item.value === categoryValue);
    if (item) {
      res = item.childrenList.format??[];
    }
    setFormatOptions(res);
    if (!res.find((item) => item.value === formatValue)) {
      setFormat('');
    }
  }, [categoryValue, categoryOptions]);

  // brand
  const [brandOptions, setBrandOptions] = useState([]);
  const [brandValue, setBrandValue] = useState(stepOneData.brand);
  const setBrand = (val) => {
    setBrandValue(val);
    dispatch(changeStepOneData({ stepOneData: { brand: val } }));
  };
  useEffect(() => {
    let res = [];
    const item = categoryOptions.find((item) => item.value === categoryValue);
    if (item) {
      res = item.childrenList.brand??[];
    }
    setBrandOptions(res);
    if (!res.find((item) => item.value === brandValue)) {
      setBrand('');
    }
  }, [categoryValue, categoryOptions]);

  // ApiPriceTier
  const [apiValue, setApiValue] = useState(stepOneData.apiPriceTier);
  const setApi = (val) => {
    setApiValue(val);
    dispatch(changeStepOneData({ stepOneData: { apiPriceTier: val } }));
  };

  // img
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState('');
  useEffect(() => {
    setImageUrl(stepOneData.image_urls);
  }, [stepOneData.image_urls]);
  const setImage = (val, key) => {
    setImageUrl(val);
    dispatch(
      changeStepOneData({
        stepOneData: {
          image_urls: val,
          image_key: key,
        },
      })
    );
  };

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <div className={style.uploadIcon}></div>}
      <div className={style.uploadText}>Click and select image</div>
    </div>
  );

  function getFileExt(file) {
    if (!file) {
      console.error('The file parameter is empty');
      return '';
    }
    const fileName = file.name || file;
    const fileNameList = fileName.split('.');
    if (fileNameList[fileNameList.length - 1].indexOf('?') === -1) {
      return fileNameList[fileNameList.length - 1];
    }
    return fileNameList[fileNameList.length - 1].split('?')[0];
  }

  const beforeUpload = (file) => {
    const suffix = getFileExt(file);
    const validate = ['jpeg', 'jpg', 'jpe', 'png', 'ppt', 'pptx'].includes(
      suffix
    );
    if (!validate) {
      message.error('You can only upload JPG/PNG/PPT/PPTX file!');
      return;
    }
    const isLt2M = file.size / 1024 / 1024 < 100;
    if (!isLt2M) {
      message.error('Image must smaller than 100MB!');
      return;
    }
    setLoading(true);
    const formData = new FormData();
    formData.append('prefix', 'conceptai');
    formData.append('form', 'form');
    formData.append('file', file);
    fileUpload(formData)
      .then((res) => {
        setImage(res.data.url, res.data.key);
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setLoading(false);
      });
    return false;
  };

  useImperativeHandle(ref, () => ({
    reset: () => {
      setConcept('');
      setCategory('');
      setFormat('');
      setBrand('');
      setApi('');
      setImage('');
    },
  }));

  return (
    <div className={style.formContainer}>
      <div className={style.formItem}>
        <div className={style.itemName}>Concept Name</div>
        <div className={style.itemInput}>
          <Input
            placeholder=""
            value={conceptName}
            onChange={(e) => setConcept(e.target.value)}
          />
        </div>
        <div className={style.itemInfo}>
          Enter concept name for identification
        </div>
      </div>
      <div className={style.formItem}>
        <div className={style.itemName}>Category</div>
        <div className={style.itemInput}>
          <Select
            placeholder=""
            value={categoryValue}
            onChange={setCategory}
            options={categoryOptions}
          />
        </div>
      </div>
      <div className={style.formItem}>
        <div className={style.itemName}>Format</div>
        <div className={style.itemInput}>
          <Select
            placeholder=""
            value={formatValue}
            onChange={setFormat}
            options={formatOptions}
          />
        </div>
      </div>
      <div className={style.formItem}>
        <div className={style.itemName}>Brand</div>
        <div className={style.itemInput}>
          <Select
            placeholder=""
            value={brandValue}
            onChange={setBrand}
            options={brandOptions}
          />
        </div>
      </div>
      <div className={style.formItem}>
        <div className={style.itemName}>ApiPriceTier</div>
        <div className={style.itemInput}>
          <Select
            placeholder=""
            value={apiValue}
            onChange={setApi}
            options={OptionsApiPriceTier}
          />
        </div>
      </div>
      <div className={style.formItem}>
        <div className={style.itemName}>Image upload</div>
        <div className={style.itemImage}>
          <Dragger
            name="avatar"
            accept={['.ppt', '.pptx', 'image/*']}
            className="avatar-uploader"
            showUploadList={false}
            action=""
            beforeUpload={beforeUpload}
            disabled={loading}
          >
            {imageUrl ? (
              <Spin
                wrapperClassName={style.updateImageLoading}
                indicator={antIcon}
                spinning={loading}
              >
                <img
                  key={imageUrl}
                  className={style.imgItem}
                  src={imageUrl}
                  alt=""
                  style={{
                    width: '100%',
                  }}
                />
              </Spin>
            ) : (
              uploadButton
            )}
          </Dragger>
        </div>
      </div>
    </div>
  );
};

FormCom = forwardRef(FormCom);

export default FormCom;
