/* eslint-disable import/no-unresolved */
import bgVideo from '@/assets/videos/trend-dial-video.mp4';
import classNames from 'classnames';
import React from 'react';
import style from './bg.module.scss';

function VideoBg({ className }) {
  return (
    <div className={classNames(style.videoBox, className)}>
      <video autoPlay="autoPlay" muted={true} loop="loop">
        <source src={bgVideo} />
      </video>
    </div>
  );
}

export default VideoBg;
