import React from 'react';
import { Modal, Form, Input, Button, Select, Checkbox, Row, Col } from 'antd';

const { Item } = Form;

const { Option } = Select;

const roles = {
  1: 'User',
  2: 'Admin',
  3: 'BuFeedbackUser',
  4: 'OccasionUser',
};

const EditCreateUserModal = ({
  title,
  isEditing,
  setIsEditing,
  form,
  handleFinish,
  editingRecord,
}) => {

  const handleRoleChange = (newValues) => {
    console.log(newValues);
  };
  
  return (
    <>
      <Modal
        title={title}
        visible={isEditing}
        onCancel={() => setIsEditing(false)}
        footer={null}
      >
        <Form form={form} layout="vertical" onFinish={handleFinish}>
          <Item
            label="Username"
            name="username"
            rules={[{ required: true, message: 'Please enter username' }]}
            initialValue={editingRecord ? editingRecord.username : ''}
          >
            <Input />
          </Item>
          <Item
            label="Email"
            name="email"
            rules={[{ required: true, message: 'Please enter email' }]}
            initialValue={editingRecord ? editingRecord.email : ''}
          >
            <Input />
          </Item>
          <Item
            name="bu"
            label="BU"
            rules={[{ required: false}]}
            initialValue={editingRecord ? editingRecord.bu : ''}
          >
            <Input />
          </Item>
          <Item
            name="roles"
            label="Roles"
            rules={[{ required: false}]}
            initialValue={editingRecord ? editingRecord.roles: ''}
          >
            <Select mode="multiple" onChange={handleRoleChange}>
              {Object.keys(roles).map((key) => (
                <Option key={key} value={Number(key)}>
                  {roles[key]}
                </Option>
              ))}
            </Select>
          </Item>
          <Row
            justify="space-between"
            align="middle"
            style={{ marginTop: '16px' }}
          >
            <Col span={12}>
              <Item name="active" label="active" initialValue={true}>
                <Checkbox checked={true}>Active</Checkbox>
              </Item>
            </Col>
            <Col span={12}>
              <Item name="is_supervisor" label="is_supervisor">
                <Checkbox
                  checked={editingRecord ? editingRecord.is_supervisor : false}
                >
                  Is Supervisor{' '}
                </Checkbox>
              </Item>
            </Col>
          </Row>

          <div style={{ textAlign: 'right' }}>
            <Button type="primary" htmlType="submit">
              Save
            </Button>
            <Button onClick={() => setIsEditing(false)}>Cancel</Button>
          </div>
        </Form>
      </Modal>
    </>
  );
};

export default EditCreateUserModal;
