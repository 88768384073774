import React, { useState, useEffect, useRef, memo, useMemo, useCallback } from 'react';
import ReactECharts from 'echarts-for-react';
import * as echarts from 'echarts/core';
import { CanvasRenderer } from 'echarts/renderers';
import style from './search-tiktok.module.scss';
import classNames from 'classnames';
import { Pagination, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import VideoPlayer from '../video-player/video-player.jsx';
import TiktokModal from '../tiktok-modal/tiktok-modal.jsx';
import seeDetails from '../../../../assets/images/trends-dial/see-details.png';
import { 
  fetchSearchCategoriesAssociated,
  fetchSearchCategoriesChart,
  fetchTiktokCategoriesVideos
} from '../../api/request.js';

echarts.use(
  [CanvasRenderer]
);

const antIcon = (
  <LoadingOutlined
    style={{
      fontSize: 30,
      color: '#00FF00'
    }}
    spin
  />
);

function debounce (callback, delay) {
  let lastTime;
  return function () {
    clearTimeout(lastTime);
    const [that, args] = [this, arguments];
    lastTime = setTimeout(() => {
      callback.apply(that, args);
    }, delay);
  };
}

let requestIndex = Date.now();

const SearchTiktokMemo = memo(function SearchTiktok({country, consumerTrend}) {

  // 标签
  const [labels, setLabels] = useState([]);
  const [categoryValue, setCategoryValue] = useState('');
  const labelClick = (label) => {
    setCategoryValue(categoryValue === label.categoryName ? '' : label.categoryName);
  };

  // charts
  const [option, setOption] = useState({});
  const [chartsLoading, setChartsLoading] = useState(true);
  const TiktokModalRef = useRef(null);
  const onChartClick = (params) => {
    TiktokModalRef.current.open(params.data.name);
  };
  const fetchChartsFn = (categoryValue) => {
    const params = {
      country,
      consumerTrend,
      category: categoryValue,
      limit: 30
    };
    setChartsLoading(true);
    fetchSearchCategoriesChart(params)
      .then(res => {
        const sData = Array.isArray(res.data) ? res.data : [];
        const xData = [];
        for (let i = 0; i < sData.length; i++) {
          xData.push(i);
        }
        setOption({
          grid: {
            left: '80'
          },
          tooltip: {
            trigger: 'item',
            backgroundColor: 'rgba(0,0,0,0.7)',
            textStyle: {
              color: '#FFFFFF'
            }
          },
          xAxis: {
            type: 'category',
            axisLine: {
              lineStyle: {
                color: '#00A32E'
              }
            },
            axisTick: {
              show: false
            },
            data: xData
          },
          yAxis: {
            type: 'value',
            axisLabel: {
              color: '#00A32E'
            },
            splitLine: {
              lineStyle: {
                color: 'rgba(0, 163, 46, .2)'
              }
            }
          },
          series: [
            {
              data: sData,
              type: 'bar',
              itemStyle: {
                color: '#00A32E'
              },
              lineStyle: {
                color: '#00A32E',
                width: '3'
              },
              label: {
                show: true,
                color: 'rgba(255,255,255,.5)',
                rotate: '90',
                position: 'top',
                align: 'left',
                verticalAlign: 'middle',
                formatter(params) {
                  return params.name;
                }
              },
            },
            {
              name: 'glyph',
              type: 'pictorialBar',
              barGap: '-100%',
              symbolPosition: 'end',
              symbolSize: [100, 50],
              symbolOffset: ['50%', '-82%'],
              silent: true,
              z: 10,
              data: [
                {
                  value: sData[0].value,
                  symbol: `image://${seeDetails}`,
                }
              ],
            },
          ]
        });
      })
      .catch(err => {console.error(err);})
      .finally(() => {
        setChartsLoading(false);
      });
  };

  const ChartsNode = useMemo(() => {
    return (
      <div className={style.chartsBox}>
        <ReactECharts
          echarts={echarts}
          option={option}
          style={{
            height: '400px'
          }}
          onEvents={{
            'click': onChartClick
          }}
        />
      </div>
    );
  }, [option]);

  // tiktok
  const [tiktokLoading, setTiktokLoading] = useState(true);
  const [total, setTotal] = useState(0);
  const [current, setCurrent] = useState(1);
  const [pageSize, setPageSize] = useState(18);
  const [listData, setListData] = useState([]);
  const VideoPlayerRef = useRef(null);
  const openDetail = (videoData) => {
    VideoPlayerRef.current.open(videoData);
  };
  const fetchTiktokFn = (params) => {
    setTiktokLoading(true);
    fetchTiktokCategoriesVideos(params, requestIndex)
      .then(res => {
        if (requestIndex !== res.config.uuid) return;
        const resData = res.data.data;
        setListData(Array.isArray(resData.data) ? resData.data : []);
        setTotal(resData.total);
        setCurrent(resData.current);
        setPageSize(resData.size);
      })
      .catch(err => {console.error(err);})
      .finally(() => {
        setTiktokLoading(false);
      });
  };
  const delayFetchDetails = useCallback(debounce(function(val) { return fetchTiktokFn(val);}, 500), []);

  useEffect(() => {
    if (!categoryValue) return;
    fetchChartsFn(categoryValue);
  }, [categoryValue]);

  useEffect(() => {
    if (!categoryValue) return;
    const params = {
      country,
      consumerTrend,
      category: categoryValue === 'Retail' ? 'Retailer' : categoryValue,
      page: current,
      pageSize
    };
    requestIndex++;
    delayFetchDetails(params);
  }, [categoryValue, current, pageSize]);

  useEffect(() => {
    const params = {
      country,
      consumerTrend,
      limit: 30
    };
    fetchSearchCategoriesAssociated(params)
      .then(res => {
        const list = Array.isArray(res.data) ? res.data : [];
        setLabels(list);
        setCategoryValue(list[0].categoryName);
      })
      .catch(err => {console.error(err);});
    
  }, [country, consumerTrend]);

  return (
    <div className={style.SearchTiktok}>
      <div className={style.labelContainer}>
        {labels.map((item, index) => {
          return (
            <div 
              key={item.categoryName + index} 
              className={classNames(style.labelItem, 
                {[style.active]: categoryValue === item.categoryName}
              )}
              onClick={() => labelClick(item)}
            >{item.categoryName}</div>);
        })}
      </div>
      <Spin indicator={antIcon} spinning={chartsLoading}>
        { ChartsNode }
      </Spin>
      <div className={style.tiktokTitle}>Tiktok</div>
      <Spin indicator={antIcon} spinning={tiktokLoading}>
        {tiktokLoading && listData?.length === 0 && <div className={style.subBox}></div>}
        {listData?.length > 0 && <>
          <div className={style.videosBox}>
            {listData.map(item => {
              return (
                <div key={item.videoId} className={style.videoItem} onClick={() => openDetail(item)}>
                  <div className={style.videoItemInner}>
                    <iframe 
                      className={style.iframe}
                      src={`https://www.tiktok.com/embed/${item.videoId}`}
                      title="Video Player"
                      allowFullScreen
                      scrolling="no"
                      allow="encrypted-media"
                    ></iframe>
                    <div className={style.videoCover}></div>
                  </div>
                  <div className={style.videoName}>{item.videoTitle}</div>
                </div>
              );
            })}
          </div>
          <div className={style.paginationBox}>
            <Pagination
              onChange={(page) => setCurrent(page)}
              onShowSizeChange={(_, size) => {setCurrent(1);setPageSize(size);}}
              total={total}
              current={current}
              pageSize={pageSize} 
              showSizeChanger={true} 
              pageSizeOptions={[1, 18, 36, 54, 72]} 
              showQuickJumper={true} 
            />
          </div>
        </>}
      </Spin>
      <VideoPlayer ref={VideoPlayerRef} />
      <TiktokModal ref={TiktokModalRef} country={country} consumerTrend={consumerTrend} category={categoryValue} />
    </div>
  );
});

export default SearchTiktokMemo;