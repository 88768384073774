import * as XLSX from 'xlsx';

function exportToExcel(sheetName, data) {
  // 创建一个新的工作簿
  const workbook = XLSX.utils.book_new();
  // 将数据转换为工作表
  const worksheet = XLSX.utils.json_to_sheet(data);
  // 添加工作表到工作簿
  XLSX.utils.book_append_sheet(workbook, worksheet);
  // 导出 Excel 文件
  XLSX.writeFile(workbook, `${sheetName}.xlsx`);
}

export default exportToExcel;
