// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rDWvTPOFpP0XHOb6Jp_v{display:flex;height:100%}.BArA7VVzvAisF6yihJM_{flex:1;padding:12px;overflow-x:hidden;overflow-y:auto}`, "",{"version":3,"sources":["webpack://./src/modules/occasion-module/views/dashboard/dashboard.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,WAAA,CAGF,sBACE,MAAA,CACA,YAAA,CACA,iBAAA,CACA,eAAA","sourcesContent":[".dashboardContainer {\n  display: flex;\n  height: 100%;\n}\n\n.dashboardContent {\n  flex: 1;\n  padding: 12px;\n  overflow-x: hidden;\n  overflow-y: auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dashboardContainer": `rDWvTPOFpP0XHOb6Jp_v`,
	"dashboardContent": `BArA7VVzvAisF6yihJM_`
};
export default ___CSS_LOADER_EXPORT___;
