import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';
import useBaseInfo from './useBaseInfo';

const BaseContext = createContext(null);
export const BaseProvider = ({children}) => {
  const { buOptions, categoryOptions } = useBaseInfo();
  const [ isEdit, setIsEdit] = useState(false);
  const [ curData, setCurData ] = useState([]);
  const [ userInfo, setUserInfo] = useState(null);
  const [ openDetail, setOpenDetail ] = useState(false);

  const editChange = useCallback(async () => {
    setIsEdit(!isEdit);
  }, [isEdit]);

  const curDataChange = useCallback(async (data) => {
    setCurData(data);
  }, []);

  useEffect(() => {
    document.getElementById('tableWrap').scrollTo({
      left: isEdit ? 10000000 : 0,
      behavior: 'smooth'
    });
  }, [isEdit]);

  useEffect(() => {
    const tempUser = sessionStorage.getItem('user');
    const tempUserObj = JSON.parse(tempUser);
    const email = tempUserObj.email;
    const bu = tempUserObj.bu;
    setUserInfo({
      admin: !!bu,
      username: email,
      business_unit: bu
    });
  }, []);

  const changeOpenDetail = useCallback(async (data) => {
    setOpenDetail(data);
  }, []);

  return <BaseContext.Provider value={{
    buOptions,
    categoryOptions,
    isEdit,
    curData,

    userInfo,
    openDetail,

    editChange,
    curDataChange,
    changeOpenDetail,
  }}>
    {children}
  </BaseContext.Provider>;
};

export const useBase = () => {
  return useContext(BaseContext) || {
    buOptions: [],
    categoryOptions: [],
    isEdit: false,
    curData: [],
    userInfo: null,
    openDetail: false,
    
    editChange: () => {},
    curDataChange: () => {},
    changeOpenDetail: () => {},
  };
};

export const loadingObj = {
  text: '',
  color: '#178f1e',
  textColor: '#000',
  maskColor: 'rgba(0, 0, 0, 0.1)',
  zlevel: 10,
};
