export const STATUS_INIT = 0;
export const STATUS_PENDING = 1;
export const STATUS_FAILED = 2;
export const STATUS_SUCCESS = 3;

export * from './aesthetics';
export * from './predictImage';
export * from './predictPackage';
export * from './predictShelf';
export * from './predictVariant';
export * from './standardizer';
export * from './suggest';
