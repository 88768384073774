import React, { useEffect, useState } from 'react';
import MyLearningPopup from './learningcard-modal/mylearning-popup.jsx';
import PropTypes from 'prop-types';
import {
  // commentIcon,
  noImageUploaded,
  shareIcon,
  videoIcon,
} from '../../../../components/icons/icons.jsx';
// import InfiniteScroll from 'react-infinite-scroll-component';
import { useParams } from 'react-router-dom';
import FavouriteButton from '../../../../components/favourite/favourite-button.jsx';
import ShareModal from '../../../../components/share-modal/share-modal.jsx';
import Loader from '../../../../components/loader/loader.jsx';
import chevronRight from '../../../../assets/images/svg/chevron-right-green.svg';
import { apiURL } from '../../../../env-url.js';
import api from '@/util/api.jsx';
// import SimplePagination from '../../../../components/antd-pagination/antd-pagination.jsx';

function AllLearningCard({
  filteredData,
  searchQuery,
  filterData,
  isSorted,
  isSortedLatest,
  favoriteData,
  isFunctionAdded,
  sortData,
  isLiked,
  IsMyLearning,
  fetchlearning,
  userName,
  nofilter,
}) {
  const [enable, setEnable] = useState(false);                             
  const [idValue, setIdValue] = useState();
  const [data, setData] = useState([]);
  // const [pageNumber, setPageNumber] = useState(1);
  // const [pageSize, setPageSize] = useState(20);
  // const [totalCount, setTotalCount] = useState();
  const [openReadMorePopup, setOpenReadMorePopup] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const [openSliderIndex, setOpenSliderIndex] = useState(null);
  const [newToken, ] = useState(
    sessionStorage.getItem('jwt-access-token')
  );
  const [selectedCardURL, setSelectedCardURL] = useState('');
  // const [selectedAssetURL, setSelectedAssetURL] = useState('');
  const [showShareIcons, setShowShareIcons] = useState(
    Array(data.length).fill(false)
  );

  let { id } = useParams();

  const favorite = favoriteData.flat();
  const fileprefix = `${apiURL}/gotfl/learnings/media?name=`;

  useEffect(() => {
    handleClick;
    fetchData();
  }, []);

  useEffect(()=>{
    if(id !== undefined){
      setIdValue(id);
    }
  },[id]);

  const closeMyLearning = () => {
    setEnable(false);
    setOpenReadMorePopup(false);
    id=undefined;
    setIdValue();
  };

  // const handlePageChange = (page) => {
  //   setLoading(true);
  //   setPageNumber(page);
  // };

  const handleClick = (e) => {
    setEnable(true);
    setIdValue(e.target.value);
    setOpenSliderIndex(e.target.value);
  };

  const handleReadMore = (id) => {
    setIdValue(id);
    setOpenReadMorePopup(!openReadMorePopup);
  };

  const fetchData = () => {
    api
      .get(`${apiURL}/gotfl/learnings`, {
        // params: {
        //   pageNumber:pageNumber,
        //   pageSize:pageSize
        // }
      })
      .then((response) => {
        setData(response.data);
        // setPageNumber(response.pageMeta.pageNumber);
        // setPageSize(response.pageMeta.pageSize);
        // setTotalCount(response.pageMeta.totalCount);
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  };


  const generateCardLink = (index) => {
    let url = window.location.href;

    if(id){
      let urlParts = url.split('/');
      let lastIndex = urlParts.length - 1;
      urlParts[lastIndex] = index;
      
      return urlParts.join('/');
    }
    else 
      return url + '/' + index;
  };

  const handleShare = (item, index) => {
    const cardLink = generateCardLink(index);
    if (cardLink) {
      setSelectedCardURL(cardLink);
      setShowShareIcons((prevState) => {
        const newState = [...prevState];
        newState[index] = true;
        return newState;
      });
    }
  };

  const extractTikTokVideoID = (videoURL) => {
    const regex = /\/video\/(\d+)/;
    const match = videoURL.match(regex);
    return match ? match[1] : null;
  };

  return isLoading ? ( //Checkif if is loading
    <Loader />
  ) : (
    <>
      {/* <InfiniteScroll
        dataLength={data.length}
        next={() => setPage((prevPage) => prevPage + 1)}
        hasMore={false}
        loader={<h4>Loading...</h4>}
      > */}
      <div className="containerwrapper">
        {searchQuery.length > 1 ? (
          <>
            {filteredData.map((item, i) => (
              <div className="cardwrapper" key={i}>
                <div className="card-container" tabIndex={0}>
                  <div className="card" tabIndex={0}>
                    {item.mediaLinks.map((file, index) =>
                      file.fileCategory === 'img' ? (
                        <img
                          src={
                            fileprefix +
                            (file.fileURL
                              ? `${file.fileURL}&accessToken=${newToken}`
                              : noImageUploaded)
                          }
                          alt="mediaLinks"
                          onClick={() => handleReadMore(i)}
                          value={item.id}
                          id={item.ownerId}
                          key={index}
                        />
                      ) : (
                        <>
                          {file.fileURL.includes('tiktok.com') ? (
                            <iframe
                              title="Tiktok Video"
                              src={`https://www.tiktok.com/embed/${extractTikTokVideoID(
                                file.fileURL
                              )}`}
                              allowFullScreen
                              className="learning-videos"
                              allow="encrypted-media"
                            ></iframe>
                          ) : file.fileURL.includes('youtube.com') ? (
                            <iframe
                              title="YouTube Video"
                              src={file.fileURL}
                              className="learning-videos"
                            ></iframe>
                          ) : (
                            <div className="learning-videos">
                              <video
                                src={
                                  file.fileURL.startsWith('gotfl')
                                    ? `${fileprefix}${file.fileURL}&accessToken=${newToken}`
                                    : `${file.fileURL}&accessToken=${newToken}`
                                }
                                alt="mediaLinks"
                                onClick={() => handleReadMore(i)}
                                value={item.id}
                                id={item.ownerId}
                                key={index}
                              ></video>
                              <div className="video-icon-container">
                                <img
                                  className="video-icon"
                                  src={videoIcon}
                                  alt="Video Icon"
                                />
                              </div>
                            </div>
                          )}
                        </>
                      )
                    )}
                  </div>
                  <div className="expandable">
                    <ul className="card-icon-wrapper">
                      <li>
                        <FavouriteButton
                          item={item}
                          id={item.ownerId}
                          fetchData={fetchData}
                          fetchlearning={fetchlearning}
                        />
                      </li>
                      <li>{/* <img src={commentIcon} alt="" /> */}</li>
                      <li>
                        <img src={shareIcon} alt="share link" />
                      </li>
                    </ul>
                    <div className="card-dscp mt-10">{item.description}</div>
                    <div className="card-btn mt-10 mb-10">
                      <button
                        onClick={handleClick}
                        value={i}
                        id={item.id}
                        onKeyDown={(event) => {
                          if (event.key === 'Enter') {
                            setEnable(enable);
                          }
                        }}
                        role="button"
                      >
                        Read More <img src={chevronRight} alt="" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </>
        ) : isFunctionAdded && favorite.length > 0 ? (
          <>
            {favorite.map((item, i) => (
              <div className="cardwrapper" key={i}>
                <div className="card-container" tabIndex={0}>
                  <div className="card" tabIndex={0}>
                    {item.mediaLinks.map((file, index) =>
                      file.fileCategory === 'img' ? (
                        <img
                          src={
                            fileprefix + file.fileURL
                              ? `${fileprefix}${file.fileURL}&accessToken=${newToken}`
                              : noImageUploaded
                          }
                          alt="mediaLinks"
                          onClick={() => handleReadMore(i)}
                          value={item.id}
                          id={item.ownerId}
                          key={index}
                        />
                      ) : (
                        <>
                          {file.fileURL.includes('tiktok.com') ? (
                            <iframe
                              title="Tiktok Video"
                              src={`https://www.tiktok.com/embed/${extractTikTokVideoID(
                                file.fileURL
                              )}`}
                              allowFullScreen
                              className="learning-videos"
                              allow="encrypted-media"
                            ></iframe>
                          ) : file.fileURL.includes('youtube.com') ? (
                            <iframe
                              title="YouTube Video"
                              src={file.fileURL}
                              className="learning-videos"
                            ></iframe>
                          ) : (
                            <div className="learning-videos">
                              <video
                                src={
                                  file.fileURL.startsWith('gotfl')
                                    ? `${fileprefix}${file.fileURL}&accessToken=${newToken}`
                                    : `${file.fileURL}&accessToken=${newToken}`
                                }
                                alt="mediaLinks"
                                onClick={() => handleReadMore(i)}
                                value={item.id}
                                id={item.ownerId}
                                key={index}
                              ></video>
                              <div className="video-icon-container">
                                <img
                                  className="video-icon"
                                  src={videoIcon}
                                  alt="Video Icon"
                                />
                              </div>
                            </div>
                          )}
                        </>
                      )
                    )}
                  </div>
                  <div className="expandable">
                    <ul className="card-icon-wrapper">
                      <li>
                        <FavouriteButton
                          item={item}
                          id={item.ownerId}
                          fetchData={fetchData}
                          fetchlearning={fetchlearning}
                        />
                      </li>
                      <li>{/* <img src={commentIcon} alt="" /> */}</li>
                      <li>
                        <img src={shareIcon} alt="" />
                      </li>
                    </ul>
                    <div className="card-dscp mt-10">{item.description}</div>
                    <div className="card-btn mt-10 mb-10">
                      <button
                        onClick={handleClick}
                        value={i}
                        id={item.id}
                        onKeyDown={(event) => {
                          if (event.key === 'Enter') {
                            setEnable(enable);
                          }
                        }}
                        role="button"
                      >
                        Read More <img src={chevronRight} alt="" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </>
        ) : isFunctionAdded && favorite.length > 0 ? (
          <>
            {favorite.map((item, i) => (
              <div className="cardwrapper" key={i}>
                <div className="card-container" tabIndex={0}>
                  <div className="card" tabIndex={0}>
                    {item.mediaLinks.map((file, index) =>
                      file.fileCategory === 'img' ? (
                        <img
                          src={
                            fileprefix + file.fileURL
                              ? `${fileprefix}${file.fileURL}&accessToken=${newToken}`
                              : noImageUploaded
                          }
                          alt="mediaLinks"
                          onClick={() => handleReadMore(i)}
                          value={item.id}
                          id={item.ownerId}
                          key={index}
                        />
                      ) : (
                        <>
                          {file.fileURL.includes('tiktok.com') ? (
                            <iframe
                              title="Tiktok Video"
                              src={`https://www.tiktok.com/embed/${extractTikTokVideoID(
                                file.fileURL
                              )}`}
                              allowFullScreen
                              className="learning-videos"
                              allow="encrypted-media"
                            ></iframe>
                          ) : file.fileURL.includes('youtube.com') ? (
                            <iframe
                              title="YouTube Video"
                              src={file.fileURL}
                              className="learning-videos"
                            ></iframe>
                          ) : (
                            <div className="learning-videos">
                              <video
                                src={
                                  file.fileURL.startsWith('gotfl')
                                    ? `${fileprefix}${file.fileURL}&accessToken=${newToken}`
                                    : `${file.fileURL}&accessToken=${newToken}`
                                }
                                alt="mediaLinks"
                                onClick={() => handleReadMore(i)}
                                value={item.id}
                                id={item.ownerId}
                                key={index}
                              ></video>
                              <div className="video-icon-container">
                                <img
                                  className="video-icon"
                                  src={videoIcon}
                                  alt="Video Icon"
                                />
                              </div>
                            </div>
                          )}
                        </>
                      )
                    )}
                  </div>
                  <div className="expandable">
                    <ul className="card-icon-wrapper">
                      <li>
                        <FavouriteButton
                          item={item}
                          id={item.ownerId}
                          fetchData={fetchData}
                          fetchlearning={fetchlearning}
                        />
                      </li>
                      <li>{/* <img src={commentIcon} alt="" /> */}</li>
                      <li>
                        <img src={shareIcon} alt="" />
                      </li>
                    </ul>
                    <div className="card-dscp mt-10">{item.description}</div>
                    <div className="card-btn mt-10 mb-10">
                      <button
                        onClick={handleClick}
                        value={i}
                        id={item.id}
                        onKeyDown={(event) => {
                          if (event.key === 'Enter') {
                            setEnable(enable);
                          }
                        }}
                        role="button"
                      >
                        Read More <img src={chevronRight} alt="" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </>
        ) : nofilter === false && filterData.length > 0 ? (
          <>
            {filterData.map((item, i) => (
              <div className="cardwrapper" key={i}>
                <div className="card-container" tabIndex={0}>
                  <div className="card" tabIndex={0}>
                    {item.mediaLinks.map((file, index) =>
                      file.fileCategory === 'img' ? (
                        <img
                          src={
                            fileprefix + file.fileURL
                              ? `${fileprefix}${file.fileURL}&accessToken=${newToken}`
                              : noImageUploaded
                          }
                          alt="mediaLinks"
                          onClick={() => handleReadMore(i)}
                          value={item.id}
                          id={item.ownerId}
                          key={index}
                        />
                      ) : (
                        <>
                          {file.fileURL.includes('tiktok.com') ? (
                            <iframe
                              title="Tiktok Video"
                              src={`https://www.tiktok.com/embed/${extractTikTokVideoID(
                                file.fileURL
                              )}`}
                              allowFullScreen
                              className="learning-videos"
                              allow="encrypted-media"
                            ></iframe>
                          ) : file.fileURL.includes('youtube.com') ? (
                            <iframe
                              title="YouTube Video"
                              src={file.fileURL}
                              className="learning-videos"
                            ></iframe>
                          ) : (
                            <div className="learning-videos">
                              <video
                                src={
                                  file.fileURL.startsWith('gotfl')
                                    ? `${fileprefix}${file.fileURL}&accessToken=${newToken}`
                                    : `${file.fileURL}&accessToken=${newToken}`
                                }
                                alt="mediaLinks"
                                onClick={() => handleReadMore(i)}
                                value={item.id}
                                id={item.ownerId}
                                key={index}
                              ></video>
                              <div className="video-icon-container">
                                <img
                                  className="video-icon"
                                  src={videoIcon}
                                  alt="Video Icon"
                                />
                              </div>
                            </div>
                          )}
                        </>
                      )
                    )}
                  </div>
                  <div className="expandable">
                    <ul className="card-icon-wrapper">
                      <li>
                        <FavouriteButton
                          item={item}
                          id={item.ownerId}
                          fetchData={fetchData}
                          fetchlearning={fetchlearning}
                        />
                      </li>
                      <li>{/* <img src={commentIcon} alt="" /> */}</li>
                      <li>
                        <img src={shareIcon} alt="share link" />
                      </li>
                    </ul>
                    <div className="card-dscp mt-10">{item.description}</div>
                    <div className="card-btn mt-10 mb-10">
                      <button
                        onClick={handleClick}
                        value={i}
                        id={item.id}
                        onKeyDown={(event) => {
                          if (event.key === 'Enter') {
                            setEnable(enable);
                          }
                        }}
                        role="button"
                      >
                        Read More <img src={chevronRight} alt="" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </>
        ) : isSorted || isSortedLatest || isLiked || IsMyLearning ? (
          <>
            {sortData.map((item, i) => (
              <div className="cardwrapper" key={i}>
                <div className="card-container" tabIndex={0}>
                  <div className="card" tabIndex={0}>
                    {item.mediaLinks.map((file, index) =>
                      file.fileCategory === 'img' ? (
                        <img
                          src={
                            fileprefix + file.fileURL
                              ? `${fileprefix}${file.fileURL}&accessToken=${newToken}`
                              : noImageUploaded
                          }
                          alt="mediaLinks"
                          onClick={() => handleReadMore(i)}
                          value={item.id}
                          id={item.ownerId}
                          key={index}
                        />
                      ) : (
                        <>
                          {file.fileURL.includes('tiktok.com') ? (
                            <iframe
                              title="Tiktok Video"
                              src={`https://www.tiktok.com/embed/${extractTikTokVideoID(
                                file.fileURL
                              )}`}
                              allowFullScreen
                              className="learning-videos"
                              allow="encrypted-media"
                            ></iframe>
                          ) : file.fileURL.includes('youtube.com') ? (
                            <iframe
                              title="YouTube Video"
                              src={file.fileURL}
                              className="learning-videos"
                            ></iframe>
                          ) : (
                            <div className="learning-videos">
                              <video
                                src={
                                  file.fileURL.startsWith('gotfl')
                                    ? `${fileprefix}${file.fileURL}&accessToken=${newToken}`
                                    : `${file.fileURL}&accessToken=${newToken}`
                                }
                                alt="mediaLinks"
                                onClick={() => handleReadMore(i)}
                                value={item.id}
                                id={item.ownerId}
                                key={index}
                              ></video>
                              <div className="video-icon-container">
                                <img
                                  className="video-icon"
                                  src={videoIcon}
                                  alt="Video Icon"
                                />
                              </div>
                            </div>
                          )}
                        </>
                      )
                    )}
                  </div>
                  <div className="expandable">
                    <ul className="card-icon-wrapper">
                      <li>
                        <FavouriteButton
                          item={item}
                          id={item.ownerId}
                          fetchData={fetchData}
                          fetchlearning={fetchlearning}
                        />
                      </li>
                      <li>{/* <img src={commentIcon} alt="" /> */}</li>
                      <li>
                        <img src={shareIcon} alt="" />
                      </li>
                    </ul>
                    <div className="card-dscp mt-10">{item.description}</div>
                    <div className="card-btn mt-10 mb-10">
                      <button
                        onClick={handleClick}
                        value={i}
                        id={item.id}
                        onKeyDown={(event) => {
                          if (event.key === 'Enter') {
                            setEnable(enable);
                          }
                        }}
                        role="button"
                      >
                        Read More <img src={chevronRight} alt="" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </>
        ) : nofilter ? (
          <p>No Data Found</p>
        ) : (
          <>
            {data
              .slice(0)
              .reverse()
              .map((item, i) => (
                <div className="cardwrapper" key={i}>
                  <div className="card-container" tabIndex={0}>
                    <div className="card" tabIndex={0}>
                      {item.mediaLinks.map((file, index) =>
                        file.fileCategory === 'img' ? (
                          <img
                            src={
                              fileprefix + file.fileURL
                                ? `${fileprefix}${file.fileURL}&accessToken=${newToken}`
                                : noImageUploaded
                            }
                            alt="mediaLinks"
                            onClick={() => handleReadMore(i)}
                            value={item.id}
                            id={item.ownerId}
                            key={index}
                          />
                        ) : (
                          <>
                            {file.fileURL.includes('tiktok.com') ? (
                              <iframe
                                title="Tiktok Video"
                                src={`https://www.tiktok.com/embed/${extractTikTokVideoID(
                                  file.fileURL
                                )}`}
                                allowFullScreen
                                className="learning-videos"
                                allow="encrypted-media"
                              ></iframe>
                            ) : file.fileURL.includes('youtube.com') ? (
                              <iframe
                                title="YouTube Video"
                                src={file.fileURL}
                                className="learning-videos"
                              ></iframe>
                            ) : (
                              <div className="learning-videos">
                                <video
                                  src={
                                    file.fileURL.startsWith('gotfl')
                                      ? `${fileprefix}${file.fileURL}&accessToken=${newToken}`
                                      : `${file.fileURL}&accessToken=${newToken}`
                                  }
                                  alt="mediaLinks"
                                  onClick={() => handleReadMore(i)}
                                  value={item.id}
                                  id={item.ownerId}
                                  key={index}
                                ></video>
                                <div className="video-icon-container">
                                  <img
                                    className="video-icon"
                                    src={videoIcon}
                                    alt="Video Icon"
                                  />
                                </div>
                              </div>
                            )}
                          </>
                        )
                      )}
                    </div>
                    <div className="expandable">
                      <ul className="card-icon-wrapper">
                        <li>
                          <FavouriteButton
                            item={item}
                            id={item.ownerId}
                            fetchData={fetchData}
                            fetchlearning={fetchlearning}
                          />
                        </li>
                        <li>{/* <img src={commentIcon} alt="" /> */}</li>
                        <li>
                          <img
                            src={shareIcon}
                            alt="share link"
                            onClick={() => handleShare(item, i)}
                          />
                        </li>
                      </ul>
                      <div className="card-dscp mt-10">{item.description}</div>
                      <div className="card-btn mt-10 mb-10">
                        <button
                          onClick={handleClick}
                          value={i}
                          id={item.ownerId}
                          onKeyDown={(event) => {
                            if (event.key === 'Enter') {
                              setEnable(enable);
                            }
                          }}
                          role="button"
                        >
                          Read More{' '}
                          <img
                            className="chevron-img"
                            src={chevronRight}
                            alt=""
                          />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              ))}{' '}
          </>
        )}
        <div>
          {(enable || openReadMorePopup || idValue) && (
            <MyLearningPopup
              data={
                sortData.length > 0
                  ? sortData
                  : filterData.length > 0
                    ? filterData
                    : data.slice(0).reverse()
              }
              generateCardLink={generateCardLink}
              handleClose={closeMyLearning}
              id={idValue}
              fetchData={fetchData}
              fetchlearning={fetchlearning}
              openIndex={openSliderIndex}
              handleSlider={() => setOpenSliderIndex(null)}
              fileprefix={fileprefix}
              userName={userName}
              newToken={newToken}
            />
          )}
        </div>
      </div>
      {/* </InfiniteScroll> */}
      {/* <SimplePagination
        current={pageNumber}
        pageSize={pageSize}
        handlePageChange={handlePageChange}
        total={totalCount}
      /> */}
      {showShareIcons.map(
        (showIcon, index) =>
          showIcon && (
            <ShareModal
              key={index}
              cardURL={selectedCardURL}
              onClose={() =>
                setShowShareIcons((prevState) => {
                  const newState = [...prevState];
                  newState[index] = false;
                  return newState;
                })
              }
            />
          )
      )}
    </>
  );
}

AllLearningCard.propTypes = {
  handleClose: PropTypes.func, // Add the missing prop type validation
  description: PropTypes.string,
  category: PropTypes.string,
  id: PropTypes.string,
  data: PropTypes.any,
};

export default AllLearningCard;
