import { Checkbox, Form, Input } from 'antd';
import React, { useCallback, useRef } from 'react';
import Img1Png from '../assets/img/img1.png';
import { PackageAiBtn2 as Btn } from './btn.jsx';
import Dialog from './dialog.jsx';
import style from './template.module.scss';

const { Item } = Form;

function CheckBoxWarp({ className, value, onChange, defaultValue, children }) {
  return (
    <Checkbox
      className={className}
      checked={value}
      onChange={(ev) => {
        onChange(ev.target.checked);
      }}
      defaultChecked={defaultValue}
    >
      {children}
    </Checkbox>
  );
}

function PackageAiDialog({ className = '', visible, setVisible, onSubmit }) {
  const btnRef = useRef();

  const onSubmit2 = useCallback(() => {
    if (!btnRef.current) return;
    btnRef.current.click();
  }, [btnRef.current]);

  const onFinish = useCallback(
    async (values) => {
      onSubmit && onSubmit(values);
      setVisible(false);
    },
    [onSubmit, setVisible]
  );

  const rules = {
    templateName: [{ required: true, message: 'Please input template name' }],
  };

  return (
    <Dialog className={className} visible={visible} setVisible={setVisible}>
      <div className={style.dialogInner}>
        <div className={style.titleBox}>
          <div className={style.titleImgBox}>
            <img src={Img1Png} />
          </div>
          <div className={style.titleText}>Save as Template</div>
        </div>
        <div className={style.bodyBox}>
          <Form
            layout="vertical"
            initialValues={{ isPublic: true }}
            onFinish={onFinish}
          >
            <Item
              label="Template Name"
              name="templateName"
              rules={rules.templateName}
            >
              <Input className={style.formInput} />
            </Item>
            <Item name="isPublic">
              <CheckBoxWarp>
                Publish my template in the Sous-Chef library (visible for
                others). Deselect it for keeping template only visible to you
              </CheckBoxWarp>
            </Item>
            <Item className={style.btnRow}>
              <Btn className={style.submitBtn} onClick={onSubmit2}>
                Submit
              </Btn>
              <button ref={btnRef} style={{ display: 'none' }} type="submit" />
            </Item>
          </Form>
        </div>
      </div>
    </Dialog>
  );
}

export default PackageAiDialog;
