import React, { useState, forwardRef, useEffect } from 'react';
import style from './side-detail.module.scss';
import BorderContainer from '../border-container/border-container.jsx';
import classNames from 'classnames';
import { triangleRight, cancelGreenIcon, trendingDown, trendingup } from '../../../../components/icons/icons.jsx';

// cancel-green-icon

// eslint-disable-next-line no-unused-vars
let SideDetail = ({show, closeCallback, solutionClick, consumerClick, sideDetailData, country }, ref) => {

  const { title, cagr, yoy, solutionTrends, consumerTrends } = sideDetailData;
  const cagrDown = cagr.value < 0 ? true : false;
  const yoyDown = yoy.value < 0 ? true : false;
  const [titleOne, setTitleOne] = useState('');

  useEffect(() => {
    if (!show) return;
    setTitleOne(country === 'US' ? 'Category Value Driver' : 'Emerging Food Solution Trends');
  }, [show]);

  const capitalizeEachWord = (str) => {
    const words = str.split(' ');
    const capitalizedWords = words.map(
      (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
    );
    return capitalizedWords.join(' ');
  };
  const capitalizedName = capitalizeEachWord(title);

  const numCompute = (val) => {
    return Math.floor(val * 100) / 100;
  };

  return (
    <div className={classNames(style.sideDetailContainer, {[style.show]: show})}>
      <BorderContainer small>
        <div className={style.sideDetailBox}>
          <div className={style.sideDetailTitle}>
            <div className={style.arrow}>
              <img src={triangleRight} alt="" />
            </div>
            <div className={style.titleBox}>{title}</div>
            <div className={style.closeBtn} onClick={() => closeCallback()}>
              <img src={cancelGreenIcon} alt="" />
            </div>
          </div>
          <div className={style.mainBox}>
            <div className={style.tipBox}>
              <div className={style.tipBoxInner}>What is our customer doing for {capitalizedName}？</div>
            </div>
            <div className={style.dataBox}>
              {/* dataBoxItem */}
              <div className={classNames(style.dataBoxItem, {[style.down]: cagrDown})}>
                <div className={style.boxItemTop}>
                  <div className={style.dataLeft}>
                    <div className={style.dataName}>CAGR</div>
                  </div>
                  <div className={style.dataRight}>
                    <div className={style.rateBox}>
                      <div className={style.rateBoxInner}>{numCompute(cagr.value)}%</div>
                    </div>
                    <div className={style.trendBox}>
                      {cagrDown ? <img src={trendingDown} alt="" /> : <img src={trendingup} alt="" />}
                    </div>
                  </div>
                </div>
                <div className={style.dataTime}>
                  <div className={style.dataTimeInner}>{cagr.date}</div>
                </div>
              </div>
              <div className={classNames(style.dataBoxItem, {[style.down]: yoyDown})}>
                <div className={style.boxItemTop}>
                  <div className={style.dataLeft}>
                    <div className={style.dataName}>CAGR GR</div>
                  </div>
                  <div className={style.dataRight}>
                    <div className={style.rateBox}>
                      <div className={style.rateBoxInner}>{numCompute(yoy.value)}%</div>
                    </div>
                    <div className={style.trendBox}>
                      {yoyDown ? <img src={trendingDown} alt="" /> : <img src={trendingup} alt="" />}
                    </div>
                  </div>
                </div>
                <div className={style.dataTime}>
                  <div className={style.dataTimeInner}>{yoy.date}</div>
                </div>
              </div>
            </div>
            {/* 第一部分 */}
            <div className={style.subTitle}>
              <div className={style.subTitleInner}>{titleOne}</div>
            </div>
            <div className={style.lineBox}></div>
            {/* 1 */}
            {solutionTrends.map(item => {
              return (<div key={item.name} className={classNames(style.trendDataItem)}>
                <div className={classNames(style.trendDataItemName,  style.trendDataItemNameUnique)}>
                  <div className={style.trendDataItemNameInner}>
                    <span className={style.trendDataItemNameInnerText} onClick={() => solutionClick(item.name)}>{item.name}</span>
                  </div>
                </div>
              </div>);
            })}
            {/* 第二部分 */}
            <div className={style.subTitle}>
              <div className={style.subTitleInner}>Consumer Trends</div>
            </div>
            <div className={style.lineBox}></div>
            {consumerTrends.map(item => {
              return (<div key={item.name} className={style.trendDataItem}>
                <div className={style.trendDataItemName} title={item.name}>
                  <div className={style.trendDataItemNameInner}>
                    <span className={style.trendDataItemNameInnerText} onClick={() => consumerClick(item.name)}>{item.name}</span>
                  </div>
                </div>
                <div className={style.trendDataRate}>
                  <div className={classNames(style.trendDataRateItem, {[style.down]: item.cagr < 0})}>
                    <div className={style.trendDataRateItemTitle}>
                      <div className={style.trendDataRateItemTitleInner}><span>CAGR</span><span>{numCompute(item.cagr)}%</span></div>
                    </div>
                    <div className={style.trendDataRateItemArrow}>
                      {item.cagr < 0 ? <img src={trendingDown} alt="" /> : <img src={trendingup} alt="" />}
                    </div>
                  </div>
                  <div className={classNames(style.trendDataRateItem, style.trendDataRateItemLong, {[style.down]: item.yoy < 0})}>
                    <div className={style.trendDataRateItemTitle}>
                      <div className={style.trendDataRateItemTitleInner}><span>CAGR GR</span><span>{numCompute(item.yoy)}%</span></div>
                    </div>
                    <div className={style.trendDataRateItemArrow}>
                      {item.yoy < 0 ? <img src={trendingDown} alt="" /> : <img src={trendingup} alt="" />}
                    </div>
                  </div>
                </div>
              </div>);
            })}
          </div>
        </div>
      </BorderContainer>
    </div>
  );
};

SideDetail = forwardRef(SideDetail);

export default SideDetail;