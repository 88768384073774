import React, { useState } from 'react';
import { Input } from 'antd';
import style from './editableText.module.scss';
import PenSvg from '../assets/img/pen.svg';

function PackageAiEditableText({ className = '', value, onChange }) {
  const [isEdit, setIsEdit] = useState(false);

  function onInputChange(ev) {
    if (!ev.target) return;
    onChange && onChange(ev.target.value);
  }

  return (
    <div
      className={className}
      onClick={() => setIsEdit(true)}
      onBlur={() => setIsEdit(false)}
    >
      {isEdit ? (
        <Input value={value} onChange={onInputChange} />
      ) : (
        <div className={style.header}>
          <img className={style.penImg} src={PenSvg} alt="Edit" />
          <div className={style.headerBody}>{value}</div>
        </div>
      )}
    </div>
  );
}

export default PackageAiEditableText;
