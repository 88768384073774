/* eslint-disable indent */
import { ArrowRightOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { Col, Row } from 'antd';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { PackageAiBtn2 as Btn2 } from '../../components/btn.jsx';
import Dialog from '../../components/dialog.jsx';
import style from './dialog.module.scss';
import Editor from './editor.jsx';
import { checkAoi } from './checkAoi';

function PackageAiDialog({
  visible,
  setVisible,
  data,
  aoi,
  setAoi,
  onHelpClick,
}) {
  const [localAoi, setLocalAoi] = useState(aoi);
  useEffect(() => {
    if (!visible) return;
    setLocalAoi(aoi);
  }, [visible]);

  const aoi1 = useMemo(() => {
    return localAoi.brand;
  }, [localAoi]);
  const setAoi1 = useCallback(
    (a) => {
      setLocalAoi({
        ...localAoi,
        brand: a,
      });
    },
    [localAoi, setLocalAoi]
  );

  const aoi2 = useMemo(() => {
    return localAoi.variant;
  }, [localAoi]);
  const setAoi2 = useCallback(
    (a) => {
      setLocalAoi({
        ...localAoi,
        variant: a,
      });
    },
    [localAoi, setLocalAoi]
  );

  const aoi3 = useMemo(() => {
    return localAoi.brand2;
  }, [localAoi]);
  const setAoi3 = useCallback(
    (a) => {
      setLocalAoi({
        ...localAoi,
        brand2: a,
      });
    },
    [localAoi, setLocalAoi]
  );

  const aoi4 = useMemo(() => {
    return localAoi.variant2;
  }, [localAoi]);
  const setAoi4 = useCallback(
    (a) => {
      setLocalAoi({
        ...localAoi,
        variant2: a,
      });
    },
    [localAoi, setLocalAoi]
  );

  const onConfirm = useCallback(() => {
    setVisible(false);

    const na = { ...localAoi };
    na.complete = checkAoi(na.brand) && checkAoi(na.variant);
    setAoi(na);
  }, [setVisible, localAoi, setAoi]);

  if (!data) return null;

  return (
    <Dialog
      className={style.dialogBox}
      visible={visible}
      setVisible={() => setVisible(false)}
    >
      <div className={style.dialogBoxInner}>
        <Row className={style.dialogContentBox} gutter={48}>
          <Col
            className={`${style.dialogContentUnit} ${style.dialogTitleBox}`}
            span={6}
          >
            <div className={style.dialogTitle}>{data.title}</div>
            <div className={style.dialogTitleImageBox}>
              <img src={data.src} alt={data.title} crossOrigin="anonymous" />
            </div>
          </Col>
          <Col className={style.dialogContentUnit} span={18}>
            <div className={style.dialogInfo}>
              In this section, click each pack image to highlight the key design
              elements and DBAs on the pack
            </div>
            <Row className={style.dialogLine} gutter={70}>
              <Col className={style.rightLine} span={12}>
                <Row gutter={48}>
                  <Col span={12}>
                    <Editor
                      data={data}
                      aoi={aoi1}
                      setAoi={setAoi1}
                      footText="Position the yellow box over the brand logo on the pack design."
                      allowNull={false}
                    />
                  </Col>
                  <Col span={12}>
                    <Editor
                      data={data}
                      aoi={aoi2}
                      setAoi={setAoi2}
                      footText="Position the yellow box over the variant name."
                      allowNull={false}
                    />
                  </Col>
                </Row>
              </Col>
              <Col span={12}>
                <Row gutter={48}>
                  <Col span={12}>
                    <Editor
                      data={data}
                      aoi={aoi3}
                      setAoi={setAoi3}
                      footText="Position the yellow box over any other brand element, image, or DBA. Skip if there aren’t any."
                    />
                  </Col>
                  <Col span={12}>
                    <Editor
                      data={data}
                      aoi={aoi4}
                      setAoi={setAoi4}
                      footText="Position the yellow box over any other variant level design element or image. Skip if there aren’t any."
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
        <div className={style.btnBox}>
          <Btn2 onClick={onConfirm} suffix={<ArrowRightOutlined />}>
            CONFIRM
          </Btn2>
        </div>
        <QuestionCircleOutlined className={style.help} onClick={onHelpClick} />
      </div>
    </Dialog>
  );
}

export default PackageAiDialog;
