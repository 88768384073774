import React, { useEffect, useRef } from 'react';
import style from './index.module.scss';

function TT10View() {
  const aRef = useRef();

  useEffect(() => {
    setTimeout(() => {
      if (aRef) aRef.current.click();
    }, 100);
  }, []);

  return (
    <div>
      <a
        ref={aRef}
        href="https://app.powerbi.com/Redirect?action=OpenReport&appId=f2d57ad3-7f7a-4e32-ade2-e89cad2803fe&reportObjectId=1bbaf4b6-ea0b-4f52-8b38-0077e1786564&ctid=f66fae02-5d36-495b-bfe0-78a6ff9f8e6e&reportPage=ReportSectiond31e110838dac14d9379&pbi_source=appShareLink&portalSessionId=ed759c57-f3a1-4328-8b37-78fad66aa414"
      >
        <h1 className={style.title}>Loading....</h1>
      </a>
    </div>
  );
}

export default TT10View;
