// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NDzekf2JqrQWF3wnrjd6{width:40px}`, "",{"version":3,"sources":["webpack://./src/modules/package-ai-performance/components/flag/index.module.scss"],"names":[],"mappings":"AAAA,sBACE,UAAA","sourcesContent":[".flag {\n  width: 40px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"flag": `NDzekf2JqrQWF3wnrjd6`
};
export default ___CSS_LOADER_EXPORT___;
