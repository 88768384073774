import { useMemo, useCallback } from 'react';

function buildId({ market, sn }) {
  return `${market}-${sn}`;
}

export function useVerticalRank(originData) {
  const verticalRankData = useMemo(() => {
    const verticalRankMap = new Map();

    // loop all product
    for (const d of originData) {
      if (verticalRankMap.has(d.vertical)) {
        verticalRankMap.get(d.vertical).push(d);
      } else {
        verticalRankMap.set(d.vertical, [d]);
      }
    }

    const newRank = () => ({
      brand_logo_visibility: [],
      variant_name_visibility: [],
      appeal: [],
      upi: [],

      taste: [],
      premium: [],
      natural: [],
      value: [],
      sustainable: [],
      modernity: [],
      excitement: [],
      desirability: [],
    });

    const sortRank = (list) => {
      const sl = list.sort((a, b) => b.value - a.value);
      const ll = list.length;

      const map = new Map();
      for (let i = 0; i < ll; i++) {
        map.set(buildId(sl[i]), i / ll);
      }

      return map;
    };

    for (const [key, value] of verticalRankMap) {
      const rank = newRank();
      for (const v of value) {
        const vv = { market: v.market, sn: v.sn };

        rank.brand_logo_visibility.push({
          ...vv,
          value: v.brand_logo_visibility,
        });
        rank.variant_name_visibility.push({
          ...vv,
          value: v.variant_name_visibility,
        });
        rank.appeal.push({ ...vv, value: v.appeal });
        rank.upi.push({ ...vv, value: v.upi });

        rank.taste.push({ ...vv, value: v.taste });
        rank.premium.push({ ...vv, value: v.premium });
        rank.natural.push({ ...vv, value: v.natural });
        rank.value.push({ ...vv, value: v.value });
        rank.sustainable.push({ ...vv, value: v.sustainable });
        rank.modernity.push({ ...vv, value: v.modernity });
        rank.excitement.push({ ...vv, value: v.excitement });
        rank.desirability.push({ ...vv, value: v.desirability });
      }

      rank.brand_logo_visibility = sortRank(rank.brand_logo_visibility);
      rank.variant_name_visibility = sortRank(rank.variant_name_visibility);
      rank.appeal = sortRank(rank.appeal);
      rank.upi = sortRank(rank.upi);

      rank.taste = sortRank(rank.taste);
      rank.premium = sortRank(rank.premium);
      rank.natural = sortRank(rank.natural);
      rank.value = sortRank(rank.value);
      rank.sustainable = sortRank(rank.sustainable);
      rank.modernity = sortRank(rank.modernity);
      rank.excitement = sortRank(rank.excitement);
      rank.desirability = sortRank(rank.desirability);

      verticalRankMap.set(key, rank);
    }

    return verticalRankMap;
  }, [originData]);

  const getVerticalRank = useCallback(
    (vertical, key, market, sn) => {
      if (!key) return 1;
      // console.log('vertical', dv);
      const vv = verticalRankData.get(vertical);
      // console.log('vrd', vv);
      if (!vv) return 1;
      const vvv = vv[key];
      // console.log('vvv', vvv);
      if (!vvv) return 1;
      const vvvv = vvv.get(buildId({ market, sn }));
      // console.log('vvvv', vvvv);
      return vvvv ?? 1;
    },
    [verticalRankData]
  );

  return {
    verticalRankData,
    getVerticalRank,
  };
}
