import { Input, Select } from 'antd';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { getFilter } from '../../api';
import style from './index.module.scss';

const GENERATION_OPTIONS = [
  { label: 'Gen X', value: 'Gen X' },
  { label: 'Millennials', value: 'Millennials' },
  { label: 'Gen Z', value: 'Gen Z' },
  { label: 'Baby Boomers', value: 'Baby Boomers' },
];
const GENDER_OPTIONS = [
  { label: 'Male', value: 'Male' },
  { label: 'Female', value: 'Female' },
];
const USER_VS_NONE_USER_OPTIONS = [
  { label: 'Users', value: 'Users' },
  { label: 'Non-Users', value: 'Non-Users' },
];
const SOCIAL_CLASS_OPTIONS = [
  { label: 'Low Income', value: 'Low Income' },
  { label: 'Middle Income', value: 'Middle Income' },
  { label: 'High Income', value: 'High Income' },
];

function filterOption(input, option) {
  return (option?.label ?? '').toLowerCase().includes(input.toLowerCase());
}

function buildOptions(options) {
  return options.map((item) => ({
    label: item,
    value: item,
  }));
}

function PackageAiFilters({
  className = '',
  showSearch = true,
  search,
  setSearch = () => {},
  showFilter = true,
  data = {},
  setData = () => {},
  showSubFilters = true,
  subData = {},
  setSubData = () => {},
}) {
  const [marketOptions, setMarketOptions] = useState([]);
  const [marketData, setMarketData] = useState([]);

  const [verticalOptions, setVerticalOptions] = useState([]);
  const [verticalData, setVerticalData] = useState([]);

  const [categoryOptions, setCategoryOptions] = useState([]);
  const [categoryData, setCategoryData] = useState([]);

  const [subCategoryOptions, setSubCategoryOptions] = useState([]);
  const [subCategoryData, setSubCategoryData] = useState([]);

  const [companyOptions1, setCompanyOptions1] = useState([]);
  const [companyOptions, setCompanyOptions] = useState([]);
  const [companyData, setCompanyData] = useState([]);

  const postCompanyData = useMemo(() => {
    if (!companyData?.length) return [];
    else if (companyData.length === 1) {
      if (companyData[0] === 'Unilever') {
        return ['Unilever'];
      } else {
        return companyOptions1.filter((e) => e !== 'Unilever');
      }
    } else {
      if (companyData.includes('Unilever')) {
        return companyOptions1;
      } else {
        return companyOptions1.filter((e) => e !== 'Unilever');
      }
    }
  }, [companyData, companyOptions1]);

  const [brandOptions, setBrandOptions] = useState([]);
  const [brandData, setBrandData] = useState([]);

  const [packFormatOptions, setPackFormatOptions] = useState([]);
  const [packFormatData, setPackFormatData] = useState([]);

  const getMarketOptions = useCallback(async () => {
    const { data } = await getFilter('market');
    setMarketOptions(buildOptions(data));
  }, [setMarketOptions]);
  useEffect(() => {
    getMarketOptions().catch((err) => {
      console.error('get market options error', err);
    });
  }, [getMarketOptions]);

  const getVerticalOptions = useCallback(async () => {
    const { data } = await getFilter('vertical', { market: marketData });
    setVerticalOptions(buildOptions(data));
  }, [marketData, setVerticalOptions]);
  useEffect(() => {
    getVerticalOptions().catch((err) => {
      console.error('get vertical options error', err);
    });
  }, [getVerticalOptions]);

  const getCategoryOptions = useCallback(async () => {
    const { data } = await getFilter('category', {
      market: marketData,
      vertical: verticalData,
    });
    setCategoryOptions(buildOptions(data));
  }, [marketData, verticalData, setCategoryOptions]);
  useEffect(() => {
    getCategoryOptions().catch((err) => {
      console.error('get category options error', err);
    });
  }, [getCategoryOptions]);

  const getSubCategoryOptions = useCallback(async () => {
    const { data } = await getFilter('sub_category', {
      market: marketData,
      vertical: verticalData,
      category: categoryData,
    });
    setSubCategoryOptions(buildOptions(data));
  }, [marketData, verticalData, categoryData, setSubCategoryOptions]);
  useEffect(() => {
    getSubCategoryOptions().catch((err) => {
      console.error('get sub category options error', err);
    });
  }, [getSubCategoryOptions]);

  const getCompanyOptions = useCallback(async () => {
    const { data } = await getFilter('company', {
      market: marketData,
      vertical: verticalData,
      category: categoryData,
      sub_category: subCategoryData,
    });
    setCompanyOptions1(data);

    if (data.length === 0) {
      setCompanyOptions([]);
    } else if (data.length === 1) {
      if (data[0] === 'Unilever') {
        setCompanyData([{ label: 'Unilever', value: 'Unilever' }]);
      } else {
        setCompanyOptions([{ label: 'Competitors', value: 'Competitors' }]);
      }
    } else {
      if (data.includes('Unilever')) {
        setCompanyOptions([
          { label: 'Unilever', value: 'Unilever' },
          { label: 'Competitors', value: 'Competitors' },
        ]);
      } else {
        setCompanyOptions([{ label: 'Competitors', value: 'Competitors' }]);
      }
    }
  }, [
    marketData,
    verticalData,
    categoryData,
    subCategoryData,
    setCompanyOptions,
  ]);
  useEffect(() => {
    getCompanyOptions().catch((err) => {
      console.error('get company options error', err);
    });
  }, [getCompanyOptions]);

  const getBrandOptions = useCallback(async () => {
    const { data } = await getFilter('brand', {
      market: marketData,
      vertical: verticalData,
      category: categoryData,
      sub_category: subCategoryData,
      company: postCompanyData,
    });
    setBrandOptions(buildOptions(data));
  }, [
    marketData,
    verticalData,
    categoryData,
    subCategoryData,
    postCompanyData,
    setBrandOptions,
  ]);
  useEffect(() => {
    getBrandOptions().catch((err) => {
      console.error('get brand options error', err);
    });
  }, [getBrandOptions]);

  const getPackformatOptions = useCallback(async () => {
    const { data } = await getFilter('physical_pack_format', {
      market: marketData,
      vertical: verticalData,
      category: categoryData,
      sub_category: subCategoryData,
      company: postCompanyData,
      brand: brandData,
    });
    setPackFormatOptions(buildOptions(data));
  }, [
    marketData,
    verticalData,
    categoryData,
    subCategoryData,
    postCompanyData,
    brandData,
    setPackFormatOptions,
  ]);
  useEffect(() => {
    getPackformatOptions().catch((err) => {
      console.error('get pack format options error', err);
    });
  }, [getPackformatOptions]);

  const onMarketChange = useCallback(
    (val) => {
      setMarketData(val);
      setVerticalData([]);
      setCategoryData([]);
      setSubCategoryData([]);
      setCompanyData([]);
      setBrandData([]);
      setPackFormatData([]);
    },
    [
      setMarketData,
      setVerticalData,
      setCategoryData,
      setSubCategoryData,
      setCompanyData,
      setBrandData,
      setPackFormatData,
    ]
  );

  const onVerticalChange = useCallback(
    (val) => {
      setVerticalData(val);
      setCategoryData([]);
      setSubCategoryData([]);
      setCompanyData([]);
      setBrandData([]);
      setPackFormatData([]);
    },
    [
      setVerticalData,
      setCategoryData,
      setSubCategoryData,
      setCompanyData,
      setBrandData,
      setPackFormatData,
    ]
  );

  const onCategoryChange = useCallback(
    (val) => {
      setCategoryData(val);
      setSubCategoryData([]);
      setCompanyData([]);
      setBrandData([]);
      setPackFormatData([]);
    },
    [
      setCategoryData,
      setSubCategoryData,
      setCompanyData,
      setBrandData,
      setPackFormatData,
    ]
  );

  const onSubCategoryChange = useCallback(
    (val) => {
      setSubCategoryData(val);
      setCompanyData([]);
      setBrandData([]);
      setPackFormatData([]);
    },
    [setSubCategoryData, setCompanyData, setBrandData, setPackFormatData]
  );

  const onCompanyChange = useCallback(
    (val) => {
      setCompanyData(val);
      setBrandData([]);
      setPackFormatData([]);
    },
    [setCompanyData, setBrandData, setPackFormatData]
  );

  const onBrandChange = useCallback(
    (val) => {
      setBrandData(val);
      setPackFormatData([]);
    },
    [setBrandData, setPackFormatData]
  );
  const onPackFormatChange = useCallback(
    (val) => {
      setPackFormatData(val);
    },
    [setPackFormatData]
  );

  const postData = useMemo(() => {
    const d = {};
    if (marketData.length) d.market = marketData;
    if (verticalData.length) d.vertical = verticalData;
    if (categoryData.length) d.category = categoryData;
    if (subCategoryData.length) d.sub_category = subCategoryData;
    if (postCompanyData.length) d.company = postCompanyData;
    if (brandData.length) d.brand = brandData;
    if (packFormatData.length) d.physical_pack_format = packFormatData;
    return d;
  }, [
    marketData,
    verticalData,
    categoryData,
    subCategoryData,
    postCompanyData,
    brandData,
    packFormatData,
  ]);
  useEffect(() => {
    setData(postData);
  }, [postData]);

  const onClearData = useCallback(() => {
    onMarketChange([]);
  }, [onMarketChange]);

  const onClearSubData = useCallback(() => {
    setSubData({});
  }, [setSubData]);

  const subFilterDisplay = useMemo(() => {
    const sfd = {
      generation: false,
      gender: false,
      user_vs_nouser: false,
      social_class: false,
    };
    const oks = Object.keys(subData);
    if (oks.length === 0) {
      sfd.generation = true;
      sfd.gender = true;
      sfd.user_vs_nouser = true;
      sfd.social_class = true;
    } else {
      sfd[oks[0]] = true;
    }
    return sfd;
  }, [subData]);

  return (
    <div className={`${style.searchWrap} ${className}`}>
      {/* Search */}
      {showSearch && (
        <div className={style.searchInputBox}>
          <Input
            className={style.searchInput}
            placeholder="Search"
            onChange={setSearch}
            value={search}
          />
        </div>
      )}
      {/* Pack Filter */}
      {showFilter && (
        <>
          <div className={style.title}>
            <s className={style.icon}></s>
            <span className={style.text}>Pack Filter</span>
            <div className={style.clearBtn} onClick={onClearData}>
              Clear
            </div>
          </div>
          <h5 className={style.searchLabel}>Market</h5>
          <div className={style.myInput}>
            <Select
              placeholder="Market"
              mode="multiple"
              filterOption={filterOption}
              value={marketData}
              onChange={onMarketChange}
              options={marketOptions}
            />
          </div>
          <h5 className={style.searchLabel}>Vertical</h5>
          <div className={style.myInput}>
            <Select
              placeholder="Vertical"
              mode="multiple"
              filterOption={filterOption}
              value={verticalData}
              onChange={onVerticalChange}
              options={verticalOptions}
            />
          </div>
          <h5 className={style.searchLabel}>Category</h5>
          <div className={style.myInput}>
            <Select
              placeholder="Category"
              mode="multiple"
              filterOption={filterOption}
              value={categoryData}
              onChange={onCategoryChange}
              options={categoryOptions}
            />
          </div>
          <h5 className={style.searchLabel}>Sub Category</h5>
          <div className={style.myInput}>
            <Select
              placeholder="Sub Category"
              mode="multiple"
              filterOption={filterOption}
              value={subCategoryData}
              onChange={onSubCategoryChange}
              options={subCategoryOptions}
            />
          </div>
          <h5 className={style.searchLabel}>Company</h5>
          <div className={style.myInput}>
            <Select
              placeholder="Company"
              mode="multiple"
              filterOption={filterOption}
              value={companyData}
              onChange={onCompanyChange}
              options={companyOptions}
            />
          </div>
          <h5 className={style.searchLabel}>Brand</h5>
          <div className={style.myInput}>
            <Select
              placeholder="Brand"
              mode="multiple"
              filterOption={filterOption}
              value={brandData}
              onChange={onBrandChange}
              options={brandOptions}
            />
          </div>
          <h5 className={style.searchLabel}>Pack format</h5>
          <div className={style.myInput}>
            <Select
              placeholder="Pack format"
              mode="multiple"
              filterOption={filterOption}
              value={packFormatData}
              onChange={onPackFormatChange}
              options={packFormatOptions}
            />
          </div>
        </>
      )}
      {/* Subgroup Filters */}
      {showSubFilters && (
        <>
          <div className={style.title}>
            <s className={style.icon}></s>
            <span className={style.text}>Subgroup Filters</span>
            <div className={style.clearBtn} onClick={onClearSubData}>
              Clear
            </div>
          </div>
          {subFilterDisplay.generation && (
            <>
              <h5 className={style.searchLabel}>Generation</h5>
              <div className={style.myInput}>
                <Select
                  placeholder="Generation"
                  mode="multiple"
                  filterOption={filterOption}
                  value={subData.generation}
                  onChange={(val) => {
                    if (!val.length) {
                      setSubData({});
                    } else {
                      setSubData({ generation: val });
                    }
                  }}
                  options={GENERATION_OPTIONS}
                />
              </div>
            </>
          )}
          {subFilterDisplay.gender && (
            <>
              <h5 className={style.searchLabel}>Gender</h5>
              <div className={style.myInput}>
                <Select
                  placeholder="Gender"
                  mode="multiple"
                  filterOption={filterOption}
                  value={subData.gender}
                  onChange={(val) => {
                    if (!val.length) {
                      setSubData({});
                    } else {
                      setSubData({ gender: val });
                    }
                  }}
                  options={GENDER_OPTIONS}
                />
              </div>
            </>
          )}
          {subFilterDisplay.user_vs_nouser && (
            <>
              <h5 className={style.searchLabel}>Category Users vs Non-Users</h5>
              <div className={style.myInput}>
                <Select
                  placeholder="Category Users vs Non-Users"
                  mode="multiple"
                  value={subData.user_vs_nouser}
                  onChange={(val) => {
                    if (!val.length) {
                      setSubData({});
                    } else {
                      setSubData({ user_vs_nouser: val });
                    }
                  }}
                  options={USER_VS_NONE_USER_OPTIONS}
                />
              </div>
            </>
          )}
          {subFilterDisplay.social_class && (
            <>
              <h5 className={style.searchLabel}>Social Class</h5>
              <div className={style.myInput}>
                <Select
                  placeholder="Social Class"
                  mode="multiple"
                  filterOption={filterOption}
                  value={subData.social_class}
                  onChange={(val) => {
                    if (!val.length) {
                      setSubData({});
                    } else {
                      setSubData({ social_class: val });
                    }
                  }}
                  options={SOCIAL_CLASS_OPTIONS}
                />
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
}

export default PackageAiFilters;
