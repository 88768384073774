import request from './fetch';

// add get-in-touch message
export function postMessage (data){
  return request({
    url: '/v1/get-in-touch/message',
    method: 'post',
    data
  });
}

// me API
export function getUser (){
  return request({
    url: '/v1/me',
    method: 'get',
  });
}