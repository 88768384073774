import request from './fetch';

// Lookup
export function fetchLookup () {
  return request({
    url: 'v1/tdti/lookup',
    method: 'get',
  });
}

export function fetchTopDishIndex (data) {
  return request({
    url: 'v1/tdti/topdish/index',
    method: 'post',
    data
  });
}

export function fetchGrowth (data) {
  return request({
    url: 'v1/tdti/topdish/insights/growth',
    method: 'post',
    data
  });
}
export function fetchPost (data) {
  return request({
    url: 'v1/tdti/topdish/insights/post',
    method: 'post',
    data
  });
}

export function fetchWordCloud(data) {
  return request({
    url: 'v1/tdti/wordcloud/',
    method: 'post',
    data
  });
}