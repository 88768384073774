import React, { useState } from 'react';
import AIChatPopup from './components/ai-chat-box/index.jsx';
import { RootProvider } from './hooks/useRoot.jsx';
import style from './index.module.scss';
import PreviewVideo from './components/preview-video/index.jsx';

const AIChatForConsumer = ({
  children,
  className=''
}) => {
  const [showWin, setShowWin] = useState(false);
  return <>
    <div className={`${className}`} onClick={() => setShowWin(true)}>
      {
        children ? children : <div className={style.bubbleBox}>ASK GPT</div>
      }
    </div>
    <RootProvider>
      <AIChatPopup showWin={showWin} setShowWin={setShowWin}></AIChatPopup>
      <PreviewVideo></PreviewVideo>
    </RootProvider>
  </>;
};

export default AIChatForConsumer;