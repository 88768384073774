// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LcEx1gorsaNJIC4nwbOi{height:80px;box-shadow:0px 2px 10px 0px #000}.CVfeLnR46jLUraLWcVpv{position:fixed;left:0;right:0;top:0;z-index:10;display:flex;justify-content:space-between;align-items:center;padding:0 20px;background-color:#146d1e}.WU9snfXh1AredX_f1Uzh{font-family:"Unilever Shilling";font-style:normal;font-weight:900;font-size:24px;line-height:33px;color:#fff}`, "",{"version":3,"sources":["webpack://./src/modules/pain-points/components/header/header.module.scss"],"names":[],"mappings":"AAAA,sBACE,WAAA,CACA,gCAAA,CAGF,sBACE,cAAA,CACA,MAAA,CACA,OAAA,CACA,KAAA,CACA,UAAA,CAEA,YAAA,CACA,6BAAA,CACA,kBAAA,CACA,cAAA,CAEA,wBAAA,CAGF,sBACE,+BAAA,CACA,iBAAA,CACA,eAAA,CACA,cAAA,CACA,gBAAA,CAEA,UAAA","sourcesContent":[".headerHeight {\n  height: 80px;\n  box-shadow: 0px 2px 10px 0px black;\n}\n\n.headerWrap {\n  position: fixed;\n  left: 0;\n  right: 0;\n  top: 0;\n  z-index: 10;\n\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  padding: 0 20px;\n\n  background-color: #146d1e;\n}\n\n.headerText {\n  font-family: 'Unilever Shilling';\n  font-style: normal;\n  font-weight: 900;\n  font-size: 24px;\n  line-height: 33px;\n\n  color: #ffffff;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"headerHeight": `LcEx1gorsaNJIC4nwbOi`,
	"headerWrap": `CVfeLnR46jLUraLWcVpv`,
	"headerText": `WU9snfXh1AredX_f1Uzh`
};
export default ___CSS_LOADER_EXPORT___;
