import request from './fetch';

// login
export function login (data) {
  return request({
    url: '/v1/auth/login',
    method: 'post',
    data
  });
}


// brand-performance-category-performance
export const painPointGetList = (params = {}) => {
  // return request({
  //   url: '/v1/pain-point/list',
  //   method: 'get',
  //   params
  // });
  let returnData = []
  for (let key in testdata) {
    returnData.push({
      id: key,
      needTitle: testdata[key].title,
      needDes: testdata[key].des,
      Q9_3_Q10_2:Math.ceil(Math.random()*100),
      Q9_4_Q10_3:Math.ceil(Math.random()*100),
      z:Math.ceil(Math.random()*50)
      // ...testdata[key]
    })
  }
  return {data:returnData};
};

// brand-performance-category-performance
export const painPointGetDetail = (params = {}) => {
  let returnData = {}
  returnData.id = params.id
  returnData.title = params.needTitle
  returnData.list = []
  returnData.pies = [Math.ceil(Math.random()*100),Math.ceil(Math.random()*100),Math.ceil(Math.random()*100)]
  returnData.needState = [Math.ceil(Math.random()*100),Math.ceil(Math.random()*100)]
  returnData.generation = [Math.ceil(Math.random()*100),Math.ceil(Math.random()*100),Math.ceil(Math.random()*100),Math.ceil(Math.random()*100)]
  returnData.lifeStage = [Math.ceil(Math.random()*100),Math.ceil(Math.random()*100),Math.ceil(Math.random()*100),Math.ceil(Math.random()*100),Math.ceil(Math.random()*100),Math.ceil(Math.random()*100)]
  returnData.productUsage = [Math.ceil(Math.random()*100),Math.ceil(Math.random()*100),Math.ceil(Math.random()*100),Math.ceil(Math.random()*100),Math.ceil(Math.random()*100),Math.ceil(Math.random()*100)]
  returnData.contemporaryCooks = [Math.ceil(Math.random()*100),Math.ceil(Math.random()*100)]
  let testNeedData = []
  for (let key in testdata) {
    returnData.list.push(testdata[key].des)
    testNeedData.push({
      id: key,
      needTitle: testdata[key].title,
      needDes: testdata[key].des,
      Q9_3_Q10_2:Math.ceil(Math.random()*100),
      Q9_4_Q10_3:Math.ceil(Math.random()*100),
      z:Math.ceil(Math.random()*50)
      // ...testdata[key]
    })
  }
  return {data:returnData};
  // return request({
  //   url: '/v1/pain-point/detail',
  //   method: 'get',
  //   params
  // });
};


const testdata = {
  "Need1": {
      "title": "Sensory Enrichment",
      "des": " Enhancing the sensory aspects of pasta dishes through aromatic herbs or visually appealing pasta shapes could meet consumer aspirations"
  },
  "Need2": {
      "title": "Visual Appeal of the Dish",
      "des": " Solutions that help achieve a more consistently appealing presentation of the final dish are sought after"
  },
  "Need3": {
      "title": "Sensory Satisfaction",
      "des": " Innovations that can guarantee a delicious and visually appealing dish every time are sought to meet consumer desires for sensory satisfaction"
  },
  "Need4": {
      "title": "Visual Appeal and Presentation",
      "des": " Innovations in pasta shapes, colors, or packaging that enhance the visual presentation of the dish could appeal to consumer needs for aesthetic appeal"
  },
  "Need5": {
      "title": "Sensory Appeal",
      "des": " Vibrant, colorful pasta or ingredients that enhance the sensory appeal of the dish, making it delicious, visually appealing, and aromatic, are desired"
  },
  "Need6": {
      "title": "Aesthetic and Modern Presentation",
      "des": " Innovative pasta shapes, colors, or packaging that enhances the overall dining experience and aligns with modern culinary trends are desired"
  },
  "Need7": {
      "title": "Homemade Taste and Aesthetic Appeal",
      "des": " The desire for pasta dishes that look fresh and homemade suggests that consumers are seeking products that help achieve a gourmet look and taste at home"
  },
  "Need8": {
      "title": "Cooking Like a Pro with Minimal Effort",
      "des": " Products that help achieve professional-tasting results with minimal effort are in demand, pointing towards expert cooking tips or simplified techniques"
  },
  "Need9": {
      "title": "Stress-Free Cooking Experience",
      "des": " Consumers aspire for a relaxing cooking process, suggesting a market for products or services that make cooking more enjoyable"
  },
  "Need10": {
      "title": "Safe and Easy Cooking Process",
      "des": " Safer, more convenient cooking tools or pasta forms to prevent accidents and ease the cooking process are needed"
  },
  "Need11": {
      "title": "Distraction-Free Meal Preparation",
      "des": " Solutions for a peaceful and uninterrupted meal preparation process are desired"
  },
  "Need12": {
      "title": "Simplicity and Ease of Preparation2",
      "des": " Foolproof products that cater to those doubting their cooking skills are in demand"
  },
  "Need13": {
      "title": "Mental and Physical Ease in Preparation",
      "des": " Products that are less strenuous to prepare, catering to those with low mental resilience or physical exhaustion, are needed"
  },
  "Need14": {
      "title": "Interactive Cooking Experience",
      "des": " Products or services that facilitate interactive cooking sessions, such as easy-to-follow meal kits for groups, could meet consumer needs"
  },
  "Need15": {
      "title": "Assistance and Convenience in Cooking",
      "des": " There's a notable interest in having help during the cooking process, suggesting a market for products that offer guidance or simplify cooking"
  },
  "Need16": {
      "title": "Engagement Beyond Cooking",
      "des": " Products that enable multitasking in the kitchen, like appliances with integrated entertainment features, could fulfill this need"
  },
  "Need17": {
      "title": "Ease and Convenience",
      "des": " Products that simplify the preparation process, reduce cooking time, or offer foolproof ways to achieve great results are highly attractive"
  },
  "Need18": {
      "title": "Quick and Easy Gourmet Options",
      "des": " Interest in creating more elaborate dishes without the time investment typically required suggests an opportunity for products that offer a gourmet experience with minimal preparation time"
  },
  "Need19": {
      "title": "Relaxation and Stress Relief",
      "des": " Simplifying the cooking process or creating a more zen-like cooking environment could cater to consumer needs for relaxation and unwinding"
  },
  "Need20": {
      "title": "Healthier Pasta Options",
      "des": " Pasta dishes that are less fattening, lower in calories, and healthier overall are in demand, indicating a need for healthier pasta formulations"
  },
  "Need21": {
      "title": "Weight Management Options",
      "des": " Pasta options that cater to weight management or are compatible with diet plans are needed"
  },
  "Need22": {
      "title": "Stress Reduction",
      "des": " Solutions that minimize stress throughout the process of preparing, serving, and eating pasta are sought"
  },
  "Need23": {
      "title": "Health-Conscious Preparation and Ingredients",
      "des": " Pasta dishes that align with a health-conscious lifestyle, including low-calorie options and ingredients that contribute to healthy eating, are in demand"
  },
  "Need24": {
      "title": "Incorporation of Vegetables",
      "des": " Pasta dishes that allow for a reduction in carbohydrate intake while increasing vegetable consumption are desired"
  },
  "Need25": {
      "title": "Focus on Light and Fresh Dishes",
      "des": " The desire for pasta dishes to be healthy, light, and fresh suggests a consumer need for pasta products that can be used to create meals perceived as less heavy"
  },
  "Need26": {
      "title": "Fitness-Focused Nutrition",
      "des": " There is a clear desire for pasta dishes that support fitness goals, including endurance, strength, and overall well-being"
  },
  "Need27": {
      "title": "Weight Management",
      "des": " Attention to ingredients and a preference for more sauce and vegetables over pasta indicate a consumer interest in weight management"
  },
  "Need28": {
      "title": "Social and Emotional Connection",
      "des": " Products that encourage family or communal cooking and dining experiences, like family-sized meal kits, could resonate well"
  },
  "Need29": {
      "title": "Social Eating Experience",
      "des": " Products or meal solutions that cater to solo diners for a more enjoyable eating experience are needed"
  },
  "Need30": {
      "title": "Family and Social Bonding",
      "des": " Products that cater to group dining experiences, like family-sized pasta meal kits or themed cooking sets, could enhance social cooking occasions"
  },
  "Need31": {
      "title": "Social Pleasure and Family Contentment",
      "des": " Products that facilitate shared cooking experiences or are designed to be enjoyed in a group setting could resonate well with consumers"
  },
  "Need32": {
      "title": "Cultural Connection and Atmosphere",
      "des": " Products or experiences that can bring the warmth and joy of Italy into the German home, regardless of the local weather, are in demand"
  },
  "Need33": {
      "title": "Appreciation and Trust Through Cooking",
      "des": " Enhancing the emotional component tied to cooking, where it is seen as an act of trust and appreciation, could meet consumer needs"
  },
  "Need34": {
      "title": "Family Togetherness",
      "des": " Products that encourage family participation and make gatherings more special could fulfill the aspiration for moments of laughter, joy, and intimacy within the family"
  },
  "Need35": {
      "title": "Social and Leisure Integration",
      "des": " The aspiration to enjoy a beer while cooking or the desire for meals that facilitate relaxation, like eating on the sofa, points towards a broader lifestyle integration"
  },
  "Need36": {
      "title": "Economical and Cost-Effective Cooking",
      "des": " Consumers seek pasta dishes that are both economical and delicious, highlighting the need for cost-effective ingredients without compromising taste"
  },
  "Need37": {
      "title": "Simplicity and Ease of Preparation1",
      "des": " There's a strong desire for simple, quick, and uncomplicated cooking experiences, suggesting a demand for easy recipes and meals requiring few ingredients"
  },
  "Need38": {
      "title": "Time Efficiency",
      "des": " Quick-to-make dishes are essential for consumers, indicating a market for time-saving cooking solutions"
  },
  "Need39": {
      "title": "Efficient Shopping and Quality Purchases",
      "des": " Tools or services that assist in efficient shopping and ingredient selection are sought after for making better quality purchases"
  },
  "Need40": {
      "title": "Convenience and Leisure",
      "des": " Simplifying the cooking process through ready-to-use sauce mixes or pre-measured ingredient packs is in demand"
  },
  "Need41": {
      "title": "Ease of Cleanup",
      "des": " Solutions that minimize kitchen mess, such as one-pot pasta mixes or disposable cooking accessories, are attractive"
  },
  "Need42": {
      "title": "Kitchen Cleanliness Post-Cooking",
      "des": " Easier clean-up solutions, either through packaging innovation or cooking method improvements, are attractive"
  },
  "Need43": {
      "title": "Energy and Time Efficient",
      "des": " Quick and easy-to-prepare options for consumers who are often tired or in a hurry are sought"
  },
  "Need44": {
      "title": "Convenience and Laziness",
      "des": " Ultra-convenient, minimal effort pasta solutions are attractive to consumers"
  },
  "Need45": {
      "title": "Time-Efficient Cooking Solutions",
      "des": " Solutions that simplify the cooking process without compromising the quality and taste of the meal are in demand, catering to consumers with limited time"
  },
  "Need46": {
      "title": "Simplified Meal Preparation",
      "des": " The desire for pasta dishes that require less effort in terms of preparation steps indicates a market for more convenient or time-saving pasta products"
  },
  "Need47": {
      "title": "Convenience and Efficiency",
      "des": " Quick-cook pasta, pre-made sauces that retain a fresh taste, or all-in-one meal kits that save time and reduce complexity in the kitchen are sought"
  },
  "Need48": {
      "title": "Convenience for Active Lifestyles",
      "des": " The need for quick and easy meal solutions after sports or work is evident, highlighting a demand for pasta products that can be prepared swiftly and effortlessly"
  },
  "Need49": {
      "title": "Satisfying Portions",
      "des": " Solutions for more substantial, filling pasta servings are sought"
  },
  "Need50": {
      "title": "Comfort and Warmth",
      "des": " Pasta dishes that provide a comforting and warm eating experience, especially in cold conditions, are desired"
  },
  "Need51": {
      "title": "Quality Time with Children and Grandchildren",
      "des": " Child-friendly pasta cooking kits or educational cooking tools designed for children and adults to use together are desired"
  },
  "Need52": {
      "title": "Community and Joy of Life",
      "des": " Products that focus on creating an experience around pasta meals that celebrates community and joy are sought"
  },
  "Need53": {
      "title": "Promotion of Family Health",
      "des": " Pasta products that are appealing to all family members, including children, and support a family-wide healthy eating initiative are sought"
  },
  "Need54": {
      "title": "Kid-Friendly Cooking Processes",
      "des": " Pasta products that can be prepared swiftly, reducing wait time for children and making the cooking process more engaging for younger family members, are sought"
  },
  "Need55": {
      "title": "Satisfaction Assurance",
      "des": " Products or solutions that can guarantee a higher rate of success and satisfaction in pasta meal preparation are in demand"
  },
  "Need56": {
      "title": "Family-Friendly Options",
      "des": " Pasta options that cater to the diverse preferences of family members, including pickier palates like children, ensuring the pasta dish is a hit with everyone, are sought"
  },
  "Need57": {
      "title": "Family Dining Experience",
      "des": " Pasta products that are easy to prepare in large quantities, promote a communal dining experience, and are enjoyable for all age groups are in demand"
  },
  "Need58": {
      "title": "Economic Value",
      "des": " Pasta products that offer good value for money, possibly through bulk packaging or cost-effective meal solutions, are sought"
  },
  "Need59": {
      "title": "Portion Control",
      "des": " Products that help manage portion sizes to avoid overeating are in demand"
  },
  "Need60": {
      "title": "Portion Control and Satiety",
      "des": " Managing portion sizes effectively to achieve a feeling of fullness without overeating is a consumer need, suggesting an opportunity for pasta products that offer guidance on portion control"
  },
  "Need61": {
      "title": "Reduced Waste",
      "des": " Solutions that minimize waste produced during the pasta preparation process are desired"
  },
  "Need62": {
      "title": "Consistent Cooking Quality",
      "des": " Pasta that offers more consistent cooking results, possibly through enhanced product instructions or formulations, is needed"
  },
  "Need63": {
      "title": "Leftover Pasta Quality",
      "des": " Pasta or storage solutions that maintain or enhance the taste and texture of pasta over time are desired"
  },
  "Need64": {
      "title": "Enhanced Shelf Life",
      "des": " Products with innovative packaging solutions or ingredients that extend the usability of pasta products, making it easier for consumers to plan meals without frequent shopping trips, are needed"
  },
  "Need65": {
      "title": "Cost-Effective Flavor Enhancement",
      "des": " Affordable solutions that can elevate the flavor of pasta dishes without significantly impacting the overall meal budget are sought"
  },
  "Need66": {
      "title": "Quality and Shelf-Life of Ingredients",
      "des": " Better quality control and longer shelf-life solutions for pasta-related ingredients, possibly through innovative packaging or preservation techniques, are desired"
  },
  "Need67": {
      "title": "Quality Ingredients",
      "des": " Offering or promoting pasta made with premium ingredients could meet consumer expectations for high-quality, flavorful ingredients"
  },
  "Need68": {
      "title": "Emphasis on Freshness and Quality of Ingredients",
      "des": " A significant demand for products that preserve the freshness of ingredients or are made from ingredients perceived as freshly sourced is evident"
  },
  "Need69": {
      "title": "Space and Resource Optimization",
      "des": " Compact, efficient kitchen solutions and products that ensure the freshness of ingredients or offer guidance on buying and storing fresh produce are needed"
  },
  "Need70": {
      "title": "Sustainability and Leftover Management",
      "des": " Products that address food waste, through packaging that preserves leftovers or serving sizes that match consumption needs, could resonate with modern consumers"
  },
  "Need71": {
      "title": "Sustainability and Leftover Management",
      "des": " Products that help with food preservation, portion control, or recipes designed for repurposing leftovers, aligning with broader consumer trends towards sustainability, are in demand"
  },
  "Need72": {
      "title": "Victorious Eating Experience",
      "des": " The desire to feel like a winner when eating suggests a need for meals that are not only satisfying in taste and texture but also in achieving personal health and fitness goals"
  },
  "Need73": {
      "title": "Innovative Ideas for Ingredients",
      "des": " Ease in coming up with ingredient ideas is needed, indicating a demand for inspiration in selecting and combining ingredients"
  },
  "Need74": {
      "title": "Culinary Skill Improvement",
      "des": " Interactive pasta cooking kits with instructions or cooking apps focused on pasta dishes could fulfill the desire to improve cooking skills"
  },
  "Need75": {
      "title": "Taste Expectation Management",
      "des": " Products or guidance that help achieve the expected flavor profile more reliably are in demand"
  },
  "Need76": {
      "title": "Seasoning Guidance",
      "des": " Better seasoning guidance, either through packaging instructions or complementary products, is sought to balance flavors"
  },
  "Need77": {
      "title": "Culinary Creativity Support",
      "des": " Products that inspire culinary creativity and confidence, possibly through recipe ideas or flavor pairing suggestions, are in demand"
  },
  "Need78": {
      "title": "Creative and Culinary Exploration",
      "des": " Products that encourage culinary creativity, perhaps through customizable ingredients or versatile pasta kits, could tap into consumer desires"
  },
  "Need79": {
      "title": "Culinary Skill Development",
      "des": " Educational content, perhaps through QR codes on packaging leading to cooking tutorials, or products that teach culinary techniques, could fulfill consumer needs"
  },
  "Need80": {
      "title": "Joy in the Cooking Process",
      "des": " Innovations that make the process of choosing, combining, and cooking ingredients more exciting and fulfilling could address consumer desires"
  },
  "Need81": {
      "title": "Culinary Exploration and Experimentation",
      "des": " Pasta kits with exotic spices, recipe booklets for multicultural pasta dishes, or ingredients for fusion cuisine could encourage culinary creativity"
  },
  "Need82": {
      "title": "Enhanced Cooking Experience with Better Ingredients and Knowledge",
      "des": " Consumers are looking for an improved cooking experience that combines better ingredients with enhanced culinary knowledge"
  },
  "Need83": {
      "title": "Environmental Consciousness",
      "des": " Sustainable packaging and locally sourced ingredients are important, highlighting a need for eco-friendly pasta products"
  },
  "Need84": {
      "title": "Eco-Friendly Packaging",
      "des": " Sustainable packaging solutions for pasta products are sought to address environmental concerns"
  },
  "Need85": {
      "title": "Economic Concerns",
      "des": " More cost-effective pasta products that do not compromise on quality or healthiness are needed due to rising costs"
  },
  "Need86": {
      "title": "Nutritional Transparency",
      "des": " Clearer information on the nutritional content of pasta products is needed for making healthier choices"
  },
  "Need87": {
      "title": "Health and Quality",
      "des": " There is a concern for health and the quality of ingredients, with a need for affordable, high-quality pasta products that are perceived as healthy"
  },
  "Need88": {
      "title": "Organic and Natural Product Options",
      "des": " There is a clear aspiration for pasta and related products that are organic and natural, reflecting a broader concern for health, sustainability, and the environment"
  },
  "Need89": {
      "title": "Fair and Sustainable Manufacturing Practices",
      "des": " Transparency and sustainability in how pasta products are made, including ethical sourcing of ingredients and environmentally friendly production processes, are important to consumers"
  },
  "Need90": {
      "title": "Emphasis on Fresh and Home-Grown Ingredients",
      "des": " A strong desire for incorporating fresh, home-grown vegetables and herbs into the pasta preparation process is evident, emphasizing the importance of using vegetables and herbs that are either grown in their own gardens or sourced locally"
  },
  "Need91": {
      "title": "Dietary Diversity and Health",
      "des": " A range of organic, whole grain, or alternative grain pastas catering to healthier, environmentally friendly options is sought after"
  },
  "Need92": {
      "title": "Innovative and Customizable Options",
      "des": " Customizable pasta meal solutions, such as mix-and-match pasta and sauce packets, are desired for more personalized meals"
  },
  "Need93": {
      "title": "Seasonal and Weather-Related Variations",
      "des": " Seasonal pasta varieties or recipe collections catering to different seasons could tap into consumer interest"
  },
  "Need94": {
      "title": "Increased Frequency of Use",
      "des": " Reasons or incentives to use pasta products more frequently in meal planning are sought"
  },
  "Need95": {
      "title": "Affordability",
      "des": " Pasta products that are budget-friendly and accessible to all income levels are in demand"
  },
  "Need96": {
      "title": "High Protein and Whole Grain Options",
      "des": " Pasta options rich in protein and whole grains are sought for boosting the protein content of meals"
  },
  "Need97": {
      "title": "Plant-Based and Natural Ingredients",
      "des": " A significant number of consumers aspire to have pasta dishes that are purely plant-based, indicating a trend towards vegetarianism or veganism"
  },
  "Need98": {
      "title": "Alternative Pasta Varieties",
      "des": " Interest in pasta made from alternative ingredients like lentils or chickpeas, catering to gluten-free, high-protein, or lower-carbohydrate options, is evident"
  },
  "Need99": {
      "title": "Diverse Taste Satisfaction",
      "des": " Customizable or versatile pasta solutions that can be tailored to individual preferences within a household are in demand to universally appeal to varying taste preferences"
  },
  "Need100": {
      "title": "Vegetable-Forward Options",
      "des": " Pasta products designed to complement vegetable-based sauces and toppings or come with a variety of vegetable mix-ins are sought for their health benefits and preference over meat"
  },
  "Need101": {
      "title": "Diverse Dietary Needs",
      "des": " Pasta products catering to various dietary restrictions and preferences, including gluten-free, low-carb, or plant-based options, are in demand"
  },
  "Need102": {
      "title": "Seasonal and Flavorful Varieties",
      "des": " Consumer interest in pasta products that offer seasonal flavors and evoke certain moods or memories suggests an opportunity for limited-edition pasta or sauce flavors"
  },
  "Need103": {
      "title": "Desire for Spiciness and Variety in Spices",
      "des": " A clear preference for spiciness in pasta dishes, indicating a significant interest in incorporating a variety of spices, particularly chili peppers, into their meals"
  },
  "Need104": {
      "title": "Nutritional Composition",
      "des": " A focus on high-protein content and specific ingredients like spelt noodles indicates a consumer preference for pasta products that support dietary goals"
  },
  "Need105": {
      "title": "Quality and Freshness at Lower Costs",
      "des": " Affordable options for fresh, quality ingredients are desired for fresh and healthy cooking"
  },
  "Need106": {
      "title": "Quality and Health Impact",
      "des": " High-quality food that is good for health and does not feel burdensome is in demand, emphasizing the importance of nutritious and enjoyable meals"
  },
  "Need107": {
      "title": "Hearty and Energizing Meals",
      "des": " Consumers aspire to prepare meals that are both hearty and provide a significant energy boost, making them feel strong and energized without feeling weighed down"
  },
  "Need108": {
      "title": "Meal Satisfaction and Well-being",
      "des": " Beyond physical nourishment, consumers seek meals that offer emotional satisfaction and contribute to a sense of well-being"
  }
}
