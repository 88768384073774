import { Button } from 'antd';
import React, { useCallback } from 'react';
import Dialog from '../../components/dialog.jsx';
import style from './delete.module.scss';

function DeleteDialog({ className, visible, setVisible, onConfirm }) {
  const onClose = useCallback(() => {
    setVisible(false);
  }, [setVisible]);

  return (
    <Dialog className={className} visible={visible} setVisible={setVisible}>
      <div className={style.box1}>
        <div className={style.text1}>
          Are you sure you want to delete this item?
        </div>
        <div className={style.box12}>
          <Button className={style.btn} onClick={onClose}>
            Cancel
          </Button>
          <Button danger={true} onClick={onConfirm}>
            Confirm
          </Button>
        </div>
      </div>
    </Dialog>
  );
}

export default DeleteDialog;
