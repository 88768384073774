import React, { memo, useState, useEffect } from 'react';

import ReactECharts from 'echarts-for-react';
import * as echarts from 'echarts/core';
import { CanvasRenderer } from 'echarts/renderers';
import 'echarts-wordcloud';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import NoData from '../no-data/no-data.jsx';
import style from './product-deep-dive.module.scss';

const antIcon = (
  <LoadingOutlined
    style={{
      fontSize: 30,
      color: '#00FF00'
    }}
    spin
  />
);

echarts.use(
  [CanvasRenderer]
);

const colors = [
  '#014019',
  '#026428',
  '#027F02',
  '#577C08',
  '#00BF00',
];

// 停用词列表
const stopWords = [
  'i', 'you', 'he', 'she', 'it', 'we', 'they', 
  'me', 'him', 'her', 'us', 'them', 
  'my', 'your', 'his', 'her', 'its', 'our', 'their',
  'mine', 'yours', 'hers', 'ours', 'theirs',
  'this', 'that', 'these', 'those',
  'a', 'an', 'the', 'and', 'but', 'or', 'so', 'if', 'then',
  'is', 'am', 'are', 'was', 'were', 'be', 'been', 'being',
  'have', 'has', 'had', 'do', 'does', 'did', 'will', 'would',
  'shall', 'should', 'can', 'could', 'may', 'might', 'must',
  'of', 'in', 'to', 'on', 'with', 'for', 'at', 'by', 'from', 'up', 'down', 'about', 'into', 'over', 'under',
  'as'
];

// // 导入 Worker 文件
// const workerScript = new Blob([`
//   // 停用词列表
//   const stopWords = [
//     'i', 'you', 'he', 'she', 'it', 'we', 'they', 
//     'me', 'him', 'her', 'us', 'them', 
//     'my', 'your', 'his', 'her', 'its', 'our', 'their',
//     'mine', 'yours', 'hers', 'ours', 'theirs',
//     'this', 'that', 'these', 'those',
//     'a', 'an', 'the', 'and', 'but', 'or', 'so', 'if', 'then',
//     'is', 'am', 'are', 'was', 'were', 'be', 'been', 'being',
//     'have', 'has', 'had', 'do', 'does', 'did', 'will', 'would',
//     'shall', 'should', 'can', 'could', 'may', 'might', 'must',
//     'of', 'in', 'to', 'on', 'with', 'for', 'at', 'by', 'from', 'up', 'down', 'about', 'into', 'over', 'under',
//     'as'
//   ];

//   function performExpensiveOperation(input) {
//     // 将文本拆分为单词数组，使用正则表达式去除标点符号并按空格拆分
//     const words = input.toLowerCase().replace(/[^\\w\\s]/g, '').split(/\\s+/);
//     // 生成词频统计
//     const wordFrequency = words.reduce((acc, word) => {
//       if (word && !stopWords.includes(word)) { // 确保不是空字符串且不在停用词列表中
//         acc[word] = (acc[word] || 0) + 1;
//       }
//       return acc;
//     }, {});
  
//     const res = [];
//     Object.keys(wordFrequency).forEach(key => {
//       res.push({
//         name: key,
//         value: wordFrequency[key]
//       });
//     });
  
//     return res;
//   }

//   self.addEventListener('message', (e) => {
//     const { type, data } = e.data;
//     if (type === 'WORD_TEXT_CHART') {
//       // 执行一些计算密集型的任务
//       const result = performExpensiveOperation(data.input||'');
//       self.postMessage(result);
//     }
//   });
// `], { type: 'application/javascript' });

// const workerURL = URL.createObjectURL(workerScript);

const WordCharts = memo(function WordCharts({product}) {

  const getOption = (data) => {
    const max = Math.max(...data.map(item => item.value));
    const min = Math.min(...data.map(item => item.value));
    const sub = max - min;
    data.forEach((item, index) => {
      if (sub === 0) {
        if (index === 0) {
          item.name = item.name.toUpperCase();
        }
      } else {
        if (item.value === max) {
          item.name = item.name.toUpperCase();
        }
      }
    });
    return {
      series: [
        {
          type: 'wordCloud',
          width: '90%',
          height: '100%',
          gridSize: 20,
          sizeRange: [14, 90],
          rotationRange: [-90, 90],
          rotationStep: 90,
          shrinkToFit: true,
          textStyle: {
            fontFamily: 'unilever-shilling-medium',
            fontWeight: 'bold',
            // eslint-disable-next-line no-unused-vars
            color: function (val) {
              let index;
              if (sub) {
                index = Math.floor((val.data.value - min) * 4 / sub);
              } else {
                index = val.dataIndex === 0 ? colors.length - 1 : 1;
              }
              return colors[index];
            }
          },
          data
        },
      ],
    };
  };

  const [options, setOptions] = useState({});
  // eslint-disable-next-line no-unused-vars
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!product) return;
    // setLoading(true);
    // const worker = new Worker(workerURL);
    // worker.onmessage = (e) => {
    //   const op = getOption(e.data);
    //   setOptions(op);
    //   setLoading(false);
    //   worker.terminate(); // 任务完成后终止 Worker
    // };
    // let str = '';
    // product.reviews?.forEach(item => {
    //   if (str.length < 1000) {
    //     str += item.review_body;
    //   }
    // });
    // worker && worker.postMessage({ type: 'WORD_TEXT_CHART', data: { input: str} });
    // return () => {
    //   worker.terminate();
    // };

    let str = '';
    product.reviews?.forEach(item => {
      if (str.length < 1000) {
        str += ' ';
        str += item.review_body??'';
      }
    });

    // 将文本拆分为单词数组，使用正则表达式去除标点符号并按空格拆分
    const words = str.toLowerCase().replace(/[^\w\s]/g, '').split(/\s+/);

    // 生成词频统计
    const wordFrequency = words.reduce((acc, word) => {
      if (word && !stopWords.includes(word) && isNaN(word)) { // 确保不是 空字符串、不在停用词列表中、不是纯数字
        acc[word] = (acc[word] || 0) + 1;
      }
      return acc;
    }, {});
  
    const res = [];
    Object.keys(wordFrequency).forEach(key => {
      res.push({
        name: key,
        value: wordFrequency[key]
      });
    });

    const op = getOption(res);
    setOptions(op);
  }, [product]);

  return (
    <div className={style.wordChartsContainer}>
      <Spin indicator={antIcon} spinning={loading} wrapperClassName="spin-style-name">
        {options.series && options.series[0].data.length === 0 && <NoData />}
        {options.series && options.series[0].data.length > 0 && 
        <ReactECharts
          echarts={echarts}
          option={options}
          style={{
            height: '290px'
          }}
        />}
      </Spin>
    </div>
  );
});

export default WordCharts;