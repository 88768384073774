import React from 'react';
import { cn } from '../../../utils/utils';

import { SearchOutlined } from '@ant-design/icons';

import { Button, Input } from 'antd';
import ModalCom from '../../../components/ModalCom/index.jsx';

const ProjectWinCom = ({showWin, className ='', onCloseWin}) => {
  return <ModalCom showWin={showWin} className={cn('!w-[40%] min-w-[520px]', className)} onCloseWin={onCloseWin}>
    <div className="px-[22px]">
      <div className="text-[24px] font-semibold leading-none mb-4">Import project selection</div>
      <div className="text-base text-grey-3 leading-none mb-6">What do you want to upload your content?</div>

      <div className="px-[25px] py-[20px] rounded-lg bg-[#FAFAFA] mb-[25px]">
        <span className="text-lg leading-none">Add to existing project</span>
        <div className="h-[25px]"></div>
        <Input className="h-[47px] bg-third border-none" size="middle" prefix={<SearchOutlined style={{ fontSize: '16px', fontWeight: 'bold' }} className="font-semibold text-primary"/>} />
      </div>

      <div className="px-[25px] py-[20px] rounded-lg bg-[#FAFAFA]">
        <span className="text-lg leading-none mb-[25px]">Create new project</span>
        <div className="h-[25px]"></div>
        <Input className="h-[47px] bg-third border-none" size="middle" />
      </div>
           
      <div className="mt-[30px] flex justify-center items-center">
        <Button className="w-[135px] border-[#DBDADA] text-grey-3 shadow-none rounded-2xl">Cancel</Button>
        <div className="w-[25px]"></div>
        <Button type="primary" className="w-[135px] border-none shadow-none rounded-2xl">Save</Button>
      </div>
    </div>
  </ModalCom>;
};

export default ProjectWinCom;