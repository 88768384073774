// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.XnhTv7ocSKa9qhpaYNKk{position:relative}.rRx7JAb5W78CDnGfXzJT{width:100%;left:0;right:0;z-index:5;bottom:0;display:flex;background:#071207}`, "",{"version":3,"sources":["webpack://./src/modules/pain-points/pages/home/index.module.scss"],"names":[],"mappings":"AAAA,sBACE,iBAAA,CAGF,sBAEE,UAAA,CAEA,MAAA,CACA,OAAA,CACA,SAAA,CACA,QAAA,CACA,YAAA,CACA,kBAAA","sourcesContent":[".homeWrap {\n  position: relative;\n}\n\n.boxWrap {\n  //position: fixed;\n  width: 100%;\n  //margin-top: 80px;\n  left: 0;\n  right: 0;\n  z-index: 5;\n  bottom: 0;\n  display: flex;\n  background: #071207;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"homeWrap": `XnhTv7ocSKa9qhpaYNKk`,
	"boxWrap": `rRx7JAb5W78CDnGfXzJT`
};
export default ___CSS_LOADER_EXPORT___;
