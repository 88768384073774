import React from 'react';
import { Provider } from 'react-redux';
import store from './store/store.js';
import ConceptAiPage from './pages/main.jsx';
import { ConfigProvider, theme, App } from 'antd';

const conceptAi = () => {
  if (!sessionStorage.getItem('jwt-access-token')) {
    return null;
  }

  return (
    <>
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: '#00a32e',
            colorError: '#eb0000',
            colorSuccess: '#06ca3d',
            fontFamily: 'unilever-shilling-regular'
          },
          algorithm: theme.darkAlgorithm
        }}
      >
        <App>
          <Provider store={store}>
            <ConceptAiPage />
          </Provider>
        </App>
      </ConfigProvider>
    </>
  );
};

export default conceptAi;
