// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SXZuHpSOlR8V0bmoOlaj{padding:5px 15px}.jXBL1H7RfXITm4rLVfGl{color:#00a32e;font-size:12px}.PiaRfpYD8vK1fX7Z9GhI{text-align:end}.BLESsi2zmfp2jEoxy3cu{margin-top:3px;display:flex;align-items:center}.GRvRKR3cy18M9aTLchCO{width:12px;height:12px;margin-right:5px;border-radius:50%}.BH5LUgVdQ_3V92yFC4pU{background-color:#da0000}.OD2JN36s7cn1_3yJPJNP{background-color:#ffbb38}.sVzaZ15VvEaNT_8gfXUb{background-color:#00a32e}.JcHyQ_xTxD3td30cOIma{color:#00a32e;font-size:12px}`, "",{"version":3,"sources":["webpack://./src/modules/package-ai-screening-batch/components/legend.module.scss"],"names":[],"mappings":"AAAA,sBACE,gBAAA,CAGF,sBACE,aAAA,CACA,cAAA,CAGF,sBACE,cAAA,CAGF,sBACE,cAAA,CACA,YAAA,CACA,kBAAA,CAGF,sBACE,UAAA,CACA,WAAA,CACA,gBAAA,CACA,iBAAA,CAGF,sBACE,wBAAA,CAGF,sBACE,wBAAA,CAGF,sBACE,wBAAA,CAGF,sBACE,aAAA,CACA,cAAA","sourcesContent":[".boxInner {\n  padding: 5px 15px;\n}\n\n.text1 {\n  color: #00a32e;\n  font-size: 12px;\n}\n\n.text1a {\n  text-align: end;\n}\n\n.box11 {\n  margin-top: 3px;\n  display: flex;\n  align-items: center;\n}\n\n.icon {\n  width: 12px;\n  height: 12px;\n  margin-right: 5px;\n  border-radius: 50%;\n}\n\n.dataRed {\n  background-color: #da0000;\n}\n\n.dataYellow {\n  background-color: #ffbb38;\n}\n\n.dataGreen {\n  background-color: #00a32e;\n}\n\n.text2 {\n  color: #00a32e;\n  font-size: 12px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"boxInner": `SXZuHpSOlR8V0bmoOlaj`,
	"text1": `jXBL1H7RfXITm4rLVfGl`,
	"text1a": `PiaRfpYD8vK1fX7Z9GhI`,
	"box11": `BLESsi2zmfp2jEoxy3cu`,
	"icon": `GRvRKR3cy18M9aTLchCO`,
	"dataRed": `BH5LUgVdQ_3V92yFC4pU`,
	"dataYellow": `OD2JN36s7cn1_3yJPJNP`,
	"dataGreen": `sVzaZ15VvEaNT_8gfXUb`,
	"text2": `JcHyQ_xTxD3td30cOIma`
};
export default ___CSS_LOADER_EXPORT___;
