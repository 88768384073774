import request from '../../../../util/pythonApi';

export function postFile(file) {
  const data = new FormData();
  data.append('prefix', 'package-ai-generation');
  data.append('form', 'form');
  data.append('file', file);
  return request({
    url: '/v1/azure_file',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
}

export function getFile(url) {
  return fetch(url, {
    mode: 'no-cors',
    method: 'GET',
  });
}
