import { App } from 'antd';
import ReactECharts from 'echarts-for-react';
import React, { useEffect, useMemo, useState } from 'react';
import { downloadImg } from '../../../apis/file';
import { getTimeChart } from '../../../apis/serve/index.js';
import Colors from '../../../assets/json/colors.json';
import style from './chart.module.scss';
import ChartContainer from './container.jsx';

function Chart({ name, tabName, tab2Name, filter, size, title }) {
  const app = App.useApp();
  const { message } = app;

  const [loading, setLoading] = useState(false);
  const [filterData, setFilterData] = useState([]);
  const [needLoad, setNeedLoad] = useState(true);
  const [isIntersecting, setIntersecting] = useState(false);

  useEffect(() => {
    setNeedLoad(true);
  }, [filter]);

  async function read() {
    if (loading) return;
    setLoading(true);
    try {
      const { data } = await getTimeChart(name, filter);
      if (data) {
        setFilterData(data);
      } else {
        setFilterData([]);
      }
    } catch (err) {
      console.error('read chart data error', name, err);
      message.error('Read chart data error');
    }
    setLoading(false);
    setNeedLoad(false);
  }

  useEffect(() => {
    if (needLoad && isIntersecting) {
      void read();
    }
  }, [needLoad, isIntersecting]);

  const option = useMemo(() => {
    const o = {
      legend: {
        icon: 'circle',
        bottom: '0',
        left: 0,
        itemWidth: 10,
        itemHeight: 10,
        textStyle: {
          color: 'rgba(255,255,255, 1)',
          // fontSize: '8px',
        },
        selectedMode: false,
      },
      tooltip: {
        trigger: 'item',
        axisPointer: {
          type: 'shadow',
        },
        confine: true,
      },
      grid: {
        top: '3%',
        left: '0',
        right: '5%',
        bottom: '15%',
        containLabel: true,
      },
      xAxis: {
        type: 'category',
        data: [],
        axisTick: {
          show: false,
        },
        axisLabel: {
          color: '#fff',
          // fontSize: '8px',
          fontWeight: 'bold',
          rotate: 45,
        },
        axisLine: {
          lineStyle: {
            color: '#00A32E',
          },
        },
      },
      yAxis: {
        type: 'value',
        axisLabel: {
          // fontSize: '8px',
          color: '#fff',
        },
        splitLine: {
          show: true,
          lineStyle: {
            color: '#63636369',
            width: 1,
          },
        },
        max: 'dataMax',
      },
      color: Colors,
      series: [],
    };

    const serieOption = {
      type: 'line',
      // itemStyle: {
      //   color: '#00A32E',
      // },
    };
    const categories = [];
    const series = [];
    filterData.forEach((e) => {
      const [time, data] = e;
      categories.push(time);
      data.data.forEach((e1) => {
        const [name, value] = e1;
        const index = series.findIndex((s) => s.name === name);
        if (index === -1) {
          series.push({
            ...serieOption,
            name: name,
            data: [value],
          });
        } else {
          series[index].data.push(value);
        }
      });
    });
    o.xAxis.data = categories;
    o.series = series;
    return o;
  }, [filterData]);

  const [echarts, setEcharts] = useState(null);

  return (
    <ChartContainer
      name={name}
      tabName={tabName}
      tab2Name={tab2Name}
      title={title}
      size={size}
      loading={loading}
      onSaveImg={() => downloadImg(title, echarts)}
      setIntersecting={setIntersecting}
    >
      <ReactECharts
        option={option}
        notMerge={true}
        lazyUpdate={true}
        className={style.chart}
        onChartReady={setEcharts}
      ></ReactECharts>
    </ChartContainer>
  );
}

export default Chart;
