import { Form, Input } from 'antd';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { postFile } from '../../apis/file';
import { PackageAiBtn1 as Btn } from '../../components/btn.jsx';
import Dialog from '../../components/dialog2.jsx';
import Loading from '../../components/loading.jsx';
import style from './update.module.scss';
import { FileAddOutlined } from '@ant-design/icons';

const IMG_FILE_TYPE = ['image/jpeg', 'image/png', 'image/webp'];

const { Item } = Form;

function UpdateDialog({ data, visible, setVisible, onConfirm }) {
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState();
  const fileName = useMemo(() => {
    if (!file) {
      if (!data?.url) {
        return 'Click to select file';
      }
      const u = new URL(data.url);
      const s = u.searchParams;
      const f = s.get('file_key');
      return f ?? 'Click to select file';
    }
    return file.name;
  }, [file, data]);

  const onFinish = useCallback(
    async (values) => {
      if (!onConfirm) return;
      if (loading) return;
      setLoading(true);
      try {
        const dd = {
          ...data,
          title: values.title ?? file.name,
          brand: values.brand,
          category: values.category,
          type: 'logo',
        };
        if (file) {
          const { data: fd } = await postFile(file);
          dd.url = fd.key;
        }
        onConfirm(dd);
      } catch (err) {
        console.error('update asset error', err);
      }
      setLoading(false);
    },
    [file, data]
  );

  const submitRef = useRef();
  const onSubmit = useCallback(() => {
    if (!submitRef.current) return;
    submitRef.current.click();
  }, [submitRef.current]);

  const uploadRef = useRef();

  const onUpload = useCallback(() => {
    if (!uploadRef.current) return;
    uploadRef.current.click();
  }, [uploadRef.current]);
  const onUploadFile = useCallback((files) => {
    if (!files?.length) return;
    setFile(files[0]);
  });

  const formRef = useRef();
  useEffect(() => {
    if (!visible) return;
    if (!formRef.current) return;
    formRef.current.resetFields();
    setFile(null);
  }, [visible, formRef, formRef.current]);

  return (
    <Dialog
      title="Update asset"
      visible={visible}
      setVisible={setVisible}
      zIndex={101}
    >
      <div className={style.box1}>
        <div className={style.box11}>
          <Form
            layout="vertical"
            ref={formRef}
            initialValues={data}
            labelCol={{ span: 8 }}
            onFinish={onFinish}
          >
            <Item label={<span className={style.formLabel}>File</span>}>
              <div className={style.box111} onClick={onUpload}>
                <FileAddOutlined className={style.icon} />
                {fileName}
              </div>
            </Item>
            <Item
              label={<span className={style.formLabel}>Title</span>}
              name="title"
            >
              <Input placeholder="Input title" />
            </Item>
            <button ref={submitRef} type="submit" style={{ display: 'none' }} />
          </Form>
        </div>
        <div className={style.box12}>
          <Btn onClick={onSubmit}>Submit</Btn>
        </div>
      </div>
      <input
        ref={uploadRef}
        style={{ display: 'none' }}
        type="file"
        name="upload"
        id="upload"
        accept={IMG_FILE_TYPE.join(',')}
        onChange={(ev) => {
          if (!ev.target) return;
          onUploadFile(ev.target.files);
        }}
      />
      <Loading loading={loading} />
    </Dialog>
  );
}

export default UpdateDialog;
