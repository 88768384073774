import React, { useState, useEffect } from 'react';
import style from './common.module.scss';
import classNames from 'classnames';
import { ClearOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, ConfigProvider, Space, Tooltip } from 'antd';
import Collapse from '../collapse/collapse.jsx'

const LeftFilterNavigateCollapsible = ({ toggleCallback }) => {


  return (
    <div className={style.filterBox}>
      <div className={classNames(style.left15, style.flexBetween)}>
        <span className={style.textTitle}>Filter</span>
        <div>
          <Tooltip title="Clear">
            <Button type="link" style={{ color: 'rgba(255,255,255,0.6)' }} icon={<ClearOutlined />} />
          </Tooltip>
          <Button type="text" size="small" shape="round" style={{ background: '#1a3d1b', color: '#FFF' }}>Apply</Button>
        </div>
      </div>
      <div style={{ marginTop: '20px' }} />
      <Collapse/>

      {/*<div className={style.line} />*/}


    </div>
  );
};

export default LeftFilterNavigateCollapsible;
