import React, { useCallback, useEffect, useRef } from 'react';

function PackageAiLoadMore({ className, onLoadMore }) {
  const divRef = useRef();
  const init = useCallback(() => {
    if (!divRef.current) return;
    if (!onLoadMore) return;

    const obs = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        onLoadMore();
      }
    });
    obs.observe(divRef.current);

    return () => {
      obs.disconnect();
    };
  }, [onLoadMore, divRef, divRef.current]);
  useEffect(() => {
    return init();
  }, [init]);

  return (
    <div ref={divRef} className={className} style={{ minHeight: '1px' }} />
  );
}

export default PackageAiLoadMore;
