import request from './fetch';

// search options
export function fetchSearchOptions(data, uuid) {
  return request({
    url: '/v1/rating_and_review/filter_options',
    method: 'post',
    uuid,
    data,
    originData: true
  });
}

export function fetchChartsData(params, uuid) {
  return request({
    url: '/v1/rating_and_review/chart',
    method: 'get',
    uuid,
    params,
    originData: true
  });
}

export function fetchMarketingMixIndicator(params, uuid) {
  return request({
    url: '/v1/rating_and_review/marketing_mix_indicator',
    method: 'get',
    uuid,
    params,
    originData: true
  });
}

// 废弃
export function fetchProductDeepDive(params, uuid) {
  return request({
    url: '/v1/rating_and_review/product_deep_dive',
    method: 'get',
    uuid,
    params,
    originData: true
  });
}

// 废弃
export function fetchProductDeepDiveCec(params, uuid) {
  return request({
    url: '/v1/rating_and_review/cec',
    method: 'get',
    uuid,
    params,
    originData: true
  });
}

export function fetchChartsByTopicData(params, uuid) {
  return request({
    url: '/v1/rating_and_review/chart_by_topic',
    method: 'get',
    uuid,
    params,
    originData: true
  });
}

export function fetchProductDeepDiveReviews(params, uuid) {
  return request({
    url: '/v1/rating_and_review/reviews',
    method: 'get',
    uuid,
    params,
    originData: true
  });
}