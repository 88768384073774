import React, { useState, useEffect } from 'react';
import GraphWithSelectors from './graphwithselector.jsx';
import { fetchGrowth, fetchPost } from '../../api/request.js';
import VideoComponent from '../tiktok-modal-tab/tiktok-modal.jsx';
import MetaPostComponent from '../meta-post/meta-post-modal.jsx';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import 'antd/dist/reset.css';
import style from './tab.module.scss';
import RelatedTheme from '../related-theme/related-theme.jsx';

const TabsComponent = ({
  countries,
  brand,
  topDish,
  trendingDish,
  trendingDishId,
  defaultTab = 'tab1'
}) => {
  const [graphData, setGraphData] = useState(null);
  const [metaPosts, setMetaPosts] = useState([]);
  const [tiktokMentions, setTiktokMentions] = useState([]);
  const [activeTab, setActiveTab] = useState(defaultTab);
  const [loading, setLoading] = useState(true);
  const [themeData, setThemeData] = useState([]);
  const fetchData = async () => {
    setLoading(true);
    try {
      const data = {
        countries: countries,
        brands: brand,
        top_dishes: topDish,
        trending_dish_id: trendingDishId,
      };

      if (activeTab === 'tab1') {
        const response = await fetchGrowth(data);
        setGraphData(response.data);
      } else if (activeTab === 'tab2') {
        const response = await fetchPost(data);
        if (response.data) {
          setMetaPosts(response.data.meta_posts || []);
          setTiktokMentions(response.data.tiktok_mentions || []);
          setThemeData(response.data.relevant_themes || []);
        }
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, [activeTab, trendingDishId]);

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 30,
        color: '#00FF00',
      }}
      spin
    />
  );

  return (
    <div className={style.tabTransition}>
      <div className={style.HorizontalNav}>
        <ul className={style.Container}>
          <li
            className={activeTab === 'tab1' ? style.active : ''}
            onClick={() => handleTabClick('tab1')}
          >
            Growth
          </li>
          <li
            className={activeTab === 'tab2' ? style.active : ''}
            onClick={() => handleTabClick('tab2')}
          >
            Post
          </li>
        </ul>
      </div>
      {loading ? (
        <Spin indicator={antIcon}>
          <div className='spin-icon'></div>
        </Spin>
      ) : (
        <>
          {activeTab === 'tab1' && graphData && (
            <div id="tab1" className="tab-content">
              <div className='growth-content'>
                <section id="section-1">
                  <div className={style.BorderContainer}>
                    {graphData.TrendIndexCurve ? (
                      <GraphWithSelectors
                        countries={countries}
                        brand={brand}
                        topDish={topDish}
                        trendingDishId={trendingDishId}
                        graphData={graphData.TrendIndexCurve}
                        graphId="TrendIndexCurve"
                      />
                    ) : (
                      <div className="no-data-container">
                        <p>No data available for TrendIndexCurve</p>
                      </div>
                    )}
                  </div>
                </section>
                <section id="section-2">
                  <div className={`Border ${style.Border}`}>
                    {graphData.TiktokMentions ? (
                      <GraphWithSelectors
                        countries={countries}
                        brand={brand}
                        topDish={topDish}
                        trendingDishId={trendingDishId}
                        graphData={graphData.TiktokMentions}
                        graphId="TiktokMentions"
                      />
                    ) : (
                      <div className="no-data-container">
                        <p>No data available for TiktokMentions</p>
                      </div>
                    )}
                  </div>
                </section>
                <section id="section-3">
                  <div className={`Border ${style.Border}`}>
                    {graphData.MetaPosts ? (
                      <GraphWithSelectors
                        countries={countries}
                        brand={brand}
                        topDish={topDish}
                        trendingDishId={trendingDishId}
                        graphData={graphData.MetaPosts}
                        graphId="MetaPosts"
                      />
                    ) : (
                      <div className="no-data-container">
                        <p>No data available for MetaPosts</p>
                      </div>
                    )}
                  </div>
                </section>
                <section id="section-4">
                  <div className='Border'>
                    {graphData.GoogleSearches ? (
                      <GraphWithSelectors
                        countries={countries}
                        brand={brand}
                        topDish={topDish}
                        trendingDishId={trendingDishId}
                        graphData={graphData.GoogleSearches}
                        graphId="GoogleSearches"
                      />
                    ) : (
                      <div className="no-data-container">
                        <p>No data available for GoogleSearches</p>
                      </div>
                    )}
                  </div>
                </section>
              </div>
            </div>
          )}
          {activeTab === 'tab2' && (
            <div id="tab2" className="tab-content mb-10">
              <div className={style.postContainer}>
                <RelatedTheme themeData={themeData} />
                <div className={style.Border}>
                  <VideoComponent videoData={tiktokMentions} trendingDish={trendingDish} />
                </div>
                <div className='mt-40'><MetaPostComponent videoData={metaPosts} trendingDish={trendingDish} /></div>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default TabsComponent;
