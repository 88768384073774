import React from 'react';
import BtnPng from '../assets/img/btn.png';
import style from './btn.module.scss';
import { LoadingOutlined } from '@ant-design/icons';

export function PackageAiBtn1({
  className,
  children,
  onClick,
  disabled = false,
  loading = false,
}) {
  function handleClick(ev) {
    if (disabled) return;
    onClick && onClick(ev);
  }

  return (
    <div
      className={`${style.btn1} ${
        disabled ? style.btnDisabled : ''
      } ${className}`}
      onClick={handleClick}
    >
      {loading ? <LoadingOutlined className={style.icon} spin /> : null}
      {children}
    </div>
  );
}

export function PackageAiBtn2({
  className,
  children,
  onClick,
  disabled = false,
  loading = false,
}) {
  function handleClick(ev) {
    if (disabled) return;
    onClick && onClick(ev);
  }

  return (
    <div
      className={`${style.btn2} ${
        disabled ? style.btnDisabled : ''
      } ${className}`}
      style={{ backgroundImage: `url(${BtnPng})` }}
      onClick={handleClick}
    >
      {loading ? <LoadingOutlined className={style.icon} spin /> : null}
      {children}
    </div>
  );
}

export function PackageAiBtn3({
  className,
  children,
  onClick,
  disabled = false,
  loading = false,
}) {
  function handleClick(ev) {
    if (disabled) return;
    onClick && onClick(ev);
  }

  return (
    <div
      className={`${style.btn3} ${
        disabled ? style.btnDisabled : ''
      } ${className}`}
      onClick={handleClick}
    >
      {loading ? <LoadingOutlined className={style.icon} spin /> : null}
      {children}
    </div>
  );
}
