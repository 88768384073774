import React, { useEffect } from 'react';
import classNames from 'classnames';
import style from './intro-popup.module.scss';
import closeSvg from '../../../../assets/images/gotfl-weena/close-icon.svg';

import { Modal } from 'antd';
import BorderContainer from '../../../../components/border-container/border-container.jsx';
import useBasic from '../../hooks/useBasic.js';
import { isWinDpr1 } from '../../../../util/utils.jsx';

const IntroPopup = ({open, onClose, onMore, id}) => {
  const { introDetail: detail, fnGetWrokShopDetail} = useBasic();
  useEffect(() => {
    if(open) {
      fnGetWrokShopDetail(id);
    }
  }, [id, open]);

  return <Modal open={open} closeIcon={false} width="1100px" centered={isWinDpr1()} footer={false} className={style.customModalWrap}  destroyOnClose={true}>
    <img src={closeSvg} className={classNames(style.closeIcon)} onClick={onClose} />
    <BorderContainer id="intro_1">
      <div className={classNames(style.contentWrap)}>
        <img src={detail?.thumbUrl} className={classNames(style.imgBg)}></img>
        <div className={classNames(style.detailWrap)}>
          <div className={classNames(style.title1)}>{detail?.name}</div>
          <div className={classNames(style.title2)}>{detail?.when}</div>
          <div style={{height: '43px'}}></div>
          <div className={classNames(style.label)}>When :</div>
          <div className={classNames(style.desc)}>{detail?.when}</div>
          <div style={{height: '8px'}}></div>
          <div className={classNames(style.label)}>Where :</div>
          <div className={classNames(style.desc)}>{detail?.where}</div>
          <div style={{height: '8px'}}></div>
          <div className={classNames(style.label)}>Introduction :</div>
          <div className={classNames(style.scrollBox)}>
            <div className={classNames(style.desc)}>{detail?.intro}</div>
          </div>
        
          <div className={classNames(style.btn)} onClick={onMore}>
            MORE
          </div>
        </div>
      </div>
    </BorderContainer>
  </Modal>;
};

export default IntroPopup;