import React from 'react';
import EditableText from '../../components/editableText.jsx';
import Title from '../../components/title2.jsx';
import Dialog from './dialog.jsx';
import style from './item.module.scss';

function PackageAiImageItem({ className = '', data, setData, aoi, setAoi }) {
  const [editorVisible, setEditorVisible] = React.useState(false);

  function onClick() {
    setEditorVisible(true);
  }

  return (
    <div className={`${style.imgItem} ${className}`}>
      <Title>
        <EditableText
          value={data.title}
          onChange={(val) => setData({ ...data, title: val })}
        />
      </Title>
      <div className={style.imgItemContent} onClick={onClick}>
        <img
          className={style.imgItemContentImg}
          src={data.src}
          crossOrigin="anonymous"
        />
        {aoi.complete ? (
          <div className={style.completeTag}>Edited</div>
        ) : (
          <div className={style.incompleteTag}>Unedited</div>
        )}
      </div>
      <Dialog
        visible={editorVisible}
        setVisible={setEditorVisible}
        title={data.title}
        data={data}
        aoi={aoi}
        setAoi={setAoi}
      />
    </div>
  );
}

export default PackageAiImageItem;
