import { Col, Row } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { putFileKey } from '../../apis/file';
import HelpPng from '../../assets/img/help3.png';
import AiIcon from '../../components/aiIcon2.jsx';
import ColorCoding from '../../components/colorCoding.jsx';
import Help from '../../components/help.jsx';
import ImgDialog from '../../components/imgDialog.jsx';
import Legend from '../../components/legend2.jsx';
import Loading from '../../components/loading.jsx';
import style from './shelf.module.scss';
import Table from './table.jsx';
import Title from './title.jsx';

const COLOR_KEYS = ['black', 'dark gray', 'light gray', 'white'];

function RatingIcon({ ulScore, competitorScore }) {
  const v = useMemo(() => {
    if (ulScore > competitorScore * 1.1) return 1;
    else if (ulScore > competitorScore * 0.9) return 3;
    else return 0;
  }, [ulScore, competitorScore]);

  return <AiIcon value={v} />;
}

function colorValue(val) {
  if (val > 70) return 3;
  else if (val > 40) return 2;
  else return 1;
}

function HotspotHeatmap({ header, img1, img2, onImgClick }) {
  return (
    <>
      <Title>Hotspot & Heatmap{header ? ` - ${header}` : ''}</Title>
      <div className={style.box13}>
        <div className={style.box131}>
          <img
            className={style.imgBox}
            src={img1}
            alt="Hotspot"
            onClick={() => onImgClick(img1, 'Hotspot')}
          />
        </div>
        <div className={style.divider} />
        <div className={style.box131}>
          <img
            className={style.imgBox}
            src={img2}
            alt="Heatmap"
            onClick={() => onImgClick(img2, 'Heatmap')}
          />
        </div>
      </div>
    </>
  );
}

/**
 * calculate design score
 * @param {{[key:string]:{ul: number, c1: number, c2: number}}} designScore
 * @returns {{ul: number, c1: number, c2: number}}
 */
function calculateDesignScore(designScore) {
  const score = { ul: 0, c1: 0, c2: 0 };
  let length = 0;
  for (const ck of COLOR_KEYS) {
    const s = designScore[ck];
    if (!s) continue;
    score.ul += s.ul;
    score.c1 += s.c1;
    score.c2 += s.c2;
    length += 1;
  }
  score.ul /= length;
  score.c1 /= length;
  score.c2 /= length;
  return score;
}

function PackageAiBody({ useNextBtn, context: ctx }) {
  // table 1
  const [column1, setColumn1] = useState([
    '',
    'Current',
    'New Design',
    'New Design 2',
    'New Design 3',
  ]);
  const [value1, setValue1] = useState([
    [
      'Our Brand',
      <ColorCoding key="Our Brand 1" colorValue={0}>
        0.0
      </ColorCoding>,
      <ColorCoding key="Our Brand 2" colorValue={0}>
        0.0
      </ColorCoding>,
      <ColorCoding key="Our Brand 3" colorValue={0}>
        0.0
      </ColorCoding>,
      <ColorCoding key="Our Brand 4" colorValue={0}>
        0.0
      </ColorCoding>,
    ],
    [
      'Competitor 1',
      <ColorCoding key="Competitor 1 1" colorValue={0}>
        0.0
      </ColorCoding>,
      <ColorCoding key="Competitor 1 2" colorValue={0}>
        0.0
      </ColorCoding>,
      <ColorCoding key="Competitor 1 3" colorValue={0}>
        0.0
      </ColorCoding>,
      <ColorCoding key="Competitor 1 4" colorValue={0}>
        0.0
      </ColorCoding>,
    ],
    [
      'Competitor 2',
      <ColorCoding key="Competitor 2 1" colorValue={0}>
        0.0
      </ColorCoding>,
      <ColorCoding key="Competitor 2 2" colorValue={0}>
        0.0
      </ColorCoding>,
      <ColorCoding key="Competitor 2 3" colorValue={0}>
        0.0
      </ColorCoding>,
      <ColorCoding key="Competitor 2 4" colorValue={0}>
        0.0
      </ColorCoding>,
    ],
  ]);
  // table 2
  const column2 = ['', 'Rating'];
  const [value2, setValue2] = useState([
    ['UL Brand Current Design vs Competitor 1', 0],
    ['UL Brand Current Design vs Competitor 2', 0],
    ['UL Brand New Design vs Competitor 1', 0],
    ['UL Brand New Design vs Competitor 2', 0],
  ]);
  const [dialogVisible, setDialogVisible] = useState(false);
  const [dialogSrc, setDialogSrc] = useState('');
  const [dialogAlt, setDialogAlt] = useState('');
  const [data3, setData3] = useState([]);
  function onImgClick(src, alt) {
    setDialogSrc(src);
    setDialogAlt(alt);
    setDialogVisible(true);
  }

  const [loading, setLoading] = useState(true);
  async function init() {
    // use design score
    const pp = ctx?.res?.predictPackage;
    if (!pp) return;
    {
      // table 1
      const ds = pp.designscores;
      if (!ds) return;
      // use column
      const column = ['Pack Visibility on Shelf'];
      // use value
      const value = [[pp.brand_ul], [pp.brand_c1], [pp.brand_c2]];
      const value2 = [];
      if (pp.ul_design0_variant0_id) {
        // table 1
        const ds = pp.designscores.uldesign0;
        if (ds) {
          column.push('Current Design');
          const score = calculateDesignScore(ds);
          value[0].push(
            <ColorCoding
              key="Our brand current deisgn"
              colorValue={colorValue(score.ul)}
            >
              {score.ul.toFixed(2)}
            </ColorCoding>
          );
          value[1].push(
            <ColorCoding
              key="Our brand current deisgn"
              colorValue={colorValue(score.c1)}
            >
              {score.c1.toFixed(2)}
            </ColorCoding>
          );
          value[2].push(
            <ColorCoding
              key="Our brand current deisgn"
              colorValue={colorValue(score.c2)}
            >
              {score.c2.toFixed(2)}
            </ColorCoding>
          );
          // table 2
          value2.push([
            `${pp.brand_ul} Current Design vs ${pp.brand_c1}`,
            <RatingIcon
              key={`${pp.brand_ul} Current Design vs ${pp.brand_c1}`}
              ulScore={score.ul}
              competitorScore={score.c1}
            />,
          ]);
          value2.push([
            `${pp.brand_ul} Current Design vs ${pp.brand_c2}`,
            <RatingIcon
              key={`${pp.brand_ul} Current Design vs ${pp.brand_c2}`}
              ulScore={score.ul}
              competitorScore={score.c2}
            />,
          ]);
        }
      }
      if (pp.ul_design1_variant0_id) {
        // table 1
        const ds = pp.designscores.uldesign1;
        if (ds) {
          column.push('New Design');
          const score = calculateDesignScore(ds);
          value[0].push(
            <ColorCoding
              key="Our brand new deisgn"
              colorValue={colorValue(score.ul)}
            >
              {score.ul.toFixed(2)}
            </ColorCoding>
          );
          value[1].push(
            <ColorCoding
              key="Our brand new deisgn"
              colorValue={colorValue(score.c1)}
            >
              {score.c1.toFixed(2)}
            </ColorCoding>
          );
          value[2].push(
            <ColorCoding
              key="Our brand new deisgn"
              colorValue={colorValue(score.c2)}
            >
              {score.c2.toFixed(2)}
            </ColorCoding>
          );
          // table 2
          value2.push([
            `${pp.brand_ul} New Design vs ${pp.brand_c1}`,
            <RatingIcon
              key={`${pp.brand_ul} New Design vs ${pp.brand_c1}`}
              ulScore={score.ul}
              competitorScore={score.c1}
            />,
          ]);
          value2.push([
            `${pp.brand_ul} New Design vs ${pp.brand_c2}`,
            <RatingIcon
              key={`${pp.brand_ul} New Design vs ${pp.brand_c2}`}
              ulScore={score.ul}
              competitorScore={score.c2}
            />,
          ]);
        }
      }
      if (pp.ul_design2_variant0_id) {
        // table 1
        const ds = pp.designscores.uldesign2;
        if (ds) {
          column.push('New Design 2');
          const score = calculateDesignScore(ds);
          value[0].push(
            <ColorCoding
              key="Our brand new deisgn 2"
              colorValue={colorValue(score.ul)}
            >
              {score.ul.toFixed(2)}
            </ColorCoding>
          );
          value[1].push(
            <ColorCoding
              key="Our brand new deisgn 2"
              colorValue={colorValue(score.c1)}
            >
              {score.c1.toFixed(2)}
            </ColorCoding>
          );
          value[2].push(
            <ColorCoding
              key="Our brand new deisgn 2"
              colorValue={colorValue(score.c2)}
            >
              {score.c2.toFixed(2)}
            </ColorCoding>
          );
          // table 2
          value2.push([
            `${pp.brand_ul} New Design 2 vs ${pp.brand_c1}`,
            <RatingIcon
              key={`${pp.brand_ul} New Design 2 vs ${pp.brand_c1}`}
              ulScore={score.ul}
              competitorScore={score.c1}
            />,
          ]);
          value2.push([
            `${pp.brand_ul} New Design 2 vs ${pp.brand_c2}`,
            <RatingIcon
              key={`${pp.brand_ul} New Design 2 vs ${pp.brand_c2}`}
              ulScore={score.ul}
              competitorScore={score.c2}
            />,
          ]);
        }
      }
      if (pp.ul_design3_variant0_id) {
        // table 1
        const ds = pp.designscores.uldesign3;
        if (ds) {
          column.push('New Design 3');
          const score = calculateDesignScore(ds);
          value[0].push(
            <ColorCoding
              key="Our brand new deisgn 3"
              colorValue={colorValue(score.ul)}
            >
              {score.ul.toFixed(2)}
            </ColorCoding>
          );
          value[1].push(
            <ColorCoding
              key="Our brand new deisgn 3"
              colorValue={colorValue(score.c1)}
            >
              {score.c1.toFixed(2)}
            </ColorCoding>
          );
          value[2].push(
            <ColorCoding
              key="Our brand new deisgn 3"
              colorValue={colorValue(score.c2)}
            >
              {score.c2.toFixed(2)}
            </ColorCoding>
          );
          // table 2
          value2.push([
            `${pp.brand_ul} New Design 3 vs ${pp.brand_c1}`,
            <RatingIcon
              key={`${pp.brand_ul} New Design 3 vs ${pp.brand_c1}`}
              ulScore={score.ul}
              competitorScore={score.c1}
            />,
          ]);
          value2.push([
            `${pp.brand_ul} New Design 3 vs ${pp.brand_c2}`,
            <RatingIcon
              key={`${pp.brand_ul} New Design 3 vs ${pp.brand_c2}`}
              ulScore={score.ul}
              competitorScore={score.c2}
            />,
          ]);
        }
      }
      setColumn1(column);
      setValue1(value);
      setValue2(value2);
    }
    // use shelf
    {
      const value = [];
      const shelf = ctx?.res?.predictShelf;
      if (shelf) {
        // ul design 0
        const ul0id = shelf.uldesign0_id;
        const ul0d = ctx?.res?.shelfDesigns[ul0id];
        if (ul0d) {
          const cid = ul0d['light gray_shelf_id'];
          if (cid) {
            const shelfColor = ctx?.res?.shelfColors[cid.toString()];
            const { data: url1 } = await putFileKey(shelfColor.hotpotimage);
            const { data: url2 } = await putFileKey(shelfColor.heatmapimage);
            value.push({
              header: '',
              img1: url1.url,
              img2: url2.url,
            });
          } else {
            console.warn('light gray_shelf_id not found');
          }
        } else {
          console.warn('ul0d not found');
        }
        // ul design 1
        const ul1id = shelf.uldesign1_id;
        const ul1d = ctx?.res?.shelfDesigns[ul1id];
        if (ul1d) {
          const cid = ul1d['light gray_shelf_id'];
          if (cid) {
            const shelfColor = ctx?.res?.shelfColors[cid.toString()];
            const { data: url1 } = await putFileKey(shelfColor.hotpotimage);
            const { data: url2 } = await putFileKey(shelfColor.heatmapimage);
            if (ul0d) {
              value[value.length - 1].header = 'Current Design';
            }
            value.push({
              header: 'New Design',
              img1: url1.url,
              img2: url2.url,
            });
          } else {
            console.warn('shelf not found');
          }
        } else {
          console.warn('ul1d not found');
        }
      } else {
        console.warn('shelf not found');
      }
      setData3(value);
    }
    setLoading(false);
  }
  useEffect(() => {
    void init();
  }, [ctx]);

  return (
    <>
      {loading && <Loading />}
      <div className={style.box1}>
        <Row gutter={70}>
          <Col span={17}>
            <Title>
              Note: use structural and color contrasts to drive shelf visibility
            </Title>
            <Table labels={column1} values={value1} />
            {data3.length === 0 && <div className={style.box14} />}
            {data3.map((d) => (
              <div className={style.box2} key={d.header}>
                <HotspotHeatmap
                  header={d.header}
                  img1={d.img1}
                  img2={d.img2}
                  onImgClick={onImgClick}
                />
              </div>
            ))}
          </Col>
          <Col span={7}>
            <Title>
              <div className={style.titleInner}>
                Assessment Rating
                <Help>
                  <img src={HelpPng} alt="help" />
                </Help>
              </div>
            </Title>
            <Table labels={column2} values={value2} />
            <Legend className={style.infoBox} />
          </Col>
        </Row>
      </div>
      <Row className={style.box3}>
        <Col span={16}>
          <div className={style.info}>
            Note: Heatmap and Hotspot show how one brand is taking visibility
            from another within a shelf context.
            <br />
            Tip: Click on heatmap and hotspot to enlarge.
          </div>
        </Col>
        <Col span={8}>
          {useNextBtn && useNextBtn({ className: style.nextBtn })}
        </Col>
      </Row>
      <ImgDialog
        visible={dialogVisible}
        setVisible={setDialogVisible}
        src={dialogSrc}
        alt={dialogAlt}
      />
    </>
  );
}

export default PackageAiBody;
