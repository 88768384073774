import React, { useMemo } from 'react';
import classNames from 'classnames';
import style from './horizontal.module.scss';

// 奇数 2 1 0 -1 -2
// 偶数 2 1 -1 -2
function HorizontalLine({ className, pos = 0, showLeftChildren = false }) {
  const posClass = useMemo(() => {
    if (pos >= 2) {
      return style.right2;
    } else if (pos >= 1) {
      return style.right;
    } else if (pos <= -2) {
      return style.left2;
    } else if (pos <= -1) {
      return style.left;
    } else if (pos === 0) {
      return style.middle;
    } else {
      console.warn('unkown pos', pos);
    }
  }, [pos]);

  return (
    <div
      className={classNames(
        style.horizontalLine,
        posClass,
        showLeftChildren ? style.showLeftChildren : '',
        className
      )}
    />
  );
}

export default HorizontalLine;
