import { message } from 'antd';
import { utils, writeFile } from 'xlsx';

export function downloadImg(name, echarts) {
  if (!name) {
    console.warn('Must input img name');
    return;
  } else if (!echarts) {
    console.error('Must input echarts instance');
    return;
  }
  const img = echarts.getDataURL({
    pixelRatio: 2,
  });
  const link = document.createElement('a');
  link.download = `${name}.png`;
  link.href = img;
  link.click();
  message.success('Image save successful');
  URL.revokeObjectURL(link.href);
  link.remove();
}

function formatPercent(value) {
  return `${(value * 100.0).toFixed(2)}%`;
}

function processSheetName(sheetName) {
  // Remove invalid characters \ / ? * [ ]
  const invalidCharsRegex = /[\\/?*[\]]/g;
  const sanitizedSheetName = sheetName.replace(invalidCharsRegex, '_');

  // Truncate to maximum 31 characters
  const truncatedSheetName = sanitizedSheetName.substring(0, 31);

  return truncatedSheetName;
}

export function downloadBarData(
  title,
  fileType,
  total,
  data,
  hasCompare,
  compareTotal,
  compareData,
  existWb = null
) {
  const rows = [];
  // title line
  rows.push(['', title, ''], []);
  // data header line
  if (hasCompare) {
    rows.push([
      '',
      'Count',
      'Percentage',
      'Compare Count',
      'Compare Percentage',
    ]);
  } else {
    rows.push(['', 'Count', 'Percentage']);
  }
  // data lines
  for (const d of data) {
    const [label, count] = d;
    const percentage = count / total;
    const dataLine = [label, count, formatPercent(percentage)];
    if (hasCompare) {
      const cd = compareData.find((e) => {
        return e[0] === label;
      });
      if (cd) {
        const compareCount = cd[1];
        const comparePercentage = compareCount / compareTotal;
        dataLine.push(compareCount, formatPercent(comparePercentage));
      } else {
        dataLine.push(0, formatPercent(0));
      }
    }
    rows.push(dataLine);
  }
  rows.push([]);
  // total line
  if (hasCompare) {
    rows.push([
      'Total',
      total,
      formatPercent(1),
      compareTotal,
      formatPercent(compareTotal / total),
    ]);
  } else {
    rows.push(['Total', total, formatPercent(1)], []);
  }
  // sample size line
  if (hasCompare) {
    rows.push(['Sample Size', total, '', compareTotal]);
  } else {
    rows.push(['Sample Size', total]);
  }
  // save
  const ws = utils.aoa_to_sheet(rows);
  if (existWb) {
    utils.book_append_sheet(existWb, ws, processSheetName(title));
    return;
  }
  const wb = utils.book_new();
  utils.book_append_sheet(wb, ws, processSheetName(title));
  if (fileType === 'xlsx') {
    writeFile(wb, `${title}.xlsx`);
    message.success('Excel save successful');
  } else if (fileType === 'csv') {
    writeFile(wb, `${title}.csv`, { bookType: 'csv' });
    message.success('Csv file save successful');
  } else {
    throw new Error('Unknown file type');
  }
}

export function downloadStackData(
  title,
  fileType,
  total,
  data,
  hasCompare,
  compareTotal,
  compareData,
  existWb = null
) {
  const rows = [];
  // title line
  rows.push(['', title, ''], []);
  // percent header line
  rows.push(['', 'Percentage']);
  if (hasCompare) {
    rows.push(['label', 'value', 'compare']);
  } else {
    rows.push(['label', 'value']);
  }
  // percent data lines
  for (const d of data) {
    const [label, count] = d;
    const dataLine = [label, formatPercent(count / total)];
    if (hasCompare) {
      const cd = compareData.find((e) => {
        return e[0] === label;
      });
      if (cd) {
        const compareCount = cd[1];
        dataLine.push(formatPercent(compareCount / compareTotal));
      }
    }
    rows.push(dataLine);
  }
  rows.push([]);
  // percent total line
  if (hasCompare) {
    rows.push(['Total', formatPercent(1), formatPercent(compareTotal / total)]);
  } else {
    rows.push(['Total', formatPercent(1)]);
  }
  // count header line
  rows.push([], ['', 'Counts']);
  if (hasCompare) {
    rows.push(['label', 'value', 'compare']);
  } else {
    rows.push(['label', 'value']);
  }
  // count data lines
  for (const d of data) {
    const [label, count] = d;
    const dataLine = [label, count];
    if (hasCompare) {
      const cd = compareData.find((e) => {
        return e[0] === label;
      });
      if (cd) {
        const compareCount = cd[1];
        dataLine.push(compareCount);
      }
    }
    rows.push(dataLine);
  }
  rows.push([]);
  // count total line
  if (hasCompare) {
    rows.push(['Total', total, compareTotal]);
  } else {
    rows.push(['Total', total]);
  }
  // sample size line
  rows.push([], ['Sample Size', total]);
  // save
  const ws = utils.aoa_to_sheet(rows);
  if (existWb) {
    utils.book_append_sheet(existWb, ws, title.slice(0, 30));
    return;
  }
  const wb = utils.book_new();
  utils.book_append_sheet(wb, ws, title.slice(0, 30));
  if (fileType === 'xlsx') {
    writeFile(wb, `${title}.xlsx`);
    message.success('Excel save successful');
  } else if (fileType === 'csv') {
    writeFile(wb, `${title}.csv`, { bookType: 'csv' });
    message.success('Csv file save successful');
  } else {
    throw new Error('Unknown file type');
  }
}

export async function downloadWb(saveDataFns, fileType, title) {
  const wb = utils.book_new();
  for (const fn of saveDataFns) {
    fn(wb);
  }
  if (Object.keys(wb.Sheets).length === 0) return;
  if (fileType === 'xlsx') {
    writeFile(wb, `${title}.xlsx`);
    message.success('Excel save successful');
  } else if (fileType === 'csv') {
    writeFile(wb, `${title}.csv`, { bookType: 'csv' });
    message.success('Csv file save successful');
  } else {
    throw new Error('Unknown file type');
  }
}
