import React from 'react';
import { Modal } from 'antd';
import SvgCom from '../SvgCom/index.jsx';
import {ReactComponent as CloseSvg} from '../../assets/svgs/close.svg';

const classNames = {
  content: '!p-[18px]',
};

const ModalCom = ({children = <></>, showWin, className='', onCloseWin}) => {
  return (
    <Modal open={showWin} closable={false} footer={null} centered={true} className={className} classNames={classNames}>
      {
        onCloseWin && <SvgCom Svg={CloseSvg} size='30' className='absolute top-[18px] right-[18px] cursor-pointer z-10' onClick={() => onCloseWin(false)}></SvgCom>
      }
      {
        children
      }
    </Modal>
  );
};

export default ModalCom;