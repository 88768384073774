import React from 'react';
import style from './logo.module.scss';
import LogoPng from '../../../assets/images/logo.png';

function OccasionHomeLogo({ className }) {
  return (
    <a href="/">
      <div className={`${style.logoContainer} ${className ?? ''}`}>
        <img className={style.logoImg} src={LogoPng} alt="unilever" />
      </div>
    </a>
  );
}

export default OccasionHomeLogo;
