import React, { useEffect, useState } from 'react';
import {
  shareIcon,
  // favoriteIcon,
} from '../../../../components/icons/icons.jsx';
import FavouriteButton from '../../../../components/favourite/favourite-button.jsx';
import ShareModal from '../../../../components/share-modal/share-modal.jsx';
// import axios from 'axios';
import { apiURL } from '../../../../env-url';
import ConsumerTrendVideoPlay from './consumer-trend-video-play.jsx';
import Loader from '../../../../components/loader/loader.jsx';
import SimplePagination from '../../../../components/antd-pagination/antd-pagination.jsx';
import api from '../../../../util/api.jsx';

export default function ConsumerTrendCard({
  selectedCategory,
  consumerTrendsCategory,
  selectedItemName,
  object,
  selectedCountry
}) {
  const [showMessage, setShowMessage] = useState(false);
  const [allVideos, setAllVideos] = useState([]);
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [redirectToVideoPage, setRedirectToVideoPage] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const [videoUrlToShare, setVideoUrlToShare] = useState('');
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize ] = useState(20);
  const [totalCount, setTotalCount] = useState();

  let subcategory = object;
  const fetchData = () => {
    if (subcategory) {
      let brandcategory = encodeURIComponent(selectedCategory);
      let consumerTrendCategory = encodeURIComponent(selectedItemName);
      
      api
        .get(
          `${apiURL}/trends/tiktok/categories/${brandcategory}/subcategories/${subcategory}/videos?country=${selectedCountry}&consumerTrend=${consumerTrendCategory}&pageNumber=${pageNumber}&pageSize=${pageSize}`,
        )
        .then((response) => {
          setPageNumber(response.pageMeta.pageNumber);
          setTotalCount(response.pageMeta.totalCount);
          // setPageSize(response.pageMeta.pageSize);
          setAllVideos(response.data);
          setLoading(false);
        })
        .catch((error) => {
          console.error('Error fetching data:', error);
        });
    } else {
      let brandcategory = encodeURIComponent(selectedCategory);
      let consumerTrendCategory = encodeURIComponent(selectedItemName);
      api
        .get(
          `${apiURL}/trends/tiktok/categories/${brandcategory}/videos?country=${selectedCountry}&consumerTrend=${consumerTrendCategory}&pageNumber=${pageNumber}&pageSize=${pageSize}`,
        )
        .then((response) => {
          setPageNumber(response.pageMeta.pageNumber);
          setTotalCount(response.pageMeta.totalCount);
          // setPageSize(response.pageMeta.pageSize);
          setAllVideos(response.data);
          setLoading(false);
        })
        .catch((error) => {
          console.error('Error fetching data:', error);
        });
    }
  };

  const handlePageChange = (page) => {
    setLoading(true);
    setPageNumber(page);
  };

  useEffect(()=>{
    fetchData();
    const intervalId = setInterval(fetchData, 300000);
    return () => clearInterval(intervalId);
  }, [pageNumber]);

  const handleShare = (event, videoUrl) => {
    // Step 2: Updated handleShare function
    event.stopPropagation();
    setShowMessage(!showMessage);
    setRedirectToVideoPage(false);
    setVideoUrlToShare(videoUrl); 
  };

  const handleCardClick = (video) => {
    setSelectedVideo(video);
    setRedirectToVideoPage(true);
    setShowMessage(false);
  };
  const navigateBackToCard = () => {
    setSelectedVideo(null);
    setRedirectToVideoPage(false);
  };

  if (redirectToVideoPage) {
    return (
      <ConsumerTrendVideoPlay
        selectedVideo={selectedVideo}
        navigateBackToCard={navigateBackToCard}
        selectedCategory={selectedCategory}
        consumerTrendsCategory={consumerTrendsCategory}
        selectedItemName={selectedItemName}
        selectedCountry={selectedCountry}
        pageNumber={pageNumber}
        pageSize={pageSize}
      />
    );
  }
  return isLoading ? ( //Check if is loading
    <Loader />
  ) : (
    <>
      <div className="containerwrapper-video mt-60">
        {allVideos.length > 0 ? (
          allVideos.map((item, i) => (
            <>
              <div
                className={`cardwrapper${
                  selectedVideo === item ? ' active' : ''
                }`}
                key={i}
                onClick={() => handleCardClick(item)}
              >
                <div className="card-container" tabIndex={0}>
                  <div className="card iframe-container" tabIndex={0}>
                    <iframe
                      src={`https://www.tiktok.com/embed/${item.videoId}`}
                      title="Video Player"
                      className="iframe"
                      allowFullScreen
                      scrolling="no"
                      allow="encrypted-media"
                    ></iframe>
                  </div>

                  <div className="expandable">
                    <ul className="card-icon-wrapper">
                      <li>
                        <FavouriteButton item={item} id={item.ownerId} />
                      </li>
                      <li>
                        <img
                          src={shareIcon}
                          alt="share link"
                          onClick={(event) =>
                            handleShare(
                              event,
                              `https://www.tiktok.com/embed/${item.videoId}`
                            )
                          }
                        />
                      </li>
                    </ul>
                    <div className="card-dscp mt-10">{item.videoTitle}</div>
                    <div className="hashtags mt-10">
                      {item.hashTags.slice(0, 5).map((tag, index) => (
                        <div key={index} className="hashtag-box">
                          #{tag}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                <p className="card-video-title mt-10 ml-20">
                  {item.videoTitle}
                </p>
              </div>
            </>
          ))
        ) : (
          <p>No Data Available for TikTok</p>
        )}
      </div>
      <SimplePagination
        current={pageNumber}
        pageSize={pageSize}
        total={totalCount}
        handlePageChange={handlePageChange}
      />

      {showMessage ? <ShareModal cardURL={videoUrlToShare} /> : null}
    </>
  );
}
