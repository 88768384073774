import React, { useState, useEffect, useMemo, useImperativeHandle, forwardRef } from 'react';
import style from './average-com.module.scss';
import LineCharts from '../line-charts/line-charts.jsx';
import AverageBarChart from './average-bar-chart.jsx';
import classNames from 'classnames';
import { utils, writeFile } from 'xlsx';
import NoData from '../no-data/no-data.jsx';
import imageToBeUpload from '../../../../assets/images/ratings-review/image-to-be-upload.jpg';
import { Image } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

const monthConstant = {
  '1': 'Jan',
  '2': 'Feb',
  '3': 'Mar',
  '4': 'Apr',
  '5': 'May',
  '6': 'Jun',
  '7': 'Jul',
  '8': 'Aug',
  '9': 'Sep',
  '10': 'Oct',
  '11': 'Nov',
  '12': 'Dec',
};

function dateFormate(dateNum) {
  const year = parseInt((dateNum + '').slice(0, 4));
  const month = parseInt((dateNum + '').slice(-2));
  const monthName = monthConstant[month];
  return {
    year,
    month: monthName,
    dateNum,
    value: `${monthName} ${year}`
  };
}

let AverageCom = ({products, tab}, ref) => {
  const [showBreakdown, setShowBreakdown] = useState(false);
  const [currentBreakdown, setCurrentBreakdown] = useState(null);
  const [options, setOptions] = useState({});
  const [xAxisData, setXAxisData] = useState([]);
  const [productsData, setProductsData] = useState([]);

  const colors = [
    '#00A32E',
    '#FF544F',
    '#FFBF00'
  ];

  const optionsFormat = (products, tab) => {
    let xData = [];
    const seriesData = [];
    products?.forEach(item => {
      item.data.forEach(child => {
        xData.push(child.time);
      });
    });
    xData = [...new Set(xData)].sort((a, b) => a - b).map(item => dateFormate(item));
    products?.forEach(product => {
      const itemData = {
        name: product.name,
        data: []
      };
      xData.forEach(child => {
        const index = product.data.findIndex(item => item.time === child.dateNum);
        if (index<0) {
          itemData.data.push({
            rating: null,
            score: null,
            sentiment_breakdown: {
              positive: 0,
              neutral: 0,
              negative: 0,
              reviews_count: 0
            },
            time: child.dateNum
          });
        } else {
          itemData.data.push(product.data[index]);
        }
      });
      seriesData.push(itemData);
    });
    setXAxisData(xData);
    setProductsData(seriesData);
    const option = {
      grid: {
        left: 40,
        right: 0,
        top: 24,
        bottom: 34
      },
      tooltip: {
        trigger: 'axis',
        backgroundColor: 'rgba(0,0,0,0.7)',
        borderColor: '#00A32E',
        textStyle: {
          color: '#FFFFFF'
        }
      },
      xAxis: {
        type: 'category',
        axisLine: {
          lineStyle: {
            color: '#00A32E'
          }
        },
        axisTick: {
          show: false
        },
        data: xData
      },
      yAxis: {
        type: 'value',
        axisLabel: {
          color: '#00A32E'
        },
        splitLine: {
          lineStyle: {
            color: 'rgba(0, 163, 46, .2)'
          }
        }
      },
      series: [
        ...seriesData.map((product, index) => {
          return {
            name: product.name,
            data: product.data.map(item => {
              return {
                value: tab === 'rating' ? item.rating : (tab === 'score' ? item.score : item.sentiment_breakdown.reviews_count),
                breakdown: {
                  reviewsCount: item.sentiment_breakdown.reviews_count,
                  positive: item.sentiment_breakdown.positive,
                  neutral: item.sentiment_breakdown.neutral,
                  negative: item.sentiment_breakdown.negative,
                }
              };
            }),
            type: 'line',
            symbol: 'circle',
            symbolSize: 3,
            label: {
              show: true,
              color: colors[index]
            },
            itemStyle: {
              color: colors[index]
            },
            lineStyle: {
              color: colors[index],
              width: '3'
            }
          };
        })
      ]
    };
    return option;
  };
  
  const chartClick = (val) => {
    !showBreakdown && setShowBreakdown(true);
    setCurrentBreakdown(val);
  };

  useEffect(() => {
    setShowBreakdown(false);
    setOptions(optionsFormat(products, tab));
  }, [products, tab]);

  const ChartsNode = useMemo(() => {
    return (
      <LineCharts options={options} chartClick={chartClick} />
    );
  }, [options]);

  const computeData = (fileType) => {
    // 定义双层表头
    const headers = [
      ['Date'],
      ['']
    ];
    // 定义数据
    const data = [];
    xAxisData.forEach(item => {
      data.push([item.value]);
    });
    productsData.forEach(product => {
      for (let i = 0; i < 6; i++) {
        headers[0].push(product.name);
      }
      headers[1].push(
        'Average Ratings', 
        'Average Sentiment Scores', 
        'Reviews Count', 
        'Sentiment Breakdown(Positive)', 
        'Sentiment Breakdown(Neutral)', 
        'Sentiment Breakdown(Negative)'
      );
      product.data.forEach((item, index) => {
        data[index].push(
          item.rating, 
          item.score, 
          item.sentiment_breakdown.reviews_count,
          item.sentiment_breakdown.positive,
          item.sentiment_breakdown.neutral,
          item.sentiment_breakdown.negative
        );
      });
    });
    const sheetData = [...headers, ...data];

    // 创建工作表
    const worksheet = utils.aoa_to_sheet(sheetData);

    // 合并单元格以创建双层表头
    const merge = [];
    productsData.forEach((_, index) => {
      merge.push(
        { s: { r: 0, c: index * 6 + 1 }, e: { r: 0, c: index * 6 + 6 } }
      );
    });
    worksheet['!merges'] = merge;

    // 列宽度
    const cols = [{ wch: 15 }];
    productsData.forEach(() => {
      cols.push(
        { wch: 15 },
        { wch: 23 },
        { wch: 15 },
        { wch: 30 },
        { wch: 30 },
        { wch: 30 }
      );
    });
    worksheet['!cols'] = cols;

    const wb = utils.book_new();
    utils.book_append_sheet(wb, worksheet, 'Sheet1');
    writeFile(wb, `Ratings & Review Main Average Data.${fileType}`);
  };

  useImperativeHandle(ref, () => ({
    exportExcel: () => {
      computeData('xlsx');
    },
    exportCsv: () => {
      computeData('csv');
    }
  }));

  return (
    <div className={classNames(style.averageCom, {[style.showBreakdown]: showBreakdown})}>
      <div className={style.productsBox}>
        {products.map(product => (
          <div key={product.name} className={style.productBox}>
            <div className={style.productImgBox}>
              <Image
                preview={false}
                width={38}
                height={38}
                src={product.img_url}
                fallback={imageToBeUpload}
                placeholder={
                  <div className={style.productImgPlaceholder}>
                    <LoadingOutlined />
                  </div>
                }
              />
            </div>
            <div className={style.productName} title={product.name}>{product.name}</div>
          </div>
        ))}
      </div>
      <div id="line-chart-box" className={classNames(style.chartsBox)}>
        { xAxisData.length ? ChartsNode : <NoData /> }
      </div>
      <div className={style.breakdownBox}>
        <div className={style.breakdownBoxContainer}>
          <div className={style.breakdownTitle}>Choose a data-point to see the breakdown</div>
          <div className={style.breakdownItemContainer}>
            <div className={style.breakdownItemName}>Reviews Count</div>
            <div className={style.reviewCountBox}>{currentBreakdown?.reviewsCount}</div>
          </div>
          <div className={style.breakdownItemContainer}>
            <div className={style.breakdownItemName}>Sentiment Breakdown</div>
            <div className={style.breakdownProgress}>
              { currentBreakdown && <AverageBarChart item={currentBreakdown} />}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

AverageCom = forwardRef(AverageCom);

export default AverageCom;