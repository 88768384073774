import React, { useMemo } from 'react';
import style from './table.module.scss';
import ColorCoding from '../../components/colorCoding.jsx';

export function TableBodyCellValue({
  className = '',
  index,
  value,
  reverse = false,
  threshold = [40, 70],
}) {
  const displayValue = useMemo(() => {
    if (index === 0) return value;
    if (typeof value !== 'number') return value;
    if (value === undefined || value === null) return 'Blank';
    return value.toFixed(2);
  }, [value]);

  const cv = useMemo(() => {
    if (typeof value !== 'number') return undefined;
    if (!reverse) {
      if (value < threshold[0]) return 1;
      else if (value < threshold[1]) return 2;
      else return 3;
    } else {
      if (value < threshold[0]) return 3;
      else if (value < threshold[1]) return 2;
      else return 1;
    }
  }, [value]);

  const cns = [style.cell];
  if (index === 0) {
    cns.push(style.cellFirst);
  }
  return (
    <td className={`${cns.join(' ')} ${className}`}>
      {index !== 0 ? (
        <ColorCoding colorValue={cv}>{displayValue}</ColorCoding>
      ) : (
        displayValue
      )}
    </td>
  );
}

export function TableBodyRowValue({
  className = '',
  values = [],
  reverse = false,
  threshold = [40, 70],
}) {
  return (
    <tr className={className}>
      {values.map((value, index) => {
        return (
          <TableBodyCellValue
            key={index}
            index={index}
            value={value}
            reverse={reverse}
            threshold={threshold}
          />
        );
      })}
    </tr>
  );
}

export function TableHeaderCell({ className = '', children }) {
  return (
    <th className={`${style.headerCell} ${className}`}>{children ?? ''}</th>
  );
}

export function PackageAiTableValue1({
  className = '',
  labels = [],
  children,
}) {
  return (
    <table className={`${style.table} ${className}`}>
      <thead>
        <tr>
          {labels.map((label, index) => {
            return <TableHeaderCell key={index}>{label}</TableHeaderCell>;
          })}
        </tr>
      </thead>
      <tbody>{children}</tbody>
    </table>
  );
}
