import { App } from 'antd';
import React, { useEffect, useState } from 'react';
import { downloadWb } from '../../../apis/file/index.js';
import BarChart from '../charts/bar.jsx';
import style from './tab.module.scss';

function OccasionsAndNeedStates({
  filter,
  compare,
  hasCompare,
  exportCount,
  isActive,
}) {
  const app = App.useApp();
  const { message } = app;

  const saveDataFns = [];
  function appendSaveDataFn(fn) {
    saveDataFns.push(fn);
  }

  useEffect(() => {
    if (!isActive) return;
    if (!exportCount) return;
    downloadWb(saveDataFns, 'xlsx', 'Occasions and Needstates', message);
  }, [exportCount]);

  const [userSelectChart, setUserSelectChart] = useState('');
  const [userSelectChartKey, setUserSelectChartKey] = useState('');
  const [userSelectChartValue, setUserSelectChartValue] = useState('');
  function onUserSelectChart(name, key, value) {
    setUserSelectChart(name);
    setUserSelectChartKey(key);
    setUserSelectChartValue(value);
  }

  return (
    <div className={style.chartContainer}>
      <BarChart
        name="occasion"
        tabName="occasions-and-need-states"
        title="Occasion"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Occasion"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <BarChart
        name="needstates"
        tabName="occasions-and-need-states"
        title="Needstate"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Needstate"
        hiddenItems={['Noise', 'Keep', 'Delete']}
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
      <BarChart
        name="specific-needstates"
        tabName="occasions-and-need-states"
        title="Specific Needstates"
        filter={filter}
        compare={compare}
        hasCompare={hasCompare}
        size="3"
        appendSaveDataFn={appendSaveDataFn}
        chartKey="Specific Needstates"
        userSelectChart={userSelectChart}
        userSelectChartKey={userSelectChartKey}
        userSelectChartValue={userSelectChartValue}
        onUserSelectChart={onUserSelectChart}
      />
    </div>
  );
}

export default OccasionsAndNeedStates;
