import service from '../../../../util/pythonApi';

export function postPredictPackage(data) {
  return service.request({
    url: '/v1/pred_package',
    method: 'post',
    data,
  });
}

export function getPredictPackage(id) {
  return service.request({
    url: `/v1/pred_package/${id}`,
    method: 'get',
  });
}

export function getPredictPackageList(query = {}, page = 1, pageSize = 12) {
  const params = {
    page,
    page_size: pageSize,
  };
  const { category, brand, favorite, share } = query;
  if (category) {
    params.category = category;
  }
  if (brand) {
    params.brand_ul = brand;
  }
  if (favorite) {
    params.is_favorited = 1;
  }
  if (share) {
    params.is_share = 1;
  } else {
    params.is_share = 0;
  }
  return service.request({
    url: '/v1/pred_package',
    method: 'get',
    params,
  });
}

export function putPredictPackageLike(id, like) {
  return service.request({
    url: `/v1/pred_package/${id}`,
    method: 'put',
    params: {
      like: like ? 'true' : 'false',
    },
  });
}

export function getPredictPackageFilter() {
  return service.request({
    url: '/v1/pred_package_filter',
    method: 'get',
  });
}
