import React, { useState, useCallback, useEffect } from 'react';
import style from './style.module.scss';
import { Input, Table, Image, Tooltip, Select, Button, Modal, Space } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { fetchList, fetchCountries, deleteConsumerTheater } from '../../api/request.js';

const antIcon = (
  <LoadingOutlined
    style={{
      fontSize: 30,
      color: '#00FF00'
    }}
    spin
  />
);

function debounce (callback, delay) {
  let lastTime;
  return function () {
    clearTimeout(lastTime);
    const [that, args] = [this, arguments];
    lastTime = setTimeout(() => {
      callback.apply(that, args);
    }, delay);
  };
}

let requestIndex = Date.now();

const OverviewPage = () => {

  // country
  const [countryValue, setCountryValue] = useState(null);
  const [countryOptions, setCountryOptions] = useState([]);
  useEffect(() => {
    fetchCountries()
      .then(res => {
        const countries = res.data?.map(item => (
          {
            name: item.country,
            value: item.country
          }
        ));
        setCountryOptions(countries);
      })
      .catch(error => console.error(error));
  }, []);

  // topic
  const [topicValue, setTopicValue] = useState(null);
  // search
  const [searchValue, setSearchValue] = useState(null);
  // external_id
  const [externalIdValue, setExternalIdValue] = useState(null);

  // table
  const [tableData, setTableData] = useState([]);
  const [tableLoading, setTableLoading] = useState(true);
  const [total, setTotal] = useState(0);
  const [current, setCurrent] = useState(1);
  const [pageSize, setPageSize] = useState(20);

  const paginateChange = (pagination) => {
    const {current, pageSize} = pagination;
    setCurrent(current);
    setPageSize(pageSize);
  };

  const fetchTopicDetailData = (params) => {
    setTableLoading(true);
    return fetchList({...params, sort: '-id'}, requestIndex)
      .then(res => {
        if (requestIndex !== res.config.uuid) return;
        setTableData(Array.isArray(res.data.data) ? res.data.data : []);
        setTotal(res.data.total);
        setCurrent(res.data.current);
        setPageSize(res.data.size);
        setTableLoading(false);
      })
      .catch(error => {
        if (requestIndex !== error?.config?.uuid) return;
        setTableLoading(false);
      });
  };

  const delayFetchTableData = useCallback(debounce(function(val) { return fetchTopicDetailData(val);}, 500), []);

  useEffect(() => {
    const params = {
      search: searchValue,
      country: countryValue,
      topic: topicValue,
      external_id: externalIdValue,
      page: current,
      page_size: pageSize,
    };
    setSelectIds([]);
    requestIndex++;
    delayFetchTableData(params);
  }, [current, pageSize, searchValue, countryValue, topicValue, externalIdValue]);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [videoUrl, setVideoUrl] = useState('');

  const previewVideo = (url) => {
    setVideoUrl(url);
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  // 删除
  const deleteFn = async (value) => {
    try {
      setTableLoading(true);
      await deleteConsumerTheater({id: value.id});
      requestIndex++;
      const params = {
        search: searchValue,
        country: countryValue,
        topic: topicValue,
        external_id: externalIdValue,
        page: current,
        page_size: pageSize,
      };
      await fetchTopicDetailData(params);
    } catch (error) {
      setTableLoading(false);
      console.error(error);
    }
  };

  const columns = [
    {
      title: 'id',
      dataIndex: 'id',
      width: '100px',
      align: 'center',
    },
    {
      title: 'external_id',
      dataIndex: 'external_id',
      width: '120px',
    },
    {
      title: 'source',
      dataIndex: 'source',
      width: '120px',
      ellipsis: {
        showTitle: false,
      },
      render: source => (
        <Tooltip placement="topLeft" title={source}>
          {source}
        </Tooltip>
      ),
    },
    {
      title: 'title',
      dataIndex: 'title',
      width: '200px',
      ellipsis: {
        showTitle: false,
      },
      render: title => (
        <Tooltip placement="topLeft" title={title}>
          {title}
        </Tooltip>
      ),
    },
    {
      title: 'transcription',
      dataIndex: 'transcription',
      width: '200px',
      ellipsis: {
        showTitle: false,
      },
      render: transcription => (
        <Tooltip placement="topLeft" title={transcription}>
          {transcription}
        </Tooltip>
      ),
    },
    {
      title: 'category',
      dataIndex: 'category',
      width: '200px',
      ellipsis: {
        showTitle: false,
      },
      render: category => (
        <Tooltip placement="topLeft" title={category}>
          {category}
        </Tooltip>
      ),
    },
    {
      title: 'topic',
      dataIndex: 'topic',
      width: '200px',
      ellipsis: {
        showTitle: false,
      },
      render: topic => (
        <Tooltip placement="topLeft" title={topic}>
          {topic}
        </Tooltip>
      ),
    },
    {
      title: 'lifeStage',
      dataIndex: 'lifeStage',
      width: '200px',
      ellipsis: {
        showTitle: false,
      },
      render: lifeStage => (
        <Tooltip placement="topLeft" title={lifeStage}>
          {lifeStage}
        </Tooltip>
      ),
    },
    {
      title: 'dietary',
      dataIndex: 'dietary',
      width: '200px',
      ellipsis: {
        showTitle: false,
      },
      render: dietary => (
        <Tooltip placement="topLeft" title={dietary}>
          {dietary}
        </Tooltip>
      ),
    },

    {
      title: 'age',
      dataIndex: 'age',
      width: '200px',
      ellipsis: {
        showTitle: false,
      },
      render: age => (
        <Tooltip placement="topLeft" title={age}>
          {age}
        </Tooltip>
      ),
    },
    {
      title: 'socialClass',
      dataIndex: 'socialClass',
      width: '200px',
      ellipsis: {
        showTitle: false,
      },
      render: socialClass => (
        <Tooltip placement="topLeft" title={socialClass}>
          {socialClass}
        </Tooltip>
      ),
    },
    {
      title: 'geographic',
      dataIndex: 'geographic',
      width: '200px',
      ellipsis: {
        showTitle: false,
      },
      render: geographic => (
        <Tooltip placement="topLeft" title={geographic}>
          {geographic}
        </Tooltip>
      ),
    },
    {
      title: 'country',
      dataIndex: 'country',
      width: '200px',
      ellipsis: {
        showTitle: false,
      },
      render: country => (
        <Tooltip placement="topLeft" title={country}>
          {country}
        </Tooltip>
      ),
    },
    {
      title: 'tags',
      dataIndex: 'tags',
      width: '200px',
      ellipsis: {
        showTitle: false,
      },
      render: tags => (
        <Tooltip placement="topLeft" title={tags?.join(', ')}>
          {tags?.join(', ')}
        </Tooltip>
      ),
    },
    {
      title: 'image_url',
      dataIndex: 'image_url',
      width: '160px',
      render: (_, { image_url }) => {
        return (image_url ? <Image src={image_url} width={100} height={50} /> : 'No pictures yet');
      },
    },
    {
      title: 'video_url',
      dataIndex: 'video_url',
      width: '160px',
      render: (_, { video_url }) => {
        return (video_url ? <Button onClick={() => previewVideo(video_url)}>Preview video</Button> : 'No video yet');
      },
    },
    {
      title: 'handle',
      width: '100px',
      align: 'center',
      render: (_, item) => {
        return (<Space size="middle">
          <Button loading={item['_loading']} size='small' onClick={() => deleteFn(item)}>delete</Button>
        </Space>
        );
      },
    }
  ];

  const [selectIds, setSelectIds] = useState([]);
  const [btnLoading, setBtnLoading] = useState(false);

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectIds(selectedRows.map(item => item.id));
    }
  };

  const batchDelete = async () => {
    try {
      setTableLoading(true);
      setBtnLoading(true);
      await deleteConsumerTheater({ids: selectIds});
      requestIndex++;
      const params = {
        search: searchValue,
        country: countryValue,
        topic: topicValue,
        external_id: externalIdValue,
        page: current,
        page_size: pageSize,
      };
      await fetchTopicDetailData(params);
    } catch (error) {
      setTableLoading(false);
      console.error(error);
    }
    setBtnLoading(false);
  };

  return <div className={style.pageContainer}>
    <div className={style.pageHeader}>Overview</div>
    <div className={style.selectBox}>
      <div className={style.selectItem}>
        <div className={style.selectLabel} style={{width: '60px'}}>search</div>
        <Input
          placeholder="" 
          allowClear
          value={searchValue}
          onChange={e => setSearchValue(e.target.value)}
        />
      </div>
      <div className={style.selectItem}>
        <div className={style.selectLabel} style={{width: '80px'}}>country </div>
        <Select
          style={{ width: '100%' }}
          placeholder="select one country"
          allowClear
          value={countryValue}
          onChange={(newValue) => {
            setCountryValue(newValue);
          }}
          optionLabelProp="label"
          showSearch
          options={countryOptions}
          popupMatchSelectWidth={false}
        />
      </div>
    </div>
    <div className={style.selectBox}>
      <div className={style.selectItem}>
        <div className={style.selectLabel} style={{width: '60px'}}>topic</div>
        <Input
          placeholder="" 
          allowClear
          value={topicValue}
          onChange={e => setTopicValue(e.target.value)}
        />
      </div>
      <div className={style.selectItem}>
        <div className={style.selectLabel} style={{width: '80px'}}>external_id</div>
        <Input
          placeholder="" 
          allowClear
          value={externalIdValue}
          onChange={e => setExternalIdValue(e.target.value)}
        />
      </div>
    </div>
    <div className={style.tableBox}>
      <Button 
        type="primary" 
        onClick={batchDelete} 
        disabled={!selectIds.length} 
        loading={btnLoading}
        style={{marginBottom: '10px'}}
      >delete in batches</Button>
      <Table
        rowKey="uuid"
        bordered
        columns={columns}
        dataSource={tableData}
        loading={{spinning: tableLoading, indicator: antIcon}}
        onChange={paginateChange}
        scroll={{
          x: 2740
        }}
        rowSelection={{
          ...rowSelection,
        }}
        pagination={{
          position: ['bottomCenter'],
          showTotal: total => `Total ${total}`,
          showQuickJumper: true,
          current,
          pageSize,
          total,
          showSizeChanger: true,
          pageSizeOptions: [10, 20, 30, 40],
        }}
      />
    </div>
    <Modal title="" destroyOnClose  width={800}  open={isModalOpen} footer={null} onCancel={handleCancel}>
      <div style={{width: '100%', marginTop: '20px'}}>
        <video controls width={'100%'}>
          <source src={videoUrl} />
          Your browser does not support the video.
        </video>
      </div>
    </Modal>
  </div>;
};

export default OverviewPage;