// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Zu19c1Ttvui53pXZPT5R{position:absolute;right:15px;top:15px;z-index:2;display:flex;flex-direction:column;align-items:center}.xn03hsJKTcr4tsaiq8JQ{margin-bottom:16px;padding:3px;border-radius:2px;font-size:20px;color:#00a32e;background-color:#000;transition:background-color .3s}.xn03hsJKTcr4tsaiq8JQ.z7xszPC4aEvxmUoSWKH5{background-color:#313131}`, "",{"version":3,"sources":["webpack://./src/modules/package-ai-generation/views/update/cursor.module.scss"],"names":[],"mappings":"AAAA,sBACE,iBAAA,CACA,UAAA,CACA,QAAA,CACA,SAAA,CACA,YAAA,CACA,qBAAA,CACA,kBAAA,CAGF,sBACE,kBAAA,CACA,WAAA,CACA,iBAAA,CACA,cAAA,CACA,aAAA,CACA,qBAAA,CACA,+BAAA,CAEA,2CACE,wBAAA","sourcesContent":[".cursorBox {\n  position: absolute;\n  right: 15px;\n  top: 15px;\n  z-index: 2;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n}\n\n.cursorBtn {\n  margin-bottom: 16px;\n  padding: 3px;\n  border-radius: 2px;\n  font-size: 20px;\n  color: #00a32e;\n  background-color: #000;\n  transition: background-color 0.3s;\n\n  &.active {\n    background-color: #313131;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cursorBox": `Zu19c1Ttvui53pXZPT5R`,
	"cursorBtn": `xn03hsJKTcr4tsaiq8JQ`,
	"active": `z7xszPC4aEvxmUoSWKH5`
};
export default ___CSS_LOADER_EXPORT___;
