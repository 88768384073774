import React from 'react';

function PackageAiImg({ className, src, alt, onClick }) {
  return (
    <img
      className={className}
      src={src}
      alt={alt}
      loading="lazy"
      onClick={() => {
        if (!src) return;
        onClick(src, alt);
      }}
    />
  );
}

export default PackageAiImg;
