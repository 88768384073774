import React from 'react';
import learningBgVideo from '../../../../assets/videos/bu_video.mp4';

const BaseLayout = ({children}) => {
  return <>
    <div className="sidebar-right-layout" style={{margin: 0}}>
      <video autoPlay muted className="learning-bg-video" loop>
        <source src={learningBgVideo} type="video/mp4" />
      </video>
      <div style={{position: 'relative', zIndex: 1}}>
        {children}
      </div>
    </div>
  </>;
};

export default BaseLayout;