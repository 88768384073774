/* eslint-disable import/no-unresolved */
import React, { useEffect, useState } from 'react';
import style from './header.module.scss';
import { ConfigProvider, Dropdown, Menu, Space } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import EventBus from '@/modules/top-dish/hooks/eventBus';
import { topDishGetList } from '@/modules/top-dish/api/request';
import { itemsRight } from '@/modules/top-dish/components/list-main/SpreadSheet';

import cius from '../../img/ci-us.png';
import ciuk from '../../img/ci-uk.png';
import ciyn from '@/modules/top-dish/img/ci-yn.png';
import ciph from '@/modules/top-dish/img/ci-ph.png';
import cibx from '@/modules/top-dish/img/ci-bx.png';
import ciagt from '@/modules/top-dish/img/ci-agt.png';
import cimxg from '@/modules/top-dish/img/ci-mxg.png';
import cibls from '@/modules/top-dish/img/ci-bls.png';
import cidg from '@/modules/top-dish/img/ci-dg.png';
import cifg from '@/modules/top-dish/img/ci-fg.png';
import citg from '@/modules/top-dish/img/ci-tg.png';
import ciydn from '@/modules/top-dish/img/ci-ydn.png';
import cinf from '@/modules/top-dish/img/ci-nf.png';
import cigl from '@/modules/top-dish/img/ci-gl.png';
import cifl from '@/modules/top-dish/img/ci-fl.png';
import cixby from '@/modules/top-dish/img/ci-xby.png';
import cird from '@/modules/top-dish/img/ci-rd.png';
import ciad from '@/modules/top-dish/img/ci-ad.png';

const itemsLeftDefault = [
  {
    label: 'Overall Top Dish',
    key: 'dataA',
  },
  {
    label: 'Bouillon',
    key: 'dataB',
  },
  {
    label: 'Mayonnaise',
    key: 'dataC',
  },
  {
    label: 'OOH',
    key: 'dataD',
  },
];

const PlantHeader = () => {
  const [current, setCurrent] = useState('dataA');
  const [showKey, setShowKey] = useState('United States');
  const [itemsLeft, setItemsLeft] = useState(itemsLeftDefault);
  const [data, setData] = useState({});

  useEffect(() => {
    //挂载
    EventBus.addEventListener('topDishTitleKeyEvent', topDishTitleKeyEvent);
    return () => {
      //卸载
      EventBus.removeEventListener(
        'topDishTitleKeyEvent',
        topDishTitleKeyEvent
      );
    };
  }, [showKey]);

  const topDishTitleKeyEvent = (e) => {
    setShowKey(e.detail.key);
    let a = [];
    if (e.detail.data.dataA.title[0] !== '空')
      a.push({ label: 'Overall Top Dish', key: 'dataA' });
    if (e.detail.data.dataB.title[0] !== '空')
      a.push({ label: 'Bouillon', key: 'dataB' });
    if (e.detail.data.dataC.title[0] !== '空') {
      if (e.detail.key === 'Colombia') {
        a.push({ label: 'Ketchup', key: 'dataC' });
        EventBus.dispatchEvent('topDishHeaderKetchupEvent', { ketchup: true });
      } else {
        a.push({ label: 'Mayonnaise', key: 'dataC' });
        EventBus.dispatchEvent('topDishHeaderKetchupEvent', { Ketchup: false });
      }
    }
    if (e.detail.data.dataD.title[0] !== '空')
      a.push({ label: 'OOH', key: 'dataD' });
    setItemsLeft(a);
    console.log('showKey接收到传值啦！~~~~~~', e.detail);
  };

  const onClickMenu = async (e) => {
    setShowKey(e.key);
    setCurrent('dataA');
    EventBus.dispatchEvent('topDishHeaderEvent', 'dataA');
    const result = await topDishGetList({ key: e.key });
    setData(result.data)
    EventBus.dispatchEvent('topDishTitleKeyEvent', {
      key: e.key,
      data: result.data,
    });
    EventBus.dispatchEvent('topDishListEvent', {
      country: e.key,
      data: result.data,
    });
  };

  const onClick = async (e) => {
    setCurrent(e.key);
    EventBus.dispatchEvent('topDishHeaderEvent', e.key);
    EventBus.dispatchEvent('topDishTitleKeySetTopEvent', { key: current });
  };

  const [topStyle, setTopStyle] = useState(false);

  const HandleScroll = (e) => {
    const winHeight = window.innerHeight;
    const scrollTop =
      (e.srcElement ? e.srcElement.documentElement.scrollTop : false) ||
      window.pageYOffset ||
      (e.srcElement ? e.srcElement.body.scrollTop : 0);

    if (winHeight < scrollTop) {
      setTopStyle(true);
    } else {
      setTopStyle(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', HandleScroll);

    return () => {
      window.removeEventListener('scroll', HandleScroll);
    };
  }, []);

  return (
    <div className={topStyle && style.djTop}>
      <div className={style.headerWrap}>
        <ConfigProvider
          theme={{
            components: {
              Menu: {
                /* 这里是你的组件 token */
                colorSplit: 'rgba(0,0,255,0)',
                fontSize: 16,
                colorBgTextHover: '#FFF',
              },
            },
          }}
        >
          <Menu
            className={style.headerMenu}
            onClick={onClick}
            selectedKeys={[current]}
            mode="horizontal"
            items={itemsLeft}
          />
          <div className={style.headerRight}>
            <ConfigProvider
              theme={{
                components: {
                  Dropdown: {
                    paddingBlock: 12,
                  },
                },
              }}
            >
              <Dropdown
                menu={{ items: itemsRight, onClick: onClickMenu }}
                overlayStyle={{ width: '220px' }}
                className={style.headerRightMenu}
                trigger="click"
              >
                <span onClick={(e) => e.preventDefault()}>
                  <Space>
                    <a href="" style={{ color: '#FFF' }}>
                      {showKey}
                      <DownOutlined style={{ margin: '0 15px' }} />
                    </a>
                  </Space>
                </span>
              </Dropdown>
            </ConfigProvider>

            {showKey === 'United States' && <img src={cius} alt="" />}

            {showKey === 'Brazil' && <img src={cibx} alt="" />}
            {showKey === 'Argentina' && <img src={ciagt} alt="" />}
            {showKey === 'Mexico' && <img src={cimxg} alt="" />}

            {showKey === 'United Kingdom' && <img src={ciuk} alt="" />}
            {showKey === 'Belgium' && <img src={cibls} alt="" />}
            {showKey === 'Germany' && <img src={cidg} alt="" />}
            {showKey === 'France' && <img src={cifg} alt="" />}

            {showKey === 'Philippines' && <img src={ciph} alt="" />}
            {showKey === 'Vietnam' && <img src={ciyn} alt="" />}
            {showKey === 'Thailand' && <img src={citg} alt="" />}

            {showKey === 'Indonesia' && <img src={ciydn} alt="" />}

            {showKey === 'South Africa' && <img src={cinf} alt="" />}
            {showKey === 'Colombia' && <img src={cigl} alt="" />}

            {showKey === 'Finland' && <img src={cifl} alt="" />}
            {showKey === 'Spain' && <img src={cixby} alt="" />}
            {showKey === 'Sweden' && <img src={cird} alt="" />}

            {showKey === 'Australia' && <img src={ciad} alt="" />}
          </div>
        </ConfigProvider>
      </div>
    </div>
  );
};
export default PlantHeader;
