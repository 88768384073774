import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import style from './index.module.scss';
import { Input } from 'antd';
import TextArea from 'antd/es/input/TextArea';

const IntroCom = ({initData, onValueChange = () => {}}) => {
  const [formObj, setFormObj] = useState({});

  useEffect(() => {
    setFormObj(initData);
  }, [initData]);

  useEffect(() => {
    onValueChange(formObj);
  }, [formObj]);

  const fnValChange = (e, field) => {
    const val = e.target.value;
    const tempFormObj = {...formObj};
    tempFormObj[field] = val;
    setFormObj(tempFormObj);
  };

  return <div className={classNames(style.rightWrap)}>
    <div className={classNames(style.ctrlBox)}>
      <div className={classNames(style.label)}>Name:</div>
      <Input placeholder="Enter an name" value={formObj?.name} onChange={(e) => fnValChange(e, 'name')} />
    </div>
    <div className={classNames(style.ctrlBox)}>
      <div className={classNames(style.label)}>When:</div>
      <Input placeholder="" value={formObj?.when} onChange={(e) => fnValChange(e, 'when')} />
    </div>
    <div className={classNames(style.ctrlBox)}>
      <div className={classNames(style.label)}>Where:</div>
      <Input placeholder="" value={formObj?.where} onChange={(e) => fnValChange(e, 'where')} />
    </div>
    <div className={classNames(style.ctrlBox)}>
      <div className={classNames(style.label)}>Introduction:</div>
      <TextArea
        placeholder="Enter an introduction"
        autoSize={{ maxRows: 9.5, minRows: 9.5 }}
        value={formObj?.intro} onChange={(e) => fnValChange(e, 'intro')}
      />
    </div>
  </div>;
};

export default IntroCom;