import React from 'react';
import { ReactComponent as VideoDirSvg } from '../../../assets/svgs/videodir.svg';
import SvgCom from '../../../components/SvgCom/index.jsx';
import { cn } from '../../../utils/utils';

const ProjectCardCom = ({ className ='' }) => {
  return (
    <div className={cn('bg-grey-1 rounded-xl p-[15px]', className)}>
      <div className="flex items-center justify-between mb-[15px]">
        <span className="text-lg font-semibold leading-none">Project1</span>
        <div className="py-[8px] px-[16px] text-xs leading-none rounded-xl bg-primary text-white font-bold">Active</div>
      </div>
      <div className="flex justify-start items-center text-sm leading-none text-grey-3 mb-[15px]">
        <span>Project ID</span>
        <span className="text-black ml-2 flex-1 text-right">234313</span>
        <div className="h-5 w-[1px] mx-4 bg-[#D9D9D9]"></div>
        <span className="">Wed, Jul 17, 2024</span>
      </div>
      <div className="py-[10px] px-[15px] text-xs leading-none rounded-2xl bg-second flex items-center w-fit min-w-[120px]">
        <SvgCom Svg={VideoDirSvg} size="14"></SvgCom>
        <span className="text-primary mx-2">Video</span>
        <span className="font-bold text-black">1</span>
      </div>
    </div>
  );
};

export default ProjectCardCom;