import { MoreOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Input, message } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { useSessionStorage } from '../../../hooks/useSessionStorage.js';
import { getKnorr } from '../apis/serve/index.js';
import SortSvg from '../assets/img/sort.svg';
import KnorrFilter from './filter.jsx';
import KnorrItem from './item.jsx';
import KnorrListEmpty from './list-empty.jsx';
import style from './list.module.scss';
import KnorrSelect from './select.jsx';

/**
 * @param {import("../apis/serve").KnorrData} data
 * @param {boolean} isAdmin
 * @param {string} userId
 * @param {(id:string)=>void} onDelete
 */
function knorrItem(data, isAdmin, userId, onDelete) {
  return (
    <KnorrItem
      data={data}
      key={data.id}
      isAdmin={isAdmin}
      userId={userId}
      onDelete={onDelete}
    />
  );
}

function KnorrListMine({ uploadTime, scrollCount }) {
  const [loading, setLoading] = useState(false);
  async function waitForLoading() {
    if (!loading) return;
    await new Promise((r) => setTimeout(r, 100));
    await waitForLoading();
  }
  const [noMore, setNoMore] = useState(false);
  const [list, setList] = useState([]);
  const [search, setSearch] = useState(undefined);
  const [sort, setSort] = useState('fav_count desc');
  const [filter, setFilter] = useState({});
  const [user] = useSessionStorage('user', {});

  /**
   * @type {boolean}
   */
  const isAdmin = useMemo(() => {
    return user.is_supervisor;
  });
  /**
   * @type {number}
   */
  const userId = useMemo(() => {
    return user.id;
  });

  async function readKnorr(clear = false) {
    await waitForLoading();
    setLoading(true);
    try {
      const query = { mine: true };
      if (search) {
        query.title = search;
      }
      if (sort) {
        query.sort = sort;
      }
      if (filter.category?.length) {
        query.category = filter.category;
      }
      if (filter.market?.length) {
        query.market = filter.market;
      }
      const { data } = await getKnorr(query, clear ? 0 : list.length, 12);
      if (!data) {
        message.warning('Read data fail');
        return;
      }
      const { data: dataList } = data;
      if (dataList.length < 12) setNoMore(true);
      if (clear) {
        setList(dataList);
      } else {
        const idMap = list.map((e) => e.id);
        const fr = dataList.filter((e) => {
          return !idMap.includes(e.id);
        });
        setList(list.concat(fr));
      }
    } catch (err) {
      message.error('Read data error');
      console.error('read data error', err);
    }
    setLoading(false);
  }
  useEffect(() => {
    setList([]);
    void readKnorr(true);
  }, [uploadTime, search, filter, sort]);

  function nextPage() {
    void readKnorr();
  }
  useEffect(() => {
    if (scrollCount > 0) nextPage();
  }, [scrollCount]);

  /**
   * @param {string} id
   */
  function onDelete(id) {
    const index = list.findIndex((item) => item.id === id);
    if (index === -1) return;
    setList([...list.slice(0, index), ...list.slice(index + 1)]);
  }

  return (
    <>
      <div className={style.listHeader}>
        <Input
          variant="filled"
          placeholder="Search"
          prefix={<SearchOutlined></SearchOutlined>}
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          style={{ width: '400px' }}
          allowClear={true}
        ></Input>
        <div className={style.listHearderFilter}>
          <KnorrFilter
            className={style.listHearderFilterItem}
            setFilter={setFilter}
          ></KnorrFilter>
          <KnorrSelect
            className={style.listHearderFilterItem}
            value={sort}
            options={[
              { label: 'Likes - Low to High', value: 'fav_count asc' },
              { label: 'Likes - High to Low', value: 'fav_count desc' },
              { label: 'Date - Old to New', value: 'created_at asc' },
              { label: 'Date - New to Old', value: 'created_at desc' },
            ]}
            onChange={setSort}
            preIcon={SortSvg}
            placeholder="Sort"
          ></KnorrSelect>
        </div>
      </div>
      <div className={style.listContent}>
        {list.length === 0 ? (
          <KnorrListEmpty />
        ) : (
          list.map((e) => knorrItem(e, isAdmin, userId, onDelete))
        )}
      </div>
      <div
        className={style.listLoadingLine}
        style={{ display: noMore ? 'none' : '' }}
      >
        <Button
          loading={loading}
          icon={<MoreOutlined />}
          onClick={nextPage}
          style={{ display: 'none' }}
        >
          More
        </Button>
      </div>
    </>
  );
}

export default KnorrListMine;
