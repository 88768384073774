import {
  CloseOutlined,
  HeartFilled,
  HeartOutlined,
  ShareAltOutlined,
} from '@ant-design/icons';
import { message } from 'antd';
import React, { useEffect, useState } from 'react';
import { getTag } from '../apis/serve';
import DefaultPng from '../assets/img/default.png';
import style from './detail.module.scss';

function onShare(id) {
  const u = new URL(window.location.href);
  u.pathname = u.pathname.concat(`/${id}`);
  console.log('share', u.toString());
  navigator.clipboard.writeText(u.toString());
  message.success('Link copied to clipboard');
}

function KnorrVideo({ video, cover }) {
  const [coverError, setCoverError] = useState(false);

  return (
    <div className={style.detailMediaContainer}>
      <img
        className={style.detailImage}
        src={cover}
        alt="knorr"
        style={{ display: 'none' }}
        onError={() => setCoverError(true)}
      ></img>
      <video
        className={style.detailVideo}
        src={video}
        controls
        poster={coverError? DefaultPng:cover}
        preload="none"
      ></video>
    </div>
  );
}

function KnorrImage({ src }) {
  return <img className={style.detailImage} src={src} alt="knorr"></img>;
}

function KnorrImageBtn({ index, setIndex, current }) {
  return (
    <div
      className={`${style.detailImageBtn} ${
        index === current ? style.detailImageBtnActive : ''
      }`}
      onClick={() => setIndex(index)}
    ></div>
  );
}

function KnorrImages({ images }) {
  const [index, setIndex] = useState(0);

  return (
    <div className={style.detailMediaContainer}>
      <div
        className={style.detailImageContainer}
        style={{
          transform: `translateX(-${index * 100}%)`,
        }}
      >
        {images.map((img, idx) => (
          <KnorrImage key={idx} src={img}></KnorrImage>
        ))}
      </div>
      {images.length > 1 && (
        <div className={style.detailImageBtnGroup}>
          {images.map((img, idx) => (
            <KnorrImageBtn
              key={idx}
              index={idx}
              setIndex={setIndex}
              current={index}
            ></KnorrImageBtn>
          ))}
        </div>
      )}
    </div>
  );
}

function KnorrDetail({
  data,
  mediaType,
  images,
  video,
  cover,
  isFav,
  favCountStr,
  onFav,
  onClose,
}) {
  const [category, setCategory] = useState([]);
  const [market, setMarket] = useState([]);

  async function readTags() {
    try {
      const { data: res } = await getTag(data.id);
      if (!res) return;
      setCategory(res.filter((e) => e.type === 'category').map((e) => e.value));
      setMarket(res.filter((e) => e.type === 'market').map((e) => e.value));
    } catch (err) {
      console.error('read tags error', err);
    }
  }
  useEffect(() => {
    void readTags();
  }, [data]);

  return (
    <div className={style.detailContainer}>
      <div className={style.detailInner}>
        <div className={style.detailLeft}>
          {mediaType === 'video' ? (
            <KnorrVideo video={video} cover={cover}></KnorrVideo>
          ) : (
            <KnorrImages images={images}></KnorrImages>
          )}
          <div className={style.detailLeftBottom}>
            <div>
              {category.length > 0 && (
                <div className={style.detailLine}>
                  <span
                    className={`${style.detailLabel} ${style.detailLabelInline}`}
                  >
                    Topic:
                  </span>
                  <span className={style.detailText}>
                    {category.join(', ')}
                  </span>
                </div>
              )}
              {market.length > 0 && (
                <div className={style.detailLine} style={{ marginTop: '16px' }}>
                  <span
                    className={`${style.detailLabel} ${style.detailLabelInline}`}
                  >
                    Market:
                  </span>
                  <span className={style.detailText}>{market.join(', ')}</span>
                </div>
              )}
            </div>
            <div>
              <div
                className={style.detailShare}
                onClick={() => onShare(data.id)}
              >
                <ShareAltOutlined></ShareAltOutlined>
              </div>
              <div
                className={`${style.detailFav} ${
                  isFav ? style.detailFavActive : ''
                }`}
                onClick={onFav}
              >
                {isFav ? (
                  <HeartFilled></HeartFilled>
                ) : (
                  <HeartOutlined></HeartOutlined>
                )}
                <span>{favCountStr}</span>
              </div>
            </div>
          </div>
        </div>
        <div className={style.detailRight}>
          <div>
            <span className={style.detailLabel}>Title:</span>
          </div>
          <div className={style.detailTextLine}>
            <span className={style.detailText}>{data.title}</span>
          </div>
          {data.owner && (
            <>
              <div className={style.detailDataLine}>
                <span className={style.detailLabel}>Owner(s):</span>
              </div>
              <div className={style.detailTextLine}>
                <span className={style.detailText}>{data.owner}</span>
              </div>
            </>
          )}
          {data.description && (
            <>
              <div className={style.detailDataLine}>
                <span className={style.detailLabel}>What`s my Learning:</span>
              </div>
              <div className={style.detailTextLine}>
                <span className={style.detailText}>{data.description}</span>
              </div>
            </>
          )}
          {data.url && (
            <>
              <div className={style.url}>
                <span className={style.detailLabel}>URL:</span>
              </div>
              <div className={style.detailTextLine}>
                <a
                  className={`${style.detailText} ${style.detailUrl}`}
                  href={data.url}
                >
                  {data.url}
                </a>
              </div>
            </>
          )}
        </div>
      </div>
      <div className={style.detailClose} onClick={onClose}>
        <CloseOutlined></CloseOutlined>
      </div>
    </div>
  );
}

export default KnorrDetail;
