import React, { useState } from 'react';
import SummaryPage from './pages/summary.jsx';
import OverviewPage from './pages/overview-page.jsx';
import ProjectIdPage from './pages/project-id.jsx';
import LayerTopicPage from './pages/layer-topic.jsx';
import { ConfigProvider, App } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import { BarChartOutlined } from '@ant-design/icons';
import style from './consumer-theater-admin.module.scss';
import classNames from 'classnames';

const menus = [
  {
    name: 'Overview',
    key: 'overview',
  },
  {
    name: 'Project_ID',
    key: 'project_id',
  },
  {
    name: 'Summary',
    key: 'summary',
  },
  {
    name: 'Layer I && II Topic',
    key: 'layer_topic',
  }
];

const ConsumerTheaterAdmin = () => {
  const { pageType } = useParams();
  const navigate = useNavigate();
  const [token, ] = useState(sessionStorage.getItem('jwt-access-token'));

  // if (!token) {
  //   return null;
  // }

  const onClick = (type) => {
    navigate(`/consumertheater/admin/${type.key}`);
  };

  return (
    <>
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: '#00a32e',
            colorError: '#eb0000',
            colorSuccess: '#00A32E',
            fontFamily: 'unilever-shilling-regular'
          }
        }}
      >
        <App>
          <div className={style.adminMainView}>
            <div className={style.leftMenuContainer}>
              <div className={style.menuLogoBox}>
                <div className={style.logoItem}></div>
              </div>
              <div className={classNames(style.subMenu)}>
                <BarChartOutlined />
                <div className={style.menuItemTitle}>Consumer Theater</div>
              </div>
              <div className={style.menusBox}>
                {menus.map(item => {
                  return (
                    <div key={item.key}
                      className={classNames(style.menuItem, {[style.active]: pageType === item.key})}
                      onClick={() => onClick(item)}
                    >
                      <div className={style.menuItemTitle}>{item.name}</div>
                    </div>);
                })}
              </div>
            </div>
            <div className={style.pageView}>
              {pageType === 'overview' && <OverviewPage />}
              {pageType === 'summary' && <SummaryPage />}
              {pageType === 'project_id' && <ProjectIdPage />}
              {pageType === 'layer_topic' && <LayerTopicPage />}
            </div>
          </div>
        </App>
      </ConfigProvider>
    </>
  );
};

export default ConsumerTheaterAdmin;
