import React, {useRef, useState, memo, useEffect, useCallback} from 'react';
import style from './competitor-innovation.module.scss';
import { triangleRight } from '../../../../components/icons/icons.jsx';
import { Select, DatePicker, Pagination, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import CompetitorDetailModal from '../competitor-detail-modal/competitor-detail-modal.jsx';
import { 
  fetchMintelBrands, 
  fetchMintelSubcategory, 
  // fetchMintelMarket, 
  fetchConsumerTrendDetails 
} from '../../api/request.js';

const { RangePicker } = DatePicker;

const antIcon = (
  <LoadingOutlined
    style={{
      fontSize: 30,
      color: '#00FF00'
    }}
    spin
  />
);

function debounce (callback, delay) {
  let lastTime;
  return function () {
    clearTimeout(lastTime);
    const [that, args] = [this, arguments];
    lastTime = setTimeout(() => {
      callback.apply(that, args);
    }, delay);
  };
}
let requestIndex = Date.now();

const CompetitorInnovationMemo = memo(function CompetitorInnovation({country, consumerTrend, }) {

  const maxTagPlaceholder = (val) => {
    return `+${val.length}`;
  };

  // brand
  const [brandOptions, setBrandOptions] = useState([]);
  const [brandValue, setBrandValue] = useState([]);

  // Subcategory
  const [subcategoryOptions, setSubcategoryOptions] = useState([]);
  const [subcategoryValue, setSubcategoryValue] = useState([]);

  // Sort
  const [sortOptions] = useState([
    {
      value: 'asc',
      label: 'Oldest to Newest'
    },
    {
      value: 'desc',
      label: 'Newest to Oldest'
    }
  ]);
  const [sortValue, setSortValue] = useState('desc');

  // date
  const [dateValue, setDateValue] = useState([]);
  const dataOnChange = (value, dateString) => {
    setDateValue(dateString);
  };

  const [loading, setLoading] = useState(true);
  const [total, setTotal] = useState(0);
  const [current, setCurrent] = useState(1);
  const [pageSize, setPageSize] = useState(12);
  const [listData, setListData] = useState([]);

  useEffect(() => {
    const params = {
      country,
      consumerTrend
    };
    fetchMintelBrands(params)
      .then(res => {
        setBrandOptions(res.data?.map(item => ({label: item.name, value: item.name})));
      })
      .catch(err => {console.error(err);});
    fetchMintelSubcategory(params)
      .then(res => {
        setSubcategoryOptions(res.data?.map(item => ({label: item.name, value: item.name})));
      })
      .catch(err => {console.error(err);});
  }, [country, consumerTrend]);

  // 详情弹窗
  const CompetitorDetailModalRef = useRef(null);

  const openDetail = (itemData) => {
    CompetitorDetailModalRef.current.open(itemData);
  };

  const fetchData = (params) => {
    setLoading(true);
    return fetchConsumerTrendDetails(params, requestIndex)
      .then(res => {
        if (requestIndex !== res.config.uuid) return;
        const resData = res.data.data;
        setListData(Array.isArray(resData.data) ? resData.data : []);
        setTotal(resData.total);
        setCurrent(resData.current);
        setPageSize(resData.size);
        setLoading(false);
      })
      .catch(error => {
        if (requestIndex !== error?.config?.uuid) return;
        setLoading(false);
      });
  };
  const delayFetchDetails = useCallback(debounce(function(val) { return fetchData(val);}, 500), []);

  useEffect(() => {
    if (!country || !consumerTrend) return;
    const params = {
      country,
      consumerTrend,
      brands: brandValue,
      subcategories: subcategoryValue,
      sort: sortValue,
      startDate: dateValue[0],
      endDate: dateValue[1],
      page: current,
      pageSize
    };
    requestIndex++;
    delayFetchDetails(params);
  }, [country, consumerTrend, brandValue, subcategoryValue, sortValue, dateValue, current, pageSize]);

  return (
    <div className={style.competitorInnovation}>
      <div className={style.titleBox}>
        <div className={style.arrow}>
          <img src={triangleRight} alt="" />
        </div>
        <div className={style.titleInner}>{consumerTrend}</div>
      </div>
      <div className={style.selectContainerResize}>
        <div className={style.selectContainer}>
          <div className={style.inputBorder}>
            <Select
              mode="multiple"
              placeholder='Brand'
              allowClear
              maxTagCount={1}
              maxTagTextLength={brandValue.length > 1 ? 2 : 8}
              maxTagPlaceholder={maxTagPlaceholder}
              value={brandValue}
              onChange={(newValue) => {
                setBrandValue(newValue);
              }}
              options={brandOptions}
              showSearch
              popupMatchSelectWidth={false}
            />
          </div>
          <div className={style.inputBorder}>
            <Select
              mode="multiple"
              placeholder='Subcategory'
              allowClear
              maxTagCount={1}
              maxTagTextLength={subcategoryValue.length > 1 ? 2 : 8}
              maxTagPlaceholder={maxTagPlaceholder}
              value={subcategoryValue}
              onChange={(newValue) => {
                setSubcategoryValue(newValue);
              }}
              options={subcategoryOptions}
              showSearch
              popupMatchSelectWidth={false}
            />
          </div>
          <div className={style.inputBorder}>
            <Select
              style={{width: '160px'}}
              placeholder='Time order'
              value={sortValue}
              onChange={(newValue) => {
                setSortValue(newValue);
              }}
              options={sortOptions}
              popupMatchSelectWidth={false}
            />
          </div>
          <div className={style.inputBorder}>
            <RangePicker onChange={dataOnChange} />
          </div>
        </div>
      </div>
      {!loading && listData?.length===0 && <div className={style.noData}>Coming Soon</div>}
      <Spin indicator={antIcon} spinning={loading}>
        {loading && listData?.length === 0 && <div className={style.subBox}></div>}
        {listData?.length > 0 && <><div className={style.itemContainer}>
          <div className={style.itemContainerInner}>
            {listData.map((item, index) => {
              return (
                <div key={item.recordId + '-' + index} className={style.itemBox}>
                  <div className={style.itemBoxInner} onClick={() => openDetail(item)}>
                    <img src={item.imageLink} alt="" />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div className={style.paginationBox}>
          <Pagination
            onChange={(page) => setCurrent(page)}
            onShowSizeChange={(_, size) => {setCurrent(1);setPageSize(size);}}
            total={total}
            current={current}
            pageSize={pageSize} 
            showSizeChanger={true} 
            pageSizeOptions={[12, 24, 36, 48, 60]} 
            showQuickJumper={true} 
          />
        </div>
        </>}
      </Spin>
      <CompetitorDetailModal ref={CompetitorDetailModalRef} />
    </div>
  );
});

export default CompetitorInnovationMemo;