import { message } from 'antd';
import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import BtnPng from '../assets/img/btn.png';
import style from './btn.module.scss';

function PackageAiBtn({ children, href }) {
  const navigate = useNavigate();

  const onClick = useCallback(() => {
    if (!href) {
      message.warning('Coming soon');
    } else navigate(href);
  }, [navigate, href]);

  return (
    <div className={style.btn} onClick={onClick}>
      <img className={style.btnImg} src={BtnPng} />
      <div className={style.btnText}>{children}</div>
    </div>
  );
}

export default PackageAiBtn;
