import React, { useRef, useState ,useCallback,useEffect } from 'react';
import './deep-dive-body.scss';
import TrendSummary from '../trend-summary/trend-summary.jsx'
import GrowthTrend from '../growth-trend/growth-trend.jsx'
import SocialBuzz from '../social-buzz/social-buzz.jsx'
import KeyIngredients from '../key-ingredients/key-ingredients.jsx'
import {arrowRightGreen,exportTopRight,exportTopLeft,exportBottomRight,exportBottomLeft,exportLogo,popuoClose, ki} from '../../../../components/icons/icons.jsx';
import html2canvas from 'html2canvas';



function DeepDiveBody({changeIsDeepDiveVisible=changeIsDeepDiveVisible,loading={loading},err={err},trendingDishData={trendingDishData}}) {
  const sideMenuItems = [
    {title:'Trend Summary'},
    {title:'Growth Trend'},
    {title:'Social Buzz'},
    {title:'Key Ingredients'}
  ];
  const [selectedTitle, setSelectedTitle] = useState('Trend Summary');
  useEffect(() => {
      const observer = new IntersectionObserver(
        (entries) => {
          console.log(entries)
          for (let i=0;i<entries.length;i++){
            const entry = entries[i]
            // console.log(entry.isIntersecting,i)
            if(entry.isIntersecting){
              setSelectedTitle(entry.target.id)
              break
            }
          }
          // entries.forEach((entry) => {
          //   if (entry.isIntersecting) {
          //     setSelectedTitle(entry.target.id)
          //   }
          // });
        },
        { threshold: 0.25,rootMargin:'0px 0px 0px 0px'}
      );
      observer.observe(TrendSummaryRef.current)
      observer.observe(GrowthTrendRef.current)
      observer.observe(SocialBuzzRef.current)
      observer.observe(KeyIngredientsRef.current)
  }, [])
  const SideMenuItem = ({item,index}) =>{
    if(selectedTitle==item.title){
      return(
        <div className='sideMenuItem' onClick={(e)=>handleSideMenuClick(item,index)}>
          <div className='sideMenuText' style={{color:'#26F028'}}>{item.title}</div>
          <img className='selectedArrow' src={arrowRightGreen}/>
        </div>
      )
    }else{
      return(
        <div className='sideMenuItem' onClick={(e)=>handleSideMenuClick(item,index)}>
          <div className='sideMenuText'>{item.title}</div>
        </div>
      )
    }
  };

  const TrendSummaryRef =useRef(null)
  const GrowthTrendRef =useRef(null)
  const SocialBuzzRef=useRef(null)
  const KeyIngredientsRef =useRef(null)
  const printRef = useRef(null)
  const tsRef = useRef(null)
  const gtRef = useRef(null)
  const sbRef = useRef(null)
  const kiRef = useRef(null)

  const handleSideMenuClick = (e,index) => {
    if (e.title == 'Trend Summary') {
        TrendSummaryRef.current.scrollIntoView({ behavior: 'smooth' });
    }
    else if (e.title == 'Growth Trend') {
        GrowthTrendRef.current.scrollIntoView({ behavior: 'smooth' });
    }
    else if (e.title == 'Social Buzz') {
        SocialBuzzRef.current.scrollIntoView({ behavior: 'smooth' });
    }
    else if (e.title == 'Key Ingredients') {
        KeyIngredientsRef.current.scrollIntoView({ behavior: 'smooth' });
    }
    // setSelectedTitle(e.title)
  }
  const handleExport = (e) =>{
    // console.log(e,selectedTitle)
    var element = printRef.current
    // if(selectedTitle == "Trend Summary"){
    //   element = TrendSummaryRef.current
    // }
    // if(selectedTitle == "Growth Trend"){
    //   element = GrowthTrendRef.current
    // }
    // if(selectedTitle == "Social Buzz"){
    //   element = SocialBuzzRef.current
    // }
    // if(selectedTitle == "Key Ingredients"){
    //   element = KeyIngredientsRef.current
    // }
    // console.log(TrendSummaryRef)
    html2canvas(element, {backgroundColor:null,useCORS:true}).then(canvas => {
      const imgData = canvas.toDataURL('image/png');
      const link = document.createElement('a');
      link.href = imgData;
      link.download = 'print.png';
      link.click()
    })
  }

  const handleClose = (e) =>{
    // console.log(e)
    changeIsDeepDiveVisible(false)
  }

  return (
    <div className="container">
        <div className='sideMenu'>
          {sideMenuItems.map((item,index)=>(
            <SideMenuItem item={item} key={index}/>
          ))}
        </div>
        <div className='borderLine'></div>
        
        <div className='export' onClick={handleExport}>
          <img className='exportLogo' src={exportLogo}/>
          <img className='exportTR' src={exportTopRight}/>
          <img className='exportTL' src={exportTopLeft}/>
          <img className='exportBR' src={exportBottomRight}/>
          <img className='exportBL' src={exportBottomLeft}/>
          Export
        </div>
        <div className='popupclose' onClick={handleClose}><img src={popuoClose}/></div>
        <div className='detail' ref={printRef}>
                <div ref={TrendSummaryRef} id='Trend Summary'>
                <TrendSummary  trendingDishData={trendingDishData} ref={tsRef}/>
                </div>
                <div ref={GrowthTrendRef} id='Growth Trend'>
                <GrowthTrend loading={loading} err={err} trendingDishData={trendingDishData} ref={gtRef}/>
                </div>
                <div ref={SocialBuzzRef} id='Social Buzz'>
                <SocialBuzz loading={loading} err={err} trendingDishData={trendingDishData} ref={sbRef}/>
                </div>
                <div ref={KeyIngredientsRef} id='Key Ingredients'>
                <KeyIngredients ref={kiRef}/>
                </div>
        </div>
    </div>
  );
}

export default DeepDiveBody;
