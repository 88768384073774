import React, { useEffect, useState } from 'react';
import { Avatar, List, message } from 'antd';
import VirtualList from 'rc-virtual-list';
import EventBus from '@/modules/pain-points/hooks/eventBus';
import NoData from './noData.jsx';


const App = () => {
  let [data, setData] = useState({ id: '', title: '', list: [] });

  useEffect(() => {
    //挂载
    EventBus.addEventListener('painPointDetailEvent', handlePainPointDetailEvent);
    return (() => {
      //卸载
      EventBus.removeEventListener('painPointDetailEvent', handlePainPointDetailEvent);
    });
  }, []);

  const handlePainPointDetailEvent = e => {
    let list = e.detail.list.map((v, k) => ({ key: k, value: v }));
    let dataC = ({ title: e.detail.title, list: list });
    setData(dataC);
    console.log('dataC接收到传值啦！~~~~~~');
  };


  let ContainerHeight = 420;

  const onScroll = (e) => {
    if (Math.abs(e.currentTarget.scrollHeight - e.currentTarget.scrollTop - ContainerHeight) <= 1) {
    }
  };
  return <>
    {
      !data.list.length ? <NoData />
        : <div style={{ padding: '0 20px' }}>
          <div style={{ padding: '0 10px 10px 0', color: '#b9b9b9' ,fontSize:'0.9vw'}}>{data.title}</div>
          <List
            split={false}
          >
            <VirtualList
              data={data.list}
              height={ContainerHeight}
              itemHeight={40}
              itemKey="key"
              onScroll={onScroll}
              styles={{ verticalScrollBarThumb: { background: '#02661c' } }}
            >
              {(item) => (
                <List.Item style={{ color: 'rgb(72,158,64)',fontSize:'0.9vw' }}>{item.value}</List.Item>
              )}
            </VirtualList>
          </List>
        </div>
    }
  </>;

};

export default App;