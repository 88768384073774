import React from 'react';
import { ConfigProvider, theme, App } from 'antd';
import BusinessCaseAssistant from './pages/business-case-assistant-page.jsx';
import businessCaseAssistantBgVideo from '../../assets/videos/green-grid-motion-5s.mp4';
import LeftSidebarCollapsible from '../../components/left-navigation/left-navigation.jsx';

const BusinessCaseAssitant = () => {
  return (
    <>
      <LeftSidebarCollapsible />
      <div className="sidebar-right-layout">
        <video autoPlay muted className="learning-bg-video" loop>
          <source src={businessCaseAssistantBgVideo} type="video/mp4" />
        </video>
        <div className="business-case-assistant-container">
          <ConfigProvider
            theme={{
              token: {
                colorPrimary: '#00a32e',
                colorError: '#eb0000',
                colorSuccess: '#06ca3d',
                fontFamily: 'unilever-shilling-regular',
              },
              algorithm: theme.darkAlgorithm,
            }}
          >
            <App>
              <BusinessCaseAssistant />
            </App>
          </ConfigProvider>
        </div>
      </div>
    </>
  );
};

export default BusinessCaseAssitant;
