// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.lKONR_lHlS9bskBBQwfw{height:100%;display:flex;justify-content:space-between}.y0IS9HHvStRvqhGtoH5w{width:68%;height:100%}.KH9m0vJ5eowEtwGpHSOg{width:30%;height:100%}.TFARfSPI9aQAKevjIfPQ{position:absolute;bottom:12px;left:12px;font-size:10px;font-weight:400;line-height:13px;color:#fff;opacity:.4}.PCAYcfw9zBAx8S2bABq8{position:absolute;bottom:12px;right:12px;z-index:11}`, "",{"version":3,"sources":["webpack://./src/modules/package-ai-generation/views/update/index.module.scss"],"names":[],"mappings":"AAAA,sBACE,WAAA,CACA,YAAA,CACA,6BAAA,CAGF,sBACE,SAAA,CACA,WAAA,CAGF,sBACE,SAAA,CACA,WAAA,CAGF,sBACE,iBAAA,CACA,WAAA,CACA,SAAA,CACA,cAAA,CACA,eAAA,CACA,gBAAA,CACA,UAAA,CACA,UAAA,CAGF,sBACE,iBAAA,CACA,WAAA,CACA,UAAA,CACA,UAAA","sourcesContent":[".box {\n  height: 100%;\n  display: flex;\n  justify-content: space-between;\n}\n\n.box1 {\n  width: 68%;\n  height: 100%;\n}\n\n.box2 {\n  width: 30%;\n  height: 100%;\n}\n\n.info1 {\n  position: absolute;\n  bottom: 12px;\n  left: 12px;\n  font-size: 10px;\n  font-weight: 400;\n  line-height: 13px;\n  color: #fff;\n  opacity: 0.4;\n}\n\n.btn1 {\n  position: absolute;\n  bottom: 12px;\n  right: 12px;\n  z-index: 11;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"box": `lKONR_lHlS9bskBBQwfw`,
	"box1": `y0IS9HHvStRvqhGtoH5w`,
	"box2": `KH9m0vJ5eowEtwGpHSOg`,
	"info1": `TFARfSPI9aQAKevjIfPQ`,
	"btn1": `PCAYcfw9zBAx8S2bABq8`
};
export default ___CSS_LOADER_EXPORT___;
