import React, { useState, useImperativeHandle, forwardRef } from 'react';
import { Modal } from 'antd';
import BorderContainer from '../border-container/border-container.jsx';
import style from './competitor-detail-modal.module.scss';
import { cancelGreenIcon, dialShare } from '../../../../components/icons/icons.jsx';
import { LeftOutlined } from '@ant-design/icons';
import ShareModal from '../../../../components/share-modal/share-modal.jsx';

let CompetitorDetailModal = (_, ref) => {

  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const [imageData, setImageData] = useState({});
  const [showShare, setShowShare] = useState(false);

  useImperativeHandle(ref, () => ({
    open: (data) => {
      setImageData(data);
      setIsModalOpen(true);
    },
    close: () => {
      setIsModalOpen(false);
    },
  }));

  const [previewModalOpen, setPreviewModalOpen] = useState(false);
  const showPreview = () => {
    setPreviewModalOpen(true);
  };

  const previewCancel = () => {
    setPreviewModalOpen(false);
  };

  return (
    <Modal
      title="" 
      wrapClassName={style.myDialog}
      width={1100} 
      centered 
      destroyOnClose
      footer={null} 
      open={isModalOpen}
      onCancel={handleCancel}
      closeIcon={(
        <div className={style.closeBtn}>
          <img src={cancelGreenIcon} alt="" />
        </div>
      )}
    >
      <div className={style.dialogBox}>
        <div className={style.backBtn} onClick={() => setIsModalOpen(false)}>
          <LeftOutlined /> Details
        </div>
        <BorderContainer>
          <div className={style.dialogMain}>
            <div className={style.mainLeft}>
              <div className={style.imgBox}>
                <img src={imageData.imageLink} onClick={showPreview} alt="" />
              </div>
              <div className={style.infoItem}>
                <div className={style.infoTitle}>Category :</div>
                <div className={style.infoContent}>{imageData?.category}</div>
                <div className={style.shareBox} onClick={() => setShowShare(true)}>
                  <img src={dialShare} alt="" />
                </div>
              </div>
              <div className={style.lineBox}></div>
              <div className={style.infoItem}>
                <div className={style.infoTitle}>Brand :</div>
                <div className={style.infoContent}>{imageData?.brand}</div>
              </div>
              <div className={style.lineBox}></div>
              <div className={style.infoItem}>
                <div className={style.infoTitle}>Country :</div>
                <div className={style.infoContent}>{imageData?.country}</div>
              </div>
              <div className={style.lineBox}></div>
            </div>
            <div className={style.mainRight}>
              <div className={style.infoItem}>
                <div className={style.infoTitle}>SubCategory :</div>
                <div className={style.infoContent}>{imageData?.subCategory}</div>
              </div>
              <div className={style.infoItem}>
                <div className={style.infoTitle}>Product :</div>
                <div className={style.infoContent}>{imageData?.product}</div>
              </div>
              <div className={style.infoItem}>
                <div className={style.infoTitle}>Product Description:</div>
                <div className={style.infoContent}>{imageData?.productDescription}</div>
              </div>
            </div>
          </div>
        </BorderContainer>
      </div>
      {showShare ? <ShareModal cardURL={imageData.imageLink} onClose={() => setShowShare(false)} /> : null}
      <Modal
        title="" 
        width={800} 
        wrapClassName={style.myDialog}
        centered 
        destroyOnClose
        footer={null} 
        open={previewModalOpen}
        onCancel={previewCancel}
        closeIcon={(
          <div className={style.closeBtn}>
            <img src={cancelGreenIcon} alt="" />
          </div>
        )}
      >
        <div className={style.imagePreviewBox} style={{backgroundImage: `url(${imageData.imageLink})`}}></div>
      </Modal>
    </Modal>
  );
};

CompetitorDetailModal = forwardRef(CompetitorDetailModal);

export default CompetitorDetailModal;