import React from 'react';
import style from './header-nav.module.scss';

function HeaderNav({children}) {

  function scrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }
  
  return (
    <div className={style.headerNav}>
      <div className={style.headerTitle} onClick={scrollToTop}></div>
      <div className={style.countryBox}>
        { children }
      </div>
    </div>
  );
}

export default HeaderNav;