import React, { useMemo } from 'react';
import style from './colorCoding.module.scss';

function PackageAiColorCoding({
  className = '',
  children,
  /**
   * @type {number} 0 no color, 1 red, 2 amber, 3 green
   */
  colorValue = 0,
}) {
  const colorClass = useMemo(() => {
    if (colorValue === 1) {
      return style.colorCodingRed;
    } else if (colorValue === 2) {
      return style.colorCodingAmber;
    } else if (colorValue === 3) {
      return style.colorCodingGreen;
    } else {
      return style.colorCodingDefault;
    }
  });

  return (
    <div className={`${style.colorCodingContainer} ${colorClass} ${className}`}>
      <div className={style.colorCodingText}>{children}</div>
    </div>
  );
}

export default PackageAiColorCoding;
