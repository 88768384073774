// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sJU4D5WWbc0PBjvivbCQ{height:100%}.azRfaogYXeBGuMsZTftv{position:relative;height:100%;width:100%;display:flex;justify-content:center;align-items:center}.cnLkOJa5jbZuefC0Zxq0{height:90%;display:flex;justify-content:center;background-color:#fff}.cnLkOJa5jbZuefC0Zxq0 img{height:100%}.y1oUyvFUklt2KAWrvsof{position:absolute;bottom:12px;right:12px;z-index:11}`, "",{"version":3,"sources":["webpack://./src/modules/package-ai-generation/views/render/index.module.scss"],"names":[],"mappings":"AAAA,sBACE,WAAA,CAGF,sBACE,iBAAA,CACA,WAAA,CACA,UAAA,CACA,YAAA,CACA,sBAAA,CACA,kBAAA,CAGF,sBACE,UAAA,CACA,YAAA,CACA,sBAAA,CACA,qBAAA,CACA,0BACE,WAAA,CAIJ,sBACE,iBAAA,CACA,WAAA,CACA,UAAA,CACA,UAAA","sourcesContent":[".box {\n  height: 100%;\n}\n\n.box1 {\n  position: relative;\n  height: 100%;\n  width: 100%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n.imgBox {\n  height: 90%;\n  display: flex;\n  justify-content: center;\n  background-color: #fff;\n  img {\n    height: 100%;\n  }\n}\n\n.btn1 {\n  position: absolute;\n  bottom: 12px;\n  right: 12px;\n  z-index: 11;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"box": `sJU4D5WWbc0PBjvivbCQ`,
	"box1": `azRfaogYXeBGuMsZTftv`,
	"imgBox": `cnLkOJa5jbZuefC0Zxq0`,
	"btn1": `y1oUyvFUklt2KAWrvsof`
};
export default ___CSS_LOADER_EXPORT___;
