// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.WTO27XUlzCdoEIWo_dTv{position:absolute;top:0;left:0;width:100%;height:100%;z-index:101;background-color:rgba(0,0,0,.5)}.muFVRFkuUZjYGLUTtB8B{position:absolute;top:50%;left:50%;transform:translate(-50%, -50%);z-index:102;width:50vw}`, "",{"version":3,"sources":["webpack://./src/modules/package-ai-screening/views/asset/loading.module.scss"],"names":[],"mappings":"AAAA,sBACE,iBAAA,CACA,KAAA,CACA,MAAA,CACA,UAAA,CACA,WAAA,CACA,WAAA,CACA,+BAAA,CAGF,sBACE,iBAAA,CACA,OAAA,CACA,QAAA,CACA,+BAAA,CACA,WAAA,CACA,UAAA","sourcesContent":[".loadingBox {\n  position: absolute;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  z-index: 101;\n  background-color: rgba(0, 0, 0, 0.5);\n}\n\n.loadingImg {\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n  z-index: 102;\n  width: 50vw;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loadingBox": `WTO27XUlzCdoEIWo_dTv`,
	"loadingImg": `muFVRFkuUZjYGLUTtB8B`
};
export default ___CSS_LOADER_EXPORT___;
