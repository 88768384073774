import React from 'react';
import { cn } from '../../../utils/utils';
import bgIcon from '../../../assets/bg.png';
import pauseIcon from '../../../assets/images/pause.png';

import { ReactComponent as EditSvg } from '../../../assets/svgs/edit.svg';
import { ReactComponent as DownloadSvg } from '../../../assets/svgs/download.svg';
import { ReactComponent as ShareSvg } from '../../../assets/svgs/share.svg';
import SvgCom from '../../../components/SvgCom/index.jsx';

const VideoCardCom = ({ className ='' }) => {
  return (
    <div className={cn('bg-[#DFECDD] rounded-2xl p-[15px]', className)}>
      <div className="flex items-center justify-between mb-2">
        <span className="text-base font-bold leading-none text-green-1">Video name.mp4</span>
        <div className="py-[6px] px-[12px] text-[10px] leading-none rounded-lg bg-primary text-white">Not sharing</div>
      </div>
      <div className="flex items-center justify-between text-xs leading-none text-green-1 mb-2.5">
        <span>Created 25/ 09/24</span>
        <span className="mr-[12px]">Videos 17</span>
      </div>
      <div className="h-[265px] rounded-md relative overflow-hidden">
        <img src={bgIcon} alt="" className="w-full h-full" />
        <div className="absolute w-full h-full top-0 left-0 flex justify-center items-center cursor-pointer">
          <img src={pauseIcon} alt="" className="w-[22px] h-[30px]" />
        </div>
        <div className="absolute bottom-3 right-3 px-2 py-1 rounded text-white text-[10px] bg-[#00A32E61]">
                    02:20
        </div>
      </div>
      <div className="mt-[13px] flex items-center justify-end">
        <SvgCom Svg={EditSvg} size="16" className="ml-4 cursor-pointer text-green-1 hover:text-primary"></SvgCom>
        <SvgCom Svg={DownloadSvg} size="16" className="ml-4 cursor-pointer text-green-1 hover:text-primary"></SvgCom>
        <SvgCom Svg={ShareSvg} size="16" className="ml-4 cursor-pointer text-green-1 hover:text-primary"></SvgCom>
      </div>
    </div>
  );
};

export default VideoCardCom;