import React, { useEffect, useRef, useState } from 'react';
import { chevronDownIcon } from '../../icons/icons.jsx';
import FilterModal from '../filterModal.jsx';
import api from '@/util/api.jsx';
import { apiURL } from '@/env-url.js';
import { useFilters } from '@/modules/trends/hooks/useFilters.jsx';

function BrandFilter({
  consumerTrendsCategory,
  selectedCountry,
  brands,
  updateFilters,
}) {
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const ref = useRef(null);
  const { filters } = useFilters();
  const [filteredBrands, setFilteredBrands] = useState([]);
  let consumerTrend = encodeURIComponent(consumerTrendsCategory);
  const fetchBrands = () => {
    return api
      .get(
        `${apiURL}/trends/mintel/brands?country=${selectedCountry}&consumerTrend=${consumerTrend}`
      )
      .then((response) => {
        setFilteredBrands((prevData) => {
          return [...prevData, ...response.data];
        });
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  };
  const handleButtonClick = () => {
    setIsFilterOpen(!isFilterOpen);
  };

  const checkIfClickedOutside = (e) => {
    if (ref.current && !ref.current.contains(e.target)) {
      setIsFilterOpen(false);
    }
  };

  const onApply = (brands) => {
    updateBrandsSelection(brands);
    setIsFilterOpen(false);
  };

  const updateBrandsSelection = (values) => {
    console.log(values);
    updateFilters({ ...filters, brands: values });
  };

  useEffect(() => {
    fetchBrands();
    document.addEventListener('mousedown', checkIfClickedOutside);
    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  }, []);

  useEffect(()=>{console.log('brands:', brands);}, [brands]);

  return (
    <div ref={ref}>
      <div className="consumer-filter-container">
        <button
          className={'brand-filter' + (isFilterOpen ? ' active' : '')}
          onClick={handleButtonClick}
          role="button"
        >
          <span>Brand</span>
          {isFilterOpen ? (
            <img
              className="up-arrow-filter"
              src={chevronDownIcon}
              alt="arrow"
            />
          ) : (
            <img className="arrow-filter" src={chevronDownIcon} alt="arrow" />
          )}
        </button>
      </div>
      {isFilterOpen && (
        <FilterModal
          isOpen={isFilterOpen}
          selectedOptions={brands}
          onApply={onApply}
          data={filteredBrands}
        />
      )}
    </div>
  );
}

export default BrandFilter;
