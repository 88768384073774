// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `._JzRlUF4QcxpKJL7n1HA{display:flex;flex-direction:column}.DKJHBdK7o4PgfF8RdLCd{height:60px;display:flex;align-items:center;background-color:#00a32e;padding:0 30px;font-size:16px;font-weight:900;color:#fff}.ZivJ9gNTCqZZZ342SiuG{flex:1;overflow:hidden;background-color:rgba(0,0,0,.6);position:relative}`, "",{"version":3,"sources":["webpack://./src/modules/package-ai-screening/components/box.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,qBAAA,CAGF,sBACE,WAAA,CACA,YAAA,CACA,kBAAA,CACA,wBAAA,CACA,cAAA,CACA,cAAA,CACA,eAAA,CACA,UAAA,CAGF,sBACE,MAAA,CACA,eAAA,CACA,+BAAA,CACA,iBAAA","sourcesContent":[".box {\n  display: flex;\n  flex-direction: column;\n}\n\n.boxHeader {\n  height: 60px;\n  display: flex;\n  align-items: center;\n  background-color: #00a32e;\n  padding: 0 30px;\n  font-size: 16px;\n  font-weight: 900;\n  color: #fff;\n}\n\n.boxBody {\n  flex: 1;\n  overflow: hidden;\n  background-color: #00000099;\n  position: relative;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"box": `_JzRlUF4QcxpKJL7n1HA`,
	"boxHeader": `DKJHBdK7o4PgfF8RdLCd`,
	"boxBody": `ZivJ9gNTCqZZZ342SiuG`
};
export default ___CSS_LOADER_EXPORT___;
