import React from 'react';
import classNames from 'classnames';
import style from './index.module.scss';
import logoImg from '../../../../assets/images/bu-monthly-feedback/logo_big.png';
import DownMoreSvgIcon from '../../../../assets/images/bu-monthly-feedback/down-more.svg';
import MainContent from '../../components/main-content/index.jsx';
import AdminUserSvgIcon from '../../../../assets/images/bu-monthly-feedback/admin_user_icon.svg';
import { useBase } from '../../hooks/useBase.jsx';

const HomePage = () => {
  const { openDetail, userInfo, changeOpenDetail } = useBase();

  return (
    <div style={{height: '100vh', position: 'relative', overflow: 'hidden'}}>
      <div className={classNames(style.adminFlagWrap, openDetail ? style.hidden : '')}>
        {
          userInfo !== null && <div className={classNames(style.flagBox)}>
            <img src={AdminUserSvgIcon} className={classNames(style.userIcon)}></img>
            <span className={classNames(style.label)}>{userInfo.username}</span>
          </div>
        }
      </div>

      <div className={classNames(style.initBox)}>
        <img src={logoImg} className={classNames(style.logo, openDetail ? style.hidden : '')}/>

        <div className={classNames(style.bottomBtnBox, openDetail ? style.hidden : '')}>
          <div className={classNames(style.btn)} onClick={() => changeOpenDetail(true)}>
            <div className={classNames(style.label)}>Business Unit</div>
            <img src={DownMoreSvgIcon} className={classNames(style.downIcon)}></img>
          </div>
        </div>
      </div>
      <div className={classNames(style.contentBox, openDetail ? style.show : '')}>
        <MainContent></MainContent>
      </div>
    </div>
  );
};

export default HomePage;