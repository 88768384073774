import { App, ConfigProvider, theme } from 'antd';
import React, { useEffect } from 'react';
import PackAiHome from './home/home.jsx';

function PackageAiView() {
  useEffect(() => {
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }, 500);
  }, []);

  return (
    <>
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: '#00a32e',
            colorError: '#eb0000',
            colorSuccess: '#06ca3d',
            fontFamily: 'unilever-shilling-regular',
          },
          components: {
            Select: {
              optionFontSize: 16,
            },
          },
          algorithm: theme.darkAlgorithm,
        }}
      >
        <App>
          <PackAiHome />
        </App>
      </ConfigProvider>
    </>
  );
}

export default PackageAiView;
