// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.IG2AXYyFFqAhYLMzcQyV{margin-right:25px;margin-bottom:25px;width:10vw;overflow:hidden}.qYuBHEtMhbeklt1RtmQ4{margin-top:10px;width:10vw;height:12vw;cursor:pointer;border-radius:6px;overflow:hidden;background-color:#02290c;position:relative}.IJ9eD_ee4xAKAW2E_ozI{display:block;width:100%;height:100%;object-fit:contain}.Jm0TuMKRE6Fc3GUDor49,.legAxvb_icR5nUVbTrdL{position:absolute;top:4px;left:12px;z-index:2;padding:4px;border-radius:9999px;font-size:10px;font-weight:400;line-height:9px;color:#fff}.Jm0TuMKRE6Fc3GUDor49{background-color:#00a32e}.legAxvb_icR5nUVbTrdL{background-color:rgba(186,186,186,.8)}`, "",{"version":3,"sources":["webpack://./src/modules/package-ai-screening-batch/views/aoi/item.module.scss"],"names":[],"mappings":"AAAA,sBACE,iBAAA,CACA,kBAAA,CACA,UAAA,CACA,eAAA,CAGF,sBACE,eAAA,CACA,UAAA,CACA,WAAA,CACA,cAAA,CACA,iBAAA,CACA,eAAA,CACA,wBAAA,CACA,iBAAA,CAGF,sBACE,aAAA,CACA,UAAA,CACA,WAAA,CACA,kBAAA,CAGF,4CAEE,iBAAA,CACA,OAAA,CACA,SAAA,CACA,SAAA,CACA,WAAA,CACA,oBAAA,CACA,cAAA,CACA,eAAA,CACA,eAAA,CACA,UAAA,CAGF,sBACE,wBAAA,CAGF,sBACE,qCAAA","sourcesContent":[".imgItem {\n  margin-right: 25px;\n  margin-bottom: 25px;\n  width: 10vw;\n  overflow: hidden;\n}\n\n.imgItemContent {\n  margin-top: 10px;\n  width: 10vw;\n  height: 12vw;\n  cursor: pointer;\n  border-radius: 6px;\n  overflow: hidden;\n  background-color: #02290c;\n  position: relative;\n}\n\n.imgItemContentImg {\n  display: block;\n  width: 100%;\n  height: 100%;\n  object-fit: contain;\n}\n\n.completeTag,\n.incompleteTag {\n  position: absolute;\n  top: 4px;\n  left: 12px;\n  z-index: 2;\n  padding: 4px;\n  border-radius: 9999px;\n  font-size: 10px;\n  font-weight: 400;\n  line-height: 9px;\n  color: #fff;\n}\n\n.completeTag {\n  background-color: #00a32e;\n}\n\n.incompleteTag {\n  background-color: #bababacc;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"imgItem": `IG2AXYyFFqAhYLMzcQyV`,
	"imgItemContent": `qYuBHEtMhbeklt1RtmQ4`,
	"imgItemContentImg": `IJ9eD_ee4xAKAW2E_ozI`,
	"completeTag": `Jm0TuMKRE6Fc3GUDor49`,
	"incompleteTag": `legAxvb_icR5nUVbTrdL`
};
export default ___CSS_LOADER_EXPORT___;
