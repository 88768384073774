// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.m3J6qAjfLZ2bFDvtuAny{cursor:pointer;font-size:32px;color:#00a32e}`, "",{"version":3,"sources":["webpack://./src/modules/package-ai-screening/components/help.module.scss"],"names":[],"mappings":"AAAA,sBACE,cAAA,CACA,cAAA,CACA,aAAA","sourcesContent":[".helpIcon {\n  cursor: pointer;\n  font-size: 32px;\n  color: #00a32e;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"helpIcon": `m3J6qAjfLZ2bFDvtuAny`
};
export default ___CSS_LOADER_EXPORT___;
