import React from 'react';
import { useBase } from '../../hooks/useBase.jsx';
import style from './index.module.scss';
import classNames from 'classnames';
import bgVideo from '@/assets/videos/topDish.mp4';

const BaseLayout = ({children}) => {
  const { openWin } = useBase();
  return <>
    {/*<LeftFilterNavigateCollapsible />*/}
    <div className={style.painLayout}>
      <div className={style.videoBox}>
        <video className={classNames(style.videoBackground)} autoPlay="autoPlay" muted={true} loop="loop">
          <source src={bgVideo} />
        </video>
      </div>
      <div>
        {children}
      </div>
    </div>
  </>;
};

export default BaseLayout;