import React from 'react';
import ConsumerTrendsCard from './consumer-trends/consumer-trends.jsx';

function TrendsInnovation({
  consumerTrendsCategory,
  selectedCountry
}) {
  return (
    <>
      <ConsumerTrendsCard
        consumerTrendsCategory={consumerTrendsCategory}
        selectedCountry={selectedCountry}
      />
    </>
  );
}
export default TrendsInnovation;
