import React from 'react';
import { ConfigProvider, theme, Pagination } from 'antd';

const SimplePagination = ({current, total, pageSize, handlePageChange}) => {
  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: '#00a32e',
          colorError: '#eb0000',
          colorSuccess: '#06ca3d',
          fontFamily: 'unilever-shilling-regular',
        },
        algorithm: theme.darkAlgorithm,
      }}
    >
      <Pagination
        className="simple-pagination"
        simple
        defaultCurrent={1}
        current={current}
        total={total}
        pageSize={pageSize}
        onChange={handlePageChange}
        showSizeChanger={false}
      />
    </ConfigProvider>
  );
};
export default SimplePagination;
