import React from 'react';
import style from './main.module.scss';
import SendUserBox from './send-user-box.jsx';
import SendAIBox from './send-ai-box.jsx';
import InitQuestionBox from './init-question-box.jsx';
import { useRoot } from '../../hooks/useRoot.jsx';
import SendLoadingBox from './send-loading-box.jsx';
import SendRetryBox from './send-retry-box.jsx';

const ChatMainBody = () => {
  const { chatList, loading, isRetryAsk } = useRoot();
  return (<>
    <div className={style.mainBody}>
      <InitQuestionBox></InitQuestionBox>
      {
        chatList.map((item, index) => {
          if(item.type === 'answer') {
            return <SendAIBox key={index} data={item.data}></SendAIBox>;
          } else {
            return <SendUserBox key={index} data={item.data}></SendUserBox>; 
          }
        })
      }
      {
        loading ? <SendLoadingBox></SendLoadingBox> : <></>
      }
      {
        isRetryAsk ? <SendRetryBox></SendRetryBox> : <></>
      }
    </div>  
  </>);
};

export default ChatMainBody;