// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.iHNB_wKP3HuZ6SywAGlv{display:flex;flex-direction:column;align-items:center}.G4QNCLeUYlZlJED5bMv2,.fnvvb5OuhX19O3z3rlPB{margin-bottom:10px}.G4QNCLeUYlZlJED5bMv2,.U2NwFmK6MUqOuV7hRzRv{padding:9px 30px;background-color:#081b09;border-radius:4px;cursor:pointer}.sqELApkLYbPRO9weO92_{color:#00a32e}.fnvvb5OuhX19O3z3rlPB{padding:8px;border:solid 1px rgba(0,163,46,.3215686275);border-radius:6px;cursor:pointer}.fnvvb5OuhX19O3z3rlPB>img{width:5vw;height:5vw;object-fit:contain;opacity:.6;transition:all .3s}.fnvvb5OuhX19O3z3rlPB.NpraDwRAMprHoRwt3KgF{border:solid 1px #00a32e}.fnvvb5OuhX19O3z3rlPB.NpraDwRAMprHoRwt3KgF>img{opacity:1;transform:scale(1.18)}`, "",{"version":3,"sources":["webpack://./src/modules/package-ai-generation/views/edit/options.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,qBAAA,CACA,kBAAA,CAGF,4CAEE,kBAAA,CAGF,4CAEE,gBAAA,CACA,wBAAA,CACA,iBAAA,CACA,cAAA,CAGF,sBACE,aAAA,CAGF,sBACE,WAAA,CACA,2CAAA,CACA,iBAAA,CACA,cAAA,CACA,0BACE,SAAA,CACA,UAAA,CACA,kBAAA,CACA,UAAA,CACA,kBAAA,CAGF,2CACE,wBAAA,CACA,+CACE,SAAA,CACA,qBAAA","sourcesContent":[".optionsContainer {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n}\n\n.optionsPrev,\n.optionsItem {\n  margin-bottom: 10px;\n}\n\n.optionsPrev,\n.optionsNext {\n  padding: 9px 30px;\n  background-color: #081b09;\n  border-radius: 4px;\n  cursor: pointer;\n}\n\n.optionsBtnIcon {\n  color: #00a32e;\n}\n\n.optionsItem {\n  padding: 8px;\n  border: solid 1px #00a32e52;\n  border-radius: 6px;\n  cursor: pointer;\n  > img {\n    width: 5vw;\n    height: 5vw;\n    object-fit: contain;\n    opacity: 0.6;\n    transition: all 0.3s;\n  }\n\n  &.active {\n    border: solid 1px #00a32e;\n    > img {\n      opacity: 1;\n      transform: scale(1.18);\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"optionsContainer": `iHNB_wKP3HuZ6SywAGlv`,
	"optionsPrev": `G4QNCLeUYlZlJED5bMv2`,
	"optionsItem": `fnvvb5OuhX19O3z3rlPB`,
	"optionsNext": `U2NwFmK6MUqOuV7hRzRv`,
	"optionsBtnIcon": `sqELApkLYbPRO9weO92_`,
	"active": `NpraDwRAMprHoRwt3KgF`
};
export default ___CSS_LOADER_EXPORT___;
