import React from 'react';
import style from './title2.module.scss';
import RightSvg from '../assets/img/right-g.svg';

function Title2({ className, children }) {
  return (
    <div className={`${style.title} ${className}`}>
      <img src={RightSvg} />
      <div className={style.titleText}>{children}</div>
    </div>
  );
}

export default Title2;
