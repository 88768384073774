import React,{memo  } from 'react';
import SocialAnimatedWordCloud from './social-word-cloud-chart.jsx';
import Style from './social-word-cloud.module.scss';
import { aiTopDishInfo, topRight, topLeft, bottomRight, bottomLeft } from '../../../../components/icons/icons.jsx';
import Tippy from '@tippyjs/react';
import { Spin, Empty } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import 'tippy.js/dist/tippy.css';

const SocialWordCloud = ({ selectedWordCloud, loading, err }) => {
  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 30,
        color: '#00FF00',
      }}
      spin
    />
  );

  return (
    <div className={`${Style.wordCloudMainContainer}`} style={{ width: '88%', marginLeft: '4vw' }}>
      <div className={`${Style.wordCloudContainer}`}>
        <img className='topright' src={topRight} />
        <img className='topleft' src={topLeft} />
        <img className='bottomright' src={bottomRight} />
        <img className='bottomleft' src={bottomLeft} />
        <div className={Style.mainContainer}>
          <div className={Style.wordContainer}>
            {loading ? (
              <Spin indicator={antIcon}>
                <div className={Style.placeholder}></div>
              </Spin>
            ) : (
              <>
                {(selectedWordCloud && selectedWordCloud.length === 0) || err === true ? (
                  <Empty
                    className={Style.emptyTable}
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                  />
                ) : (
                  <SocialAnimatedWordCloud selectedWordCloud={selectedWordCloud} />
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(SocialWordCloud);
