import React, { useEffect, useState } from 'react';
import style from './chart.module.scss';
import ReactEcharts from 'echarts-for-react';
import options from './chartAll.jsx';
import NoData from './noData.jsx';
import EventBus from '@/modules/pain-points/hooks/eventBus';


const chartDetail = () => {
  let [data, setData] = useState({
    id: '',
    pies: [],
    needState: [],
    generation: [],
    lifeStage: [],
    productUsage: [],
    contemporaryCooks: [],
  });

  useEffect(() => {
    //挂载
    EventBus.addEventListener('painPointDetailEvent', handlePainPointDetailEvent);
    return (() => {
      //卸载
      EventBus.removeEventListener('painPointDetailEvent', handlePainPointDetailEvent);
    });
  }, [data]);
  const handlePainPointDetailEvent = e => {
    setData(e.detail);
    console.log('dataD接收到传值啦！~~~~~~');
  };

  const getPieColor = (v) => {
    if (v < 5) return '#d90000';
    if (v < 30) return '#fdba38';
    return '#00a22e';
  };


  return <>
    {
      !data.id ? <NoData />
        :
        <div className={style.chartDetailContent}>
          <div className={style.chartDetailTop}>

            <div className={style.chartDetailTopLeft}>

              <div className={style.chartDetailTopLeftTitleBox}>
                <div style={{ color: getPieColor(data.pies[2]) }}>Reach</div>
                <ReactEcharts style={{ height: '168px', width: '100%', paddingBottom: '15px' }}
                              option={options.optionC([{ value: data.pies[2] }], getPieColor(data.pies[2]))} />
              </div>

              <div className={style.chartDetailTopLeftTitleBox}>
                <div style={{ color: getPieColor(data.pies[1]) }}>Importance</div>
                <ReactEcharts style={{ height: '168px', width: '100%', paddingBottom: '15px' }}
                              option={options.optionC([{ value: data.pies[1] }], getPieColor(data.pies[1]))} />
              </div>

              <div className={style.chartDetailTopLeftTitleBox}>
                <div style={{ color: getPieColor(data.pies[0]) }} className={style.chartDetailDoubleRowTitle}>
                  <span>Willingness</span>
                  <span>to pay</span>
                </div>
                <ReactEcharts style={{ height: '168px', width: '100%', paddingBottom: '15px' }}
                              option={options.optionC([{ value: data.pies[0] }], getPieColor(data.pies[0]))} />
              </div>
            </div>


            <div className={style.chartDetailTopLeftTitleBox} style={{ width: '20%' }}>
              <div style={{ color: '#67aad9' }}>Needstate</div>
              <ReactEcharts style={{ height: '170px', width: '100%' }}
                            option={options.optionD(data.needState, [103, 170, 217])} />
            </div>

            <div className={style.chartDetailTopLeftTitleBox} style={{ width: '30%' }}>
              <div style={{ color: 'rgba(125,192,165)' }}>Generation</div>
              <ReactEcharts style={{ height: '170px', width: '100%' }}
                            option={options.optionD(data.generation, [125, 192, 165])} />
            </div>

          </div>
          <div className={style.chartDetailTop}>

            <div className={style.chartDetailTopLeftTitleBox} style={{ width: '41%' }}>
              <div style={{ color: 'rgba(196,87,103)' }}>Lifestage</div>
              <ReactEcharts style={{ height: '170px', width: '100%' }}
                            option={options.optionD(data.lifeStage || [], [196, 87, 103])} />
            </div>

            <div className={style.chartDetailTopLeftTitleBox} style={{ width: '32%' }}>
              <div style={{ color: 'rgb(72,159,64)' }}>Product Usage</div>
              <ReactEcharts style={{ height: '170px', width: '100%' }}
                            option={options.optionD(data.productUsage || [], [72, 159, 64])} />
            </div>

            <div className={style.chartDetailTopLeftTitleBox} style={{ width: '20%' }}>
              <div style={{ color: 'rgb(253,186,56)' }}>Contemporary Cooks</div>
              <ReactEcharts style={{ height: '170px', width: '100%' }}
                            option={options.optionD(data.contemporaryCooks || [], [253, 186, 56])} />
            </div>

          </div>
        </div>
    }
  </>;

};


export default chartDetail;