import service from '../../../../util/pythonApi';
import { utils, writeFile } from 'xlsx';

export function postFile(file, prefix = 'packageyhao/rawimage') {
  const data = new FormData();
  data.append('prefix', prefix);
  data.append('form', 'form');
  data.append('file', file);
  return service.request({
    url: '/v1/azure_file',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
}

export function putFileKey(fileKey) {
  return service.request({
    url: '/v1/azure_file',
    method: 'put',
    params: {
      file_key: fileKey,
    },
  });
}

export function downloadData(fileName, data) {
  const ws = utils.aoa_to_sheet(data);
  const wb = utils.book_new();
  utils.book_append_sheet(wb, ws, fileName);
  writeFile(wb, `${fileName}.xlsx`);
}
