// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.i2xqDn7kUF5lB1HohqqE{padding:6px 20px;color:#00a32e;line-height:1;font-size:14px;border:1px solid #00a32e;border-radius:20px;display:inline-block;margin-right:12px;margin-bottom:12px;cursor:pointer}.i2xqDn7kUF5lB1HohqqE.vUHstB2tI3wdV33xxv3E{color:#fff;background-color:#00a32e}`, "",{"version":3,"sources":["webpack://./src/modules/plant-based/components/common-btn/common-btn.module.scss"],"names":[],"mappings":"AAAA,sBACE,gBAAA,CACA,aAAA,CACA,aAAA,CACA,cAAA,CACA,wBAAA,CACA,kBAAA,CACA,oBAAA,CACA,iBAAA,CACA,kBAAA,CACA,cAAA,CAEA,2CACE,UAAA,CACA,wBAAA","sourcesContent":[".btnWrap {\n  padding: 6px 20px;\n  color: #00A32E;\n  line-height: 1;\n  font-size: 14px;\n  border: 1px solid #00A32E;\n  border-radius: 20px;\n  display: inline-block;\n  margin-right: 12px;\n  margin-bottom: 12px;\n  cursor: pointer;\n\n  &.active {\n    color: #fff;\n    background-color: #00A32E;;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"btnWrap": `i2xqDn7kUF5lB1HohqqE`,
	"active": `vUHstB2tI3wdV33xxv3E`
};
export default ___CSS_LOADER_EXPORT___;
