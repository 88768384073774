import React, { useMemo } from 'react';
import EqualSvg from '../assets/img/equal-y.svg';
import MinusSvg from '../assets/img/minus-r.svg';
import PlusSvg from '../assets/img/plus-g.svg';
import style from './aiIcon2.module.scss';

function PackageAiAiIcon({ value }) {
  const icon = useMemo(() => {
    if (value === 0) {
      return MinusSvg;
    } else if (value === 1) {
      return PlusSvg;
    } else if (value === 2) {
      return PlusSvg;
    } else {
      return EqualSvg;
    }
  });

  return (
    <div className={style.aiCycle}>
      <img src={icon} />
    </div>
  );
}

export default PackageAiAiIcon;
