import React, { useState, useEffect } from 'react';
import style from './common.module.scss';
import classNames from 'classnames';
import { ClearOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, ConfigProvider, Space, Tooltip } from 'antd';
import Collapse from '../collapse/collapse.jsx';
import { popularDiet } from '@/modules/plant-based/api/request';
import { painPointGetDetail, painPointGetList } from '@/modules/pain-points/api/request';
import EventBus from '@/modules/pain-points/hooks/eventBus';

const LeftFilterNavigateCollapsible = ({ toggleCallback }) => {

  const applyOnClick = async () => {
    const result = await painPointGetList();
    let list = result.data.map(item => {
      let x = item.Q9_3_Q10_2;
      let y = item.Q9_4_Q10_3;
      let z = item.z;
      let name = item.needTitle;
      return [x, y, z, name, item.id];
    });

    EventBus.dispatchEvent('painPointListEvent', list);
    console.log('--------列表数据---------', result.data);
  };
  // applyOnClick(); // 默认加载数据


  return (
    <div className={style.filterBox}style={{overflow:'scroll'}}>
      <div className={classNames(style.left15, style.flexBetween)}>
        <span className={style.textTitle}>Filter</span>
        <div style={{margin:'auto 0'}}>
          <Tooltip title="Clear">
            <Button type="link" style={{ color: 'rgba(255,255,255,0.6)' }} icon={<ClearOutlined />} />
          </Tooltip>
          <Button onClick={applyOnClick} type="text" size="small" shape="round"
                  style={{ background: '#1a3d1b', color: '#FFF' ,fontSize: '0.8vw',height: '1.5vw'}}>Apply</Button>
        </div>
      </div>
      <div style={{ marginTop: '20px' }} />
      <Collapse />
    </div>
  );
};

export default LeftFilterNavigateCollapsible;
