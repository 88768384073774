import React, { createContext, useContext, useState } from 'react';

const initialCountry = 'UK';

export const TrendsContext = createContext(null);

export const TrendsProvider = ({ selectedCountry, children }) => {
  const initialValue = selectedCountry || initialCountry;  
  const [country, setCountry] = useState(initialValue);
  
  const updateTrendsCountry = (country) => {
    setCountry(country);
  };

  return (
    <TrendsContext.Provider value={{ country, updateTrendsCountry }}>
      {children}
    </TrendsContext.Provider>
  );
};

export const useTrendsContext= ()=> {
  return useContext(TrendsContext);
};

export const loadingObj = {
  text: '',
  color: '#178f1e',
  textColor: '#000',
  maskColor: 'rgba(0, 57, 20, 0.9)',
  zlevel: 10,
};
