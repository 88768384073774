import React from 'react';
import { cn } from '../../../utils/utils';
import CardCom from '../../../components/CardCom/index.jsx';
import ReactECharts from 'echarts-for-react'; 
import 'echarts-wordcloud';

import StarSvg from '../../../assets/svgs/star.svg';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Divider } from 'antd';

const OverviewCom = () => {
  return (
    <div className={cn('grid gap-4')}>
      <CardCom label="Auto-video summarization">
        <div className="flex justify-start">
          <video className="h-[220px]" autoPlay={true} muted loop controls src="https://ec-crm-aliyun-oss.bluemoon.com.cn/officialwebsite/website/pro/website_20247_1724325255689.mp4" preload="true"></video>
          <div className="flex-1 ml-[20px]">
            <div className="border rounded-[14px] border-primary p-[10px] flex">
              <img src={StarSvg} className='mt-[3px] w-[16px] h-[16px]' alt="" />
              <div className="text-sm text-primary flex-1 ml-2">
                <b>Hey! Did you know</b> Unique themes identified from your responses are displayed below. Clicking any theme will refine the video summary to the left.
              </div>
            </div>
            <div className="mt-6 mb-[20px] flex justify-between items-center">
              <span className="text-lg leading-none font-semibold text-primary">Unique themes based on your video responses</span>
              <div className="flex items-center text-primary text-lg">
                <LeftOutlined className=" cursor-pointer" />
                <div className="w-5"></div>
                <RightOutlined className=" cursor-pointer" />
              </div>
            </div>

            <div className="grid gap-4 grid-cols-2">
              {
                [1,2,3,4].map((item, index) => <div key={index} className="bg-white rounded-xl p-3">
                  <span className="text-base leading-none text-primary font-semibold">Improvise</span>
                  <Divider className="bg-[#EAF4EA] my-3" />
                  <div className="flex justify-between items-center text-sm font-semibold leading-none mb-3">
                    <div className="text-[#34783C99]">Responses <span className="text-primary">3</span></div>
                    <div className="text-[#34783C99]">Mentions <span className="text-primary">3</span></div>
                  </div>
                  <div className="h-4 w-full rounded-full overflow-hidden flex items-center">
                    <div className="bg-red-300 h-full w-1/2"></div>
                    <div className="bg-green-600 h-full w-1/2"></div>
                  </div>
                </div>)
              }
            </div>
          </div>
        </div>
      </CardCom>
      <div className="grid gap-4 grid-cols-2">
        <CardCom label="Word cloud">
          <ReactECharts 
            option={{
              tooltip: {},
              series: [ {
                type: 'wordCloud',
                gridSize: 2,
                sizeRange: [12, 50],
                rotationRange: [-90, 90],
                shape: 'pentagon',
                width: 600,
                height: 400,
                drawOutOfBound: true,
                textStyle: {
                  color: function () {
                    return 'rgb(' + [
                      Math.round(Math.random() * 160),
                      Math.round(Math.random() * 160),
                      Math.round(Math.random() * 160)
                    ].join(',') + ')';
                  }
                },
                emphasis: {
                  textStyle: {
                    shadowBlur: 10,
                    shadowColor: '#333'
                  }
                },
                data: [
                  {
                    name: 'Sam S Club',
                    value: 10000,
                    textStyle: {
                      color: 'black'
                    },
                    emphasis: {
                      textStyle: {
                        color: 'red'
                      }
                    }
                  },
                  {
                    name: 'Macys',
                    value: 6181
                  },
                  {
                    name: 'Amy Schumer',
                    value: 4386
                  },
                  {
                    name: 'Jurassic World',
                    value: 4055
                  },
                  {
                    name: 'Charter Communications',
                    value: 2467
                  },
                  {
                    name: 'Chick Fil A',
                    value: 2244
                  },
                  {
                    name: 'Planet Fitness',
                    value: 1898
                  },
                  {
                    name: 'Pitch Perfect',
                    value: 1484
                  },
                  {
                    name: 'Express',
                    value: 1112
                  },
                  {
                    name: 'Home',
                    value: 965
                  },
                  {
                    name: 'Johnny Depp',
                    value: 847
                  },
                  {
                    name: 'Lena Dunham',
                    value: 582
                  },
                  {
                    name: 'Lewis Hamilton',
                    value: 555
                  },
                  {
                    name: 'KXAN',
                    value: 550
                  },
                  {
                    name: 'Mary Ellen Mark',
                    value: 462
                  },
                  {
                    name: 'Farrah Abraham',
                    value: 366
                  },
                  {
                    name: 'Rita Ora',
                    value: 360
                  },
                  {
                    name: 'Serena Williams',
                    value: 282
                  },
                  {
                    name: 'NCAA baseball tournament',
                    value: 273
                  },
                  {
                    name: 'Point Break',
                    value: 265
                  }
                ]
              } ]
            }}
          ></ReactECharts>
        </CardCom>

        <CardCom label="Sentence sentiment">
          <ReactECharts
            option={{
              tooltip: {
                trigger: 'item',
                formatter: '{a} <br/> {b}: {c} ({d}%)'
              },
              series: [
                {
                  name: 'Sentence sentiment',
                  type: 'pie',
                  radius: ['40%', '70%'],
                  avoidLabelOverlap: false,
                  label: {
                    show: true,
                    color: '#8FBF71',
                    fontSize: 15,
                    // padding: [0, -60, 0, -100],
                  },
                  labelLine: {
                    show: true,
                    length: 20,
                    length2: 30,
                    lineStyle: {
                      color: '#000000',
                      width: 2
                    }
                  },
                  data: [
                    { value: 1048, name: 'Positive', itemStyle: {
                      color:'#22AE4A'
                    }},
                    { value: 735, name: 'Negative', itemStyle: {
                      color: '#FF8C8C'
                    }},
                    { value: 580, name: 'Neutral', itemStyle: {
                      color: '#FFCE1F'
                    }},
                  ]
                },
                {
                  tooltip: {
                    show: false
                  },
                  type: 'pie',
                  radius: ['35%', '40%'],
                  label: {
                    show: false
                  },
                  emphasis: {
                    label: {
                      show: false
                    }
                  },
                  labelLine: {
                    show: false
                  },
                  data: [
                    { value: 1048, name: 'Positive', itemStyle: {
                      color:'#34783C'
                    }},
                    { value: 735, name: 'Negative', itemStyle: {
                      color: '#D77575'
                    }},
                    { value: 580, name: 'Neutral', itemStyle: {
                      color: '#FFB11F'
                    }},
                  ]
                }
              ]
            }}
          />
        </CardCom>
      </div>

      <CardCom label="Auto-video summarization">
        <ReactECharts 
          option={{
            legend: {
              orient: 'vertical',
              left: '50%',
              top: 'center',
              align: 'left',
              textStyle: {
                color: '#00A32E',
                fontSize: 16,
                fontWeight: 400
              }
            },
            series: [
              {
                name: 'Nightingale Chart',
                type: 'pie',
                roseType: 'radius',
                radius: ['2%', '90%'],
                center: ['25%', '50%'],
                avoidLabelOverlap: true,
                padAngle: 5,
                itemStyle: {
                  borderRadius: 5,
                },
                label: {
                  show: true,
                  position: 'inside',
                  formatter: '{d}%',
                  color: '#ffffff',
                  fontSize: '18',
                  fontWeight: 600
                },
                data: [
                  { value: 40, name: 'rose 1' },
                  { value: 38, name: 'rose 2' },
                  { value: 32, name: 'rose 3' },
                  { value: 30, name: 'rose 4' },
                  { value: 28, name: 'rose 5' },
                ]
              }
            ]
          }}
          style={{height: '400px'}}
        />
      </CardCom>
    </div>
  );
};

export default OverviewCom;