// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pcJiYPTk5JnWkiGP05tU{height:100%;display:flex;flex-direction:column}.Du6L2VTMYgj6POEjRlI4{margin-top:25px;flex:1;overflow:hidden;position:relative}.Gs2ZGB3dC9hrEnmVLaom{width:100%;display:flex;justify-content:space-between;align-items:center}.p1nFsHB21bUT54yTb3II{margin-right:15px}.LgfGTvk4ndy2EB51qy7w{margin-right:10px;font-size:13px}.cjR_mG0TAJA9BNW3IlwP{height:100%;padding:12px 50px;overflow-x:hidden}.H23DV5OLmv5wij0rrCf2{margin-right:10px;font-size:16px;color:#fff;cursor:pointer}.VufMecaJmYbzIybXeucN{display:flex}.RdsZrJnO5cox0Ev6SAhn{margin-right:10px;padding:10px 50px !important}`, "",{"version":3,"sources":["webpack://./src/modules/package-ai-screening/views/deep-dive/index.module.scss"],"names":[],"mappings":"AAAA,sBACE,WAAA,CACA,YAAA,CACA,qBAAA,CAGF,sBACE,eAAA,CACA,MAAA,CACA,eAAA,CACA,iBAAA,CAGF,sBACE,UAAA,CACA,YAAA,CACA,6BAAA,CACA,kBAAA,CAGF,sBACE,iBAAA,CAGF,sBACE,iBAAA,CACA,cAAA,CAGF,sBACE,WAAA,CACA,iBAAA,CACA,iBAAA,CAGF,sBACE,iBAAA,CACA,cAAA,CACA,UAAA,CACA,cAAA,CAGF,sBACE,YAAA,CAGF,sBACE,iBAAA,CACA,4BAAA","sourcesContent":[".containerInner {\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n}\n\n.box {\n  margin-top: 25px;\n  flex: 1;\n  overflow: hidden;\n  position: relative;\n}\n\n.boxHeaderInner {\n  width: 100%;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n}\n\n.boxHeaderExport {\n  margin-right: 15px;\n}\n\n.boxHeaderBtnIcon {\n  margin-right: 10px;\n  font-size: 13px;\n}\n\n.boxInner {\n  height: 100%;\n  padding: 12px 50px;\n  overflow-x: hidden;\n}\n\n.boxTitleBack {\n  margin-right: 10px;\n  font-size: 16px;\n  color: #fff;\n  cursor: pointer;\n}\n\n.btnBox {\n  display: flex;\n}\n\n.btn2 {\n  margin-right: 10px;\n  padding: 10px 50px !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"containerInner": `pcJiYPTk5JnWkiGP05tU`,
	"box": `Du6L2VTMYgj6POEjRlI4`,
	"boxHeaderInner": `Gs2ZGB3dC9hrEnmVLaom`,
	"boxHeaderExport": `p1nFsHB21bUT54yTb3II`,
	"boxHeaderBtnIcon": `LgfGTvk4ndy2EB51qy7w`,
	"boxInner": `cjR_mG0TAJA9BNW3IlwP`,
	"boxTitleBack": `H23DV5OLmv5wij0rrCf2`,
	"btnBox": `VufMecaJmYbzIybXeucN`,
	"btn2": `RdsZrJnO5cox0Ev6SAhn`
};
export default ___CSS_LOADER_EXPORT___;
