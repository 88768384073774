import React from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import style from './loading.module.scss';

function LoadingModal({ loading }) {
  return (
    <div
      className={style.loadingModal}
      style={{ display: loading ? undefined : 'none' }}
    >
      <div className={style.loadingIcon}>
        <LoadingOutlined
          style={{
            fontSize: 100,
            color: '#78F550',
          }}
          spin
        />
      </div>
    </div>
  );
}

export default LoadingModal;
