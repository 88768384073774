import { message } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { getImages } from '../../apis/serve';
import LoadingModal from '../../components/loading.jsx';
import style from './photo.module.scss';
import PhotoCellDetail from './cell-detail.jsx';

/**
 * @param {import("../apis/serve").Image} img
 */
function PhotoCell(img, onClick) {
  return (
    <div className={style.photoCell} key={img.id} onClick={onClick}>
      <img
        src={img.thumb ?? img.url}
        width={img.thumb_width ?? 180}
        height={img.thumb_height ?? 240}
        referrerPolicy="no-referrer"
        loading="lazy"
      ></img>
    </div>
  );
}

function OccasionPhotoContent({ filter, className }) {
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [images, setImages] = useState([]);
  const [total, setTotal] = useState(0);
  async function readImages(clear = false) {
    if (loading) return;
    setLoading(true);
    try {
      const { data } = await getImages(clear ? 1 : page + 1, 100, filter);
      if (!data) return;
      const { total, page: currentPage, data: imgs } = data;
      if (clear) {
        setPage(1);
        setImages(imgs.sort(() => Math.random() - 0.5));
      } else {
        setPage(currentPage);
        setImages(images.concat(imgs.sort(() => Math.random() - 0.5)));
      }
      setTotal(total);
    } catch (err) {
      console.error('get images error', err);
      message.error('Get images failed');
    }
    setLoading(false);
  }
  useEffect(() => {
    setImages([]);
    void readImages(true);
  }, [filter]);
  function nextPage() {
    void readImages();
  }
  /**
   * @param {React.UIEvent<HTMLDivElement, UIEvent>} ev
   */
  function onScroll(ev) {
    const isBottom =
      ev.target.scrollHeight - ev.target.scrollTop === ev.target.clientHeight;
    if (isBottom) nextPage();
    return { isBottom };
  }

  const [detailVisible, setDetailVisible] = useState(false);
  const [detailImgIndex, setDetailImgIndex] = useState(0);
  function onCellClick(i) {
    setDetailImgIndex(i);
    setDetailVisible(true);
  }
  function onDetailClose() {
    setDetailVisible(false);
  }
  const detailImg = useMemo(() => {
    return images[detailImgIndex];
  }, [images, detailImgIndex]);
  function onDetailNext() {
    if (detailImgIndex + 1 >= images.length) return;
    setDetailImgIndex(detailImgIndex + 1);
  }
  function onDetailPrev() {
    if (detailImgIndex <= 0) return;
    setDetailImgIndex(detailImgIndex - 1);
  }

  return (
    <div className={`${style.photoContent} ${className ?? ''}`}>
      <div>Total Result: {total}</div>
      <div className={style.photoContentInner} onScroll={onScroll}>
        <div className={style.photoList}>
          {images.map((e, i) => PhotoCell(e, () => onCellClick(i)))}
        </div>
      </div>
      <LoadingModal loading={loading}></LoadingModal>
      {detailVisible && (
        <PhotoCellDetail
          data={detailImg}
          onClose={onDetailClose}
          onNext={onDetailNext}
          onPrev={onDetailPrev}
        ></PhotoCellDetail>
      )}
    </div>
  );
}

export default OccasionPhotoContent;
