// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.uli92dc0eZfEWsRM3XYm{position:relative}.RjM52HzPryUBFJeJqtCM{position:fixed;top:0;left:0;right:0;z-index:10;bottom:0;margin-left:134px}.RjM52HzPryUBFJeJqtCM .ITt2mnITZJldJ8vmfQHu{position:absolute;top:100vh;left:0;right:0;z-index:10;width:90%;margin:0 auto;transition:top ease-in-out .3s}`, "",{"version":3,"sources":["webpack://./src/modules/plant-based/pages/home/index.module.scss"],"names":[],"mappings":"AAAA,sBACE,iBAAA,CAGF,sBACE,cAAA,CACA,KAAA,CACA,MAAA,CACA,OAAA,CACA,UAAA,CACA,QAAA,CACA,iBAAA,CAEA,4CACE,iBAAA,CACA,SAAA,CACA,MAAA,CACA,OAAA,CACA,UAAA,CACA,SAAA,CACA,aAAA,CACA,8BAAA","sourcesContent":[".homeWrap {\n  position: relative;\n}\n\n.boxWrap {\n  position: fixed;\n  top: 0;\n  left: 0;\n  right: 0;\n  z-index: 10;\n  bottom: 0;\n  margin-left: 134px;\n\n  .mainContent {\n    position: absolute;\n    top: 100vh;\n    left: 0;\n    right: 0;\n    z-index: 10;\n    width: 90%;\n    margin: 0 auto;\n    transition: top ease-in-out 0.3s;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"homeWrap": `uli92dc0eZfEWsRM3XYm`,
	"boxWrap": `RjM52HzPryUBFJeJqtCM`,
	"mainContent": `ITt2mnITZJldJ8vmfQHu`
};
export default ___CSS_LOADER_EXPORT___;
