export const downloadFile = (item) => {
  const link = document.createElement('a');
  link.href = item.fileUrl;
  link.download = item.name;
  link.target = '_blank';
  document.body.appendChild(link);
  link.click();
  window.setTimeout(() => {
    document.body.removeChild(link);
  }, 0);
};