import React, { useEffect, useState } from 'react';
import style from './chart.module.scss';
import options from './chartAll.jsx';
import ReactEcharts from 'echarts-for-react';
import NoData from './noData.jsx';
import ChatTitle from '@/modules/pain-points/components/chart-main/chatTitle.jsx';
import Search from './chartSearch.jsx';
import ChartListFilter from './chartRightFilter.jsx';
import ChartList from '@/modules/pain-points/components/chart-main/chartList.jsx';
import ChartDetail from '@/modules/pain-points/components/chart-main/chartDetail.jsx';
import EventBus from '@/modules/pain-points/hooks/eventBus';
import { painPointGetDetail } from '@/modules/pain-points/api/request';


const dataD = {};


const ChartMain = () => {
  const [dataA, setDataA] = useState([]);
  const [dataB, setDataB] = useState([]);

  useEffect(() => {
    //挂载
    EventBus.addEventListener('painPointListEvent', painPointListEvent);
    EventBus.addEventListener('painPointListChangeEvent', painPointListChangeEvent);
    return (() => {
      //卸载
      EventBus.removeEventListener('painPointListEvent', painPointListEvent);
      EventBus.removeEventListener('painPointListChangeEvent', painPointListChangeEvent);
    });
  }, [dataA,dataB]);

  let dataARef, dataBRef;

  const painPointListEvent = e => {
    setDataA(e.detail);
    dataAToDataB(e.detail,2);
    console.log('dataA接收到传值啦！~~~~~~');
  };

  const painPointListChangeEvent = e => {
    dataAToDataB(dataA, e.detail);
    console.log('dataBChange接收到传值啦！~~~~~~');
  };

  const dataAToDataB = (data, type = 0) => {
    const dataList = data.map(i => ({ name: i[3], value: i[type], id: i[4] }));
    let a = dataList.sort((a, b) => a.value - b.value)
    setDataB(a);
  };

  const dataASortLimit = (data, type = 0) => {
    // return dataA.sort((a, b) => a[type] - b[type]);
    // todo 正式数据放开这行
    // .slice(0, 10)
    return dataA.sort((a, b) =>b[2] - a[2]).slice(0, 10);
  };

  const queryDetail = async (id, needTitle) => {
    const result = await painPointGetDetail({ id, needTitle });
    EventBus.dispatchEvent('painPointDetailEvent', result.data);
    console.log('--------详情数据---------', result.data);
  };

  const dataAOnClick = (param) => {
    // console.log(param,dataA,dataB,'dataAOnClick');
    const echartInstance = dataBRef.getEchartsInstance();
    echartInstance.dispatchAction({
      type: 'select',
      name: param.value[3],
    });
    queryDetail(param.value[4], param.value[3]);
  };

  const dataBOnClick = (param) => {
    // console.log(param.data.name,dataASortLimit(dataA).findIndex(i => i[3] === param.data.name),'dataBOnClick');
    const echartInstance = dataARef.getEchartsInstance();
    echartInstance.dispatchAction({
      type: 'select',
      dataIndex: dataASortLimit(dataA).findIndex(i => i[3] === param.data.name),
    });
    // queryDetail(param.data.id, param.data.name);
  };


  return <div className={style.chartMain}>
    <div className={style.chartTop}>
      <div className={style.chartLeftTop}>
        <ChatTitle text="Top 10 Unmet Needs" />
        {
          !dataA.length ? <NoData />
            : <ReactEcharts style={{ height: '100%', width: '100%' }}
                            ref={(e) => {
                              dataARef = e;
                            }}
                            option={options.optionA(dataASortLimit(dataA))}
                            onEvents={{ 'click': dataAOnClick }}
                            notMerge />
        }
      </div>
      <div className={style.chartRightTop}>
        <ChatTitle text="Unmet Need Full List" />

        {
          !dataA.length ? <NoData />
            : <>
              <div>
                <Search />
                {/* <ChartListFilter /> */}
              </div>
              <ReactEcharts
                            style={{ height: '100%', width: '100%' }}
                            ref={(e) => {
                              dataBRef = e;
                            }}
                            option={options.optionB(dataB)}
                            onEvents={{ 'click': dataBOnClick }} />
            </>
        }
      </div>
    </div>

    <div className={style.chartBottom}>
      <div className={style.chartLeftBottom}>
        <ChatTitle text="Consumer Verbatims" />
        <ChartList />
      </div>
      <div className={style.chartRightBottom}>
        <ChatTitle text="Average Ratings" />
        {
          !dataD ? <NoData />
            : <ChartDetail data={dataD} />
        }
      </div>
    </div>
  </div>;
};

export default ChartMain;
