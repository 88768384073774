import React, { useEffect, useRef } from 'react';
import style from './index.module.scss';

function VistaView() {
  const aRef = useRef();

  useEffect(() => {
    setTimeout(() => {
      if (aRef) aRef.current.click();
    }, 100);
  }, []);

  return (
    <div>
      <a
        ref={aRef}
        href="https://app.powerbi.com/groups/me/apps/bc76a2fa-72b3-4584-bcd3-aeaf9af45ca4/reports/357f263d-7cfd-4006-bd32-18e465230856/ReportSection?experience=power-bi"
      >
        <h1 className={style.title}>Loading....</h1>
      </a>
    </div>
  );
}

export default VistaView;
