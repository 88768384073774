// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../../assets/images/consumer-theater-chat/close@2x.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.A9FTNbNjt4vv9dgQLUd6 .ant-modal-content{padding:0 !important;background-color:rgba(0,0,0,.7)}.m3aUdMc39Mox9INKvC3i{width:100%;height:70vh}.qb5NeVP4bg7lF6jL7AXw{width:24px;height:24px;background:url(${___CSS_LOADER_URL_REPLACEMENT_0___});background-repeat:no-repeat;background-size:cover;background-position:center}`, "",{"version":3,"sources":["webpack://./src/modules/consumer-theater-chat/components/preview-video/index.module.scss"],"names":[],"mappings":"AAEQ,yCACA,oBAAA,CACA,+BAAA,CAKR,sBACI,UAAA,CACA,WAAA,CAGJ,sBACI,UAAA,CACA,WAAA,CACA,kDAAA,CACA,2BAAA,CACA,qBAAA,CACA,0BAAA","sourcesContent":[".customModalWrap {\n    :global {\n        .ant-modal-content {\n        padding: 0 !important;\n        background-color: rgba(0, 0, 0, 0.7);\n        }\n    }\n}\n\n.video {\n    width: 100%;\n    height: 70vh;\n}\n\n.closeBtn {\n    width: 24px;\n    height: 24px;\n    background: url('../../../../assets/images/consumer-theater-chat/close@2x.png');\n    background-repeat: no-repeat;\n    background-size: cover;\n    background-position: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"customModalWrap": `A9FTNbNjt4vv9dgQLUd6`,
	"video": `m3aUdMc39Mox9INKvC3i`,
	"closeBtn": `qb5NeVP4bg7lF6jL7AXw`
};
export default ___CSS_LOADER_EXPORT___;
