// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.vEAx8hsfSoXs4DZzHfKO{margin-bottom:27px;font-size:16px;font-weight:600;line-height:22.3px;color:#00a32e}`, "",{"version":3,"sources":["webpack://./src/modules/package-ai-screening/views/deep-dive/title.module.scss"],"names":[],"mappings":"AAAA,sBACE,kBAAA,CACA,cAAA,CACA,eAAA,CACA,kBAAA,CACA,aAAA","sourcesContent":[".title1 {\n  margin-bottom: 27px;\n  font-size: 16px;\n  font-weight: 600;\n  line-height: 22.3px;\n  color: #00a32e;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title1": `vEAx8hsfSoXs4DZzHfKO`
};
export default ___CSS_LOADER_EXPORT___;
