// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LjMA1KlgjIZTv61_rbl3{width:50vw}.nTdr0NUYAqgumQAiqzuq{position:relative;padding:15px 35px;background:linear-gradient(90deg, #00b200 0%, #000503 100%)}.ebpfG4YvPcvYBYjwEj8s{position:absolute;height:100%;top:-29%;left:-0.6%;z-index:1}.ebpfG4YvPcvYBYjwEj8s img{height:157%}.KLTmIUCQiTly4D2zrSML{position:relative;z-index:2;font-size:20px;font-weight:700;line-height:27.88px;color:#fff}.y2UOfffUusUQdDgCX76n{padding:45px 45px 5px}.wIOgd5QC9Mt495W2XKzN{background:#026019;border:none}.Jdt9ivTqZL9uwH7Zw6Xk{display:flex;justify-content:center}`, "",{"version":3,"sources":["webpack://./src/modules/package-ai-screening/components/template.module.scss"],"names":[],"mappings":"AAAA,sBACE,UAAA,CAGF,sBACE,iBAAA,CACA,iBAAA,CACA,2DAAA,CAGF,sBACE,iBAAA,CACA,WAAA,CACA,QAAA,CACA,UAAA,CACA,SAAA,CACA,0BACE,WAAA,CAIJ,sBACE,iBAAA,CACA,SAAA,CACA,cAAA,CACA,eAAA,CACA,mBAAA,CACA,UAAA,CAGF,sBACE,qBAAA,CAGF,sBACE,kBAAA,CACA,WAAA,CAGF,sBACE,YAAA,CACA,sBAAA","sourcesContent":[".dialogInner {\n  width: 50vw;\n}\n\n.titleBox {\n  position: relative;\n  padding: 15px 35px;\n  background: linear-gradient(90deg, #00b200 0%, #000503 100%);\n}\n\n.titleImgBox {\n  position: absolute;\n  height: 100%;\n  top: -29%;\n  left: -0.6%;\n  z-index: 1;\n  img {\n    height: 157%;\n  }\n}\n\n.titleText {\n  position: relative;\n  z-index: 2;\n  font-size: 20px;\n  font-weight: 700;\n  line-height: 27.88px;\n  color: white;\n}\n\n.bodyBox {\n  padding: 45px 45px 5px;\n}\n\n.formInput {\n  background: #026019;\n  border: none;\n}\n\n.btnRow {\n  display: flex;\n  justify-content: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dialogInner": `LjMA1KlgjIZTv61_rbl3`,
	"titleBox": `nTdr0NUYAqgumQAiqzuq`,
	"titleImgBox": `ebpfG4YvPcvYBYjwEj8s`,
	"titleText": `KLTmIUCQiTly4D2zrSML`,
	"bodyBox": `y2UOfffUusUQdDgCX76n`,
	"formInput": `wIOgd5QC9Mt495W2XKzN`,
	"btnRow": `Jdt9ivTqZL9uwH7Zw6Xk`
};
export default ___CSS_LOADER_EXPORT___;
