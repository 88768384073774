import service from '../../../../util/pythonApi';

export function getKnorrVisit(id) {
  return service.request({
    url: `/v1/knorr/${id}/visit`,
    method: 'get',
  });
}

export function postKnorrVisit(id) {
  return service.request({
    url: `/v1/knorr/${id}/visit`,
    method: 'post',
  });
}
