// import FiledirSvg from "@/assets/svgs/filedir.svg?react"
import React from 'react';
import { ReactComponent as ArrowRightSvg} from '../../assets/svgs/arrow_right.svg';
import { ReactComponent as AddImgSvg } from '../../assets/svgs/add_img.svg';
import ProjectCardCom from './components/ProjectCardCom.jsx';
import VideoCardCom from './components/VideoCardCom.jsx';
import { useState } from 'react';
import ProjectWinCom from './components/ProjectWinCom.jsx';

import UploadWinCom from './components/UploadWinCom.jsx';
import SvgCom from '../../components/SvgCom/index.jsx';

const HomePage = () => {
  const [showProjectWin, setShowProjectWin] = useState(false);
  const [showUploadWin, setShowUploadWin] = useState(false);

  return (
    <div className="px-[30px] py-[30px] overflow-x-hidden">
      {/* <div className="flex items-center justify-between mb-[25px]">
                <span className="text-xl font-semibold">Cover Page</span>
                <button className="border-btn">
                    <SvgCom Svg={FiledirSvg} size="20"></SvgCom>
                    <span className="ml-2">Browse the file</span>
                </button>
            </div> */}
      <div onClick={() => setShowUploadWin(true)} className="h-[100px] bg-third rounded-md cursor-pointer flex justify-center items-center group">
        <SvgCom Svg={AddImgSvg} size="30"></SvgCom>
        <span className="text-sm ml-3 text-primary hover:font-bold">Upload</span>
      </div>

      <div className="h-[3px] bg-grey-2 my-[30px] w-[110%] ml-[-1%]"></div>
      <div className="flex items-center justify-between mb-[20px]">
        <span className="text-xl font-bold">Recent project</span>
        <button className="border-btn">
          <span className="mr-2">View All Projects</span>
          <SvgCom Svg={ArrowRightSvg} size="20"></SvgCom>
        </button>
      </div>
      <div className="grid gap-6 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4">
        <ProjectCardCom></ProjectCardCom>
        <ProjectCardCom></ProjectCardCom>
        <ProjectCardCom></ProjectCardCom>
        <ProjectCardCom></ProjectCardCom>
      </div>
      <div className="h-[3px] bg-grey-2 my-[30px] w-[110%] ml-[-1%]"></div>
      <div className="flex items-center justify-between mb-[25px]">
        <span className="text-xl font-semibold">Recent showreels</span>
        <button className="border-btn">
          <span className="mr-2">View All showreels</span>
          <SvgCom Svg={ArrowRightSvg} size="20"></SvgCom>
        </button>
      </div>
      <div className="grid gap-4 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-6">
        <VideoCardCom></VideoCardCom>
        <VideoCardCom></VideoCardCom>
        <VideoCardCom></VideoCardCom>
        <VideoCardCom></VideoCardCom>
        <VideoCardCom></VideoCardCom>
        <VideoCardCom></VideoCardCom>
      </div>

      <UploadWinCom showWin={showUploadWin} onCloseWin={setShowUploadWin}></UploadWinCom>
      <ProjectWinCom showWin={showProjectWin} onCloseWin={setShowProjectWin}></ProjectWinCom>
    </div>
  );
};

export default HomePage;