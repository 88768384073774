import React, {
  useEffect,
  useState,
  useRef,
  useCallback,
  forwardRef,
  useImperativeHandle,
} from 'react';
import { Empty } from 'antd';
import TrendLifeStageFilter from '../trend-lifestage-filter/trend-lifestage-filter.jsx';
import { fetchTopDishIndex } from '../../api/request.js';
import {
  Filter,
  filterGreen,
  searchIcon,
  searchIconGreen,
  rectangleDownIcon,
  knorIcon,
  hellmonsIcon,
  arrowUp,
  arrowUpGreen,
  arrowDownGreen,
  chevronDownIcon,
  trendingIcon,
  trendingDown,
  trendingup,
  roycoIcon,
  bangoIcon,
  maizenaIcon,
} from '../../../../components/icons/icons.jsx';
import PastaKnorrBackground from '../../../../assets/images/ai-top-dish/pasta.png';
import PastaHellmannsBackground from '../../../../assets/images/ai-top-dish/pasta-hellmanns.png';
import BoundSaladsBackground from '../../../../assets/images/ai-top-dish/bound-salads.png';
import BowlsBackground from '../../../../assets/images/ai-top-dish/bowls.png';
import BreakfastSandwichBacground from '../../../../assets/images/ai-top-dish/breakfast-sandwich.png';
import LunchSandwichBackground from '../../../../assets/images/ai-top-dish/lunch-sandwich.png';
import BurgerBackground from '../../../../assets/images/ai-top-dish/burger.png';
import ChickenBackground from '../../../../assets/images/ai-top-dish/chicken.png';
import CasseroleBackground from '../../../../assets/images/ai-top-dish/casserole.png';
import ColdDipBackground from '../../../../assets/images/ai-top-dish/cold-dip.png';
import HotDipBackground from '../../../../assets/images/ai-top-dish/hot-dip.png';
import CornBackground from '../../../../assets/images/ai-top-dish/corn.png';
import RiceBackground from '../../../../assets/images/ai-top-dish/rice.png';
import SoupBackground from '../../../../assets/images/ai-top-dish/soup.png';
import WrapsBackground from '../../../../assets/images/ai-top-dish/wraps.png';
import devilledEggsBackground from '../../../../assets/images/ai-top-dish/devilled-eggs.png';
import FriesBackground from '../../../../assets/images/ai-top-dish/fries.png';
import TacosBackground from '../../../../assets/images/ai-top-dish/tacos.png';
import StewsBackground from '../../../../assets/images/ai-top-dish/stew.png';
import Sort from '../sort/sort.jsx';
import IndexScoreFilter from '../index-score-filter/index-score-filter.jsx';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import BorderContainer from '../border-container/border-container.jsx';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import style from './top-dish-table.module.scss';
import GrowthPost from '../growth-post/growth-post.jsx';
import DeepDivePopup from '../deep-dive-pop-up/deep-dive-pop-up.jsx';
import Spacer from '../../../../assets/images/ai-top-dish/spacer.png';
import CahBackground from '../../../../assets/images/ai-top-dish/cah-kankung.png';
import KariBackground from '../../../../assets/images/ai-top-dish/kari-ayam.png';
import SayurBackground from '../../../../assets/images/ai-top-dish/sayur-lodeh.png';
import TelurBackground from '../../../../assets/images/ai-top-dish/telur-dadar.png';
import NasiBackground from '../../../../assets/images/ai-top-dish/nasi-goreng.png';
import AyamBackground from '../../../../assets/images/ai-top-dish/ayam-bakar.png';
import BucarroBackground from '../../../../assets/images/ai-top-dish/bucarro-quente.png';
import SaladaBackground from '../../../../assets/images/ai-top-dish/salada-batata.png';
import CoxinhaBackground from '../../../../assets/images/ai-top-dish/coxinha.png';
import ArrozBackground from '../../../../assets/images/ai-top-dish/arroz-feijão.png';
import LasanhaBackground from '../../../../assets/images/ai-top-dish/lasanha-frango.png';
import FrangoBackground from '../../../../assets/images/ai-top-dish/frango-cubos.png';
import SopaBackground from '../../../../assets/images/ai-top-dish/sopa-legumes.png';
import EstrogonofeBackground from '../../../../assets/images/ai-top-dish/estrogonofe-frango.png';
import MingauBackground from '../../../../assets/images/ai-top-dish/mingau.png';

const initialSortOrders = {
  categoryRelevance: 'as-is',
  googleSearch: 'as-is',
  metaMentions: 'as-is',
  tikTokPosts: 'as-is',
  googleSearchVariation: 'as-is',
  metaMentionsVariation: 'as-is',
  tikTokPostsVariation: 'as-is',
};

const initialSortIcon = {
  categoryRelevance: arrowUp,
  googleSearch: arrowUp,
  metaMentions: arrowUp,
  tikTokPosts: arrowUp,
  googleSearchVariation: arrowUp,
  metaMentionsVariation: arrowUp,
  tikTokPostsVariation: arrowUp,
};

const TopDishesTable = forwardRef(
  ({ brand, value, country, marketData }, ref) => {
    const [dishes, setDishes] = useState([]);
    const [originalDishes, setOriginalDishes] = useState([]);
    const [topDishData, setTopDishData] = useState(null);
    const [search, setSearch] = useState('');
    const [isSearchOpen, setIsSearchOpen] = useState(false);
    const [filterOpen, setFilterOpen] = useState(false);
    const [rangeFilterOpen, setRangeFilterOpen] = useState(false);
    const [selectedStages, setSelectedStages] = useState([]);
    const [showSortOptions, setShowSortOptions] = useState(false);
    const [showSortOptionsTikTok, setShowSortOptionsTikTok] = useState(false);
    const [showSortOptionsMetaMention, setShowSortOptionsMetaMention] =
      useState(false);
    const [showSortOptionsGoogleSearch, setShowSortOptionsGoogleSearch] =
      useState(false);
    const [OptionsTikTokVariation, setOptionsTikTokVariation] = useState(false);
    const [OptionsMetaMentionVariation, setOptionsMetaMentionVariation] =
      useState(false);
    const [OptionsGoogleSearchVariation, setOptionsGoogleSearchVariation] =
      useState(false);
    const [selectedIndexScoreRange, setSelectedIndexScoreRange] = useState([]);
    const [loading, setLoading] = useState(true);
    const [expandedRows, setExpandedRows] = useState({});
    const [editColumns, setEditColumns] = useState(false);
    const [err, setErr] = useState(false);
    const [headerTitle, setHeaderTitle] = useState('Top Dish Variant');
    const [containerClass, setContainerClass] = useState('');
    const [activeTabs, setActiveTabs] = useState({});
    const [isPopupVisible, setIsPopupVisible] = useState(false);
    const [columnVisibility, setColumnVisibility] = useState({
      TopDishVariant: true,
      IndexScore: true,
      TrendLifecycle: true,
      CategoryRelevance: true,
      GoogleSearch: true,
      MetaPosts: true,
      TikTokMentions: true,
      GoogleSearchVariation: false,
      MetaPostsVariation: false,
      TikTokMentionsVariation: false,
      ViewMore: true,
    });
    const [sortOrders, setSortOrders] = useState(initialSortOrders);
    const [sortIconSrc, setSortIconSrc] = useState(initialSortIcon);
    const [isAppliedMarket, setIsAppliedMarket] = useState(false);
    const [isApplied, setIsApplied] = useState(false);
    const [placeholder, setPlaceholder] = useState('Search');
    const [isAppliedBrand, setIsAppliedBrand] = useState(false);

    const textElementRef = useRef();
    const [hoverStatus, setHover] = useState(false);
    const debounce = (func, wait) => {
      let timeout;
      return (...args) => {
        clearTimeout(timeout);
        timeout = setTimeout(() => func.apply(this, args), wait);
      };
    };

    const compareSize = () => {
      if (textElementRef.current) {
        const width = textElementRef.current.offsetWidth;
        const compare = width < 107;
        setHover(compare);
      }
    };

    const debouncedCompareSize = useCallback(debounce(compareSize, 200), []);

    const fetchDefaultData = useCallback(
      (topDishData) => {
        if (topDishData && Array.isArray(topDishData)) {
          let filteredDishesArray = [...topDishData];

          if (value && value.length > 0) {
            filteredDishesArray = filteredDishesArray.filter((dish) =>
              value.includes(dish.top_dish)
            );
          }

          if (filteredDishesArray.length > 0) {
            setDishes(filteredDishesArray);
            setOriginalDishes(filteredDishesArray);
          } else {
            setDishes([]);
            setOriginalDishes([]);
          }
          const title = getTrendingTwistName(filteredDishesArray);
          setHeaderTitle(title);
          const updatedContainerClass = getContainerClass(filteredDishesArray);
          setContainerClass(updatedContainerClass);
        } else {
          setDishes([]);
          setOriginalDishes([]);
          setContainerClass('');
          setHeaderTitle('Top Dish Variant');
        }
      },
      [value]
    );

    const fetchData = useCallback(async () => {
      setLoading(true);
      try {
        const data = {
          brands: brand,
          countries: country,
          top_dishes: value,
        };

        const res = await fetchTopDishIndex(data);
        const topDishData = res.data;
        setTopDishData(topDishData);
        fetchDefaultData(topDishData);
        setErr(false);
      } catch (err) {
        console.error('Error fetching top dish index:', err);
        setErr(true);
        setHeaderTitle('Top Dish Variant');
        setContainerClass('');
      } finally {
        setLoading(false);
        debouncedCompareSize();
      }
    }, [brand, country, value, fetchDefaultData]);

    useEffect(() => {
      if (marketData.length > 0) {
        fetchData();
      }
    }, [marketData]);

    const handleApplyClick = () => {
      fetchData();
      setSortOrders(initialSortOrders);
      setSelectedStages([]);
      setSearch();
      setSelectedIndexScoreRange([]);
      setSortIconSrc(initialSortIcon);
      setIsApplied(value && value.length > 1);
      setIsAppliedMarket(country && country.length > 1);
      setIsAppliedBrand(brand && brand.length > 1);
    };

    useImperativeHandle(ref, () => ({
      handleApplyClick,
    }));

    const dishClassMap = {
      'Pasta Hellmanns': PastaHellmannsBackground,
      'Pasta Knorr': PastaKnorrBackground,
      'Bound Salads': BoundSaladsBackground,
      Stews: StewsBackground,
      Tacos: TacosBackground,
      Fries: FriesBackground,
      Burger: BurgerBackground,
      'Side Dips': ColdDipBackground,
      Salad: BoundSaladsBackground,
      'Chicken Based Dishes': ChickenBackground,
      'Lunch Sandwiches': LunchSandwichBackground,
      Wraps: WrapsBackground,
      Soup: SoupBackground,
      'Bakes and Casseroles': CasseroleBackground,
      Bowls: BowlsBackground,
      Rice: RiceBackground,
      Sandwiches: BreakfastSandwichBacground,
      'Breakfast Sandwiches': BreakfastSandwichBacground,
      Burgers: BurgerBackground,
      Chicken: ChickenBackground,
      'Cold Dips': ColdDipBackground,
      Corn: CornBackground,
      'Devilled Eggs': devilledEggsBackground,
      'Hot Dips': HotDipBackground,
      'Stir fried': CahBackground,
      Stewed: KariBackground,
      Soupy: SayurBackground,
      'Fried/Deep-fried': TelurBackground,
      'Rice and Noodle Dishes': NasiBackground,
      Other: AyamBackground,
      Sanduíche: BucarroBackground,
      'Salade de Batata': SaladaBackground,
      Lanches: CoxinhaBackground,
      'Prato Feito': ArrozBackground,
      Massa: LasanhaBackground,
      'Carne e Peixe': FrangoBackground,
      Sopa: SopaBackground,
      Ensopado: EstrogonofeBackground,
      'Maizena Top Dish': MingauBackground,
      Default: '',
    };

    const getContainerClass = () => {
      if (Array.isArray(value)) {
        if (value.length > 1 || value.length === 0) {
          return '';
        } else {
          const selectedDish = value[0];
          if (selectedDish === 'Pasta') {
            if (Array.isArray(brand) && brand.includes('Hellmann\'s')) {
              return PastaHellmannsBackground;
            } else if (Array.isArray(brand) && brand.includes('Knorr')) {
              return PastaKnorrBackground;
            }
          }
          return dishClassMap[selectedDish] || '';
        }
      } else {
        return '';
      }
    };

    const getBrandIconForDish = (brandName) => {
      if (!topDishData) return null;

      for (const dish of topDishData) {
        if (dish.brand === brandName) {
          switch (dish.brand) {
          case 'Knorr':
            return knorIcon;
          case 'Hellmann\'s':
            return hellmonsIcon;
          case 'Bango':
            return bangoIcon;
          case 'Royco':
            return roycoIcon;
          case 'Maizena':
            return maizenaIcon;
          default:
            return null;
          }
        }
      }
      return null;
    };

    const handleSelectedIndexScoreRange = (value) => {
      setSelectedIndexScoreRange(value);
    };

    const getColorForIndexScore = (indexScore) => {
      if (indexScore >= 50) {
        return 'above-90';
      } else if (indexScore >= 30 && indexScore <= 49) {
        return 'above-60';
      } else {
        return 'below-60';
      }
    };

    const getTrendingIcon = (value) => {
      if (value === 0 || value === 1 || value === 2) {
        return (
          <img
            src={trendingIcon}
            className={style.trendingStableImage}
            alt="Trending"
          />
        );
      } else if (value < 0) {
        return (
          <img
            src={trendingDown}
            className={style.trendingDownImage}
            alt="Trending down"
          />
        );
      } else if (value > 2) {
        return (
          <img
            src={trendingup}
            className={style.trendingImage}
            alt="Trending up"
          />
        );
      } else {
        return <img src={Spacer} className={style.noImage} />;
      }
    };

    const handleFilterByTrendLifeStage = (stage) => {
      if (selectedStages.includes(stage)) {
        setSelectedStages(selectedStages.filter((s) => s !== stage));
      } else {
        setSelectedStages([...selectedStages, stage]);
      }
    };

    const handleSearchChange = (event) => {
      setSearch(event.target.value);
    };

    const filteredDishes = dishes?.filter((dish) => {
      const trendLifeStageMatch =
        selectedStages.length === 0 ||
        selectedStages.includes(dish.trend_lifestage);

      const rangeHandlers = {
        'below-60': (index) => index < 60,
        '60-100': (index) => index >= 60 && index <= 100,
      };

      let indexScoreMatch = true;

      if (
        Array.isArray(selectedIndexScoreRange) &&
        selectedIndexScoreRange.length > 0
      ) {
        indexScoreMatch = selectedIndexScoreRange.some((range) => {
          if (rangeHandlers[range]) {
            return rangeHandlers[range](dish.trend_index);
          } else {
            const [minScore, maxScore] = range.split('-').map(Number);
            return dish.trend_index >= minScore && dish.trend_index <= maxScore;
          }
        });
      }

      const searchMatch =
        !search ||
        dish.trending_dish.toLowerCase().includes(search.toLowerCase());

      return trendLifeStageMatch && indexScoreMatch && searchMatch;
    });

    const handleColumnToggle = (columnName) => {
      setColumnVisibility((prevVisibility) => ({
        ...prevVisibility,
        [columnName]: !prevVisibility[columnName],
      }));
      debouncedCompareSize();
    };
    const columnKeyMapping = {
      tikTokPosts: 'tiktok_post_count',
      metaMentions: 'meta_post_count',
      googleSearch: 'google_search_volume',
      categoryRelevance: 'relevance_score',
      tikTokPostsVariation: 'tiktok_post_count_variation',
      metaMentionsVariation: 'meta_post_count_variation',
      googleSearchVariation: 'google_search_volume_variation',
    };

    const [currentSortColumn, setCurrentSortColumn] = useState(null);

    const handleSort = (columnKey, newSortOrder) => {
      const isDifferentColumn =
        currentSortColumn && currentSortColumn !== columnKey;
      const updatedSortOrders = {
        ...sortOrders,
        ...(isDifferentColumn ? { [currentSortColumn]: 'as-is' } : {}), // Reset previous column
        [columnKey]: newSortOrder,
      };

      setSortOrders(updatedSortOrders);

      setSortIconSrc((prevState) => {
        const newIconState = { ...prevState };
        if (isDifferentColumn) {
          newIconState[currentSortColumn] = arrowUp;
        }

        newIconState[columnKey] =
          newSortOrder === 'low-to-high'
            ? arrowDownGreen
            : newSortOrder === 'high-to-low'
              ? arrowUpGreen
              : arrowUp;

        return newIconState;
      });

      setCurrentSortColumn(columnKey);

      if (newSortOrder !== 'as-is') {
        const parseNumericValue = (value) => {
          if (typeof value === 'string') {
            if (value.includes('M')) {
              return parseFloat(value.replace('M', '')) * 1000000;
            }
            if (value.includes('K')) {
              return parseFloat(value.replace('K', '')) * 1000;
            }
          }
          return parseFloat(value) || value;
        };

        const actualKey = columnKeyMapping[columnKey] || columnKey;

        const sortedDishes = [...dishes].sort((a, b) => {
          const valueA = parseNumericValue(a[actualKey]);
          const valueB = parseNumericValue(b[actualKey]);

          if (newSortOrder === 'low-to-high') {
            return valueA - valueB;
          } else if (newSortOrder === 'high-to-low') {
            return valueB - valueA;
          }
          return 0;
        });
        setDishes(sortedDishes);
      } else {
        setDishes([...originalDishes]);
      }
    };

    const trendLifeStages = [
      'Seasonal',
      'Fluctuating',
      'Consistent Increase',
      'Consistent Decrease',
      'Low data range',
    ];

    const editColumnRef = useRef(null);
    const filterRef = useRef(null);
    const rangeFilterRef = useRef(null);
    const sortRef = useRef(null);
    const searchRef = useRef(null);

    useEffect(() => {
      const checkIfClickedOutside = (e) => {
        if (
          editColumns &&
          editColumnRef.current &&
          !editColumnRef.current.contains(e.target) &&
          !e.target.classList.contains(style.editColumnFilter)
        ) {
          setEditColumns(false);
        }
        if (
          isSearchOpen &&
          searchRef.current &&
          !searchRef.current.contains(e.target) &&
          !e.target.classList.contains(style.searchImg)
        ) {
          setIsSearchOpen(false);
        }
        if (
          filterOpen &&
          filterRef.current &&
          !filterRef.current.contains(e.target) &&
          !e.target.classList.contains(style.filterIconImg)
        ) {
          setFilterOpen(false);
        }
        if (
          rangeFilterOpen &&
          rangeFilterRef.current &&
          !rangeFilterRef.current.contains(e.target) &&
          !e.target.classList.contains(style.scorefilterIcon)
        ) {
          setRangeFilterOpen(false);
        }
        if (
          showSortOptions &&
          sortRef.current &&
          !sortRef.current.contains(e.target) &&
          !e.target.classList.contains(style.categoryFilterIcon)
        ) {
          setShowSortOptions(false);
        }
        if (
          showSortOptionsGoogleSearch &&
          sortRef.current &&
          !sortRef.current.contains(e.target) &&
          !e.target.classList.contains(style.googleFilterIcon)
        ) {
          setShowSortOptionsGoogleSearch(false);
        }
        if (
          showSortOptionsMetaMention &&
          sortRef.current &&
          !sortRef.current.contains(e.target) &&
          !e.target.classList.contains(style.metaFilterIcon)
        ) {
          setShowSortOptionsMetaMention(false);
        }
        if (
          showSortOptionsTikTok &&
          sortRef.current &&
          !sortRef.current.contains(e.target) &&
          !e.target.classList.contains(style.tikTokFilterIcon)
        ) {
          setShowSortOptionsTikTok(false);
        }
        if (
          OptionsGoogleSearchVariation &&
          sortRef.current &&
          !sortRef.current.contains(e.target) &&
          !e.target.classList.contains(style.googleFilterVariationIcon)
        ) {
          setOptionsGoogleSearchVariation(false);
        }
        if (
          OptionsMetaMentionVariation &&
          sortRef.current &&
          !sortRef.current.contains(e.target) &&
          !e.target.classList.contains(style.metaFilterVariationIcon)
        ) {
          setOptionsMetaMentionVariation(false);
        }
        if (
          OptionsTikTokVariation &&
          sortRef.current &&
          !sortRef.current.contains(e.target) &&
          !e.target.classList.contains(style.tikTokFilterVariationIcon)
        ) {
          setOptionsTikTokVariation(false);
        }
      };

      document.addEventListener('mousedown', checkIfClickedOutside);

      return () => {
        document.removeEventListener('mousedown', checkIfClickedOutside);
      };
    }, [
      editColumns,
      isSearchOpen,
      filterOpen,
      rangeFilterOpen,
      showSortOptions,
      showSortOptionsGoogleSearch,
      showSortOptionsMetaMention,
      showSortOptionsTikTok,
      OptionsTikTokVariation,
      OptionsMetaMentionVariation,
      OptionsGoogleSearchVariation,
    ]);

    const handleEditButtonClick = (e) => {
      e.stopPropagation();
      setEditColumns((prevFilterOpen) => !prevFilterOpen);
    };

    const handleSearchIconClick = (e) => {
      e.stopPropagation();
      setIsSearchOpen((prevFilterOpen) => !prevFilterOpen);
    };

    const handleButtonClick = (e) => {
      e.stopPropagation();
      setRangeFilterOpen((prevFilterOpen) => !prevFilterOpen);
    };

    const handleFilterIconClick = (e) => {
      e.stopPropagation();
      setFilterOpen((prevFilterOpen) => !prevFilterOpen);
    };

    const handleSortOption = (e) => {
      e.stopPropagation();
      setShowSortOptions((prevFilterOpen) => !prevFilterOpen);
    };
    const handleSortOptionGoogleSearch = (e) => {
      e.stopPropagation();
      setShowSortOptionsGoogleSearch((prevFilterOpen) => !prevFilterOpen);
    };
    const handleSortOptionTikTok = (e) => {
      e.stopPropagation();
      setShowSortOptionsTikTok((prevFilterOpen) => !prevFilterOpen);
    };
    const handleSortOptionMeta = (e) => {
      e.stopPropagation();
      setShowSortOptionsMetaMention((prevFilterOpen) => !prevFilterOpen);
    };
    const handleGoogleSearchVariation = (e) => {
      e.stopPropagation();
      setOptionsGoogleSearchVariation((prevFilterOpen) => !prevFilterOpen);
    };
    const handleTikTokVariation = (e) => {
      e.stopPropagation();
      setOptionsTikTokVariation((prevFilterOpen) => !prevFilterOpen);
    };
    const handleMetaVariation = (e) => {
      e.stopPropagation();
      setOptionsMetaMentionVariation((prevFilterOpen) => !prevFilterOpen);
    };

    const handleExpandClick = (dishName, e) => {
      e.stopPropagation();

      setExpandedRows((prevExpandedRows) => {
        const newExpandedRows = Object.keys(prevExpandedRows).reduce(
          (acc, key) => {
            acc[key] = false;
            return acc;
          },
          {}
        );

        const updatedExpandedRows = {
          ...newExpandedRows,
          [dishName]: !prevExpandedRows[dishName],
        };

        // Schedule class update and scroll after state change
        setTimeout(() => {
          const targetElement = document.querySelector(
            `tr[data-dish-name="${dishName}"]`
          );
          if (targetElement) {
            // Add or remove the fixedRow class based on the new expanded state
            const isExpanded = updatedExpandedRows[dishName];
            if (isExpanded) {
              targetElement.classList.add(`${style.fixedRow}`);
            } else {
              targetElement.classList.remove(`${style.fixedRow}`);
            }
          }

          const tableContainer = document.querySelector('.tableContainer');
          if (targetElement && tableContainer) {
            const tableRect = tableContainer.getBoundingClientRect();
            const headerOffset = document.querySelector('thead')
              ? document.querySelector('thead').offsetHeight
              : 0;
            const targetRect = targetElement.getBoundingClientRect();
            const clickedOffset =
              targetRect.top - tableRect.top + tableContainer.scrollTop;

            // Ensure scrolling does not go past the table's bottom edge
            const maxScrollTop =
              tableContainer.scrollHeight - tableContainer.clientHeight;
            const targetScrollTop = clickedOffset - headerOffset + headerOffset;

            tableContainer.scrollTo({
              top: Math.min(targetScrollTop, maxScrollTop),
              behavior: 'smooth',
            });
          }
        }, 0);

        return updatedExpandedRows;
      });

      setActiveTabs((prev) => ({
        ...prev,
        [dishName]: 'tab1',
      }));
    };

    const getDisplayName = (columnName) => {
      switch (columnName) {
      case 'MetaPostsVariation':
        return 'Meta Posts (%)';

      case 'TikTokMentionsVariation':
        return 'TikTok Mentions (%)';

      case 'GoogleSearchVariation':
        return 'Google Search (%)';

      default:
        return splitColumnName(columnName);
      }
    };

    const splitColumnName = (columnName) => {
      return columnName.replace(/([a-z])([A-Z])/g, '$1 $2');
    };

    const formatDate = (dateString) => {
      const date = new Date(dateString);
      const month = date.toLocaleString('default', { month: 'long' });
      const year = date.getFullYear();
      return `${month} ${year}`;
    };

    const getRefreshDate = () => {
      if (filteredDishes && filteredDishes.length > 0 && filteredDishes[0]) {
        return formatDate(filteredDishes[0].refresh_period);
      } else {
        return 'No date available';
      }
    };

    const getTrendingTwistName = (filteredDishesArray) => {
      if (value.length === 1 && value.length > 0) {
        return `${value}`;
      } else {
        return 'Top Dish Variant';
      }
    };

    const antIcon = (
      <LoadingOutlined
        style={{
          fontSize: 30,
          fontWeight: 600,
          color: '#00FF00',
        }}
        spin
      />
    );

    const handleTabClick = (dishName, tabName) => {
      setActiveTabs((prev) => ({
        ...prev,
        [dishName]: tabName,
      }));
    };
    const [selectedDish, setSelectedDish] = useState(null);  // state to store selected dish

    const togglePopup = (dish) => {
      setSelectedDish(dish);  // Store the clicked dish
      setIsPopupVisible(!isPopupVisible);  // Toggle visibility of the popup
    };

    // Generic handler function
    const handleTooltipShow = (instance, state) => {
      if (state) {
        instance.popper.style.display = 'none';
      } else {
        instance.popper.style.display = 'block';
      }
    };

    return (
      <><DeepDivePopup
        isVisible={isPopupVisible}
        onClose={togglePopup}
        countries={[selectedDish?.market]}  // Use optional chaining here
        brand={[selectedDish?.brand]}
        topDish={[selectedDish?.top_dish]}
        trendingDish={[selectedDish?.trending_dish]}
        trendingDishId={selectedDish?.trending_dish_id}
      /><div className={style.mainContainer}>
        <BorderContainer>
          <div className={style.DishTableContainer}>
            <div className={style.headerContainer}>
              <div className={style.container}>
                <div className={style.headerTitle}>
                  <h2> {headerTitle}</h2>
                  <span>Last refreshed on {getRefreshDate()}</span>
                </div>
                <div className={style.textContainer}></div>
                <div
                  className={`${style.dishContainer}`}
                  style={{
                    backgroundImage: `linear-gradient(90deg, rgba(0,48,2,1) 0%, rgba(0,48,2,0.6616771708683473) 0%, rgba(1,47,3,0) 21%, rgba(2,46,3,0) 83%, rgba(0,20,2,1) 100%, rgba(2,46,3,1) 100%), url(${containerClass})`,
                    backgroundRepeat: 'no-repeat',
                  }}
                ></div>
                {/* <div className={`${style.editContainer}`}>
    <button onClick={handleEditButtonClick}>
        Edit columns{' '}
      <img
        src={chevronDownIcon}
        className={style.editColumnFilter}
        alt="chevron-down"
      />
    </button>
  </div> */}
              </div>
            </div>
            {editColumns && (
              <div ref={editColumnRef} className={style.editColumn}>
                <div className={style.editColumnContainer}>
                  {Object.keys(columnVisibility)
                    .sort()
                    .map((columnName) => (
                      <label key={columnName}>
                        <input
                          type="checkbox"
                          checked={columnVisibility[columnName]}
                          onChange={() => handleColumnToggle(columnName)} />
                        {getDisplayName(columnName)}
                      </label>
                    ))}
                </div>
              </div>
            )}
            <div className={style.tableContainer}>
              <table>
                <thead>
                  <tr>
                    {isAppliedMarket && <th>Market</th>}
                    {isAppliedBrand && <th className={style.brand}>Brand</th>}
                    {isApplied && <th className="topDish">Top Dish</th>}
                    {columnVisibility.TopDishVariant && (
                      <Tippy
                        theme="custom"
                        maxWidth="264px"
                        content={<span>
                          <h3>Top Dish Variant</h3>
                          Name of the Top Dish Variant for the selected Top
                          Dish
                        </span>}
                        placement="top"
                      >
                        <th className={style.searchField}>
                          {isSearchOpen ? (
                            <span className={style.trendingSearch}>
                              <input
                                ref={searchRef}
                                type="text"
                                placeholder={placeholder}
                                autoFocus
                                value={search}
                                onChange={handleSearchChange} />
                            </span>
                          ) : (
                            <span
                              className={`${style.handCursor} ${style.trendingSearch}`}
                              onClick={handleSearchIconClick}
                            >
                              <span className={style.midAlign}>
                                <span>
                                  <img
                                    src={search?.length > 0
                                      ? searchIconGreen
                                      : searchIcon}
                                    alt="search"
                                    className={style.searchImg} />
                                </span>
                                Top Dish Variant
                              </span>
                            </span>
                          )}
                        </th>
                      </Tippy>
                    )}

                    {columnVisibility.IndexScore && (
                      <Tippy
                        maxWidth="310px"
                        theme="custom"
                        content={<span>
                          <h3>Index Score</h3>
                          Score that indicates the strength of the Top Dish
                          Variant on a scale from 1 to 100
                        </span>}
                        placement="top"
                        onShow={(instance) => handleTooltipShow(instance, rangeFilterOpen)}
                      >
                        <th
                          className={`${style.blockContainer} ${style.indexScoreCell}`}
                        >
                          <span
                            className={style.midAlign}
                            onClick={handleButtonClick}
                          >
                            <span className={style.imgContainer}>
                              <img
                                src={selectedIndexScoreRange.length > 0 &&
                                  selectedIndexScoreRange != '1-100'
                                  ? filterGreen
                                  : Filter}
                                alt="filter"
                                className={`filterImgIcon ${style.scorefilterIcon}`} />
                            </span>
                            <span
                              className={`${style.textContainer} ${style.scorefilterIcon}`}
                            >
                              Index
                              <br />
                              Score{' '}
                            </span>
                          </span>
                          {rangeFilterOpen && (
                            <div ref={rangeFilterRef}>
                              <IndexScoreFilter
                                setSelectedIndexScoreRange={handleSelectedIndexScoreRange}
                                initialSelectedRanges={selectedIndexScoreRange} />
                            </div>
                          )}
                        </th>
                      </Tippy>
                    )}
                    {columnVisibility.TrendLifecycle && (
                      <Tippy
                        maxWidth="264px"
                        theme="custom"
                        content={<span>
                          <h3>Trend Lifecycle</h3>
                          The pattern of growth of the Top Dish Variant in the
                          last month (seasonal, fluctuating, consistent
                          increase, consistent decrease).
                        </span>}
                        placement="top"
                        onShow={(instance) => handleTooltipShow(instance, filterOpen)}
                      >
                        <th className={style.blockContainer}>
                          <span
                            className={style.midAlign}
                            onClick={handleFilterIconClick}
                          >
                            <span className={style.imgContainer}>
                              <img
                                src={selectedStages.length > 0
                                  ? filterGreen
                                  : Filter}
                                alt="filter"
                                className={`filterImgIcon ${style.filterIconImg}`} />
                            </span>
                            <span
                              className={`${style.textContainer} ${style.filterIconImg}`}
                            >
                              Trend
                              <br />
                              Lifecycle{' '}
                            </span>
                          </span>
                          {filterOpen && (
                            <div ref={filterRef}>
                              <TrendLifeStageFilter
                                trendLifeStages={trendLifeStages}
                                setFilterByTrendLifeStage={handleFilterByTrendLifeStage}
                                selectedStages={selectedStages} />
                            </div>
                          )}
                        </th>
                      </Tippy>
                    )}
                    {columnVisibility.CategoryRelevance && (
                      <Tippy
                        maxWidth="264px"
                        theme="custom"
                        content={<span>
                          <h3>Category Relevance</h3>
                          The relevance of this Top Dish Variant to our Top
                          Dish Category on a scale from 0 to 100
                        </span>}
                        placement="top"
                        onShow={(instance) => handleTooltipShow(instance, showSortOptions)}
                      >
                        <th className={style.blockContainer}>
                          <span
                            className={style.midAlign}
                            onClick={handleSortOption}
                          >
                            <span className={style.imgContainer}>
                              <img
                                src={sortIconSrc.categoryRelevance}
                                alt="sort"
                                className={`filterImgIcon ${style.categoryFilterIcon}`} />
                            </span>
                            <span
                              className={`${style.textContainer} ${style.categoryFilterIcon}`}
                            >
                              Category
                              <br /> Relevance{' '}
                            </span>
                          </span>
                          {showSortOptions && (
                            <div ref={sortRef}>
                              <Sort
                                sortOrder={sortOrders.categoryRelevance}
                                handleSort={(newSortOrder) => handleSort('categoryRelevance', newSortOrder)} />
                            </div>
                          )}
                        </th>
                      </Tippy>
                    )}
                    {columnVisibility.TikTokMentions && (
                      <Tippy
                        maxWidth="264px"
                        theme="custom"
                        content={<span>
                          <h3>TikTok Mentions</h3>
                          Shows the absolute number of TikTok mentions in the
                          last month
                        </span>}
                        placement="top"
                        onShow={(instance) => handleTooltipShow(instance, showSortOptionsTikTok)}
                      >
                        <th className={style.blockContainer}>
                          <span
                            className={style.midAlign}
                            onClick={handleSortOptionTikTok}
                          >
                            <span className={style.imgContainer}>
                              <img
                                src={sortIconSrc.tikTokPosts}
                                alt="sort"
                                className={`filterImgIcon ${style.tikTokFilterIcon}`} />
                            </span>
                            <span
                              className={`${style.textContainer} ${style.tikTokFilterIcon}`}
                            >
                              TikTok
                              <br /> Mentions{' '}
                            </span>
                          </span>

                          {showSortOptionsTikTok && (
                            <div ref={sortRef}>
                              <Sort
                                sortOrder={sortOrders['tikTokPosts']}
                                handleSort={(newSortOrder) => handleSort('tikTokPosts', newSortOrder)} />{' '}
                            </div>
                          )}
                        </th>
                      </Tippy>
                    )}
                    {columnVisibility.TikTokMentionsVariation && (
                      <Tippy
                        theme="custom"
                        maxWidth="264px"
                        content={<span>
                          <h3>TikTok Mentions (%)</h3>Shows the percentage
                          growth in the number of TikTok mentions in the last
                          month
                        </span>}
                        placement="top"
                        onShow={(instance) => handleTooltipShow(instance, OptionsTikTokVariation)}
                      >
                        <th className={style.blockContainer}>
                          <span
                            className={style.midAlign}
                            onClick={handleTikTokVariation}
                          >
                            <span className={style.imgContainer}>
                              <img
                                src={sortIconSrc.tikTokPostsVariation}
                                alt="sort"
                                className={`filterImgIcon ${style.tikTokFilterVariationIcon}`} />
                            </span>
                            <span
                              className={`${style.textContainer} ${style.tikTokFilterVariationIcon}`}
                            >
                              TikTok
                              <br /> Mentions
                            </span>
                            <span className="mt-20">&nbsp;(%)</span>
                          </span>
                          {OptionsTikTokVariation && (
                            <div ref={sortRef}>
                              <Sort
                                sortOrder={sortOrders['tikTokPostsVariation']}
                                handleSort={(newSortOrder) => handleSort(
                                  'tikTokPostsVariation',
                                  newSortOrder
                                )} />{' '}
                            </div>
                          )}
                        </th>
                      </Tippy>
                    )}
                    {columnVisibility.MetaPosts && (
                      <Tippy
                        theme="custom"
                        maxWidth="264px"
                        content={<span>
                          <h3>Meta Posts</h3>Shows the absolute number of Meta
                          posts in the last month
                        </span>}
                        placement="top"
                        onShow={(instance) => handleTooltipShow(
                          instance,
                          showSortOptionsMetaMention
                        )}
                      >
                        <th className={style.blockContainer}>
                          <span
                            className={style.midAlign}
                            onClick={handleSortOptionMeta}
                          >
                            <span className={style.imgContainer}>
                              <img
                                src={sortIconSrc.metaMentions}
                                alt="sort"
                                className={`filterImgIcon ${style.metaFilterIcon}`} />
                            </span>
                            <span
                              className={`${style.textContainer} ${style.metaFilterIcon}`}
                            >
                              Meta
                              <br /> Posts{' '}
                            </span>
                          </span>
                          {showSortOptionsMetaMention && (
                            <div ref={sortRef}>
                              <Sort
                                sortOrder={sortOrders['metaMentions']}
                                handleSort={(newSortOrder) => handleSort('metaMentions', newSortOrder)} />
                            </div>
                          )}
                        </th>
                      </Tippy>
                    )}
                    {columnVisibility.MetaPostsVariation && (
                      <Tippy
                        theme="custom"
                        maxWidth="264px"
                        content={<span>
                          <h3>Meta Posts (%)</h3>Shows the percentage growth
                          in the number of Meta posts in the last month in
                          percentage
                        </span>}
                        placement="top"
                        onShow={(instance) => handleTooltipShow(
                          instance,
                          OptionsMetaMentionVariation
                        )}
                      >
                        <th className={style.blockContainer}>
                          <span
                            className={style.midAlign}
                            onClick={handleMetaVariation}
                          >
                            <span className={style.imgContainer}>
                              <img
                                src={sortIconSrc.metaMentionsVariation}
                                alt="sort"
                                className={`filterImgIcon ${style.metaFilterVariationIcon}`} />
                            </span>
                            <span
                              className={`${style.textContainer} ${style.metaFilterVariationIcon}`}
                            >
                              Meta
                              <br /> Posts
                            </span>
                            <span className="mt-20">&nbsp;(%)</span>
                          </span>
                          {OptionsMetaMentionVariation && (
                            <div ref={sortRef}>
                              <Sort
                                sortOrder={sortOrders['metaMentionsVariation']}
                                handleSort={(newSortOrder) => handleSort(
                                  'metaMentionsVariation',
                                  newSortOrder
                                )} />
                            </div>
                          )}
                        </th>
                      </Tippy>
                    )}
                    {columnVisibility.GoogleSearch && (
                      <Tippy
                        theme="custom"
                        maxWidth="264px"
                        content={<span>
                          <h3>Google Search</h3>Shows the absolute number of
                          Google searches in the last month{' '}
                        </span>}
                        placement="top"
                        onShow={(instance) => handleTooltipShow(
                          instance,
                          showSortOptionsGoogleSearch
                        )}
                      >
                        <th className={style.blockContainer}>
                          <span
                            className={style.midAlign}
                            onClick={handleSortOptionGoogleSearch}
                          >
                            <span className={style.imgContainer}>
                              <img
                                src={sortIconSrc.googleSearch}
                                alt="sort"
                                className={`filterImgIcon ${style.googleFilterIcon}`} />
                            </span>
                            <span
                              className={`${style.textContainer} ${style.googleFilterIcon}`}
                            >
                              Google
                              <br />
                              Search
                            </span>
                          </span>
                          {showSortOptionsGoogleSearch && (
                            <div ref={sortRef}>
                              <Sort
                                sortOrder={sortOrders['googleSearch']}
                                handleSort={(newSortOrder) => handleSort('googleSearch', newSortOrder)} />
                            </div>
                          )}
                        </th>
                      </Tippy>
                    )}
                    {columnVisibility.GoogleSearchVariation && (
                      <Tippy
                        theme="custom"
                        maxWidth="264px"
                        content={<span>
                          <h3>Google Search (%)</h3>Shows the percentage
                          growth in the number of Google searches in the last
                          month in percentage
                        </span>}
                        placement="top"
                        onShow={(instance) => handleTooltipShow(
                          instance,
                          OptionsGoogleSearchVariation
                        )}
                      >
                        <th className={style.blockContainer}>
                          <span
                            className={style.midAlign}
                            onClick={handleGoogleSearchVariation}
                          >
                            <span className={style.imgContainer}>
                              <img
                                src={sortIconSrc.googleSearchVariation}
                                alt="sort"
                                className={`filterImgIcon ${style.googleFilterVariationIcon}`} />
                            </span>
                            <span
                              className={`${style.textContainer} ${style.googleFilterVariationIcon}`}
                            >
                              Google
                              <br /> Search
                            </span>
                            <span className="mt-20">&nbsp;(%)</span>
                          </span>

                          {OptionsGoogleSearchVariation && (
                            <div ref={sortRef}>
                              <Sort
                                sortOrder={sortOrders['googleSearchVariation']}
                                handleSort={(newSortOrder) => handleSort(
                                  'googleSearchVariation',
                                  newSortOrder
                                )} />
                            </div>
                          )}
                        </th>
                      </Tippy>
                    )}
                    {columnVisibility.ViewMore && (
                      <th className={style.centerAlign}>
                        View
                        <br /> More
                      </th>
                    )}
                  </tr>
                </thead>
                {loading && (
                  <Spin indicator={antIcon}>
                    <div className={style.placeholder}></div>
                  </Spin>
                )}
                {!loading && (
                  <tbody className="tableContainer">
                    {dishes.length === 0 || err === true ? (
                      <tr>
                        <td
                          colSpan={Object.keys(columnVisibility).filter(
                            (col) => columnVisibility[col]
                          ).length}
                          className={style.errorMessage}
                        >
                          <Empty
                            className={style.emptyTable}
                            image={Empty.PRESENTED_IMAGE_SIMPLE} />
                        </td>
                      </tr>
                    ) : filteredDishes.length > 0 ? (
                      filteredDishes.map((dish, index) => (
                        <React.Fragment key={index}>
                          <tr data-dish-name={dish.trending_dish}>
                            {isAppliedMarket && (
                              <td>
                                <div className={style.market}>
                                  {dish.market}
                                </div>
                              </td>
                            )}
                            {isAppliedBrand && (
                              <td className={style.brand}>
                                <img
                                  src={getBrandIconForDish(dish.brand)}
                                  alt="Brand Icon" />
                              </td>
                            )}
                            {isApplied && (
                              <td className={style.topDish}>{dish.top_dish}</td>
                            )}
                            {columnVisibility.TopDishVariant && (
                              <td className={`${style.searchField}`}>
                                <div>{dish.trending_dish}</div>
                              </td>
                            )}
                            {columnVisibility.IndexScore && (
                              <td className={style.indexScoreCell}>
                                <div
                                  className={`${style.dot} ${style[getColorForIndexScore(dish.trend_index)]}`}
                                ></div>
                                {dish.trend_index}{' '}
                              </td>
                            )}
                            {columnVisibility.TrendLifecycle && (
                              <td className="tooltipLifecycle">
                                {hoverStatus ? (
                                  <Tippy
                                    theme="tippycustom"
                                    content={<span>{dish.trend_lifestage}</span>}
                                    placement="top"
                                  >
                                    <div
                                      ref={textElementRef}
                                      className={`${style.leftAlign} ${style.truncateTextStage} textContainer`}
                                    >
                                      {dish.trend_lifestage}
                                    </div>
                                  </Tippy>
                                ) : (
                                  <div
                                    ref={textElementRef}
                                    className={`${style.leftAlign} ${style.truncateTextStage} textContainer`}
                                  >
                                    {dish.trend_lifestage}
                                  </div>
                                )}
                              </td>
                            )}
                            {columnVisibility.CategoryRelevance && (
                              <td>
                                {getTrendingIcon(dish.relevance_score)}
                                {dish.relevance_score}{' '}
                              </td>
                            )}
                            {columnVisibility.TikTokMentions && (
                              <td>
                                {getTrendingIcon(
                                  dish.tiktok_post_count_variation
                                )}
                                {(dish.tiktok_post_count || '') != ''
                                  ? new Intl.NumberFormat('en-US').format(
                                    dish.tiktok_post_count
                                  )
                                  : '-'}{' '}
                              </td>
                            )}
                            {columnVisibility.TikTokMentionsVariation && (
                              <td>
                                {getTrendingIcon(
                                  dish.tiktok_post_count_variation
                                )}
                                {(dish.tiktok_post_count_variation || '') != ''
                                  ? `${dish.tiktok_post_count_variation}%`
                                  : '-'}{' '}
                              </td>
                            )}
                            {columnVisibility.MetaPosts && (
                              <td>
                                {getTrendingIcon(
                                  dish.meta_post_count_variation
                                )}
                                {(dish.meta_post_count || '') != ''
                                  ? new Intl.NumberFormat('en-US').format(
                                    dish.meta_post_count
                                  )
                                  : '-'}{' '}
                              </td>
                            )}
                            {columnVisibility.MetaPostsVariation && (
                              <td>
                                {getTrendingIcon(
                                  dish.meta_post_count_variation
                                )}
                                {(dish.meta_post_count_variation || '') != ''
                                  ? `${dish.meta_post_count_variation}%`
                                  : '-'}{' '}
                              </td>
                            )}
                            {columnVisibility.GoogleSearch && (
                              <td>
                                {getTrendingIcon(
                                  dish.google_search_volume_variation
                                )}
                                {(dish.google_search_volume || '') != ''
                                  ? new Intl.NumberFormat('en-US').format(
                                    dish.google_search_volume
                                  )
                                  : '-'}{' '}
                              </td>
                            )}
                            {columnVisibility.GoogleSearchVariation && (
                              <td>
                                {getTrendingIcon(
                                  dish.google_search_volume_variation
                                )}
                                {(dish.google_search_volume_variation || '') !=
                                  ''
                                  ? `${dish.google_search_volume_variation}%`
                                  : '-'}{' '}
                              </td>
                            )}
                            {columnVisibility.ViewMore && (
                              <td>  
                                <button className={style.deepDiveButton} onClick={() => togglePopup(dish)}>Deep Dive</button>
                              </td>
                            )}
                          </tr>
                          {expandedRows[dish.trending_dish] && (
                            <tr className={style.transparentRow}>
                              <td
                                colSpan={Object.keys(columnVisibility).filter(
                                  (col) => columnVisibility[col]
                                ).length}
                              >
                                {/* <GrowthPost
                                  countries={[dish.market]}
                                  brand={[dish.brand]}
                                  topDish={[dish.top_dish]}
                                  trendingDish={[dish.trending_dish]}
                                  trendingDishId={dish.trending_dish_id}
                                  defaultTab={activeTabs[dish.trending_dish] || 'tab1'}
                                  onTabClick={(tabName) => handleTabClick(dish.trending_dish, tabName)} /> */}
                              </td>
                            </tr>
                          )}
                        </React.Fragment>
                      ))
                    ) : (
                      <tr>
                        <td
                          className={style.noDataContainer}
                          colSpan={Object.keys(columnVisibility).filter(
                            (col) => columnVisibility[col]
                          ).length}
                        >
                          No Data Available
                        </td>
                      </tr>
                    )}
                  </tbody>
                )}
              </table>
            </div>
          </div>
        </BorderContainer>
      </div></>
    );
  }
);
TopDishesTable.displayName = 'TopDishesTable';

export default TopDishesTable;
