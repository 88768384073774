// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.VJBHDFnk994fSkO36N4g{height:40px;display:flex;align-items:center;padding:0 17px;background:#022f03}.VJBHDFnk994fSkO36N4g img{display:block;margin-right:20px;height:16px}.VJBHDFnk994fSkO36N4g h2{font-size:16px;font-weight:900;line-height:22.3px;color:#00a32e}`, "",{"version":3,"sources":["webpack://./src/modules/package-ai-screening/views/result/title.module.scss"],"names":[],"mappings":"AAAA,sBACE,WAAA,CACA,YAAA,CACA,kBAAA,CACA,cAAA,CACA,kBAAA,CACA,0BACE,aAAA,CACA,iBAAA,CACA,WAAA,CAEF,yBACE,cAAA,CACA,eAAA,CACA,kBAAA,CACA,aAAA","sourcesContent":[".title {\n  height: 40px;\n  display: flex;\n  align-items: center;\n  padding: 0 17px;\n  background: #022f03;\n  img {\n    display: block;\n    margin-right: 20px;\n    height: 16px;\n  }\n  h2 {\n    font-size: 16px;\n    font-weight: 900;\n    line-height: 22.3px;\n    color: #00a32e;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": `VJBHDFnk994fSkO36N4g`
};
export default ___CSS_LOADER_EXPORT___;
