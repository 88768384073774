import React, { useState, useEffect } from 'react';
import LineCharts from '../../components/graph-new/line-charts.jsx';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { noDataGraph } from '../../../../components/icons/icons.jsx';

const GraphComponent = ({
  selectedYears,
  selectedTimeRange,
  graphId,
  graphData,
  getEchartsOption
}) => {
  const [monthLineOption, setMonthLineOption] = useState({});
  const [allgraphData, setAllGraphData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [noDataMessage, setNoDataMessage] = useState(false);

  useEffect(() => {
    setLoading(true);
    if (graphData && graphData.monthsData && graphData.monthsData.length > 0) {
      const categoryData = graphData;
      setAllGraphData(categoryData.monthsData);
      setLoading(false);
      setNoDataMessage(false); // data is present, so no need to show the no data message
    } else {
      console.error(`Data for graphId ${graphId} not found or incomplete`);
      setLoading(false);
      setNoDataMessage(true); // no data found, so show the no data message
    }
  }, [graphData, graphId]);

  useEffect(() => {
    if (allgraphData.length > 0) {
      const sortedSelectedYears = selectedYears.slice().sort();
      const filteredData = sortedSelectedYears.flatMap((year) => {
        const yearString = year.slice(-2);
        return allgraphData.filter((item) => item.period.endsWith(yearString));
      });

      const sortedData = filteredData.sort((a, b) => {
        const [monthA, yearA] = a.period.split('-');
        const [monthB, yearB] = b.period.split('-');
        const monthNames = [
          'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
          'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec',
        ];
        const monthIndexA = monthNames.indexOf(monthA);
        const monthIndexB = monthNames.indexOf(monthB);
        if (yearA === yearB) {
          return monthIndexA - monthIndexB;
        } else {
          return parseInt(yearA) - parseInt(yearB);
        }
      });

      let dataToDisplay = [];

      switch (selectedTimeRange) {
        case 'Monthly':
          sortedSelectedYears.forEach((year) => {
            const yearData = sortedData.filter((item) =>
              item.period.endsWith(year.slice(-2))
            );
            dataToDisplay = dataToDisplay.concat(yearData.slice(0, 12));
          });
          break;
        case 'Quarterly':
          dataToDisplay = sortedData.filter((_, index) => index % 3 === 0);
          break;
        case 'Yearly':
        default:
          dataToDisplay = sortedSelectedYears.map((year) => {
            const yearString = year.slice(-2);
            const yearlyData = sortedData.filter((item) =>
              item.period.endsWith(yearString)
            );
            const totalValue = yearlyData.reduce(
              (sum, item) => sum + item.value,
              0
            );
            return { period: year, value: totalValue };
          });
          break;
      }

      monthOptionFn(dataToDisplay);
      setNoDataMessage(false); // data is present, so no need to show the no data message
    } else {
      setNoDataMessage(true); // no data found, so show the no data message
    }
  }, [allgraphData, selectedTimeRange, selectedYears, graphId]);

  const monthOptionFn = (data) => {
    const options = {
      backgroundColor: '#fffff4',
      grid: {
        left: '80',
      },
      tooltip: {
        trigger: 'axis',
        backgroundColor: 'rgba(20,109,31,0.7)',
        borderColor: '#00A32E',
        textStyle: {
          color: '#FFFFFF',
          fontSize: '14'
        },
      },
      xAxis: {
        type: 'category',
        boundaryGap: false,
        axisLine: {
          lineStyle: {
            color: 'rgba(117, 123, 140, 1)',
          },
        },
        axisTick: {
          show: false,
        },
        axisLabel: {
          hideOverlap: true,
          margin: 10,
          padding: [0, 0, 0, 10],
          textStyle: {
            fontSize: 14
          }
        },
        data: data.map((item) => item.period),
      },
      yAxis: {
        type: 'value',
        axisLine: {
          show: true,
          lineStyle: {
            color: 'rgba(140, 191, 113, 1)',
          },
          textStyle: {
            fontSize: 14
          }
        },
        axisLabel: {
          color: 'rgba(140, 191, 113, 1)',
        },
        splitLine: {
          lineStyle: {
            color: 'rgba(20, 109, 31, 0.1)',
          },
        },
      },
      series: [
        {
          data: data.map((item) => item.value),
          type: 'line',
          // smooth: true,
          // symbol: 'circle',
          // symbolSize: 3,
          itemStyle: {
            color: '#00A32E',
          },
          lineStyle: {
            color: '#00A32E',
            width: 3,
          },
          // areaStyle: {
          //   color: {
          //     type: 'linear',
          //     x: 0,
          //     y: 0,
          //     x2: 0,
          //     y2: 1,
          //     colorStops: [
          //       { offset: 0, color: 'rgba(38, 240, 40, 0.6)' },
          //       { offset: 1, color: 'rgba(0, 163, 46, 0)' }
          //     ],
          //     globalCoord: false
          //   }
          // },
        },
      ],
    };
    setMonthLineOption(options);
    getEchartsOption(options)
  };

  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 30,
        color: '#00FF00'
      }}
      spin
    />
  );
  const splitColumnName = (columnName) => {
    return columnName.replace(/([a-z])([A-Z])/g, '$1 $2');
  };

  return (
    <div className='graph-component'>
      {loading ? (
        <Spin indicator={antIcon}>
          <div className='spin-icon'></div>
        </Spin>
      ) : noDataMessage ? (
        <div>
          <div><img src={noDataGraph} alt="No data available" /></div>
          <div className='no-data-container'>{splitColumnName(graphId)} data is currently unavailable
          </div>
        </div>
      ) : (
        <LineCharts options={monthLineOption} />
      )}
    </div>
  );
};

export default GraphComponent;