import { message } from 'antd';
import React, { useEffect } from 'react';
import { getPredictPackage } from '../../apis/serve/predictPackage.js';
import Box from '../../components/box.jsx';
import Container from '../../components/container.jsx';
import Loading from '../../components/loading.jsx';
import style from './index.module.scss';

function PackageAiView() {
  async function init() {
    const query = new URLSearchParams(window.location.search);
    const id = query.get('id');
    if (!id) {
      message.warning('Must input id');
      window.open('/package-ai-screening', '_self');
      return;
    }
    try {
      const { data } = await getPredictPackage(id);
      if (!data) {
        message.warning('No data found');
        window.open('/package-ai-screening', '_self');
        return;
      }
      const ctx = { res: { predictPackage: data } };
      sessionStorage.setItem('packageAiContext', JSON.stringify(ctx));
      window.open('/package-ai-screening-wait', '_self');
    } catch (err) {
      console.error('init error', err);
      message.error('Load data error');
    }
  }
  useEffect(() => {
    init();
  }, []);

  return (
    <Container noDatabase={true}>
      <div className={style.containerInner}>
        <Box title="Share" className={style.box}>
          <Loading />
        </Box>
      </div>
    </Container>
  );
}

export default PackageAiView;
