import React, { useEffect, useRef, useState } from 'react';
import { chevronDownIcon } from '../../icons/icons.jsx';
import FilterModal from '../filterModal.jsx';
import api from '@/util/api.jsx';
import { apiURL } from '../../../env-url.js';
import { useFilters } from '../../../modules/trends/hooks/useFilters.jsx';

function MarketFilter({
  consumerTrendsCategory,
  selectedCountry,
  markets,
  updateFilters
}) {
  const [isMarketFilter, setIsMarketFilter] = useState(false);
  const ref = useRef(null);
  const [filteredMarket, setFilteredMarket] = useState([]);
  const { filters } = useFilters();

  const fetchMarketData = () => {
    return api
      .get(
        `${apiURL}/trends/mintel/market`, {
          params: {
            country: selectedCountry,
            consumerTrend: consumerTrendsCategory
          }
        }
      )
      .then((response) => {
        setFilteredMarket((prevData) => [...prevData, ...response.data]);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  };

  const handleButtonClick = () => {
    setIsMarketFilter(!isMarketFilter);
  };

  const checkIfClickedOutside = (e) => {
    if (ref.current && !ref.current.contains(e.target)) {
      setIsMarketFilter(false);
    }
  };

  const onApply = (markets) => {
    updateMarketsSelection(markets);
    setIsMarketFilter(false);
  };

  const updateMarketsSelection = (values) => {
    console.log(values);
    updateFilters(({...filters, markets: values}));
  };

  useEffect(() => {
    fetchMarketData();
    document.addEventListener('mousedown', checkIfClickedOutside);
    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  }, []);

  return (
    <div ref={ref}>
      <div className="consumer-filter-container">
        <button
          className={'market-filter' + (isMarketFilter ? ' active' : '')}
          onClick={handleButtonClick}
        >
          <span>Market</span>
          {isMarketFilter ? (
            <img
              className="up-arrow-filter"
              src={chevronDownIcon}
              alt="arrow"
            />
          ) : (
            <img className="arrow-sort" src={chevronDownIcon} alt="arrow" />
          )}
        </button>
        {isMarketFilter && (
          <FilterModal
            isOpen={isMarketFilter}
            onApply={onApply}
            data={filteredMarket}
            selectedOptions={markets}
          />
        )}
      </div>
    </div>
  );
}
export default MarketFilter;
