// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hmp8iyBN8jkV9PfwCV_e{height:32px;line-height:32px;overflow:hidden}.d0DbjGmrKVuApFJC_fMu{display:flex;align-items:center}.r3_fgDXeTsaDeqhhBMGO{flex:1;white-space:nowrap;color:#00a32e}.KTFZ00u2ISY5vWepmvtZ{margin-left:5px;font-size:18px;color:#00a32e}.IhnPldpPwe3pgGkkJsQ8{margin-right:5px}`, "",{"version":3,"sources":["webpack://./src/modules/package-ai-screening-batch/components/editableText.module.scss"],"names":[],"mappings":"AAAA,sBACE,WAAA,CACA,gBAAA,CACA,eAAA,CAGF,sBACE,YAAA,CACA,kBAAA,CAGF,sBACE,MAAA,CACA,kBAAA,CACA,aAAA,CAGF,sBACE,eAAA,CACA,cAAA,CACA,aAAA,CAGF,sBACE,gBAAA","sourcesContent":[".container {\n  height: 32px;\n  line-height: 32px;\n  overflow: hidden;\n}\n\n.header {\n  display: flex;\n  align-items: center;\n}\n\n.headerBody {\n  flex: 1;\n  white-space: nowrap;\n  color: #00a32e;\n}\n\n.penImg {\n  margin-left: 5px;\n  font-size: 18px;\n  color: #00a32e;\n}\n\n.rightImg {\n  margin-right: 5px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `hmp8iyBN8jkV9PfwCV_e`,
	"header": `d0DbjGmrKVuApFJC_fMu`,
	"headerBody": `r3_fgDXeTsaDeqhhBMGO`,
	"penImg": `KTFZ00u2ISY5vWepmvtZ`,
	"rightImg": `IhnPldpPwe3pgGkkJsQ8`
};
export default ___CSS_LOADER_EXPORT___;
