// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.uSK3uZHRH7LxaceGvYps{position:relative;height:100vh;width:100vw;overflow:hidden}.TvLDySG2Ia3t_euZBKAj{position:absolute;top:0;left:0;z-index:-1;width:100%;height:100%;object-fit:cover}.NgccrGmGpadCftAVFFFW{width:100%;height:100%;padding:25px;box-sizing:border-box;display:flex;flex-direction:column;overflow:hidden}.CVS1LV2fg_pyl9jPQGdZ{margin-top:25px;flex:1;overflow:hidden}`, "",{"version":3,"sources":["webpack://./src/modules/package-ai-performance/components/container/index.module.scss"],"names":[],"mappings":"AAAA,sBACE,iBAAA,CACA,YAAA,CACA,WAAA,CACA,eAAA,CAGF,sBACE,iBAAA,CACA,KAAA,CACA,MAAA,CACA,UAAA,CACA,UAAA,CACA,WAAA,CACA,gBAAA,CAGF,sBACE,UAAA,CACA,WAAA,CACA,YAAA,CACA,qBAAA,CACA,YAAA,CACA,qBAAA,CACA,eAAA,CAGF,sBACE,eAAA,CACA,MAAA,CACA,eAAA","sourcesContent":[".container {\n  position: relative;\n  height: 100vh;\n  width: 100vw;\n  overflow: hidden;\n}\n\n.bg {\n  position: absolute;\n  top: 0;\n  left: 0;\n  z-index: -1;\n  width: 100%;\n  height: 100%;\n  object-fit: cover;\n}\n\n.content {\n  width: 100%;\n  height: 100%;\n  padding: 25px;\n  box-sizing: border-box;\n  display: flex;\n  flex-direction: column;\n  overflow: hidden;\n}\n\n.body {\n  margin-top: 25px;\n  flex: 1;\n  overflow: hidden;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `uSK3uZHRH7LxaceGvYps`,
	"bg": `TvLDySG2Ia3t_euZBKAj`,
	"content": `NgccrGmGpadCftAVFFFW`,
	"body": `CVS1LV2fg_pyl9jPQGdZ`
};
export default ___CSS_LOADER_EXPORT___;
