import React, { useEffect, useState } from 'react';
import * as echarts from 'echarts';
import { hoverBG } from '../../../../components/icons/icons.jsx'
let rpx = window.innerWidth / 2048;
const updateSize = () => {
  rpx = window.innerWidth / 2048;
};
window.addEventListener('resize', updateSize);
console.log('rpx', rpx);

const optionA = (data) => {
  return {
    grid: {
      left: '8%',
      top: '2%',
      bottom: '10%',
      right: '6%',
      borderColor: 'rgba(0,70,22,0.8)',
    },
    tooltip: {
      backgroundColor: 'rgba(0,0,0,1)',
      formatter: function(param) {
        const value = param.value;
        console.log(value);
        // return '<div style="font-size: 18px;color: #00A32E;background-image: url(' + hoverBG + ');background-repeat: no-repeat;background-attachment:fixed;background-size: 100% 100%;">'
        return '<div style="font-size: 18px;color: #00A32E;">'
          + value[2] + '<br/>'
          + value[4] + '<br/>'
          + value[3] + '<br/>'
          // + value[3] + '<br/>'
          // + value[0] + '<br/>'
          // + value[1] + '<br/>'
          // + value[2] + '<br/>'

          + '</div>';
      },
    },
    xAxis: {
      axisLine: {
        lineStyle: {
          type: 'dashed',
          color: 'rgba(0,147,45,0.9)',
        },
      },
      splitLine: {
        lineStyle: {
          type: 'dashed',
          color: 'rgba(0,70,22,0.8)',
        },
      },
    },
    yAxis: {
      axisLine: {
        lineStyle: {
          type: 'dashed',
          color: 'rgba(0,147,45,0.9)',
        },
      },
      splitLine: {
        lineStyle: {
          type: 'dashed',
          color: 'rgba(0,70,22,0.8)',
        },
      },
      scale: true,
    },
    series: {
      data: data,
      type: 'scatter',
      symbolSize: function(data) {
        return data[2] * 2 * rpx;
      },
      // emphasis: {
      //   focus: 'series',
      //   label: {
      //     show: true,
      //     formatter: function(param) {
      //       return param.data[3];
      //     },
      //     position: 'top',
      //   },
      // },
      itemStyle: {
        shadowBlur: 5,
        shadowColor: 'rgb(0,178,47)',
        // shadowOffsetY: 5,
        color: 'rgb(0,162,46)',
      },
      selectedMode: 'single',
      select: {
        itemStyle: {
          color: 'rgb(253,186,56)',
          shadowColor: 'rgb(253,186,56)',
        },
      },
    },
  };
};


const optionB = (data) => {

  return {
    grid: {
      left: '150',
      top: '25',
      bottom: '95',
      right: '55',
      borderColor: 'rgba(0,70,22,0.8)',
    },
    xAxis: {
      max: 'dataMax',
      show: false,
    },
    yAxis: {
      type: 'category',
      axisLabel: {
        show: true,
        formatter: function (v) {
          return data[v].name ;
        },
        color: '#00b730',
        margin: 130,
        textStyle: {
          align: 'left',
        },
        
      },
      axisLine: {
        show: false,
      },
      axisTick: {
        show: false,
      },
    },
    dataZoom: [
      {
        type: 'slider', // 在底部
        show: true, // 是否显示 组件。如果设置为 false，不会显示，但是数据过滤的功能还存在。
        startValue: data.length,
        endValue: data.length - 9,
        right: 10,
        yAxisIndex: [0],
        fillerColor: 'rgba(1,76,17,0.4)',
        brushSelect: false,
      },
    ],
    series: [
      {
        data,
        type: 'bar',
        itemStyle: {
          color: '#489f40',
        },
        selectedMode: 'single',
        select: {
          itemStyle: {
            color: '#e7ca6c',
          },
        },
        // barWidth: 30,
        barCategoryGap: '40%',
      },
    ],
  };
};

const optionC = (data, color) => {
  return {
    color: color,
    title: {
      text: data[0].value + '%',
      left: 'center',
      top: '42%',
      textStyle: {
        color: color,
        fontSize: 16,
        align: 'center',
      },
    },
    series: [
      {
        data,
        type: 'pie',
        radius: ['75%', '90%'],
        avoidLabelOverlap: false,
        label: {
          show: false,
        },
      },
    ],
  };

};


const optionD = (data, color) => {
  const color1 = 'rgba(' + color[0] + ',' + color[1] + ',' + color[2] + ')';
  const color2 = 'rgba(' + color[0] + ',' + color[1] + ',' + color[2] + ',0.35)';
  return {
    color: color1,
    // barWidth: 40,
    xAxis: {
      // show:false,
      data: data,
      axisLine: {
        symbol: ['none', 'arrow'],
        symbolSize: [6, 6],
        lineStyle: {
          color: color2,
        },
      },
      axisLabel: {
        show: false,
      },
      axisTick: {
        show: false,
      },
    },
    yAxis: {
      type: 'value',
      axisLine: {
        show: true,
        symbol: ['none', 'arrow'],
        symbolSize: [6, 6],
        lineStyle: {
          color: color2,
        },
      },
      splitLine: {
        show: true,
        lineStyle: {
          type: 'solid',
          color: ['#011c03', color2, color2, color2],
        },
      },
    },
    grid: {
      left: '5',
      top: '0',
      bottom: '15',
      right: '5',
    },
    series: [{
      data: data,
      type: 'bar',
      itemStyle: {
        normal: {
          //这里设置柱形图圆角 [左上角，右上角，右下角，左下角]
          barBorderRadius: 8,
        },
      },
      barCategoryGap: '40%',
    }],
  };
};


export default {
  optionA,
  optionB,
  optionC,
  optionD,
};