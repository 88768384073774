/* eslint-disable indent */
import classNames from 'classnames';
import React, { useMemo } from 'react';
import AUPng from '../../assets/images/flag-au.png';
import UKPng from '../../assets/images/flag-uk.png';
import USPng from '../../assets/images/flag-us.png';
import style from './index.module.scss';

function PackageAiFlag({ className, country = '' }) {
  const src = useMemo(() => {
    switch (country.toLowerCase()) {
      case 'us':
        return USPng;
      case 'uk':
        return UKPng;
      case 'au':
        return AUPng;
      default:
        return null;
    }
  }, [country]);

  if (!src) return null;
  return (
    <img
      className={classNames(style.flag, className)}
      src={src}
      alt={country}
    />
  );
}

export default PackageAiFlag;
