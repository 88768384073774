import { FilterOutlined } from '@ant-design/icons';
import { Button, Checkbox, Form, Cascader } from 'antd';
import React, { useState } from 'react';
import CategoryJson from '../assets/json/category.json';
import MarketJson from '../assets/json/market.json';
import KnorrDrop from './drop.jsx';
import style from './filter.module.scss';

const { Item } = Form;

const Categories = CategoryJson.map((e) => ({ label: e, value: e }));
const Markets = MarketJson.map((e) => {
  return {
    label: e.region,
    value: e.region,
    children: e.markets.map((e1) => {
      return {
        label: e1,
        value: e1,
      };
    }),
  };
});

function KnorrFilterForm({ setFilter }) {
  function onSubmit(value) {
    const filter = {};
    if (value.category) filter.category = value.category;
    if (value.market) {
      filter.market = value.market.map((e) => {
        if (e.length === 1) {
          const m = Markets.find((e1) => e1.value === e[0]);
          if (!m) return [];
          return m.children.map((e1) => `${e}-${e1.value}`);
        }
        return e.join('-');
      });
    }
    console.log('filter', filter);
    setFilter && setFilter(filter);
  }

  function onClear() {
    console.log('filter clear');
    setFilter && setFilter({});
  }

  return (
    <Form
      layout="vertical"
      onFinish={onSubmit}
      onReset={onClear}
      className={style.filterForm}
    >
      <Item label="Topic" name="category">
        <Checkbox.Group className={style.filterItem} options={Categories} />
      </Item>
      <Item label="Market" name="market">
        <Cascader.Panel
          className={style.filterItem}
          multiple={true}
          options={Markets}
        />
      </Item>
      <div className={style.filterBtnLine}>
        <Button shape="round" htmlType="submit">
          Apply
        </Button>
        {'   '}
        <Button shape="round" htmlType="reset">
          Clear
        </Button>
      </div>
    </Form>
  );
}

function KnorrFilter({ className, setFilter }) {
  const [dropVisible, setDropVisible] = useState(false);

  return (
    <KnorrDrop
      className={`${style.listHearderFilterItem} ${className}`}
      icon={<FilterOutlined></FilterOutlined>}
      item={KnorrFilterForm({ setFilter })}
      dropVisible={dropVisible}
      setDropVisible={setDropVisible}
    >
      Filter
    </KnorrDrop>
  );
}

export default KnorrFilter;
