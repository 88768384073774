import classNames from 'classnames';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import Box from '../../components/box/index.jsx';
import Compare from '../../components/compare/compare.jsx';
import { ModalContext } from '../../components/context/context.jsx';
import ModalGreen from '../../components/modal/modal.jsx';
import Perceived from '../../components/perceived/perceived.jsx';
import TopBar from '../../components/top-bar/top.jsx';
import Topline from '../../components/topline/topline.jsx';
import style from './main.module.scss';
import Filter from '../../components/filter/index.jsx';

const PackageAiView = () => {
  // tab list
  const tabList = [
    { title: 'Topline Metrics' },
    { title: 'Perceived Benefits Deepdive' },
    { title: 'Compare' },
  ];
  const [modalType, setModalType] = useState(0);

  // tab active
  const [activeTab, setActiveTab] = useState(0);
  const toggleTab = (index) => {
    setActiveTab(index);
    setModalType(index);
  };

  // Modal
  const { isOpen, modalData, closeModal, avgs, getVerticalRank } =
    useContext(ModalContext);
  const [newModalData, setNewModalData] = useState({});

  const buildFlag = useCallback(
    (key) => {
      const rank = getVerticalRank(
        modalData.vertical,
        key,
        modalData.market,
        modalData.sn
      );
      // console.log('key', key, rank);
      if (rank <= 0.3) return 'ok';
      else if (rank <= 0.7) return 'bad';
      else return 'worse';
    },
    [modalData, getVerticalRank]
  );

  // build modal data
  const buildModalData = (type) => {
    let data = [];
    if (type === 0) {
      data = [
        {
          title: 'Brand Logo Visibility',
          titleTip:
            'This metric measures the probability of the brand logo being noticed in the first 3-5 seconds. It is based on 3M VAS algorithm.',
          score: modalData.brand_logo_visibility,
          avg: 0,
          flag: buildFlag('brand_logo_visibility'),
        },
        {
          title: 'Variant Name Visibility',
          titleTip:
            'This metric evaluates the likelihood that the variant name will be noticed within the first 3-5 seconds of viewing. It utilizes the 3M VAS algorithm to provide this assessment.',
          score: modalData.variant_name_visibility,
          avg: 0,
          flag: buildFlag('variant_name_visibility'),
        },
        {
          title: 'Appeal',
          titleTip:
            'This metric measures the percentage of respondents who find the packaging moderately and strongly appealing. It is derived from consumer survey data.',
          score: modalData.appeal,
          avg: 0,
          flag: buildFlag('appeal'),
        },
        {
          title: 'Unpriced Purchase Intent',
          titleTip:
            'This metric measures the percentage of respondents who find the somewhat or very willing to purchase the product based on design alone. It is derived from consumer survey data.',
          score: modalData.upi,
          avg: 0,
          flag: buildFlag('upi'),
        },
      ];
    } else if (type === 1) {
      data = [
        {
          title: 'Taste',
          titleTip:
            'This metric measures the percentage of respondents who somewhat or strongly believe the product would have a delicious taste based on its design alone. It is derived from consumer survey data.',
          score: modalData.taste,
          avg: 0,
          flag: buildFlag('taste'),
        },
        {
          title: 'High Quality',
          titleTip:
            'This metric measures the percentage of respondents who somewhat or strongly think that the product is of high quality based on its design alone. It is derived from consumer survey data.',
          score: modalData.premium,
          avg: 0,
          flag: buildFlag('premium'),
        },
        {
          title: 'Healthy',
          titleTip:
            'This metric measures the percentage of respondents who somewhat or strongly think that the product is a healthy choice based on its design alone. It is derived from consumer survey data.',
          score: modalData.natural,
          avg: 0,
          flag: buildFlag('natural'),
        },
        {
          title: 'Premium',
          titleTip:
            'This metric measures the percentage of respondents who somewhat or strongly think that the product would be worth paying more for based on its design alone. It is derived from consumer survey data.',
          score: modalData.value,
          avg: 0,
          flag: buildFlag('value'),
        },
        {
          title: 'Sustainable',
          titleTip:
            'This metric measures the percentage of respondents who somewhat or strongly think that the product is a more environmentally friendly choice based on its design alone. It is derived from consumer survey data.',
          score: modalData.sustainable,
          avg: 0,
          flag: buildFlag('sustainable'),
        },
        {
          title: 'Modernity',
          titleTip:
            'This metric measures the percentage of respondents who somewhat or strongly think that the product looks modern based on its design alone. It is derived from consumer survey data.',
          score: modalData.modernity,
          avg: 0,
          flag: buildFlag('modernity'),
        },
        {
          title: 'Excitement',
          titleTip:
            'This metric measures the percentage of respondents who somewhat or strongly think that the product looks exciting based on its design alone. It is derived from consumer survey data.',
          score: modalData.excitement,
          avg: 0,
          flag: buildFlag('excitement'),
        },
        {
          title: 'Desirability',
          titleTip:
            'This metric measures the percentage of respondents who somewhat or strongly think that the product looks like something that they would want to have. It is derived from consumer survey data.',
          score: modalData.desirability,
          avg: 0,
          flag: buildFlag('desirability'),
        },
      ];
    }

    const obj = {
      title: modalData.name,
      id: modalData.sn,
      market: modalData.market,
      cover: modalData.cover,
      table: {
        title: 'Metric',
        barContent: 'Score',
        body: data,
      },
    };
    return obj;
  };

  useEffect(() => {
    if (modalData && Object.keys(modalData).length > 0) {
      const obj = buildModalData(modalType);
      setNewModalData(obj);
    }
  }, [modalData]);

  // filters
  const [filterData, setFilterData] = useState({});
  const [subFilterData, setSubFilterData] = useState({});

  return (
    <div className={style.fixedContent}>
      <TopBar />
      <Box className={style.contentWrap}>
        <div className={style.contentInner}>
          <div className={style.tabBar}>
            {tabList.map((tab, index) => {
              return (
                <div
                  className={classNames(
                    index === 0 ? style.firstTab : '',
                    index === 1 ? style.secondTab : '',
                    index === 2 ? style.thirdTab : '',
                    index === activeTab ? style.active : ''
                  )}
                  onClick={() => {
                    toggleTab(index);
                  }}
                  key={index}
                >
                  <div className={style.outerCircle}>
                    <div className={style.innerCircle}></div>
                  </div>
                  {index === 1 && <div className={style.lineRight}></div>}
                  {index === 1 && <div className={style.lineLeft}></div>}
                  <span className={style.navTitle}>{tab.title}</span>
                </div>
              );
            })}
          </div>
          <div className={style.tabContent}>
            {(activeTab === 0 || activeTab === 1) && (
              <div className={style.optWrap}>
                <Filter
                  showSearch={false}
                  data={filterData}
                  setData={setFilterData}
                  showSubFilters={true}
                  subData={subFilterData}
                  setSubData={setSubFilterData}
                />
                {activeTab === 0 && (
                  <Topline
                    filterData={filterData}
                    subFilterData={subFilterData}
                  />
                )}
                {activeTab === 1 && (
                  <Perceived
                    filterData={filterData}
                    subFilterData={subFilterData}
                  />
                )}
              </div>
            )}

            {activeTab === 2 && <Compare />}
          </div>
        </div>
      </Box>
      {/* Modal */}
      <ModalGreen
        open={isOpen}
        data={newModalData}
        avgs={avgs}
        modalType={modalType}
        closeModal={closeModal}
      />
    </div>
  );
};

export default PackageAiView;
