import React, { useImperativeHandle, forwardRef, useEffect, useCallback, useState } from 'react';
import style from './marketing-mix.module.scss';
import BarChart from './bar-chart.jsx';
import { utils, writeFile } from 'xlsx';
import { fetchMarketingMixIndicator } from '../../api/request.js';
import { Spin, Image } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import imageToBeUpload from '../../../../assets/images/ratings-review/image-to-be-upload.jpg';

const colors = [
  '#00A32E',
  '#FF544F',
  '#FFBF00'
];

const antIcon = (
  <LoadingOutlined
    style={{
      fontSize: 30,
      color: '#00FF00'
    }}
    spin
  />
);

function debounce (callback, delay) {
  let lastTime;
  return function () {
    clearTimeout(lastTime);
    const [that, args] = [this, arguments];
    lastTime = setTimeout(() => {
      callback.apply(that, args);
    }, delay);
  };
}

let requestIndex = Date.now();
let requestStop = false;

let MarketingMix = ({visible, searchParams, noFilterResult}, ref) => {

  const [marketLoading, setMarketLoading] = useState(false);
  const [products, setProducts] = useState([]);
  const fetchMarketMix = (searchParams) => {
    if (requestStop) return;
    setMarketLoading(true);
    fetchMarketingMixIndicator(searchParams, requestIndex)
      .then(res => {
        if (requestStop || requestIndex !== res.config.uuid) return;
        const arr = Array.isArray(res.data.data) ? res.data.data : [];
        setProducts(arr);
        setMarketLoading(false);
      })
      .catch(error => {
        if (requestIndex !== error?.config?.uuid) return;
        setProducts([]);
        setMarketLoading(false);
      });
  };
  const delayFetchMarketMix = useCallback(debounce(function(val) { return fetchMarketMix(val);}, 500), []);

  useEffect(() => {
    if (!searchParams) {
      requestStop = true;
      setProducts([]);
      setMarketLoading(false);
      return;
    }
    requestStop = false;
    requestIndex++;
    delayFetchMarketMix(searchParams);
  }, [searchParams]);

  const columns = ['Product', 'Price', 'Place', 'Proposition', 'Promotion', 'Packaging'];

  const computeData = (fileType) => {
    // 定义表头
    const headers = [['']];
    // 定义数据
    const data = [...columns.map(item => [item])];
    products.forEach(item => {
      headers[0].push(item.name);
      data[0].push(item.product);
      data[1].push(item.price);
      data[2].push(item.place);
      data[3].push(item.proposition);
      data[4].push(item.promotion);
      data[5].push(item.packaging);
    });
    
    const sheetData = [...headers, ...data];

    // 创建工作表
    const worksheet = utils.aoa_to_sheet(sheetData);

    // 列宽度
    const cols = [
      { wch: 20 },
      { wch: 40 },
      { wch: 40 },
      { wch: 40 }
    ];
    worksheet['!cols'] = cols;

    const wb = utils.book_new();
    utils.book_append_sheet(wb, worksheet, 'Sheet1');
    writeFile(wb, `Marketing Mix Indicator Data.${fileType}`);
  };

  useImperativeHandle(ref, () => ({
    exportExcel: () => {
      computeData('xlsx');
    },
    exportCsv: () => {
      computeData('csv');
    }
  }));

  if (!visible) return null;

  return (
    <Spin indicator={antIcon} spinning={marketLoading}>
      {!searchParams || !products || products.length === 0 ? 
        noFilterResult :
        <div className={style.marketingMix}>
          {
            products.map((item, index) => {
              return (
                <div key={item.name} className={style.productItem}>
                  <div className={style.productBox}>
                    <div className={style.productImgBox}>
                      <Image
                        preview={false}
                        width={28}
                        height={28}
                        src={item.img_url}
                        fallback={imageToBeUpload}
                        placeholder={
                          <div className={style.productImgPlaceholder}>
                            <LoadingOutlined />
                          </div>
                        }
                      />
                    </div>
                    <div className={style.productName} title={item.name}>{item.name}</div>
                  </div>
                  <BarChart item={item} color={colors[index]} />
                </div>
              );
            })
          } 
        </div>}
    </Spin>
  );
};

MarketingMix = forwardRef(MarketingMix);

export default MarketingMix;