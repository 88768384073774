import service from '../../../../util/pythonApi';

export function postStandardizer(file) {
  const data = new FormData();
  data.append('file', file);
  return service.request({
    url: '/v1/standardizer',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
}
