// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.aUB5mMMY7owJNTFKtiWB{height:100%;display:flex;flex-direction:column}.chEwbpozAlZ1Hz_uaaHR{margin-top:25px;flex:1;overflow:hidden;position:relative}.cYHTg2IlEKVgepb3iqQW{height:100%;padding:15px 25px;overflow-x:hidden;overflow-y:auto}.aCaG8aXw2K6qJ6Rn9EZv{width:100%;display:flex;justify-content:space-between;align-items:center}`, "",{"version":3,"sources":["webpack://./src/modules/package-ai-screening-batch/views/result/index.module.scss"],"names":[],"mappings":"AAAA,sBACE,WAAA,CACA,YAAA,CACA,qBAAA,CAGF,sBACE,eAAA,CACA,MAAA,CACA,eAAA,CACA,iBAAA,CAGF,sBACE,WAAA,CACA,iBAAA,CACA,iBAAA,CACA,eAAA,CAGF,sBACE,UAAA,CACA,YAAA,CACA,6BAAA,CACA,kBAAA","sourcesContent":[".containerInner {\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n}\n\n.box {\n  margin-top: 25px;\n  flex: 1;\n  overflow: hidden;\n  position: relative;\n}\n\n.boxInner {\n  height: 100%;\n  padding: 15px 25px;\n  overflow-x: hidden;\n  overflow-y: auto;\n}\n\n.boxHeaderInner {\n  width: 100%;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"containerInner": `aUB5mMMY7owJNTFKtiWB`,
	"box": `chEwbpozAlZ1Hz_uaaHR`,
	"boxInner": `cYHTg2IlEKVgepb3iqQW`,
	"boxHeaderInner": `aCaG8aXw2K6qJ6Rn9EZv`
};
export default ___CSS_LOADER_EXPORT___;
