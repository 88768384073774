import { useCallback, useEffect, useState } from 'react';
import { baseInfo } from '../api/request';

const useBaseInfo = () => {
  const [buOptions, setBuOptions] = useState([]);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [monthDate, setMonthDate] = useState("");

  const getInfo = useCallback(async () => {
    const {data = {}, status = ''} = await baseInfo();
    if(status === 'Success') {
      const tempBuOptions = [];
      const tempCategoryOptions = [];
      const { buList, categoryList, dateFlag } = data;

      buList.forEach(item => {
        tempBuOptions.push({value: item, label: item});
      });

      categoryList.forEach(item => {
        tempCategoryOptions.push({value: item, label: item});
      });

      setMonthDate(dateFlag)
      setBuOptions(tempBuOptions);
      setCategoryOptions(tempCategoryOptions);
    }
  }, []);

  useEffect(() => {
    getInfo();
  }, [getInfo]);

  return {buOptions, categoryOptions, monthDate};
};

export default useBaseInfo;