import React, { useEffect, useState } from 'react';
import {
  closeIcon,
  searchIcon,
} from '../../../../../components/icons/icons.jsx';

const SustainabilitySearch = ({
  handleFilterData,
  searchQuery,
  setSearchQuery,
  principleData,
  plantData,
  foodData,
  agricultureData,
  activeTab,
  handleLearningData
}) => {
  const [showInput, setShowInput] = useState(false);

  const tabDataSources = {
    tab1: principleData,
    tab2: plantData,
    tab3: foodData,
    tab4: agricultureData,
  };
  
  const handleSearch = () => {
    const query = searchQuery.toLowerCase();
    const activeTabData = tabDataSources[activeTab] || [];
    if (query.trim() !== '') {
      const filteredResults = activeTabData.filter(
        (item) =>
          item.content.toLowerCase().includes(query) ||
          item.title.toLowerCase().includes(query)
      );
      handleFilterData(filteredResults);
    } else {
      handleFilterData(activeTabData);
    }
    setShowInput(true);
  };  
  
  const searchOnChange = (e) => {
    const newQuery = e.target.value.toLowerCase();
    const activeTabData = tabDataSources[activeTab] || [];
    const filteredResults = activeTabData.filter(
      (item) =>
        item.content.toLowerCase().includes(newQuery) ||
        item.title.toLowerCase().includes(newQuery) 
    );

    if (newQuery.trim() !== '') {
      handleFilterData(filteredResults);
      setSearchQuery(newQuery);
    } else {
      handleFilterData(activeTabData);
      setSearchQuery('');
    }
  };

  return (
    <>
      <div className="search-main-container">
        <div className="search-section" >
          {showInput && (
            <input
              className="search-input"
              type="text"
              value={searchQuery}
              onChange={searchOnChange}
              placeholder="Search..."
            />
          )}
          {searchQuery === '' ? (
            <img src={searchIcon} alt="search" onClick={handleSearch} />
          ) : (
            <img
              src={closeIcon}
              alt="search"
              onClick={() => {setSearchQuery('');
                handleFilterData([]);
                setShowInput(false);
              }}    
            />
          )}
        </div>
      </div>
    </>
  );
};

export default SustainabilitySearch;