import React from 'react';
import { Outlet } from 'react-router-dom';
import NavBarCom from './components/NavBarCom.jsx';

const BasicLayout = () => {
  return <div className="w-full h-full ctp-root">
    <NavBarCom></NavBarCom>
    <div className="w-full h-full pl-[75px] pt-[60px]">
      <Outlet></Outlet>
    </div>
  </div>;
};

export default BasicLayout;