import React, { useEffect, useRef, useState } from 'react';
import { chevronDownIcon } from '../icons/icons.jsx';
import api from '../../util/api.jsx';
import { apiURL } from '../../env-url.js';

const Sort = ({
  isSorted,
  setIsSorted,
  isSortedLatest,
  setIsSortedLatest,
  isLiked,
  setIsLiked,
  setSortData,
}) => {
  const ref = useRef(null);
  const [isSortOpen, setIsSortOpen] = useState(false);
  const [data, setData] = useState([]);

  const fetchData = () => {
    api
      .get(`${apiURL}/gotfl/learnings`)
      .then((response) => {
        setData(response.data);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  };

  const checkIfClickedOutside = (e) => {
    if (ref.current && !ref.current.contains(e.target)) {
      setIsSortOpen(false);
    }
  };

  useEffect(() => {
    fetchData();
    document.addEventListener('mousedown', checkIfClickedOutside);
    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  }, []);

  const sortItemsOldestToLatest = () => {
    if (isSortedLatest) {
      setIsSortedLatest(false);
    }
    const sortedData = data
      .slice()
      .sort((a, b) => new Date(b.createdOn) - new Date(a.createdOn));
    setSortData(sortedData);
    setIsSortedLatest((prevState) => !prevState);
  };

  const sortItemsLatestToOldest = () => {
    if (isSorted) {
      setIsSorted(false);
    }
    const sortedData = data
      .slice()
      .sort((a, b) => new Date(a.createdOn) - new Date(b.createdOn));
    setSortData(sortedData);
    setIsSorted((prevState) => !prevState);
  };

  const sortMostLiked = () => {
    if (isLiked) {
      setIsLiked(false);
    }
    const sortedData = data.filter(
      ({ learningFavorite }) => learningFavorite.length > 0
    );
    setSortData(sortedData);

    setIsLiked((prevState) => !prevState);
  };

  const handleButtonClick = () => {
    setIsSortOpen(!isSortOpen);
    setIsLiked(false);
    setIsSorted(false);
    setIsSortedLatest(false);
    if (isSortOpen) {
      setSortData(data);
    }
  };

  let boxes = document.querySelectorAll('input[type=checkbox]');
  boxes.forEach((b) => b.addEventListener('change', tick));
  function tick(e) {
    let state = e.target.checked; // save state of changed checkbox
    boxes.forEach((b) => (b.checked = false)); // clear all checkboxes
    e.target.checked = state; // restore state of changed checkbox
  }

  return (
    <>
      <div ref={ref}>
        <div className="sort-container">
          <button
            className={'btn-sort ' + (isSortOpen ? 'active' : '')}
            onClick={handleButtonClick}
          >
            <span>Sort</span>
            {isSortOpen ? (
              <img
                className="up-arrow-sort"
                src={chevronDownIcon}
                alt="arrow"
              />
            ) : (
              <img className="arrow-sort" src={chevronDownIcon} alt="arrow" />
            )}
          </button>
        </div>
        {isSortOpen && (
          <ul className="sort-radio-items" id="sort-item">
            <li>
              <label>
                {' '}
                <input
                  type="checkbox"
                  name="sortfilter"
                  value="oldest"
                  onChange={sortItemsLatestToOldest}
                  checked={isSorted}
                />
                Oldest
              </label>
            </li>
            <li>
              <label>
                <input
                  type="checkbox"
                  name="sortfilter"
                  value="latest"
                  onChange={sortItemsOldestToLatest}
                  checked={isSortedLatest}
                />
                Latest
              </label>
            </li>
            <li>
              <label>
                <input
                  type="checkbox"
                  name="sortfilter"
                  value="liked"
                  onChange={sortMostLiked}
                  checked={isLiked}
                />
                Most Liked
              </label>
            </li>
          </ul>
        )}
      </div>
    </>
  );
};

export default Sort;
