import React, { memo } from 'react';
import style from './marketing-mix.module.scss';
import classNames from 'classnames';

import ReactECharts from 'echarts-for-react';
import * as echarts from 'echarts/core';
import { CanvasRenderer } from 'echarts/renderers';

echarts.use(
  [CanvasRenderer]
);

const BarChart = memo(function BarChart({ color, item }) {
  const { product, price, place, proposition, promotion, packaging } = item;

  const options = {
    tooltip: {
      trigger: 'axis',
      backgroundColor: 'rgba(0,0,0,0.7)',
      borderColor: color,
      textStyle: {
        color: '#FFFFFF'
      }
    },
    grid: {
      left: '0',
      right: '20',
      top: '13',
      bottom: '0',
      containLabel: true
    },
    xAxis: {
      type: 'value',
      show: false,
      axisTick: {
        show: false
      },
    },
    yAxis: {
      type: 'category',
      axisLabel: {
        color,
        fontSize: 10,
        margin: 12,
      },
      axisTick: {
        show: false
      },
      axisLine: {
        show: false
      },
      data: ['Product', 'Price', 'Place', 'Proposition', 'Promotion', 'Packaging'].reverse()
    },
    series: [
      {
        name: '',
        type: 'bar',
        itemStyle: {
          color
        },
        barWidth: 18,
        markLine: {

        },
        data: [product, price, place, proposition, promotion, packaging].reverse()
      }
    ]
  };

  return (
    <div className={style.chartBox}>
      {[1,2,3,4,5].map(item => {
        return <div key={item} className={classNames(style.line, style[`line${item}`])} style={{backgroundColor: color}}></div>;
      })}
      <ReactECharts
        className={style.barChart}
        echarts={echarts}
        option={options}
        style={{
          height: '262px'
        }}
      />
    </div>
  );
});

export default BarChart;