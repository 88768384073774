import classNames from 'classnames';
import React, { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import style from './container.module.scss';

function StepBorder({ className, index, step }) {
  const active = useMemo(() => {
    return index < step;
  }, [index, step]);
  const current = useMemo(() => {
    return index === step;
  }, [index, step]);

  return (
    <div
      className={classNames(
        style.stepBorder,
        {
          [style.stepBorderActive]: active,
          [style.stepBorderCurrent]: current,
        },
        className
      )}
    >
      <div className={style.stepBorderLine} />
    </div>
  );
}

function Step({ className, index, step, children, url, onClick }) {
  const active = useMemo(() => {
    return index <= step;
  }, [index, step]);
  const current = useMemo(() => {
    return index === step;
  }, [index, step]);
  const clickable = useMemo(() => {
    return index < step;
  }, [index, step]);
  const oc = useCallback(() => {
    if (!clickable) return;
    onClick && onClick(url);
  }, [onClick, clickable]);

  return (
    <div
      className={classNames(
        style.step,
        {
          [style.stepActive]: active,
          [style.stepClickable]: clickable,
        },
        className
      )}
      onClick={oc}
    >
      {current && (
        <div className={style.stepDot}>
          <div className={style.stepDotInner} />
        </div>
      )}
      <div className={style.stepText}>{children}</div>
    </div>
  );
}

function PackageAiContainer({ children, step }) {
  const navigate = useNavigate();

  const onStepClick = useCallback(
    (url) => {
      navigate(url);
    },
    [navigate]
  );

  return (
    <div className={style.container}>
      <div className={style.header}>
        <Step
          className={style.headerItem}
          index={1}
          step={step}
          url="/package-ai-generation"
          onClick={onStepClick}
        >
          Choose your Base Pack
        </Step>
        <StepBorder index={1} step={step} />
        <Step
          className={style.headerItem}
          index={2}
          step={step}
          url="/package-ai-generation2"
          onClick={onStepClick}
        >
          Edit with Easy AI
        </Step>
        <StepBorder index={2} step={step} />
        <Step
          className={style.headerItem}
          index={3}
          step={step}
          url="/package-ai-generation3"
          onClick={onStepClick}
        >
          Update Text and Logo
        </Step>
        <StepBorder index={3} step={step} />
        <Step
          className={style.headerItem}
          index={4}
          step={step}
          url="/package-ai-generation4"
          onClick={onStepClick}
        >
          Rendering
        </Step>
      </div>
      <div className={style.body}>{children}</div>
    </div>
  );
}

export default PackageAiContainer;
