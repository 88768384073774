/* eslint-disable indent */
import service from '../../../../util/pythonApi';

/**
 * @param {Blob} file
 */
export async function postUploadImage(file) {
  const data = await file.arrayBuffer();
  return await service.request({
    url: '/v1/package-ai/upload-image',
    method: 'post',
    headers: {
      'Content-Type': file.type,
    },
    data,
  });
}

export function postGenerativeFill(data) {
  return service.request({
    url: '/v1/package-ai/fill-image',
    method: 'post',
    data,
  });
}
