import { SearchOutlined, UploadOutlined } from '@ant-design/icons';
import { Button, Input, message } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import {
  deletePackageAiAsset,
  getPackageAiAsset,
  putPackageAiAsset,
} from '../../apis/serve/asset.js';
import { PackageAiBtn1 as Btn } from '../../components/btn.jsx';
import More from '../../components/more.jsx';
import DeleteDialog from './delete.jsx';
import Item from './item.jsx';
import style from './library.module.scss';
import UpdateDialog from './update.jsx';
import UploadDialog from './upload.jsx';

function PackageAiLibrary({ className, addImage, onNext }) {
  const [search, setSearch] = useState();
  const [list, setList] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [loading, setLoading] = useState(false);
  const [isNoMore, setIsNoMore] = useState(false);

  const clearList = useCallback(() => {
    setList([]);
    setIsNoMore(false);
  }, [setList, setIsNoMore]);

  useEffect(() => {
    clearList();
  }, [search]);

  function onItemClick(ev, data) {
    if (!ev.target) return;
    addImage(data.url, {
      width: ev.target.naturalWidth,
      height: ev.target.naturalHeight,
    });
  }

  const readAssets = useCallback(async () => {
    if (isNoMore) return;
    try {
      setLoading(true);
      const isEmpty = !list?.length;
      const { data } = await getPackageAiAsset(
        { search },
        isEmpty ? 0 : list.length,
        6,
        'logo'
      );
      if (data) {
        const { data: dd } = data;
        setIsNoMore(dd.length < 6);
        if (isEmpty) {
          setList(dd);
        } else {
          setList((prev) => prev.concat(dd));
        }
      } else {
        console.warn('read asset fail', data);
        message.warning('Read asset fail');
      }
    } catch (err) {
      console.error('read asset error', err);
      message.error('Read asset error');
    }
    setLoading(false);
  }, [list, setList, setLoading, search, isNoMore, setIsNoMore]);

  const [uploadVisibel, setUploadVisible] = useState(false);
  const onUploadClick = useCallback(() => {
    setUploadVisible(true);
  }, [setUploadVisible]);

  const [updateData, setUpdateData] = useState();
  const [updateVisible, setUpdateVisible] = useState(false);
  const onUpdateClick = useCallback(
    (data) => {
      setUpdateData(data);
      setUpdateVisible(true);
    },
    [setUpdateData, setUpdateVisible]
  );
  const onUpdateConfirm = useCallback(
    async (data) => {
      setUpdateVisible(false);
      if (!data) return;
      await putPackageAiAsset(data);
      clearList();
    },
    [setUpdateData]
  );

  const [deleteId, setDeleteId] = useState();
  const [deleteVisible, setDeleteVisible] = useState(false);
  const onDeleteClick = useCallback(
    (id) => {
      setDeleteId(id);
      setDeleteVisible(true);
    },
    [setDeleteId, setDeleteVisible]
  );
  const onDeleteConfirm = useCallback(async () => {
    setDeleteVisible(false);
    if (!deleteId) return;
    await deletePackageAiAsset(deleteId);
    clearList();
  }, [deleteId, setDeleteVisible]);

  return (
    <div className={`${style.libraryBox} ${className}`}>
      <div className={style.libraryHeader}>
        <Input
          className={style.input}
          style={{ width: '100%' }}
          value={search}
          onChange={setSearch}
          prefix={<SearchOutlined />}
        />
        <Button
          type="primary"
          icon={<UploadOutlined />}
          onClick={onUploadClick}
        />
      </div>
      <div className={style.libraryBody}>
        {list.map((item) => (
          <Item
            key={item.id}
            data={item}
            onClick={onItemClick}
            onUpdate={onUpdateClick}
            onDelete={onDeleteClick}
          />
        ))}
        <More onLoadMore={readAssets} />
      </div>
      <div className={`${style.confirmBox} ${className}`}>
        <Btn onClick={onNext}>Next</Btn>
      </div>
      <UploadDialog
        visible={uploadVisibel}
        setVisible={setUploadVisible}
        afterUpload={clearList}
      />
      <UpdateDialog
        data={updateData}
        visible={updateVisible}
        setVisible={setUpdateVisible}
        onConfirm={onUpdateConfirm}
      />
      <DeleteDialog
        visible={deleteVisible}
        setVisible={setDeleteVisible}
        onConfirm={onDeleteConfirm}
      />
    </div>
  );
}

export default PackageAiLibrary;
