import { useCallback, useEffect, useState } from 'react';
import { animalMeatVsPlantBaseFood, animalMeatVsPlantBaseFoodKey, count } from '../api/request';
import { formatPercentDataByTotal, tooltipFormatter } from '../utils';
import { utils, writeFile } from 'xlsx';

const options = {
  tooltip: {
    trigger: 'axis',
    confine: true
  },
  legend: {
    data: [],
    icon: 'circle',
    bottom: '0',
    left: 0,
    itemWidth: 10,
    itemHeight: 10,
    textStyle: {
      color: 'rgba(255,255,255, 0.6)',
      fontSize: '12px'
    },
    selectedMode: false
  },
  grid: {
    top: '3%',
    left: '0',
    right: '0',
    bottom: '18%',
    containLabel: true
  },
  xAxis: {
    type: 'category',
    data: [],
    axisPointer: {
      type: 'shadow'
    },
    axisTick: {
      show: false
    },
    axisLabel: {
      color: '#00A32E',
      fontSize: 12,
      fontWeight: 'bold',
    },
    axisLine: {
      lineStyle: {
        color: '#00A32E'
      }
    }
  },
  yAxis: {
    type: 'value',
    axisLabel: {
      color: '#00A32E',
      formatter: '{value}%'
    },
    splitLine: {
      lineStyle: {
        color: '#00A32E',
        width: 0.5
      }
    },
    max: 100
  },
  series: [
    {
      name: 'Total Animal Meat Consumption',
      type: 'bar',
      color: '#00A32E',
      data: [
        23.2, 25.6, 76.7
      ]
    },
    {
      name: 'Total Plant-Based Food Consumption',
      type: 'bar',
      color: '#FFC000',
      data: [
        26.4, 28.7, 70.7
      ]
    }
  ]
};

const colors = ['#00A32E', '#FFC000'];

const labelOption = {
  show: true,
  rotate: -90,
  position: 'insideTop',
  distance: 20,
  align: 'center',
  verticalAlign: 'middle',
  formatter: '{c}%',
  color: '#ffffff'
};

const useAnimalMeatVsPlantBaseFood = () => {
  const [filter, setFilter] = useState([]);

  const [dataForCountry, setDataForCountry] = useState({});
  const [countryList, setCountryList] = useState([]);

  const [dataForAge, setDataForAge] = useState({});
  const [ageList, setAgeList] = useState([]);

  const [loadingForCountry, setLoadingForCountry] = useState(false);
  const [loadingForAge, setLoadingForAge] = useState(false);

  const getCount = useCallback(async (type) => {
    let result = null;
    const {data = [], status = ''} = await count({split: type});
    if(status === 'Success') {
      result = data;
    }
    return result;
  }, []);

  const getKey = useCallback(async () => {
    const {data = [], status = ''} = await animalMeatVsPlantBaseFoodKey();
    if(status === 'Success') {
      setFilter(data);
    }
  }, []);

  const getChartDataForCountry = useCallback(async () => {
    setLoadingForCountry(true);
    try {
      const countResult = await getCount('country');
      if(!countResult) return;
      const {data={}, status=''}  = await animalMeatVsPlantBaseFood({split:'country'});
      if(status === 'Success') {
        const tempFormatData = formatPercentDataByTotal(data, countResult);
        setDataForCountry(tempFormatData);
  
        const keyList = Object.keys(data);
        const tempCountryList = [];
        keyList.forEach(item => tempCountryList.push({label: item, value: item}));
        setCountryList(tempCountryList);
      }
    } catch(error) { console.log(error); } finally {
      setLoadingForCountry(false);
    }
  });

  const getChartDataForAge = useCallback(async () => {
    setLoadingForAge(true);
    try {
      const countResult = await getCount('age');
      if(!countResult) return;
      const {data={}, status=''}  = await animalMeatVsPlantBaseFood({split:'age'});
      if(status === 'Success') {
        const tempFormatData = formatPercentDataByTotal(data, countResult);
        setDataForAge(tempFormatData);
  
        const keyList = Object.keys(data);
        const tempAgeList = [];
        keyList.forEach(item => tempAgeList.push({label: item, value: item}));
        setAgeList(tempAgeList);
      }
    } catch(error) { console.log(error); } finally {
      setLoadingForAge(false);
    }
  });

  const getChartOptionsForCountry = useCallback((curFilter = [], country = []) => {
    // 筛选数据，定顺序， 定颜色
    let legendData = [];
    let xAxisData = [];
    let seriesData = [];

    filter.forEach(item => {
      if(curFilter.includes(item)) {
        legendData.push(item);
      }
    });

    const countryKeys = countryList.map(item => item.value);
    if(country.length) {
      countryKeys.forEach(item => {
        if(country.includes(item)) {
          xAxisData.push(item);
        }
      });
    } else {
      xAxisData = countryKeys;
    }

    const tempObjForFilterKey = {};
    legendData.forEach(item => {
      tempObjForFilterKey[item] = [];
      xAxisData.forEach(subItem => {
        const tempVal = dataForCountry[subItem][item];
        tempObjForFilterKey[item].push(tempVal);
      });
    });
    
    legendData.forEach(item => {
      const index =  filter.findIndex(filterItem => filterItem === item);
      const color = colors[index];
      const temp = {
        name: item,
        type: 'bar',
        color: color,
        data: tempObjForFilterKey[item],
        label: labelOption
      };
      seriesData.push(temp);
    });
    const tempOptions = JSON.parse(JSON.stringify(options));
    tempOptions.tooltip.formatter = tooltipFormatter;
    tempOptions.legend.data = legendData;
    tempOptions.xAxis.data = xAxisData;
    tempOptions.series = seriesData;
    return tempOptions;
  }, [dataForCountry, filter, countryList]);

  const getChartOptionsForAge = useCallback((curFilter = [], age = []) => {
    // 筛选数据，定顺序， 定颜色
    let legendData = [];
    let xAxisData = [];
    let seriesData = [];

    filter.forEach(item => {
      if(curFilter.includes(item)) {
        legendData.push(item);
      }
    });

    const ageKeys = ageList.map(item => item.value);
    if(age.length) {
      ageKeys.forEach(item => {
        if(age.includes(item)) {
          xAxisData.push(item);
        }
      });
    } else {
      xAxisData = ageKeys;
    }

    const tempObjForFilterKey = {};
    legendData.forEach(item => {
      tempObjForFilterKey[item] = [];
      xAxisData.forEach(subItem => {
        const tempVal = dataForAge[subItem][item];
        tempObjForFilterKey[item].push(tempVal);
      });
    });
    
    legendData.forEach(item => {
      const index =  filter.findIndex(filterItem => filterItem === item);
      const color = colors[index];
      const temp = {
        name: item,
        type: 'bar',
        color: color,
        data: tempObjForFilterKey[item],
        label: labelOption
      };
      seriesData.push(temp);
    });
    
    const tempOptions = JSON.parse(JSON.stringify(options));
    tempOptions.tooltip.formatter = tooltipFormatter;
    tempOptions.legend.data = legendData;
    tempOptions.xAxis.data = xAxisData;
    tempOptions.series = seriesData;
    return tempOptions;
  }, [dataForAge, filter, ageList]);

  useEffect(() => {
    getKey();
    getChartDataForCountry();
    getChartDataForAge();
  }, []);

  const { generateExcel } = useGenerateExcel(filter, dataForCountry, dataForAge);

  return {filter, countryList, dataForCountry, ageList, dataForAge, loadingForCountry, loadingForAge, getChartOptionsForCountry, getChartOptionsForAge, exportExcel: generateExcel};
};

export const useGenerateExcel = (filter, dataForCountry, dataForAge) => {
  const formatData = useCallback((originData) => {
    const excelData = [];
    const nameKeys = Object.keys(originData);
    filter.forEach((item) => {
      const tempObj = {Name: item};
      nameKeys.forEach(subItem => {
        tempObj[subItem] = originData[subItem][item];
      });
      excelData.push(tempObj);
    });

    return excelData;
  }, [filter]);

  const generateExcel = useCallback((excelName) => {
    const countrySheet = formatData(dataForCountry);
    const ageSheet = formatData(dataForAge);

    const wsForCountry = utils.json_to_sheet(countrySheet);
    const wsForAge = utils.json_to_sheet(ageSheet);

    const wb = utils.book_new();
    utils.book_append_sheet(wb, wsForCountry, 'country');
    utils.book_append_sheet(wb, wsForAge, 'age');
    writeFile(wb, excelName + '.xlsx');
  }, [dataForCountry, dataForAge, filter]);

  return {generateExcel};
};

export default useAnimalMeatVsPlantBaseFood;