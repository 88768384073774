import React, { memo, useEffect, useState } from 'react';

import ReactECharts from 'echarts-for-react';
import * as echarts from 'echarts/core';
import { CanvasRenderer } from 'echarts/renderers';

echarts.use(
  [CanvasRenderer]
);

const monthConstant = {
  '1': 'Jan',
  '2': 'Feb',
  '3': 'Mar',
  '4': 'Apr',
  '5': 'May',
  '6': 'Jun',
  '7': 'Jul',
  '8': 'Aug',
  '9': 'Sep',
  '10': 'Oct',
  '11': 'Nov',
  '12': 'Dec',
};

function dateFormate(dateNum) {
  const year = parseInt((dateNum + '').slice(0, 4));
  const month = parseInt((dateNum + '').slice(-2));
  const monthName = monthConstant[month];
  return {
    year,
    month: monthName,
    dateNum,
    value: `${monthName} ${year}`
  };
}

const LineCharts = memo(function LineCharts({product}) {
  
  const [options, setOptions] = useState({});
  const optionsFormat = (product) => {
    let xData = [];
    const seriesData = [];
    product?.sort((a, b) => a.time - b.time).forEach(item => {
      xData.push(dateFormate(item.time));
      seriesData.push(item.score);
    });
    const option = {
      grid: {
        left: 40,
        right: 0,
        top: 20,
        bottom: 20
      },
      tooltip: {
        trigger: 'axis',
        backgroundColor: 'rgba(0,0,0,0.7)',
        borderColor: '#00A32E',
        textStyle: {
          color: '#FFFFFF'
        }
      },
      xAxis: {
        type: 'category',
        axisLine: {
          lineStyle: {
            color: '#00A32E'
          }
        },
        axisTick: {
          show: false
        },
        data: xData
      },
      yAxis: {
        type: 'value',
        axisLabel: {
          color: '#00A32E'
        },
        splitLine: {
          lineStyle: {
            color: 'rgba(0, 163, 46, .2)'
          }
        }
      },
      series: [
        {
          name: '',
          data: seriesData,
          type: 'line',
          symbol: 'circle',
          symbolSize: 3,
          label: {
            show: false
          },
          itemStyle: {
            color: '#00A32E'
          },
          lineStyle: {
            color: '#00A32E',
            width: '3'
          }
        }
      ]
    };
    return option;
  };

  useEffect(() => {
    setOptions(optionsFormat(product));
  }, [product]);

  return (
    <ReactECharts
      echarts={echarts}
      option={options}
      style={{
        height: '100%',
      }}
    />
  );
});

export default LineCharts;