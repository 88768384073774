/* eslint-disable indent */
import {
  ArrowRightOutlined,
  PlusOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons';
import { Col, Row } from 'antd';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  Image as KonvaImage,
  Layer,
  Rect,
  Stage,
  Transformer,
} from 'react-konva';
import useImage from 'use-image';
import GrabageSvg from '../../assets/img/garbage.svg';
import { PackageAiBtn2 as Btn2 } from '../../components/btn.jsx';
import Dialog from '../../components/dialog.jsx';
import EditableText from '../../components/editableText.jsx';
import style from './dialog.module.scss';

function buildAoi(name, nullPoints = false) {
  return {
    name,
    type: 'rect',
    points: nullPoints
      ? undefined
      : [
          { x: 0, y: 0 },
          { x: 50, y: 50 },
        ],
  };
}

function Editor({
  url,
  visible,
  data,
  setData,
  footText = 'Some foot text',
  needCreate = false,
}) {
  // image
  const [image] = useImage(url);

  // ref
  const boxRef = useRef(null);
  const rectRef = useRef();
  const transformerRef = useRef();

  // visible count
  const [visibleCount, setVisibleCount] = useState(0);
  useEffect(() => {
    if (!visible) return;
    setTimeout(() => {
      setVisibleCount((value) => {
        value + 1;
      });
    }, 100);
  }, [visible]);

  const contain = useMemo(() => {
    if (!visible) return { posScale: 1 };
    if (!image) return { posScale: 1 };
    if (!boxRef.current) return { posScale: 1 };
    const s = Math.min(
      boxRef.current.clientWidth / image.width,
      boxRef.current.clientHeight / image.height
    );
    return {
      imageWidth: image.width,
      imageHeight: image.height,
      posScale: s,
    };
  }, [image, boxRef.current, visibleCount]);

  const rect = useMemo(() => {
    if (!data?.points) return {};
    if (data.points.length < 2) return {};
    const p1 = data.points[0];
    const p2 = data.points[1];
    return {
      x: Math.min(p1.x, p2.x),
      y: Math.min(p1.y, p2.y),
      width: Math.abs(p1.x - p2.x),
      height: Math.abs(p1.y - p2.y),
    };
  }, [data]);

  function onRectChange(p1x, p1y, p2x, p2y) {
    const points = [
      { x: p1x, y: p1y },
      { x: p2x, y: p2y },
    ];
    setData((data) => {
      return { ...data, points };
    });
  }

  function onDragEnd(ev) {
    if (!ev.target) return;
    let { x, y } = ev.target.position();
    if (x < 0) x = 0;
    if (x > contain.width) x = contain.width;
    if (y < 0) y = 0;
    if (y > contain.height) y = contain.height;
    let { width, height } = ev.target.size();
    if (x + width > contain.width) width = contain.width - x;
    if (y + height > contain.height) height = contain.height - y;
    onRectChange(x, y, x + width, y + height);
  }

  // init transformer
  useEffect(() => {
    if (!rectRef.current || !transformerRef.current) return;
    transformerRef.current.nodes([rectRef.current]);
    transformerRef.current.getLayer().batchDraw();
  }, [rectRef.current, transformerRef.current]);

  function onTransformEnd() {
    const node = rectRef.current;
    if (!node) return;
    const scaleX = node.scaleX();
    const scaleY = node.scaleY();
    let x = node.x();
    if (x < 0) x = 0;
    if (x > contain.width) x = contain.width;
    let y = node.y();
    if (y < 0) y = 0;
    if (y > contain.height) y = contain.height;
    let width = node.width() * scaleX;
    if (x + width > contain.width) width = contain.width - x;
    let height = node.height() * scaleY;
    if (y + height > contain.height) height = contain.height - y;
    node.scaleX(1);
    node.scaleY(1);
    onRectChange(x, y, x + width, y + height);
  }

  function setName(val) {
    setData((data) => {
      return { ...data, name: val };
    });
  }

  function onClear() {
    setData((data) => {
      return { ...data, points: undefined };
    });
  }

  function onCreate() {
    setData((data) => {
      if (data.points) return data;
      return {
        ...data,
        points: [
          { x: 0, y: 0 },
          { x: 50 / contain.posScale, y: 50 / contain.posScale },
        ],
      };
    });
  }
  useEffect(() => {
    if (!needCreate) return;
    onCreate();
  }, [needCreate, contain]);

  return (
    <div className={style.editorBox}>
      <EditableText
        className={style.editorTitle}
        value={data.name}
        onChange={setName}
      />
      <div className={style.editorBody} ref={boxRef}>
        <div className={style.editorBodyInner}>
          <Stage
            style={{ transform: `scale(${contain.posScale})` }}
            width={contain?.imageWidth}
            height={contain?.imageHeight}
          >
            <Layer>
              {image && (
                <KonvaImage
                  image={image}
                  width={contain?.imageWidth}
                  height={contain?.imageHeight}
                />
              )}
            </Layer>
            <Layer>
              <Rect
                ref={rectRef}
                x={rect.x}
                y={rect.y}
                width={rect.width}
                height={rect.height}
                fill="#FAFF00AD"
                stroke="#00FFF0"
                strokeWidth={2}
                draggable={true}
                onDragEnd={onDragEnd}
                onTransformEnd={onTransformEnd}
              ></Rect>
              <Transformer
                visible={!!rect.width}
                ref={transformerRef}
                resizeEnabled={true}
                rotateEnabled={false}
                flipEnabled={false}
                anchorSize={8 / contain.posScale}
                anchorStrokeWidth={1 / contain.posScale}
                borderStrokeWidth={1 / contain.posScale}
              />
            </Layer>
          </Stage>
          {rect.width ? (
            <img
              src={GrabageSvg}
              className={style.editorClearImg}
              onClick={onClear}
            />
          ) : (
            <PlusOutlined className={style.editorClear} onClick={onCreate} />
          )}
        </div>
      </div>
      <div className={style.editorFoot}>{footText}</div>
    </div>
  );
}

function PackageAiDialog({
  designName,
  visible,
  setVisible,
  assetVariant,
  variant,
  setVariant,
  onHelpClick,
}) {
  const [data1, setData1] = useState(buildAoi('Brand Logo'));
  const [data2, setData2] = useState(buildAoi('Variant Name'));
  const [data3, setData3] = useState(
    buildAoi(
      'Secondary Element that helps with Brand Blocking (Optional)',
      true
    )
  );
  const [data4, setData4] = useState(
    buildAoi(
      'Secondary Element that helps with Variant Differentiation (Optional)',
      true
    )
  );
  useEffect(() => {
    if (!visible) return;
    // set data
    setData1(variant.aois[0]);
    setData2(variant.aois[1]);
    setData3(variant.aois[2]);
    setData4(variant.aois[3]);
  }, [variant, visible]);

  const onConfirm = useCallback(() => {
    const nv = { ...variant };
    nv.aois = [data1, data2, data3, data4];
    setVariant(nv);
    setVisible(false);
  }, [data1, data2, data3, data4]);

  function onDialogClose(v) {
    if (!v) onConfirm();
    else setVisible(v);
  }

  return (
    <Dialog
      className={style.dialogBox}
      visible={visible}
      setVisible={onDialogClose}
    >
      <div className={style.dialogBoxInner}>
        <Row className={style.dialogBody} gutter={48}>
          <Col className={style.dialogBodyUnit} span={6}>
            <div className={style.title1}>{designName}</div>
            <div className={style.imageBox}>
              <img src={assetVariant.url} />
            </div>
          </Col>
          <Col className={style.dialogBodyUnit} span={18}>
            <div className={style.info}>
              In this section, click each pack image to highlight the key design
              elements and DBAs on the pack
            </div>
            <Row className={style.designBox} gutter={70}>
              <Col className={style.rightLine} span={12}>
                <Row gutter={48}>
                  <Col span={12}>
                    <Editor
                      visible={visible}
                      url={assetVariant.standardUrl}
                      data={data1}
                      setData={setData1}
                      footText="Position the yellow box over the brand logo on the pack design."
                      needCreate={true}
                    />
                  </Col>
                  <Col span={12}>
                    <Editor
                      visible={visible}
                      url={assetVariant.standardUrl}
                      data={data2}
                      setData={setData2}
                      footText="Position the yellow box over the variant name."
                      needCreate={true}
                    />
                  </Col>
                </Row>
              </Col>
              <Col span={12}>
                <Row gutter={48}>
                  <Col span={12}>
                    <Editor
                      visible={visible}
                      url={assetVariant.standardUrl}
                      data={data3}
                      setData={setData3}
                      footText="Position the yellow box over any other brand element, image, or DBA. Skip if there aren’t any."
                    />
                  </Col>
                  <Col span={12}>
                    <Editor
                      visible={visible}
                      url={assetVariant.standardUrl}
                      data={data4}
                      setData={setData4}
                      footText="Position the yellow box over any other variant level design element or image. Skip if there aren’t any."
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
            <div className={style.btnBox}>
              <Btn2 onClick={onConfirm} suffix={<ArrowRightOutlined />}>
                CONFIRM
              </Btn2>
            </div>
          </Col>
        </Row>
        <QuestionCircleOutlined className={style.help} onClick={onHelpClick} />
      </div>
    </Dialog>
  );
}

export default PackageAiDialog;
