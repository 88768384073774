// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.WEtDkYilYeq_PHWulg6O{position:absolute;top:5vh;left:50%;transform:translateX(-50%);z-index:11}.iVpZUHB76ZFZ2LxfviZa{width:34vw}`, "",{"version":3,"sources":["webpack://./src/modules/occasion-module/views/home/components/title.module.scss"],"names":[],"mappings":"AAAA,sBACE,iBAAA,CACA,OAAA,CACA,QAAA,CACA,0BAAA,CACA,UAAA,CAGF,sBACE,UAAA","sourcesContent":[".titleContainer {\n  position: absolute;\n  top: 5vh;\n  left: 50%;\n  transform: translateX(-50%);\n  z-index: 11;\n}\n\n.titleImg {\n  width: 34vw;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"titleContainer": `WEtDkYilYeq_PHWulg6O`,
	"titleImg": `iVpZUHB76ZFZ2LxfviZa`
};
export default ___CSS_LOADER_EXPORT___;
