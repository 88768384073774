// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.QBt4OmLXOsOmmxdIVx9n{height:100%;width:2px;background:linear-gradient(180deg, #030f07 0%, #00a32e 50.52%, #030c07 100%)}`, "",{"version":3,"sources":["webpack://./src/modules/package-ai-performance/components/divider/index.module.scss"],"names":[],"mappings":"AAAA,sBACE,WAAA,CACA,SAAA,CACA,4EAAA","sourcesContent":[".divider {\n  height: 100%;\n  width: 2px;\n  background: linear-gradient(180deg, #030f07 0%, #00a32e 50.52%, #030c07 100%);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"divider": `QBt4OmLXOsOmmxdIVx9n`
};
export default ___CSS_LOADER_EXPORT___;
