import React from 'react';
import { Select } from 'antd';
import style from '../graph/graph.module.scss';

const { Option } = Select;

const TimeRangeSelector = ({ timeRanges, selectedTimeRange, setSelectedTimeRange,dropdownStyle }) => {
  return (
    <div>
      <Select
        className={`graph-select ${style.InputContainer}`}
        value={selectedTimeRange}
        dropdownStyle={dropdownStyle}
        onChange={(value) => setSelectedTimeRange(value)}
      >
        {timeRanges.map(range => (
          <Option style={{fontSize:'12px'}} key={range} value={range}>{range}</Option>
        ))}
      </Select>
    </div>
  );
};

export default TimeRangeSelector;
