import { ArrowLeftOutlined } from '@ant-design/icons';
import { message } from 'antd';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { putFileKey } from '../../apis/file';
import { getPredictPackageTemplate } from '../../apis/serve';
import Box from '../../components/box.jsx';
import Container from '../../components/container.jsx';
import style from './index.module.scss';
import ResizeBox from './resize.jsx';

function Title() {
  const navigate = useNavigate();
  function onBack() {
    navigate(-1);
  }

  return (
    <div className={style.title}>
      <ArrowLeftOutlined onClick={onBack} high={14} width={14} />
      <h1>Template</h1>
    </div>
  );
}

function ListBox({ children }) {
  return <div className={style.listBox}>{children}</div>;
}

function ListBoxInner({ children }) {
  return (
    <div className={style.listBoxInner}>
      <div className={style.list}>{children}</div>
    </div>
  );
}

function ListItem({ item }) {
  const navigate = useNavigate();
  // image url
  const [url, setUrl] = useState('');

  const ctx = useMemo(() => {
    try {
      return JSON.parse(item.info);
    } catch (err) {
      console.error('parse error', item.info, err);
      return {};
    }
  }, [item]);
  const title = useMemo(() => {
    return item.name ?? ctx?.basic?.projectName ?? 'Template';
  }, [ctx]);
  const imgFileKey = useMemo(() => {
    return (
      ctx?.asset?.unilever?.[0]?.variants?.[0]?.file ??
      ctx?.asset?.unilever?.[1]?.variants?.[0]?.file
    );
  }, [ctx]);

  const getImageUrl = useCallback(async () => {
    if (!imgFileKey) return;
    const { data } = await putFileKey(imgFileKey);
    if (data) {
      setUrl(data.url);
    }
  }, [imgFileKey]);
  useEffect(() => {
    void getImageUrl();
  }, [imgFileKey]);

  // goto asset
  const onClick = useCallback(() => {
    sessionStorage.setItem('packageAiContext', item.info);
    navigate('/package-ai-screening-asset');
  }, [item]);

  return (
    <div className={style.listItem} onClick={onClick}>
      <div className={style.listItemImgBox}>
        <img src={url} alt={title} loading="lazy" />
      </div>
      <div className={style.listItemInfoBox}>
        <h3 title={title}>{title}</h3>
      </div>
    </div>
  );
}

function PackageAiView() {
  // my tests
  const [list1, setList1] = useState([]);
  const [loading1, setLoading1] = useState(false);
  const [page1, setPage1] = useState(1);
  const [totalPages1, setTotalPages1] = useState(1);
  async function getList1() {
    if (loading1) return;
    setLoading1(true);
    try {
      const { pages, data } = await getPredictPackageTemplate({}, page1, 20);
      setTotalPages1(pages);
      if (page1 === 1) {
        setList1(data);
      } else {
        setList1((l) => {
          return l.concat(data);
        });
      }
    } catch (err) {
      console.error('get list 1 error', err);
      message.error('Get data error');
    }
    setLoading1(false);
  }
  function onNext1() {
    if (page1 < totalPages1) {
      setPage1((p) => p + 1);
    }
  }
  useEffect(() => {
    void getList1();
  }, [page1]);
  const bottomRef1 = useRef(null);
  const [scrollCount1, setScrollCount1] = useState(0);
  useEffect(() => {
    if (!bottomRef1.current) return;
    const observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        setScrollCount1((c) => c + 1);
      }
    });
    observer.observe(bottomRef1.current);
    return () => {
      observer.disconnect();
    };
  }, [bottomRef1.current]);
  useEffect(() => {
    if (scrollCount1 > 0) {
      onNext1();
    }
  }, [scrollCount1]);

  // public
  const [list2, setList2] = useState([]);
  const [loading2, setLoading2] = useState(false);
  const [page2, setPage2] = useState(1);
  const [totalPages2, setTotalPages2] = useState(1);
  async function getList2() {
    if (loading2) return;
    setLoading2(true);
    try {
      const { pages, data } = await getPredictPackageTemplate(
        { share: true },
        page2,
        20
      );
      setTotalPages2(pages);
      if (page2 === 1) {
        setList2(data);
      } else {
        setList2((l) => {
          return l.concat(data);
        });
      }
    } catch (err) {
      console.error('get list 2 error', err);
      message.error('Get data error');
    }
    setLoading2(false);
  }
  function onNext2() {
    if (page2 < totalPages2) {
      setPage2((p) => p + 1);
    }
  }
  useEffect(() => {
    void getList2();
  }, [page2]);
  const bottomRef2 = useRef(null);
  const [scrollCount, setScrollCount] = useState(0);
  useEffect(() => {
    if (!bottomRef2.current) return;
    const observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        setScrollCount((c) => c + 1);
      }
    });
    observer.observe(bottomRef2.current);
    return () => {
      observer.disconnect();
    };
  }, [bottomRef2.current]);
  useEffect(() => {
    if (scrollCount > 0) {
      onNext2();
    }
  }, [scrollCount]);

  return (
    <Container noDatabase={true}>
      <div className={style.containerInner}>
        <Box title={<Title />} className={style.box}>
          <ResizeBox
            className={style.boxInner}
            item1={
              <Box title="My Templates" className={style.itemBox}>
                <ListBox>
                  <ListBoxInner>
                    {list1.map((e) => {
                      return <ListItem item={e} key={e.id} />;
                    })}
                    <div ref={bottomRef1} />
                  </ListBoxInner>
                </ListBox>
              </Box>
            }
            item2={
              <Box title="Public Templates" className={style.itemBox}>
                <ListBox>
                  <ListBoxInner>
                    {list2.map((e) => {
                      return <ListItem item={e} key={e.id} />;
                    })}
                    <div ref={bottomRef2} />
                  </ListBoxInner>
                </ListBox>
              </Box>
            }
          />
        </Box>
      </div>
    </Container>
  );
}

export default PackageAiView;
