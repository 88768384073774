import { MoreOutlined } from '@ant-design/icons';
import { Dropdown, Switch } from 'antd';
import html2canvas from 'html2canvas';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import exportToExcel from '../../api/export.js';
import { getProduct } from '../../api/index.js';
import { PackageAiBtn2 as Btn } from '../../components/btn/index.jsx';
import Container from '../../components/container/index.jsx';
import Divider from '../../components/divider/index.jsx';
import ImgDialog from '../../components/img-dialog/index.jsx';
import { useVerticalRank } from '../../hooks/vertical.js';
import Chart from './chart/index.jsx';
import Filter from './filter/index.jsx';
import style from './index.module.scss';
import Table from './table/index.jsx';

function SwitchBtn({ className, value, setValue }) {
  const onTable = useCallback(() => {
    setValue('table');
  }, [setValue]);
  const onChart = useCallback(() => {
    setValue('chart');
  }, [setValue]);

  if (value === 'table') {
    return (
      <Btn className={className} onClick={onChart}>
        Switch to chart
      </Btn>
    );
  } else if (value === 'chart') {
    return (
      <Btn className={className} onClick={onTable}>
        Back to table
      </Btn>
    );
  } else {
    return <div className={className}>Unknown tab {value}</div>;
  }
}

function TabBody({
  className,
  value,
  data,
  setChartRef,
  showValue,
  onImgClick,
}) {
  if (value === 'table') {
    return (
      <Table
        className={className}
        data={data}
        setChartRef={setChartRef}
        showValue={showValue}
        onImgClick={onImgClick}
      />
    );
  } else if (value === 'chart') {
    return (
      <Chart
        className={className}
        data={data}
        setRef={setChartRef}
        showValue={showValue}
      />
    );
  } else {
    return <div className={className}>Unknown tab {value}</div>;
  }
}

function PackageAiView() {
  // loading
  const [loading, setLoading] = useState(false);

  // query
  const [query, setQuery] = useState(null);

  // data
  const [data, setData] = useState([]);
  const getData = useCallback(async () => {
    if (!query) return;
    setLoading(true);
    try {
      const { data: dd } = await getProduct(query);
      setData(dd);
    } catch (err) {
      console.error('get data error', err);
    }
    setLoading(false);
  }, [query, setData]);
  useEffect(() => {
    void getData();
  }, [getData]);

  const { getVerticalRank } = useVerticalRank(data);

  // build data
  const data2 = useMemo(() => {
    let totalBrand = 0;
    let totalAppeal = 0;
    let totalUpi = 0;

    let maxBrand = 0;
    let maxAppeal = 0;
    let maxUpi = 0;

    const d = data.map((e) => {
      totalBrand += e.brand_logo_visibility;
      totalAppeal += e.appeal;
      totalUpi += e.upi;

      maxBrand = Math.max(maxBrand, e.brand_logo_visibility);
      maxAppeal = Math.max(maxAppeal, e.appeal);
      maxUpi = Math.max(maxUpi, e.upi);

      return {
        name: e.display_name,
        key: `${e.market}-${e.sn}`,
        market: e.market,
        sn: e.sn,
        cover: e.cover,
        brand: e.brand_logo_visibility,
        brandRank: getVerticalRank(
          e.vertical,
          'brand_logo_visibility',
          e.market,
          e.sn
        ),
        appeal: e.appeal,
        appealRank: getVerticalRank(e.vertical, 'appeal', e.market, e.sn),
        upi: e.upi,
        upiRank: getVerticalRank(e.vertical, 'upi', e.market, e.sn),
      };
    });

    const avgBrand = totalBrand / d.length;
    const avgAppeal = totalAppeal / d.length;
    const avgUpi = totalUpi / d.length;

    return d.map((e) => {
      return {
        ...e,
        brand2: (e.brand / avgBrand) * 100.0,
        brand3: (e.brand / maxBrand) * 100.0,
        appeal2: (e.appeal / avgAppeal) * 100.0,
        appeal3: (e.appeal / maxAppeal) * 100.0,
        upi2: (e.upi / avgUpi) * 100.0,
        upi3: (e.upi / maxUpi) * 100.0,
      };
    });
  }, [data, getVerticalRank]);

  const [showValue, setShowValue] = useState(true);

  const onSaveExcel = useCallback(() => {
    exportToExcel(
      'Pack AI Competitive Landscape',
      data2.map((e) => {
        return {
          Market: e.market,
          'Product Name': e.name,
          'Brand Visibility': e.brand,
          Appeal: e.appeal,
          'Unpriced Purchase Intent': e.upi,
        };
      })
    );
  }, [data2]);

  const [chartRef, setChartRef] = useState(null);
  const onSavePng = useCallback(() => {
    if (!chartRef) return;
    html2canvas(chartRef).then((canvas) => {
      const img = canvas.toDataURL('image/png');
      const a = document.createElement('a');
      a.href = img;
      a.download = 'Pack AI Competitive Landscape.png';
      a.click();
    });
  }, [chartRef]);

  const onMenu = useCallback(
    ({ key }) => {
      if (key === '2') {
        onSavePng();
      } else if (key === '3') {
        onSaveExcel();
      }
    },
    [onSaveExcel]
  );

  // switch tab
  const [tab, setTab] = useState('table');

  // image dialog
  const [imgDialogVisible, setImgDialogVisible] = useState(false);
  const [imgDialogSrc, setImgDialogSrc] = useState();
  const [imgDialogAlt, setImgDialogAlt] = useState();
  const onImgClick = useCallback(
    (src, alt) => {
      setImgDialogSrc(src);
      setImgDialogAlt(alt);
      setImgDialogVisible(true);
    },
    [setImgDialogSrc, setImgDialogAlt, setImgDialogVisible]
  );

  return (
    <Container loading={loading}>
      <div className={style.box1}>
        <div className={style.box11}>
          <div className={style.box111}>
            <SwitchBtn value={tab} setValue={setTab} />
          </div>
          <div className={style.box112}>
            <Filter query={query} setQuery={setQuery} />
          </div>
        </div>
        <Divider className={style.box12} />
        <TabBody
          className={style.box13}
          value={tab}
          data={data2}
          setChartRef={setChartRef}
          showValue={showValue}
          onImgClick={onImgClick}
        />
      </div>
      <div className={style.box2}>
        <Dropdown
          menu={{
            items: [
              {
                key: '1',
                label: (
                  <span className={style.menu}>
                    <span className={style.text}>Show Data Labels</span>
                    <Switch
                      className={style.switch}
                      checked={showValue}
                      onChange={(checked) => setShowValue(checked)}
                    />
                  </span>
                ),
              },
              {
                key: '2',
                label: 'Save as PNG',
              },
              {
                key: '3',
                label: 'Export as Excel',
              },
            ],
            onClick: onMenu,
          }}
          trigger={['click']}
          placement="bottomRight"
        >
          <MoreOutlined className={style.icon} />
        </Dropdown>
      </div>
      <ImgDialog
        visible={imgDialogVisible}
        setVisible={setImgDialogVisible}
        src={imgDialogSrc}
        alt={imgDialogAlt}
      />
    </Container>
  );
}

export default PackageAiView;
