// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.jiSR6J2Hc_O60GlFLm2D{width:90vw;height:90vh}.RoQZBdZCfNmuGNRsZlfC{height:100%;padding:23px 100px;box-sizing:border-box}.S9sX8c8bXghfQsf754gX{width:100%;height:100%;object-fit:contain}`, "",{"version":3,"sources":["webpack://./src/modules/package-ai-performance/components/img-dialog/index.module.scss"],"names":[],"mappings":"AAAA,sBACE,UAAA,CACA,WAAA,CAGF,sBACE,WAAA,CACA,kBAAA,CACA,qBAAA,CAGF,sBACE,UAAA,CACA,WAAA,CACA,kBAAA","sourcesContent":[".imgDialog {\n  width: 90vw;\n  height: 90vh;\n}\n\n.imgDialogBox {\n  height: 100%;\n  padding: 23px 100px;\n  box-sizing: border-box;\n}\n\n.imgDialogImg {\n  width: 100%;\n  height: 100%;\n  object-fit: contain;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"imgDialog": `jiSR6J2Hc_O60GlFLm2D`,
	"imgDialogBox": `RoQZBdZCfNmuGNRsZlfC`,
	"imgDialogImg": `S9sX8c8bXghfQsf754gX`
};
export default ___CSS_LOADER_EXPORT___;
