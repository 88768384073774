import React, { useCallback, useEffect, useState } from 'react';
import style from './index.module.scss';
// import { Input, Table } from 'antd';
import classNames from 'classnames';
import TextArea from 'antd/es/input/TextArea';
import { useBase } from '../../hooks/useBase.jsx';
import useFeedback from '../../hooks/useFeedback.js';
import ShowTableByEditCom from './showTableByEdit.jsx';

const EditContent = () => {
  const { editChange, curData, curDataChange, userInfo } = useBase();
  const {  updateFeedback } = useFeedback();

  // const columnEdit = [
  //   {
  //     title: <div>Value Share Projection 2024 (MAT)</div>,
  //     dataIndex: 'projection',
  //     align: 'center',
  //     children: [
  //       {
  //         title: '',
  //         dataIndex: 'projection_jan',
  //         width: 130,
  //         align: 'center',
  //         render: () => <div>
  //           <div style={{textAlign: 'left'}} className={classNames(style.inputBox)}>
  //             Share %
  //             <div className={classNames(style.oneBox)}>
  //               <div className={classNames(style.twoBox)}>
  //                 <div className={classNames(style.threeBox)}>
  //                   <div className={classNames(style.tipBox)}>
  //                       Please submit share as a number
  //                       E.g. 21.4% is 0.214
  //                   </div>
  //                 </div>
  //               </div>
  //             </div>
  //           </div>
  //           <div style={{textAlign: 'left', marginTop: '10px'}}>Bps vs YA or AM</div>
  //         </div>
  //       },
  //       {
  //         title: 'Feb',
  //         dataIndex: 'projection_feb',
  //         width: 100,
  //         align: 'center',
  //         render: () => <div>
  //           <div><Input placeholder="(share)" value={dataEdit[0]['projection_feb']['share']} onChange={(e) => fnValChange(e, 'projection_feb', 'share')} /></div>
  //           <div><Input placeholder="(bps)" value={dataEdit[0]['projection_feb']['value']} onChange={(e) => fnValChange(e, 'projection_feb', 'value')} /></div>
  //         </div>
  //       },
  //       {
  //         title: 'Mar',
  //         dataIndex: 'projection_mar',
  //         width: 100,
  //         align: 'center',
  //         render: () => <div>
  //           <div><Input placeholder="(share)" value={dataEdit[0]['projection_mar']['share']} onChange={(e) => fnValChange(e, 'projection_mar', 'share')} /></div>
  //           <div><Input placeholder="(bps)" value={dataEdit[0]['projection_mar']['value']} onChange={(e) => fnValChange(e, 'projection_mar', 'value')} /></div>
  //         </div>
  //       },
  //       {
  //         title: 'Apr',
  //         dataIndex: 'projection_apr',
  //         width: 100,
  //         align: 'center',
  //         render: () => <div>
  //           <div><Input placeholder="(share)" value={dataEdit[0]['projection_apr']['share']} onChange={(e) => fnValChange(e, 'projection_apr', 'share')} /></div>
  //           <div><Input placeholder="(bps)" value={dataEdit[0]['projection_apr']['value']} onChange={(e) => fnValChange(e, 'projection_apr', 'value')} /></div>
  //         </div>
  //       },
  //       {
  //         title: 'May',
  //         dataIndex: 'projection_may',
  //         width: 100,
  //         align: 'center',
  //         render: () => <div>
  //           <div><Input placeholder="(share)" value={dataEdit[0]['projection_may']['share']} onChange={(e) => fnValChange(e, 'projection_may', 'share')} /></div>
  //           <div><Input placeholder="(bps)" value={dataEdit[0]['projection_may']['value']} onChange={(e) => fnValChange(e, 'projection_may', 'value')} /></div>
  //         </div>
  //       },
  //       {
  //         title: 'Jun',
  //         dataIndex: 'projection_jun',
  //         width: 100,
  //         align: 'center',
  //         render: () => <div>
  //           <div><Input placeholder="(share)" value={dataEdit[0]['projection_jun']['share']} onChange={(e) => fnValChange(e, 'projection_jun', 'share')} /></div>
  //           <div><Input placeholder="(bps)" value={dataEdit[0]['projection_jun']['value']} onChange={(e) => fnValChange(e, 'projection_jun', 'value')} /></div>
  //         </div>
  //       },
  //       {
  //         title: 'Jul',
  //         dataIndex: 'projection_jul',
  //         width: 100,
  //         align: 'center',
  //         render: () => <div>
  //           <div><Input placeholder="(share)" value={dataEdit[0]['projection_jul']['share']} onChange={(e) => fnValChange(e, 'projection_jul', 'share')} /></div>
  //           <div><Input placeholder="(bps)" value={dataEdit[0]['projection_jul']['value']} onChange={(e) => fnValChange(e, 'projection_jul', 'value')} /></div>
  //         </div>
  //       },
  //       {
  //         title: 'Aug',
  //         dataIndex: 'projection_aug',
  //         width: 100,
  //         align: 'center',
  //         render: () => <div>
  //           <div><Input placeholder="(share)" value={dataEdit[0]['projection_aug']['share']} onChange={(e) => fnValChange(e, 'projection_aug', 'share')} /></div>
  //           <div><Input placeholder="(bps)" value={dataEdit[0]['projection_aug']['value']} onChange={(e) => fnValChange(e, 'projection_aug', 'value')} /></div>
  //         </div>
  //       },
  //       {
  //         title: 'Sep',
  //         dataIndex: 'projection_sep',
  //         width: 100,
  //         align: 'center',
  //         render: () => <div>
  //           <div><Input placeholder="(share)" value={dataEdit[0]['projection_sep']['share']} onChange={(e) => fnValChange(e, 'projection_sep', 'share')} /></div>
  //           <div><Input placeholder="(bps)" value={dataEdit[0]['projection_sep']['value']} onChange={(e) => fnValChange(e, 'projection_sep', 'value')} /></div>
  //         </div>
  //       },
  //       {
  //         title: 'Oct',
  //         dataIndex: 'projection_oct',
  //         width: 100,
  //         align: 'center',
  //         render: () => <div>
  //           <div><Input placeholder="(share)" value={dataEdit[0]['projection_oct']['share']} onChange={(e) => fnValChange(e, 'projection_oct', 'share')} /></div>
  //           <div><Input placeholder="(bps)" value={dataEdit[0]['projection_oct']['value']} onChange={(e) => fnValChange(e, 'projection_oct', 'value')} /></div>
  //         </div>
  //       },
  //       {
  //         title: 'Nov',
  //         dataIndex: 'projection_nov',
  //         width: 100,
  //         align: 'center',
  //         render: () => <div>
  //           <div><Input placeholder="(share)" value={dataEdit[0]['projection_nov']['share']} onChange={(e) => fnValChange(e, 'projection_nov', 'share')} /></div>
  //           <div><Input placeholder="(bps)" value={dataEdit[0]['projection_nov']['value']} onChange={(e) => fnValChange(e, 'projection_nov', 'value')} /></div>
  //         </div>
  //       },
  //       {
  //         title: 'Dec',
  //         dataIndex: 'projection_dec',
  //         width: 100,
  //         align: 'center',
  //         render: () => <div>
  //           <div><Input placeholder="(share)" value={dataEdit[0]['projection_dec']['share']} onChange={(e) => fnValChange(e, 'projection_dec', 'share')} /></div>
  //           <div><Input placeholder="(bps)" value={dataEdit[0]['projection_dec']['value']} onChange={(e) => fnValChange(e, 'projection_dec', 'value')} /></div>
  //         </div>
  //       }
  //     ]
  //   },
  // ];

  const [dataEdit, setDataEdit] = useState([
    {
      projection_jan: 'L',
      projection_feb: {
        share: '',
        value: ''
      },
      projection_mar: {
        share: '',
        value: ''
      },
      projection_apr: {
        share: '',
        value: ''
      },
      projection_may: {
        share: '',
        value: ''
      },
      projection_jun: {
        share: '',
        value: ''
      },
      projection_jul: {
        share: '',
        value: ''
      },
      projection_aug: {
        share: '',
        value: ''
      },
      projection_sep: {
        share: '',
        value: ''
      },
      projection_oct: {
        share: '',
        value: ''
      },
      projection_nov: {
        share: '',
        value: ''
      },
      projection_dec: {
        share: '',
        value: ''
      },
    }
  ]);

  // const fnValChange = (e, field, type) => {
  //   const val = e.target.value;
  //   const tempDataEdit = JSON.parse(JSON.stringify(dataEdit));
  //   tempDataEdit[0][field][type] = val;
  //   setDataEdit(tempDataEdit);
  // };

  const fnClose = () => {
    curDataChange([]);
    editChange();
  };

  const [keyIssues, setKeyIssues] = useState('');
  const [actionPlan, setActionPlan] = useState('');
  const [turnaroundPlan, setTurnaroundPlan] = useState('');

  const fnInputChagne = (e, type) => {
    if(type === 'keyIssues') {
      setKeyIssues(e.target.value);
    } else if(type === 'actionPlan'){
      setActionPlan(e.target.value);
    } else {
      setTurnaroundPlan(e.target.value);
    }
  };

  useEffect(() => {
    if(curData.length) {
      const data = curData[0];
      setKeyIssues(data.key_issues);
      setActionPlan(data.action_plan);
      setTurnaroundPlan(data.turnaround_plan);
      if(data.projection) {
        setDataEdit([JSON.parse(data.projection)]);
      } else {
        setDataEdit([{
          projection_jan: 'L',
          projection_feb: {
            share: '',
            value: ''
          },
          projection_mar: {
            share: '',
            value: ''
          },
          projection_apr: {
            share: '',
            value: ''
          },
          projection_may: {
            share: '',
            value: ''
          },
          projection_jun: {
            share: '',
            value: ''
          },
          projection_jul: {
            share: '',
            value: ''
          },
          projection_aug: {
            share: '',
            value: ''
          },
          projection_sep: {
            share: '',
            value: ''
          },
          projection_oct: {
            share: '',
            value: ''
          },
          projection_nov: {
            share: '',
            value: ''
          },
          projection_dec: {
            share: '',
            value: ''
          },
        }]);
      }
    }
  }, [curData]);

  const fnSubmit = useCallback(async () => {
    const params = {
      id: curData[0].id,
      key_issues: keyIssues,
      action_plan: actionPlan,
      turnaround_plan: turnaroundPlan,
      projection: JSON.stringify(dataEdit[0]),
      modify_user: userInfo.username
    };
    const result = await updateFeedback(params);
    if(result) {
      const tempData = {...curData[0]};
      tempData.key_issues = params.key_issues;
      tempData.action_plan = params.action_plan;
      tempData.projection = params.projection;
      tempData.turnaround_plan = params.turnaround_plan;
      curDataChange([tempData]);
      editChange();
    }
  }, [keyIssues, actionPlan, dataEdit, curData, userInfo, turnaroundPlan]);

  return <div>
    <ShowTableByEditCom curData={curData}></ShowTableByEditCom>
    <div className={classNames(style.editBox)}>
      <div className={classNames(style.editRow)}>
        <div className={classNames(style.inputWrap, style.inputWrap2)}>
          <TextArea placeholder='Performance Status Reflection(Key Issues)' rows={4} value={keyIssues} onChange={(e) => fnInputChagne(e, 'keyIssues')} />
        </div>
        <div className={classNames(style.inputWrap, style.inputWrap2)} style={{marginLeft: '5px'}}>
          <TextArea placeholder='Action Plan(30-60-90)' rows={4}  value={actionPlan} onChange={(e) => fnInputChagne(e, 'actionPlan')}/>
        </div>
        <div className={classNames(style.inputWrap, style.inputWrap2)} style={{marginLeft: '5px'}}>
          <TextArea placeholder='Turnaround Plan' rows={4}  value={turnaroundPlan} onChange={(e) => fnInputChagne(e, 'turnaroundPlan')}/>
        </div>
      </div>
      <div className={classNames(style.editRow)} style={{marginTop: '10px'}}>
        <div className={classNames(style.inputWrap)} style={{width: '1100px'}}>
          {/* <Table
            rowKey="projection_jan"
            columns={columnEdit}
            dataSource={dataEdit}
            bordered
            size="middle"
            pagination={false}
          /> */}
          <div className={classNames(style.btnBox)}>
            <div className={classNames(style.subBtn)} style={{margin: '0px', marginRight: '10px'}} onClick={fnClose}>Cancel</div>
            <div className={classNames(style.subBtn)} style={{margin: '0px'}} onClick={fnSubmit}>Submit</div>
          </div>
        </div>
      </div>
    </div>
  </div>;

};

export default EditContent;