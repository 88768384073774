import React, { useMemo, useRef, useState } from 'react';
import LeftSvg from '../../assets/img/left.svg';
import styleModule from './resize.module.scss';

function ResizeBtn({
  reff,
  className = '',
  onMouseDown,
  style = { left: '50%' },
}) {
  return (
    <div
      ref={reff}
      className={`${styleModule.resizeBtn} ${className}`}
      style={style}
    >
      <img
        className={`${styleModule.icon} ${styleModule.left}`}
        src={LeftSvg}
      />
      <img
        className={`${styleModule.icon} ${styleModule.right}`}
        src={LeftSvg}
      />
      <div className={styleModule.over} onMouseDown={onMouseDown}></div>
    </div>
  );
}

function ResizeBox({ className = '', item1, item2 }) {
  const [isResizing, setIsResizing] = useState(false);
  const boxRef = useRef(null);
  const btnRef = useRef(null);
  const [btnLeft, setBtnLeft] = useState(50.0);
  const itemWidth1 = useMemo(() => {
    return `${btnLeft}%`;
  }, [btnLeft]);
  const itemWidth2 = useMemo(() => {
    return `${100.0 - btnLeft}%`;
  }, [btnLeft]);

  function onMouseDown() {
    setIsResizing(true);
  }
  function onMouseUp() {
    setIsResizing(false);
  }
  function onMouseMove(ev) {
    if (!boxRef.current) return;
    if (!btnRef.current) return;
    if (!ev.target) return;
    if (!isResizing) return;
    const x = ev.clientX;
    const boxRect = boxRef.current.getBoundingClientRect();
    const width = Math.max(
      40,
      Math.min(60, ((x - boxRect.left) / boxRect.width) * 100)
    );
    setBtnLeft(width);
  }

  return (
    <div
      ref={boxRef}
      className={`${styleModule.resizeBox} ${className}`}
      onMouseUp={onMouseUp}
      onMouseLeave={onMouseUp}
      onMouseMove={onMouseMove}
    >
      <div
        className={styleModule.item}
        style={{ width: itemWidth1, paddingRight: '10px' }}
      >
        {item1}
      </div>
      <div
        className={styleModule.item}
        style={{ width: itemWidth2, paddingLeft: '10px' }}
      >
        {item2}
      </div>
      <ResizeBtn
        reff={btnRef}
        onMouseDown={onMouseDown}
        style={{ left: itemWidth1 }}
      />
    </div>
  );
}

export default ResizeBox;
