import { ColorPicker, Input, message, Select } from 'antd';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { PackageAiBtn2 as Btn } from '../../components/btn.jsx';
import style from './text.module.scss';
import PickerSvg from '../../assets/img/picker.svg';
import classNames from 'classnames';

const FONT_OPTIONS = [
  {
    label: 'Unilever Shilling',
    value: 'unilever-shilling-regular',
  },
  {
    label: <span style={{ fontFamily: 'Brandon' }}>Brandon</span>,
    value: 'Brandon',
  },
  {
    label: <span style={{ fontFamily: 'FilsonSoft' }}>Filson Soft</span>,
    value: 'FilsonSoft',
  },
  {
    label: <span style={{ fontFamily: 'GoodKarma' }}>Good Karma</span>,
    value: 'GoodKarma',
  },
  {
    label: <span style={{ fontFamily: 'TrendSansOne' }}>Trend Sans One</span>,
    value: 'TrendSansOne',
  },
  {
    label: <span style={{ fontFamily: 'TrendSansFive' }}>Trend Sans Five</span>,
    value: 'TrendSansFive',
  },
];

const FONT_SIZE_OPTIONS = [
  { label: '8px', value: 8 },
  { label: '16px', value: 16 },
  { label: '24px', value: 24 },
  { label: '48px', value: 48 },
  { label: '64px', value: 64 },
  { label: '72px', value: 72 },
];

const TEXT_ALIGN_OPTIONS = [
  { label: 'Left', value: 'left' },
  { label: 'Center', value: 'center' },
  { label: 'Right', value: 'right' },
];

function FormLabel({ children }) {
  return (
    <div className={style.formLabel}>
      <div className={style.formIcon} />
      <div className={style.formLabelContent}>{children}</div>
    </div>
  );
}

function PackageAiText({
  className,
  data,
  scale = 1,
  addText,
  updateText,
  picking = false,
  onPick,
  pickColor,
  onConfirm,
}) {
  const selectedIndex = useMemo(() => {
    return data.findIndex((e) => e.selected && e.type === 'text');
  }, [data]);
  const selectedItem = useMemo(() => {
    if (selectedIndex >= 0) {
      return data[selectedIndex];
    }
    return null;
  }, [data, selectedIndex]);

  const [size, setSize] = useState(16);
  const [font, setFont] = useState('unilever-shilling-regular');
  const [color, setColor] = useState('#00a32e');
  const [textAlign, setTextAlign] = useState('left');
  const [text, setText] = useState();

  const onColorChange = useCallback(
    (color, hex) => {
      setColor(hex);
    },
    [setColor]
  );

  const fontSizeOptions = useMemo(() => {
    return FONT_SIZE_OPTIONS.map((e) => {
      return {
        label: e.label,
        value: Math.round(e.value / scale),
      };
    });
  }, [scale]);
  useEffect(() => {
    setSize(fontSizeOptions[1].value);
  }, [fontSizeOptions]);

  useEffect(() => {
    if (selectedItem) {
      setSize(selectedItem.fontSize);
      setFont(selectedItem.fontFamily);
      setColor(selectedItem.color);
      setTextAlign(selectedItem.textAlign);
      setText(selectedItem.text);
    } else {
      setText();
    }
  }, [selectedItem]);

  const onSubmit = useCallback(() => {
    if (!text) {
      message.warning('Please enter text');
      return;
    }
    if (selectedItem) {
      updateText(selectedIndex, {
        ...selectedItem,
        fontSize: size,
        fontFamily: font,
        textAlign: textAlign,
        color: color,
        text,
      });
    } else {
      addText({
        fontSize: size,
        fontFamily: font,
        textAlign: textAlign,
        color: color,
        text,
      });
    }
  }, [text, size, font, color, textAlign, selectedItem]);

  useEffect(() => {
    if (!pickColor) return;
    setColor(pickColor);
  }, [pickColor]);

  return (
    <div className={`${style.textBox} ${className}`}>
      <FormLabel>Font</FormLabel>
      <Select
        style={{ width: '100%' }}
        value={font}
        onChange={setFont}
        options={FONT_OPTIONS}
      />
      <FormLabel>Color</FormLabel>
      <div className={style.box12}>
        <ColorPicker
          className={style.box121}
          value={color}
          onChange={onColorChange}
          defaultFormat="hex"
          showText={true}
        />
        <div
          className={classNames(style.box122, {
            [style.active]: picking,
          })}
          onClick={onPick}
        >
          <img className={style.icon} src={PickerSvg} />
        </div>
      </div>
      <FormLabel>Size</FormLabel>
      <Select
        style={{ width: '100%' }}
        value={size}
        onChange={setSize}
        options={fontSizeOptions}
      />
      <FormLabel>Align</FormLabel>
      <Select
        style={{ width: '100%' }}
        value={textAlign}
        onChange={setTextAlign}
        options={TEXT_ALIGN_OPTIONS}
      />
      <FormLabel>Content</FormLabel>
      <div className={style.inputBox}>
        <Input.TextArea
          style={{ marginTop: '10px' }}
          value={text}
          onChange={(e) => setText(e.target.value)}
          placeholder="Please enter text"
          autoSize={{ minRows: 2, maxRows: 6 }}
        />
        <div className={style.inputBtnBox}>
          <div className={style.inputBtn} onClick={onSubmit}>
            {selectedItem ? 'Update' : 'Add text'}
          </div>
        </div>
      </div>
      <div className={style.textBtn}>
        <Btn onClick={onConfirm}>Render the Design</Btn>
      </div>
    </div>
  );
}

export default PackageAiText;
