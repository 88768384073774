import { message } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { downloadData, putFileKey } from '../../apis/file';
import ImgDialog from '../../components/imgDialog.jsx';
import legendStyle from '../../components/legend.module.scss';
import Loading from '../../components/loading.jsx';
import Title from '../../components/title2.jsx';
import style from './brand.module.scss';
import { PackageAiTableValue1, TableBodyRowValue } from './table.jsx';

function Legend({ className = '' }) {
  <div className={`${style.legendBox} ${className}`}>
    <div className={style.legendBox11}>
      <div className={`${legendStyle.icon} ${legendStyle.dataGreen}`} />
      <span className={legendStyle.text2}> = Outstanding</span>
    </div>
    <div className={style.legendBox11}>
      <div className={`${legendStyle.icon} ${legendStyle.dataYellow}`} />
      <span className={legendStyle.text2}> = Ready</span>
    </div>
    <div className={style.legendBox11}>
      <div className={`${legendStyle.icon} ${legendStyle.dataRed}`} />
      <span className={legendStyle.text2}> = Risky/High Risk</span>
    </div>
  </div>;
}

const ROWS = [
  'Brand Logo',
  'Variant Name',
  'Secondary Brand Blocking (Optional)',
  'Secondary Variant Differentiation (Optional)',
];

function Table({ labels = [], values = [] }) {
  const brand = useMemo(() => {
    if (values[0]) {
      return [ROWS[0]].concat(values[0]);
    } else {
      const vs = new Array(labels.length).fill('');
      vs[0] = [ROWS[0]];
      return vs;
    }
  }, [values]);
  const variant = useMemo(() => {
    if (values[1]) {
      return [ROWS[1]].concat(values[1]);
    } else {
      const vs = new Array(labels.length).fill('');
      vs[0] = [ROWS[1]];
      return vs;
    }
  }, [values]);
  const brand2 = useMemo(() => {
    if (values[2]) {
      return [ROWS[2]].concat(values[2]);
    } else {
      const vs = new Array(labels.length).fill('');
      vs[0] = [ROWS[2]];
      return vs;
    }
  }, [values]);
  const variant2 = useMemo(() => {
    if (values[3]) {
      return [ROWS[3]].concat(values[3]);
    } else {
      const vs = new Array(labels.length).fill('');
      vs[0] = [ROWS[3]];
      return vs;
    }
  }, [values]);

  return (
    <PackageAiTableValue1 labels={labels}>
      <TableBodyRowValue values={brand}></TableBodyRowValue>
      <TableBodyRowValue values={variant}></TableBodyRowValue>
      <TableBodyRowValue values={brand2}></TableBodyRowValue>
      <TableBodyRowValue values={variant2}></TableBodyRowValue>
    </PackageAiTableValue1>
  );
}

function Item3({ className = '', title, url, onClick }) {
  function onImgClick() {
    onClick && onClick(url);
  }

  return (
    <div className={`${style.item} ${className}`}>
      <div className={style.itemTitle}>{title}</div>
      <div className={style.itemImg} onClick={onImgClick}>
        <img src={url} alt={title} />
      </div>
    </div>
  );
}

function PackageAiBody({ context = {}, exportCount = 0 }) {
  const [columns, setColumns] = useState(['', 'Some brand']);
  const [values, setValues] = useState([[0.0], [0.0], [0.0], [0.0]]);
  const [imgs, setImgs] = useState([]);
  const [loading, setLoading] = useState(false);

  async function init(ctx) {
    if (loading) return;
    setLoading(true);
    try {
      const col = [''];
      const val = [[], [], [], []];
      const imgs = [];

      // process data
      for (let i = 0; i < ctx.asset.list.length; i += 1) {
        const ii = ctx.asset.list[i];
        col.push(ii.title);
        const vid = ctx.res.imageIds[i];
        const vids = vid.toString();
        const v = ctx.res.variants[vids];
        val[0].push(v.vasresult.logo);
        val[1].push(v.vasresult.brand);
        val[2].push(v.vasresult.variant);
        val[3].push(v.vasresult.aoi);
        imgs.push({ title: ii.title, file: v.roiimage });
      }

      // set data
      setColumns(col);
      setValues(val);

      // process images
      for (const ii of imgs) {
        const { title, file } = ii;
        const { data } = await putFileKey(file);
        if (!data) continue;
        setImgs((oi) => [...oi, { title, url: data.url }]);
      }
    } catch (err) {
      console.error('brand init error', err);
      message.error('Init error');
    }
    setLoading(false);
  }
  useEffect(() => {
    void init(context);
  }, [context]);

  useEffect(() => {
    if (!exportCount) return;
    const data = [
      columns,
      [ROWS[0], ...values[0]],
      [ROWS[1], ...values[1]],
      [ROWS[2], ...values[2]],
      [ROWS[3], ...values[3]],
    ];
    downloadData('Vas_batch_text.xlsx', data);
  }, [exportCount]);

  const [dialogVisible, setDialogVisible] = useState(false);
  const [dialogImg, setDialogImg] = useState('');
  function onImgClick(url) {
    setDialogImg(url);
    setDialogVisible(true);
  }

  return (
    <>
      <Title>Brand Visibility Score</Title>
      <div className={style.box1}>
        <div className={style.box11}>
          <Table labels={columns} values={values} />
        </div>
        <Legend className={style.legend} />
      </div>
      <Title className={style.title2}>DBA Hotspot</Title>
      <div className={style.box2}>
        <div className={style.box21}>
          {imgs.map((item, index) => {
            return (
              <Item3
                key={`${item.title}-${index}`}
                title={item.title}
                url={item.url}
                onClick={onImgClick}
              />
            );
          })}
        </div>
      </div>
      {loading && <Loading />}
      <ImgDialog
        visible={dialogVisible}
        setVisible={setDialogVisible}
        src={dialogImg}
      />
    </>
  );
}

export default PackageAiBody;
