import React, { useEffect, useRef, useState,forwardRef  } from 'react';
import './social-buzz.scss';
import { tiktokLogoWhite, metaLogoWhite } from '../../../../components/icons/icons.jsx';
import DetailTitle from '../detail-title/detail-title.jsx'
import SocialWordCloud from '../social-word-cloud/social-word-cloud.jsx';
import { fetchGrowth, fetchPost,fetchWordCloud } from '../../api/request.js';
import VideoComponent from '../tiktok-modal-tab/tiktok-modal.jsx';
import MetaPostComponent from '../meta-post/meta-post-modal.jsx';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import 'antd/dist/reset.css';
import RelatedTheme from '../related-theme/related-theme.jsx';

function LogoLabel(e) {
    return (
        <div className='label'>
            <img className='labelArrow' src={e.logo} />
            <div className='labelText'>{e.name}</div>
        </div>
    )
}




const SocialBuzz = forwardRef(({loading,err,trendingDishData},ref)=>{
// function SocialBuzz({  loading = { loading }, err = { err }, trendingDishData = { trendingDishData } }) {

    const [metaPosts, setMetaPosts] = useState([]);
    const [tiktokMentions, setTiktokMentions] = useState([]);
    const [nowLoading, setNowLoading] = useState(true);
    const [themeData, setThemeData] = useState([]);
    const [selectedWordCloud,setSelectedWordCloud] = useState([])
    const [errorPost, setErrorPost] = useState(false);
    const [errorWC, setErrorWC] = useState(false);


    const data = {
        countries: trendingDishData.countries,
        brands: trendingDishData.brand,
        top_dishes: trendingDishData.topDish,
        top_dish_variant_id: trendingDishData.detail.top_dish_variant_id,
    };
    const WordCloudData = {
        countries: trendingDishData.countries,
        brands: trendingDishData.brand,
        top_dish: trendingDishData.topDish[0],
        top_dish_variant_id: trendingDishData.detail.top_dish_variant_id,
    };

    // fetchPost(data).then(res => {
    //         // console.log(res)
    //         setMetaPosts(res.data.meta_posts || []);
    //         setTiktokMentions(res.data.tiktok_mentions || []);
    //         setThemeData(res.data.relevant_themes || []);
    //     })

    useEffect(() => {
        fetchPost(data)
            .then(res => {
                // console.log('aaaa',res)
                setMetaPosts(res.data.meta_posts || []);
                setTiktokMentions(res.data.tiktok_mentions || []);
                setThemeData(res.data.relevant_themes || []);
                setNowLoading(false)
            })
            .catch(err1 => {
                console.error('fetchPost error:', err1);
                setErrorPost(true);
            });
            // console.log('data',WordCloudData)
        fetchWordCloud(WordCloudData)
            .then(res1 => {
                // console.log('res',res1)
                setSelectedWordCloud(res1.data)
            })
            .catch(err2 => {
                console.error('fetchWordCloud error:', err2);
                setErrorWC(true);
            });
    }, []);


    const antIcon = (
        <LoadingOutlined
            style={{
                fontSize: 30,
                color: '#00FF00',
            }}
            spin
        />
    );

    return (
        <>
        <div ref={ref} id='Social Buzz'>
        <DetailTitle title={'Social Buzz'}/>
        </div>
            {nowLoading ? antIcon :
                <>
                    <SocialWordCloud selectedWordCloud={selectedWordCloud} />
                    <LogoLabel name={'Tiktok'} logo={tiktokLogoWhite} />
                    <RelatedTheme themeData={themeData} />
                    {/* <div className='Border'> */}
                    <VideoComponent videoData={tiktokMentions} trendingDish={themeData.trendingDish} />
                    {/* </div> */}
                    <LogoLabel name={'Meta'} logo={metaLogoWhite} />
                    <div className='mt-40'><MetaPostComponent videoData={metaPosts} trendingDish={themeData.trendingDish} /></div>
                </>
            }
        </>
    )
})
export default SocialBuzz;