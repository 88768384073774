import React from 'react';
import style from './chart.module.scss';
import ReactEcharts from 'echarts-for-react';
import options from './chartAll.jsx';
import NoData from './noData.jsx';


const chartDetail = ({ data }) => {
  return <>
    <div className={style.chartDetailContent}>
      <div className={style.chartDetailTop}>

        <div className={style.chartDetailTopLeft}>

          <div className={style.chartDetailTopLeftTitleBox}>
            <div style={{ color: '#00a22e' }}>Reach</div>
            <ReactEcharts style={{ height: '168px', width: '100%', paddingBottom: '15px' }}
                          option={options.optionC({}, '#00a22e')} />
          </div>

          <div className={style.chartDetailTopLeftTitleBox}>
            <div style={{ color: '#fdba38' }}>Importance</div>
            <ReactEcharts style={{ height: '168px', width: '100%', paddingBottom: '15px' }}
                          option={options.optionC({}, '#fdba38')} />
          </div>

          <div className={style.chartDetailTopLeftTitleBox}>
            <div className={style.chartDetailDoubleRowTitle}>
              <span>Willingness</span>
              <span>to pay</span>
            </div>
            <ReactEcharts style={{ height: '168px', width: '100%', paddingBottom: '15px' }}
                          option={options.optionC({}, '#d90000')} />
          </div>
        </div>


        <div className={style.chartDetailTopLeftTitleBox} style={{ width: '20%' }}>
          <div style={{ color: '#67aad9' }}>Needstate</div>
          <ReactEcharts style={{ height: '170px', width: '100%' }}
                        option={options.optionD({}, [103, 170, 217])} />
        </div>

        <div className={style.chartDetailTopLeftTitleBox} style={{ width: '30%' }}>
          <div style={{ color: 'rgba(125,192,165)' }}>Generation</div>
          <ReactEcharts style={{ height: '170px', width: '100%' }}
                        option={options.optionD({}, [125, 192, 165])} />
        </div>

      </div>
      <div className={style.chartDetailTop}>

        <div className={style.chartDetailTopLeftTitleBox} style={{ width: '41%' }}>
          <div style={{ color: 'rgba(196,87,103)' }}>Lifestage</div>
          <ReactEcharts style={{ height: '170px', width: '100%' }}
                        option={options.optionD({}, [196, 87, 103])} />
        </div>

        <div className={style.chartDetailTopLeftTitleBox} style={{ width: '56%' }}>
          <div style={{ color: 'rgb(72,159,64)' }}>Product Usage</div>
          <ReactEcharts style={{ height: '170px', width: '100%' }}
                        option={options.optionD({}, [72, 159, 64])} />
        </div>

      </div>
    </div>
  </>;

};


export default chartDetail;