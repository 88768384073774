import {
  ArrowLeftOutlined,
  ArrowRightOutlined,
  ExportOutlined,
} from '@ant-design/icons';
import { message } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import Box from '../../components/box.jsx';
import {
  PackageAiBtn2 as Btn2,
  PackageAiBtn4 as Btn4,
  PackageAiBtn5 as Btn5,
} from '../../components/btn.jsx';
import Container from '../../components/container.jsx';
import Stage from '../../components/stage.jsx';
import Appeal from './appeal.jsx';
import Brand from './brand.jsx';
import Brand2 from './brand2.jsx';
import style from './index.module.scss';
import Shelf from './shelf.jsx';
import Variant from './variant.jsx';

const TITLES = [
  'Attract: Shelf Visibility Score',
  'Attract: Brand Visibility',
  'Attract: Brand-Variant Balance',
  'Attract: Visual Hierarchy',
  'Engage: Aesthetic Appeal',
];

function PackageAiView() {
  const [step, setStep] = useState(0);
  const title = useMemo(() => {
    return TITLES[step] ?? '';
  }, [step]);
  function onNext() {
    setStep((s) => {
      if (step >= TITLES.length - 1) return 0;
      return s + 1;
    });
  }
  function onBack() {
    setStep((s) => {
      if (step <= 0) {
        window.history.back();
        return 0;
      }
      return s - 1;
    });
  }
  function useNextBtn({ className = '' } = {}) {
    return (
      <div className={`${style.btnBox} ${className}`}>
        <Btn5
          className={style.btn2}
          prefix={<ArrowLeftOutlined />}
          onClick={onBack}
        >
          BACK
        </Btn5>
        <Btn2
          className={style.btn}
          suffix={<ArrowRightOutlined />}
          onClick={onNext}
        >
          NEXT
        </Btn2>
      </div>
    );
  }

  function onExport() {
    window.print();
  }

  const [ctx, setCtx] = useState({});
  function init() {
    // use context
    const ctxs = sessionStorage.getItem('packageAiContext');
    if (!ctxs) {
      message.error('Please fill in the basic information first');
      window.open('/package-ai-screening-basic', '_self');
      return null;
    }
    const c = JSON.parse(ctxs);
    setCtx(c);

    // use query
    const query = new URLSearchParams(window.location.search);
    const index = query.get('index');
    if (index) {
      setStep(parseInt(index));
    }
  }
  useEffect(init, []);

  const body = useMemo(() => {
    if (step === 0) return <Shelf useNextBtn={useNextBtn} context={ctx} />;
    else if (step === 1) return <Brand useNextBtn={useNextBtn} context={ctx} />;
    else if (step === 2) {
      return <Variant useNextBtn={useNextBtn} context={ctx} />;
    } else if (step === 4) {
      return <Appeal useNextBtn={useNextBtn} context={ctx} />;
    } else if (step === 3) {
      return <Brand2 useNextBtn={useNextBtn} context={ctx} />;
    }
    return null;
  }, [step, ctx]);

  return (
    <Container>
      <div className={style.containerInner}>
        <Stage stage={4} />
        <Box
          title={
            <div className={style.boxHeaderInner}>
              <div>
                <ArrowLeftOutlined
                  className={style.boxTitleBack}
                  onClick={onBack}
                />
                {title}
              </div>
              <Btn4 className={style.boxHeaderExport} onClick={onExport}>
                <ExportOutlined className={style.boxHeaderBtnIcon} />
                Export
              </Btn4>
            </div>
          }
          className={style.box}
        >
          <div className={style.boxInner}>{body}</div>
        </Box>
      </div>
    </Container>
  );
}

export default PackageAiView;
