import React, { useEffect, useState } from 'react';
import {
  noImageUploaded,
  shareIcon,
} from '../../../../../components/icons/icons.jsx';
import api from '../..//../../../util/api.jsx';
import { apiURL } from '../../../../../env-url.js';
import ConsumerInnovationPopup from './consumer-trend-popup.jsx';
import Loader from '../../../../../components/loader/loader.jsx';
import ShareModal from '../../../../../components/share-modal/share-modal.jsx';
import ErrorModal from '../../../../../components/error-modal/error-modal.jsx';
import SimplePagination from '../../../../../components/antd-pagination/antd-pagination.jsx';
import { useFilters } from '../../../hooks/useFilters.jsx';

function ConsumerTrendsCard({ consumerTrendsCategory, selectedCountry }) {
  const [showMessage, setShowMessage] = useState(false);
  const [trends, setTrends] = useState([]);
  const [selectedCardURL, setSelectedCardURL] = useState('');
  const [, setOpenModals] = useState({});
  const [idValue, setIdValue] = useState('');
  const [openReadMorePopup, setOpenReadMorePopup] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize] = useState(20);
  const [totalCount, setTotalCount] = useState();
  const { filters } = useFilters();
  let { brands, subcategories, markets, startDate, endDate } = filters;
  const fetchData = () => {
    setLoading(true);
    return api
      .get(`${apiURL}/trends/mintel/consumerTrendDetails`, {
        params: {
          country: selectedCountry,
          consumerTrend: consumerTrendsCategory,
          brands: brands,
          subcategories: subcategories,
          markets: markets,
          startDate: startDate,
          endDate: endDate,
          pageNumber: pageNumber,
          pageSize: pageSize,
        },
      })
      .then((response) => {
        setTrends(response.data);
        setPageNumber(response.pageMeta.pageNumber);
        setTotalCount(response.pageMeta.totalCount);
        // setPageSize(response.pageMeta.pageSize);
        setLoading(false);
        const initialOpenModals = {};
        response.data.forEach((item) => {
          initialOpenModals[item.recordId] = false;
        });
        setOpenModals(initialOpenModals);
        if (response.data.length === 0) {
          setShowErrorModal(!showErrorModal);
        }
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  };

  useEffect(() => {
    fetchData();
  }, [pageNumber, filters]);

  const handleShare = (cardURL, cardId) => {
    setSelectedCardURL(cardURL);
    setOpenModals((prevState) => ({
      ...prevState,
      [cardId]: true,
    }));
    setShowMessage(!showMessage);
  };

  const handleReadMore = (id) => {
    setIdValue(id);
    setOpenReadMorePopup(!openReadMorePopup);
  };

  const handlePageChange = (page, pageSize) => {
    setPageNumber(page);
    console.log(`Page ${page}, Size ${pageSize}`);
  };

  const closeConsumerInnovation = () => {
    setOpenReadMorePopup(false);
  };

  const reversedTrends = [...trends].reverse();

  return isLoading ? ( //Checkif if is loading
    <Loader />
  ) : (
    <>
      <div className="containerwrapper-innovation mt-10">
        {reversedTrends.map((item, i) => (
          <div className="cardwrapper" key={i}>
            <div className="card-container" tabIndex={0}>
              <div className="card" tabIndex={0}>
                <img
                  src={item.imageLink ? item.imageLink : noImageUploaded}
                  alt="mediaLinks"
                  onClick={() => handleReadMore(i)}
                />
              </div>
              <div className="expandable">
                <ul className="card-icon-wrapper">
                  {/* <li>
                    <FavouriteButton item={item} id={item.recordId} />
                  </li> */}
                  <li>
                    <img
                      src={shareIcon}
                      alt="share link"
                      onClick={() => handleShare(item.imageLink, item.recordId)}
                    />
                  </li>
                </ul>
                <div className="card-Items">
                  <div className="consumer">
                    <span className="consumer-title">Product :</span>{' '}
                    {item.product}
                  </div>
                  <div className="consumer">
                    <span className="consumer-title">Date :</span>{' '}
                    {item.updatedTimestamp}
                  </div>
                  <div className="consumer">
                    <span className="consumer-title">Category:</span>{' '}
                    {item.subCategory}
                  </div>
                  <div className="consumer">
                    <span className="consumer-title">Brand:</span> {item.brand}
                  </div>
                </div>
                <div className="expandable">
                  <div className="card-dscp mt-10 mb-10">
                    {item.productDescription}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
        {openReadMorePopup && (
          <ConsumerInnovationPopup
            data={trends}
            handleClose={closeConsumerInnovation}
            id={idValue}
            fetchData={fetchData}
            consumerTrendsCategory={consumerTrendsCategory}
          />
        )}
        {showErrorModal && (
          <ErrorModal
            showErrorModal={showErrorModal}
            setShowErrorModal={setShowErrorModal}
          />
        )}
      </div>
      <SimplePagination
        current={pageNumber}
        pageSize={pageSize}
        total={totalCount}
        handlePageChange={handlePageChange}
      />
      {showMessage ? <ShareModal cardURL={selectedCardURL} /> : null}
    </>
  );
}

export default ConsumerTrendsCard;
