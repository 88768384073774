import axios from 'axios';
import React from 'react';
import { msalInstance } from '@/index.js';
import { login as loginJwt } from '@/modules/concept-ai/api/request.js';
import { getUser } from '../modules/home/api/request.js';
import { message } from 'antd';
import { InteractionRequiredAuthError } from '@azure/msal-browser';
import SignOutMessage from '../components/profile/logout-page/logout-page.jsx';

export async function getToken() {
  // check if aad-token exists
  const aadToken = sessionStorage.getItem('aad-token');
  const jwtToken = sessionStorage.getItem('jwt-access-token');
  if (aadToken && jwtToken) {
    return jwtToken;
  }
  // get aad authentation
  const request = {
    scopes: ['User.Read'],
  };
  const currentAccount = msalInstance.getActiveAccount();

  if (!currentAccount) {
    console.log('msal authentication has not completed.');
    return jwtToken;
  }
  // Acquire graph access token for "graph.microsoft.com/User.Read"
  if (!process.env.IGNORE_MFA || process.env.IGNORE_MFA === 'false') {
    const msgraphTokenResponse = await msalInstance
      .acquireTokenSilent(request)
      .catch((error) => {
        console.log(error);
        window.localStorage.clear();
        window.sessionStorage.clear();
        if (error instanceof InteractionRequiredAuthError) {
          // fallback to interaction when silent call fails
          msalInstance.loginRedirect(request);
        }
      });
    sessionStorage.setItem('aad-token', msgraphTokenResponse.accessToken);
    // jwt token
    const jwtResponse = await loginJwt({
      username: currentAccount.username,
      adalToken: msgraphTokenResponse.accessToken,
    }).catch((err) => console.log(err));
    sessionStorage.setItem('jwt-access-token', jwtResponse.access_token);

    // get User information put it into session Storage
    getUser()
      .then((res) => sessionStorage.setItem('user', JSON.stringify(res.data)))
      .catch((error) => console.error(error));
    return jwtResponse.access_token;
  } else {
    return jwtToken;
  }
}

const logoutUser = () => {
  window.localStorage.clear();
  window.sessionStorage.clear();
  window.location.reload();
};

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  paramsSerializer:{
    indexes: null
  }
});

api.interceptors.request.use(
  async function (req) {
    try {
      const jwt_token = await getToken();
      req.headers.Authorization = `Bearer ${jwt_token}`;
      return req;
    } catch (error) {
      if (error.response && error.response.status === 401) {
        setTimeout(() => logoutUser(), 3000);
      }
      return Promise.reject(error);
    }
  },
  function (error) {
    if (error.response && error.response.status === 401) {
      <SignOutMessage />;
    }
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  async (response) => {
    const pagination = response.headers['x-pagination'];
    if (pagination) {
      const parsed = JSON.parse(pagination);
      let metaData = {
        pageNumber: parsed.PageNumber,
        pageSize: parsed.PageSize,
        totalPages: parsed.TotalPages,
        totalCount: parsed.TotalCount,
        hasNext: parsed.HasNext,
        hasPrevious: parsed.hasPrevious,
      };
      response.pageMeta = metaData;
      return response;
    }
    return response;
  },
  function (error) {
    if (error.response && error.response.status === 401) {
      setTimeout(() => logoutUser(), 3000);
    }
    return Promise.reject(error.response);
  }
);
export default api;
