import React, { useState, useImperativeHandle, forwardRef } from 'react';
import { Modal } from 'antd';
import BorderContainer from '../border-container/border-container.jsx';
import style from './video-player.module.scss';
import { cancelGreenIcon } from '../../../../components/icons/icons.jsx';
import { LeftOutlined } from '@ant-design/icons';

let VideoPlayer = (_, ref) => {

  const [videoData, setVideoData] = useState(null);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  useImperativeHandle(ref, () => ({
    open: (videoData) => {
      setVideoData(videoData);
      setIsModalOpen(true);
    },
    close: () => {
      setIsModalOpen(false);
    },
  }));

  const viewRender = () => {
    if (videoData?.videoUri) {
      return (
        <iframe
          className={style.iframe}
          src={`https://www.tiktok.com/embed/${videoData.videoId}`}
          title="Video Player"
          allowFullScreen
          scrolling="no"
          allow="encrypted-media"
        ></iframe>
      );
    }
    return 'No Data';
  };

  return (
    <Modal
      title="" 
      wrapClassName={style.myDialog}
      width={1100} 
      centered 
      destroyOnClose
      footer={null} 
      open={isModalOpen}
      onCancel={handleCancel}
      closeIcon={(
        <div className={style.closeBtn}>
          <img src={cancelGreenIcon} alt="" />
        </div>
      )}
    >
      <div className={style.dialogBox}>
        <div className={style.backBtn} onClick={() => setIsModalOpen(false)}>
          <LeftOutlined /> {videoData?.videoTitle}
        </div>
        <BorderContainer>
          <div className={style.dialogMain}>
            <div className={style.videoBoxResize}>
              <div className={style.videoBox}>
                {viewRender()}
              </div>
            </div>
            <div className={style.labelsContainer}>
              {videoData?.hashTags.map(item => {
                return (
                  <div key={item} className={style.labelItem}>#{item?.replace(/"/g, '')}</div>
                );
              })}
            </div>
          </div>
        </BorderContainer>
      </div>
    </Modal>
  );
};

VideoPlayer = forwardRef(VideoPlayer);

export default VideoPlayer;