import React from 'react';
import { ConfigProvider, Input, Select } from 'antd';
import noDataImg from '@/modules/pain-points/img/noData.png';

const noData = ({ text }) => {

  return <>
    <div style={{fontSize:'1vw',fontWeight: 'bold',margin:'0 0 15px 10px'}}>
      {text}
    </div>
  </>
}
// title: {
//   text: 'Top 10 Unmet Needs',
//   left: '2%',
//   top: '0',
//   textStyle: {
//     color: '#FFF',
//   },
// },


export default noData;