// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.A8jBcAjRFoTasN1QV2wq{height:100%}.A8jBcAjRFoTasN1QV2wq .ant-spin-nested-loading,.A8jBcAjRFoTasN1QV2wq .ant-spin-container{height:100%}.A8jBcAjRFoTasN1QV2wq .echarts-for-react{height:calc(100% + 140px) !important;position:relative !important;transform:translateY(-140px)}`, "",{"version":3,"sources":["webpack://./src/modules/consumer-theater/components/global-map/global-map.module.scss"],"names":[],"mappings":"AAAA,sBACE,WAAA,CAEE,yFACE,WAAA,CAEF,yCACE,oCAAA,CACA,4BAAA,CACA,4BAAA","sourcesContent":[".myEchartsMap {\n  height: 100%;\n  :global {\n    .ant-spin-nested-loading, .ant-spin-container {\n      height: 100%;\n    }\n    .echarts-for-react {\n      height: calc(100% + 140px) !important;\n      position: relative !important;\n      transform: translateY(-140px);\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"myEchartsMap": `A8jBcAjRFoTasN1QV2wq`
};
export default ___CSS_LOADER_EXPORT___;
