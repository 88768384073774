// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fxUQ5LcYJd1ZGyaSyIvn{padding:2.75px 5px;background-color:#eef4f0;color:#00a32e;border-radius:14px;display:flex;align-items:center}.fxUQ5LcYJd1ZGyaSyIvn img{width:17px;height:17px}.fxUQ5LcYJd1ZGyaSyIvn .ant-select-selection-item{color:#00a32e}.Cj8I08kHFAleHPfaT_Ae{padding-left:20px}`, "",{"version":3,"sources":["webpack://./src/modules/knorr/components/select.module.scss"],"names":[],"mappings":"AAAA,sBACE,kBAAA,CACA,wBAAA,CACA,aAAA,CACA,kBAAA,CACA,YAAA,CACA,kBAAA,CACA,0BACE,UAAA,CACA,WAAA,CAGA,iDACE,aAAA,CAKN,sBACE,iBAAA","sourcesContent":[".select {\n  padding: 2.75px 5px;\n  background-color: #eef4f0;\n  color: #00a32e;\n  border-radius: 14px;\n  display: flex;\n  align-items: center;\n  img {\n    width: 17px;\n    height: 17px;\n  }\n  :global {\n    .ant-select-selection-item {\n      color: #00a32e;\n    }\n  }\n}\n\n.selectHasPreIcon {\n  padding-left: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"select": `fxUQ5LcYJd1ZGyaSyIvn`,
	"selectHasPreIcon": `Cj8I08kHFAleHPfaT_Ae`
};
export default ___CSS_LOADER_EXPORT___;
