import { configureStore } from '@reduxjs/toolkit';
import loginReducer from './loginSlice';
import { loadUser, saveUser } from './sessionStorage';

const persistedState = loadUser();

const store = configureStore({
  reducer: {
    login: loginReducer
  },
}, persistedState);

store.subscribe(()=>{
  saveUser(store.getState().login.user);
});

export default store;
