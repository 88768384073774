import React, { useState, useEffect, forwardRef, useRef } from 'react';
import BorderContainer from '../border-container/border-container.jsx';
import style from './top-dish-deep-dive.module.scss';
import classNames from 'classnames';
import { triangleRight, exportButton, cancelButton } from '../../../../components/icons/icons.jsx';
import PastaKnorrBackground from '../../../../assets/images/ai-top-dish/pasta.png';
import GrowthTrend from '../growth-post/growth-trend.jsx';  // Import GrowthTrend here

let TopDishDeepDive = ({ onClose, countries, brand, topDish, trendingDish, trendingDishId }, ref) => {
  const [activeTab, setActiveTab] = useState(null);
  const [title, setTitle] = useState('');
  const sectionsRef = useRef([]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const tabIndex = entry.target.dataset.tabIndex;
            setActiveTab(Number(tabIndex));
          }
        });
      },
      { threshold: 0.5 }
    );

    sectionsRef.current.forEach((section) => observer.observe(section));

    return () => observer.disconnect();
  }, []);

  const tabClick = (tabIndex) => {
    setActiveTab(tabIndex);
    sectionsRef.current[tabIndex]?.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <div className={`${style.TopDishDeepDivePage} TopdishVariant`}>
      <BorderContainer hideLeftCorner headerTitle="Top Dish Variant Deep Dive">
        <header className={style.header}>
          <button className={style.exportButton}>  
            <img src={exportButton} alt="Export" />
            Export
          </button>
          <img
            src={cancelButton}
            className="close"
            onClick={onClose}
            alt="Close"
          />
        </header>
        <div className={style.deepDiveMainContent}>
          <div className={style.mainMenuBox}>
            <div
              className={classNames(style.menuItem, {
                [style.active]: activeTab === 1,
              })}
              onClick={() => tabClick(1)}
            >
              {activeTab === 1 && (
                <div className={style.arrow}>
                  <img src={triangleRight} alt="Active Tab Arrow" />
                </div>
              )}
              Trend summary
            </div>
            <div
              className={classNames(style.menuItem, {
                [style.active]: activeTab === 2,
              })}
              onClick={() => tabClick(2)}
            >
              {activeTab === 2 && (
                <div className={style.arrow}>
                  <img src={triangleRight} alt="Active Tab Arrow" />
                </div>
              )}
              Growth Trend
            </div>
            <div
              className={classNames(style.menuItem, {
                [style.active]: activeTab === 3,
              })}
              onClick={() => tabClick(3)}
            >
              {activeTab === 3 && (
                <div className={style.arrow}>
                  <img src={triangleRight} alt="Active Tab Arrow" />
                </div>
              )}
              Social Buzz
            </div>
          </div>
          <div className={style.lineBox}></div>

          <div className={`${style.contentBox} scrollable`}>
            {/* Sections */}
            <div className={style.informationBox}>
              <div
                className={style.headerTitle}
                ref={(el) => (sectionsRef.current[1] = el)}
                data-tab-index="1"
              >
                <div className={style.titleBox}>
                  <div className={style.arrow}>
                    <img src={triangleRight} alt="" />
                  </div>
                  <div className={style.titleInner}>{trendingDish}</div>
                </div>
              </div>
              <div className={style.descBox}>
                <p>This text should be a brief description of trend movement e.g. Fried Chicken Tikka has a trend index of 44 this month, an increase of 4 vs. last month. With an Instagram share of 6.58%, it has increased by 4.87% in social discussion in the past month.
                </p>
                <img src={PastaKnorrBackground} className={'mt-20 imageContainer'} alt="Pasta Knorr" />
              </div>
            </div>

            {/* Growth Trend Section */}
            <div
              className={style.informationBox}
              ref={(el) => (sectionsRef.current[2] = el)}
              data-tab-index="2"
            >
              <div className={style.headerTitle}>
                <div className={style.titleBox}>
                  <div className={style.arrow}>
                    <img src={triangleRight} alt="" />
                  </div>
                  <div className={style.titleInner}>Growth Trend</div>
                </div>
              </div>
              <div className={style.descBox}>
                <p>Social buzz Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.</p>
                <img src={PastaKnorrBackground} className={'mt-20 imageContainer'} alt="Pasta Knorr" />
                {/* <GrowthTrend
                  countries={countries}
                  brand={brand}
                  topDish={topDish}
                  trendingDish = {trendingDish}
                  trendingDishId={trendingDishId}
                /> */}
              </div>
            </div>

            {/* Social Buzz Section */}
            <div
              className={style.informationBox}
              ref={(el) => (sectionsRef.current[3] = el)}
              data-tab-index="3"
            >
              <div className={style.headerTitle}>
                <div className={style.titleBox}>
                  <div className={style.arrow}>
                    <img src={triangleRight} alt="" />
                  </div>
                  <div className={style.titleInner}>Social Buzz</div>
                </div>
              </div>
              <div className={style.descBox}>
                <p>Social buzz Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.</p>
                <img src={PastaKnorrBackground} className={'mt-20 imageContainer'} alt="Pasta Knorr" />
              </div>
            </div>
          </div>
        </div>
      </BorderContainer>
    </div>
  );
};

TopDishDeepDive = forwardRef(TopDishDeepDive);

export default TopDishDeepDive;
