import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Mindfulprotein from '../../../../assets/images/Emerging-food-solution/mindfulprotein/mindfulprotein.jpg';
import FoodSliderComponent from './food-slider.jsx';
import FoodServiceComponent from './irr-food-service.jsx';
import IrrSocial from './irr-social.jsx';
import { leftLineIcon } from '../../../../components/icons/icons.jsx';
import FutureMenuPdf from '../../../../assets/pdf/Future-Menus-Report-2023.pdf';

function MindfulProteins() {
  const [showSlider, setShowSlider] = useState(true);
  const [showSliderservice, setShowSliderservice] = useState(true);
  const [showSlidersocial, setShowSlidersocial] = useState(true);
  const handleSlider = () => {
    setShowSlider(!showSlider);
  };
  const handleSliderservice = () => {
    setShowSliderservice(!showSliderservice);
  };
  const handleSlidersocial = () => {
    setShowSlidersocial(!showSlidersocial);
  };
  const handleModal = () => {
    setShowSlider(!showSlider);
  };
  const handleModalService = () => {
    setShowSliderservice(!showSliderservice);
  };
  const handleModalSocial = () => {
    setShowSlidersocial(!showSlidersocial);
  };
  const handleCloseSlider = () => {
    setShowSlider(true);
  };
  const handleCloseSliderservice = () => {
    setShowSliderservice(!showSliderservice);
  };
  const handleCloseSlidersocial = () => {
    setShowSlidersocial(!showSlidersocial);
  };

  const navigate = useNavigate();
  const handleNavigationMain = () => {
    navigate('/trends-dial');
  };
  return (
    <>
      <div className="trends-detail-title">
        <h2><img style={{cursor: 'pointer'}} src={leftLineIcon} onClick={handleNavigationMain} alt="left icon" /> mindful proteins</h2>
      </div>
      <div className="flex-container">
        <div className="emerging-description">
          <div>
            Dishes that mix and match alternative protein sources, potentially
            alongside small amounts of sustainable animal protein to promote
            healthfulness and sustainability. UFS’ Why:To promote personally and
            environmentally positive and sustainable habits and practices. Core
            Operator benefit: To be a creative force in the movement for greater
            balance, health and sustainability. Championing sustainable meat
            alongside protein alternatives Core Consumer motivation: “I know I
            should reduce my meat consumption but I don’t want to sacrifice on
            taste and pleasure.”
          </div>
        </div>

        <div className="emerging-image">
          <div>
            <img src={Mindfulprotein} alt="Irresistible" />
          </div>
        </div>
      </div>
      {!showSlider && (
        <FoodSliderComponent
          handleCloseSlider={handleCloseSlider}
          handleSlider={handleSlider}
        />
      )}
      {!showSliderservice && (
        <FoodServiceComponent
          handleCloseSliderservice={handleCloseSliderservice}
          handleSliderservice={handleSliderservice}
        />
      )}
      {!showSlidersocial && (
        <IrrSocial
          handleCloseSlidersocial={handleCloseSlidersocial}
          handleSlidersocial={handleSlidersocial}
        />
      )}
      <ul className="emerging-links">
        <li onClick={handleModal}>Trends Deep Dive</li>
        <li onClick={handleModalService}>Food Service Trend Scape</li>
        <li onClick={handleModalSocial}>Social Listening Analysis</li>
        <li><a href={FutureMenuPdf} target="_blank" rel="noreferrer"> Future Menu 2023</a></li>
      </ul>
    </>
  );
}

export default MindfulProteins;
