import { CloseOutlined } from '@ant-design/icons';
import React, { useMemo } from 'react';
import style from './chart.module.scss';

function ChartFullScreen({ title, count, children, onClose }) {
  const displayCount = useMemo(() => {
    return typeof count === 'number';
  }, [count]);

  return (
    <>
      <div className={style.chartFullScreen}>
        <div className={style.chartHeader}>
          <h3 className={style.chartTitle}>{title}</h3>
          <div className={style.chartHeaderRight}>
            {displayCount && (
              <div className={style.chartCountText}>
                <span>{count} SAMPLE SIZE</span>
                {count < 100 && (
                  <span className={style.chartCountTextWarning}>
                    {' '}
                    - Low Sample Size
                  </span>
                )}
              </div>
            )}
            <div className={style.chartClose} onClick={onClose}>
              <CloseOutlined />
            </div>
          </div>
        </div>
        <div className={style.chartContent}>{children}</div>
      </div>
      <div className={style.chartModal} onClick={onClose}></div>
    </>
  );
}

export default ChartFullScreen;
