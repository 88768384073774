import React, { useState } from 'react';
import classNames from 'classnames';
import style from './index.module.scss';

const btnList = ['Top 10', 'Top 30', 'All cells'];
const SwitchBtnCom = ({onChange}) => {
  const [curIndex, setCurIndex] = useState(2);

  const change = (index) => {
    setCurIndex(index);
    onChange(index === 0 ? '10' : index === 1 ? '20' : 'all')
  };

  return (
    <div className={classNames(style.btnBox)}>
      {
        btnList.map((item, index) => <div key={index} onClick={() => change(index)} className={classNames(style.btn, curIndex === index ? style.active: '')}>{item}</div>)
      }
    </div>
  );
};

export default SwitchBtnCom;