/* eslint-disable no-unused-vars */
import React from 'react';
import LeftSidebarCollapsible from '../../components/left-navigation/left-navigation.jsx';
import learningBgVideo from '../../assets/videos/GOTFLbackgroundVideo.mp4';
import Profile from '../../components/profile/profile.jsx';
import Style from './ai-top-dish.module.scss';
import MarketBrandDishFilter from './components/market-brand-dish-filter/market-brand-dish.jsx';
//import AiTopDishHeader from './components/sticky-header/sticky-header.jsx';
import { aiTopDishEngine } from '../../components/icons/icons.jsx';

const AiTopDish = () => {
  return (
    <>
      <LeftSidebarCollapsible />
      <div className='sidebar-right-layout'>
        <video autoPlay muted className='learning-bg-video' loop>
          <source src={learningBgVideo} type="video/mp4" />
        </video>

        <div className={`my-learning-container ${Style.fullContainer}`}>
          <div className="horizontal-user-profile pt-10 pr-40 pb-10">
            <h2 className={Style.titleAiToDish}>Top Dish Trend Index</h2>
            <div className='mt-10'><Profile/></div>
            <div className={Style.iconContainer}>
              <img src={aiTopDishEngine} alt="info" />
            </div>
          </div>
          {/* <AiTopDishHeader /> */}
          <div className={`pr-40 ml-40 ${Style.aiTopDishFilterApply}`}>
            <MarketBrandDishFilter />
          </div>
        </div>
      </div>
    </>
  );
};

export default AiTopDish;
