import service from '../../../../util/pythonApi';

export function postPredictAestheticsRun(data) {
  return service.request({
    url: '/v1/pred_aesthetics_run',
    method: 'post',
    data,
  });
}

export function postPredictAestheticsImg(data) {
  return service.request({
    url: '/v1/pred_aesthetics_img',
    method: 'post',
    data,
  });
}
