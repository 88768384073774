import React, { useState } from 'react';
import { OccasionCompareBtn } from '../../components/filter.jsx';
import Layout from '../../components/layout.jsx';
import OccasionPhotoContent from './content.jsx';
import OccasionPhotoFilter from './filter.jsx';
import style from './photo.module.scss';

function OccasionPhoto() {
  const startCategory = new URL(window.location).searchParams.get('category');
  const [filter, setFilter] = useState(
    startCategory ? { booster_project: [startCategory] } : {}
  );

  return (
    <Layout>
      <div className={style.photoContainer}>
        <OccasionPhotoFilter
          setFilterData={setFilter}
          hasCompare={false}
          startCategory={startCategory}
        />
        <a href="/occasion-photo-compare">
          <OccasionCompareBtn display={false} />
        </a>
        <OccasionPhotoContent filter={filter} />
      </div>
    </Layout>
  );
}

export default OccasionPhoto;
