import { Select } from 'antd';
import React, { useCallback, useMemo } from 'react';
import TriangleSvg from '../../assets/img/triangle.svg';
import OptionsJson from '../../assets/json/options.json';
import style from './filter.module.scss';

function Item({ value, setValue, label, items }) {
  return (
    <div className={style.filterItem}>
      <div className={style.filterLabel}>{label}</div>
      <Select
        className={style.filterSelect}
        options={items}
        value={value}
        onChange={setValue}
        placeholder="Select"
        mode="multiple"
      />
    </div>
  );
}

function PackageAiBaseFilter({ filter, setFilter }) {
  const categoryValue = useMemo(() => {
    return filter.category;
  }, [filter]);
  const setCategoryValue = useCallback(
    (val) => {
      setFilter((prev) => {
        if (!prev) return { category: val };
        else {
          return {
            ...prev,
            category: val,
            brand: undefined,
          };
        }
      });
    },
    [setFilter]
  );

  const brandOptions = useMemo(() => {
    if (!categoryValue) return [];
    const os = OptionsJson.filter((e) => categoryValue.includes(e.value));
    let bos = [];
    os.forEach((e) => {
      bos = bos.concat(e.children);
    });
    return bos;
  }, [categoryValue]);
  const brandValue = useMemo(() => {
    return filter.brand;
  }, [filter]);
  const setBrandValue = useCallback(
    (val) => {
      setFilter((prev) => {
        if (!prev) return { brand: val };
        else {
          return {
            ...prev,
            brand: val,
          };
        }
      });
    },
    [setFilter]
  );

  return (
    <div className={style.filterBox}>
      <img className={style.filterIcon} src={TriangleSvg} alt="filter" />
      <div className={style.filterBody}>
        <h2>Filter</h2>
        <Item
          value={categoryValue}
          setValue={setCategoryValue}
          label="Vertical"
          items={OptionsJson}
        />
        <Item
          value={brandValue}
          setValue={setBrandValue}
          label="Brand"
          items={brandOptions}
        />
      </div>
    </div>
  );
}

export default PackageAiBaseFilter;
