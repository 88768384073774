import { LoadingOutlined } from '@ant-design/icons';
import React from 'react';
import style from './loading.module.scss';

function PackageAiLoading({ className = '' }) {
  return (
    <div className={`${style.loadingBox} ${className}`}>
      <div className={style.loadingIcon}>
        <LoadingOutlined />
      </div>
    </div>
  );
}

export default PackageAiLoading;
