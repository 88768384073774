import React from 'react';
import classNames from 'classnames';
import style from './index.module.scss';
import downloadSvg from '../../../../assets/images/gotfl-weena/download.svg';
import deleteSvg from '../../../../assets/images/gotfl-weena/icn_delete.svg';
import editSvg from '../../../../assets/images/gotfl-weena/icn_edit.svg';
import showDetailSvg from '../../../../assets/images/gotfl-weena/show_detail.svg';
import previewSvg from '../../../../assets/images/gotfl-weena/preview_icon.svg';
import { downloadFile } from '../../hooks/utils';
import { useRoot } from '../../hooks/useRoot.jsx';

const FileCard = ({width, onPreview, detail, onDelete, onEdit, isDownload = true, isDoc}) => {
  const { isGotflWeenaAdmin } = useRoot();
  return <div className={classNames(style.fileCardWrap)} style={{width: width ? width : '160px'}}>
    <img src={detail?.thumbUrl} className={classNames(style.imgBg)} onClick={onPreview}></img>
    {
      !isDoc && <img src={previewSvg} className={style.previewIcon} onClick={onPreview}></img>
    }
    {
      isGotflWeenaAdmin && <div className={classNames(style.ctrlBox)}>
        <img src={editSvg} onClick={onEdit} />
        <img src={deleteSvg} onClick={onDelete}/>
      </div>
    }
    <div className={classNames(style.bottomBox)}>
      <div className={classNames(style.infoBox)}>
        <div>{detail?.name}</div>
        <span>{(+detail?.size).toFixed(2)}M</span>
      </div>
      <img src={isDownload ?  downloadSvg : showDetailSvg} className={classNames(style.downladIcon)} onClick={ isDownload ? () => downloadFile(detail) : onPreview}/>
    </div>
  </div>;
};

export default FileCard;