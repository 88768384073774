import React, { useRef, useState, forwardRef } from 'react';
import './trend-summary.scss';
import DetailTitle from '../detail-title/detail-title.jsx'
import { putFileKey } from '../../api/request.js';


const TrendSummary = forwardRef(({ trendingDishData }, ref) => {
    console.log('trendingDishData', trendingDishData)
    const dishData = trendingDishData
    const getIncreaseStr = (e) => {
        if (e >= 0) {
            return `increase of ${e}`
        } else {
            return `decrease of ${e}`
        }
    }

const increasedBy = (e)=>{
    if (e >= 0) {
        return `increased by ${e}%`
    } else {
        return `decreased by ${e}%`
    }
}
const text = `${dishData.detail.top_dish_variant} has a trend index of ${dishData.detail.trend_index} this month, an ${getIncreaseStr(dishData.detail.trend_index_variation)} vs. last month. With an Instagram share of ${dishData.detail.top_dish_variant_share_meta}%, it has ${increasedBy(dishData.detail.relevance_score_variation)} in social discussion in the past month.`
const [imgUrl, setImgUrl] = useState(null)

const getImgUrl = async (e) => {
    let countries = dishData.countries[0]
    if (dishData.countries[0] == "BR") {
        countries = 'Brazil'
    } else if (dishData.countries[0] == "ID") {
        countries = 'Indonesia'
    } else if (dishData.countries[0] == "IN") {
        countries = 'India'
    }
    const imgName = `/${countries}/${dishData.brand[0]}/${dishData.topDish[0]}/${dishData.detail.top_dish_variant}.jpg`
    const { data } = await putFileKey(imgName);
    setImgUrl(data.url)
}
getImgUrl()

return (
    <>
        <div ref={ref}  id='Trend Summary'>
        <DetailTitle title={'Trend Summary'}/>
        </div>
        <div className='briefText'>{text}</div>
        <img className='briefImg' src={imgUrl} />
    </>
)
})

export default TrendSummary;