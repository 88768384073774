import React, { useState, useEffect } from 'react';
import style from './style.module.scss';
import { App, Table, Button } from 'antd';
import { ReloadOutlined, LoadingOutlined } from '@ant-design/icons';
import { fetchCategory, celeryTask } from '../../api/request.js';

const antIcon = (
  <LoadingOutlined
    style={{
      fontSize: 30,
      color: '#00FF00'
    }}
    spin
  />
);

const LayerTopicPage = () => {

  const staticFunction = App.useApp();
  const message = staticFunction.message;

  // table
  const [tableData, setTableData] = useState([]);
  const [tableLoading, setTableLoading] = useState(false);

  const columns = [
    {
      title: 'Index',
      dataIndex: 'id',
      width: '220px',
      align: 'center',
    },
    {
      title: 'title',
      dataIndex: 'title',
    }
  ];

  // 启动
  const [startLoading, setStartLoading] = useState(false);
  const startFn = () => {
    const lastTime = window.localStorage.getItem('consumer_theater_layer_topic_start_time');
    if (lastTime && lastTime > Date.now() - 600000) {
      message.warning(`Synchronization tasks can only start once in 10 minutes! Last start: ${new Date(+lastTime)}`);
      return;
    }
    setStartLoading(true);
    celeryTask({
      task_name: 'sync_category_topic_from_data'
    })
      .then(() => {
        message.success('The synchronization task is started successfully.');
        window.localStorage.setItem('consumer_theater_layer_topic_start_time', Date.now());
        setTimeout(() => {
          setStartLoading(false);
        }, 3000);
      })
      .catch(error => {
        message.error('The synchronization task fails to be started.');
        console.error(error);
        setStartLoading(false);
      });
  };

  const updateFetch = async () => {
    if (tableLoading) return;
    setTableLoading(true);
    try {
      const res = await fetchCategory();
      let uniId = 0;
      res.data.forEach((item) => {
        item.uniId = uniId;
        uniId++;
        if (item.topics) {
          item.topics.forEach((child) => {
            child.uniId = uniId;
            uniId++;
          });
        }
      });
      setTableData(res.data);
    } catch (error) {
      console.log(error);
    }
    setTableLoading(false);
  };

  useEffect(() => {
    updateFetch();
  }, []);

  const [expandedKeys, setExpandedKeys] = useState([]);
  const handleExpand = (expanded, record) => {
    const keys = expandedKeys.includes(record.uniId)
      ? expandedKeys.filter(key => key !== record.uniId)
      : [...expandedKeys, record.uniId];
    setExpandedKeys(keys);
  };

  return <div className={style.pageContainer}>
    <div className={style.pageHeader}>Layer I && II Topic</div>
    <div className={style.searchFlex} style={{justifyContent: 'flex-start'}}>
      <Button style={{marginRight: '20px'}} type="primary" shape="round" icon={<ReloadOutlined />} onClick={updateFetch}>check</Button>
      <Button type="primary" shape="round" icon={<ReloadOutlined />} loading={startLoading} onClick={startFn}>align</Button>
    </div>
    <div className={style.tableBox}>
      <Table
        rowKey="uniId"
        expandable={{
          childrenColumnName: 'topics',
          indentSize: 100,
          expandedRowKeys: expandedKeys,
          onExpand: handleExpand
        }}
        columns={columns}
        dataSource={tableData}
        loading={{spinning: tableLoading, indicator: antIcon}}
        pagination={false}
      />
    </div>
  </div>;
};

export default LayerTopicPage;