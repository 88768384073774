import { LoadingOutlined } from '@ant-design/icons';
import React from 'react';
import style from './btn.module.scss';

function KnorrBtn({ children, icon, className, onClick, loading, type }) {
  return (
    <div
      className={`${style.btn} ${type === 'primary' ? style.btnPrimary : ''} ${
        className ?? ''
      }`}
      onClick={onClick}
    >
      {loading ? <LoadingOutlined /> : icon}
      <span className={style.btnText}>{children}</span>
    </div>
  );
}

export default KnorrBtn;
