import classNames from 'classnames';
import React, { useCallback, useEffect, useState } from 'react';
import { getProduct } from '../../api/index.js';
import Table from '../table/table.jsx';
import style from './perceived.module.scss';

function Perceived({ className, filterData, subFilterData }) {
  // loading
  const [isLoading, setIsLoading] = useState(true);

  // sort
  const [sortKey, setSortKey] = useState('taste');
  const [sort, setSort] = useState('desc');
  const changSort = (key) => {
    if (sortKey === key) {
      setSort(sort === 'desc' ? 'asc' : 'desc');
    } else {
      setSort('desc');
      setSortKey(key);
    }
  };

  // 本地排序
  const sortData = (array, key, order) => {
    return array.sort((a, b) => {
      if (a[key] < b[key]) {
        return order === 'asc' ? -1 : 1;
      }
      if (a[key] > b[key]) {
        return order === 'asc' ? 1 : -1;
      }
      return 0;
    });
  };

  // count avg
  const countAvg = (data) => {
    const denominator = data.length;
    let taste = 0;
    let premium = 0;
    let natural = 0;
    let value = 0;
    let sustainable = 0;
    let modernity = 0;
    let excitement = 0;
    let desirability = 0;

    let tasteAvg = 0;
    let premiumAvg = 0;
    let naturalAvg = 0;
    let valueAvg = 0;
    let sustainableAvg = 0;
    let modernityAvg = 0;
    let excitementAvg = 0;
    let desirabilityAvg = 0;

    if (denominator > 0) {
      for (const item of data) {
        taste += item.taste;
        premium += item.premium;
        natural += item.natural;
        value += item.value;
        sustainable += item.sustainable;
        modernity += item.modernity;
        excitement += item.excitement;
        desirability += item.desirability;
      }
      tasteAvg = parseFloat((taste / denominator).toFixed(2));
      premiumAvg = parseFloat((premium / denominator).toFixed(2));
      naturalAvg = parseFloat((natural / denominator).toFixed(2));
      valueAvg = parseFloat((value / denominator).toFixed(2));
      sustainableAvg = parseFloat((sustainable / denominator).toFixed(2));
      modernityAvg = parseFloat((modernity / denominator).toFixed(2));
      excitementAvg = parseFloat((excitement / denominator).toFixed(2));
      desirabilityAvg = parseFloat((desirability / denominator).toFixed(2));
    }
    setHeaderData((prev) => {
      return prev.map((item) => {
        if (item.key === 'taste') {
          item.value = tasteAvg;
        }
        if (item.key === 'premium') {
          item.value = premiumAvg;
        }
        if (item.key === 'natural') {
          item.value = naturalAvg;
        }
        if (item.key === 'value') {
          item.value = valueAvg;
        }
        if (item.key === 'sustainable') {
          item.value = sustainableAvg;
        }
        if (item.key === 'modernity') {
          item.value = modernityAvg;
        }
        if (item.key === 'excitement') {
          item.value = excitementAvg;
        }
        if (item.key === 'desirability') {
          item.value = desirabilityAvg;
        }
        return item;
      });
    });
  };

  // build data
  const buildData = async (data) => {
    // 计算平均值
    countAvg(data);
    setBodyData([]);

    if (data.length > 0) {
      const tmpTableData = data.map((item) => {
        return {
          id: item.sn,
          img: item.img,
          name: item.display_name,
          market: item.market,
          cover: item.cover,
          value: [
            item.taste,
            item.premium,
            item.natural,
            item.value,
            item.sustainable,
            item.modernity,
            item.excitement,
            item.desirability,
          ],
        };
      });
      setBodyData(tmpTableData); // 更新状态数据
    }
  };

  // headerData
  const [headerData, setHeaderData] = useState([
    {
      title: 'Taste',
      key: 'taste',
      tips: 'This metric measures the percentage of respondents who somewhat or strongly believe the product would have a delicious taste based on its design alone. It is derived from consumer survey data.',
      value: 0,
      isSortDesc: true,
      isSortAsc: false,
    },
    {
      title: 'High Quality',
      key: 'premium',
      tips: 'This metric measures the percentage of respondents who somewhat or strongly think that the product is of high quality based on its design alone. It is derived from consumer survey data.',
      value: 0,
      isSortDesc: false,
      isSortAsc: false,
    },
    {
      title: 'Healthy',
      key: 'natural',
      tips: 'This metric measures the percentage of respondents who somewhat or strongly think that the product is a healthy choice based on its design alone. It is derived from consumer survey data.',
      value: 0,
      isSortDesc: false,
      isSortAsc: false,
    },
    {
      title: 'Premium',
      key: 'value',
      tips: 'This metric measures the percentage of respondents who somewhat or strongly think that the product would be worth paying more for based on its design alone. It is derived from consumer survey data.',
      value: 0,
      isSortDesc: false,
      isSortAsc: false,
    },
    {
      title: 'Sustainable',
      key: 'sustainable',
      tips: 'This metric measures the percentage of respondents who somewhat or strongly think that the product is a more environmentally friendly choice based on its design alone. It is derived from consumer survey data.',
      value: 0,
      isSortDesc: false,
      isSortAsc: false,
    },
    {
      title: 'Modernity',
      key: 'modernity',
      tips: 'This metric measures the percentage of respondents who somewhat or strongly think that the product looks modern based on its design alone. It is derived from consumer survey data.',
      value: 0,
      isSortDesc: false,
      isSortAsc: false,
    },
    {
      title: 'Excitement',
      key: 'excitement',
      tips: 'This metric measures the percentage of respondents who somewhat or strongly think that the product looks exciting based on its design alone. It is derived from consumer survey data.',
      value: 0,
      isSortDesc: false,
      isSortAsc: false,
    },
    {
      title: 'Desirability',
      key: 'desirability',
      tips: 'This metric measures the percentage of respondents who somewhat or strongly think that the product looks like something that they would want to have. It is derived from consumer survey data.',
      value: 0,
      isSortDesc: false,
      isSortAsc: false,
    },
  ]);

  // bodyData
  const [bodyData, setBodyData] = useState([]);
  // const bodyData = [
  //   { id: 1, img:imgDemo1, name: 'Konrr Shellfish Fond Sauce 600g', value: [53,54,55,56] },
  // ];

  // fetch data
  const [originData, setOriginData] = useState([]);
  const fetchData = useCallback(
    async (sort, order) => {
      setIsLoading(true);
      setOriginData([]);
      const { data } = await getProduct(
        filterData,
        subFilterData,
        undefined,
        sort,
        order
      );
      setOriginData(data);
      buildData(data);
      setIsLoading(false);
    },
    [filterData, subFilterData]
  );

  useEffect(() => {
    void fetchData();
  }, [filterData, subFilterData]);

  useEffect(() => {
    const temp = [...originData];
    const newTemp = sortData(temp, sortKey, sort);
    setOriginData(newTemp);
    buildData(newTemp);
    setHeaderData((prev) => {
      return prev.map((item) => {
        if (item.key === sortKey) {
          item.isSortDesc = sort === 'desc';
          item.isSortAsc = sort === 'asc';
        } else {
          item.isSortDesc = false;
          item.isSortAsc = false;
        }
        return item;
      });
    });
  }, [sortKey, sort]);

  return (
    <div className={classNames(style.tblWrap, className)}>
      <Table
        header={headerData}
        data={bodyData}
        onAvgClick={changSort}
        loading={isLoading}
        originData={originData}
        small={true}
      />
    </div>
  );
}

export default Perceived;
