// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.kSsG3JhdAt9kjQs6HbMb{padding:20px}.kSsG3JhdAt9kjQs6HbMb .ZdEkZGK3hGuENxKv_YO9{font-size:20px;font-weight:bold}.E5SkuxQ47mlcFvbFQ_Fe{margin-top:15px;display:flex;justify-content:end;align-items:center}.E5SkuxQ47mlcFvbFQ_Fe .wgB0fHmRxsvGNhzlEBwA{margin-right:10px}`, "",{"version":3,"sources":["webpack://./src/modules/package-ai-generation/views/base/delete.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,4CACE,cAAA,CACA,gBAAA,CAIJ,sBACE,eAAA,CACA,YAAA,CACA,mBAAA,CACA,kBAAA,CACA,4CACE,iBAAA","sourcesContent":[".box1 {\n  padding: 20px;\n  .text1 {\n    font-size: 20px;\n    font-weight: bold;\n  }\n}\n\n.box12 {\n  margin-top: 15px;\n  display: flex;\n  justify-content: end;\n  align-items: center;\n  .btn {\n    margin-right: 10px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"box1": `kSsG3JhdAt9kjQs6HbMb`,
	"text1": `ZdEkZGK3hGuENxKv_YO9`,
	"box12": `E5SkuxQ47mlcFvbFQ_Fe`,
	"btn": `wgB0fHmRxsvGNhzlEBwA`
};
export default ___CSS_LOADER_EXPORT___;
