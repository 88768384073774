import React, { useMemo, useState } from 'react';
import style from './tabs.module.scss';

function KnorrTabHeaderBtn({ label, key, onClick, active }) {
  return (
    <div
      key={key}
      className={`${style.tabHeaderBtn} ${
        active ? style.tabHeaderBtnActive : ''
      }`}
      onClick={onClick}
    >
      <span>{label}</span>
    </div>
  );
}

function KnorrTabContent({ key, children, active }) {
  if (!active) {
    return;
  }
  return (
    <div className={style.tabContent} key={key}>
      {children}
    </div>
  );
}

function KnorrTabs({ items }) {
  if (!items?.length) {
    return <div></div>;
  }

  const [active, setActive] = useState(items[0].key);
  const headerBtns = items.map((e) => {
    return {
      label: e.label,
      key: e.key,
      onClick: function () {
        setActive(e.key);
      },
      active: useMemo(() => {
        return active === e.key;
      }, [active]),
    };
  });

  const contents = items.map((e) => {
    return {
      key: e.key,
      children: e.children,
      active: useMemo(() => {
        return active === e.key;
      }, [active]),
    };
  });

  return (
    <div>
      <div className={style.tabHeader}>
        <div className={style.tabHeaderInner}>
          {headerBtns.map(KnorrTabHeaderBtn)}
        </div>
      </div>
      {contents.map(KnorrTabContent)}
    </div>
  );
}

export default KnorrTabs;
