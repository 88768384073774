import { Tabs } from 'antd';
import React, { useState } from 'react';
import style from './dashboard.module.scss';
import CategoryDeepDive from './tabs/category-deep-dive.jsx';
import CookingPreparation from './tabs/cooking-preparation.jsx';
import DishesCategoriesConsiderationSets from './tabs/dishes-categories-and-ingredients-consideration-sets.jsx';
import FoodPurchase from './tabs/food-purchase.jsx';
import HabitsAttitudesAndLifestyle from './tabs/habits-attitudes-and-lifestyle.jsx';
import OccasionsAndNeedStates from './tabs/occasions-and-need-states.jsx';
import WhoWhenWhyWhereWhoWithWhere from './tabs/who-when-why-where-who-with.jsx';

function OccasionDashboardContent({
  filter,
  compare,
  hasCompare,
  className,
  exportCount,
}) {
  const startTab = new URL(window.location).searchParams.get('tab');

  const [activeKey, setActiveKey] = useState(
    startTab ?? 'occasions-and-need-states'
  );

  return (
    <Tabs
      className={`${style.dashboardContent} ${className ?? ''}`}
      tabPosition="top"
      defaultActiveKey={startTab}
      onChange={setActiveKey}
      items={[
        {
          label: <span>{'Occasions and Need States'}</span>,
          key: 'occasions-and-need-states',
          children: (
            <OccasionsAndNeedStates
              filter={filter}
              compare={compare}
              hasCompare={hasCompare}
              exportCount={exportCount}
              isActive={activeKey === 'occasions-and-need-states'}
            />
          ),
        },
        {
          label: <span>{'Who, when, why, where, with who'}</span>,
          key: 'who-when-why-where-who-with',
          children: (
            <WhoWhenWhyWhereWhoWithWhere
              filter={filter}
              compare={compare}
              hasCompare={hasCompare}
              exportCount={exportCount}
              isActive={activeKey === 'who-when-why-where-who-with'}
            />
          ),
        },
        {
          label: <span>{'Cooking/Preparation'}</span>,
          key: 'cooking-preparation',
          children: (
            <CookingPreparation
              filter={filter}
              compare={compare}
              hasCompare={hasCompare}
              exportCount={exportCount}
              isActive={activeKey === 'cooking-preparation'}
            />
          ),
        },
        {
          label: (
            <span>
              {'Dishes, categories and Ingredients \u0026 Consideration Sets'}
            </span>
          ),
          key: 'dishes-categories-and-ingredients-consideration-sets',
          children: (
            <DishesCategoriesConsiderationSets
              filter={filter}
              compare={compare}
              hasCompare={hasCompare}
              exportCount={exportCount}
              isActive={
                activeKey ===
                'dishes-categories-and-ingredients-consideration-sets'
              }
            />
          ),
        },
        {
          label: <span>{'Category Deep Dive'}</span>,
          key: 'category-deep-dive',
          children: (
            <CategoryDeepDive
              filter={filter}
              compare={compare}
              hasCompare={hasCompare}
              exportCount={exportCount}
              isActive={activeKey === 'category-deep-dive'}
            />
          ),
        },
        {
          label: <span>{'Food Purchase'}</span>,
          key: 'food-purchase',
          children: (
            <FoodPurchase
              filter={filter}
              compare={compare}
              hasCompare={hasCompare}
              exportCount={exportCount}
              isActive={activeKey === 'food-purchase'}
            />
          ),
        },
        {
          label: <span>{'Habits, Attitudes and Lifestyle'}</span>,
          key: 'habits-attitudes-and-lifestyle',
          children: (
            <HabitsAttitudesAndLifestyle
              filter={filter}
              compare={compare}
              hasCompare={hasCompare}
              exportCount={exportCount}
              isActive={activeKey === 'habits-attitudes-and-lifestyle'}
            />
          ),
        },
      ]}
    ></Tabs>
  );
}

export default OccasionDashboardContent;
