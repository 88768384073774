import React from 'react';
import style from './border-container.module.scss';
import classNames from 'classnames';

function BorderContainerDark({
  id = '',
  children,
  breath = false,
  compare = false,
}) {
  const borderBoxPath1 = 'border-box-path-1' + id;
  const borderBoxPath2 = 'border-box-path-2' + id;
  const borderBoxPath3 = 'border-box-path-3' + id;
  const borderBoxPath4 = 'border-box-path-4' + id;
  const borderBoxPath5 = 'border-box-path-5' + id;
  const borderBoxPath6 = 'border-box-path-6' + id;
  const borderBoxPath7 = 'border-box-path-7' + id;
  const borderBoxPath8 = 'border-box-path-8' + id;
  const borderBoxMask1 = 'border-box-mask-1' + id;
  const borderBoxMask2 = 'border-box-mask-2' + id;
  const borderBoxMask3 = 'border-box-mask-3' + id;
  const borderBoxMask4 = 'border-box-mask-4' + id;
  const borderBoxGradient = 'border-box-gradient' + id;

  return (
    <div className={style.lightBorderContainer}>
      <svg
        className={classNames(style.borderSvg, style.borderSvg1)}
        width="160"
        height="75"
      >
        <defs>
          <path id={borderBoxPath1} d="M2 75 L2 2" fill="transparent" />
          <path
            id={borderBoxPath2}
            d="M0 2 L160 2 L100 30"
            fill="transparent"
          />
          <radialGradient id={borderBoxGradient} cx="50%" cy="50%" r="50%">
            <stop offset="0%" stopColor="#fff" stopOpacity="1" />
            <stop offset="100%" stopColor="#fff" stopOpacity="0" />
          </radialGradient>
          <mask id={borderBoxMask1}>
            <circle
              cx="0"
              cy="0"
              r="160"
              fill={`url(#${borderBoxGradient})`}
            ></circle>
          </mask>
        </defs>
        <use
          stroke="#00FF00"
          strokeWidth="4"
          xlinkHref={`#${borderBoxPath1}`}
        ></use>
        <use
          stroke="#00FF00"
          strokeWidth="4"
          xlinkHref={`#${borderBoxPath2}`}
          mask={`url(#${borderBoxMask1})`}
        ></use>
      </svg>
      <svg
        className={classNames(style.borderSvg, style.borderSvg2)}
        width="160"
        height="75"
      >
        <defs>
          <path id={borderBoxPath3} d="M158 150 L158 2" fill="transparent" />
          <path
            id={borderBoxPath4}
            d="M0 2 L160 2 L160 30"
            fill="transparent"
          />
          <radialGradient id={borderBoxGradient} cx="50%" cy="50%" r="50%">
            <stop offset="0%" stopColor="#fff" stopOpacity="1" />
            <stop offset="100%" stopColor="#fff" stopOpacity="0" />
          </radialGradient>
          <mask id={borderBoxMask2}>
            <circle
              cx="100"
              cy="2"
              r="100"
              fill={`url(#${borderBoxGradient})`}
            ></circle>
          </mask>
        </defs>
        <use
          stroke="#00FF00"
          strokeWidth="4"
          xlinkHref={`#${borderBoxPath3}`}
        ></use>
        <use
          stroke="#00FF00"
          strokeWidth="4"
          xlinkHref={`#${borderBoxPath4}`}
          mask={`url(#${borderBoxMask2})`}
        ></use>
      </svg>

      {compare ? (
        <svg
          className={classNames(style.borderSvg, style.borderSvg3)}
          width="165"
          height="77"
          viewBox="0 0 165 77"
        >
          <path
            d="M0.917969 74.9999H163V0.333252"
            stroke="url(#paint0_linear_11_36)"
            strokeWidth="4"
            strokeMiterlimit="10"
          />
          <defs>
            <linearGradient
              id="paint0_linear_11_36"
              x1="165.998"
              y1="38.4245"
              x2="0.917969"
              y2="38.4245"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#00A96D" />
              <stop offset="1" stopColor="#011F21" />
            </linearGradient>
          </defs>
          <use
            stroke="#00FF00"
            strokeWidth="4"
            xlinkHref={`#${borderBoxPath5}`}
          ></use>
          <use
            stroke="#00FF00"
            strokeWidth="4"
            xlinkHref={`#${borderBoxPath6}`}
            mask={`url(#${borderBoxMask3})`}
          ></use>
        </svg>
      ) : (
        <svg
          className={classNames(style.borderSvg, style.borderSvg3)}
          width="200"
          height="200"
        >
          <defs>
            <path
              id={borderBoxPath5}
              d="M198 125 L198 200"
              fill="transparent"
            />
            <path
              id={borderBoxPath6}
              d="M200 198 L0 198 L0 150"
              fill="transparent"
            />
            <radialGradient id={borderBoxGradient} cx="50%" cy="50%" r="50%">
              <stop offset="0%" stopColor="#fff" stopOpacity="1" />
              <stop offset="100%" stopColor="#fff" stopOpacity="0" />
            </radialGradient>
            <mask id={borderBoxMask3}>
              <circle
                cx="200"
                cy="198"
                r="150"
                fill={`url(#${borderBoxGradient})`}
              ></circle>
            </mask>
          </defs>
          <use
            stroke="#00FF00"
            strokeWidth="4"
            xlinkHref={`#${borderBoxPath5}`}
          ></use>
          <use
            stroke="#00FF00"
            strokeWidth="4"
            xlinkHref={`#${borderBoxPath6}`}
            mask={`url(#${borderBoxMask3})`}
          ></use>
        </svg>
      )}
      {compare ? (
        <svg
          className={classNames(style.borderSvg, style.borderSvg4)}
          width="161"
          height="77"
          viewBox="0 0 161 77"
        >
          <path
            d="M160.129 74.6667H2V0"
            stroke="url(#paint0_linear_11_35)"
            strokeWidth="4"
            strokeMiterlimit="10"
          />
          <defs>
            <linearGradient
              id="paint0_linear_11_35"
              x1="-0.925198"
              y1="38.0913"
              x2="160.115"
              y2="38.0913"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#00A96D" />
              <stop offset="1" stopColor="#011F21" />
            </linearGradient>
          </defs>
          <use
            stroke="#00FF00"
            strokeWidth="4"
            xlinkHref={`#${borderBoxPath7}`}
          ></use>
          <use
            stroke="#00FF00"
            strokeWidth="4"
            xlinkHref={`#${borderBoxPath8}`}
            mask={`url(#${borderBoxMask4})`}
          ></use>
        </svg>
      ) : (
        <svg
          className={classNames(style.borderSvg, style.borderSvg4)}
          width="200"
          height="200"
        >
          <defs>
            <path id={borderBoxPath7} d="M2 125 L2 200" fill="transparent" />
            <path
              id={borderBoxPath8}
              d="M0 198 L150 198 L150 150"
              fill="transparent"
            />
            <radialGradient id={borderBoxGradient} cx="50%" cy="50%" r="50%">
              <stop offset="0%" stopColor="#fff" stopOpacity="1" />
              <stop offset="100%" stopColor="#fff" stopOpacity="0" />
            </radialGradient>
            <mask id={borderBoxMask4}>
              <circle
                cx="0"
                cy="198"
                r="150"
                fill={`url(#${borderBoxGradient})`}
              ></circle>
            </mask>
          </defs>
          <use
            stroke="#00FF00"
            strokeWidth="4"
            xlinkHref={`#${borderBoxPath7}`}
          ></use>
          <use
            stroke="#00FF00"
            strokeWidth="4"
            xlinkHref={`#${borderBoxPath8}`}
            mask={`url(#${borderBoxMask4})`}
          ></use>
        </svg>
      )}

      <div
        className={classNames(style.borderBoxContent, {
          [style.breathing]: breath,
        })}
      >
        {children}
      </div>
    </div>
  );
}

export default BorderContainerDark;
