// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.M3FVGY3xR1Ys7vqJPg8y{height:340px}.M3FVGY3xR1Ys7vqJPg8y .KtIBGFUMOPyN9cl5u9o6{height:calc(100% - 38px)}.M3FVGY3xR1Ys7vqJPg8y .GWONJSuGBpDANLWTOpFR{border-radius:100px;background:#061606;padding:0 4px;display:flex;overflow:hidden}.M3FVGY3xR1Ys7vqJPg8y .GWONJSuGBpDANLWTOpFR .GWONJSuGBpDANLWTOpFR{width:calc(100% - 38px)}.M3FVGY3xR1Ys7vqJPg8y .GWONJSuGBpDANLWTOpFR .GWONJSuGBpDANLWTOpFR .ant-input{border-width:0;background:#061606}.M3FVGY3xR1Ys7vqJPg8y .GWONJSuGBpDANLWTOpFR .GWONJSuGBpDANLWTOpFR .ant-input:focus{box-shadow:unset}.M3FVGY3xR1Ys7vqJPg8y .GWONJSuGBpDANLWTOpFR .oq9FYuV15S_piN6gYssI{width:38px;height:38px}`, "",{"version":3,"sources":["webpack://./src/modules/ratings-review/components/product-deep-dive/gpt-insight.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,4CACE,wBAAA,CAEF,4CACE,mBAAA,CACA,kBAAA,CACA,aAAA,CACA,YAAA,CACA,eAAA,CACA,kEACE,uBAAA,CAEE,6EACE,cAAA,CACA,kBAAA,CACA,mFACE,gBAAA,CAKR,kEACE,UAAA,CACA,WAAA","sourcesContent":[".gptInsightBox {\n  height: 340px;\n  .messageBox {\n    height: calc(100% - 38px);\n  }\n  .inputBox {\n    border-radius: 100px;\n    background: #061606;\n    padding: 0 4px;\n    display: flex;\n    overflow: hidden;\n    .inputBox {\n      width: calc(100% - 38px);\n      :global {\n        .ant-input {\n          border-width: 0;\n          background: #061606;\n          &:focus {\n            box-shadow: unset;\n          }\n        }\n      }\n    }\n    .sendButton {\n      width: 38px;\n      height: 38px;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"gptInsightBox": `M3FVGY3xR1Ys7vqJPg8y`,
	"messageBox": `KtIBGFUMOPyN9cl5u9o6`,
	"inputBox": `GWONJSuGBpDANLWTOpFR`,
	"sendButton": `oq9FYuV15S_piN6gYssI`
};
export default ___CSS_LOADER_EXPORT___;
