import { createSlice } from '@reduxjs/toolkit';

export const loginSlice = createSlice({
  name: 'login',
  initialState: {
    user: {},
  },

  reducers: {
    setUserData(state, action) {
      state.user = action.payload.user;
    }
  },
});

// 为每个 case reducer 函数生成 Action creators
export const { setUserData } = loginSlice.actions;

export default loginSlice.reducer;