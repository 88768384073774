import service from '../../../../util/pythonApi';

export function postPredictShelfRun(data) {
  return service.request({
    url: '/v1/pred_shelf_run',
    method: 'post',
    data,
  });
}

export function getPredictShelf(id) {
  return service.request({
    url: `/v1/pred_shelf/${id}`,
    method: 'get',
  });
}

export function getShelfDesign(id) {
  return service.request({
    url: `/v1/shelf_design/${id}`,
    method: 'get',
  });
}

export function getShelfColor(id) {
  return service.request({
    url: `/v1/shelf_color/${id}`,
    method: 'get',
  });
}
