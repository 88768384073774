import React from 'react';
import style from '@/modules/pain-points/components/left-filter-navigate/common.module.scss';
import CollapseItem from './collapseItem.jsx';
import FilterSelect from './filterSelect.jsx';

const selectOptions = [{
  value: 'jack',
  label: 'Jack',
},
  {
    value: 'lucy',
    label: 'Lucy',
  },
  {
    value: 'Yiminghe',
    label: 'yiminghe',
  },
  {
    value: 'disabled',
    label: 'Disabled',
  }];

const App = () => {
  const onChange = (key) => {
    console.log(key);
  };

  const items = [{
    key: '2',
    label: 'Dish Type',
    children: <div>
      <FilterSelect title="Dish Type" options={selectOptions} />
      <FilterSelect title="Sub Type" options={selectOptions} />
    </div>,
  }, {
    key: '4',
    label: 'Demographics',
    children: <div>
      <FilterSelect title="Generation" options={selectOptions} />
      <FilterSelect title="Life Stage" options={selectOptions} />
    </div>,
  },
  ];

  return <>
    <div className={style.selectBox}>
      <FilterSelect title="Market" options={selectOptions} />
    </div>
    <div className={style.line} />
    <CollapseItem item={items[0]} />
    <div className={style.line} />
    <div className={style.selectBox}>
      <FilterSelect title="Need States" options={selectOptions} />
    </div>
    <div className={style.line} />
    <CollapseItem item={items[1]} />
    <div className={style.line} />
    <div className={style.selectBox}>
      <FilterSelect title="Product Usage" options={selectOptions} />
    </div>
    <div className={style.line} />
    <div className={style.selectBox}>
      <FilterSelect title="Contemporary Cooks" options={selectOptions} />
    </div>
    <div className={style.line} />
  </>;
};

export default App;