import React from 'react';

const TrendLifeStageFilter = ({ trendLifeStages, setFilterByTrendLifeStage, selectedStages }) => {
  const handleCheckboxChange = (stage) => {
    setFilterByTrendLifeStage(stage);
  };

  return (
    <div className='indexscorefilter trendLifeStage'>
      <div className='mainContainer'>
        {trendLifeStages?.map((stage, index) => (
          <label key={index}>
            <input
              type="checkbox"
              checked={selectedStages.includes(stage)}
              onChange={() => handleCheckboxChange(stage)}
            />
            {stage}
          </label>
        ))}
      </div>
    </div>
  );
};

export default TrendLifeStageFilter;
