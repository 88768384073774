import classNames from 'classnames';
import React from 'react';
import EqualSvg from '../assets/img/equal-y.svg';
import MinusSvg from '../assets/img/minus-r.svg';
import PlusSvg from '../assets/img/plus-g.svg';
import style from './legend2.module.scss';

function PackageAiLegend({ className = '' }) {
  return (
    <table className={classNames(style.legendTable, className)}>
      <thead>
        <tr>
          <th>Scenario</th>
          <th>Legend</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            If Unilever Brand score is greater than Competitor Brand score by 10
            percentage points or more
          </td>
          <td>
            <div className={style.icon}>
              <img src={PlusSvg} />
            </div>
          </td>
        </tr>
        <tr>
          <td>
            If Unilever Brand score is greater or less than Competitor Brand
            score by less than 10 percentage points
          </td>
          <td>
            <div className={style.icon}>
              <img src={EqualSvg} />
            </div>
          </td>
        </tr>
        <tr>
          <td>
            If Unilever Brand score is less than Competitor Brandscore by 10
            percentage points or more
          </td>
          <td>
            <div className={style.icon}>
              <img src={MinusSvg} />
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  );
}

export default PackageAiLegend;
