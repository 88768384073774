// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bsvEnGXGi4leFWtb_fGU{padding:20px}.bsvEnGXGi4leFWtb_fGU .KE6lUGRYB0pM1SB5fPkn{font-size:20px;font-weight:bold}.zy_02IOR1pzdfc6I3PyM{margin-top:15px;display:flex;justify-content:end;align-items:center}.zy_02IOR1pzdfc6I3PyM .ZWnIpVeS2ZFS5X0hHBNk{margin-right:10px}`, "",{"version":3,"sources":["webpack://./src/modules/package-ai-generation/views/update/delete.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,4CACE,cAAA,CACA,gBAAA,CAIJ,sBACE,eAAA,CACA,YAAA,CACA,mBAAA,CACA,kBAAA,CACA,4CACE,iBAAA","sourcesContent":[".box1 {\n  padding: 20px;\n  .text1 {\n    font-size: 20px;\n    font-weight: bold;\n  }\n}\n\n.box12 {\n  margin-top: 15px;\n  display: flex;\n  justify-content: end;\n  align-items: center;\n  .btn {\n    margin-right: 10px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"box1": `bsvEnGXGi4leFWtb_fGU`,
	"text1": `KE6lUGRYB0pM1SB5fPkn`,
	"box12": `zy_02IOR1pzdfc6I3PyM`,
	"btn": `ZWnIpVeS2ZFS5X0hHBNk`
};
export default ___CSS_LOADER_EXPORT___;
